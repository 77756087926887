import { OutgoingInvoice } from 'model';
import React from 'react';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  ArrowDropDown,
  CheckCircle,
  IndeterminateCheckBoxOutlined
} from '@material-ui/icons';
import { BaseTableRow } from '../TableDataCell';
import { ColumnFilter } from './column-filter.types';

export interface InvoiceFilterState {
  amount: 'empty' | 'non-empty' | 'all';
}
function InvoiceAmountChooser(props: {
  amount: 'empty' | 'non-empty' | 'all';
  changeAmountMode: (amount: 'empty' | 'non-empty' | 'all') => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  return (
    <>
      <IconButton
        size="small"
        onClick={(event) => {
          if (!anchorEl) {
            setAnchorEl(event.currentTarget);
          }
        }}
      >
        <Box display="flex" alignItems="center">
          {props.amount === 'empty' && (
            <CancelIcon style={{ fontSize: '1rem' }} />
          )}
          {props.amount === 'all' && (
            <IndeterminateCheckBoxOutlined style={{ fontSize: '1rem' }} />
          )}
          {props.amount === 'non-empty' && (
            <CheckCircle style={{ fontSize: '1rem' }} />
          )}
          <ArrowDropDown style={{ fontSize: '0.9rem', marginLeft: '-4px' }} />
        </Box>
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            props.changeAmountMode('empty');
            setAnchorEl(null);
          }}
        >
          <CancelIcon fontSize="small" /> Only empty values
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.changeAmountMode('all');
            setAnchorEl(null);
          }}
        >
          <IndeterminateCheckBoxOutlined fontSize="small" />
          Empty and non-emty values
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.changeAmountMode('non-empty');
            setAnchorEl(null);
          }}
        >
          <CheckCircle fontSize="small" /> Only non-empty values
        </MenuItem>
      </Menu>
    </>
  );
}

export function InvoiceColumnFilter<T extends BaseTableRow>(): ColumnFilter<
  T,
  OutgoingInvoice[],
  InvoiceFilterState
> {
  return {
    overlayOnFocus: false,
    initialState: {
      amount: 'all'
    },
    createFilter: (cell, { amount }) => {
      return (value) => {
        if (amount === 'empty') {
          return value.length === 0;
        }
        if (amount === 'non-empty') {
          return value.length > 0;
        }
        return true;
      };
    },
    // eslint-disable-next-line react/prop-types
    component: ({ filterState, onFilterChange }) => (
      <InvoiceAmountChooser
        /* eslint-disable-next-line react/prop-types */
        amount={filterState.amount}
        changeAmountMode={(m) => onFilterChange({ amount: m })}
      />
    )
  };
}
