import React, { PropsWithChildren } from 'react';
import { ActionCreatorWithoutPayload, SerializedError } from '@reduxjs/toolkit';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../../redux/redux.hooks';

type KontextorAlertProps = {
  clear: ActionCreatorWithoutPayload;
  selector: (state: RootState) => SerializedError | null;
};

export function KontextorError(props: PropsWithChildren<KontextorAlertProps>) {
  const dispatch = useAppDispatch();
  const error = useAppSelector(props.selector);
  const clearError = () => dispatch(props.clear());
  return (
    <>
      {props.children}
      <Snackbar
        style={{ zIndex: 9000 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={clearError}
      >
        <MuiAlert
          style={{ zIndex: 9001 }}
          onClose={clearError}
          severity="error"
          elevation={6}
          variant="filled"
        >
          {error && error.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
