import React from 'react';
import { RequestPositionLanguages } from 'model';
import { ArrowForward } from '@material-ui/icons';
import { LanguageDisplay } from '../../../../../languages/components/LanguageDisplay';

interface RequestPositionLanguagesDisplayProps {
  languages: RequestPositionLanguages;
}

export function RequestPositionLanguagesDisplay({
  languages
}: RequestPositionLanguagesDisplayProps) {
  const { sourceLanguage, targetLanguages } = languages;

  return (
    <div style={{ display: 'flex' }}>
      {sourceLanguage && (
        <>
          <LanguageDisplay languageId={sourceLanguage} />
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <ArrowForward />
          </div>
        </>
      )}
      {targetLanguages?.map((targetLanguage, index) => (
        <LanguageDisplay
          style={{ marginLeft: index !== 0 ? 10 : undefined }}
          languageId={targetLanguage}
        />
      ))}
    </div>
  );
}
