"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectOfferTransformer = exports.ProjectOfferDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const lodash_1 = require("lodash");
const IsNullable_1 = require("../../transformation/IsNullable");
const FileAccessDTO_1 = require("../../files/dto/FileAccessDTO");
const RequestCommentDTO_1 = require("./RequestCommentDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const ProjectOffer_1 = require("../entity/ProjectOffer");
const SupplierAttachmentGrant_1 = require("../../supplier/entity/SupplierAttachmentGrant");
// eslint-disable-next-line import/no-cycle
const AbstractOfferPositionDTO_1 = require("./AbstractOfferPositionDTO");
// eslint-disable-next-line import/no-cycle
const ScalarOfferPositionDTO_1 = require("./ScalarOfferPositionDTO");
// eslint-disable-next-line import/no-cycle
const GroupOfferPositionDTO_1 = require("./GroupOfferPositionDTO");
const OfferPosition_1 = require("../entity/OfferPosition");
class ProjectOfferDTO {
}
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], ProjectOfferDTO.prototype, "offerDate", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, IsNullable_1.IsNullable)(),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], ProjectOfferDTO.prototype, "validUntil", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsNumber)(),
    (0, class_transformer_1.Type)(() => Number),
    __metadata("design:type", Number)
], ProjectOfferDTO.prototype, "totalPrice", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => FileAccessDTO_1.FileAccessDTO),
    __metadata("design:type", Array)
], ProjectOfferDTO.prototype, "attachedFiles", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => RequestCommentDTO_1.RequestCommentDTO),
    __metadata("design:type", RequestCommentDTO_1.RequestCommentDTO)
], ProjectOfferDTO.prototype, "comment", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], ProjectOfferDTO.prototype, "currency", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 2 }),
    (0, class_validator_1.Min)(0),
    (0, class_validator_1.Max)(100),
    (0, class_transformer_1.Type)(() => Number),
    __metadata("design:type", Number)
], ProjectOfferDTO.prototype, "taxPercentage", void 0);
__decorate([
    (0, class_transformer_1.Type)(() => AbstractOfferPositionDTO_1.AbstractOfferPositionDTO, {
        discriminator: {
            property: 'positionType',
            subTypes: [
                {
                    name: 'scalar',
                    value: ScalarOfferPositionDTO_1.ScalarOfferPositionDTO
                },
                {
                    name: 'group',
                    value: GroupOfferPositionDTO_1.GroupOfferPositionDTO
                }
            ]
        },
        keepDiscriminatorProperty: true
    }),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", Array)
], ProjectOfferDTO.prototype, "positions", void 0);
exports.ProjectOfferDTO = ProjectOfferDTO;
const projectOfferTransformer = (encoder) => (0, DTOTransformer_1.createDTOTransformer)(ProjectOffer_1.ProjectOffer, ProjectOfferDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'request',
    'requestId',
    'commentIds',
    'comments',
    'order',
    'orderId',
    'author',
    'authorId',
    'rejection',
    'rejectionId',
    'positionIds'
], {
    totalPrice: ['totalPrice', (v) => { var _a; return (_a = v === null || v === void 0 ? void 0 : v.amount) !== null && _a !== void 0 ? _a : null; }],
    positions: [
        'positions',
        (v) => {
            var _a;
            const parentMap = (0, lodash_1.groupBy)(v, (p) => {
                var _a;
                return (_a = (OfferPosition_1.OfferPosition.isScalarPosition(p)
                    ? p.parentPositionId
                    : undefined)) !== null && _a !== void 0 ? _a : '';
            });
            return ((_a = parentMap['']) !== null && _a !== void 0 ? _a : []).map((pos) => {
                var _a, _b, _c, _d, _e;
                if (pos.positionType === 'scalar') {
                    return {
                        id: pos.id,
                        name: pos.name,
                        amountDescription: pos
                            .amountDescription,
                        unitPriceDescription: pos
                            .unitPriceDescription,
                        description: pos.description,
                        positionType: 'scalar',
                        priceUnitId: pos.priceUnitId,
                        amount: pos.amount,
                        totalPrice: (_b = (_a = pos.totalPrice) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : null,
                        unitPrice: pos.unitPrice.amount
                    };
                }
                return {
                    id: pos.id,
                    positionType: 'group',
                    name: pos.name,
                    description: pos.description,
                    totalPrice: (_d = (_c = pos.totalPrice) === null || _c === void 0 ? void 0 : _c.amount) !== null && _d !== void 0 ? _d : null,
                    positions: ((_e = parentMap[pos.id]) !== null && _e !== void 0 ? _e : []).map((subPos) => {
                        var _a, _b;
                        if (subPos.positionType === 'scalar') {
                            return {
                                id: subPos.id,
                                name: subPos.name,
                                description: subPos.description,
                                amountDescription: subPos
                                    .amountDescription,
                                unitPriceDescription: subPos
                                    .unitPriceDescription,
                                positionType: 'scalar',
                                priceUnitId: subPos.priceUnitId,
                                amount: subPos.amount,
                                totalPrice: (_b = (_a = subPos.totalPrice) === null || _a === void 0 ? void 0 : _a.amount) !== null && _b !== void 0 ? _b : null,
                                unitPrice: subPos.unitPrice.amount
                            };
                        }
                        throw new Error('invalid position state');
                    })
                };
            });
        }
    ],
    attachedFiles: [
        'attachedFiles',
        (v, k, o) => {
            return v
                ? v.map((f) => FileAccessDTO_1.FileAccessDTO.createUnsigned(encoder, f.id, SupplierAttachmentGrant_1.supplierAttachmentPermission, o))
                : v;
        }
    ]
});
exports.projectOfferTransformer = projectOfferTransformer;
