"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierLanguage = void 0;
const typeorm_1 = require("typeorm");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("./Supplier");
const Language_1 = require("../../language/entity/Language");
const CircularReference_1 = require("../../serialization/CircularReference");
let SupplierLanguage = class SupplierLanguage {
    constructor(init) {
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => Language_1.Language),
    __metadata("design:type", Language_1.Language)
], SupplierLanguage.prototype, "language", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sl) => sl.language),
    (0, typeorm_1.PrimaryColumn)(),
    __metadata("design:type", String)
], SupplierLanguage.prototype, "languageId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", Supplier_1.Supplier)
], SupplierLanguage.prototype, "supplier", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sl) => sl.supplier),
    (0, typeorm_1.PrimaryColumn)(),
    __metadata("design:type", String)
], SupplierLanguage.prototype, "supplierId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", Boolean)
], SupplierLanguage.prototype, "native", void 0);
SupplierLanguage = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], SupplierLanguage);
exports.SupplierLanguage = SupplierLanguage;
