import { OrderStepTask, OrderTask, TemplateScope } from 'model';
import React from 'react';
import { maxBy } from 'lodash';
import { compareAsc } from 'date-fns';
import { useOrderTasks } from '../../hooks/project-order-tasks.hook';

export type OrderTaskItem =
  | {
      type: 'existing';
      order: {
        scope: TemplateScope;
        phase: 'preparation' | 'provision' | 'post-delivery';
        dependencyLevel: number;
      };
      value: OrderTask;
    }
  | {
      type: 'creating';
      order: {
        scope: TemplateScope;
        phase: 'preparation' | 'provision' | 'post-delivery';
        dependencyLevel: number;
      };
      value: OrderTask;
    };

export const OrderTaskContext = React.createContext({
  taskMap: {} as Record<string, OrderTaskItem>
});

export function useTaskMap() {
  return React.useContext(OrderTaskContext).taskMap;
}

export function useMappedTasks(projectId: string, orderId: string) {
  const { orderTasks } = useOrderTasks(projectId, orderId);
  const existingItems = React.useMemo(() => {
    const map = Object.fromEntries(
      orderTasks.map((ot) => [
        ot.id,
        { type: 'existing', order: {}, value: ot } as OrderTaskItem
      ])
    );
    return Object.fromEntries(
      Object.entries(map).map(([k, v]) => {
        const scope = ['project', 'service', 'service-lane'][
          [
            v.value.parentTaskId,
            map[v.value.parentTaskId ?? '']?.value?.parentTaskId
          ].filter(Boolean).length
        ];
        const walkDeps = ([ot, level = 0]: [OrderTask, number?]): number => {
          if (ot.dependencies.length > 0) {
            return (
              maxBy(
                ot.dependencies.map((d) =>
                  walkDeps([map[d.id].value, level + 1])
                ),
                (l) => l
              ) ?? 0
            );
          }
          return level;
        };

        return [
          k,
          {
            ...v,
            order: {
              scope,
              phase:
                v.value.taskType === 'step-task'
                  ? (v.value as OrderStepTask).phase
                  : 'provision',
              dependencyLevel: walkDeps([v.value, 0])
            }
          }
        ];
      })
    ) as Record<string, OrderTaskItem>;
  }, [orderTasks]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newTasks, setNewTasks] = React.useState<Record<string, OrderTaskItem>>(
    {}
  );
  return React.useMemo(() => {
    return { ...existingItems, ...newTasks };
  }, [existingItems, newTasks]);
}

export function usePhaseChilds(parentTask: OrderTask | null) {
  const taskMap = useTaskMap();
  const childTasks = React.useMemo(() => {
    return Object.values(taskMap)
      .filter((ti) => ti.value.parentTaskId === (parentTask?.id ?? null))
      .sort((a, b) => {
        const depCompare = a.order.dependencyLevel - b.order.dependencyLevel;
        if (depCompare === 0) {
          return compareAsc(
            new Date(a.value.createdAt),
            new Date(b.value.createdAt)
          );
        }
        return depCompare;
      });
  }, [taskMap]);

  const preparation = React.useMemo(
    () =>
      childTasks.filter(
        (task) =>
          task.value.taskType !== 'resource' &&
          task.order.phase === 'preparation'
      ),
    [childTasks]
  );
  const provision = React.useMemo(
    () =>
      childTasks.filter(
        (task) =>
          task.value.taskType !== 'resource' && task.order.phase === 'provision'
      ),
    [childTasks]
  );
  const postDelivery = React.useMemo(
    () =>
      childTasks.filter(
        (task) =>
          task.value.taskType !== 'resource' &&
          task.order.phase === 'post-delivery'
      ),
    [childTasks]
  );
  const resources = React.useMemo(
    () => childTasks.filter((task) => task.value.taskType === 'resource'),
    [childTasks]
  );
  return React.useMemo(
    () => ({ preparation, provision, postDelivery, resources }),
    [preparation, provision, postDelivery, resources]
  );
}
