import { Supplier, SupplierPatchDTO, supplierPatchTransformer } from 'model';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import React from 'react';
import * as yup from 'yup';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { FormikCountrySelect } from '../../../../components/locale/country/FormikCountrySelect';
import { suppliersThunk } from '../../redux/suppliers.thunk';
import { EditableTextArray } from '../../../../components/input/EditableTextArray';
import { AddressDisplay } from '../../../../components/address/AddressDisplay';
import { EditableCard } from '../../../../components/input/card/EditableCard';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { SupplierAddressActions } from './SupplierAddressActions';
import { buildDTOView } from '../../../../transformer/DTOViewSchema';

export const SupplierAddressView = buildDTOView<SupplierPatchDTO>()
  .include('addresses')
  .withSchema(
    yup
      .object({
        addresses: yup
          .array()
          .required()
          .of(
            yup.object({
              address: yup
                .array()
                .required('required')
                .of(yup.string().required('required')),
              zipCode: yup.string().defined().nullable().default(null),
              city: yup.string().required('required'),
              countryCode: yup.string().required('required'),
              comment: yup.string().defined().nullable().default(null)
            })
          )
      })
      .required()
  );

export function SupplierAddressForm(props: { index: number }) {
  const arrayAccessor = SupplierAddressView.path.addresses.at(props.index);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <EditableTextArray
          allowEmpty
          required
          name={arrayAccessor.address}
          primaryLabel="Address"
          secondaryLabel="address addition"
        />
      </Grid>
      <Grid item xs={3}>
        <FormikTextField name={arrayAccessor.zipCode} label="Zip Code" />
      </Grid>
      <Grid item xs={9}>
        <FormikTextField required name={arrayAccessor.city} label="City" />
      </Grid>
      <Grid item xs={12}>
        <FormikCountrySelect required name={arrayAccessor.countryCode} />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          rows={3}
          multiline
          name={arrayAccessor.comment}
          label="Comment"
        />
      </Grid>
    </Grid>
  );
}

export function SupplierAddresses({ supplier }: { supplier: Supplier }) {
  const history = useHistory();

  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const { type, reference } = useEditorLocation();

  const supplierDto = React.useMemo(
    () => supplierPatchTransformer(supplier),
    [supplier]
  );

  const dispatch = useAppDispatch();

  const addressView = SupplierAddressView.viewFactory(supplierDto);

  const updateSupplier = (input: SupplierPatchDTO) =>
    dispatch(suppliersThunk.update({ id: supplier.id, input }))
      .then(unwrapResult)
      .catch((res) => res);

  return (
    <>
      {type === 'view' && (
        <Button
          size="small"
          disabled={type !== 'view'}
          variant="outlined"
          color="primary"
          style={{ marginBottom: 20 }}
          onClick={() => history.push(`${url}?type=create#address`)}
          startIcon={<PlusIcon />}
        >
          {t('New Address')}
        </Button>
      )}
      <Grid container spacing={1}>
        {type === 'create' && reference === 'address' && (
          <Grid item xs={3}>
            <EditableCard
              identifier="address"
              title="Address"
              variant="outlined"
              onSubmit={updateSupplier}
              value={{
                addresses: [
                  ...addressView.addresses,
                  {
                    address: [''],
                    city: '',
                    countryCode: '',
                    zipCode: '',
                    comment: ''
                  }
                ]
              }}
              validationSchema={SupplierAddressView.validationSchema}
            >
              <SupplierAddressForm index={addressView.addresses.length} />
            </EditableCard>
          </Grid>
        )}
        {supplier.addresses.map((address, addressIndex) => (
          <Grid item xs={3}>
            {type === 'edit' && address.id === reference ? (
              <EditableCard
                identifier={address.id}
                variant="outlined"
                title="Address"
                onSubmit={updateSupplier}
                value={addressView}
                validationSchema={SupplierAddressView.validationSchema}
              >
                <SupplierAddressForm index={addressIndex} />
              </EditableCard>
            ) : (
              <Card variant="outlined">
                <CardContent style={{ position: 'relative' }}>
                  <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    <SupplierAddressActions
                      supplier={supplier}
                      address={address}
                    />
                  </div>
                  <AddressDisplay address={address} />
                </CardContent>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
}
