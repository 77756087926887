import { shallowEqual } from 'react-redux';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { serviceStepsSelectors } from '../redux/service-steps.slice';
import { serviceStepsThunk } from '../redux/service-steps.thunk';

export function usePriceableServiceSteps() {
  const dispatch = useAppDispatch();

  const priceableSteps = useAppSelector(
    serviceStepsSelectors.selectPriceable,
    shallowEqual
  );
  const apiState = useAppSelector(serviceStepsSelectors.selectState);
  const listFetched = useAppSelector(serviceStepsSelectors.selectListFetched);

  const listEntities = () => dispatch(serviceStepsThunk.list());
  React.useEffect(() => {
    if (apiState === 'fresh' || listFetched === false) {
      listEntities();
    }
  }, [apiState, listFetched, listEntities]);

  return React.useMemo(() => ({ priceableSteps }), [priceableSteps]);
}
