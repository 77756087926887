import React from 'react';

export interface TableSearchContextData {
  keys: string[];
  setKeys: (keys: string[]) => void;
}

export function useTableSearchContext(
  initialKeys?: string[]
): TableSearchContextData {
  const [keys, setKeys] = React.useState<string[]>(initialKeys ?? []);
  return React.useMemo(
    () => ({
      keys,
      setKeys
    }),
    [keys, setKeys]
  );
}
