import { Box, IconButton, Menu, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import SettingsIcon from '@material-ui/icons/Settings';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import {
  ArrowDropDown,
  CheckCircle,
  IndeterminateCheckBoxOutlined,
  MoreHoriz
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ColumnFilter } from './column-filter.types';
import { BaseTableRow } from '../TableDataCell';

const TextFilterStrategies = {
  includes: (h: string, n: string) => h.includes(n),
  prefix: (h: string, n: string) => h.startsWith(n)
};

type SimpleTextFilterParams = {
  nullable?: boolean;
  caseSensitive?: boolean;
  trim?: boolean;
  splitWords?: string | undefined;
  strategy?: keyof typeof TextFilterStrategies;
  applyOn?: 'change' | 'blur' | 'submit';
  initialState?: string | TextColumnFilterState;
  showReset?: boolean;
  hideSettings?: boolean;
  overlayOnFocus?: boolean;
};

function TextFilterNullChooser(props: {
  nullMode: 'only-null' | 'any-value' | 'non-null';
  changeNullMode: (nullMode: 'only-null' | 'any-value' | 'non-null') => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  return (
    <>
      <IconButton
        size="small"
        onClick={(event) => {
          if (!anchorEl) {
            setAnchorEl(event.currentTarget);
          }
        }}
      >
        <Box display="flex" alignItems="center">
          {props.nullMode === 'only-null' && (
            <CancelIcon style={{ fontSize: '1rem' }} />
          )}
          {props.nullMode === 'any-value' && (
            <IndeterminateCheckBoxOutlined style={{ fontSize: '1rem' }} />
          )}
          {props.nullMode === 'non-null' && (
            <CheckCircle style={{ fontSize: '1rem' }} />
          )}
          <ArrowDropDown style={{ fontSize: '0.9rem', marginLeft: '-4px' }} />
        </Box>
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            props.changeNullMode('only-null');
            setAnchorEl(null);
          }}
        >
          <CancelIcon fontSize="small" /> Only empty values
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.changeNullMode('any-value');
            setAnchorEl(null);
          }}
        >
          <IndeterminateCheckBoxOutlined fontSize="small" />
          Empty and non-emty values
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.changeNullMode('non-null');
            setAnchorEl(null);
          }}
        >
          <CheckCircle fontSize="small" /> Only non-empty values
        </MenuItem>
      </Menu>
    </>
  );
}

function TextFilterInput(props: {
  state: string;
  nullMode?: 'only-null' | 'any-value' | 'non-null';
  updateState: (v?: string) => void;
  updateNullMode: (mode: 'only-null' | 'any-value' | 'non-null') => void;
  applyOn: SimpleTextFilterParams['applyOn'];
  showReset: boolean;
  settings?: JSX.Element;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const [input, setInput] = React.useState<string>();

  const applyState = (inp: string, apply: boolean) => {
    setInput(apply ? undefined : inp);
    if (apply) {
      props.updateState(inp);
    }
  };
  const onTextInput = (value: string) =>
    applyState(value, props.applyOn === 'change');

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && props.applyOn === 'submit' && input !== undefined)
      applyState(input, true);
  };

  return (
    <>
      <TextField
        value={input ?? props.state}
        onChange={(event) => onTextInput(event.target.value)}
        onBlur={() => {
          if (input !== undefined) applyState(input, props.applyOn === 'blur');
        }}
        fullWidth={
          props.nullMode !== 'only-null' && (input ?? props.state).trim() !== ''
        }
        disabled={props.nullMode === 'only-null'}
        inputProps={{
          style: {
            width: props.nullMode === 'only-null' ? 0 : undefined
          }
        }}
        onKeyUp={handleKeyUp}
        style={{ width: '100%', minWidth: '150px' }}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          startAdornment: props.nullMode ? (
            <TextFilterNullChooser
              nullMode={props.nullMode}
              changeNullMode={props.updateNullMode}
            />
          ) : undefined,
          endAdornment:
            props.nullMode !== 'only-null' ? (
              <>
                {props.settings}
                {props.showReset &&
                (input !== undefined || props.state.trim() !== '') ? (
                  <IconButton
                    size="small"
                    onClick={() => {
                      setInput(undefined);
                      props.updateState();
                    }}
                  >
                    <CancelIcon style={{ fontSize: '1rem' }} />
                  </IconButton>
                ) : (
                  <></>
                )}
              </>
            ) : undefined
        }}
      />
    </>
  );
}

export interface TextColumnFilterState {
  filterValue: string;
  options: {
    nullMode?: 'only-null' | 'any-value' | 'non-null';
    caseSensitive: boolean;
    wordSplit?: string;
  };
}

function TextFilterSettings(props: {
  state: TextColumnFilterState['options'];
  onChange: (newState: TextColumnFilterState['options']) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  return (
    <>
      <IconButton size="small" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <SettingsIcon style={{ fontSize: '1rem' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{ dense: true }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          key="case-sensitive"
          selected={props.state.caseSensitive}
          onClick={() => {
            // setAnchorEl(null);
            props.onChange({
              ...props.state,
              caseSensitive: !props.state.caseSensitive
            });
          }}
        >
          <TextFieldsIcon /> Case sensitive
        </MenuItem>
        <MenuItem
          key="case-sensitive"
          selected={!!props.state.wordSplit}
          onClick={() => {
            // setAnchorEl(null);
            props.onChange({
              ...props.state,
              wordSplit: props.state.wordSplit === undefined ? ',' : undefined
            });
          }}
        >
          <MoreHoriz /> comma separated
        </MenuItem>
      </Menu>
    </>
  );
}

export function TextColumnFilter<T extends BaseTableRow>({
  nullable = true,
  caseSensitive = false,
  trim = true,
  splitWords = undefined,
  strategy = 'includes',
  applyOn = 'change',
  initialState = '',
  showReset = true,
  overlayOnFocus,
  hideSettings
}: SimpleTextFilterParams): ColumnFilter<T, string, TextColumnFilterState> {
  const realInitialState =
    typeof initialState === 'string'
      ? {
          filterValue: initialState,
          options: {
            caseSensitive,
            wordSplit: splitWords,
            nullMode: nullable ? ('any-value' as const) : undefined
          }
        }
      : initialState;

  const prepareValue = (
    s: string,
    options: TextColumnFilterState['options']
  ) => {
    let sane = s ?? '';
    if (!options.caseSensitive) {
      sane = sane.toLowerCase();
    }
    if (trim) {
      sane = sane.trim();
    }
    return sane;
  };
  const prepareSearch = (
    s: string,
    options: TextColumnFilterState['options']
  ) => {
    return (options.wordSplit ? s.split(options.wordSplit) : [s])
      .map((p) => prepareValue(p, options))
      .filter((p) => p !== '');
  };
  const matcher = TextFilterStrategies[strategy];
  return {
    overlayOnFocus,
    // eslint-disable-next-line react/prop-types
    component: ({ filterState, onFilterChange, onFilterReset }) => (
      <TextFilterInput
        /* eslint-disable-next-line react/prop-types */
        nullMode={filterState?.options?.nullMode}
        updateNullMode={(m) =>
          onFilterChange({
            ...filterState,
            // eslint-disable-next-line react/prop-types
            options: { ...filterState.options, nullMode: m }
          })
        }
        /* eslint-disable-next-line react/prop-types */
        state={filterState.filterValue}
        updateState={(s?: string) =>
          s !== undefined
            ? onFilterChange({ ...filterState, filterValue: s })
            : onFilterReset()
        }
        applyOn={applyOn}
        showReset={showReset}
        settings={
          hideSettings ? undefined : (
            <TextFilterSettings
              /* eslint-disable-next-line react/prop-types */
              state={filterState.options}
              onChange={(v) => onFilterChange({ ...filterState, options: v })}
            />
          )
        }
      />
    ),
    createFilter: (cell, state) => {
      const keys = prepareSearch(state.filterValue, state.options);
      return (value) => {
        const accessedData = value;
        if (accessedData === null || accessedData === undefined) {
          if (state.options.nullMode === 'only-null') {
            return true;
          }
          if (state.options.nullMode === 'non-null') {
            return false;
          }
          return state.filterValue.trim() === '';
        }
        if (state.options.nullMode === 'only-null') {
          return false;
        }
        const haystack = prepareValue(accessedData, state.options);
        return (
          keys.length === 0 || keys.some((needle) => matcher(haystack, needle))
        );
      };
    },
    initialState: realInitialState,
    isEffective: (_, state) =>
      state.filterValue.trim() !== '' ||
      (state.options.nullMode !== undefined &&
        state.options.nullMode !== 'any-value')
  };
}
