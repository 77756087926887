"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.userProfilePatchTransformer = exports.UserProfilePatchDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const omit_type_helper_1 = require("../../extern-libs/mapped-types/omit-type.helper");
const CreateUserDTO_1 = require("./CreateUserDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const User_1 = require("../entity/User");
const FileAccessDTO_1 = require("../../files/dto/FileAccessDTO");
class UserProfilePatchDTO extends (0, partial_type_helper_1.PartialType)((0, omit_type_helper_1.OmitType)(CreateUserDTO_1.CreateUserDTO, ['email', 'roleIds', 'enabled'])) {
}
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => FileAccessDTO_1.FileAccessDTO),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", FileAccessDTO_1.FileAccessDTO)
], UserProfilePatchDTO.prototype, "avatarAccess", void 0);
exports.UserProfilePatchDTO = UserProfilePatchDTO;
exports.userProfilePatchTransformer = (0, DTOTransformer_1.createDTOTransformer)(User_1.User, UserProfilePatchDTO, [
    'email',
    'roles',
    'enabled',
    'id',
    'supplier',
    'password',
    'clientContact',
    'createdAt',
    'updatedAt',
    'avatar'
], {});
