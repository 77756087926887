"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountryCodes = exports.CountryCodeMap = exports.Countries = void 0;
const BaseCountries = [
    { name: 'Afghanistan', code: 'AF', longCode: 'AFG' },
    { name: 'Åland Islands', code: 'AX', longCode: 'ALA' },
    { name: 'Albania', code: 'AL', longCode: 'ALB' },
    { name: 'Algeria', code: 'DZ', longCode: 'DZA' },
    { name: 'American Samoa', code: 'AS', longCode: 'ASM' },
    { name: 'Andorra', code: 'AD', longCode: 'AND' },
    { name: 'Angola', code: 'AO', longCode: 'AGO' },
    { name: 'Anguilla', code: 'AI', longCode: 'AIA' },
    { name: 'Antarctica', code: 'AQ', longCode: 'ATA' },
    { name: 'Antigua and Barbuda', code: 'AG', longCode: 'ATG' },
    { name: 'Argentina', code: 'AR', longCode: 'ARG' },
    { name: 'Armenia', code: 'AM', longCode: 'ARM' },
    { name: 'Aruba', code: 'AW', longCode: 'ABW' },
    { name: 'Australia', code: 'AU', longCode: 'AUS' },
    { name: 'Austria', code: 'AT', longCode: 'AUT' },
    { name: 'Azerbaijan', code: 'AZ', longCode: 'AZE' },
    { name: 'Bahamas', code: 'BS', longCode: 'BHS' },
    { name: 'Bahrain', code: 'BH', longCode: 'BHR' },
    { name: 'Bangladesh', code: 'BD', longCode: 'BGD' },
    { name: 'Barbados', code: 'BB', longCode: 'BRB' },
    { name: 'Belarus', code: 'BY', longCode: 'BLR' },
    { name: 'Belgium', code: 'BE', longCode: 'BEL' },
    { name: 'Belize', code: 'BZ', longCode: 'BLZ' },
    { name: 'Benin', code: 'BJ', longCode: 'BEN' },
    { name: 'Bermuda', code: 'BM', longCode: 'BMU' },
    { name: 'Bhutan', code: 'BT', longCode: 'BTN' },
    { name: 'Bolivia (Plurinational State of)', code: 'BO', longCode: 'BOL' },
    { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ', longCode: 'BES' },
    { name: 'Bosnia and Herzegovina', code: 'BA', longCode: 'BIH' },
    { name: 'Botswana', code: 'BW', longCode: 'BWA' },
    { name: 'Bouvet Island', code: 'BV', longCode: 'BVT' },
    { name: 'Brazil', code: 'BR', longCode: 'BRA' },
    { name: 'British Indian Ocean Territory', code: 'IO', longCode: 'IOT' },
    { name: 'Brunei Darussalam', code: 'BN', longCode: 'BRN' },
    { name: 'Bulgaria', code: 'BG', longCode: 'BGR' },
    { name: 'Burkina Faso', code: 'BF', longCode: 'BFA' },
    { name: 'Burundi', code: 'BI', longCode: 'BDI' },
    { name: 'Cabo Verde', code: 'CV', longCode: 'CPV' },
    { name: 'Cambodia', code: 'KH', longCode: 'KHM' },
    { name: 'Cameroon', code: 'CM', longCode: 'CMR' },
    { name: 'Canada', code: 'CA', longCode: 'CAN' },
    { name: 'Cayman Islands', code: 'KY', longCode: 'CYM' },
    { name: 'Central African Republic', code: 'CF', longCode: 'CAF' },
    { name: 'Chad', code: 'TD', longCode: 'TCD' },
    { name: 'Chile', code: 'CL', longCode: 'CHL' },
    { name: 'China', code: 'CN', longCode: 'CHN' },
    { name: 'Christmas Island', code: 'CX', longCode: 'CXR' },
    { name: 'Cocos (Keeling) Islands', code: 'CC', longCode: 'CCK' },
    { name: 'Colombia', code: 'CO', longCode: 'COL' },
    { name: 'Comoros', code: 'KM', longCode: 'COM' },
    { name: 'Congo', code: 'CG', longCode: 'COG' },
    { name: 'Congo, Democratic Republic of the', code: 'CD', longCode: 'COD' },
    { name: 'Cook Islands', code: 'CK', longCode: 'COK' },
    { name: 'Costa Rica', code: 'CR', longCode: 'CRI' },
    { name: "Côte d'Ivoire", code: 'CI', longCode: 'CIV' },
    { name: 'Croatia', code: 'HR', longCode: 'HRV' },
    { name: 'Cuba', code: 'CU', longCode: 'CUB' },
    { name: 'Curaçao', code: 'CW', longCode: 'CUW' },
    { name: 'Cyprus', code: 'CY', longCode: 'CYP' },
    { name: 'Czechia', code: 'CZ', longCode: 'CZE' },
    { name: 'Denmark', code: 'DK', longCode: 'DNK' },
    { name: 'Djibouti', code: 'DJ', longCode: 'DJI' },
    { name: 'Dominica', code: 'DM', longCode: 'DMA' },
    { name: 'Dominican Republic', code: 'DO', longCode: 'DOM' },
    { name: 'Ecuador', code: 'EC', longCode: 'ECU' },
    { name: 'Egypt', code: 'EG', longCode: 'EGY' },
    { name: 'El Salvador', code: 'SV', longCode: 'SLV' },
    { name: 'Equatorial Guinea', code: 'GQ', longCode: 'GNQ' },
    { name: 'Eritrea', code: 'ER', longCode: 'ERI' },
    { name: 'Estonia', code: 'EE', longCode: 'EST' },
    { name: 'Eswatini', code: 'SZ', longCode: 'SWZ' },
    { name: 'Ethiopia', code: 'ET', longCode: 'ETH' },
    { name: 'Falkland Islands (Malvinas)', code: 'FK', longCode: 'FLK' },
    { name: 'Faroe Islands', code: 'FO', longCode: 'FRO' },
    { name: 'Fiji', code: 'FJ', longCode: 'FJI' },
    { name: 'Finland', code: 'FI', longCode: 'FIN' },
    { name: 'France', code: 'FR', longCode: 'FRA' },
    { name: 'French Guiana', code: 'GF', longCode: 'GUF' },
    { name: 'French Polynesia', code: 'PF', longCode: 'PYF' },
    { name: 'French Southern Territories', code: 'TF', longCode: 'ATF' },
    { name: 'Gabon', code: 'GA', longCode: 'GAB' },
    { name: 'Gambia', code: 'GM', longCode: 'GMB' },
    { name: 'Georgia', code: 'GE', longCode: 'GEO' },
    { name: 'Germany', code: 'DE', longCode: 'DEU' },
    { name: 'Ghana', code: 'GH', longCode: 'GHA' },
    { name: 'Gibraltar', code: 'GI', longCode: 'GIB' },
    { name: 'Greece', code: 'GR', longCode: 'GRC' },
    { name: 'Greenland', code: 'GL', longCode: 'GRL' },
    { name: 'Grenada', code: 'GD', longCode: 'GRD' },
    { name: 'Guadeloupe', code: 'GP', longCode: 'GLP' },
    { name: 'Guam', code: 'GU', longCode: 'GUM' },
    { name: 'Guatemala', code: 'GT', longCode: 'GTM' },
    { name: 'Guernsey', code: 'GG', longCode: 'GGY' },
    { name: 'Guinea', code: 'GN', longCode: 'GIN' },
    { name: 'Guinea-Bissau', code: 'GW', longCode: 'GNB' },
    { name: 'Guyana', code: 'GY', longCode: 'GUY' },
    { name: 'Haiti', code: 'HT', longCode: 'HTI' },
    { name: 'Heard Island and McDonald Islands', code: 'HM', longCode: 'HMD' },
    { name: 'Holy See', code: 'VA', longCode: 'VAT' },
    { name: 'Honduras', code: 'HN', longCode: 'HND' },
    { name: 'Hong Kong', code: 'HK', longCode: 'HKG' },
    { name: 'Hungary', code: 'HU', longCode: 'HUN' },
    { name: 'Iceland', code: 'IS', longCode: 'ISL' },
    { name: 'India', code: 'IN', longCode: 'IND' },
    { name: 'Indonesia', code: 'ID', longCode: 'IDN' },
    { name: 'Iran (Islamic Republic of)', code: 'IR', longCode: 'IRN' },
    { name: 'Iraq', code: 'IQ', longCode: 'IRQ' },
    { name: 'Ireland', code: 'IE', longCode: 'IRL' },
    { name: 'Isle of Man', code: 'IM', longCode: 'IMN' },
    { name: 'Israel', code: 'IL', longCode: 'ISR' },
    { name: 'Italy', code: 'IT', longCode: 'ITA' },
    { name: 'Jamaica', code: 'JM', longCode: 'JAM' },
    { name: 'Japan', code: 'JP', longCode: 'JPN' },
    { name: 'Jersey', code: 'JE', longCode: 'JEY' },
    { name: 'Jordan', code: 'JO', longCode: 'JOR' },
    { name: 'Kazakhstan', code: 'KZ', longCode: 'KAZ' },
    { name: 'Kenya', code: 'KE', longCode: 'KEN' },
    { name: 'Kiribati', code: 'KI', longCode: 'KIR' },
    {
        name: "Korea (Democratic People's Republic of)",
        code: 'KP',
        longCode: 'PRK'
    },
    { name: 'Korea, Republic of', code: 'KR', longCode: 'KOR' },
    { name: 'Kuwait', code: 'KW', longCode: 'KWT' },
    { name: 'Kyrgyzstan', code: 'KG', longCode: 'KGZ' },
    { name: "Lao People's Democratic Republic", code: 'LA', longCode: 'LAO' },
    { name: 'Latvia', code: 'LV', longCode: 'LVA' },
    { name: 'Lebanon', code: 'LB', longCode: 'LBN' },
    { name: 'Lesotho', code: 'LS', longCode: 'LSO' },
    { name: 'Liberia', code: 'LR', longCode: 'LBR' },
    { name: 'Libya', code: 'LY', longCode: 'LBY' },
    { name: 'Liechtenstein', code: 'LI', longCode: 'LIE' },
    { name: 'Lithuania', code: 'LT', longCode: 'LTU' },
    { name: 'Luxembourg', code: 'LU', longCode: 'LUX' },
    { name: 'Macao', code: 'MO', longCode: 'MAC' },
    { name: 'Madagascar', code: 'MG', longCode: 'MDG' },
    { name: 'Malawi', code: 'MW', longCode: 'MWI' },
    { name: 'Malaysia', code: 'MY', longCode: 'MYS' },
    { name: 'Maldives', code: 'MV', longCode: 'MDV' },
    { name: 'Mali', code: 'ML', longCode: 'MLI' },
    { name: 'Malta', code: 'MT', longCode: 'MLT' },
    { name: 'Marshall Islands', code: 'MH', longCode: 'MHL' },
    { name: 'Martinique', code: 'MQ', longCode: 'MTQ' },
    { name: 'Mauritania', code: 'MR', longCode: 'MRT' },
    { name: 'Mauritius', code: 'MU', longCode: 'MUS' },
    { name: 'Mayotte', code: 'YT', longCode: 'MYT' },
    { name: 'Mexico', code: 'MX', longCode: 'MEX' },
    { name: 'Micronesia (Federated States of)', code: 'FM', longCode: 'FSM' },
    { name: 'Moldova, Republic of', code: 'MD', longCode: 'MDA' },
    { name: 'Monaco', code: 'MC', longCode: 'MCO' },
    { name: 'Mongolia', code: 'MN', longCode: 'MNG' },
    { name: 'Montenegro', code: 'ME', longCode: 'MNE' },
    { name: 'Montserrat', code: 'MS', longCode: 'MSR' },
    { name: 'Morocco', code: 'MA', longCode: 'MAR' },
    { name: 'Mozambique', code: 'MZ', longCode: 'MOZ' },
    { name: 'Myanmar', code: 'MM', longCode: 'MMR' },
    { name: 'Namibia', code: 'NA', longCode: 'NAM' },
    { name: 'Nauru', code: 'NR', longCode: 'NRU' },
    { name: 'Nepal', code: 'NP', longCode: 'NPL' },
    { name: 'Netherlands', code: 'NL', longCode: 'NLD' },
    { name: 'New Caledonia', code: 'NC', longCode: 'NCL' },
    { name: 'New Zealand', code: 'NZ', longCode: 'NZL' },
    { name: 'Nicaragua', code: 'NI', longCode: 'NIC' },
    { name: 'Niger', code: 'NE', longCode: 'NER' },
    { name: 'Nigeria', code: 'NG', longCode: 'NGA' },
    { name: 'Niue', code: 'NU', longCode: 'NIU' },
    { name: 'Norfolk Island', code: 'NF', longCode: 'NFK' },
    { name: 'North Macedonia', code: 'MK', longCode: 'MKD' },
    { name: 'Northern Mariana Islands', code: 'MP', longCode: 'MNP' },
    { name: 'Norway', code: 'NO', longCode: 'NOR' },
    { name: 'Oman', code: 'OM', longCode: 'OMN' },
    { name: 'Pakistan', code: 'PK', longCode: 'PAK' },
    { name: 'Palau', code: 'PW', longCode: 'PLW' },
    { name: 'Palestine, State of', code: 'PS', longCode: 'PSE' },
    { name: 'Panama', code: 'PA', longCode: 'PAN' },
    { name: 'Papua New Guinea', code: 'PG', longCode: 'PNG' },
    { name: 'Paraguay', code: 'PY', longCode: 'PRY' },
    { name: 'Peru', code: 'PE', longCode: 'PER' },
    { name: 'Philippines', code: 'PH', longCode: 'PHL' },
    { name: 'Pitcairn', code: 'PN', longCode: 'PCN' },
    { name: 'Poland', code: 'PL', longCode: 'POL' },
    { name: 'Portugal', code: 'PT', longCode: 'PRT' },
    { name: 'Puerto Rico', code: 'PR', longCode: 'PRI' },
    { name: 'Qatar', code: 'QA', longCode: 'QAT' },
    { name: 'Réunion', code: 'RE', longCode: 'REU' },
    { name: 'Romania', code: 'RO', longCode: 'ROU' },
    { name: 'Russian Federation', code: 'RU', longCode: 'RUS' },
    { name: 'Rwanda', code: 'RW', longCode: 'RWA' },
    { name: 'Saint Barthélemy', code: 'BL', longCode: 'BLM' },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        code: 'SH',
        longCode: 'SHN'
    },
    { name: 'Saint Kitts and Nevis', code: 'KN', longCode: 'KNA' },
    { name: 'Saint Lucia', code: 'LC', longCode: 'LCA' },
    { name: 'Saint Martin (French part)', code: 'MF', longCode: 'MAF' },
    { name: 'Saint Pierre and Miquelon', code: 'PM', longCode: 'SPM' },
    { name: 'Saint Vincent and the Grenadines', code: 'VC', longCode: 'VCT' },
    { name: 'Samoa', code: 'WS', longCode: 'WSM' },
    { name: 'San Marino', code: 'SM', longCode: 'SMR' },
    { name: 'Sao Tome and Principe', code: 'ST', longCode: 'STP' },
    { name: 'Saudi Arabia', code: 'SA', longCode: 'SAU' },
    { name: 'Senegal', code: 'SN', longCode: 'SEN' },
    { name: 'Serbia', code: 'RS', longCode: 'SRB' },
    { name: 'Seychelles', code: 'SC', longCode: 'SYC' },
    { name: 'Sierra Leone', code: 'SL', longCode: 'SLE' },
    { name: 'Singapore', code: 'SG', longCode: 'SGP' },
    { name: 'Sint Maarten (Dutch part)', code: 'SX', longCode: 'SXM' },
    { name: 'Slovakia', code: 'SK', longCode: 'SVK' },
    { name: 'Slovenia', code: 'SI', longCode: 'SVN' },
    { name: 'Solomon Islands', code: 'SB', longCode: 'SLB' },
    { name: 'Somalia', code: 'SO', longCode: 'SOM' },
    { name: 'South Africa', code: 'ZA', longCode: 'ZAF' },
    {
        name: 'South Georgia and the South Sandwich Islands',
        code: 'GS',
        longCode: 'SGS'
    },
    { name: 'South Sudan', code: 'SS', longCode: 'SSD' },
    { name: 'Spain', code: 'ES', longCode: 'ESP' },
    { name: 'Sri Lanka', code: 'LK', longCode: 'LKA' },
    { name: 'Sudan', code: 'SD', longCode: 'SDN' },
    { name: 'Suriname', code: 'SR', longCode: 'SUR' },
    { name: 'Svalbard and Jan Mayen', code: 'SJ', longCode: 'SJM' },
    { name: 'Sweden', code: 'SE', longCode: 'SWE' },
    { name: 'Switzerland', code: 'CH', longCode: 'CHE' },
    { name: 'Syrian Arab Republic', code: 'SY', longCode: 'SYR' },
    { name: 'Taiwan, Province of China', code: 'TW', longCode: 'TWN' },
    { name: 'Tajikistan', code: 'TJ', longCode: 'TJK' },
    { name: 'Tanzania, United Republic of', code: 'TZ', longCode: 'TZA' },
    { name: 'Thailand', code: 'TH', longCode: 'THA' },
    { name: 'Timor-Leste', code: 'TL', longCode: 'TLS' },
    { name: 'Togo', code: 'TG', longCode: 'TGO' },
    { name: 'Tokelau', code: 'TK', longCode: 'TKL' },
    { name: 'Tonga', code: 'TO', longCode: 'TON' },
    { name: 'Trinidad and Tobago', code: 'TT', longCode: 'TTO' },
    { name: 'Tunisia', code: 'TN', longCode: 'TUN' },
    { name: 'Turkey', code: 'TR', longCode: 'TUR' },
    { name: 'Turkmenistan', code: 'TM', longCode: 'TKM' },
    { name: 'Turks and Caicos Islands', code: 'TC', longCode: 'TCA' },
    { name: 'Tuvalu', code: 'TV', longCode: 'TUV' },
    { name: 'Uganda', code: 'UG', longCode: 'UGA' },
    { name: 'Ukraine', code: 'UA', longCode: 'UKR' },
    { name: 'United Arab Emirates', code: 'AE', longCode: 'ARE' },
    {
        name: 'United Kingdom of Great Britain and Northern Ireland',
        code: 'GB',
        longCode: 'GBR'
    },
    { name: 'United States of America', code: 'US', longCode: 'USA' },
    { name: 'United States Minor Outlying Islands', code: 'UM', longCode: 'UMI' },
    { name: 'Uruguay', code: 'UY', longCode: 'URY' },
    { name: 'Uzbekistan', code: 'UZ', longCode: 'UZB' },
    { name: 'Vanuatu', code: 'VU', longCode: 'VUT' },
    { name: 'Venezuela (Bolivarian Republic of)', code: 'VE', longCode: 'VEN' },
    { name: 'Viet Nam', code: 'VN', longCode: 'VNM' },
    { name: 'Virgin Islands (British)', code: 'VG', longCode: 'VGB' },
    { name: 'Virgin Islands (U.S.)', code: 'VI', longCode: 'VIR' },
    { name: 'Wallis and Futuna', code: 'WF', longCode: 'WLF' },
    { name: 'Western Sahara', code: 'EH', longCode: 'ESH' },
    { name: 'Yemen', code: 'YE', longCode: 'YEM' },
    { name: 'Zambia', code: 'ZM', longCode: 'ZMB' },
    { name: 'Zimbabwe', code: 'ZW', longCode: 'ZWE' }
];
function countryToFlagCode(country) {
    const isoCode = typeof country === 'string' ? country : country.code;
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}
exports.Countries = BaseCountries.map((base) => ({
    ...base,
    flag: countryToFlagCode(base)
}));
exports.CountryCodeMap = Object.fromEntries(exports.Countries.map((c) => [c.code, c]));
exports.CountryCodes = Object.keys(exports.CountryCodeMap);
