import { InitialDataAggregation, Qualification } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { supplierQualificationsThunk } from './supplier-qualifications.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { fetchInitialDataThunk } from '../../../redux/initial-data.thunk';

const { list, delete: deleteThunk, ...thunks } = supplierQualificationsThunk;

const supplierQualificationsSlice = createEntitySlice<Qualification>(
  'supplier-qualifications',
  [
    CustomThunk.override<Qualification>(list, {
      pending: (_, state) => {
        state.state = 'pending';
        state.listFetched = undefined;
        state.error = null;
        return state;
      },
      rejected: (_, state, action) => {
        state.state = 'idle';
        state.listFetched = false;
        state.error = (action as any).error;
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        state.listFetched = true;
        return adapter.upsertMany(state, payload as Qualification[]);
      }
    }),
    CustomThunk.override<any>(fetchInitialDataThunk, {
      pending: (_, state) => {
        return state;
      },
      rejected: (_, state) => {
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        const dataPayload = payload as InitialDataAggregation;
        if (dataPayload.qualifications) {
          state.state = state.state === 'fresh' ? 'idle' : state.state;
          state.listFetched = true;
          return adapter.upsertMany(state, dataPayload.qualifications);
        }
        return state;
      }
    }),
    CustomThunk.override<Qualification>(
      deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
      {
        fulfilled: (adapter, state, { payload }) => {
          state.state = 'idle';
          return adapter.removeOne(state, payload as string);
        }
      }
    ),
    ...Object.values(thunks)
  ]
);
export const supplierQualificationsSelectors = createAdapterSelectors(
  'supplier-qualifications',
  supplierQualificationsSlice.adapter
);

export const qualificationApi: EntityAPI<Qualification> = {
  selectors: supplierQualificationsSelectors,
  thunks: supplierQualificationsThunk
};

export default supplierQualificationsSlice;
