"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ISO3DeprecatedMap = void 0;
exports.ISO3DeprecatedMap = {
    fri: { retiredCode: 'fri', name: 'Western Frisian', replacement: 'fry' },
    auv: { retiredCode: 'auv', name: 'Auvergnat', replacement: 'oci' },
    gsc: { retiredCode: 'gsc', name: 'Gascon', replacement: 'oci' },
    lms: { retiredCode: 'lms', name: 'Limousin', replacement: 'oci' },
    lnc: { retiredCode: 'lnc', name: 'Languedocien', replacement: 'oci' },
    prv: { retiredCode: 'prv', name: 'Provençal', replacement: 'oci' },
    amd: { retiredCode: 'amd', name: 'Amapá Creole' },
    bgh: { retiredCode: 'bgh', name: 'Bogan', replacement: 'bbh' },
    bnh: { retiredCode: 'bnh', name: 'Banawá', replacement: 'jaa' },
    bvs: { retiredCode: 'bvs', name: 'Belgian Sign Language' },
    ccy: { retiredCode: 'ccy', name: 'Southern Zhuang' },
    cit: { retiredCode: 'cit', name: 'Chittagonian' },
    flm: { retiredCode: 'flm', name: 'Falam Chin' },
    jap: { retiredCode: 'jap', name: 'Jaruára', replacement: 'jaa' },
    kob: { retiredCode: 'kob', name: 'Kohoroxitari', replacement: 'xsu' },
    mob: { retiredCode: 'mob', name: 'Moinba' },
    mzf: { retiredCode: 'mzf', name: 'Aiku' },
    nhj: { retiredCode: 'nhj', name: 'Tlalitzlipa Nahuatl', replacement: 'nhi' },
    nhs: { retiredCode: 'nhs', name: 'Southeastern Puebla Nahuatl' },
    occ: { retiredCode: 'occ', name: 'Occidental', replacement: 'ile' },
    tmx: { retiredCode: 'tmx', name: 'Tomyang', replacement: 'ybi' },
    tot: { retiredCode: 'tot', name: 'Patla-Chicontla Totonac' },
    xmi: { retiredCode: 'xmi', name: 'Miarrã' },
    yib: { retiredCode: 'yib', name: 'Yinglish', replacement: 'eng' },
    ztc: { retiredCode: 'ztc', name: 'Lachirioag Zapotec', replacement: 'zty' },
    atf: { retiredCode: 'atf', name: 'Atuence' },
    bqe: {
        retiredCode: 'bqe',
        name: 'Navarro-Labourdin Basque',
        replacement: 'eus'
    },
    bsz: { retiredCode: 'bsz', name: 'Souletin Basque', replacement: 'eus' },
    aex: { retiredCode: 'aex', name: 'Amerax', replacement: 'eng' },
    ahe: { retiredCode: 'ahe', name: 'Ahe', replacement: 'knx' },
    aiz: { retiredCode: 'aiz', name: 'Aari' },
    akn: { retiredCode: 'akn', name: 'Amikoana' },
    arf: { retiredCode: 'arf', name: 'Arafundi' },
    azr: { retiredCode: 'azr', name: 'Adzera' },
    bcx: { retiredCode: 'bcx', name: 'Pamona' },
    bii: { retiredCode: 'bii', name: 'Bisu' },
    bke: { retiredCode: 'bke', name: 'Bengkulu', replacement: 'pse' },
    blu: { retiredCode: 'blu', name: 'Hmong Njua' },
    boc: { retiredCode: 'boc', name: 'Bakung Kenyah', replacement: 'xkl' },
    bsd: { retiredCode: 'bsd', name: 'Sarawak Bisaya', replacement: 'bsb' },
    bwv: { retiredCode: 'bwv', name: 'Bahau River Kenyah' },
    bxt: { retiredCode: 'bxt', name: 'Buxinhua', replacement: 'bgk' },
    byu: { retiredCode: 'byu', name: 'Buyang' },
    ccx: { retiredCode: 'ccx', name: 'Northern Zhuang' },
    cru: { retiredCode: 'cru', name: 'Carútana', replacement: 'bwi' },
    dat: { retiredCode: 'dat', name: 'Darang Deng', replacement: 'mhu' },
    dyk: { retiredCode: 'dyk', name: 'Land Dayak' },
    eni: { retiredCode: 'eni', name: 'Enim', replacement: 'pse' },
    fiz: { retiredCode: 'fiz', name: 'Izere' },
    gen: { retiredCode: 'gen', name: 'Geman Deng', replacement: 'mxj' },
    ggh: { retiredCode: 'ggh', name: 'Garreh-Ajuran' },
    itu: { retiredCode: 'itu', name: 'Itutang', replacement: 'mzu' },
    kds: { retiredCode: 'kds', name: 'Lahu Shi' },
    knh: { retiredCode: 'knh', name: 'Kayan River Kenyah' },
    krg: { retiredCode: 'krg', name: 'North Korowai', replacement: 'khe' },
    krq: { retiredCode: 'krq', name: 'Krui', replacement: 'ljp' },
    kxg: { retiredCode: 'kxg', name: 'Katingan', replacement: 'nij' },
    lmt: { retiredCode: 'lmt', name: 'Lematang', replacement: 'mui' },
    lnt: { retiredCode: 'lnt', name: 'Lintang', replacement: 'pse' },
    lod: { retiredCode: 'lod', name: 'Berawan' },
    mbg: { retiredCode: 'mbg', name: 'Northern Nambikuára' },
    mdo: { retiredCode: 'mdo', name: 'Southwest Gbaya' },
    mhv: { retiredCode: 'mhv', name: 'Arakanese' },
    miv: { retiredCode: 'miv', name: 'Mimi', replacement: 'amj' },
    mqd: { retiredCode: 'mqd', name: 'Madang', replacement: 'xkl' },
    nky: { retiredCode: 'nky', name: 'Khiamniungan Naga' },
    nxj: { retiredCode: 'nxj', name: 'Nyadu', replacement: 'byd' },
    ogn: { retiredCode: 'ogn', name: 'Ogan', replacement: 'pse' },
    ork: { retiredCode: 'ork', name: 'Orokaiva' },
    paj: { retiredCode: 'paj', name: 'Ipeka-Tapuia', replacement: 'kpc' },
    pec: { retiredCode: 'pec', name: 'Southern Pesisir', replacement: 'ljp' },
    pen: { retiredCode: 'pen', name: 'Penesak', replacement: 'mui' },
    plm: { retiredCode: 'plm', name: 'Palembang', replacement: 'mui' },
    poj: { retiredCode: 'poj', name: 'Lower Pokomo', replacement: 'pkb' },
    pun: { retiredCode: 'pun', name: 'Pubian', replacement: 'ljp' },
    rae: { retiredCode: 'rae', name: 'Ranau', replacement: 'ljp' },
    rjb: { retiredCode: 'rjb', name: 'Rajbanshi' },
    rws: { retiredCode: 'rws', name: 'Rawas', replacement: 'mui' },
    sdd: { retiredCode: 'sdd', name: 'Semendo', replacement: 'pse' },
    sdi: { retiredCode: 'sdi', name: 'Sindang Kelingi', replacement: 'liw' },
    skl: { retiredCode: 'skl', name: 'Selako', replacement: 'knx' },
    slb: { retiredCode: 'slb', name: 'Kahumamahon Saluan', replacement: 'loe' },
    srj: { retiredCode: 'srj', name: 'Serawai', replacement: 'pse' },
    suf: { retiredCode: 'suf', name: 'Tarpia' },
    suh: { retiredCode: 'suh', name: 'Suba' },
    suu: { retiredCode: 'suu', name: 'Sungkai', replacement: 'ljp' },
    szk: { retiredCode: 'szk', name: 'Sizaki', replacement: 'ikz' },
    tle: { retiredCode: 'tle', name: 'Southern Marakwet', replacement: 'enb' },
    tnj: { retiredCode: 'tnj', name: 'Tanjong', replacement: 'kxn' },
    ttx: { retiredCode: 'ttx', name: 'Tutong 1', replacement: 'bsb' },
    ubm: { retiredCode: 'ubm', name: 'Upper Baram Kenyah' },
    vky: { retiredCode: 'vky', name: 'Kayu Agung', replacement: 'kge' },
    vmo: { retiredCode: 'vmo', name: 'Muko-Muko', replacement: 'min' },
    wre: { retiredCode: 'wre', name: 'Ware' },
    xah: { retiredCode: 'xah', name: 'Kahayan', replacement: 'nij' },
    xkm: { retiredCode: 'xkm', name: 'Mahakam Kenyah' },
    xuf: { retiredCode: 'xuf', name: 'Kunfal', replacement: 'awn' },
    yio: { retiredCode: 'yio', name: 'Dayao Yi', replacement: 'lpo' },
    ymj: { retiredCode: 'ymj', name: 'Muji Yi' },
    ypl: { retiredCode: 'ypl', name: 'Pula Yi' },
    ypw: { retiredCode: 'ypw', name: 'Puwa Yi' },
    ywm: { retiredCode: 'ywm', name: 'Wumeng Yi', replacement: 'ywu' },
    yym: { retiredCode: 'yym', name: 'Yuanjiang-Mojiang Yi' },
    mly: { retiredCode: 'mly', name: 'Malay (individual language)' },
    muw: { retiredCode: 'muw', name: 'Mundari' },
    xst: { retiredCode: 'xst', name: "Silt'e" },
    ope: { retiredCode: 'ope', name: 'Old Persian', replacement: 'peo' },
    scc: { retiredCode: 'scc', name: 'Serbian', replacement: 'srp' },
    scr: { retiredCode: 'scr', name: 'Croatian', replacement: 'hrv' },
    xsk: { retiredCode: 'xsk', name: 'Sakan', replacement: 'kho' },
    mol: { retiredCode: 'mol', name: 'Moldavian', replacement: 'ron' },
    aay: { retiredCode: 'aay', name: 'Aariya' },
    acc: { retiredCode: 'acc', name: 'Cubulco Achí', replacement: 'acr' },
    cbm: {
        retiredCode: 'cbm',
        name: 'Yepocapa Southwestern Cakchiquel',
        replacement: 'cak'
    },
    chs: { retiredCode: 'chs', name: 'Chumash' },
    ckc: { retiredCode: 'ckc', name: 'Northern Cakchiquel', replacement: 'cak' },
    ckd: {
        retiredCode: 'ckd',
        name: 'South Central Cakchiquel',
        replacement: 'cak'
    },
    cke: { retiredCode: 'cke', name: 'Eastern Cakchiquel', replacement: 'cak' },
    ckf: { retiredCode: 'ckf', name: 'Southern Cakchiquel', replacement: 'cak' },
    cki: {
        retiredCode: 'cki',
        name: 'Santa María De Jesús Cakchiquel',
        replacement: 'cak'
    },
    ckj: {
        retiredCode: 'ckj',
        name: 'Santo Domingo Xenacoj Cakchiquel',
        replacement: 'cak'
    },
    ckk: {
        retiredCode: 'ckk',
        name: 'Acatenango Southwestern Cakchiquel',
        replacement: 'cak'
    },
    ckw: { retiredCode: 'ckw', name: 'Western Cakchiquel', replacement: 'cak' },
    cnm: { retiredCode: 'cnm', name: 'Ixtatán Chuj', replacement: 'cac' },
    cti: { retiredCode: 'cti', name: 'Tila Chol', replacement: 'ctu' },
    cun: { retiredCode: 'cun', name: 'Cunén Quiché', replacement: 'quc' },
    eml: { retiredCode: 'eml', name: 'Emiliano-Romagnolo' },
    eur: { retiredCode: 'eur', name: 'Europanto' },
    gmo: { retiredCode: 'gmo', name: 'Gamo-Gofa-Dawro' },
    hsf: { retiredCode: 'hsf', name: 'Southeastern Huastec', replacement: 'hus' },
    hva: {
        retiredCode: 'hva',
        name: 'San Luís Potosí Huastec',
        replacement: 'hus'
    },
    ixi: { retiredCode: 'ixi', name: 'Nebaj Ixil', replacement: 'ixl' },
    ixj: { retiredCode: 'ixj', name: 'Chajul Ixil', replacement: 'ixl' },
    jai: { retiredCode: 'jai', name: 'Western Jacalteco', replacement: 'jac' },
    mms: { retiredCode: 'mms', name: 'Southern Mam', replacement: 'mam' },
    mpf: { retiredCode: 'mpf', name: 'Tajumulco Mam', replacement: 'mam' },
    mtz: { retiredCode: 'mtz', name: 'Tacanec', replacement: 'mam' },
    mvc: { retiredCode: 'mvc', name: 'Central Mam', replacement: 'mam' },
    mvj: {
        retiredCode: 'mvj',
        name: 'Todos Santos Cuchumatán Mam',
        replacement: 'mam'
    },
    poa: { retiredCode: 'poa', name: 'Eastern Pokomam', replacement: 'poc' },
    pob: { retiredCode: 'pob', name: 'Western Pokomchí', replacement: 'poh' },
    pou: { retiredCode: 'pou', name: 'Southern Pokomam', replacement: 'poc' },
    ppv: { retiredCode: 'ppv', name: 'Papavô' },
    quj: { retiredCode: 'quj', name: 'Joyabaj Quiché', replacement: 'quc' },
    qut: { retiredCode: 'qut', name: 'West Central Quiché', replacement: 'quc' },
    quu: { retiredCode: 'quu', name: 'Eastern Quiché', replacement: 'quc' },
    qxi: { retiredCode: 'qxi', name: 'San Andrés Quiché', replacement: 'quc' },
    sic: { retiredCode: 'sic', name: 'Malinguat' },
    stc: { retiredCode: 'stc', name: 'Santa Cruz' },
    tlz: { retiredCode: 'tlz', name: "Toala'", replacement: 'rob' },
    tzb: { retiredCode: 'tzb', name: 'Bachajón Tzeltal', replacement: 'tzh' },
    tzc: { retiredCode: 'tzc', name: 'Chamula Tzotzil', replacement: 'tzo' },
    tze: { retiredCode: 'tze', name: 'Chenalhó Tzotzil', replacement: 'tzo' },
    tzs: {
        retiredCode: 'tzs',
        name: 'San Andrés Larrainzar Tzotzil',
        replacement: 'tzo'
    },
    tzt: { retiredCode: 'tzt', name: 'Western Tzutujil', replacement: 'tzj' },
    tzu: { retiredCode: 'tzu', name: 'Huixtán Tzotzil', replacement: 'tzo' },
    tzz: { retiredCode: 'tzz', name: 'Zinacantán Tzotzil', replacement: 'tzo' },
    vlr: { retiredCode: 'vlr', name: 'Vatrata' },
    yus: { retiredCode: 'yus', name: 'Chan Santa Cruz Maya', replacement: 'yua' },
    nfg: { retiredCode: 'nfg', name: 'Nyeng', replacement: 'nfd' },
    nfk: { retiredCode: 'nfk', name: 'Shakara', replacement: 'nfd' },
    agp: { retiredCode: 'agp', name: 'Paranan' },
    bhk: { retiredCode: 'bhk', name: 'Albay Bicolano' },
    bkb: { retiredCode: 'bkb', name: 'Finallig' },
    btb: { retiredCode: 'btb', name: 'Beti (Cameroon)' },
    cjr: { retiredCode: 'cjr', name: 'Chorotega', replacement: 'mom' },
    cmk: { retiredCode: 'cmk', name: 'Chimakum', replacement: 'xch' },
    drh: { retiredCode: 'drh', name: 'Darkhat', replacement: 'khk' },
    drw: { retiredCode: 'drw', name: 'Darwazi', replacement: 'prs' },
    gav: { retiredCode: 'gav', name: 'Gabutamon', replacement: 'dev' },
    mof: { retiredCode: 'mof', name: 'Mohegan-Montauk-Narragansett' },
    mst: { retiredCode: 'mst', name: 'Cataelano Mandaya', replacement: 'mry' },
    myt: { retiredCode: 'myt', name: 'Sangab Mandaya', replacement: 'mry' },
    rmr: { retiredCode: 'rmr', name: 'Caló' },
    sgl: { retiredCode: 'sgl', name: 'Sanglechi-Ishkashimi' },
    sul: { retiredCode: 'sul', name: 'Surigaonon' },
    sum: { retiredCode: 'sum', name: 'Sumo-Mayangna' },
    tnf: { retiredCode: 'tnf', name: 'Tangshewi', replacement: 'prs' },
    wgw: { retiredCode: 'wgw', name: 'Wagawaga' },
    ayx: { retiredCode: 'ayx', name: 'Ayi (China)', replacement: 'nun' },
    bjq: { retiredCode: 'bjq', name: 'Southern Betsimisaraka Malagasy' },
    dha: { retiredCode: 'dha', name: 'Dhanwar (India)' },
    dkl: { retiredCode: 'dkl', name: 'Kolum So Dogon' },
    mja: { retiredCode: 'mja', name: 'Mahei' },
    nbf: { retiredCode: 'nbf', name: 'Naxi' },
    noo: { retiredCode: 'noo', name: 'Nootka' },
    tie: { retiredCode: 'tie', name: 'Tingal', replacement: 'ras' },
    tkk: { retiredCode: 'tkk', name: 'Takpa', replacement: 'twm' },
    baz: { retiredCode: 'baz', name: 'Tunen' },
    bjd: { retiredCode: 'bjd', name: 'Bandjigali', replacement: 'drl' },
    ccq: { retiredCode: 'ccq', name: 'Chaungtha', replacement: 'rki' },
    cka: { retiredCode: 'cka', name: 'Khumi Awa Chin', replacement: 'cmr' },
    dap: { retiredCode: 'dap', name: 'Nisi (India)' },
    dwl: { retiredCode: 'dwl', name: 'Walo Kumbe Dogon' },
    elp: { retiredCode: 'elp', name: 'Elpaputih' },
    gbc: { retiredCode: 'gbc', name: 'Garawa' },
    gio: { retiredCode: 'gio', name: 'Gelao' },
    hrr: { retiredCode: 'hrr', name: 'Horuru', replacement: 'jal' },
    ibi: { retiredCode: 'ibi', name: 'Ibilo', replacement: 'opa' },
    jar: { retiredCode: 'jar', name: 'Jarawa (Nigeria)' },
    kdv: { retiredCode: 'kdv', name: 'Kado' },
    kgh: {
        retiredCode: 'kgh',
        name: 'Upper Tanudan Kalinga',
        replacement: 'kml'
    },
    kpp: { retiredCode: 'kpp', name: 'Paku Karen' },
    kzh: { retiredCode: 'kzh', name: 'Kenuzi-Dongola' },
    lcq: { retiredCode: 'lcq', name: 'Luhu', replacement: 'ppr' },
    mgx: { retiredCode: 'mgx', name: 'Omati' },
    nln: { retiredCode: 'nln', name: 'Durango Nahuatl' },
    pbz: { retiredCode: 'pbz', name: 'Palu' },
    pgy: { retiredCode: 'pgy', name: 'Pongyong' },
    sca: { retiredCode: 'sca', name: 'Sansu', replacement: 'hle' },
    tlw: { retiredCode: 'tlw', name: 'South Wemale', replacement: 'weo' },
    unp: { retiredCode: 'unp', name: 'Worora' },
    wiw: { retiredCode: 'wiw', name: 'Wirangu' },
    ybd: { retiredCode: 'ybd', name: 'Yangbye', replacement: 'rki' },
    yen: { retiredCode: 'yen', name: 'Yendang' },
    yma: { retiredCode: 'yma', name: 'Yamphe', replacement: 'lrr' },
    daf: { retiredCode: 'daf', name: 'Dan' },
    djl: { retiredCode: 'djl', name: 'Djiwarli' },
    ggr: { retiredCode: 'ggr', name: 'Aghu Tharnggalu' },
    ilw: { retiredCode: 'ilw', name: 'Talur', replacement: 'gal' },
    izi: { retiredCode: 'izi', name: 'Izi-Ezaa-Ikwo-Mgbo' },
    meg: { retiredCode: 'meg', name: 'Mea', replacement: 'cir' },
    mld: { retiredCode: 'mld', name: 'Malakhel' },
    mnt: { retiredCode: 'mnt', name: 'Maykulan' },
    mwd: { retiredCode: 'mwd', name: 'Mudbura' },
    myq: { retiredCode: 'myq', name: 'Forest Maninka' },
    nbx: { retiredCode: 'nbx', name: 'Ngura' },
    nlr: { retiredCode: 'nlr', name: 'Ngarla' },
    pcr: { retiredCode: 'pcr', name: 'Panang', replacement: 'adx' },
    ppr: { retiredCode: 'ppr', name: 'Piru', replacement: 'lcq' },
    tgg: { retiredCode: 'tgg', name: 'Tangga' },
    wit: { retiredCode: 'wit', name: 'Wintu' },
    xia: { retiredCode: 'xia', name: 'Xiandao', replacement: 'acn' },
    yiy: { retiredCode: 'yiy', name: 'Yir Yoront' },
    yos: { retiredCode: 'yos', name: 'Yos', replacement: 'zom' },
    emo: { retiredCode: 'emo', name: 'Emok' },
    ggm: { retiredCode: 'ggm', name: 'Gugu Mini' },
    leg: { retiredCode: 'leg', name: 'Lengua' },
    lmm: { retiredCode: 'lmm', name: 'Lamam', replacement: 'rmx' },
    mhh: { retiredCode: 'mhh', name: 'Maskoy Pidgin' },
    puz: { retiredCode: 'puz', name: 'Purum Naga', replacement: 'pub' },
    sap: { retiredCode: 'sap', name: 'Sanapaná' },
    yuu: { retiredCode: 'yuu', name: 'Yugh', replacement: 'yug' },
    aam: { retiredCode: 'aam', name: 'Aramanik', replacement: 'aas' },
    adp: { retiredCode: 'adp', name: 'Adap', replacement: 'dzo' },
    aue: { retiredCode: 'aue', name: 'ǂKxʼauǁʼein', replacement: 'ktz' },
    bmy: { retiredCode: 'bmy', name: 'Bemba (Democratic Republic of Congo)' },
    bxx: { retiredCode: 'bxx', name: 'Borna (Democratic Republic of Congo)' },
    byy: { retiredCode: 'byy', name: 'Buya' },
    dzd: { retiredCode: 'dzd', name: 'Daza' },
    gfx: { retiredCode: 'gfx', name: 'Mangetti Dune ǃXung', replacement: 'vaj' },
    gti: { retiredCode: 'gti', name: 'Gbati-ri', replacement: 'nyc' },
    ime: { retiredCode: 'ime', name: 'Imeraguen' },
    kbf: { retiredCode: 'kbf', name: 'Kakauhua' },
    koj: { retiredCode: 'koj', name: 'Sara Dunjo', replacement: 'kwv' },
    kwq: { retiredCode: 'kwq', name: 'Kwak', replacement: 'yam' },
    kxe: { retiredCode: 'kxe', name: 'Kakihum', replacement: 'tvd' },
    lii: { retiredCode: 'lii', name: 'Lingkhim', replacement: 'raq' },
    mwj: { retiredCode: 'mwj', name: 'Maligo', replacement: 'vaj' },
    nnx: { retiredCode: 'nnx', name: 'Ngong', replacement: 'ngv' },
    oun: { retiredCode: 'oun', name: 'ǃOǃung', replacement: 'vaj' },
    pmu: { retiredCode: 'pmu', name: 'Mirpur Panjabi', replacement: 'phr' },
    sgo: { retiredCode: 'sgo', name: 'Songa' },
    thx: { retiredCode: 'thx', name: 'The', replacement: 'oyb' },
    tsf: { retiredCode: 'tsf', name: 'Southwestern Tamang', replacement: 'taj' },
    uok: { retiredCode: 'uok', name: 'Uokha', replacement: 'ema' },
    xsj: { retiredCode: 'xsj', name: 'Subi', replacement: 'suj' },
    yds: { retiredCode: 'yds', name: 'Yiddish Sign Language' },
    ymt: { retiredCode: 'ymt', name: 'Mator-Taygi-Karagas', replacement: 'ymt' },
    ynh: { retiredCode: 'ynh', name: 'Yangho' },
    bgm: { retiredCode: 'bgm', name: 'Baga Mboteni', replacement: 'bcg' },
    btl: { retiredCode: 'btl', name: 'Bhatola' },
    cbe: { retiredCode: 'cbe', name: 'Chipiajes' },
    cbh: { retiredCode: 'cbh', name: 'Cagua' },
    coy: { retiredCode: 'coy', name: 'Coyaima', replacement: 'pij' },
    cqu: { retiredCode: 'cqu', name: 'Chilean Quechua', replacement: 'quh' },
    cum: { retiredCode: 'cum', name: 'Cumeral' },
    duj: { retiredCode: 'duj', name: 'Dhuwal' },
    ggn: { retiredCode: 'ggn', name: 'Eastern Gurung', replacement: 'gvr' },
    ggo: { retiredCode: 'ggo', name: 'Southern Gondi' },
    guv: { retiredCode: 'guv', name: 'Gey', replacement: 'guv' },
    iap: { retiredCode: 'iap', name: 'Iapama' },
    ill: { retiredCode: 'ill', name: 'Iranun' },
    kgc: { retiredCode: 'kgc', name: 'Kasseng', replacement: 'tdf' },
    kox: { retiredCode: 'kox', name: 'Coxima' },
    ktr: { retiredCode: 'ktr', name: 'Kota Marudu Tinagas', replacement: 'dtp' },
    kvs: { retiredCode: 'kvs', name: 'Kunggara', replacement: 'gdj' },
    kzj: { retiredCode: 'kzj', name: 'Coastal Kadazan', replacement: 'dtp' },
    kzt: { retiredCode: 'kzt', name: 'Tambunan Dusun', replacement: 'dtp' },
    nad: { retiredCode: 'nad', name: 'Nijadali', replacement: 'xny' },
    nts: { retiredCode: 'nts', name: 'Natagaimas', replacement: 'pij' },
    ome: { retiredCode: 'ome', name: 'Omejes' },
    pmc: { retiredCode: 'pmc', name: 'Palumata', replacement: 'huw' },
    pod: { retiredCode: 'pod', name: 'Ponares' },
    ppa: { retiredCode: 'ppa', name: 'Pao', replacement: 'bfy' },
    pry: { retiredCode: 'pry', name: 'Pray 3', replacement: 'prt' },
    rna: { retiredCode: 'rna', name: 'Runa' },
    svr: { retiredCode: 'svr', name: 'Savara' },
    tdu: { retiredCode: 'tdu', name: 'Tempasuk Dusun', replacement: 'dtp' },
    thc: { retiredCode: 'thc', name: 'Tai Hang Tong', replacement: 'tpo' },
    tid: { retiredCode: 'tid', name: 'Tidong' },
    tmp: { retiredCode: 'tmp', name: 'Tai Mène', replacement: 'tyj' },
    tne: { retiredCode: 'tne', name: 'Tinoc Kallahan', replacement: 'kak' },
    toe: { retiredCode: 'toe', name: 'Tomedes' },
    xba: { retiredCode: 'xba', name: 'Kamba (Brazil)', replacement: 'cax' },
    xbx: { retiredCode: 'xbx', name: 'Kabixí' },
    xip: { retiredCode: 'xip', name: 'Xipináwa' },
    xkh: { retiredCode: 'xkh', name: 'Karahawyana', replacement: 'waw' },
    yri: { retiredCode: 'yri', name: 'Yarí' },
    jeg: { retiredCode: 'jeg', name: 'Jeng', replacement: 'oyb' },
    kgd: { retiredCode: 'kgd', name: 'Kataang' },
    krm: { retiredCode: 'krm', name: 'Krim', replacement: 'bmf' },
    prb: { retiredCode: 'prb', name: "Lua'" },
    puk: { retiredCode: 'puk', name: 'Pu Ko' },
    rie: { retiredCode: 'rie', name: 'Rien' },
    rsi: { retiredCode: 'rsi', name: 'Rennellese Sign Language' },
    skk: { retiredCode: 'skk', name: 'Sok', replacement: 'oyb' },
    snh: { retiredCode: 'snh', name: 'Shinabo' },
    lsg: { retiredCode: 'lsg', name: 'Lyons Sign Language' },
    mwx: { retiredCode: 'mwx', name: 'Mediak' },
    mwy: { retiredCode: 'mwy', name: 'Mosiro' },
    ncp: { retiredCode: 'ncp', name: 'Ndaktup', replacement: 'kdz' },
    ais: { retiredCode: 'ais', name: 'Nataoran Amis' },
    asd: { retiredCode: 'asd', name: 'Asas', replacement: 'snz' },
    dit: { retiredCode: 'dit', name: 'Dirari', replacement: 'dif' },
    dud: { retiredCode: 'dud', name: 'Hun-Saare' },
    lba: { retiredCode: 'lba', name: 'Lui' },
    llo: { retiredCode: 'llo', name: 'Khlor', replacement: 'ngt' },
    myd: { retiredCode: 'myd', name: 'Maramba', replacement: 'aog' },
    myi: { retiredCode: 'myi', name: 'Mina (India)' },
    nns: { retiredCode: 'nns', name: 'Ningye', replacement: 'nbr' },
    aoh: { retiredCode: 'aoh', name: 'Arma' },
    ayy: { retiredCode: 'ayy', name: 'Tayabas Ayta' },
    bbz: { retiredCode: 'bbz', name: 'Babalia Creole Arabic' },
    bpb: { retiredCode: 'bpb', name: 'Barbacoas' },
    cca: { retiredCode: 'cca', name: 'Cauca' },
    cdg: { retiredCode: 'cdg', name: 'Chamari' },
    dgu: { retiredCode: 'dgu', name: 'Degaru' },
    drr: { retiredCode: 'drr', name: 'Dororo', replacement: 'kzk' },
    ekc: { retiredCode: 'ekc', name: 'Eastern Karnic' },
    gli: { retiredCode: 'gli', name: 'Guliguli', replacement: 'kzk' },
    kjf: { retiredCode: 'kjf', name: 'Khalaj' },
    kxl: { retiredCode: 'kxl', name: 'Nepali Kurux', replacement: 'kru' },
    kxu: { retiredCode: 'kxu', name: 'Kui (India)' },
    lmz: { retiredCode: 'lmz', name: 'Lumbee' },
    nxu: { retiredCode: 'nxu', name: 'Narau', replacement: 'bpp' },
    plp: { retiredCode: 'plp', name: 'Palpa' },
    sdm: { retiredCode: 'sdm', name: 'Semandang' },
    tbb: { retiredCode: 'tbb', name: 'Tapeba' },
    xrq: { retiredCode: 'xrq', name: 'Karranga', replacement: 'dmw' },
    xtz: { retiredCode: 'xtz', name: 'Tasmanian' },
    zir: { retiredCode: 'zir', name: 'Ziriya', replacement: 'scv' },
    thw: { retiredCode: 'thw', name: 'Thudam', replacement: 'ola' },
    bic: { retiredCode: 'bic', name: 'Bikaru', replacement: 'bir' },
    bij: { retiredCode: 'bij', name: 'Vaghat-Ya-Bijim-Legeri' },
    blg: { retiredCode: 'blg', name: 'Balau', replacement: 'iba' },
    gji: { retiredCode: 'gji', name: 'Geji' },
    mvm: { retiredCode: 'mvm', name: 'Muya' },
    ngo: { retiredCode: 'ngo', name: 'Ngoni' },
    pat: { retiredCode: 'pat', name: 'Papitalai', replacement: 'kxr' },
    vki: { retiredCode: 'vki', name: 'Ija-Zuba' },
    wra: { retiredCode: 'wra', name: 'Warapu' }
};
