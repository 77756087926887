import { CreateUserRoleDTO, UserRoleTree } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const createRoleThunk = createErrorHandlingThunk<
  UserRoleTree,
  CreateUserRoleDTO
>('role/create', async (arg) => {
  const result = await apiClient.post<UserRoleTree>('/roles', arg);
  return result.data;
});
