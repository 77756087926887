import {
  AbstractOfferPositionDTO,
  GroupOfferPositionDTO,
  ProjectOfferDTO,
  ScalarOfferPositionDTO
} from 'model';
import {
  Box,
  Button,
  ButtonBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  Typography,
  withStyles
} from '@material-ui/core';
import {
  AddCircleOutline,
  ArrowDownward,
  ArrowUpward,
  Cancel
} from '@material-ui/icons';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  pathToName,
  PropertyPath,
  useTypedField
} from '../../../../../transformer/DTOViewSchema';
import { ProjectOfferView } from './ProjectOfferForm';
import { FormikAmountInput } from '../../../../../components/input/FormikAmountInput';
import { PriceUnitSelect } from './PriceUnitSelect';
import { PriceAmountInput } from './PriceAmountInput';
import {
  NameDescriptionInput,
  OptionalDescriptionInput
} from '../NameDescriptionInput';
import { OfferTotalSum } from './OfferTotalSum';
import { useAlert } from '../../../../../components/alert/alert.hook';

const PositionCell = withStyles({
  root: {
    borderBottom: '1px dashed',
    height: 'unset',
    padding: '2px 5px'
  }
})(TableCell);

function BasePositionForm({
  dtoField,
  index,
  positionAmount,
  onRemove,
  onMove,
  parentIndex,
  style,
  children
}: PropsWithChildren<{
  dtoField:
    | PropertyPath<any, GroupOfferPositionDTO | ScalarOfferPositionDTO>
    | string;
  index: number;
  parentIndex?: number;
  positionAmount: number;
  onRemove: () => void;
  onMove: (change: number) => void;
  style?: TableRowProps['style'];
}>) {
  const [, meta] = useTypedField(dtoField);
  const totalPrice = calculatePositionTotal(meta.value);
  // const [, currencyMeta] = useTypedField(ProjectOfferView.path.currency);
  /*  const currencyCode = currencyMeta.value ?? DefaultCurrency.code; */
  const { t } = useTranslation();
  const alert = useAlert();
  return (
    <TableRow style={{ verticalAlign: 'top', ...style }}>
      {parentIndex === undefined ? (
        <PositionCell
          size="small"
          colSpan={2}
          style={{
            width: '3em',
            paddingRight: '4px'
          }}
        >
          {' '}
          <ButtonBase disabled={index === 0} onClick={() => onMove(-1)}>
            <ArrowUpward
              fontSize="small"
              style={{ fontSize: '1em' }}
              color={index === 0 ? 'disabled' : undefined}
            />
          </ButtonBase>
          <ButtonBase
            disabled={index === positionAmount - 1}
            onClick={() => onMove(1)}
          >
            <ArrowDownward
              fontSize="small"
              style={{ fontSize: '1em' }}
              color={index === positionAmount - 1 ? 'disabled' : undefined}
            />
          </ButtonBase>
          {`${index + 1}`}
        </PositionCell>
      ) : (
        <>
          <PositionCell style={{ borderBottom: 'none' }} />
          <PositionCell size="small" style={{}}>{`${parentIndex + 1}.${
            index + 1
          }`}</PositionCell>
        </>
      )}
      <PositionCell size="small">
        <NameDescriptionInput
          nameDtoField={`${pathToName(dtoField)}.name`}
          descriptionDtoField={`${pathToName(dtoField)}.description`}
        />
      </PositionCell>
      {children}
      <PositionCell align="right">
        <PriceAmountInput
          dtoField={`${pathToName(dtoField)}.totalPrice`}
          calculatedPrice={totalPrice}
        />
      </PositionCell>
      <PositionCell align="right">
        <Box position="relative">
          <Box display="flex" justifyContent="center">
            <ButtonBase
              onClick={() => {
                alert.showAlert({
                  title: t('Delete Position?'),
                  content: t('Are you sure'),
                  onConfirm: onRemove
                });
              }}
            >
              <Cancel style={{ fontSize: '1em' }} />
            </ButtonBase>
          </Box>
        </Box>
      </PositionCell>
    </TableRow>
  );
}

export function GroupPositionForm({
  dtoField,
  index,
  positionAmount,
  onRemove,
  onMove
}: {
  dtoField: PropertyPath<any, GroupOfferPositionDTO>;
  index: number;
  positionAmount: number;
  onRemove: () => void;
  onMove: (change: number) => void;
}) {
  const [, meta, helpers] = useTypedField(dtoField);
  return (
    <>
      <BasePositionForm
        dtoField={dtoField}
        index={index}
        positionAmount={positionAmount}
        onRemove={onRemove}
        onMove={onMove}
      >
        <PositionCell />
        <PositionCell />
        <PositionCell />
      </BasePositionForm>
      {meta.value.positions.map((sp, spi) => (
        <ScalarPositionForm
          dtoField={dtoField.sub_path(`positions[${spi}]`)}
          parentIndex={index}
          index={spi}
          positionAmount={meta.value.positions.length}
          onRemove={() => {
            helpers.setValue({
              ...meta.value,
              positions: meta.value.positions.filter((_, spi2) => spi2 !== spi)
            });
          }}
          onMove={(change) => {
            const tmp = meta.value.positions.filter((_, cI) => cI !== spi);
            tmp.splice(index + change, 0, sp);
            helpers.setValue({ ...meta.value, positions: tmp });
          }}
        />
      ))}
      <TableRow style={{ borderBottom: '1px solid' }}>
        <TableCell />
        <TableCell>
          <IconButton
            onClick={() => {
              helpers.setValue({
                ...meta.value,
                positions: [
                  ...meta.value.positions,
                  {
                    name: '',
                    description: '',
                    totalPrice: null,
                    positionType: 'scalar',
                    amount: 1,
                    priceUnitId: null,
                    unitPrice: 0
                  } as ScalarOfferPositionDTO
                ]
              });
            }}
            size="small"
          >
            <AddCircleOutline fontSize="small" />
          </IconButton>
        </TableCell>
        <TableCell colSpan={7} />
      </TableRow>
    </>
  );
}

export function ScalarPositionForm({
  dtoField,
  parentIndex,
  index,
  positionAmount,
  onRemove,
  onMove
}: {
  dtoField: PropertyPath<any, ScalarOfferPositionDTO> | string;
  parentIndex?: number;
  index: number;
  positionAmount: number;
  onRemove: () => void;
  onMove: (change: number) => void;
}) {
  return (
    <BasePositionForm
      style={{
        borderBottom:
          parentIndex !== undefined ? undefined : '1px solid #c5c7cd',
        marginLeft: parentIndex !== undefined ? '2em' : undefined,
        color: parentIndex !== undefined ? 'grey' : undefined
      }}
      dtoField={dtoField}
      index={index}
      positionAmount={positionAmount}
      onRemove={onRemove}
      onMove={onMove}
      parentIndex={parentIndex}
    >
      <PositionCell align="right">
        <OptionalDescriptionInput
          descriptionDtoField={`${pathToName(dtoField)}.amountDescription`}
        >
          <FormikAmountInput
            name={`${pathToName(dtoField)}.amount`}
            min={1}
            textFieldProps={{
              style: { width: '4em', fontSize: '1em' }
            }}
          />
        </OptionalDescriptionInput>
      </PositionCell>
      <PositionCell align="right">
        <PriceUnitSelect dtoField={`${pathToName(dtoField)}.priceUnitId`} />
      </PositionCell>
      <PositionCell align="right">
        <OptionalDescriptionInput
          descriptionDtoField={`${pathToName(dtoField)}.unitPriceDescription`}
        >
          <PriceAmountInput dtoField={`${pathToName(dtoField)}.unitPrice`} />
        </OptionalDescriptionInput>
      </PositionCell>
    </BasePositionForm>
  );
}

export function calculatePositionTotal(
  pos: GroupOfferPositionDTO | ScalarOfferPositionDTO | ProjectOfferDTO
): number {
  if (pos.totalPrice !== null) {
    return pos.totalPrice;
  }
  if (AbstractOfferPositionDTO.isPos(pos)) {
    if (ScalarOfferPositionDTO.isScalarPos(pos)) {
      return pos.amount * pos.unitPrice;
    }

    return pos.positions
      .map((sp) => calculatePositionTotal(sp))
      .reduce((acc, c) => acc + c, 0);
  }
  return (
    pos.positions
      ?.map((subPos) => calculatePositionTotal(subPos))
      .reduce((acc, c) => acc + c, 0) ?? 0
  );
}
export const PositionsHeaderCell = (props: TableCellProps) => (
  <TableCell
    {...props}
    size="small"
    style={{ ...props.style, height: 'unset', padding: '3px 5px' }}
  />
);
export function OfferPositionsForm() {
  const [, meta, helpers] = useTypedField(ProjectOfferView.path.positions);

  return (
    <>
      <TableContainer>
        <Table
          size="small"
          style={{
            border: '1px solid #c5c7cd'
          }}
        >
          <TableHead
            style={{
              backgroundColor: '#f5f5f5'
            }}
          >
            <TableRow>
              <PositionsHeaderCell
                size="small"
                style={{
                  width: '3em',
                  padding: '4px'
                }}
                colSpan={2}
              >
                <Typography variant="subtitle2">Pos.</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell size="small">
                <Typography variant="subtitle2">Name</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell size="small" align="right">
                <Typography variant="subtitle2">Amount</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell size="small" align="right">
                <Typography variant="subtitle2">Unit</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell size="small" align="right">
                <Typography variant="subtitle2">Price</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell size="small" align="right">
                <Typography variant="subtitle2">Total</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell size="small" />
            </TableRow>
          </TableHead>
          <TableBody>
            {meta.value.map((pos, index) =>
              ScalarOfferPositionDTO.isScalarPos(pos) ? (
                <ScalarPositionForm
                  onMove={(change) => {
                    const tmp = meta.value.filter((_, cI) => cI !== index);
                    tmp.splice(index + change, 0, pos);
                    helpers.setValue(tmp);
                  }}
                  onRemove={() => {
                    helpers.setValue(
                      meta.value.filter((_, idx) => idx !== index)
                    );
                  }}
                  index={index}
                  positionAmount={meta.value.length}
                  dtoField={
                    ProjectOfferView.path.positions.at(index) as PropertyPath<
                      any,
                      ScalarOfferPositionDTO
                    >
                  }
                />
              ) : (
                <GroupPositionForm
                  onMove={(change) => {
                    const tmp = meta.value.filter((_, cI) => cI !== index);
                    tmp.splice(index + change, 0, pos);
                    helpers.setValue(tmp);
                  }}
                  onRemove={() => {
                    helpers.setValue(
                      meta.value.filter((_, idx) => idx !== index)
                    );
                  }}
                  index={index}
                  positionAmount={meta.value.length}
                  dtoField={
                    ProjectOfferView.path.positions.at(index) as PropertyPath<
                      any,
                      GroupOfferPositionDTO
                    >
                  }
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        marginTop="1em"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            helpers.setValue([
              ...meta.value,
              {
                name: '',
                unitPriceDescription: null,
                amountDescription: null,
                description: '',
                totalPrice: null,
                positionType: 'scalar',
                amount: 1,
                priceUnitId: null,
                unitPrice: 0
              } as ScalarOfferPositionDTO
            ]);
          }}
        >
          New Position
        </Button>
        <OfferTotalSum />
      </Box>
    </>
  );
}
