"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var ClientContactPhone_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientContactPhone = void 0;
const typeorm_1 = require("typeorm");
const PhoneNumber_1 = require("../../shared/entity/PhoneNumber");
// eslint-disable-next-line import/no-cycle
const ClientContact_1 = require("./ClientContact");
const CircularReference_1 = require("../../serialization/CircularReference");
let ClientContactPhone = ClientContactPhone_1 = class ClientContactPhone extends PhoneNumber_1.PhoneNumber {
    static forContact(clientContact) {
        const contact = new ClientContactPhone_1();
        contact.clientContact = clientContact;
        return contact;
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => ClientContact_1.ClientContact, (contact) => contact.phoneNumbers),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", ClientContact_1.ClientContact)
], ClientContactPhone.prototype, "clientContact", void 0);
ClientContactPhone = ClientContactPhone_1 = __decorate([
    (0, typeorm_1.Entity)()
], ClientContactPhone);
exports.ClientContactPhone = ClientContactPhone;
