"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.priceBoundTransformer = exports.PriceBoundPatchDTO = void 0;
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const PriceBoundDTO_1 = require("./PriceBoundDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const PriceBound_1 = require("../entity/PriceBound");
class PriceBoundPatchDTO extends (0, partial_type_helper_1.PartialType)(PriceBoundDTO_1.PriceBoundDTO) {
}
exports.PriceBoundPatchDTO = PriceBoundPatchDTO;
exports.priceBoundTransformer = (0, DTOTransformer_1.createDTOTransformer)(PriceBound_1.PriceBound, PriceBoundPatchDTO, ['id', 'createdAt', 'updatedAt', 'priceUnit', 'serviceClass', 'serviceStep'], {});
