"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serviceStepPatchTransformer = exports.ServiceStepPatchDTO = void 0;
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const ServiceStepDTO_1 = require("./ServiceStepDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const ServiceStep_1 = require("../entity/ServiceStep");
class ServiceStepPatchDTO extends (0, partial_type_helper_1.PartialType)(ServiceStepDTO_1.ServiceStepDTO) {
}
exports.ServiceStepPatchDTO = ServiceStepPatchDTO;
exports.serviceStepPatchTransformer = (0, DTOTransformer_1.createDTOTransformer)(ServiceStep_1.ServiceStep, ServiceStepPatchDTO, ['id', 'createdAt', 'updatedAt'], {});
