import {
  OrderServiceGroupTask,
  OrderServiceTask,
  OrderStepTask,
  Supplier
} from 'model';
import { useTaskMap } from '../components/tasks/order-task.context';
import { useServiceStep } from '../../services/hooks/service-step.hook';
import { useServiceClass } from '../../services/hooks/service-class.hook';
import { useLanguageIndex } from '../../languages/hooks/language-index.hook';
import { useStatusType } from '../../suppliers/hooks/status-type.hook';

// export function useSupplierAssigneeMatcher(task: OrderStepTask) {}

export interface SupplierAssigneeError {
  message: string;
  critical: boolean;
}

export function useSupplierAssigneeValidator(
  task: OrderStepTask,
  supplier: Supplier | undefined
): SupplierAssigneeError[] | undefined {
  const taskMap = useTaskMap();
  const serviceTask = taskMap[task.parentTaskId as string]
    ?.value as OrderServiceTask;
  const groupTask = taskMap[(serviceTask?.parentTaskId as string) ?? '']
    ?.value as OrderServiceGroupTask;

  const { serviceStep } = useServiceStep(task.serviceStepId);
  const { serviceClass } = useServiceClass(groupTask?.serviceClassId);
  const languageIndex = useLanguageIndex();
  if (!serviceTask || !groupTask) {
    return [];
  }
  const { statusType } = useStatusType(
    supplier ? supplier.status.statusTypeId : null
  );
  const sourceLang = groupTask.sourceLanguageId
    ? languageIndex.descriptors[groupTask.sourceLanguageId]
    : null;
  const targetLang = serviceTask.targetLanguageId
    ? languageIndex.descriptors[serviceTask.targetLanguageId]
    : null;
  if (supplier === undefined) {
    return undefined;
  }
  const result: SupplierAssigneeError[] = [];
  if (
    !supplier.serviceSteps?.find(
      (step) => step.serviceStepId === task.serviceStepId
    )
  ) {
    result.push({
      // eslint-disable-next-line max-len
      message: `Supplier is missing capability for service step ${serviceStep?.name}`,
      critical: true
    });
  }
  if (
    !supplier.services.find(
      (s) => s.serviceClassId === groupTask.serviceClassId
    )
  ) {
    result.push({
      // eslint-disable-next-line max-len
      message: `Supplier is missing capability for service class ${serviceClass?.name}`,
      critical: true
    });
  }
  if (statusType?.statusType !== 'active') {
    result.push({
      message: `${statusType?.description}`,
      critical: true
    });
  }
  if (
    sourceLang &&
    !supplier.languages.find((l) => l.languageId === sourceLang.language.id)
  ) {
    if (
      supplier.languages.find(
        (l) =>
          languageIndex.descriptors[l.languageId].specification.language
            .code === sourceLang.specification.language.code
      )
    ) {
      result.push({
        // eslint-disable-next-line max-len
        message: `Supplier speaks language ${sourceLang.specification.language.code}, but not ${sourceLang.language.languageCode}`,
        critical: false
      });
    } else {
      result.push({
        message: `Supplier does not speak ${sourceLang.language.languageCode}`,
        critical: true
      });
    }
  }
  if (
    targetLang &&
    !supplier.languages.find(
      (l) => l.native && l.languageId === targetLang.language.id
    )
  ) {
    if (
      supplier.languages.find(
        (l) =>
          l.native &&
          languageIndex.descriptors[l.languageId].specification.language
            .code === targetLang.specification.language.code
      )
    ) {
      result.push({
        // eslint-disable-next-line max-len
        message: `Supplier speaks language ${targetLang.specification.language.code}, but not ${targetLang.language.languageCode}`,
        critical: false
      });
    } else {
      result.push({
        message: `Supplier does not speak ${targetLang.language.languageCode}`,
        critical: true
      });
    }
  }
  return result;
}
