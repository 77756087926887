import React from 'react';
import { ClientsTable } from '../components/table/ClientsTable';
import { useClients } from '../hooks/clients.hook';

export function ClientsScreen() {
  const { clients } = useClients();
  return (
    <div>
      <ClientsTable clients={clients} />
    </div>
  );
}
