"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.servicePositionTransformer = exports.ServiceRequestPositionDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const BaseRequestPositionDTO_1 = require("./BaseRequestPositionDTO");
const RequestPositionLanguagesDTO_1 = require("./RequestPositionLanguagesDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const ServiceRequestPosition_1 = require("../entity/ServiceRequestPosition");
const IsNullable_1 = require("../../transformation/IsNullable");
class ServiceRequestPositionDTO extends BaseRequestPositionDTO_1.BaseRequestPositionDTO {
}
__decorate([
    (0, class_validator_1.IsUUID)(),
    __metadata("design:type", String)
], ServiceRequestPositionDTO.prototype, "serviceClassId", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => RequestPositionLanguagesDTO_1.RequestPositionLanguagesDTO),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", RequestPositionLanguagesDTO_1.RequestPositionLanguagesDTO)
], ServiceRequestPositionDTO.prototype, "languages", void 0);
exports.ServiceRequestPositionDTO = ServiceRequestPositionDTO;
exports.servicePositionTransformer = (0, DTOTransformer_1.createDTOTransformer)(ServiceRequestPosition_1.ServiceRequestPosition, ServiceRequestPositionDTO, [
    'id',
    'updatedAt',
    'createdAt',
    'serviceClass',
    'priceUnit',
    'request',
    'requestId',
    'resources',
    'commentIds',
    'comments',
    'requiredPositions'
], 
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
{});
