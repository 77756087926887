import React from 'react';
import { ProjectAcquisitionStatistics } from 'model';
import { parse, subYears } from 'date-fns';
import { TimeBarChartWidget } from '../../components/widgets/TimeBarChartWidget';
import { apiClient } from '../../api/apiClient';

const todayLastYear = subYears(new Date(), 1);
const dateLastYear = [
  todayLastYear.getDate(),
  todayLastYear.getMonth() + 1,
  todayLastYear.getFullYear()
].join('.');

export function ProjectAcquisitionsWidget() {
  return (
    <TimeBarChartWidget
      loadData={() =>
        apiClient
          .get<ProjectAcquisitionStatistics[]>(
            '/projects/project/stats/project-acquisitions-per-month',
            {
              params: {
                from: parse(dateLastYear, 'dd.MM.yyyy', new Date()).toString(),
                to: new Date().toString()
              }
            }
          )
          .then((res) => res.data)
      }
      calculator={(t) => ({
        projects: t.projects.amount,
        firstProjects: t.projects.firstAcquisitionAmount
      })}
      label="Acquired Projects"
      keys={['projects', 'firstProjects']}
    />
  );
}
