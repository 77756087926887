"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfferPosition = void 0;
const typeorm_1 = require("typeorm");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const ProjectOffer_1 = require("./ProjectOffer");
let OfferPosition = class OfferPosition extends JournaledEntity_1.JournaledEntity {
    static isGroupLike(position) {
        return position.positionType !== 'scalar';
    }
    static isGroupPosition(position) {
        return position.positionType === 'group';
    }
    static isOffer(position) {
        return position.positionType === undefined;
    }
    static isScalarPosition(position) {
        return position.positionType === 'scalar';
    }
};
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], OfferPosition.prototype, "positionType", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], OfferPosition.prototype, "name", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], OfferPosition.prototype, "description", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectOffer_1.ProjectOffer),
    __metadata("design:type", ProjectOffer_1.ProjectOffer)
], OfferPosition.prototype, "offer", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sop) => sop.offer),
    __metadata("design:type", String)
], OfferPosition.prototype, "offerId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", Number)
], OfferPosition.prototype, "sortPosition", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'jsonb', nullable: true }),
    __metadata("design:type", Object)
], OfferPosition.prototype, "totalPrice", void 0);
OfferPosition = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.TableInheritance)({ column: { type: 'varchar', name: 'positionType' } })
], OfferPosition);
exports.OfferPosition = OfferPosition;
