import { InitialDataAggregation, ProjectTag } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { projectTagThunk } from './project-tag.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { fetchInitialDataThunk } from '../../../redux/initial-data.thunk';

const { list, delete: deleteThunk, ...thunks } = projectTagThunk;

const projectTagSlice = createEntitySlice<ProjectTag>('project-tag', [
  CustomThunk.override<ProjectTag>(list, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as ProjectTag[]);
    }
  }),
  CustomThunk.override<any>(fetchInitialDataThunk, {
    pending: (_, state) => {
      return state;
    },
    rejected: (_, state) => {
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      const dataPayload = payload as InitialDataAggregation;
      if (dataPayload.projectTags) {
        state.state = state.state === 'fresh' ? 'idle' : state.state;
        state.listFetched = true;
        return adapter.upsertMany(state, dataPayload.projectTags);
      }
      return state;
    }
  }),
  CustomThunk.override<ProjectTag>(
    deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  ...Object.values(thunks)
]);
export const projectTagSelectors = createAdapterSelectors(
  'project-tag',
  projectTagSlice.adapter
);

export const projectTagAPI: EntityAPI<ProjectTag> = {
  selectors: projectTagSelectors,
  thunks: projectTagThunk
};
export default projectTagSlice;
