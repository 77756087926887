import React from 'react';
import { ServiceClass } from 'model';
import { Chip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export function ServiceClassBasicDisplay({ value }: { value: ServiceClass }) {
  const { t } = useTranslation();
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: 10,
          paddingBottom: 10
        }}
      >
        <Typography>{value.name}</Typography>
        <div style={{ paddingLeft: 5 }}>
          <Typography variant="body2">
            <Typography variant="caption" color="textSecondary">
              {t('per')}{' '}
            </Typography>
            {value.defaultPriceUnit?.name}
          </Typography>
        </div>
      </div>
      <Chip label={t(value.type)} />
      <Typography
        variant="body2"
        color="textSecondary"
        style={{ paddingTop: 20 }}
      >
        {value.description}
      </Typography>
    </div>
  );
}
