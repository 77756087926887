import React from 'react';
import { selectUser } from '../redux/auth.slice';
import { useAppSelector } from '../../../redux/redux.hooks';

export function useGrantedPermissions() {
  const loginUser = useAppSelector(selectUser);
  return React.useMemo(() => {
    return loginUser ? loginUser.permissions.map((p) => p.name) : [];
  }, [loginUser]);
}
