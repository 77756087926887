import { JournaledEntity } from 'model';
import {
  BaseTableRow,
  CellAccessorInput,
  ConvertInputMap,
  DataCell,
  DataCellMap,
  InputDataCell
} from './TableDataCell';
import { DataCellRenderer } from './renderer/TableDataRenderer';
import { DateCellRenderer } from './renderer/DateCellRenderer';
import { DefaultCellRenderer } from './renderer/DefaultCellRenderer';
import { BooleanCellRenderer } from './renderer/BooleanCellRenderer';
import { DateFormatType } from '../../localized-formats';

export interface BaseColumnsBuilder<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  columns: ConvertInputMap<T, C>;

  addColumn: <K extends string, V>(
    columnId: K,
    accessor: CellAccessorInput<T, V>,
    label: string,
    config: Omit<InputDataCell<T, V>, 'label' | 'accessor'>
  ) => ColumnsBuilder<T, C & { [k in K]: InputDataCell<T, V> }>;
}
export interface ColumnsBuilder<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> extends BaseColumnsBuilder<T, C> {
  addDefaultColumn: <K extends string, V>(
    columnId: K,
    accessor: CellAccessorInput<T, V>,
    label: string,
    config?: Partial<
      Omit<DataCellRenderer<T, V>, 'filter'> & {
        filter?: DataCellRenderer<T, V>['filter'] | null;
      }
    >,
    columnConfig?: Omit<InputDataCell<T, V>, 'label' | 'accessor'>
  ) => ColumnsBuilder<T, C & { [k in K]: InputDataCell<T, V> }>;

  addHiddenDefaultColumn: <K extends string, V>(
    columnId: K,
    accessor: CellAccessorInput<T, V>,
    label: string,
    config?: Partial<
      Omit<DataCellRenderer<T, V>, 'filter'> & {
        filter?: DataCellRenderer<T, V>['filter'] | null;
      }
    >,
    columnConfig?: Omit<InputDataCell<T, V>, 'label' | 'accessor'>
  ) => ColumnsBuilder<T, C & { [k in K]: InputDataCell<T, V> }>;

  addDateColumn: <K extends string, V extends Date | null | string>(
    columnId: K,
    type: DateFormatType,
    accessor: CellAccessorInput<T, V>,
    label: string,
    nullable?: boolean,
    config?: Omit<InputDataCell<T, V>, 'label' | 'accessor'>
  ) => ColumnsBuilder<T, C & { [k in K]: InputDataCell<T, V> }>;

  addBooleanColumn: <K extends string, V extends boolean | null | undefined>(
    columnId: K,
    accessor: CellAccessorInput<T, V>,
    label: string,
    config?: Omit<InputDataCell<T, V>, 'label' | 'accessor'>
  ) => ColumnsBuilder<T, C & { [k in K]: InputDataCell<T, V> }>;

  addHiddenBooleanColumn: <
    K extends string,
    V extends boolean | null | undefined
  >(
    columnId: K,
    accessor: CellAccessorInput<T, V>,
    label: string,
    config?: Omit<InputDataCell<T, V>, 'label' | 'accessor'>
  ) => ColumnsBuilder<T, C & { [k in K]: InputDataCell<T, V> }>;

  addHiddenDateColumn: <K extends string, V extends Date | null | string>(
    columnId: K,
    type: DateFormatType,
    accessor: CellAccessorInput<T, V>,
    label: string,
    nullable?: boolean,
    config?: Omit<InputDataCell<T, V>, 'label' | 'accessor'>
  ) => ColumnsBuilder<T, C & { [k in K]: InputDataCell<T, V> }>;

  addHiddenColumn: <K extends string, V>(
    columnId: K,
    accessor: CellAccessorInput<T, V>,
    label: string,
    config: Omit<InputDataCell<T, V>, 'label' | 'accessor' | 'hiddenByDefault'>
  ) => ColumnsBuilder<T, C & { [k in K]: InputDataCell<T, V> }>;
}

function enhanceBuilder<T extends BaseTableRow, C extends DataCellMap<T>>(
  base: BaseColumnsBuilder<T, C>
): ColumnsBuilder<T, C> {
  return {
    ...base,
    addDateColumn: (
      columnId,
      type,
      accessor,
      label,
      nullable = false,
      config
    ) => {
      return base.addColumn(columnId, accessor, label, {
        ...config,
        renderer: DateCellRenderer(type, nullable)
      });
    },
    addBooleanColumn: (columnId, accessor, label, config) => {
      return base.addColumn(columnId, accessor, label, {
        ...config,
        renderer: { ...BooleanCellRenderer({}), ...(config?.renderer as any) }
      });
    },
    addHiddenBooleanColumn(columnId, accessor, label, config) {
      return this.addBooleanColumn(columnId, accessor, label, {
        ...config,
        hiddenByDefault: true
      });
    },
    addHiddenDateColumn(
      columnId,
      type,
      accessor,
      label,
      nullable = false,
      config
    ) {
      return this.addDateColumn(columnId, type, accessor, label, nullable, {
        ...config,
        hiddenByDefault: true
      });
    },
    addDefaultColumn: (columnId, accessor, label, config, columnConfig) => {
      return base.addColumn(columnId, accessor, label, {
        ...columnConfig,
        renderer: DefaultCellRenderer(config)
      });
    },
    addHiddenDefaultColumn(columnId, accessor, label, config, columnConfig) {
      return this.addDefaultColumn(columnId, accessor, label, config, {
        ...columnConfig,
        hiddenByDefault: true
      });
    },
    addHiddenColumn: (columnId, accessor, label, config) =>
      base.addColumn(columnId, accessor, label, {
        ...config,
        hiddenByDefault: true
      })
  };
}

function appendToBuilder<
  T extends BaseTableRow,
  C extends DataCellMap<T>,
  K extends string,
  V
>(
  columns: BaseColumnsBuilder<T, C>['columns'],
  columnId: K,
  accessor: CellAccessorInput<T, V>,
  label: string,
  config: Omit<InputDataCell<T, V>, 'label' | 'accessor'>
): BaseColumnsBuilder<T, C & { [k in K]: InputDataCell<T, V> }> {
  return {
    columns: {
      ...columns,
      [columnId]: {
        accessor,
        label,
        ...config
      }
    },
    addColumn<K1 extends string, V1>(
      columnId1: K1,
      accessor1: CellAccessorInput<T, V1>,
      label1: string,
      config1: Omit<InputDataCell<T, V1>, 'label' | 'accessor'>
    ) {
      return enhanceBuilder(
        appendToBuilder(this.columns, columnId1, accessor1, label1, config1)
      );
    }
  };
}

export function columnBuilder<T extends BaseTableRow>(): ColumnsBuilder<
  T,
  // eslint-disable-next-line @typescript-eslint/ban-types
  {}
> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return enhanceBuilder<T, {}>({
    columns: {},
    addColumn<K extends string, V>(
      columnId: K,
      accessor: CellAccessorInput<T, V>,
      label: string,
      config: Omit<InputDataCell<T, V>, 'label' | 'accessor'>
    ) {
      return enhanceBuilder<T, { [k in K]: DataCell<T, V> }>(
        // eslint-disable-next-line @typescript-eslint/ban-types
        appendToBuilder<T, {}, K, V>({}, columnId, accessor, label, config)
      );
    }
  });
}

export function entityColumnBuilder<T extends JournaledEntity>() {
  return columnBuilder<T>()
    .addHiddenColumn(
      'entity_id',
      'id' as CellAccessorInput<T, string>,
      'Id',
      {}
    )
    .addHiddenDateColumn(
      'entity_created_at',
      'date-time',
      'createdAt' as CellAccessorInput<T, Date | string>,
      'Created at'
    )
    .addHiddenDateColumn(
      'entity_updated_at',
      'date-time',
      'updatedAt' as CellAccessorInput<T, Date | string>,
      'Updated at'
    );
}
