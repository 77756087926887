import { permissions, RequestSource } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import {
  GenericTable,
  useCreateCells
} from '../../../../../components/table/GenericTable';
import { ApiState } from '../../../../auth/redux/types';
import { entityColumnBuilder } from '../../../../../components/table/columns.builder';
import { useAppDispatch } from '../../../../../redux/redux.hooks';
import { requestSourceThunk } from '../../../redux/request-sources.thunk';

function buildTableColumns() {
  return entityColumnBuilder<RequestSource>()
    .addDefaultColumn('source_name', 'name', 'Name')
    .addDefaultColumn('source_description', 'description', 'Description')
    .columns;
}

interface RequestSourceTableProps {
  requestSources: RequestSource[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function RequestSourceTable({
  requestSources,
  onRefresh,
  apiState
}: RequestSourceTableProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  const deleteRequestSource = (id: string) => {
    dispatch(requestSourceThunk.delete({ id }));
  };
  return (
    <GenericTable
      label={t('Request Sources')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Request Source',
        onClick: () => history.push(`${url}?type=create#request-source`),
        permission: permissions.project.requestSource.create
      }}
      data={requestSources}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.project.requestSource.edit,
          entry: (source) => ({
            icon: EditIcon,
            label: 'Edit',
            link: `${url}?type=edit#${source.id}`
          })
        },
        {
          permissions: permissions.project.requestSource.delete,
          entry: (sc) => ({
            icon: DeleteIcon,
            label: 'Delete',
            alert: {
              title: 'Delete Request Source',
              content: `${t(
                'You may only delete a request source ' +
                  'if it is not referenced anymore. ' +
                  'Otherwise an error will occur. ' +
                  'Are you sure you want to delete request source '
              )} "${sc.name}"?`,
              onConfirm: () => {
                deleteRequestSource(sc.id);
              }
            }
          })
        }
      ]}
      toolbarActions={[]}
    />
  );
}
