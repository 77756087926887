import React from 'react';
import { useTranslation } from 'react-i18next';
import { patchSupplierPriceTransformer, SupplierPriceDTO } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { NoOp } from '../../../../utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/redux.hooks';
import { supplierPricesThunk } from '../../redux/supplier-prices.thunk';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import supplierPricesSlice, {
  supplierPricesSelectors
} from '../../redux/supplier-price.slice';
import { SupplierPriceForm, supplierPriceView } from './SupplierPriceForm';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { useSupplier } from '../../hooks/supplier.hook';

const initialValues: SupplierPriceDTO = {
  priceUnitId: '',
  serviceStepId: null,
  serviceClassId: null,
  price: {
    expressPrice: null,
    currency: null,
    price: null as unknown as number
  },
  sourceLanguageId: null,
  targetLanguageId: null,
  customConstraint: null,
  description: null
};

export function SupplierPriceFormDialog({
  supplierId
}: {
  supplierId: string;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { type, reference } = useEditorLocation();
  const { supplier } = useSupplier(supplierId);
  const supplierPrice = useAppSelector((state) =>
    supplierPricesSelectors.selectById(state, reference ?? '')
  );

  if (!reference || (type === 'edit' && !supplierPrice)) {
    return <></>;
  }

  const createPrice = (input: SupplierPriceDTO) =>
    dispatch(supplierPricesThunk.create({ supplierId, input }))
      .then(unwrapResult)
      .catch(NoOp);

  const updatePrice = (input: SupplierPriceDTO) =>
    dispatch(
      supplierPricesThunk.update({
        supplierId,
        id: supplierPrice?.id || '',
        input
      })
    )
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <DialogForm
      identifier={type === 'edit' ? reference : 'price'}
      label={
        type === 'edit' ? t('Edit Supplier Price') : t('New Supplier Price')
      }
      form={{
        initialValues:
          type === 'edit' && supplierPrice
            ? patchSupplierPriceTransformer(supplierPrice)
            : initialValues,
        validationSchema: supplierPriceView.validationSchema
      }}
      api={{
        clearError: supplierPricesSlice.actions.clearError,
        errorSelector: supplierPricesSelectors.selectError,
        onSubmit: type === 'edit' && supplierPrice ? updatePrice : createPrice,
        stateSelector: supplierPricesSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      {supplier && <SupplierPriceForm supplier={supplier} />}
    </DialogForm>
  );
}
