import React from 'react';
import { OrderStepTask, OrderTask, TemplateScope } from 'model';
import { Button, Divider, Typography } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PlusIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { StepTaskCard } from './StepTaskCard';
import { ServiceStepSelectPopover } from './ServiceStepSelectPopover';

export function taskEditorId(
  phase: 'preparation' | 'provision' | 'post-delivery',
  parentTask?: OrderTask
) {
  return `${parentTask?.id ?? 'project'}-${phase}-task`;
}

export function PhaseStepTaskContainer({
  tasks,
  phase,
  scope,
  projectId,
  parentTask
}: {
  tasks: OrderStepTask[];
  phase: 'preparation' | 'post-delivery';
  scope: TemplateScope;
  projectId: string;
  parentTask?: OrderTask;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <div>
      {phase === 'post-delivery' && (
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      )}
      <div style={{ display: 'flex', marginBottom: 10 }}>
        <Typography
          variant="h5"
          color="textSecondary"
          style={{ paddingRight: '20%' }}
        >
          {t(phase)}
          {phase === 'preparation' && '\u00A0\u00A0'}
        </Typography>

        <ServiceStepSelectPopover
          parentTask={parentTask}
          projectId={projectId}
          scope={scope}
          phase={phase}
          onInput={(step) => {
            if (step)
              history.push(
                // eslint-disable-next-line max-len
                `${url}?type=create&serviceStepId=${step.id}#${taskEditorId(
                  phase,
                  parentTask
                )}`
              );
          }}
          /* filter={(v) => v.scopes[scope2]?.phase === scope} */
          filter={() => true}
          button={({ onClick }) => (
            <Button
              startIcon={<PlusIcon edgeMode="start" />}
              size="small"
              style={{
                color: 'grey'
              }}
              onClick={(e) => onClick(e.target as any)}
            >
              {t('Task')}
            </Button>
          )}
        />
      </div>
      <div style={{ paddingLeft: 20 }}>
        {tasks.map((task) => {
          return (
            <StepTaskCard projectId={projectId} stepTask={task} key={task.id} />
          );
        })}
      </div>
      {phase === 'preparation' && (
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      )}
    </div>
  );
}
