import React from 'react';
import { permissions, Supplier } from 'model';
import { useParams } from 'react-router-dom';
import { Divider, Grid } from '@material-ui/core';
import {
  EntityDetails,
  TabDefinition
} from '../../../components/details/EntityDetails';
import { useSupplier } from '../hooks/supplier.hook';
import { SupplierProfile } from '../components/SupplierProfile';
import { SupplierAddresses } from '../components/contacts/SupplierAddresses';
import { SupplierPhones } from '../components/contacts/SupplierPhones';
import { SupplierAttachments } from '../components/attachments/SupplierAttachments';
import { LspAssignmentCard } from '../components/lspAssignment/LspAssignmentCard';
import { SupplierTests } from '../components/tests/SupplierTests';
import { SupplierPrices } from '../components/prices/SupplierPrices';
import { SupplierProjectAssignmentCard } from '../components/projectAssignment/SupplierProjectAssignmentCard';
import { RecruitmentsTable } from '../components/RecruitmentsTable';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { listSupplierRecruimentsThunk } from '../redux/supplier-recruitments.thunk';
import { recruitmentsSelectors } from '../redux/supplier-recruitments.slice';

function SupplierProjectsTab({ supplier }: { supplier: Supplier }) {
  const dispatch = useAppDispatch();

  const refreshRecruitments = () => {
    dispatch(listSupplierRecruimentsThunk({ supplierId: supplier.id }));
  };
  const data = useAppSelector((rS) =>
    recruitmentsSelectors.selectById(rS, supplier.id)
  );

  React.useEffect(() => {
    if (data?.apiState !== 'loading') {
      refreshRecruitments();
    }
  }, []);

  return (
    <Grid container xs={12} spacing={2}>
      <Grid item xs={12}>
        <LspAssignmentCard supplier={supplier} />
      </Grid>
      <Grid item xs={12}>
        <SupplierProjectAssignmentCard supplier={supplier} />
      </Grid>
      <Grid item xs={12}>
        <RecruitmentsTable supplier={supplier} />
      </Grid>
    </Grid>
  );
}

const tabs: TabDefinition<Supplier>[] = [
  {
    name: 'profile',
    subPaths: [''],
    component: (s: Supplier) => <SupplierProfile supplier={s} />
  },
  {
    name: 'projects',
    subPaths: ['/projects'],
    component: (s) => <SupplierProjectsTab supplier={s} />
  },
  {
    name: 'prices',
    subPaths: ['/prices'],
    component: (s: Supplier) => <SupplierPrices supplier={s} />,
    permission: permissions.suppliers.supplier.prices.tab
  },
  {
    name: 'Tests',
    subPaths: ['/tests'],
    component: (s) => <SupplierTests supplier={s} />
  },
  {
    name: 'Documents',
    subPaths: ['/attachments'],
    component: (s) => <SupplierAttachments supplier={s} />,
    permission: permissions.suppliers.supplier.documents.tab
  },
  {
    name: 'Contact Details',
    subPaths: ['/contacts'],
    component: (s: Supplier) => (
      <>
        <SupplierAddresses supplier={s} />
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        <SupplierPhones supplier={s} />
      </>
    )
  }
];

export function SupplierDetailsScreen() {
  const { supplierId } = useParams<{ supplierId: string }>();
  const { supplier } = useSupplier(supplierId);

  return (
    <EntityDetails
      entityName="Supplier"
      title={(s) => `${s.name.first} ${s.name.last}`}
      tabs={tabs}
      entity={supplier}
      /* renderComments={(s) => <SupplierCommentList supplierId={s.id} />} */
    />
  );
}
