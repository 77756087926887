import { InitialDataAggregation, ResourceTranslation } from 'model';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../redux/enitySlice';
import { listTranslationsThunk } from './translations.thunk';
import { RootState } from '../../redux/store';
import { fetchInitialDataThunk } from '../../redux/initial-data.thunk';

const translationsSlice = createEntitySlice<ResourceTranslation>(
  'translations',
  [
    CustomThunk.override<ResourceTranslation>(listTranslationsThunk, {
      pending: (_, state) => {
        state.state = 'pending';
        state.listFetched = undefined;
        state.error = null;
        return state;
      },
      rejected: (_, state, action) => {
        state.state = 'idle';
        state.listFetched = false;
        state.error = (action as any).error;
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        state.listFetched = true;
        return adapter.upsertMany(state, payload as ResourceTranslation[]);
      }
    }),
    CustomThunk.override<any>(fetchInitialDataThunk, {
      pending: (_, state) => {
        return state;
      },
      rejected: (_, state) => {
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        const dataPayload = payload as InitialDataAggregation;
        if (dataPayload.translations) {
          state.state = state.state === 'fresh' ? 'idle' : state.state;
          state.listFetched = true;
          return adapter.upsertMany(state, dataPayload.translations);
        }
        return state;
      }
    })
  ]
);
export const translationsSelectors = {
  ...createAdapterSelectors('translations', translationsSlice.adapter),
  selectUntranslated: (rState: RootState) =>
    translationsSelectors
      .selectAll(rState)
      .filter((t) => t.translation === null)
};

/* export const serviceClassAPI: EntityAPI<ServiceClass> = {
    selectors: translationsSelectors,
    thunks: translationsThunk
}; */
export default translationsSlice;
