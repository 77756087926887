"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientPrice = void 0;
const typeorm_1 = require("typeorm");
const PriceConfiguration_1 = require("../../prices/entity/PriceConfiguration");
const CircularReference_1 = require("../../serialization/CircularReference");
// eslint-disable-next-line import/no-cycle
const Client_1 = require("./Client");
let ClientPrice = class ClientPrice extends PriceConfiguration_1.PriceConfiguration {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => Client_1.Client, (c) => c.prices),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", Client_1.Client)
], ClientPrice.prototype, "client", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sp) => sp.client),
    __metadata("design:type", String)
], ClientPrice.prototype, "clientId", void 0);
ClientPrice = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], ClientPrice);
exports.ClientPrice = ClientPrice;
