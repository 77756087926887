"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileAccessDTO = void 0;
const class_validator_1 = require("class-validator");
class FileAccessDTO {
    constructor(partial) {
        Object.assign(this, partial);
    }
    static createUnsigned(encoder, fileId, permission, ref) {
        const tokenString = JSON.stringify({ ...permission.createGrant(ref) });
        return new FileAccessDTO({
            token: encoder(JSON.stringify({ token: encoder(tokenString), signed: false })),
            fileAccessId: fileId
        });
    }
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsUUID)(),
    __metadata("design:type", String)
], FileAccessDTO.prototype, "fileAccessId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], FileAccessDTO.prototype, "token", void 0);
exports.FileAccessDTO = FileAccessDTO;
