import React from 'react';
import { permissions, Supplier, supplierPatchTransformer } from 'model';
import { useTranslation } from 'react-i18next';
import { useSupplierUpdate } from '../../hooks/supplier-update.hook';
import { LspAssignmentsTable } from './LspAssignmentsTable';
import { LspAssignmentForm, LspAssignmentView } from './LspAssignmentForm';
import { NewEditableCard } from '../../../../components/card/editableCard/NewEditableCard';

export function LspAssignmentCard({ supplier }: { supplier: Supplier }) {
  const { t } = useTranslation();

  const updateSupplier = useSupplierUpdate(supplier.id);

  return (
    <NewEditableCard
      identifier="large-scale-projects"
      title={t('Large Scale Project Assignments')}
      value={supplier}
      display={{
        component: LspAssignmentsTable
      }}
      form={{
        component: LspAssignmentForm,
        dtoTransformer: supplierPatchTransformer,
        validationSchema: LspAssignmentView.validationSchema,
        viewFactory: LspAssignmentView.viewFactory,
        onSubmit: updateSupplier,
        permissions: permissions.suppliers.supplier.edit
      }}
    />
  );
}
