import {
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { Timer } from '@material-ui/icons';
import { setHours, setMinutes } from 'date-fns';
import {
  PropertyPath,
  useTypedField
} from '../../../transformer/DTOViewSchema';
import { formatDate } from '../../../localized-formats';

interface FormikDateTimePickerProps {
  name: string | PropertyPath<any, Date | null>;
  label?: string;
  fullWidth?: boolean;
  small?: boolean;
  timeOptional?: boolean;
}

export function FormikDateTimePicker({
  name,
  label,
  fullWidth = true,
  small,
  timeOptional = false
}: FormikDateTimePickerProps) {
  const [, meta, helpers] = useTypedField<Date | null>(name);
  const [withTime, setWithTime] = React.useState(!timeOptional);

  return (
    <Box display="flex" alignItems="flex-end">
      {!withTime ? (
        <>
          <KeyboardDatePicker
            style={small ? { width: 140 } : undefined}
            fullWidth={fullWidth}
            disableToolbar
            variant="inline"
            format="dd.MM.yyyy"
            autoOk
            id="date-picker-inline"
            label={label}
            value={meta.value ?? null}
            error={meta.touched && Boolean(meta.error)}
            onChange={(date) => {
              let realDate: Date | null | undefined = date;
              if (realDate) {
                try {
                  if (!(realDate instanceof Date)) {
                    realDate = new Date(realDate);
                  }
                  realDate = setMinutes(setHours(new Date(realDate), 17), 0);
                } catch (e: any) {
                  realDate = null;
                }
              }
              helpers.setValue(realDate ?? null);
            }}
          />
        </>
      ) : (
        <>
          <KeyboardDateTimePicker
            style={small ? { width: 140 } : undefined}
            fullWidth={fullWidth}
            disableToolbar
            variant="inline"
            format="dd.MM.yyyy HH:mm"
            autoOk
            id="date-picker-inline"
            label={label}
            value={meta.value ?? null}
            error={meta.touched && Boolean(meta.error)}
            onChange={(date) => helpers.setValue(date)}
          />
        </>
      )}
      {timeOptional && (
        <Box display="flex" paddingLeft="0.5em" flexDirection="column">
          {!withTime && (
            <Typography variant="caption">
              {!!meta.value && formatDate(meta.value, 'time')}
            </Typography>
          )}
          <ToggleButton
            style={{ padding: '0.1em' }}
            value="check"
            size="small"
            selected={withTime}
            onChange={() => {
              let realDate: Date | null | undefined = meta.value;
              if (realDate) {
                try {
                  if (!(realDate instanceof Date)) {
                    realDate = new Date(realDate);
                  }
                  realDate = setMinutes(setHours(new Date(realDate), 17), 0);
                } catch (e: any) {
                  realDate = null;
                }
              }
              setWithTime(!withTime);
              if (withTime && meta.value !== null) {
                helpers.setValue(realDate);
              }
            }}
          >
            <Timer fontSize="small" />
          </ToggleButton>
        </Box>
      )}
    </Box>
  );
}
