import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { LoginFormInterface, loginThunk } from '../redux/actions/login.thunk';
import { selectAuthState } from '../redux/auth.slice';

export function useLogin() {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);

  const history = useHistory();
  const location = useLocation<{ from: string }>();

  const { from } = location.state || { from: { pathname: '/' } };

  return {
    authState,
    login: (form: LoginFormInterface) => {
      return dispatch(loginThunk(form))
        .then((res) => {
          history.replace(from);
          return res;
        })
        .catch((err) => {
          throw err;
        });
    }
  };
}
