import { permissions, ServiceStep, ServiceStepAssigneeMap } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { Edit as EditIcon } from '@material-ui/icons';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { ApiState } from '../../auth/redux/types';
import { entityColumnBuilder } from '../../../components/table/columns.builder';

function buildTableColumns() {
  return entityColumnBuilder<ServiceStep>()
    .addDefaultColumn('step_name', 'name', 'Name')
    .addDefaultColumn('step_description', 'description', 'Description')
    .addDefaultColumn('step_display_format', 'displayFormat', 'Display Format')
    .addBooleanColumn('step_priceable', 'priceable', 'Priceable')
    .addBooleanColumn('step_default_step', 'defaultStep', 'Default Step')
    .addColumn('step_assignee_types', 'assigneeTypes', 'Assignee Types', {
      renderer: {
        createElement: (value: ServiceStepAssigneeMap) => (
          /* TODO für manu: hier vllt. auch required prop mit anzeigen */
          <span>{Object.keys(value)?.join(', ') ?? 'All'}</span>
        ),
        createText: (value: ServiceStepAssigneeMap) => [
          Object.keys(value).join(', ')
        ]
      }
    }).columns;
}

interface ServiceStepTableProps {
  serviceSteps: ServiceStep[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function ServiceStepTable({
  serviceSteps,
  onRefresh,
  apiState
}: ServiceStepTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);

  return (
    <GenericTable
      label={t('Service Steps')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Service Step',
        onClick: () => history.push(`${url}?type=create#service-step`),
        permission: permissions.services.serviceStep.create
      }}
      data={serviceSteps}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.services.serviceStep.edit,
          entry: (step) => ({
            icon: EditIcon,
            label: 'Edit',
            link: `${url}?type=edit#${step.id}`
          })
        }
      ]}
      toolbarActions={[]}
    />
  );
}
