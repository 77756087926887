import React, { useEffect } from 'react';
import { permissions, ProjectRequest } from 'model';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  EntityDetails,
  TabDefinition
} from '../../../../components/details/EntityDetails';
import { useProjectRequest } from '../../hooks/project-request.hook';
import { ProjectRequestOverview } from './ProjectRequestOverview';
import { projectRequestThunk } from '../../redux/project-requests.thunk';
import { NewProjectRequestOffers } from './offers/NewProjectRequestOffers';
import { useProject } from '../../hooks/project.hook';

const tabs: TabDefinition<ProjectRequest>[] = [
  {
    name: 'overview',
    subPaths: [''],
    component: (p: ProjectRequest) => <ProjectRequestOverview request={p} />
  },
  {
    name: 'offers',
    subPaths: ['/offers'],
    component: (p: ProjectRequest) => <NewProjectRequestOffers request={p} />,
    permission: permissions.project.projectRequest.offers.tabs
  }
];

export function ProjectRequestDetails() {
  const { projectRequestId } = useParams<{ projectRequestId: string }>();
  const { projectRequest } = useProjectRequest(projectRequestId);
  const { project } = useProject(projectRequest?.project.id ?? '');
  const dispatch = useDispatch();
  useEffect(() => {
    if (projectRequest)
      dispatch(projectRequestThunk.get({ id: projectRequest.id }));
  }, [projectRequest?.id]);
  return (
    <EntityDetails
      entityName="Request"
      title={() => <>Request - {project?.projectName || ''}</>}
      tabs={tabs}
      entity={projectRequest}
    />
  );
}
