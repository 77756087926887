import { BaseEntity } from 'model';
import React from 'react';
import { BaseTableRow } from '../TableDataCell';
import { ColumnFilter } from './column-filter.types';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { EntityReferenceSelect } from '../../select/EntityReferenceSelect';

export function EntityColumnFilter<
  T extends BaseTableRow,
  E extends BaseEntity,
  V
>(
  entityAPI: EntityAPI<E>,
  optionsLabel: (data: E) => string,
  entityMapper: (v: V) => string[],
  overlayOnFocus?: boolean
): ColumnFilter<T, V, string | undefined> {
  return {
    overlayOnFocus,
    initialState: undefined,
    createFilter: (cell, state) => {
      return state === undefined
        ? () => true
        : (value: V) => {
            const dataVals = entityMapper(value);
            return dataVals.length > 0
              ? dataVals.some((i) => i === state)
              : false;
          };
    },
    // eslint-disable-next-line react/prop-types
    component: ({ filterState, onFilterChange }) => (
      <EntityReferenceSelect<E, E['id'] | null>
        style={{ width: '100%' }}
        multiple={false}
        entity={false}
        currentValue={filterState ?? null}
        entityApi={entityAPI}
        optionLabel={optionsLabel}
        selectValue={(v) => onFilterChange(v ?? undefined)}
      />
    ),
    isEffective: (cell, state) => state !== undefined
  };
}
