import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import {
  textComponentStyleOverrides,
  typographyOptions
} from './typographyOptions';

export const plainDarkTheme: ThemeOptions = {
  palette: {
    type: 'dark',
    // background: {
    //   default: '#232323',
    //   paper: 'rgba(255, 255, 255, 0.12)'
    // },
    error: {
      main: '#CF6679',
      contrastText: '#FFFFFF'
    },
    primary: {
      main: '#FF7460',
      contrastText: '#FFFFFF'
    },
    action: {
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      focus: 'rgba(0, 0, 0, 0.12)'
    },
    secondary: {
      '900': '#212121',
      '800': '#424242',
      '700': '#616161',
      '600': '#9E9E9E',
      '500': '#9E9E9E',
      '400': '#BDBDBD',
      '300': '#EEEEEE',
      '200': '#A0A0A0',
      '100': '#EEEEEE',
      '50': '#FAFAFA',
      A400: '#BDBDBD',
      contrastText: '#FFFFFF'
    }
  },
  spacing: 10,
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#303030',
        zIndex: 2
      }
    },
    ...textComponentStyleOverrides
  },
  typography: typographyOptions
};

export const darkTheme = createMuiTheme(plainDarkTheme);
