import React from 'react';
import { DocumentTypesTable } from '../components/DocumentTypesTable';
import { useDocumentTypes } from '../hooks/document-types.hook';

export function DocumentTypeScreen() {
  const { entities, apiState, listEntities } = useDocumentTypes();
  return (
    <DocumentTypesTable
      documentTypes={entities}
      onRefresh={listEntities}
      apiState={apiState}
    />
  );
}
