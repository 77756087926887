import React from 'react';
import { CountryCodeMap, CountryArea, RegionCodeMap } from 'model';

export function formatCountryLabel(
  country: string | CountryArea,
  withFlag = true
) {
  const countryData =
    typeof country === 'string'
      ? CountryCodeMap[country] ?? RegionCodeMap[country]
      : country;
  return `${withFlag ? countryData.flag : ''} ${countryData.name} (${
    countryData.shortCode
  })`;
}
export interface CountryLabelProps {
  country: string | CountryArea;
  withFlag?: boolean;
  withCode?: boolean;
}

export function CountryLabel({
  country,
  withFlag = true,
  withCode = true
}: CountryLabelProps) {
  const countryData =
    typeof country === 'string' ? CountryCodeMap[country] : country;
  return (
    <div>
      {withFlag && <span>{countryData.flag}</span>} {countryData.name}{' '}
      {withCode && `(${countryData.shortCode})`}
    </div>
  );
}
