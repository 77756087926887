import { UserProjectTemplate } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const listUserProjectTemplatesThunk = createErrorHandlingThunk<
  UserProjectTemplate[],
  { userId: string }
>('user-project-template/list', async (arg) => {
  const result = await apiClient.get<UserProjectTemplate[]>(
    `/user/${arg.userId}/project-templates`
  );
  return result.data;
});

export const markUserProjectTemplateThunk = createErrorHandlingThunk<
  UserProjectTemplate,
  { userId: string; projectId: string }
>('user-project-template/mark', async (arg) => {
  const result = await apiClient.put<UserProjectTemplate>(
    `/user/${arg.userId}/project-templates/${arg.projectId}`
  );
  return result.data;
});

export const removeUserProjectTemplateThunk = createErrorHandlingThunk<
  UserProjectTemplate,
  { userId: string; projectId: string }
>('user-project-template/remove', async (arg) => {
  const result = await apiClient.delete<UserProjectTemplate>(
    `/user/${arg.userId}/project-templates/${arg.projectId}`
  );
  return result.data;
});
