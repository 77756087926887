import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../redux/redux.hooks';
import { clientPricesSelectors } from '../redux/client-prices.slice';

export function useClientPrices(clientId: string) {
  const prices = useAppSelector(
    (state) => clientPricesSelectors.selectByClient(state, clientId),
    shallowEqual
  );
  return { prices };
}
