import React from 'react';
import { shallowEqual } from 'react-redux';
import { useEntityAPI } from '../../../redux/entity-api.hook';
import { listOpenSelector, projectAPI } from '../redux/projects.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { listOpenProjectsThunk } from '../redux/projects.thunk';

export function useProjects() {
  return useEntityAPI(projectAPI);
}
const openQuerySelector = projectAPI.selectors.selectSubQuery('open');
export function useOpenProjects(pmIds: string[], forceReload = false) {
  const dispatch = useAppDispatch();
  const entities = useAppSelector(listOpenSelector, shallowEqual);
  const queryState = useAppSelector(openQuerySelector, shallowEqual);
  const apiState = useAppSelector(projectAPI.selectors.selectState);
  const apiError = useAppSelector(projectAPI.selectors.selectError);
  const listFetched = useAppSelector(projectAPI.selectors.selectListFetched);

  const listEntities = React.useCallback(
    () => dispatch(listOpenProjectsThunk({ pmIds })),
    [pmIds]
  );

  React.useEffect(() => {
    if (
      forceReload ||
      (pmIds.length > 0 &&
        apiState !== 'unauthorized' &&
        apiState !== 'pending' &&
        (apiState === 'fresh' || listFetched === false) &&
        (queryState === 'fresh' || queryState === undefined) &&
        !apiError)
    ) {
      listEntities();
    }
  }, [apiState, listEntities]);

  return React.useMemo(
    () => ({ entities, apiState, listEntities, listFetched }),
    [entities, apiState, listEntities, listFetched]
  );
}
