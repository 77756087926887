import {
  SupplierAttachment,
  WrapAttachmentDTO,
  WrapAttachmentPatchDTO
} from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const supplierAttachmentsThunk = createFeatureThunks<
  SupplierAttachment,
  WrapAttachmentDTO,
  WrapAttachmentPatchDTO,
  { supplierId: string }
>({
  urlFactory: ({ supplierId, id } = { supplierId: '', id: undefined }) =>
    `/suppliers/supplier/${supplierId}/attachments${
      id !== undefined ? `/${id}` : ''
    }`,
  thunkName: 'suppliers-attachments'
});
