import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikTextField } from '../../../components/input/FormikTextField';

interface ClientProfileFormProps {
  fieldBasePath?: string;
}

export function ClientProfileForm(props: ClientProfileFormProps) {
  const { t } = useTranslation();

  const getFieldName = (originalName: string) =>
    props.fieldBasePath
      ? `${props.fieldBasePath}.${originalName}`
      : originalName;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormikTextField
          required
          label={t('Client Name')}
          name={getFieldName('name')}
        />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField label={t('Tax ID')} name={getFieldName('taxId')} />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField label={t('CEO')} name={getFieldName('ceo')} />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField label={t('E-Mail')} name={getFieldName('email')} />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField label={t('Phone')} name={getFieldName('phone')} />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          label={t('Description')}
          name="description"
          multiline
          rows={3}
        />
      </Grid>
    </Grid>
  );
}
