import React from 'react';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { CancelOutlined, Search } from '@material-ui/icons';
import { UserProjectTemplate } from 'model';
import { CgTrash } from 'react-icons/cg';
import { useUserProjectTemplateState } from '../../../users/hooks/user-project-templates.hook';
import { useProject } from '../../hooks/project.hook';
import { ProjectLink } from '../basic/ProjectLink';

function ProjectTemplateItem({
  projectId,
  search,
  hideDelete
}: {
  projectId: string;
  search: string | undefined;
  hideDelete: boolean;
}) {
  const { project } = useProject(projectId);
  const templateState = useUserProjectTemplateState(projectId);
  const history = useHistory();
  const { t } = useTranslation();
  if (!project) {
    return <Skeleton />;
  }
  return !search || project.projectName.toLowerCase().includes(search) ? (
    <ListItem>
      <ListItemText
        primary={<ProjectLink value={project} format="name" />}
        style={{ overflow: 'hidden' }}
      />
      <ListItemSecondaryAction>
        <IconButton
          title={t('Duplicate this template')}
          onClick={() => {
            history.push(
              // eslint-disable-next-line max-len
              `/projects/project-requests?type=duplicate#${project.id}`
            );
          }}
          size="small"
        >
          <HiOutlineDocumentDuplicate />
        </IconButton>
        {!!templateState && !hideDelete && (
          <IconButton
            title={t('Remove from templates')}
            onClick={() => {
              templateState?.toggle();
            }}
            size="small"
          >
            <CgTrash />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  ) : (
    <></>
  );
}

function ProjectTemplateSearchBar({
  search,
  setSearch
}: {
  search: string | undefined;
  setSearch: (s: string | undefined) => void;
}) {
  const { t } = useTranslation();
  return (
    <TextField
      InputProps={{
        startAdornment: <Search fontSize="small" />,
        endAdornment: !!search && (
          <IconButton
            size="small"
            title={t('Reset search')}
            onClick={() => setSearch(undefined)}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
        )
      }}
      value={search ?? ''}
      onChange={(evt) => setSearch(evt.target.value || undefined)}
      placeholder="Search..."
    />
  );
}

export function ProjectTemplateList({
  templates,
  hideDelete = false
}: {
  templates: UserProjectTemplate[];
  hideDelete?: boolean;
}) {
  const [search, setSearch] = React.useState<string>();
  const lowerCaseSearch = search?.toLowerCase();
  return (
    <Box display="flex" width="100%" flexDirection="column">
      <ProjectTemplateSearchBar search={search} setSearch={setSearch} />
      <List dense>
        {templates.map((template) => (
          <ProjectTemplateItem
            search={lowerCaseSearch}
            projectId={template.projectId}
            hideDelete={hideDelete}
          />
        ))}
      </List>
    </Box>
  );
}
