import React from 'react';
import {
  Client,
  GroupOfferPosition,
  GroupOfferPositionDTO,
  OfferPosition,
  ProjectOffer,
  ProjectOfferDTO,
  ScalarOfferPosition,
  ScalarOfferPositionDTO,
  User
} from 'model';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { groupBy } from 'lodash';
import { StyledLink } from '../../../../../../components/input/link/StyledLink';
import { AddressDisplay } from '../../../../../../components/address/AddressDisplay';
import { FormikDatePicker } from '../../../../../../components/input/date/FormikDatePicker';
import { ProjectOfferView } from '../ProjectOfferForm';
import { useAppDispatch } from '../../../../../../redux/redux.hooks';
import { updateProjectOfferThunk } from '../../../../redux/project-offers.thunk';
import { FormikCurrencySelect } from '../../../../../../components/select/FormikCurrencySelect';
import { OfferPositionsForm } from '../OfferPositionsForm';
import { useUser } from '../../../../../users/hooks/user.hook';

export function groupOfferPositions(offer: ProjectOffer | undefined) {
  if (!offer) {
    return [];
  }
  const v = offer.positions;
  if (!v) {
    return [];
  }
  const parentMap = groupBy(
    [...v].sort((a, b) => a.sortPosition - b.sortPosition),
    (p) =>
      (OfferPosition.isScalarPosition(p)
        ? (p as ScalarOfferPosition).parentPositionId
        : undefined) ?? ''
  );
  return (parentMap[''] ?? []).map((pos) => {
    if (pos.positionType === 'scalar') {
      return {
        id: pos.id,
        name: pos.name,
        description: pos.description,
        amountDescription: (pos as ScalarOfferPosition).amountDescription,
        unitPriceDescription: (pos as ScalarOfferPosition).unitPriceDescription,
        positionType: 'scalar',
        priceUnitId: (pos as ScalarOfferPosition).priceUnitId,
        amount: (pos as ScalarOfferPosition).amount,
        totalPrice: (pos as ScalarOfferPosition).totalPrice?.amount ?? null,
        unitPrice: (pos as ScalarOfferPosition).unitPrice.amount
      } as ScalarOfferPositionDTO;
    }
    return {
      id: pos.id,
      positionType: 'group',
      name: pos.name,
      description: pos.description,
      totalPrice: (pos as GroupOfferPosition).totalPrice?.amount ?? null,
      positions: (parentMap[pos.id] ?? []).map((subPos) => {
        if (subPos.positionType === 'scalar') {
          return {
            id: subPos.id,
            name: subPos.name,
            description: subPos.description,
            positionType: 'scalar',
            amountDescription: (pos as ScalarOfferPosition).amountDescription,
            unitPriceDescription: (pos as ScalarOfferPosition)
              .unitPriceDescription,
            priceUnitId: (subPos as ScalarOfferPosition).priceUnitId,
            amount: (subPos as ScalarOfferPosition).amount,
            totalPrice:
              (subPos as ScalarOfferPosition).totalPrice?.amount ?? null,
            unitPrice: (subPos as ScalarOfferPosition).unitPrice.amount
          } as ScalarOfferPositionDTO;
        }
        throw new Error('invalid position state');
      })
    } as GroupOfferPositionDTO;
  });
}

export function OfferDocumentBaseForm({
  submitForm,
  author,
  offerNr,
  client
}: {
  submitForm?: any;
  author?: User;
  offerNr?: number;
  client: Client;
}) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Form>
      <Card style={{ position: 'relative' }}>
        {submitForm && (
          <div style={{ position: 'absolute', top: 10, right: 10 }}>
            <IconButton
              size="small"
              onClick={() =>
                submitForm().then(() => history.replace({ search: undefined }))
              }
            >
              <SaveIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => history.replace({ search: undefined })}
            >
              <CancelIcon fontSize="small" />
            </IconButton>
          </div>
        )}
        <CardContent>
          <Typography variant="subtitle2">
            <StyledLink to={`/clients/${client.id}`}>{client.name}</StyledLink>
          </Typography>
          <Typography variant="caption">{client.ceo}</Typography>
          {client.invoiceAddress && (
            <Typography variant="caption">
              <AddressDisplay
                withoutComment
                withoutCountry
                address={client.invoiceAddress}
              />
            </Typography>
          )}
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            paddingTop="30px"
            paddingBottom="5px"
          >
            <Typography variant="h5">
              {offerNr !== undefined
                ? `${t('Offer Nr.')} ${offerNr}`
                : t('New Offer')}
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <div>
                <Typography variant="subtitle2">{t('Currency')}</Typography>
                <FormikCurrencySelect name={ProjectOfferView.path.currency} />
              </div>
              <div style={{ paddingLeft: 60 }}>
                <Typography variant="subtitle2">{t('Offer Date')}</Typography>
                <FormikDatePicker
                  name={ProjectOfferView.path.offerDate}
                  small
                />
              </div>
              <div style={{ paddingLeft: 60 }}>
                <Typography variant="subtitle2">{t('valid until')}</Typography>
                <FormikDatePicker
                  name={ProjectOfferView.path.validUntil}
                  small
                />
              </div>
            </div>
          </Box>
          <Grid item xs={12}>
            <OfferPositionsForm />
          </Grid>
          {author && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 30,
                alignItems: 'flex-end'
              }}
            >
              <Typography variant="caption" color="textSecondary">
                {t('Author')}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                <StyledLink to={`/users/accounts/${author.id}`} variant="body1">
                  {author.displayName ?? author.email}
                </StyledLink>
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    </Form>
  );
}

export function EditCurrentOfferDocumentForm({
  offerNr,
  offer,
  client
}: {
  client: Client;
  offer?: ProjectOffer;
  offerNr: number;
}) {
  const dispatch = useAppDispatch();
  const dtoPositions = React.useMemo(
    () => groupOfferPositions(offer),
    [offer, offer?.positions]
  );
  const { user: author } = useUser(offer?.authorId);
  return (
    <>
      <Formik
        initialValues={
          offer
            ? ({
                offerDate: offer.offerDate,
                validUntil: offer.validUntil,
                totalPrice: offer.totalPrice?.amount ?? null,
                currency: offer.currency,
                taxPercentage: offer.taxPercentage,
                positions: dtoPositions
              } as ProjectOfferDTO)
            : ({
                offerDate: new Date().toISOString() as unknown as Date,
                validUntil: null,
                totalPrice: null,
                currency: null,
                taxPercentage: null,
                positions: dtoPositions
              } as ProjectOfferDTO)
        }
        onSubmit={(values) => {
          dispatch(
            updateProjectOfferThunk({
              requestId: offer?.requestId || '',
              id: offer?.id || '',
              input: {
                ...values,
                totalPrice: values.totalPrice
                  ? parseFloat(`${values.totalPrice}`)
                  : null
              }
            })
          );
        }}
      >
        {({ submitForm }) => (
          <OfferDocumentBaseForm
            submitForm={submitForm}
            author={author}
            offerNr={offerNr}
            client={client}
          />
        )}
      </Formik>
    </>
  );
}
