import React from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@material-ui/core';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

type StyledLinkProps = MuiLinkProps & RouterLinkProps;

export const StyledLink = (props: StyledLinkProps) => (
  <MuiLink component={RouterLink} {...props} />
);
