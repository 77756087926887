import React from 'react';
import { ProjectOffer } from 'model';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Chip, createStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useProjectRequest } from '../../../hooks/project-request.hook';

const useStyles = makeStyles((theme) =>
  createStyles({
    accepted: {
      backgroundColor: theme.palette.success.light
    },
    rejected: {
      backgroundColor: theme.palette.error.light
    },
    open: {
      backgroundColor: lighten(theme.palette.warning.light, 0.6)
    }
  })
);

export function OfferStatusDisplay({ offer }: { offer: ProjectOffer }) {
  const history = useHistory();
  const { projectRequest } = useProjectRequest(offer.requestId);
  const classes = useStyles();

  const navigateToOffer = () =>
    history.push(`/projects/projects/${projectRequest?.projectId}/quotes`);

  if (offer.rejectionId) {
    return (
      <Chip
        size="small"
        variant="outlined"
        className={classes.rejected}
        label="Rejected"
        onClick={navigateToOffer}
      />
    );
  }
  if (offer.orderId) {
    return (
      <Chip
        size="small"
        variant="outlined"
        className={classes.accepted}
        label="Accepted"
        onClick={navigateToOffer}
      />
    );
  }
  if (!offer.orderId) {
    return (
      <Chip
        size="small"
        variant="outlined"
        className={classes.open}
        label="Open"
        onClick={navigateToOffer}
      />
    );
  }
  return <></>;
}
