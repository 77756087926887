"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createProjectDef = exports.ServiceMapping = exports.monthNames = void 0;
// eslint-disable-next-line max-classes-per-file
const lodash_1 = require("lodash");
function createMonthMapping(...monthNames) {
    return monthNames.map((name, index) => ({ name, index, number: index + 1 }));
}
exports.monthNames = createMonthMapping('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');
class CellTest {
    constructor(rawData) {
        this.dataRow = rawData;
        Object.assign(this, this.parse(rawData.content));
    }
}
function blubs(index, name, parser) {
    return class extends CellTest {
        // eslint-disable-next-line class-methods-use-this
        get cellIndex() {
            return index;
        }
        // eslint-disable-next-line class-methods-use-this
        get cellName() {
            return name;
        }
        static cellIndexAccessor() {
            return index;
        }
        static cellNameAccessor() {
            return name;
        }
        parse(rawData) {
            const content = rawData[this.cellIndex].trim();
            const parseResult = parser(content);
            const resultValue = parseResult.errors.some((e) => e.level === 'error')
                ? { type: 'error', value: parseResult.value }
                : { type: 'value', value: parseResult.value };
            return {
                value: resultValue,
                content,
                empty: content === '',
                errors: parseResult.errors
            };
        }
        static parseCell(rawData) {
            return new this(rawData);
        }
    };
}
class CellGroupTest {
    constructor(dataRow) {
        this.dataRow = dataRow;
        this.cellValues = this.parse(dataRow);
        this.empty = Object.values(this.cellValues).every((v) => v.empty);
    }
}
function blubsGroup(groupName, cellMap, reinspector = (r) => r) {
    return class extends CellGroupTest {
        // eslint-disable-next-line class-methods-use-this
        get cellMap() {
            return cellMap;
        }
        // eslint-disable-next-line class-methods-use-this
        get groupName() {
            return groupName;
        }
        static cellMapAccessor() {
            return cellMap;
        }
        static groupNameAccessor() {
            return groupName;
        }
        // eslint-disable-next-line class-methods-use-this
        parse(rawData) {
            return reinspector(Object.fromEntries(Object.entries(cellMap).map(([name, cell]) => [
                name,
                cell.parseCell(rawData)
            ])));
        }
        static parseGroup(dataRow) {
            return new this(dataRow);
        }
    };
}
function blubsRow(map) {
    return {
        groups: map,
        parse: (row) => ({
            dataRow: row,
            values: Object.fromEntries(Object.entries(map).map(([groupName, group]) => [
                groupName,
                group.parseGroup(row)
            ]))
        })
    };
}
function groupBy(map, group) {
    const createBuilder = (groups) => {
        return {
            groupBy(group2) {
                return createBuilder([...groups, group2]);
            },
            build(rows) {
                const valueRows = rows.map((r) => map.parse(r));
                return groups.reduce(([fun, excludes], c) => {
                    return [
                        (a) => {
                            const rows1 = a.filter((v) => Object.entries(v.values).some(([, v1]) => !v1.empty));
                            const subRows = rows1.map((r) => ({
                                ...r,
                                values: (0, lodash_1.omit)(r.values, c)
                            }));
                            const groupRows = rows1.filter((v, i) => !v.values[c].empty || i === 0);
                            return groupRows
                                .reverse()
                                .map((g) => {
                                const rows2 = [];
                                let subRow;
                                do {
                                    subRow = subRows.pop();
                                    if (subRow)
                                        rows2.push(subRow);
                                } while (subRow &&
                                    subRow.dataRow.rowIndex > g.dataRow.rowIndex);
                                const intermediate = {
                                    group: g.values[c],
                                    rows: fun(rows2.reverse())
                                };
                                return {
                                    ...intermediate,
                                    rows: intermediate.rows.map((subRow1) => Object.assign(subRow1, { parent: intermediate }))
                                };
                                /* return {
                                  group: g.values[c],
                                  rows:
                                }; */
                            })
                                .reverse();
                        },
                        [...excludes, c]
                    ];
                }, [(a) => a, []])[0](valueRows);
            }
        };
    };
    return createBuilder([group]);
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ParseResult = {};
function chainValidation(fun) {
    return {
        process(input) {
            const fun1 = fun(input);
            return fun1;
        },
        chain(fun1) {
            return chainValidation((r) => {
                const before = this.process(r);
                if (before.value) {
                    const result = fun1(before.value);
                    return {
                        value: result.value,
                        errors: [...result.errors, ...before.errors]
                    };
                }
                return before;
            });
        }
    };
}
/*

type ValidationChain<I, O> = {
  process(
    input: I
  ):
    | { type: 'valid'; result: O }
    | { type: 'warning'; result: O; errors: ParseError[] }
    | { type: 'error'; errors: ParseError[] };

  chain<O2>(fun: (i: O) => O2): ValidationChain<I, O2>;
};

class ValidationError extends Error {
  level: 'error' | 'warning';

  message: string;

  constructor(message: string, level: 'error' | 'warning') {
    super(message);
    this.level = level;
    this.message = message;
  }
}

class ValidationWarning extends ValidationError {
  value: any;

  constructor(message: string, level: 'error' | 'warning', value: any) {
    super(message, level);
    this.value = value;
  }
}

function chainValidation<I, O>(fun: (r: I) => O): ValidationChain<I, O> {
  return {
    process(
      input: I
    ):
      | { type: 'valid'; result: O }
      | { type: 'warning'; result: O; errors: ParseError[] }
      | { type: 'error'; errors: ParseError[] } {
      try {
        const output = fun(input);
        return { type: 'valid', result: output };
      } catch (error: ValidationError) {
        if (error instanceof ValidationWarning) {
          return {
            type: 'warning', result: error.value,
            errors: [{ level: 'warning', message: error.message }]
          };
        }
        return {
          type: 'error',
          errors: [{ level: 'error', message: error.message }]
        };
      }
    },
    chain<O2>(fun: (i: O) => O2): ValidationChain<I, O2> {
      return chainValidation<I, O2>(r => {
        const before = this.process(r);
        if (before.type !== "error") {

        } else {
          throw new ValidationError(before.)
        }
      })
    }
  };
}
*/
exports.ServiceMapping = {
    Copywriting: 'Copywriting',
    'Keyword-Recherche': '',
    Proofreading: 'Proofreading',
    Research: 'Research',
    Subtitling: 'Subtitling',
    Transcreation: 'Transcreation',
    Transkription: 'Transcription',
    Translation: 'Translation'
};
function createProjectDef(sources) {
    const projectDef = blubsRow({
        year: blubsGroup('year', {
            year: blubs(0, 'year', (r) => {
                return {
                    value: parseInt(r, 10),
                    errors: /^[0-9]{4}$/.test(r)
                        ? []
                        : [{ level: 'error', message: 'Invalid year' }]
                };
            })
        }),
        month: blubsGroup('month', {
            month: blubs(1, 'month', (r) => {
                const monthName = exports.monthNames.find((month) => month.name.toLowerCase() === r.toLowerCase());
                return {
                    value: monthName,
                    errors: monthName
                        ? []
                        : [{ level: 'error', message: 'Unknown month' }]
                };
            })
        }),
        project: blubsGroup('project', {
            pmIds: blubs(3, 'pmIds', chainValidation((r) => {
                const ids = r === '' ? [] : r.split(',').map((r1) => r1.trim());
                const errors = ids.flatMap((id) => /^[0-9]{3}-[0-9]{3}$/.test(id) ? [] : [id]);
                if (errors.length) {
                    return {
                        errors: errors.map((e) => ({
                            level: 'error',
                            message: `${e} is invalid supplier id`
                        }))
                    };
                }
                return {
                    value: ids,
                    errors: ids.length > 0 ? [] : [{ level: 'warning', message: 'no pm' }]
                };
            }).chain((ids) => {
                const result = ids.map((sid) => [sid, sources.supplier(sid)]);
                const errors = result
                    .filter(([, s]) => s === undefined)
                    .map(([id]) => ({
                    level: 'error',
                    message: `Supplier ${id} is unkndown`
                }));
                if (errors.length) {
                    return { errors };
                }
                return {
                    value: result.map(([, s]) => s),
                    errors: result
                        .filter(([, s]) => !(s === null || s === void 0 ? void 0 : s.userId))
                        .map(([id, su]) => ({
                        level: 'warning',
                        message: `supplier ${id} ${su.id} has no user associated`
                    }))
                };
            }).process),
            clientNumber: blubs(5, 'clientNumber', chainValidation((r) => /^[0-9]{5}$/.test(r)
                ? { value: r, errors: [] }
                : {
                    errors: [
                        { level: 'error', message: 'invalid client number format' }
                    ]
                }).chain((r) => {
                const client = sources.client(r);
                if (client) {
                    return { value: client, errors: [] };
                }
                return {
                    errors: [
                        { level: 'error', message: `client ${r} not found` }
                    ]
                };
            }).process),
            projectNumber: blubs(6, 'projectNumber', chainValidation((r) => /^[0-9]{5}-[0-9]{2}-[0-9]{2,4}$/.test(r)
                ? { value: r, errors: [] }
                : {
                    errors: [
                        { level: 'error', message: 'invalid project number format' }
                    ]
                }).chain((pN) => {
                const project = sources.project(pN);
                if (project) {
                    return { value: project, errors: [] };
                }
                return {
                    errors: [
                        {
                            level: 'error',
                            message: `Project with number ${pN} not found`
                        }
                    ]
                };
            }).process),
            projectName: blubs(7, 'projectName', (r) => ({ value: r, errors: [] })),
            projectSheet: blubs(8, 'projectSheet', (r) => ({
                value: r !== '' ? r : null,
                errors: []
            })),
            clientOffer: blubs(9, 'clientOffer', (r) => ({
                value: r.toLowerCase() === 'ja',
                errors: []
            })),
            wordbee: blubs(10, 'wordbee', (r) => ({
                value: r.toLowerCase() === 'ja',
                errors: []
            })),
            comment: blubs(11, 'comments', (r) => ({
                value: r !== '' ? r : null,
                errors: []
            })),
            deliveryDate: blubs(19, 'deliveryDate', (r) => ({
                value: r,
                errors: r === '' || /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/.test(r)
                    ? []
                    : [{ level: 'error', message: 'invalid date format' }]
            })),
            tmConsolidated: blubs(20, 'tmConsolidated', (r) => ({
                value: r.toLowerCase() === 'ja',
                errors: []
            })),
            delivered: blubs(21, 'deliverd', chainValidation((r) => ['ja', 'nein'].includes(r.toLowerCase())
                ? { value: r.toLowerCase(), errors: [] }
                : {
                    errors: [{ level: 'error', message: "Expected 'ja' or 'nein'" }]
                }).chain((r) => ({ value: r === 'ja', errors: [] })).process),
            projectFinal: blubs(22, 'projectFinal', chainValidation((r) => ['ja', 'nein'].includes(r.toLowerCase())
                ? { value: r.toLowerCase(), errors: [] }
                : {
                    errors: [{ level: 'error', message: "Expected 'ja' or 'nein'" }]
                }).chain((r) => ({ value: r === 'ja', errors: [] })).process),
            invoice: blubs(23, 'invoice', chainValidation((r) => ['ja', 'nein'].includes(r.toLowerCase())
                ? { value: r.toLowerCase(), errors: [] }
                : {
                    errors: [{ level: 'error', message: "Expected 'ja' or 'nein'" }]
                }).chain((r) => ({ value: r === 'ja', errors: [] })).process),
            invoiceNumber: blubs(24, 'invoiceNumber', chainValidation((r) => r === '' || /^[0-9]{3,4}$/.test(r)
                ? { value: r, errors: [] }
                : {
                    errors: [
                        { level: 'error', message: 'Invalid invoice number format' }
                    ]
                }).chain((r) => ({ value: r === '' ? null : r, errors: [] })).process)
        }),
        service: blubsGroup('service', {
            service: blubs(12, 'service', chainValidation((r) => ({
                value: exports.ServiceMapping[r],
                errors: exports.ServiceMapping[r]
                    ? []
                    : [{ level: 'error', message: `Service ${r} unknown` }]
            })).chain((r) => ({
                value: sources.serviceClass(r),
                errors: sources.serviceClass(r)
                    ? []
                    : [
                        {
                            level: 'error',
                            message: `service class ${r} not found`
                        }
                    ]
            })).process),
            sourceLanguage: blubs(13, 'sourceLanguage', chainValidation((r) => {
                var _a;
                return ({
                    value: r === '' ? null : (_a = sources.language(r)) === null || _a === void 0 ? void 0 : _a.language,
                    errors: r === '' || sources.language(r)
                        ? []
                        : [{ level: 'error', message: `Language ${r} unknown` }]
                });
            }).process)
        }, (values) => {
            const serviceValue = values.service;
            const sourceLang = values.sourceLanguage;
            if (serviceValue.value.type === 'value') {
                if (serviceValue.value.value.type !== 'source-target' &&
                    sourceLang.value.value !== null) {
                    sourceLang.errors.push({
                        level: 'error',
                        message: 'service should not have source language'
                    });
                }
                if (serviceValue.value.value.type === 'source-target' &&
                    sourceLang.value.value === null) {
                    sourceLang.errors.push({
                        level: 'error',
                        message: 'Missing source language for service'
                    });
                }
            }
            return values;
        }),
        task: blubsGroup('task', {
            targetLanguage: blubs(14, 'targetLanguage', chainValidation((r) => {
                var _a;
                return ({
                    value: r === '' ? null : (_a = sources.language(r)) === null || _a === void 0 ? void 0 : _a.language,
                    errors: r === '' || sources.language(r) !== undefined
                        ? []
                        : [{ level: 'error', message: `Language ${r} unknown` }]
                });
            }).process),
            provisionSupplier: blubs(16, 'provisionSupplier', chainValidation((r) => ({
                value: /^[0-9]{3}-[0-9]{3}$/.test(r) ? r : undefined,
                errors: /^[0-9]{3}-[0-9]{3}$/.test(r)
                    ? []
                    : [{ level: 'error', message: `${r} invalid supplier id format` }]
            })).chain((r) => ({
                value: sources.supplier(r),
                errors: sources.supplier(r)
                    ? []
                    : [{ level: 'error', message: `Supplier ${r} does not exist` }]
            })).process),
            reviewerSupplier: blubs(18, 'reviewerSupplier', chainValidation((r) => ({
                value: r === '' || /^[0-9]{3}-[0-9]{3}$/.test(r) ? r : undefined,
                // eslint-disable-next-line no-nested-ternary
                errors: /^[0-9]{3}-[0-9]{3}$/.test(r)
                    ? []
                    : r === ''
                        ? [{ level: 'warning', message: 'no reviewer' }]
                        : [{ level: 'error', message: `${r} invalid supplier id format` }]
            })).chain((r) => ({
                value: sources.supplier(r),
                errors: sources.supplier(r)
                    ? []
                    : [{ level: 'error', message: `Supplier ${r} does not exist` }]
            })).process)
        })
    });
    const projectGroupDef = groupBy(projectDef, 'service')
        // .groupBy('service')
        .groupBy('project')
        .groupBy('month')
        .groupBy('year');
    const oldBuild = projectGroupDef.build;
    projectGroupDef.build = (r) => {
        const oldResult = oldBuild(r);
        oldResult.forEach((y) => y.rows.forEach((m) => m.rows.forEach((p) => {
            var _a, _b, _c, _d, _e;
            const projectValue = p.group.cellValues;
            if (!projectValue.invoice.value.value &&
                projectValue.invoiceNumber.value.value) {
                projectValue.invoiceNumber.errors.push({
                    level: 'error',
                    message: 'no invoice but invoice number'
                });
            }
            if (projectValue.invoice.value.value &&
                !projectValue.invoiceNumber.value.value) {
                projectValue.invoiceNumber.errors.push({
                    level: 'error',
                    message: 'invoice true but no invoice number'
                });
            }
            if (projectValue.delivered.value.value &&
                !projectValue.deliveryDate.value.value) {
                projectValue.deliveryDate.errors.push({
                    level: 'error',
                    message: 'project delivered but no delivery date given'
                });
            }
            if (projectValue.delivered.value.value &&
                !projectValue.projectFinal.value.value) {
                projectValue.projectFinal.errors.push({
                    level: 'error',
                    message: 'project delivered but not final'
                });
            }
            if (!projectValue.delivered.value.value &&
                projectValue.deliveryDate.value.value) {
                projectValue.deliveryDate.errors.push({
                    level: 'error',
                    message: 'project not delivered but delivery date given'
                });
            }
            if ((_a = projectValue.projectNumber.value) === null || _a === void 0 ? void 0 : _a.value) {
                const project = projectValue.projectNumber.value.value;
                if (project.clientId !== ((_c = (_b = projectValue.clientNumber.value) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.id)) {
                    projectValue.clientNumber.errors.push({
                        level: 'warning',
                        // eslint-disable-next-line max-len
                        message: `project request client id ${projectValue.projectNumber.value.value.clientId} does not match this client ${(_e = (_d = projectValue.clientNumber.value) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.id}`
                    });
                }
            }
            p.rows.forEach((s) => {
                const service = s.group.cellValues.service.value.value;
                const sourceLanguage = s.group.cellValues.sourceLanguage.value.value;
                if (service) {
                    s.rows.forEach((t) => {
                        const targetLanguage = t.values.task.cellValues.targetLanguage.value.value;
                        if (service.type === 'no-language') {
                            if (t.values.task.cellValues.targetLanguage.value.value !== null) {
                                t.values.task.cellValues.targetLanguage.errors.push({
                                    level: 'error',
                                    message: 'no source language allowed'
                                });
                            }
                        }
                        else if (t.values.task.cellValues.targetLanguage.value.value === null) {
                            t.values.task.cellValues.targetLanguage.errors.push({
                                level: 'error',
                                message: 'source language required'
                            });
                        }
                        const provSup = t.values.task.cellValues.provisionSupplier.value.value;
                        if (provSup) {
                            if (!provSup.serviceSteps.find((suS) => suS.serviceStepId ===
                                sources.serviceStep('service provision').id)) {
                                t.values.task.cellValues.provisionSupplier.errors.push({
                                    level: 'warning',
                                    // eslint-disable-next-line max-len
                                    message: `Supplier ${provSup.importReference} does not have step service provision`
                                });
                            }
                            if (!provSup.services.find((supS) => supS.serviceClassId === service.id)) {
                                t.values.task.cellValues.provisionSupplier.errors.push({
                                    level: 'warning',
                                    // eslint-disable-next-line max-len
                                    message: `supplier ${provSup.importReference} does offer service ${service.name}`
                                });
                            }
                            if (sourceLanguage) {
                                if (!provSup.languages.find((l) => {
                                    var _a, _b, _c, _d, _e;
                                    return ((_b = (_a = sources.language(l.languageId)) === null || _a === void 0 ? void 0 : _a.specification) === null || _b === void 0 ? void 0 : _b.language.code) ===
                                        ((_e = (_d = (_c = sources.language(sourceLanguage.id)) === null || _c === void 0 ? void 0 : _c.specification) === null || _d === void 0 ? void 0 : _d.language) === null || _e === void 0 ? void 0 : _e.name);
                                })) {
                                    t.values.task.cellValues.provisionSupplier.errors.push({
                                        level: 'warning',
                                        // eslint-disable-next-line max-len
                                        message: `supplier ${provSup.importReference} does not speak ${sourceLanguage.languageCode}`
                                    });
                                }
                            }
                            if (targetLanguage) {
                                if (!provSup.languages.find((l) => {
                                    var _a, _b, _c, _d, _e;
                                    return ((_b = (_a = sources.language(l.languageId)) === null || _a === void 0 ? void 0 : _a.specification) === null || _b === void 0 ? void 0 : _b.language.code) ===
                                        ((_e = (_d = (_c = sources.language(targetLanguage.id)) === null || _c === void 0 ? void 0 : _c.specification) === null || _d === void 0 ? void 0 : _d.language) === null || _e === void 0 ? void 0 : _e.name) && l.native;
                                })) {
                                    t.values.task.cellValues.provisionSupplier.errors.push({
                                        level: 'warning',
                                        // eslint-disable-next-line max-len
                                        message: `supplier ${provSup.importReference} does not speak ${targetLanguage.languageCode} as native language`
                                    });
                                }
                            }
                        }
                        const rewSup = t.values.task.cellValues.reviewerSupplier.value.value;
                        if (rewSup) {
                            if (!rewSup.serviceSteps.find((suS) => suS.serviceStepId === sources.serviceStep('review').id)) {
                                t.values.task.cellValues.provisionSupplier.errors.push({
                                    level: 'warning',
                                    // eslint-disable-next-line max-len
                                    message: `Supplier ${rewSup.importReference} does not have step review`
                                });
                            }
                            if (!rewSup.services.find((supS) => supS.serviceClassId === service.id)) {
                                t.values.task.cellValues.reviewerSupplier.errors.push({
                                    level: 'warning',
                                    // eslint-disable-next-line max-len
                                    message: `supplier ${rewSup.importReference} does offer service ${service.name}`
                                });
                            }
                            if (sourceLanguage) {
                                if (!rewSup.languages.find((l) => {
                                    var _a, _b, _c, _d, _e;
                                    return ((_b = (_a = sources.language(l.languageId)) === null || _a === void 0 ? void 0 : _a.specification) === null || _b === void 0 ? void 0 : _b.language.code) ===
                                        ((_e = (_d = (_c = sources.language(sourceLanguage.id)) === null || _c === void 0 ? void 0 : _c.specification) === null || _d === void 0 ? void 0 : _d.language) === null || _e === void 0 ? void 0 : _e.name);
                                })) {
                                    t.values.task.cellValues.reviewerSupplier.errors.push({
                                        level: 'warning',
                                        // eslint-disable-next-line max-len
                                        message: `supplier ${rewSup.importReference} does not speak ${sourceLanguage.languageCode}`
                                    });
                                }
                            }
                            if (targetLanguage) {
                                if (!rewSup.languages.find((l) => {
                                    var _a, _b, _c, _d, _e;
                                    return ((_b = (_a = sources.language(l.languageId)) === null || _a === void 0 ? void 0 : _a.specification) === null || _b === void 0 ? void 0 : _b.language.code) ===
                                        ((_e = (_d = (_c = sources.language(targetLanguage.id)) === null || _c === void 0 ? void 0 : _c.specification) === null || _d === void 0 ? void 0 : _d.language) === null || _e === void 0 ? void 0 : _e.name) && l.native;
                                })) {
                                    t.values.task.cellValues.reviewerSupplier.errors.push({
                                        level: 'warning',
                                        // eslint-disable-next-line max-len
                                        message: `supplier ${rewSup.importReference} does not speak ${targetLanguage.languageCode} as native language`
                                    });
                                }
                            }
                        }
                    });
                }
            });
        })));
        return oldResult;
    };
    const createImportData = (data) => {
        if (data.every((y) => y.group.cellValues.year.errors.every((e) => e.level !== 'error') &&
            y.rows.every((m) => m.group.cellValues.month.value.type !== 'error' &&
                m.group.cellValues.month.errors.every((e) => e.level !== 'error') &&
                m.rows.every((p) => Object.values(p.group.cellValues).every((v) => v.value.type !== 'error' &&
                    v.errors.every((e) => e.level !== 'error')) &&
                    p.rows.every((s) => Object.values(s.group.cellValues).every((v) => v.value.type !== 'error' &&
                        v.errors.every((e) => e.level !== 'error')) &&
                        s.rows.every((t) => Object.values(t.values.task.cellValues).every((v) => v.value.type !== 'error' &&
                            v.errors.every((e) => e.level !== 'error')))))))) {
            return data.flatMap((year) => {
                return year.rows.map((month) => {
                    return {
                        year: year.group.cellValues.year.value.value,
                        month: month.group.cellValues.month.value.value.number,
                        rows: month.rows.map((project, position) => {
                            const pValues = project.group.cellValues;
                            const projectEntity = pValues.projectNumber.value
                                .value;
                            const additionalPms = pValues.pmIds.value.value
                                .filter((sup) => projectEntity.projectManagerId !== sup.id &&
                                !projectEntity.supportingProjectManagerIds.includes(sup.id))
                                .map((a) => a.id);
                            return {
                                position,
                                projectId: projectEntity.id,
                                projectName: pValues.projectName.value.value,
                                additionalPms,
                                projectSheet: pValues.projectSheet.value.value,
                                clientOffer: pValues.clientOffer.value.value,
                                wordbee: pValues.wordbee.value.value,
                                tmConsolidated: pValues.tmConsolidated.value.value,
                                comment: pValues.comment.value.value,
                                deliveryDate: pValues.delivered.value.value
                                    ? pValues.deliveryDate.value.value
                                    : null,
                                final: pValues.projectFinal.value.value,
                                invoiceNumber: pValues.invoice.value.value
                                    ? pValues.invoiceNumber.value.value
                                    : null,
                                services: project.rows.map((service) => {
                                    var _a, _b;
                                    return ({
                                        serviceClassId: service.group.cellValues.service.value.value.id,
                                        sourceLangId: (_b = (_a = service.group.cellValues.sourceLanguage.value.value) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
                                        tasks: service.rows.map((task) => {
                                            var _a, _b, _c, _d;
                                            return ({
                                                targetLangId: (_b = (_a = task.values.task.cellValues.targetLanguage.value.value) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null,
                                                provisionSupplierId: task.values.task.cellValues.provisionSupplier.value.value
                                                    .id,
                                                reviewSupplierId: (_d = (_c = task.values.task.cellValues.reviewerSupplier.value.value) === null || _c === void 0 ? void 0 : _c.id) !== null && _d !== void 0 ? _d : null
                                            });
                                        })
                                    });
                                })
                            };
                        })
                    };
                });
            });
        }
        throw new Error('blubs');
    };
    return { projectDef, projectGroupDef, createImportData };
}
exports.createProjectDef = createProjectDef;
