import React from 'react';
import {
  permissions,
  ServiceClass,
  ServiceClassPatchDTO,
  serviceClassPathTransformer
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { NewEditableCard } from '../../../../components/card/editableCard/NewEditableCard';
import {
  ServiceClassBasicForm,
  serviceClassBasicView
} from './ServiceClassBasicForm';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { serviceClassesThunk } from '../../redux/service-classes.thunk';
import { NoOp } from '../../../../utils';
import { ServiceClassBasicDisplay } from './ServiceClassBasicDisplay';

export function ServiceClassBasicCard({
  serviceClass
}: {
  serviceClass: ServiceClass;
}) {
  const dispatch = useAppDispatch();

  const updateServiceClass = (input: ServiceClassPatchDTO) =>
    dispatch(serviceClassesThunk.update({ id: serviceClass.id, input }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <NewEditableCard
      identifier="basic"
      title="Basic"
      value={serviceClass}
      display={{
        component: ServiceClassBasicDisplay
      }}
      form={{
        component: ServiceClassBasicForm,
        validationSchema: serviceClassBasicView.validationSchema,
        viewFactory: serviceClassBasicView.viewFactory,
        onSubmit: updateServiceClass,
        dtoTransformer: serviceClassPathTransformer,
        permissions: permissions.services.serviceClass.edit
      }}
    />
  );
}
