import React from 'react';
import { BaseTableRow, DataCell, DataCellMap } from '../TableDataCell';
import { useDisplayedTableColumns } from './displayed-columns.hook';
import { useTableConfigContext } from '../context/DataTableContext';

export function useArrangedTableColumns<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const displayColumns = useDisplayedTableColumns<T, C>();
  const {
    tableColumnPosition: { columnConfig }
  } = useTableConfigContext<T, C>();
  return React.useMemo(() => {
    return Object.values(displayColumns).sort((a, b) => {
      return columnConfig[a.cellId].position - columnConfig[b.cellId].position;
    }) as DataCell<T, any>[];
  }, [displayColumns, columnConfig]);
}
