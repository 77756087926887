import React from 'react';
import { Supplier, SupplierService } from 'model';
import { Box, Divider, List, ListItem, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ArrowForward } from '@material-ui/icons';
import { useServiceClass } from '../../../services/hooks/service-class.hook';
import { useLanguageIndex } from '../../../languages/hooks/language-index.hook';
import { StyledLink } from '../../../../components/input/link/StyledLink';
import { IETFIcon } from '../../../languages/components/IETFIcon';
import { SupplierServiceStepsDisplay } from '../steps/SupplierServiceStepDisplay';

interface SupplierServiceDisplayProps {
  supplierService: SupplierService;
}

function SupplierServiceDisplay({
  supplierService
}: SupplierServiceDisplayProps) {
  const { serviceClass } = useServiceClass(supplierService.serviceClassId);

  const languageIndex = useLanguageIndex();

  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1}>
        <StyledLink
          variant="body1"
          to={`/services/service-class/${serviceClass?.id}`}
        >
          {serviceClass ? serviceClass.name : <Skeleton />}
        </StyledLink>
        <List dense>
          {supplierService.languages?.map((sl) => (
            <ListItem style={{ display: 'flex' }}>
              {sl.sourceLanguage && (
                <>
                  <span style={{ marginRight: 5 }}>
                    <IETFIcon
                      tag={
                        languageIndex.descriptors[sl.sourceLanguage]
                          .specification
                      }
                    />
                  </span>
                  <Typography variant="body2">
                    {languageIndex.descriptors[sl.sourceLanguage].language.name}
                  </Typography>
                  <ArrowForward
                    color="action"
                    style={{
                      marginRight: 10,
                      marginLeft: 10
                    }}
                  />
                </>
              )}
              <span style={{ marginRight: 5 }}>
                <IETFIcon
                  tag={
                    languageIndex.descriptors[sl.targetLanguage].specification
                  }
                />
              </span>
              <Typography variant="body2">
                {languageIndex.descriptors[sl.targetLanguage].language.name}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
      <Typography variant="subtitle1" color="textSecondary">
        {serviceClass ? supplierService.comment : <Skeleton />}
      </Typography>
    </Box>
  );
}

interface SupplierServicesDisplayProps {
  value: Supplier;
}

export function SupplierServicesDisplay({
  value
}: SupplierServicesDisplayProps) {
  return (
    <>
      {value.services?.map((service) => (
        <SupplierServiceDisplay supplierService={service} />
      ))}
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <SupplierServiceStepsDisplay value={value} />
    </>
  );
}
