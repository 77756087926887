import { BaseEntity } from 'model';
import { shallowEqual } from 'react-redux';
import React, { useEffect } from 'react';
import { EntityAPI } from './entity-api.hook';
import { useAppDispatch, useAppSelector } from './redux.hooks';

export type EntityAPIMap<M extends Record<string, BaseEntity>> = {
  [m in keyof M]: M[m] extends BaseEntity ? EntityAPI<M[m]> : never;
};

export function defineEntityMap<M extends Record<string, BaseEntity>>(
  apiMap: EntityAPIMap<M>
): EntityAPIMap<M> {
  return apiMap;
}

export type EntityMapType<E extends EntityAPIMap<any>> = E extends EntityAPIMap<
  infer B
>
  ? {
      [m in keyof E]: m extends keyof B
        ? Record<string, B[m]> | undefined
        : never;
    }
  : never;

export function useEntityMap<M extends Record<string, BaseEntity>>(
  apiMap: EntityAPIMap<M>
  // { [m in keyof M]: M[m] extends BaseEntity ? EntityAPI<M[m]> : never }
) {
  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (root) =>
      Object.fromEntries(
        Object.entries(apiMap).map(([k, v]) => [
          k,
          {
            fetched:
              v.selectors.selectListFetched(root, shallowEqual) !== false &&
              v.selectors.selectState(root, shallowEqual) !== 'fresh',
            entities: v.selectors.selectAll(root, shallowEqual)
          }
        ])
      ) as { [m in keyof M]: { fetched: boolean; entities: M[m][] } },
    shallowEqual
  );

  useEffect(() => {
    Object.entries(data)
      .filter(([, d]) => !d.fetched)
      .forEach(([k]) => {
        dispatch(apiMap[k].thunks.list());
      });
  }, []);

  return React.useMemo(() => {
    return Object.fromEntries(
      Object.entries(data).map(
        ([k, { fetched, entities }]: [
          keyof M,
          { fetched: boolean; entities: M[keyof M][] }
        ]) => [
          k,
          fetched
            ? Object.fromEntries(entities.map((e) => [e.id, e]))
            : undefined
        ]
      )
    ) as { [m in keyof M]: Record<string, M[m]> | undefined };
  }, [data, apiMap]);
}
