import React from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { sequenceTemplatesSelector } from '../redux/sequence-template.slice';
import { sequenceTemplateThunk } from '../redux/sequence-template.thunk';

export function useSequenceTemplates() {
  const dispatch = useAppDispatch();

  const sequenceTemplates = useAppSelector(
    sequenceTemplatesSelector.selectAll,
    shallowEqual
  );

  const apiState = useAppSelector(
    sequenceTemplatesSelector.selectState,
    shallowEqual
  );
  const listState = useAppSelector(sequenceTemplatesSelector.selectListFetched);

  const listAllSequenceTemplates = () => dispatch(sequenceTemplateThunk());

  React.useEffect(() => {
    if (listState === false || apiState === 'fresh') listAllSequenceTemplates();
  }, []);

  return { sequenceTemplates, apiState, listAllSequenceTemplates };
}
