import React from 'react';
import {
  Breadcrumbs,
  BreadcrumbsProps,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseLocationElements } from '../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'flex'
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20
    }
  })
);

export function SimpleBreadcrumbs(props: BreadcrumbsProps) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const locationElements = parseLocationElements(pathname);

  const { t } = useTranslation();
  return (
    <Breadcrumbs aria-label="Breadcrumb" {...props}>
      <Typography id="BreadCrumbs-TEO-BaseBreadCrumb">TEO</Typography>
      {locationElements.map((e) => (
        <Typography key={e.link} className={classes.link}>
          <e.icon className={classes.icon} />
          {typeof e.label === 'string' ? t(e.label) : e.label}
        </Typography>
      ))}
    </Breadcrumbs>
  );
}
