import { EntityId } from '@reduxjs/toolkit';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const deleteClientThunk = createErrorHandlingThunk<EntityId, EntityId>(
  'client/delete',
  async (clientId) => {
    await apiClient.delete(`/client/${clientId}`);
    return clientId;
  }
);
