import { Client } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const deleteClientAddressThunk = createErrorHandlingThunk<
  Client,
  { clientId: string; addressId: string }
>('client/address/delete', async (args) => {
  await apiClient.delete(
    `/client/${args.clientId}/addresses/${args.addressId}`
  );

  const clientResult = await apiClient.get<Client>(`/client/${args.clientId}`);
  return clientResult.data;
});
