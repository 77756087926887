import { Project, Supplier } from 'model';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { ProjectTable } from '../../../projects/components/table/ProjectsTable';
import { useAppDispatch, useAppSelector } from '../../../../redux/redux.hooks';
import { suppliersThunk } from '../../redux/suppliers.thunk';

export function SupplierProjectAssignmentCard({
  supplier
}: {
  supplier: Supplier;
}) {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(
    (state) =>
      (
        supplier.projects?.map((p) => state.projects.entities[p.id]) ?? []
      ).filter((p) => p !== undefined) as Project[],
    shallowEqual
  );

  return (
    <ProjectTable
      uiMode="widget"
      projects={projects}
      onRefresh={() => dispatch(suppliersThunk.get({ id: supplier.id }))}
      apiState={supplier.projects ? 'idle' : 'fresh'}
      initialColumnLayout={[
        'project_name',
        'project_number',
        'project_status',
        'project_client',
        'project_all_managers'
      ]}
    />
  );
}
