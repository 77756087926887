import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { Switch as RouterSwitch } from 'react-router';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { LargeScaleProjectsScreen } from './screens/LargeScaleProjects.screen';
import { ProjectTagScreen } from './screens/ProjectTag.screen';
import { ProjectTagFormDialog } from './components/tags/ProjectTagFormDialog';
import { LargeScaleProjectFormDialog } from './components/largeScaleProjects/LargeScaleProjectFormDialog';
import { RequestSourceFormDialog } from './components/request/source/RequestSourceFormDialog';
import { RequestSourceScreen } from './screens/RequestSource.screen';
import { ProjectScreen } from './screens/Projects.screen';
import { ProjectFormDialog } from './components/ProjectFormDialog';
import { getUuidUrlParamMatcher } from '../../components/navigation/utils';
import { ProjectDetailsScreen } from './screens/ProjectDetails.screen';
import { RequestsScreen } from './screens/Requests.screen';
import { RequestFormDialog } from './components/request/RequestFormDialog';
import { ProjectRequestDetails } from './components/request/ProjectRequestDetails';
import { RequestStatusDialog } from './components/RequestStatusDialog';
import { RequestStatusScreen } from './screens/RequestStatus.screen';
import { ProjectVariationScreen } from '../services/screens/ProjectVariation.screen';
import { ProjectVariationFormDialog } from '../services/components/ProjectVariationFormDialog';
import { RequestImportScreen } from './screens/RequestImport.screen';
import { KontextorError } from '../error/KontextorError';
import projectRequestSlice, {
  projectRequestSelectors
} from './redux/project-requests.slice';
import { ProjectImportScreen } from './screens/ProjectImporter.screen';
import { ProjectImport2Screen } from './screens/ProjectImport2.screen';
import { ProjectDuplicateDialog } from './components/duplicate/ProjectDuplicateDialog';

export function ProjectsFeature() {
  const { path } = useRouteMatch();

  return (
    <ErrorBoundary context="projectsFeature">
      <KontextorError
        clear={projectRequestSlice.actions.clearError}
        selector={projectRequestSelectors.selectError}
      >
        <ProjectTagFormDialog />
        <RequestStatusDialog />
        <LargeScaleProjectFormDialog />
        <RequestSourceFormDialog />
        <ProjectFormDialog />
        <RequestFormDialog />
        <ProjectDuplicateDialog />
        <ProjectVariationFormDialog />
        <RouterSwitch>
          <Route path={`${path}/large-scale-projects`}>
            <LargeScaleProjectsScreen />
          </Route>
          <Route
            path={`${path}/projects/${getUuidUrlParamMatcher('projectId')}`}
          >
            <ProjectDetailsScreen />
          </Route>
          <Route
            path={`${path}/project-requests/${getUuidUrlParamMatcher(
              'projectRequestId'
            )}`}
          >
            <ProjectRequestDetails />
          </Route>
          <Route path={`${path}/projects`}>
            <ProjectScreen />
          </Route>
          <Route path={`${path}/project-requests/import`}>
            <RequestImportScreen />
          </Route>
          <Route path={`${path}/project/import`}>
            <ProjectImportScreen />
          </Route>
          <Route path={`${path}/project/import2`}>
            <ProjectImport2Screen />
          </Route>
          <Route path={`${path}/project-requests`}>
            <RequestsScreen />
          </Route>
          <Route path={`${path}/project-tag`}>
            <ProjectTagScreen />
          </Route>
          <Route path={`${path}/request-source`}>
            <RequestSourceScreen />
          </Route>
          <Route path={`${path}/request-status`}>
            <RequestStatusScreen />
          </Route>
          <Route path={`${path}/project-variation`}>
            <ProjectVariationScreen />
          </Route>
        </RouterSwitch>
      </KontextorError>
    </ErrorBoundary>
  );
}
