import React, { PropsWithChildren } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import TableCell from '@material-ui/core/TableCell';
import { Box, TableCellProps } from '@material-ui/core';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { ColumnKey } from '../types';
import { useColumnPositionControl } from './column-position.hook';

export interface DraggableTableCellProps<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> extends TableCellProps {
  column: ColumnKey<T, C>;
}

const CellItemType = 'table-cell';

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function DraggableTableCell<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>({
  children,
  column,
  ...rest
}: PropsWithChildren<DraggableTableCellProps<T, C>>) {
  const ref = React.useRef<HTMLTableCellElement>(null);
  // const { position, moveColumn } = useColumnPosition<T, C>(column);
  const { position, moveOtherColumn } = useColumnPositionControl<T, C>(column);
  const [{ handlerId }, drop] = useDrop({
    accept: CellItemType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = position;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      moveOtherColumn(
        item.id,
        hoverClientX > hoverMiddleX ? 'after' : 'before'
      );

      // item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: CellItemType,
    item: () => {
      return { id: column, index: position };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <TableCell
      {...rest}
      style={{
        opacity,
        ...rest.style
        // display: 'flex'
        // justifyContent: 'flex-end'
      }}
      ref={ref}
      data-handler-id={handlerId}
    >
      <Box
        justifyContent="flex-start"
        flexDirection="row"
        style={{ height: '100%' }}
      >
        {children}
      </Box>
    </TableCell>
  );
}
