import React, { useEffect } from 'react';
import {
  AcceptOfferDTO,
  formatProjectNumber,
  ProjectNumber,
  ProjectNumberDTO
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import * as yup from 'yup';
import {
  CircularProgress,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@material-ui/lab';
import { omit } from 'lodash';
import { useParams } from 'react-router-dom';
import { setHours, setMinutes } from 'date-fns';
import { useAppDispatch } from '../../../../../redux/redux.hooks';
import { acceptProjectOfferThunk } from '../../../redux/project-offers.thunk';
import { NoOp } from '../../../../../utils';
import { DialogForm } from '../../../../../components/input/form/DialogForm';
import { useProjectOffer } from '../../../hooks/project-offer.hook';
import projectOffersSlice, {
  projectOfferSelectors
} from '../../../redux/project-offer.slice';
import { buildDTOView } from '../../../../../transformer/DTOViewSchema';
import { FormikDatePicker } from '../../../../../components/input/date/FormikDatePicker';
import { useQuery } from '../../../../../hooks/query-params.hook';
import { useProjectRequest } from '../../../hooks/project-request.hook';
import { FormikSelect } from '../../../../../components/select/FormikSelect';
import { useRequestStatusList } from '../../../hooks/request-status-list.hook';
import { useClient } from '../../../../clients/hooks/client.hook';
import { useProject } from '../../../hooks/project.hook';
import { apiClient } from '../../../../../api/apiClient';
import { FormikTextField } from '../../../../../components/input/FormikTextField';
import { EditableCheckboxDisplay } from '../../../../../components/input/checkbox/EditableCheckboxDisplay';
import { projectRequestThunk } from '../../../redux/project-requests.thunk';
import { FormikDateTimePicker } from '../../../../../components/input/date/FormikDateTimePicker';

interface AcceptOfferInput extends Omit<AcceptOfferDTO, 'projectNumber'> {
  projectNumber: string;
  firstAcquisition: boolean;
}

const acceptOfferView = buildDTOView<AcceptOfferInput>()
  .all()
  .withSchema(
    yup
      .object({
        order: yup.object().shape({
          deadline: yup.date().required('required') as any,
          orderDate: yup.date().required('required') as any
        }),
        requestStatusId: yup.string().required('required'),
        projectNumber: yup
          .string()
          .matches(/^[0-9]{5}-[0-9]{2}-[0-9]{2,5}/)
          .required(),
        firstAcquisition: yup.boolean().required()
      })
      .defined()
  );

export function AcceptOfferFormDialog() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const queryParams = useQuery();
  const { projectId } = useParams<{
    projectId: string;
  }>();
  const { project } = useProject(projectId);
  const { projectOffer } = useProjectOffer(
    project?.requestId,
    queryParams.get('offerId')
  );

  const { entities: requestStatusList } = useRequestStatusList();

  const { projectRequest } = useProjectRequest(project?.requestId);

  const { client } = useClient(project?.clientId ?? '');
  const [suggestedProjectNumber, setSuggestedProjectNumber] =
    React.useState<ProjectNumber>();

  useEffect(() => {
    if (client && client.clientNumber && project && !project.projectNumber) {
      apiClient
        .get<ProjectNumber>(`/projects/project/project-number/${project.id}`)
        .then((res) => {
          setSuggestedProjectNumber(res.data);
          return res;
        })
        .catch(NoOp);
    }
  }, [client, project]);
  let projectDeadlineDate: Date | null | undefined = project?.deadline;
  if (projectDeadlineDate) {
    try {
      if (!(projectDeadlineDate instanceof Date)) {
        projectDeadlineDate = new Date(projectDeadlineDate);
      }
      projectDeadlineDate = setMinutes(
        setHours(new Date(projectDeadlineDate), 17),
        0
      );
    } catch (e: any) {
      projectDeadlineDate = null;
    }
  }
  const acceptProjectOffer = (input: AcceptOfferInput) => {
    const numberParts = input.projectNumber.split('-');
    const projectNumber: ProjectNumberDTO = {
      clientNumber: numberParts[0],
      year: parseInt(numberParts[1], 10),
      sequentialNumber: parseInt(numberParts[2], 10),
      firstAcquisition: input.firstAcquisition
    };
    return dispatch(
      acceptProjectOfferThunk({
        projectId: project?.id || '',
        requestId: projectOffer?.requestId || '',
        id: projectOffer?.id || '',
        input: {
          ...omit(input, 'projectNumber', 'firstAcquisition'),
          projectNumber: project?.projectNumber ? null : projectNumber
        }
      })
    )
      .then(unwrapResult)
      .then((res) => {
        dispatch(projectRequestThunk.get({ id: projectRequest?.id || '' }));
        return res;
      })
      .catch(NoOp);
  };

  const initialValues: AcceptOfferInput = {
    order: {
      deadline: (projectDeadlineDate ?? null) as unknown as Date,
      orderDate: projectOffer?.offerDate || new Date()
    },
    requestStatusId:
      requestStatusList.filter((rs) => rs.type === 'accepted').length === 1
        ? requestStatusList.filter((rs) => rs.type === 'accepted')[0].id
        : '',
    projectNumber:
      suggestedProjectNumber || project?.projectNumber
        ? formatProjectNumber(
            suggestedProjectNumber ?? (project?.projectNumber as any)
          )
        : '',
    firstAcquisition:
      (suggestedProjectNumber ?? (project?.projectNumber as any))
        ?.firstAcquisition ?? false
  };

  return (
    <DialogForm
      identifier="accept-offer"
      label="Accept Offer"
      form={{
        initialValues,
        validationSchema: acceptOfferView.validationSchema
      }}
      api={{
        clearError: projectOffersSlice.actions.clearError,
        errorSelector: projectOfferSelectors.selectError,
        onSubmit: acceptProjectOffer,
        stateSelector: projectOfferSelectors.selectState
      }}
      actions={[{ label: t('Accept Offer'), doSubmit: true }]}
    >
      {!project ||
        !client ||
        (!suggestedProjectNumber && !project?.projectNumber && (
          <CircularProgress />
        ))}
      {client && !client.clientNumber && (
        <Alert severity="error">
          <AlertTitle>
            <Typography>
              {t('No client number has yet been assigned to client ')}
              {client.name}
            </Typography>
          </AlertTitle>
        </Alert>
      )}
      {!!project &&
        !!client &&
        (suggestedProjectNumber || project?.projectNumber) && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikSelect
                label={t('Status')}
                name={acceptOfferView.path.requestStatusId}
                options={requestStatusList
                  .filter((rs) => rs.type === 'accepted')
                  .map((rs) => ({
                    value: rs.id,
                    label: rs.name
                  }))}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDatePicker
                name={acceptOfferView.path.order.orderDate}
                label={t('Order Date')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikDateTimePicker
                timeOptional
                name={acceptOfferView.path.order.deadline}
                label={t('Deadline')}
              />
            </Grid>
            <Grid item xs={8}>
              {project?.projectNumber ? (
                <TextField
                  value={formatProjectNumber(project.projectNumber)}
                  label={t('Projectnumber')}
                  disabled
                />
              ) : (
                <FormikTextField
                  name={acceptOfferView.path.projectNumber}
                  label={t('Projectnumber')}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {!project?.projectNumber && (
                <EditableCheckboxDisplay
                  name={acceptOfferView.path.firstAcquisition}
                  label={t('First Acquisition')}
                />
              )}
            </Grid>
          </Grid>
        )}
    </DialogForm>
  );
}
