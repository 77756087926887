import { Supplier, SupplierPrice } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import { suppliersThunk } from './suppliers.thunk';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { supplierPricesThunk } from './supplier-prices.thunk';
import { RootState } from '../../../redux/store';

const { list, delete: deleteThunk, ...thunks } = supplierPricesThunk;

const supplierPricesSlice = createEntitySlice<SupplierPrice>(
  'supplier-prices',
  [
    CustomThunk.override<SupplierPrice>(list, {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.upsertMany(state, payload as SupplierPrice[]);
      }
    }),
    CustomThunk.override<SupplierPrice>(
      deleteThunk as AsyncThunk<
        EntityId,
        { supplierId: string; id: EntityId },
        any
      >,
      {
        fulfilled: (adapter, state, { payload }) => {
          state.state = 'idle';
          return adapter.removeOne(state, payload as string);
        }
      }
    ),
    CustomThunk.override<Supplier, SupplierPrice>(suppliersThunk.get, {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.upsertMany(state, (payload as Supplier).prices ?? []);
      }
    }),
    ...Object.values(thunks)
  ]
);
export const supplierPricesSelectors = {
  ...createAdapterSelectors('supplier-prices', supplierPricesSlice.adapter),
  selectBySupplier(state: RootState, supplierId: string) {
    return this.selectAll(state).filter((a) => a.supplierId === supplierId);
  }
};

export default supplierPricesSlice;
