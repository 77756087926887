import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import {
  Checkbox,
  createStyles,
  makeStyles,
  Typography
} from '@material-ui/core';
import { UserRoleTree, UserRoleTreeNode } from 'model';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    treeNodeLabel: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
);

function PermissionTreeElement({
  treeNode,
  onTogglePermission,
  disabled
}: {
  disabled?: boolean;
  treeNode: UserRoleTreeNode;
  onTogglePermission?: (id: number, granted: boolean) => void;
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <TreeItem
      nodeId={String(treeNode.id)}
      label={
        <div className={classes.treeNodeLabel}>
          <Typography color={disabled ? 'textSecondary' : 'textPrimary'}>
            {treeNode.name === 'app'
              ? t('Admin')
              : t(treeNode.name.replaceAll('.', '_'))}
          </Typography>
          <Checkbox
            disabled={disabled || !onTogglePermission}
            checked={disabled || treeNode.granted}
            onClick={(e) => e.stopPropagation()}
            onChange={() =>
              onTogglePermission &&
              onTogglePermission(treeNode.id, !treeNode.granted)
            }
          />
        </div>
      }
    >
      {treeNode.children.map((treeNodeChild) => (
        <PermissionTreeElement
          disabled={disabled || treeNode.granted}
          key={`permission-tree-item${treeNodeChild.id}`}
          treeNode={treeNodeChild}
          onTogglePermission={onTogglePermission}
        />
      ))}
    </TreeItem>
  );
}

function treeNodeToIds(treeNode: UserRoleTreeNode): string[] {
  return treeNode.children
    .flatMap((node) => treeNodeToIds(node))
    .concat([String(treeNode.id)]);
}

type PermissionTreeProps = {
  defaultExpanded?: boolean;
  roleTree: UserRoleTree;
  updateRolePermission?: (permissionId: number, granted: boolean) => void;
};

export function PermissionTree({
  roleTree,
  updateRolePermission,
  defaultExpanded = false
}: PermissionTreeProps) {
  return (
    <TreeView
      defaultExpanded={
        defaultExpanded
          ? treeNodeToIds(roleTree.permissionTree)
          : [String(roleTree.permissionTree.id)]
      }
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <PermissionTreeElement
        treeNode={roleTree.permissionTree}
        onTogglePermission={updateRolePermission}
      />
    </TreeView>
  );
}
