import { BaseEntity } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { createErrorHandlingThunk } from './thunk';
import { apiClient } from '../api/apiClient';

type ThunkIdArg = { id: EntityId };
export type PartialReference<Reference extends ThunkIdArg> = Omit<
  Reference,
  'id'
> &
  Partial<ThunkIdArg>;

export type EntityReference<
  Reference extends Record<string, EntityId> | undefined = undefined
> = Reference extends undefined ? ThunkIdArg : Reference & ThunkIdArg;
export interface FeatureThunks<
  Entity,
  CreateInput,
  UpdateInput,
  Reference extends Record<string, EntityId> | undefined = undefined
> {
  get: AsyncThunk<Entity, EntityReference<Reference>, Record<never, never>>;
  list: AsyncThunk<
    Entity[],
    Reference extends undefined ? void : Reference,
    Record<never, never>
  >;
  create: AsyncThunk<
    Entity,
    Reference extends undefined
      ? { input: CreateInput }
      : Reference & { input: CreateInput },
    Record<never, never>
  >;
  update: AsyncThunk<
    Entity,
    Reference extends undefined
      ? ThunkIdArg & { input: UpdateInput }
      : EntityReference<Reference> & { input: UpdateInput },
    Record<never, never>
  >;
  delete: AsyncThunk<
    EntityId,
    Reference extends undefined ? ThunkIdArg : Reference & ThunkIdArg,
    Record<never, never>
  >;
}

interface FeatureThunkConfig<
  Reference extends Record<string, EntityId> | undefined = undefined
> {
  urlFactory: (
    reference: Reference extends undefined
      ? Partial<ThunkIdArg>
      : Reference & Partial<ThunkIdArg>
  ) => string;
  thunkName: string;
  excludeList?: boolean;
}

export function createFeatureThunks<
  Entity extends BaseEntity,
  CreateInput,
  UpdateInput = CreateInput,
  Reference extends Record<string, EntityId> | undefined = undefined
>(
  config: FeatureThunkConfig<Reference>
): FeatureThunks<Entity, CreateInput, UpdateInput, Reference> {
  const thunkResult = {
    get: createErrorHandlingThunk<Entity, EntityReference<Reference>>(
      `${config.thunkName}/get`,
      async (ref) => {
        const result = await apiClient.get<Entity>(config.urlFactory(ref));
        return result.data;
      }
    ),
    create: createErrorHandlingThunk<
      Entity,
      Reference extends undefined
        ? { input: CreateInput }
        : Reference & { input: CreateInput }
    >(`${config.thunkName}/create`, async (args) => {
      const result = await apiClient.post<Entity>(
        config.urlFactory(omit(args, 'input') as any),
        args.input
      );
      // return result.data;
      return (await apiClient
        .get<Entity>(
          config.urlFactory({
            ...(omit(args, 'input', 'id') as any),
            id: result.data.id
          }),
          args.input
        )
        .then((r) => r.data)) as Entity;
    }),
    update: createErrorHandlingThunk<
      Entity,
      Reference extends undefined
        ? ThunkIdArg & { input: UpdateInput }
        : EntityReference<Reference> & { input: UpdateInput }
    >(`${config.thunkName}/update`, async (args) => {
      const result = await apiClient.put<Entity>(
        config.urlFactory(omit(args, 'input') as any),
        args.input
      );
      // return result.data;
      return (await apiClient
        .get<Entity>(
          config.urlFactory({
            ...(omit(args, 'input', 'id') as any),
            id: result.data.id as EntityId
          }),
          args.input
        )
        .then((r) => r.data)) as Entity;
    }),
    delete: createErrorHandlingThunk<EntityId, EntityReference<Reference>>(
      `${config.thunkName}/delete`,
      async (entityId) => {
        await apiClient.delete(config.urlFactory(entityId as any));
        return entityId.id;
      }
    )
  } as FeatureThunks<Entity, CreateInput, UpdateInput, Reference>;
  if (!config.excludeList) {
    thunkResult.list = createErrorHandlingThunk<
      Entity[],
      Reference extends undefined ? void : Reference
    >(`${config.thunkName}/list`, (async (ref: Reference) => {
      const result = await apiClient.get<Entity[]>(
        config.urlFactory((ref ?? {}) as any)
      );
      return result.data;
    }) as any);
  }
  return thunkResult;
}
