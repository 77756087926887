import React from 'react';
import { ActiveStatusType, permissions, SupplierStatusType } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Delete as DeleteIcon,
  Edit,
  Star as SetDefaultIcon
} from '@material-ui/icons';
import { entityColumnBuilder } from '../../../../components/table/columns.builder';
import {
  GenericTable,
  useCreateCells
} from '../../../../components/table/GenericTable';
import { ApiState } from '../../../auth/redux/types';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import {
  changeStatusTypeDefaultThunk,
  supplierStatusTypesThunk
} from '../../redux/status-type.thunk';

function buildTableColumns() {
  return entityColumnBuilder<SupplierStatusType>()
    .addDefaultColumn('status_type_type', 'statusType', 'Type', {
      createElement: (value) =>
        value === 'active' ? <span>Active</span> : <span>Inactive</span>
    })
    .addDefaultColumn('status_type_name', 'name', 'Name')
    .addDefaultColumn('status_type_description', 'description', 'Description')
    .addBooleanColumn(
      'status_type_default_type',
      'defaultType',
      'Default Status'
    )
    .addBooleanColumn(
      'status_type_constraints',
      (v) =>
        v.statusType === 'active'
          ? (v as ActiveStatusType).enforceActivationConstraints
          : null,
      'Only with validation'
    )
    .addBooleanColumn(
      'status_type_selectable',
      'selectable',
      'Selectable by User'
    )
    .addDefaultColumn(
      'status_type_expire_type',
      'expireType',
      'Has expire date'
    )
    .addDefaultColumn(
      'status_type_explanation_type',
      'explanationType',
      'Has/Needs explanation'
    ).columns;
}

interface SupplierStatusTypesTableProps {
  supplierStatusTypes: SupplierStatusType[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function SupplierStatusTypesTable({
  supplierStatusTypes,
  onRefresh,
  apiState
}: SupplierStatusTypesTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  const dispatch = useAppDispatch();
  const deleteStatusType = (id: string) => {
    dispatch(supplierStatusTypesThunk.delete({ id }));
  };
  const changeDefaultType = (id: string, type: 'active' | 'inactive') => {
    dispatch(changeStatusTypeDefaultThunk({ id, type }));
  };
  return (
    <GenericTable
      label={t('Status Types')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Status Type',
        onClick: () => history.push(`${url}?type=create#supplier-status-type`)
      }}
      data={supplierStatusTypes}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.suppliers.statusType.edit,
          entry: (statusType) => ({
            label: 'Edit',
            icon: Edit,
            link: `${url}?type=edit#${statusType.id}`
          })
        },
        {
          permissions: permissions.suppliers.statusType.delete,
          entry: (d) => ({
            icon: DeleteIcon,
            label: 'Delete',
            alert: {
              title: 'Delete Status Type',
              content: `${t(
                'Are you  sure you want to delete the Status Type '
              )} "${d.name}"? It may only be deleted if no references exist!`,
              onConfirm: () => {
                deleteStatusType(d.id);
              }
            }
          })
        },
        {
          permissions: permissions.suppliers.statusType.edit,
          entry: (type) =>
            type.defaultType === true
              ? null
              : {
                  icon: SetDefaultIcon,
                  label: 'Make Default Type',
                  disabled: !type.selectable,
                  onClick: () => {
                    changeDefaultType(type.id, type.statusType);
                  }
                }
        }
      ]}
      toolbarActions={
        [
          /*        {
                    icon: <PlusIcon />,
                    label: 'Merge',
                    showWhen: 'selected',
                    onClick: () => {}
                  } */
        ]
      }
    />
  );
}
