import React, { useEffect } from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar';
import { formatDate } from '../../localized-formats';

type MonthYearData = { month: number; year: number };
export interface TimeBarChartProps<
  T extends MonthYearData,
  D extends Record<string, number>
> {
  data: T[];
  calculator: (t: T) => D;
  label: string;
  keys: (string & keyof D)[];
}

export function TimeCarChart<
  T extends MonthYearData,
  D extends Record<string, number>
>({ data, calculator, label, keys }: TimeBarChartProps<T, D>) {
  const preparedData = data
    .map((d) => {
      const date = new Date();
      date.setFullYear(d.year, d.month - 1, 1);
      const year = formatDate(date, 'month-year-short');
      return {
        ...d,
        date,
        monthPlusYear: year,
        ...calculator(d)
      };
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime())
    .slice(-12);

  return (
    <Card style={{ position: 'relative', height: '22em', padding: '5px' }}>
      <CardContent
        style={{ position: 'relative', height: '95%', padding: '5px' }}
      >
        <Typography variant="h5" style={{ marginLeft: 20 }}>
          {label}
        </Typography>
        <ResponsiveBar
          data={preparedData}
          indexBy="monthPlusYear"
          keys={keys}
          margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'nivo' }}
          defs={[
            {
              id: 'lines',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
            }
          ]}
          fill={keys.map((k) => ({ match: { id: k }, id: 'dots' }))}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisLeft={{
            format: (e) => Math.floor(e) === e && e,
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0
            // tickSize: 5,
            // tickPadding: 5,
            // tickRotation: 0
            // legend: 'Amount'
            // // legendPosition: 'middle'
            // legendOffset: -40
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          legends={[]}
          animate={false}
          motionStiffness={90}
          motionDamping={15}
        />
      </CardContent>
    </Card>
  );
}

export interface TimeBarChartWidgetProps<
  T extends MonthYearData,
  D extends Record<string, number>
> {
  loadData: () => Promise<T[]>;
  calculator: (t: T) => D;
  label: string;
  keys: (string & keyof D)[];
}

export function TimeBarChartWidget<
  T extends MonthYearData,
  D extends Record<string, number>
>({ loadData, calculator, label, keys }: TimeBarChartWidgetProps<T, D>) {
  const [data, setData] = React.useState<T[]>([]);

  useEffect(() => {
    loadData()
      .then((res) => setData(res))
      .catch(() => {});
  }, []);

  return (
    <TimeCarChart
      data={data}
      calculator={calculator}
      label={label}
      keys={keys}
    />
  );
}
