import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { currencyMap, DefaultCurrency } from 'model';
import NumberFormat from 'react-number-format';

interface PriceDisplayProps {
  amount?: number | null;
  currencyCode?: string | null;
  defaultCurrencyCode?: string | null;
  typographyProps?: TypographyProps;
}

interface MoneyFormatProps {
  value?: string | number | null;
}

function MoneyFormat({ value }: MoneyFormatProps) {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator={String.fromCharCode(160)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderText={(v: any) => <>{v}</>}
      isNumericString
      decimalSeparator=","
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
    />
  );
}

const defaultCode = DefaultCurrency.code as keyof typeof currencyMap;

export function PriceDisplay(props: PriceDisplayProps) {
  return (
    <Typography {...props.typographyProps}>
      <MoneyFormat value={props.amount} />
      <span>
        {props.amount
          ? ` ${
              currencyMap[
                (props.currencyCode ??
                  props.defaultCurrencyCode ??
                  defaultCode) as keyof typeof currencyMap
              ].symbol ?? ''
            }`
          : ''}
      </span>
    </Typography>
  );
}
