import { RequestResource, ResourceRequestDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const requestResourceThunk = createFeatureThunks<
  RequestResource,
  ResourceRequestDTO,
  ResourceRequestDTO,
  { requestId: string }
>({
  urlFactory: ({ id, requestId } = { id: undefined, requestId: '' }) =>
    `/projects/project-request/${requestId}/resources${
      id !== undefined ? `/${id}` : ''
    }`,
  thunkName: 'request-resource'
});
