"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestResourcePermission = exports.RequestResource = exports.ResourceTypes = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const ProjectRequest_1 = require("./ProjectRequest");
const CircularReference_1 = require("../../serialization/CircularReference");
// eslint-disable-next-line import/no-cycle
const UploadFile_1 = require("../../files/entity/UploadFile");
// eslint-disable-next-line import/no-cycle
const RequestComment_1 = require("./RequestComment");
const RequestAttachmentGrant_1 = require("./RequestAttachmentGrant");
exports.ResourceTypes = {
    text: {
        name: 'Text',
        description: 'Some Text'
    },
    image: {
        name: 'Images',
        description: 'Image material'
    },
    media: {
        name: 'Media',
        description: 'audio or video data'
    },
    other: {
        name: 'Other',
        description: 'Other resources'
    }
};
let RequestResource = class RequestResource extends JournaledEntity_1.JournaledEntity {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectRequest_1.ProjectRequest, (pr) => pr.positions),
    (0, CircularReference_1.CircularRef)(ProjectRequest_1.ProjectRequest),
    __metadata("design:type", ProjectRequest_1.ProjectRequest)
], RequestResource.prototype, "request", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rc) => rc.request),
    __metadata("design:type", String)
], RequestResource.prototype, "requestId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], RequestResource.prototype, "type", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], RequestResource.prototype, "name", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], RequestResource.prototype, "description", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], RequestResource.prototype, "available", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'timestamp', nullable: true }),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], RequestResource.prototype, "estimatedDelivery", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => UploadFile_1.UploadFile),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], RequestResource.prototype, "attachedFiles", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => RequestComment_1.RequestComment, (rc) => rc.resource),
    __metadata("design:type", Array)
], RequestResource.prototype, "comments", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rc) => rc.comments),
    __metadata("design:type", Array)
], RequestResource.prototype, "commentIds", void 0);
RequestResource = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], RequestResource);
exports.RequestResource = RequestResource;
exports.requestResourcePermission = (0, RequestAttachmentGrant_1.defineRequestAttachmentPermission)('projectRequestResource', (a) => new RequestAttachmentGrant_1.RequestAttachmentGrant({
    type: 'projectRequestResource',
    id: a.id,
    request: a.requestId
}));
