import {
  createEntityAdapter,
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit';
import { ProjectCandidate } from 'model';
import {
  addProjectCandidateThunk,
  listProjectCandidatesThunk,
  makeProjectCandidateDecisionThunk,
  removeProjectCandidateDecisionThunk,
  removeProjectCandidateThunk,
  updateProjectCandidateThunk
} from './projects.thunk';
import { createAdapterSelectors } from '../../../redux/enitySlice';
import { getInitialApiAdapterState } from '../../auth/redux/types';

export type ProjectCandidates = {
  id: string;
  apiState: 'fresh' | 'loading' | 'error' | 'done';
} & (
  | { dataState: 'fetched'; candidates: ProjectCandidate[] }
  | { dataState: 'none'; candidates?: undefined }
);

export const candidatesAdapter = createEntityAdapter<ProjectCandidates>();
export const projectCandidatesSlice = createSlice({
  name: 'project-candidates',
  initialState: candidatesAdapter.getInitialState(getInitialApiAdapterState()),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isFulfilled(listProjectCandidatesThunk),
      (state, { payload, meta }) => {
        return candidatesAdapter.upsertOne(state, {
          id: meta.arg.projectId,
          apiState: 'done',
          dataState: 'fetched',
          candidates: payload
        });
      }
    );
    builder.addMatcher(
      isFulfilled(addProjectCandidateThunk),
      (state, { payload, meta }) => {
        return candidatesAdapter.upsertOne(state, {
          ...(state.entities[meta.arg.projectId] ?? {
            id: meta.arg.projectId,
            dataState: 'none'
          }),
          apiState: 'done',
          dataState: 'fetched',
          candidates: [
            ...(state.entities[payload.projectId]?.candidates ?? []),
            payload
          ]
        });
      }
    );
    builder.addMatcher(
      isFulfilled(updateProjectCandidateThunk),
      (state, { payload, meta }) => {
        return candidatesAdapter.upsertOne(state, {
          ...(state.entities[meta.arg.projectId] ?? {
            id: meta.arg.projectId,
            dataState: 'none'
          }),
          apiState: 'done',
          dataState: 'fetched',
          candidates: [
            ...(state.entities[payload.projectId]?.candidates?.filter(
              (c) => c.id !== meta.arg.candidateId
            ) ?? []),
            payload
          ]
        });
      }
    );
    builder.addMatcher(
      isFulfilled(removeProjectCandidateThunk),
      (state, { payload }) => {
        return candidatesAdapter.upsertOne(state, {
          id: payload.projectId,
          apiState: 'done',
          dataState: 'fetched',
          candidates: [
            ...(state.entities[payload.projectId]?.candidates?.filter(
              (c) => c.id !== payload.id
            ) ?? []),
            payload
          ]
        });
      }
    );
    builder.addMatcher(
      isFulfilled(makeProjectCandidateDecisionThunk),
      (state, { payload }) => {
        return candidatesAdapter.upsertOne(state, {
          id: payload.projectId,
          apiState: 'done',
          dataState: 'fetched',
          candidates:
            state.entities[payload.projectId]?.candidates?.map((c) =>
              c.id !== payload.id ? c : payload
            ) ?? []
        });
      }
    );
    builder.addMatcher(
      isFulfilled(removeProjectCandidateDecisionThunk),
      (state, { payload }) => {
        return candidatesAdapter.upsertOne(state, {
          id: payload.projectId,
          apiState: 'done',
          dataState: 'fetched',
          candidates:
            state.entities[payload.projectId]?.candidates?.map((c) =>
              c.id !== payload.id ? c : payload
            ) ?? []
        });
      }
    );
    builder.addMatcher(
      isPending(
        addProjectCandidateThunk,
        removeProjectCandidateDecisionThunk,
        listProjectCandidatesThunk,
        makeProjectCandidateDecisionThunk,
        removeProjectCandidateDecisionThunk
      ),
      (state, action) => {
        return candidatesAdapter.upsertOne(state, {
          ...(state.entities[action.meta.arg.projectId] ?? {
            id: action.meta.arg.projectId,
            dataState: 'none'
          }),
          apiState: 'loading'
        });
      }
    );
    builder.addMatcher(
      isRejected(
        addProjectCandidateThunk,
        removeProjectCandidateDecisionThunk,
        listProjectCandidatesThunk,
        makeProjectCandidateDecisionThunk,
        removeProjectCandidateDecisionThunk
      ),
      (state, action) => {
        return candidatesAdapter.upsertOne(state, {
          ...(state.entities[action.meta.arg.projectId] ?? {
            id: action.meta.arg.projectId,
            dataState: 'none'
          }),
          apiState: 'error'
        });
      }
    );
  }
});

export const candidatesSelectors = createAdapterSelectors(
  'project-candidates',
  candidatesAdapter
);
