import React from 'react';
import { Grid } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  KeyboardTimePicker
} from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { isAfter, isBefore } from 'date-fns';
import { BaseTableRow } from '../TableDataCell';
import { ColumnFilter } from './column-filter.types';
import { DateFormatType, DateTimeFormats } from '../../../localized-formats';

const DateFilterInputs: Record<
  DateFormatType,
  (props: {
    title: string;
    value: Date | string | null;
    onChange: (value: Date | null) => void;
  }) => JSX.Element
> = {
  // eslint-disable-next-line react/prop-types
  'date-time': ({ title, value, onChange }) => {
    return (
      <KeyboardDateTimePicker
        fullWidth
        disableToolbar
        variant="inline"
        format={DateTimeFormats['date-time'].default}
        autoOk
        label={title}
        value={value}
        onChange={(date) => onChange(date)}
      />
    );
  },
  // eslint-disable-next-line react/prop-types
  'month-year': ({ title, value, onChange }) => {
    return (
      <KeyboardDateTimePicker
        fullWidth
        disableToolbar
        variant="inline"
        format={DateTimeFormats['month-year'].default}
        autoOk
        label={title}
        value={value}
        onChange={(date) => onChange(date)}
      />
    );
  },
  // eslint-disable-next-line react/prop-types
  'month-year-short': ({ title, value, onChange }) => {
    return (
      <KeyboardDateTimePicker
        fullWidth
        disableToolbar
        variant="inline"
        format={DateTimeFormats['month-year-short'].default}
        autoOk
        label={title}
        value={value}
        onChange={(date) => onChange(date)}
      />
    );
  },
  // eslint-disable-next-line react/prop-types
  date: ({ title, value, onChange }) => {
    return (
      <KeyboardDatePicker
        fullWidth
        disableToolbar
        variant="inline"
        format={DateTimeFormats.date.default}
        autoOk
        label={title}
        value={value}
        onChange={(date) => onChange(date)}
      />
    );
  },
  // eslint-disable-next-line react/prop-types
  time: ({ title, value, onChange }) => {
    return (
      <KeyboardTimePicker
        fullWidth
        disableToolbar
        variant="inline"
        format={DateTimeFormats.time.default}
        autoOk
        label={title}
        value={value}
        onChange={(date) => onChange(date)}
      />
    );
  }
};

function DateFilterComponent(props: {
  nullable: boolean;
  type: DateFormatType;
  value: DateFilterState;
  onDateChange: (target: 'from' | 'to', value: Date | null) => void;
  onOptionsChange: (value: DateFilterState['options']) => void;
}) {
  const { t } = useTranslation();
  const InputComponent = DateFilterInputs[props.type];
  return (
    <Grid container xs={12}>
      {props.nullable && (
        <Grid item xs={12}>
          <ToggleButtonGroup
            size="small"
            value={props.value.options.nullMode}
            exclusive
            onChange={(_, v) => {
              if (v) props.onOptionsChange({ nullMode: v });
            }}
          >
            <ToggleButton value="only-null" title={t('Show only empty values')}>
              <EventBusyIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton
              value="any-value"
              title={t('show empty and non-empty values')}
            >
              <CalendarTodayIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton
              value="non-null"
              title={t('show only non-empty values')}
            >
              <EventAvailableIcon fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      )}
      {(!props.nullable || props.value.options.nullMode !== 'only-null') && (
        <>
          <Grid item xs={12}>
            <InputComponent
              title={t('from')}
              value={props.value.dateBounds.from ?? null}
              onChange={(date) => props.onDateChange('from', date)}
            />
          </Grid>
          <Grid item xs={12}>
            <InputComponent
              title={t('to')}
              value={props.value.dateBounds.to ?? null}
              onChange={(date) => props.onDateChange('to', date)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

type DateFilterState = {
  dateBounds: {
    from: Date | string | null;
    to: Date | string | null;
  };
  options: {
    nullMode: 'only-null' | 'any-value' | 'non-null';
  };
};

export function DateColumnFilter<T extends BaseTableRow>({
  nullable = false,
  type = 'date',
  overlayOnFocus
}: {
  nullable?: boolean;
  type?: DateFormatType;
  overlayOnFocus?: boolean;
}): ColumnFilter<T, Date | string | null, DateFilterState> {
  return {
    overlayOnFocus,
    initialState: {
      dateBounds: {
        from: null,
        to: null
      },
      options: {
        nullMode: 'any-value'
      }
    },
    createFilter: (
      cell,
      { options: { nullMode }, dateBounds: { from: fromBound, to: toBound } }
    ) => {
      return (value) => {
        if (value === null || value === undefined) {
          return nullMode !== 'non-null';
        }
        if (nullMode === 'only-null') {
          return false;
        }
        const dateValue = typeof value === 'string' ? new Date(value) : value;
        const dateFrom =
          typeof fromBound === 'string' ? new Date(fromBound) : fromBound;
        const dateTo =
          typeof toBound === 'string' ? new Date(toBound) : toBound;
        return (
          (dateFrom === null || isAfter(dateValue, dateFrom)) &&
          (dateTo === null || isBefore(dateValue, dateTo))
        );
      };
    },
    // eslint-disable-next-line react/prop-types
    component: ({ filterState, onFilterChange }) => (
      <DateFilterComponent
        nullable={nullable}
        type={type}
        value={filterState}
        onDateChange={(t, v) =>
          onFilterChange({
            ...filterState,
            // eslint-disable-next-line react/prop-types
            dateBounds: { ...filterState.dateBounds, [t]: v }
          })
        }
        onOptionsChange={(o) => onFilterChange({ ...filterState, options: o })}
      />
    )
  };
}
