"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.permissionsList = exports.permissions = void 0;
const utils_1 = require("./utils");
exports.permissions = (0, utils_1.createPermissionsMap)({
    dashboard: {
        revenues: true,
        newClients: true,
        projectAcquisitions: true,
        newUsers: true,
        projects: true,
        requests: true,
        templates: true
    },
    files: {
        upload: true,
        download: true,
        rename: true
    },
    translations: {
        manage: true
    },
    users: {
        permission: {
            list: true,
            changeStatus: true
        },
        role: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true
        },
        user: {
            create: true,
            screen: true,
            list: true,
            delete: true,
            edit: true,
            patch: true
        }
    },
    language: {
        screen: true,
        create: true,
        edit: true,
        delete: true
    },
    suppliers: {
        supplier: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true,
            documents: {
                tab: true
            },
            prices: {
                tab: true,
                define: true,
                overrideBound: true
            }
        },
        domain: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true,
            merge: true
        },
        supplierCategory: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        },
        qualification: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        },
        statusType: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        },
        testType: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        },
        documentType: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        }
    },
    clients: {
        client: {
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true,
            screen: true,
            prices: {
                tab: true,
                define: true,
                overrideBound: true
            },
            clientNumber: {
                create: true,
                edit: true,
                delete: true
            }
        }
    },
    prices: {
        priceUnit: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            details: true
        },
        // priceBound: {
        //   screen: true,
        //   create: true,
        //   edit: true,
        //   delete: true,
        //   details: true
        // },
        priceLimit: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            details: true
        }
    },
    services: {
        serviceClass: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        },
        serviceStep: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        }
    },
    project: {
        projectVariation: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        },
        large: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        },
        projectTag: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        },
        project: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        },
        projectRequest: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true,
            offers: {
                tabs: true
            }
        },
        requestStatus: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        },
        requestSource: {
            screen: true,
            create: true,
            edit: true,
            delete: true,
            list: true,
            details: true
        }
    }
}, 'app');
function permissionsToList(permission) {
    return [
        permission,
        ...permission.children.flatMap((c) => permissionsToList(c))
    ];
}
exports.permissionsList = permissionsToList(exports.permissions);
