import {
  ServiceStepDTO,
  ServiceStepPatchDTO,
  serviceStepPatchTransformer
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { NoOp } from '../../../utils';
import { DialogForm } from '../../../components/input/form/DialogForm';
import { serviceStepsThunk } from '../redux/service-steps.thunk';
import {
  ServiceStepBasicForm,
  ServiceStepBasicView
} from './ServiceStepBasicForm';
import serviceStepsSlice, {
  serviceStepsSelectors
} from '../redux/service-steps.slice';

const initialValues: ServiceStepDTO = {
  name: '',
  description: '',
  displayFormat: '',
  assigneeTypes: { supplier: { required: true, constraints: false } },
  scopes: {
    'service-lane': {
      required: false,
      phase: 'provision'
    }
  },
  priceable: false,
  defaultStep: false
};

export function ServiceStepFormDialog({ stepId }: { stepId?: string }) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const serviceStep = useAppSelector((state) =>
    serviceStepsSelectors.selectById(state, stepId ?? '')
  );

  const serviceStepDTO = serviceStep
    ? serviceStepPatchTransformer(serviceStep)
    : undefined;

  const createServiceStep = (dto: ServiceStepDTO | ServiceStepPatchDTO) => {
    if (serviceStep) {
      // TODO für manu: hier object struktur anpassen
      return dispatch(
        serviceStepsThunk.update({
          id: serviceStep.id,
          input: {
            ...dto
            // assigneeType: dto.assigneeType?.length ? dto.assigneeType : null
          }
        })
      )
        .then(unwrapResult)
        .catch(NoOp);
    }
    // TODO für manu: hier object struktur anpassen
    return dispatch(
      serviceStepsThunk.create({
        input: {
          ...dto
          // assigneeType: dto.assigneeType?.length ? dto.assigneeType : null
        } as ServiceStepDTO
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <DialogForm
      identifier={serviceStep?.id ?? 'service-step'}
      maxWidth="md"
      label={serviceStep?.id ? 'Edit Service Step' : 'New Service Step'}
      form={{
        initialValues: serviceStepDTO ?? initialValues,
        validationSchema: ServiceStepBasicView.validationSchema
      }}
      api={{
        clearError: serviceStepsSlice.actions.clearError,
        errorSelector: serviceStepsSelectors.selectError,
        onSubmit: createServiceStep,
        stateSelector: serviceStepsSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <ServiceStepBasicForm />
    </DialogForm>
  );
}
