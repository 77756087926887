import React from 'react';
import { Client, ClientAddress, permissions } from 'model';
import { Avatar, Button, Card, CardContent, Grid } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessIcon from '@material-ui/icons/Business';
import MinusIcon from '@material-ui/icons/Remove';
import PlusIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Edit as EditIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { patchClientThunk } from '../redux/actions/patch-client.thunk';
import { CreateAddressCard, EditAddressCard } from './AddressCardEditor';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import {
  ActionsMenu,
  PermittedActionMenuEntry,
  useActionMenuItems
} from '../../../components/actions/ActionsMenu';
import { deleteClientAddressThunk } from '../redux/actions/delete-client-address.thunk';
import { AddressDisplay } from '../../../components/address/AddressDisplay';

function ClientAddressActions({
  client,
  address
}: {
  client: Client;
  address: ClientAddress;
}) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { reference } = useEditorLocation();

  const updateAddressStatus = (
    type: 'invoiceAddressId' | 'headQuarterAddressId',
    value: string | null
  ) =>
    dispatch(patchClientThunk({ id: client.id, dto: { [type]: value } }))
      .then(unwrapResult)
      .catch((res) => res);

  const { url } = useRouteMatch();

  const addressStatusAction = (
    type: 'invoiceAddress' | 'headQuarterAddress',
    name: string
  ): PermittedActionMenuEntry => {
    const hasStatus = address.id === client[type]?.id;
    const onClick = () => {
      updateAddressStatus(
        `${type}Id` as 'invoiceAddressId' | 'headQuarterAddressId',
        hasStatus ? null : address.id
      );
    };
    return {
      entry: { icon: hasStatus ? MinusIcon : PlusIcon, onClick, label: name },
      permissions: permissions.clients.client.edit
    };
  };
  const actionItems = useActionMenuItems([
    addressStatusAction('invoiceAddress', 'invoice'),
    addressStatusAction('headQuarterAddress', 'headquarter'),
    {
      entry: {
        icon: EditIcon,
        label: 'Edit',
        link: `${url}?type=edit#${address.id}`
      },
      permissions: permissions.clients.client.edit
    },
    {
      permissions: permissions.clients.client.edit,
      entry: {
        icon: DeleteIcon,
        label: 'Remove',

        onClick: () => {
          dispatch(
            deleteClientAddressThunk({
              clientId: client.id,
              addressId: address.id
            })
          );
          if (address.id === reference) {
            history.replace(url);
          }
        }
      }
    }
  ]);

  return <ActionsMenu items={actionItems} />;
}

export function ClientAddressComponent({
  client,
  address
}: {
  client: Client;
  address: ClientAddress;
}) {
  return (
    <Card variant="outlined" id={address.id}>
      <CardContent
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div style={{ flex: 2 }}>
          <AddressDisplay address={address} />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end'
          }}
        >
          {address.id === client.invoiceAddress?.id && (
            <Avatar
              title="Invoice Address"
              style={{
                marginRight:
                  address.id === client.headQuarterAddress?.id ? 3 : undefined
              }}
            >
              <AttachMoneyIcon />
            </Avatar>
          )}
          {address.id === client.headQuarterAddress?.id && (
            <Avatar title="Headquarter Address">
              <BusinessIcon />
            </Avatar>
          )}
          <ClientAddressActions client={client} address={address} />
        </div>
      </CardContent>
    </Card>
  );
}
export interface ClientAddressesProps {
  client: Client;
}

export function ClientAddresses({ client }: ClientAddressesProps) {
  const addressSortOrder = (address: ClientAddress) => {
    if (address.id === client.invoiceAddress?.id) {
      return 0;
    }
    if (address.id === client.headQuarterAddress?.id) {
      return 1;
    }
    return 2;
  };
  const sortedAddresses = [...client.addresses].sort(
    (a, b) => addressSortOrder(a) - addressSortOrder(b)
  );
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const { type, reference } = useEditorLocation();

  return (
    <>
      <Button
        size="small"
        disabled={type !== 'view'}
        variant="outlined"
        color="primary"
        onClick={() => history.push(`${url}?type=create`)}
        startIcon={<PlusIcon />}
      >
        {t('New Address')}
      </Button>

      <Grid container spacing={2} style={{ marginTop: 20 }}>
        {type === 'create' && <CreateAddressCard client={client} />}
        {sortedAddresses.map((address) => {
          return reference === address.id && type === 'edit' ? (
            <Grid xs={12} sm={6} md={6} lg={6}>
              <EditAddressCard
                key={address.id}
                client={client}
                address={address}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <ClientAddressComponent
                key={address.id}
                client={client}
                address={address}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
