"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serviceClassPathTransformer = exports.ServiceClassPatchDTO = void 0;
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const ServiceClassDTO_1 = require("./ServiceClassDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const ServiceClass_1 = require("../entity/ServiceClass");
const ServiceQualification_1 = require("../entity/ServiceQualification");
const ServiceQualificationDTO_1 = require("./ServiceQualificationDTO");
class ServiceClassPatchDTO extends (0, partial_type_helper_1.PartialType)(ServiceClassDTO_1.ServiceClassDTO) {
}
exports.ServiceClassPatchDTO = ServiceClassPatchDTO;
exports.serviceClassPathTransformer = (0, DTOTransformer_1.createDTOTransformer)(ServiceClass_1.ServiceClass, ServiceClassPatchDTO, ['id', 'createdAt', 'updatedAt', 'usablePriceUnits'], {
    defaultPriceUnit: (0, DTOTransformer_1.transformId)('defaultPriceUnitId'),
    requiredQualifications: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createDTOTransformer)(ServiceQualification_1.ServiceQualification, ServiceQualificationDTO_1.ServiceQualificationDTO, ['serviceClass', 'serviceStep', 'qualification'], {}), 'requiredQualifications')
});
