import { SupplierDomain } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { supplierDomainsThunk } from './supplier-domains.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';

const { list, delete: deleteThunk, ...thunks } = supplierDomainsThunk;

const supplierDomainsSlice = createEntitySlice<SupplierDomain>(
  'supplier-domains',
  [
    CustomThunk.override<SupplierDomain>(list, {
      pending: (_, state) => {
        state.state = 'pending';
        state.listFetched = undefined;
        state.error = null;
        return state;
      },
      rejected: (_, state, action) => {
        state.state = 'idle';
        state.listFetched = false;
        state.error = (action as any).error;
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        state.listFetched = true;
        return adapter.upsertMany(state, payload as SupplierDomain[]);
      }
    }),
    CustomThunk.override<SupplierDomain>(
      deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
      {
        fulfilled: (adapter, state, { payload }) => {
          state.state = 'idle';
          return adapter.removeOne(state, payload as string);
        }
      }
    ),
    ...Object.values(thunks)
  ]
);
export const supplierDomainsSelectors = createAdapterSelectors(
  'supplier-domains',
  supplierDomainsSlice.adapter
);

export const supplierDomainAPI: EntityAPI<SupplierDomain> = {
  selectors: supplierDomainsSelectors,
  thunks: supplierDomainsThunk
};
export default supplierDomainsSlice;
