import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../redux/redux.hooks';
import { supplierAttachmentsSelectors } from '../redux/supplier-attachments.slice';

export function useAttachmentsOfSupplier(supplierId: string) {
  const attachments = useAppSelector(
    (state) => supplierAttachmentsSelectors.selectBySupplier(state, supplierId),
    shallowEqual
  );
  return { attachments };
}
