"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sequenceTemplatePatchTransformer = exports.StepSequenceTemplatePatchDTO = void 0;
const StepSequenceTemplateDTO_1 = require("./StepSequenceTemplateDTO");
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const StepSequenceTemplate_1 = require("../entity/StepSequenceTemplate");
const SequenceTemplateStep_1 = require("../entity/SequenceTemplateStep");
const SequenceTemplateStepDTO_1 = require("./SequenceTemplateStepDTO");
class StepSequenceTemplatePatchDTO extends (0, partial_type_helper_1.PartialType)(StepSequenceTemplateDTO_1.StepSequenceTemplateDTO) {
}
exports.StepSequenceTemplatePatchDTO = StepSequenceTemplatePatchDTO;
exports.sequenceTemplatePatchTransformer = (0, DTOTransformer_1.createDTOTransformer)(StepSequenceTemplate_1.StepSequenceTemplate, StepSequenceTemplatePatchDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'projectVariation',
    'serviceClass',
    'scope',
    'childPosition'
], {
    sequenceSteps: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createDTOTransformer)(SequenceTemplateStep_1.SequenceTemplateStep, SequenceTemplateStepDTO_1.SequenceTemplateStepDTO, ['position', 'stepSequenceTemplate', 'serviceStep'], {}), 'sequenceSteps')
});
