import React from 'react';
import { Supplier, SupplierLargeScaleProjectAssignment } from 'model';
import {
  CircularProgress,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core';
import { useLargeScaleProject } from '../../../projects/hooks/large-scale-project.hook';
import { LspAssignmentTableHeader } from './LspAssignmentTableHeader';
import { StyledLink } from '../../../../components/input/link/StyledLink';

type LspAssignmentRowProps = {
  assignment: SupplierLargeScaleProjectAssignment;
};

function LspAssignmentRow({ assignment }: LspAssignmentRowProps) {
  const largeScaleProject = useLargeScaleProject(
    assignment.largeScaleProjectId
  );

  if (!largeScaleProject) {
    return <CircularProgress />;
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row" width={200}>
        <StyledLink
          to={`/projects/large-scale-projects/${largeScaleProject.id}`}
        >
          {largeScaleProject.name}
        </StyledLink>
      </TableCell>
      <TableCell>{assignment.comment}</TableCell>
      <TableCell>{assignment.active ? 'active' : 'inactive'}</TableCell>
      <TableCell align="right">{assignment.weeklyHours}</TableCell>
    </TableRow>
  );
}

export function LspAssignmentsTable({ value }: { value: Supplier }) {
  return (
    <TableContainer>
      <Table>
        <LspAssignmentTableHeader />
        {value.largeScaleProjectAssignments.map((lspAssignment) => (
          <LspAssignmentRow assignment={lspAssignment} />
        ))}
      </Table>
      <Typography
        color="textSecondary"
        style={{
          textAlign: 'end',
          marginTop: 10,
          marginRight: 16,
          fontWeight: 'bold'
        }}
      >
        {value.largeScaleProjectAssignments.reduce(
          (acc, cur) => acc + Number(cur.weeklyHours),
          0
        )}
      </Typography>
    </TableContainer>
  );
}
