import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { listCatTools } from '../redux/cat-toos.thunk';
import { catToolsSelector } from '../redux/cat-tool.slice';

export function useCatTools() {
  const dispatch = useAppDispatch();

  const catTools = useAppSelector(catToolsSelector.selectAll);

  const apiState = useAppSelector(catToolsSelector.selectState);

  const listAllCatTools = () => dispatch(listCatTools());

  React.useEffect(() => {
    listAllCatTools();
  }, []);

  return { catTools, apiState, listAllCatTools };
}
