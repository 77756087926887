import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { ColumnKey } from '../types';
import { useTableColumn } from '../context/table-column.hook';
import { useTableFilterControl } from './column-filter-control.hook';

export function ColumnHeaderFilter<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>({ columnKey }: { columnKey: ColumnKey<T, C> }) {
  const { filter, ...cell } = useTableColumn<T, C>(columnKey);
  const { state, active, resetState, changeState } = useTableFilterControl<
    T,
    C
  >(columnKey);
  if (filter === undefined || !active) {
    return <></>;
  }
  const Component = filter.component;
  return (
    <Component
      cell={cell}
      filterState={state}
      onFilterChange={changeState}
      onFilterReset={resetState}
    />
  );
}
