import { Client } from 'model';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { patchClientThunk } from './actions/patch-client.thunk';
import { updateAddressThunk } from './actions/update-address.thunk';
import { createAddressThunk } from './actions/create-address.thunk';
import { loadClientThunk } from './actions/load-client.thunk';
import { deleteClientAddressThunk } from './actions/delete-client-address.thunk';
import { createClientThunk } from './actions/create-client.thunk';
import { createClientContactThunk } from './actions/create-client-contact.thunk';
import { updateClientContactThunk } from './actions/patch-client-contact.thunk';
import { deleteClientContactThunk } from './actions/delete-client-contact.thunk';
import { createClientCommentThunk } from './actions/create-client-comment.thunk';
import { listClientsThunk } from './actions/list-clients.thunk';
import { deleteClientThunk } from './actions/delete-client.thunk';
import { clientsThunk } from './clients.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import {
  assignClientNumberThunk,
  removeClientNumberThunk,
  updateClientNumberThunk
} from './actions/client-number.thunk';
import { defineMinimumClientPrice } from './actions/client-prices.thunk';

const clientSlice = createEntitySlice<Client>('clients', [
  CustomThunk.override<Client, Client>(listClientsThunk, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as Client[]);
    }
  }),
  CustomThunk.override<Client>(deleteClientThunk, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.removeOne(state, payload as string);
    }
  }),
  CustomThunk.override<any, any>(assignClientNumberThunk, {
    fulfilled: (adapter, state) => {
      state.state = 'idle';
      return state;
    }
  }) as any,
  CustomThunk.override<any, any>(updateClientNumberThunk, {
    fulfilled: (adapter, state) => {
      state.state = 'idle';
      return state;
    }
  }) as any,
  CustomThunk.override<any, any>(removeClientNumberThunk, {
    fulfilled: (adapter, state) => {
      state.state = 'idle';
      return state;
    }
  }) as any,
  patchClientThunk,
  updateAddressThunk,
  createAddressThunk,
  loadClientThunk,
  deleteClientAddressThunk,
  createClientThunk,
  createClientContactThunk,
  updateClientContactThunk,
  deleteClientContactThunk,
  createClientCommentThunk,
  defineMinimumClientPrice
]);

export const clientSelectors = createAdapterSelectors(
  'clients',
  clientSlice.adapter
);

export const clientAPI: EntityAPI<Client> = {
  selectors: clientSelectors,
  thunks: clientsThunk
};
export default clientSlice;
