import { AddressDTO, Client, ClientAddress } from 'model';
import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Card, CardContent, IconButton, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { ErrorBoundary } from '../../error/ErrorBoundary';
import { NoOp } from '../../../utils';
import { updateAddressThunk } from '../redux/actions/update-address.thunk';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { AddressFormElement } from './AddressFormElement';
import { createAddressThunk } from '../redux/actions/create-address.thunk';

interface ClientAddressEditViewProps {
  initial: AddressDTO;
  save: (dto: AddressDTO) => Promise<Client | void>;
  finish: (client: Client | void) => void;
  title: string;
}

function ClientAddressEditView({
  initial,
  save,
  finish,
  title
}: ClientAddressEditViewProps) {
  const [isCreationSuccess, setIsCreationSuccess] = React.useState(false);

  return (
    <ErrorBoundary context="client-addresses-edit">
      <Prompt
        when={!isCreationSuccess}
        message="all data will be lost if you navigate now!"
      />
      <Formik<AddressDTO>
        initialValues={initial}
        validationSchema={yup.object().shape({
          address: yup
            .array()
            .min(1, 'At least one address row')
            .required('required')
            .of(
              yup
                .string()
                .max(255, 'Max. 255 characters per row')
                .required('required')
            ),
          zipCode: yup
            .string()
            .max(255, 'Max. 255 characters')
            .default(null)
            .nullable(),
          city: yup
            .string()
            .max(255, 'Max. 255 characters')
            .required('required'),
          countryCode: yup
            .string()
            .max(255, 'Max. 255 characters')
            .required('required'),
          comment: yup
            .string()
            .max(255, 'Max. 255 characters')
            .default(null)
            .nullable()
        })}
        onSubmit={save}
      >
        {({ submitForm }) => {
          return (
            <Form>
              <Card variant="outlined">
                <CardContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <Typography variant="h5">{title}</Typography>
                    <IconButton
                      onClick={() =>
                        submitForm()
                          .then((res) => {
                            const result = res as any;
                            if (result !== undefined) {
                              setIsCreationSuccess(true);
                              finish(res);
                            }
                            return res;
                          })
                          .catch(NoOp)
                      }
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton onClick={() => finish()}>
                      <CancelIcon />
                    </IconButton>
                  </div>
                  <AddressFormElement name="" postfix="" />
                </CardContent>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </ErrorBoundary>
  );
}

interface EditAddressCardProps {
  client: Client;
  address: ClientAddress;
}

export function EditAddressCard({ client, address }: EditAddressCardProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();

  const updateAddress = (addressDTO: AddressDTO) =>
    dispatch(
      updateAddressThunk({
        clientId: client.id,
        addressId: address.id,
        dto: addressDTO
      })
    )
      .then(unwrapResult)
      .catch((res) => res);

  const { id, country, ...rest } = address;
  return (
    <div id={id}>
      <ClientAddressEditView
        title="Edit address"
        initial={{ ...rest, countryCode: country }}
        save={updateAddress}
        finish={(res) =>
          res ? history.replace(`${url}#${id}`) : history.goBack()
        }
      />
    </div>
  );
}

interface CreateAddressCardProps {
  client: Client;
}

export function CreateAddressCard({ client }: CreateAddressCardProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();

  const createAddress = (addressDTO: AddressDTO) =>
    dispatch(
      createAddressThunk({
        clientId: client.id,
        dto: addressDTO
      })
    )
      .then(unwrapResult)
      .catch((res) => res);

  return (
    <ClientAddressEditView
      title="Create Address"
      initial={{
        address: [''],
        countryCode: '',
        city: '',
        comment: null,
        zipCode: ''
      }}
      save={createAddress}
      finish={(res) => {
        if (res) {
          const { id } = res.addresses[res.addresses.length - 1];
          history.replace(`${url}#${id}`);
        } else {
          history.goBack();
        }
      }}
    />
  );
}
