import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { SupplierStatus } from 'model';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useStatusType } from '../../hooks/status-type.hook';
import { formatDate } from '../../../../localized-formats';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    colorIndicator: {
      height: 18,
      width: 18,
      marginRight: 8,
      borderRadius: '50%',
      display: 'inline-block'
    },
    colorIndicatorActive: {
      backgroundColor: theme.palette.success.main
    },
    colorIndicatorInactive: {
      backgroundColor: 'rgba(242, 201, 76, 1)'
    }
  })
);

export function SupplierStatusColumnRenderer({
  status
}: {
  status: SupplierStatus;
}) {
  const { statusType } = useStatusType(status.statusTypeId);
  const classes = useStyles();

  if (!statusType) {
    return <CircularProgress size="1em" />;
  }
  return (
    <div className={classes.root}>
      <span
        className={clsx(
          classes.colorIndicator,
          statusType.statusType === 'active'
            ? classes.colorIndicatorActive
            : classes.colorIndicatorInactive
        )}
      />
      <div>
        <Typography>{statusType.name}</Typography>
        {statusType.expireType !== 'never' && status.until && (
          <Typography variant="caption" color="textPrimary">
            {formatDate(status.until, 'date')}
          </Typography>
        )}
      </div>
    </div>
  );
}
