import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import React from 'react';
import { useTypedField } from '../../../../transformer/DTOViewSchema';
import { SupplierServiceStepFormElement } from './SupplierServiceStepFormElement';
import { SupplierServiceView } from '../SupplierServiceForm';

export function SupplierServiceStepForm() {
  const { t } = useTranslation();
  const [, meta, helpers] = useTypedField(
    SupplierServiceView.path.serviceSteps
  );

  const removeService = (serviceIndex: number) =>
    helpers.setValue(meta.value.filter((_, i) => i !== serviceIndex));

  const addEmptyService = () => {
    helpers.setValue([
      ...meta.value,
      {
        serviceStepId: '',
        comment: ''
      }
    ]);
  };

  return meta.value !== undefined ? (
    <Grid container spacing={1} xs={12}>
      {meta.value.map((_, serviceIndex) => {
        return (
          <SupplierServiceStepFormElement
            path={SupplierServiceView.path.serviceSteps.at(serviceIndex)}
            onRemove={() => removeService(serviceIndex)}
          />
        );
      })}
      <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          style={{ marginBottom: 20 }}
          onClick={addEmptyService}
          startIcon={<PlusIcon />}
        >
          {t('Add Service Step')}
        </Button>
      </Grid>
    </Grid>
  ) : (
    <CircularProgress />
  );
}
