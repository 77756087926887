import React from 'react';
import { useProjectRequests } from '../hooks/project-requests.hook';
import { ProjectRequestTable } from '../components/request/table/ProjectRequestsTable';

export function RequestsScreen() {
  const { entities, apiState, listEntities } = useProjectRequests();
  return (
    <ProjectRequestTable
      projectRequests={entities}
      onRefresh={listEntities}
      apiState={apiState}
    />
  );
}
