import React from 'react';
import { permissions, PriceUnit, ServiceClass } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { ApiState } from '../../auth/redux/types';
import { StyledLink } from '../../../components/input/link/StyledLink';

function buildTableColumns() {
  return entityColumnBuilder<ServiceClass>()
    .addColumn('class_name', 'name', 'Name', {
      renderer: {
        createElement: (_, s) => {
          return (
            <StyledLink target="_blank" to={`/services/service-class/${s.id}`}>
              {s.name}
            </StyledLink>
          );
        },
        createText: (_, data) => [data.name]
      }
    })
    .addDefaultColumn('class_description', 'description', 'Description')
    .addDefaultColumn('class_type', 'type', 'Type')
    .addColumn('class_default_unit', 'defaultPriceUnit', 'Default Unit', {
      renderer: {
        createElement: (value: PriceUnit | null) => (
          <span>{value?.name ?? '-'}</span>
        ),
        createText: (value: PriceUnit | null) => [value?.name ?? '-']
      }
    })
    .addColumn('class_usable_units', 'usablePriceUnits', 'Usable Units', {
      renderer: {
        createElement: (value: PriceUnit[]) => (
          <span>{value.map((pu) => pu.name).join(', ')}</span>
        ),
        createText: (value: PriceUnit[]) => [
          value.map((pu) => pu.name).join(', ')
        ]
      }
    }).columns;
}

interface ServiceClassTableProps {
  serviceClasses: ServiceClass[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function ServiceClassTable({
  serviceClasses,
  onRefresh,
  apiState
}: ServiceClassTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  return (
    <GenericTable
      label={t('Services')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Service',
        onClick: () => history.push(`${url}?type=create#service-class`),
        permission: permissions.services.serviceClass.create
      }}
      rowActions={[]}
      data={serviceClasses}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      toolbarActions={[]}
    />
  );
}
