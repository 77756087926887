"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierServiceStep = void 0;
const typeorm_1 = require("typeorm");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("./Supplier");
const CircularReference_1 = require("../../serialization/CircularReference");
const ServiceStep_1 = require("../../service/entity/ServiceStep");
let SupplierServiceStep = class SupplierServiceStep {
    constructor(init) {
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => ServiceStep_1.ServiceStep),
    __metadata("design:type", ServiceStep_1.ServiceStep)
], SupplierServiceStep.prototype, "serviceStep", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sq) => sq.serviceStep),
    (0, typeorm_1.PrimaryColumn)(),
    __metadata("design:type", String)
], SupplierServiceStep.prototype, "serviceStepId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier, (s) => s.services),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", Supplier_1.Supplier)
], SupplierServiceStep.prototype, "supplier", void 0);
__decorate([
    (0, typeorm_1.RelationId)((step) => step.supplier),
    (0, typeorm_1.PrimaryColumn)(),
    __metadata("design:type", String)
], SupplierServiceStep.prototype, "supplierId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], SupplierServiceStep.prototype, "comment", void 0);
SupplierServiceStep = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], SupplierServiceStep);
exports.SupplierServiceStep = SupplierServiceStep;
