import { PriceUnit, PriceUnitDTO, PriceUnitPatchDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const priceUnitsThunk = createFeatureThunks<
  PriceUnit,
  PriceUnitDTO,
  PriceUnitPatchDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/prices/price-unit${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'price-units'
});
