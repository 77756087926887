import { useSelector } from 'react-redux';
import {
  NormalTestType,
  normalTestTypeTransformer,
  SimpleTestType,
  simpleTestTypeTransformer,
  SupplierTestType,
  SupplierTestTypeDTO
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { RootState } from '../../../redux/store';
import { supplierTestTypesSelectors } from '../redux/test-types.slice';
import { supplierTestTypesThunk } from '../redux/test-types.thunk';
import { NoOp } from '../../../utils';
import { DialogForm } from '../../../components/input/form/DialogForm';
import {
  TestTypeBasicForm,
  TestTypeNormalBasicView,
  TestTypeSimpleBasicView
} from './TestTypeBasicForm';
import suppliersSlice, { suppliersSelectors } from '../redux/suppliers.slice';

export function SupplierTestTypeEditFormDialog() {
  const dispatch = useAppDispatch();
  const { type, reference } = useEditorLocation();
  const supplierTestType = useSelector((rs: RootState) =>
    supplierTestTypesSelectors.selectById(rs, reference ?? '')
  );
  if (!reference || type !== 'edit' || !supplierTestType) {
    return <></>;
  }
  return (
    <SupplierTestTypeFormDialog
      save={(dto: SupplierTestTypeDTO) =>
        dispatch(
          supplierTestTypesThunk.update({
            id: supplierTestType.id,
            input: { type: dto }
          })
        )
          .then(unwrapResult)
          .catch(NoOp)
      }
      supplierTestType={supplierTestType}
    />
  );
}

export function SupplierTestTypeFormDialog({
  save,
  supplierTestType
}: {
  save?: (d: SupplierTestTypeDTO) => Promise<void | SupplierTestType>;
  supplierTestType?: SupplierTestType;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const createSupplierTestType =
    save ??
    ((dto: SupplierTestTypeDTO) =>
      dispatch(supplierTestTypesThunk.create({ input: { type: dto } }))
        .then(unwrapResult)
        .catch(NoOp));

  let initValues;
  if (supplierTestType) {
    initValues =
      supplierTestType.testVariant === 'simple'
        ? TestTypeSimpleBasicView.viewFactory(
            simpleTestTypeTransformer(supplierTestType as SimpleTestType)
          )
        : TestTypeNormalBasicView.viewFactory(
            normalTestTypeTransformer(supplierTestType as NormalTestType)
          );
  } else {
    initValues = TestTypeNormalBasicView.viewFactory({
      name: '',
      description: '',
      testVariant: 'normal',
      testStates: [
        {
          name: 'scheduled',
          color: 'rgba(0,0,255,1)',
          resultsAvailable: false
        },
        {
          name: 'evaluation',
          color: 'rgba(170,170,255,1)',
          resultsAvailable: false
        },
        {
          name: 'declined',
          color: 'rgba(230,0,0,1)',
          resultsAvailable: false
        },
        {
          name: 'finished',
          color: 'rgba(180,210,180,1)',
          resultsAvailable: true
        }
      ],
      resultStates: [
        {
          name: 'passed',
          color: 'rgba(0,230,0,1)',
          passed: true
        },
        {
          name: 'failed',
          color: 'rgba(230,0,0,1)',
          passed: false
        }
      ]
    });
  }
  return (
    <DialogForm
      identifier={supplierTestType?.id ?? 'test-type'}
      maxWidth="md"
      label="New Test Type"
      form={{
        initialValues: initValues,
        validationSchema: TestTypeSimpleBasicView.validationSchema
      }}
      api={{
        clearError: suppliersSlice.actions.clearError,
        errorSelector: suppliersSelectors.selectError,
        onSubmit: createSupplierTestType,
        stateSelector: suppliersSelectors.selectState
      }}
      actions={[{ label: t('Save and Exit'), doSubmit: true }]}
    >
      <TestTypeBasicForm
        basePath={TestTypeSimpleBasicView.path}
        showTypeSelect={!supplierTestType}
      />
    </DialogForm>
  );
}
