import React from 'react';
import { DefaultCurrency, permissions, PriceBound } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Edit as EditIcon } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { ApiState } from '../../auth/redux/types';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import { useServiceClass } from '../../services/hooks/service-class.hook';
import { useServiceStep } from '../../services/hooks/service-step.hook';
import { usePriceUnit } from '../hooks/price-unit.hook';

function PriceUnitCellRenderer(props: { id: string }) {
  const { priceUnit } = usePriceUnit(props.id);
  return <Typography variant="inherit">{priceUnit?.name}</Typography>;
}

function ServiceStepCellRenderer(props: { id: string | null }) {
  const { serviceStep } = useServiceStep(props.id);
  return <Typography variant="inherit">{serviceStep?.name}</Typography>;
}

function ServiceClassCellRenderer(props: { id: string | null }) {
  const { serviceClass } = useServiceClass(props.id);
  return <Typography variant="inherit">{serviceClass?.name}</Typography>;
}

function buildTableColumns() {
  return entityColumnBuilder<PriceBound>()
    .addColumn('price_bound_service_class', 'serviceClassId', 'Service Class', {
      renderer: {
        createElement: (serviceClassId) => (
          <ServiceClassCellRenderer id={serviceClassId} />
        ),
        createText: (serviceClassId: string | null) => [serviceClassId ?? '']
      }
    })
    .addColumn('price_bound_service_step', 'serviceStepId', 'Service Step', {
      renderer: {
        createElement: (serviceStepId) => (
          <ServiceStepCellRenderer id={serviceStepId} />
        ),
        createText: (serviceStepId: string | null) => [serviceStepId ?? '']
      }
    })
    .addColumn('price_bound_unit', (b) => b.priceUnitId || '', 'Unit', {
      renderer: {
        createElement: (priceUnitId) => (
          <PriceUnitCellRenderer id={priceUnitId} />
        ),
        createText: (priceUnitId: string) => [priceUnitId]
      }
    })
    .addColumn('price_bound_limit', (b) => `${b.priceLimit}`, 'Limit', {
      renderer: {
        createElement: (limit) => (
          <Typography>
            {limit}
            {DefaultCurrency.symbol}
          </Typography>
        ),
        createText: (priceUnitId: string) => [priceUnitId]
      }
    }).columns;
}

interface PriceBoundTableProps {
  bounds: PriceBound[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function PriceBoundTable({
  bounds,
  onRefresh,
  apiState
}: PriceBoundTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);

  return (
    <>
      <GenericTable
        label={t('Price Bounds')}
        refresh={onRefresh}
        primaryButton={{
          label: 'New Pricebound',
          onClick: () => history.push(`${url}?type=create#pricebound`)
        }}
        data={bounds}
        isLoading={apiState === 'pending'}
        tableCells={tableCells}
        rowActions={[
          {
            permissions: permissions.suppliers.supplier.prices.define,
            entry: (v) => ({
              label: 'Edit',
              icon: EditIcon,
              link: `${url}?type=edit#${v.id}`
            })
          }
        ]}
        toolbarActions={[]}
      />
    </>
  );
}
