import React from 'react';
import { PhoneNumber } from 'model';
import { makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { PhoneNumberComponent } from '../text/PhoneNumberComponent';

const useStyles = makeStyles(() =>
  createStyles({
    phoneContainer: {
      display: 'inline-flex',
      paddingBottom: 5
    },
    phoneType: {
      paddingRight: 5
    }
  })
);

interface PhoneNumberDisplayProps {
  phoneNumber: PhoneNumber;
}

export function PhoneNumberDisplay({ phoneNumber }: PhoneNumberDisplayProps) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.phoneContainer}>
        {phoneNumber.type && (
          <Typography variant="body2" className={classes.phoneType}>
            {phoneNumber.type}:
          </Typography>
        )}
        <PhoneNumberComponent number={phoneNumber.number} />
      </div>
      {phoneNumber.comment && (
        <Typography color="textSecondary">{phoneNumber.comment}</Typography>
      )}
    </div>
  );
}
