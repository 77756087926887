import React, { PropsWithChildren } from 'react';
import { AppBar, styled, Toolbar } from '@material-ui/core';

const StyledToolBar = styled(Toolbar)({
  paddingLeft: 0,
  paddingRight: 0,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center'
});

export function StyledAppBar({ children }: PropsWithChildren<unknown>) {
  return (
    <AppBar position="sticky" elevation={0}>
      <StyledToolBar>{children}</StyledToolBar>
    </AppBar>
  );
}
