import React, { ReactNode } from 'react';
import { NoOp } from '../../utils';

export interface AlertContextState {
  title: string;
  content: ReactNode;
  onConfirm: () => void | Promise<void>;
}

type ShowAlertType = (state: AlertContextState) => void;

const state: ShowAlertType = NoOp;

export const AlertContext = React.createContext(state);
