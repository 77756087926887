import React, { useEffect } from 'react';
import * as yup from 'yup';
import {
  formatProjectNumber,
  Project,
  ProjectNumber,
  ProjectNumberDTO
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DialogForm } from '../../../components/input/form/DialogForm';
import projectSlice, { projectSelectors } from '../redux/projects.slice';
import { buildDTOView } from '../../../transformer/DTOViewSchema';
import { confirmProjectThunk } from '../redux/projects.thunk';
import { useClient } from '../../clients/hooks/client.hook';
import { apiClient } from '../../../api/apiClient';
import { NoOp } from '../../../utils';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { FormikTextField } from '../../../components/input/FormikTextField';
import { EditableCheckboxDisplay } from '../../../components/input/checkbox/EditableCheckboxDisplay';

interface AcceptOfferInput {
  projectNumber: string;
  firstAcquisition: boolean;
}

const projectNumberView = buildDTOView<AcceptOfferInput>()
  .all()
  .withSchema(
    yup
      .object({
        projectNumber: yup
          .string()
          .matches(/^[0-9]{5}-[0-9]{2}-[0-9]{2,5}/)
          .required(),
        firstAcquisition: yup.boolean().required()
      })
      .defined()
  );

interface ProjectNumberFormDialogProps {
  project: Project;
}

export function ProjectNumberFormDialog(props: ProjectNumberFormDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { client } = useClient(props.project.clientId);

  const [suggestedProjectNumber, setSuggestedProjectNumber] =
    React.useState<ProjectNumber>();

  const confirmProject = (input: AcceptOfferInput) => {
    const numberParts = input.projectNumber.split('-');
    const projectNumber: ProjectNumberDTO = {
      clientNumber: numberParts[0],
      year: parseInt(numberParts[1], 10),
      sequentialNumber: parseInt(numberParts[2], 10),
      firstAcquisition: input.firstAcquisition
    };
    return dispatch(
      confirmProjectThunk({ id: props.project.id, input: projectNumber })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  useEffect(() => {
    if (!props.project.projectNumber) {
      apiClient
        .get<ProjectNumber>(
          `/projects/project/project-number/${props.project.id}`
        )
        .then((res) => {
          setSuggestedProjectNumber(res.data);
          return res;
        })
        .catch(NoOp);
    }
  }, [client]);

  if (!client) {
    return <></>;
  }

  const initialValues: AcceptOfferInput = {
    projectNumber: suggestedProjectNumber
      ? formatProjectNumber(suggestedProjectNumber)
      : '',
    firstAcquisition: suggestedProjectNumber?.firstAcquisition ?? false
  };

  return (
    <DialogForm
      identifier="project-number"
      label="Create Project Number"
      form={{
        initialValues,
        validationSchema: projectNumberView.validationSchema
      }}
      api={{
        clearError: projectSlice.actions.clearError,
        errorSelector: projectSelectors.selectError,
        onSubmit: confirmProject,
        stateSelector: projectSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      {!!client && !!suggestedProjectNumber && (
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <FormikTextField
              name={projectNumberView.path.projectNumber}
              label={t('Projectnumber')}
            />
          </Grid>
          <Grid item xs={4}>
            <EditableCheckboxDisplay
              name={projectNumberView.path.firstAcquisition}
              label={t('First Acquisition')}
            />
          </Grid>
        </Grid>
      )}
    </DialogForm>
  );
}
