import { ProjectCandidate } from 'model';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const listSupplierRecruimentsThunk = createErrorHandlingThunk<
  ProjectCandidate[],
  { supplierId: string }
>('suppliers/list/recruitments', async ({ supplierId }) => {
  const result = await apiClient.get<ProjectCandidate[]>(
    `/suppliers/supplier/${supplierId}/recruitments`
  );
  return result.data;
});
