"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScalarOfferPositionDTO = void 0;
const class_validator_1 = require("class-validator");
// eslint-disable-next-line import/no-cycle
const AbstractOfferPositionDTO_1 = require("./AbstractOfferPositionDTO");
const IsNullable_1 = require("../../transformation/IsNullable");
class ScalarOfferPositionDTO extends AbstractOfferPositionDTO_1.AbstractOfferPositionDTO {
    static isScalarPos(position) {
        return position.positionType === 'scalar';
    }
}
__decorate([
    (0, class_validator_1.IsNumber)({ maxDecimalPlaces: 0 }),
    __metadata("design:type", Number)
], ScalarOfferPositionDTO.prototype, "amount", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], ScalarOfferPositionDTO.prototype, "amountDescription", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], ScalarOfferPositionDTO.prototype, "unitPriceDescription", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", String)
], ScalarOfferPositionDTO.prototype, "priceUnitId", void 0);
__decorate([
    (0, class_validator_1.IsNumber)(),
    __metadata("design:type", Number)
], ScalarOfferPositionDTO.prototype, "unitPrice", void 0);
exports.ScalarOfferPositionDTO = ScalarOfferPositionDTO;
