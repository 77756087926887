import React from 'react';

export interface TablePaginationState {
  page: number;
  rowsPerPage: number;
}

export interface TablePaginationContextData {
  paginationState: TablePaginationState;
  setPaginationState: (state: TablePaginationState) => void;
}

export function useTablePaginationContext(
  initialState?: TablePaginationState
): TablePaginationContextData {
  const [paginationState, setPaginationState] =
    React.useState<TablePaginationState>(
      initialState ?? { page: 0, rowsPerPage: 25 }
    );
  return React.useMemo(
    () => ({ paginationState, setPaginationState }),
    [paginationState, setPaginationState]
  );
}
