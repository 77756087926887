import { StepSequenceTemplate } from 'model';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { sequenceTemplateThunk } from './sequence-template.thunk';

const sequenceTemplatesSlice = createEntitySlice<StepSequenceTemplate>(
  'sequence-templates',
  [
    CustomThunk.override<StepSequenceTemplate>(sequenceTemplateThunk, {
      pending: (_, state) => {
        state.state = 'pending';
        state.listFetched = undefined;
        state.error = null;
        return state;
      },
      rejected: (_, state, action) => {
        state.state = 'idle';
        state.listFetched = false;
        state.error = (action as any).error;
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        state.listFetched = true;
        return adapter.upsertMany(state, payload as StepSequenceTemplate[]);
      }
    })
  ]
);
export const sequenceTemplatesSelector = createAdapterSelectors(
  'sequence-templates',
  sequenceTemplatesSlice.adapter
);

export default sequenceTemplatesSlice;
