import axios from 'axios';
import { AuthTokensWithUser } from 'model';
import { toast } from 'react-hot-toast';
import { tokenService } from '../features/auth/token.service';

export const baseURL = `http${
  process.env.NODE_ENV !== 'development' ? 's' : ''
}://${process.env.REACT_APP_BACKEND_HOST}${
  process.env.NODE_ENV === 'development'
    ? `:${process.env.REACT_APP_BACKEND_PORT}`
    : ''
}`;

/**
 * HTTP client;
 */
export const apiClient = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
});

/**
 * @interceptor
 * RequestInterceptor
 * adds Bearer access_token
 */
apiClient.interceptors.request.use(
  async (config) => {
    if (
      config.url &&
      !(
        config.url.endsWith('login') ||
        config.url.endsWith('refresh') ||
        config.url.endsWith('refreshWithData')
      )
    ) {
      const accessToken = tokenService.getAccessToken();
      if (accessToken) {
        config.headers.set('Authorization', `Bearer ${accessToken}`);
        return config;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @function Calls api and renews tokens in local storage
 *
 * @return access_token
 */
export async function refreshAuthTokens(): Promise<AuthTokensWithUser> {
  const refreshToken = tokenService.getRefreshToken();
  const result = await apiClient.post<AuthTokensWithUser>(
    '/auth/refresh',
    undefined,
    {
      headers: { Authorization: `Bearer ${refreshToken}` }
    }
  );

  tokenService.setAuthTokens(result.data);
  return result.data;
}

let lastVersionPopup = 0;

/**
 * ResponseInterceptor
 */
apiClient.interceptors.response.use(
  (axiosResponse) => {
    if (
      (axiosResponse?.headers as any)?.get('X-App-Version') !==
      process.env.REACT_APP_VERSION
    ) {
      if (Date.now() - lastVersionPopup > 60000) {
        toast(
          `New Version ${(axiosResponse?.headers as any)?.get(
            'X-App-Version'
          )} available. Please reload the page.`
        );
        lastVersionPopup = Date.now();
      }
    }
    return axiosResponse;
  },
  async (axiosError) => {
    if (
      axiosError.response.status !== 401 ||
      axiosError.config.url.endsWith('auth/login')
    ) {
      // if(axiosError.response.status !== 403)
      throw axiosError;
    }

    if (
      axiosError.config.url.endsWith('auth/refresh') ||
      axiosError.config.url.endsWith('auth/refreshWithData')
    ) {
      tokenService.clear();
      throw axiosError;
    }

    try {
      await refreshAuthTokens();
    } catch (refreshTokenError) {
      throw axiosError;
    }
    return apiClient.request(axiosError.config);
  }
);
