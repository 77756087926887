import React from 'react';
import { BaseEntity } from 'model';
import { Form, Formik } from 'formik';
import { Prompt } from 'react-router-dom';
import { Close as CancelIcon, Save as SaveIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { EditableListCardItemFormBaseProps } from './types';
import { ErrorBoundary } from '../../../features/error/ErrorBoundary';
import { NoOp } from '../../../utils';

interface EditableListCardFormProps<E, U>
  extends EditableListCardItemFormBaseProps<E, U> {
  value: E;
  onCancel: () => void;
}

export function EditableListCardForm<E extends BaseEntity, U>(
  props: EditableListCardFormProps<E, U>
) {
  const { t } = useTranslation();
  const [editSuccess, setEditSuccess] = React.useState(false);

  const FormComponent = props.component;

  return (
    <ErrorBoundary context="EditableListCardForm">
      <Formik
        initialValues={props.viewFactory(props.dtoTransformer(props.value))}
        validationSchema={props.validationSchema}
        onSubmit={(validValues) => props.onSubmit(validValues)}
      >
        {({ submitForm }) => (
          <Form>
            <Prompt
              message="All changes will be lost if you navigate now1"
              when={!editSuccess}
            />
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>
                <FormComponent />
              </div>
              <div>
                <IconButton
                  title={t('Save')}
                  onClick={() =>
                    submitForm()
                      .then((res) => {
                        if (res !== undefined) {
                          setEditSuccess(true);
                          props.onCancel();
                        }
                        return res;
                      })
                      .catch(NoOp)
                  }
                >
                  <SaveIcon />
                </IconButton>
                <IconButton title={t('Discard')} onClick={props.onCancel}>
                  <CancelIcon />
                </IconButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ErrorBoundary>
  );
}
