import { Project, Supplier } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { changeSupplierStatusThunk, suppliersThunk } from './suppliers.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { defineMinimumSupplierPrice } from './supplier-prices.thunk';

const {
  list,
  listDetails,
  delete: deleteThunk,
  get,
  ...thunks
} = suppliersThunk;

const suppliersSlice = createEntitySlice<Supplier>('suppliers', [
  CustomThunk.override<Supplier>(list, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as Supplier[]);
    }
  }),
  CustomThunk.override<Supplier>(
    deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  CustomThunk.override<Supplier>(listDetails, {
    pending: (_, state) => {
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      return adapter.upsertMany(state, payload as Supplier[]);
    }
  }),
  CustomThunk.override<Supplier>(get, {
    pending: (_, state) => {
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      const supplier = payload as Supplier;
      return adapter.upsertOne(state, {
        ...supplier,
        projects: supplier.projects?.map((p) => ({ id: p.id } as Project)) ?? []
      });
    }
  }),
  ...Object.values(thunks),
  changeSupplierStatusThunk,
  defineMinimumSupplierPrice
]);
export const suppliersSelectors = createAdapterSelectors(
  'suppliers',
  suppliersSlice.adapter
);

export const supplierApi: EntityAPI<Supplier> = {
  selectors: suppliersSelectors,
  thunks: suppliersThunk
};

export default suppliersSlice;
