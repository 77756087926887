"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolymorphSupertype = exports.POLYMORPH_SUPERTYPE_KEY = void 0;
exports.POLYMORPH_SUPERTYPE_KEY = 'polymorph_supertype';
function PolymorphSupertype(discriminator) {
    return (target) => {
        Reflect.defineMetadata(exports.POLYMORPH_SUPERTYPE_KEY, discriminator, target);
    };
}
exports.PolymorphSupertype = PolymorphSupertype;
