import React from 'react';
import { CountryCodeMap } from 'model';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { CountryLabel, formatCountryLabel } from './CountryLabel';

export interface CountrySelectProps {
  currentCountry: string | null;
  onChange: (code: string | null) => void;
  hasError?: boolean;
  required?: boolean;
}

export function CountrySelect({
  currentCountry,
  onChange,
  hasError,
  required
}: CountrySelectProps) {
  const [inputValue, setInputValue] = React.useState('');
  return (
    <Autocomplete
      value={currentCountry !== null ? CountryCodeMap[currentCountry] : null}
      onChange={(_, newValue) => onChange(newValue?.shortCode ?? null)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="country-select-demo"
      options={Object.values(CountryCodeMap)}
      autoHighlight
      getOptionLabel={(option) => formatCountryLabel(option)}
      renderOption={(option) => <CountryLabel country={option} />}
      renderInput={(params) => (
        <TextField
          required={required}
          error={hasError}
          {...params}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
        />
      )}
    />
  );
}
