import { omit, pick } from 'lodash';
import * as yup from 'yup';
import {
  SchemaInnerTypeDescription,
  SchemaObjectDescription
} from 'yup/lib/schema';
import { useField } from 'formik';

export type DTOViewSchema<T, K extends keyof T> = {
  [k in K]-?: T[k];
};

export interface DTOView<D, K extends keyof D> {
  viewFactory: (obj: D) => DTOViewSchema<D, K>;
  validationSchema: yup.SchemaOf<DTOViewSchema<D, K>>;
  path: PropertyPath<DTOViewSchema<D, K>, DTOViewSchema<D, K>>;
}
export function buildDTOView<D extends Record<any, any>>() {
  const schemaBuilder = <K extends keyof D>(
    viewFactory: (obj: D) => DTOViewSchema<D, K>
  ) => ({
    withSchema: (
      validationSchema: yup.SchemaOf<DTOViewSchema<D, K>>
    ): DTOView<D, K> => {
      return {
        viewFactory,
        validationSchema,
        path: createPropertyPaths([], validationSchema.describe())
      };
    }
  });
  return {
    include<K extends keyof D>(...props: K[]) {
      return schemaBuilder(
        (obj: D) => pick(obj, ...props) as DTOViewSchema<D, K>
      );
    },
    exclude<K extends keyof D>(...props: K[]) {
      return schemaBuilder(
        (obj: D) =>
          omit<D, K[]>(obj, ...props) as DTOViewSchema<D, Exclude<keyof D, K>>
      );
    },
    all() {
      return schemaBuilder<keyof D>((obj) => obj as any);
    }
  };
}

export interface PropertyValuePath<D, V> {
  _name: string;
  _accessor: (obj: D) => V;
  sub_path: (path: string) => string;
}

export type PropertyPath<D, V> = PropertyValuePath<D, V> &
  (V extends { [k in infer K]?: any }
    ? {
        [k in {
          [p in K]: V[p] extends (...args: any[]) => any ? never : p;
        }[K]]: PropertyPath<D, V[k]>;
      }
    : Record<'test', string>) &
  (V extends (infer AV)[]
    ? { at: (i: number) => PropertyPath<D, AV> }
    : Record<never, never>);

export function createPropertyPaths<D, V>(
  path: string[],
  desc: ReturnType<yup.BaseSchema['describe']>
): PropertyPath<D, V> {
  const basePath: Record<string, any> = {
    _name: path.join('.'),
    _accessor: () => undefined as unknown as V,
    sub_path: (sp: string) =>
      sp !== '' ? [...path, sp].join('.') : path.join('.')
  };
  if (desc.type === 'object') {
    Object.entries((desc as SchemaObjectDescription).fields).forEach(
      ([k, v]) => {
        basePath[k] = createPropertyPaths([...path, k], v as any);
      }
    );
  }
  if (desc.type === 'array') {
    basePath.at = (i: number) =>
      createPropertyPaths(
        [...path, `[${i}]`],
        (desc as SchemaInnerTypeDescription).innerType as any
      );
  }
  return basePath as PropertyPath<D, V>;
}

export function pathToName<V>(path: PropertyPath<any, V> | string) {
  // eslint-disable-next-line no-underscore-dangle
  return typeof path === 'string' ? path : path._name;
}

export function useTypedField<V>(path: PropertyPath<any, V> | string) {
  const name = pathToName(path);
  return useField<V>(name);
}
