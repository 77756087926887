"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = exports.userAvatarPermission = exports.userAvatarSelfPermission = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
const UserRole_1 = require("./UserRole");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("../../supplier/entity/Supplier");
// eslint-disable-next-line import/no-cycle
const ClientContact_1 = require("../../client/entity/ClientContact");
const CircularReference_1 = require("../../serialization/CircularReference");
// eslint-disable-next-line import/no-cycle
const UploadFile_1 = require("../../files/entity/UploadFile");
const UploadAccessPermission_1 = require("../../auth/UploadAccessPermission");
const UploadAccessPermissionGrant_1 = require("../../auth/UploadAccessPermissionGrant");
exports.userAvatarSelfPermission = (0, UploadAccessPermission_1.defineUploadPermission)('userAvatarSelf', UploadAccessPermissionGrant_1.UploadAccessPermissionGrant, 
// eslint-disable-next-line no-use-before-define
(user) => new UploadAccessPermissionGrant_1.UploadAccessPermissionGrant({
    type: 'userAvatarSelf',
    id: user.id
}), false);
exports.userAvatarPermission = (0, UploadAccessPermission_1.defineUploadPermission)('userAvatar', UploadAccessPermissionGrant_1.UploadAccessPermissionGrant, 
// eslint-disable-next-line no-use-before-define
(user) => new UploadAccessPermissionGrant_1.UploadAccessPermissionGrant({
    type: 'userAvatar',
    id: user.id
}), false);
let User = class User extends JournaledEntity_1.JournaledEntity {
    constructor(partial) {
        super();
        Object.assign(this, partial);
    }
};
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], User.prototype, "email", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    (0, class_transformer_1.Exclude)(),
    __metadata("design:type", String)
], User.prototype, "password", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", Boolean)
], User.prototype, "enabled", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], User.prototype, "displayName", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => UserRole_1.UserRole),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], User.prototype, "roles", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => Supplier_1.Supplier, (supplier) => supplier.user, { nullable: true }),
    __metadata("design:type", Supplier_1.Supplier)
], User.prototype, "supplier", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => ClientContact_1.ClientContact, (contact) => contact.user, { nullable: true }),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", ClientContact_1.ClientContact)
], User.prototype, "clientContact", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", Boolean)
], User.prototype, "useLinkedName", void 0);
__decorate([
    (0, typeorm_1.JoinColumn)(),
    (0, typeorm_1.OneToOne)(() => UploadFile_1.UploadFile, { nullable: true }),
    __metadata("design:type", UploadFile_1.UploadFile)
], User.prototype, "avatar", void 0);
User = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.Unique)(['email']),
    __metadata("design:paramtypes", [Object])
], User);
exports.User = User;
