"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierHistory = void 0;
const typeorm_1 = require("typeorm");
// eslint-disable-next-line import/no-cycle
const User_1 = require("../../user/entity/User");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("./Supplier");
const CircularReference_1 = require("../../serialization/CircularReference");
let SupplierHistory = class SupplierHistory extends JournaledEntity_1.JournaledEntity {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier, (supplier) => supplier.addresses),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", Supplier_1.Supplier)
], SupplierHistory.prototype, "supplier", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sa) => sa.supplier),
    __metadata("design:type", String)
], SupplierHistory.prototype, "supplierId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => User_1.User),
    __metadata("design:type", User_1.User)
], SupplierHistory.prototype, "author", void 0);
__decorate([
    (0, typeorm_1.RelationId)((a) => a.author),
    __metadata("design:type", String)
], SupplierHistory.prototype, "authorId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], SupplierHistory.prototype, "referenceType", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], SupplierHistory.prototype, "referenceId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], SupplierHistory.prototype, "eventType", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], SupplierHistory.prototype, "comment", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'jsonb' }),
    __metadata("design:type", Object)
], SupplierHistory.prototype, "changeReference", void 0);
SupplierHistory = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], SupplierHistory);
exports.SupplierHistory = SupplierHistory;
