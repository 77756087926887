"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateDocumentTypeTransformer = exports.UpdateDocumentTypeDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const omit_type_helper_1 = require("../../extern-libs/mapped-types/omit-type.helper");
const DocumentTypeDTO_1 = require("./DocumentTypeDTO");
const DocumentTypeStateDTO_1 = require("./DocumentTypeStateDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const DocumentType_1 = require("../entity/DocumentType");
const DocumentTypeState_1 = require("../entity/DocumentTypeState");
class UpdateDocumentTypeDTO extends (0, omit_type_helper_1.OmitType)(DocumentTypeDTO_1.DocumentTypeDTO, [
    'states'
]) {
}
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => DocumentTypeStateDTO_1.DocumentTypeStateDTO),
    __metadata("design:type", Array)
], UpdateDocumentTypeDTO.prototype, "states", void 0);
exports.UpdateDocumentTypeDTO = UpdateDocumentTypeDTO;
exports.updateDocumentTypeTransformer = (0, DTOTransformer_1.createDTOTransformer)(DocumentType_1.DocumentType, UpdateDocumentTypeDTO, ['id', 'createdAt', 'updatedAt'], {
    states: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createDTOTransformer)(DocumentTypeState_1.DocumentTypeState, DocumentTypeStateDTO_1.DocumentTypeStateDTO, ['createdAt', 'updatedAt', 'documentType', 'documentTypeId'], {}), 'states')
});
