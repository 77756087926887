"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceBound = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
const JournaledEntity_1 = require("../../JournaledEntity");
const PriceUnit_1 = require("./PriceUnit");
const ServiceClass_1 = require("../../service/entity/ServiceClass");
const ServiceStep_1 = require("../../service/entity/ServiceStep");
let PriceBound = class PriceBound extends JournaledEntity_1.JournaledEntity {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], PriceBound.prototype, "rangeTarget", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => PriceUnit_1.PriceUnit, { nullable: true }),
    __metadata("design:type", PriceUnit_1.PriceUnit)
], PriceBound.prototype, "priceUnit", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pr) => pr.priceUnit),
    __metadata("design:type", String)
], PriceBound.prototype, "priceUnitId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ServiceClass_1.ServiceClass, { nullable: true }),
    __metadata("design:type", ServiceClass_1.ServiceClass)
], PriceBound.prototype, "serviceClass", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pr) => pr.serviceClass),
    __metadata("design:type", String)
], PriceBound.prototype, "serviceClassId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ServiceStep_1.ServiceStep, { nullable: true }),
    __metadata("design:type", ServiceStep_1.ServiceStep)
], PriceBound.prototype, "serviceStep", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pr) => pr.serviceStep),
    __metadata("design:type", String)
], PriceBound.prototype, "serviceStepId", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'numeric' }),
    (0, class_transformer_1.Type)(() => Number),
    __metadata("design:type", Number)
], PriceBound.prototype, "priceLimit", void 0);
PriceBound = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.Unique)(['rangeTarget', 'priceUnit', 'serviceClass', 'serviceStep']),
    __metadata("design:paramtypes", [Object])
], PriceBound);
exports.PriceBound = PriceBound;
