import React from 'react';
import { selectPermissionTree } from '../../users/redux/permissions.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { getPermissionsTreeThunk } from '../redux/actions/get-permission-tree.thunk';

export function usePermissionTree() {
  const dispatch = useAppDispatch();
  const permissionTree = useAppSelector(selectPermissionTree);

  React.useEffect(() => {
    dispatch(getPermissionsTreeThunk());
  }, []);

  return {
    permissionTree
  };
}
