import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { clientSelectors } from '../redux/clients.slice';
import { loadClientThunk } from '../redux/actions/load-client.thunk';

export function useClient(clientId?: string | null) {
  const dispatch = useAppDispatch();

  const client = useAppSelector((state) =>
    clientSelectors.selectById(state, clientId || '')
  );

  React.useEffect(() => {
    if (clientId) {
      dispatch(loadClientThunk({ id: clientId }));
    }
  }, [clientId]);

  return { client };
}
