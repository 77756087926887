import React from 'react';
import {
  Avatar,
  Container,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { LockOpenOutlined as LockOutlinedIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { clearAuthError, selectAuthError } from '../redux/auth.slice';
import { KontextorError } from '../../error/KontextorError';
import { ForgotPasswordForm } from '../components/ForgotPasswordForm';
import { usePasswordReset } from '../hooks/password-reset.hook';
import { ValidatePasswordResetTokenForm } from '../components/ValidatePasswordResetTokenForm';
import { ApplyPasswordResetForm } from '../components/ApplyPasswordResetForm';
import { PageAreaBoundary } from '../../../hooks/page-area.components';
import { LoginAppSpecifier } from './Login.screen';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}));

export function ForgotPasswordScreen() {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    resetState,
    requestPasswordReset,
    validatePasswordResetToken,
    applyPasswordReset
  } = usePasswordReset();

  const getForm = () => {
    switch (resetState) {
      case 'initial':
        return <ForgotPasswordForm onSubmit={requestPasswordReset} />;
      case 'validate-token':
        return (
          <ValidatePasswordResetTokenForm
            onSubmit={validatePasswordResetToken}
          />
        );
      case 'apply-reset':
        return <ApplyPasswordResetForm onSubmit={applyPasswordReset} />;
      default:
        return <></>;
    }
  };

  return (
    <PageAreaBoundary specifier={LoginAppSpecifier}>
      <Container
        component="main"
        maxWidth="xs"
        className={classes.loginContainer}
      >
        <KontextorError clear={clearAuthError} selector={selectAuthError}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Password Reset
          </Typography>
          {getForm()}
          <Link component={RouterLink} to="/login" variant="body2">
            {t('Go back')}
          </Link>
        </KontextorError>
      </Container>
    </PageAreaBoundary>
  );
}
