import { ProjectRequest } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import {
  listOpenProjectRequestsThunk,
  projectRequestThunk
} from './project-requests.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';

const { list, delete: deleteThunk, ...thunks } = projectRequestThunk;

const projectRequestSlice = createEntitySlice<ProjectRequest>(
  'project-requests',
  [
    CustomThunk.override<ProjectRequest>(list, {
      pending: (_, state) => {
        state.state = 'pending';
        state.listFetched = undefined;
        state.error = null;
        return state;
      },
      rejected: (_, state, action) => {
        state.state = 'idle';
        state.listFetched = false;
        state.error = (action as any).error;
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        state.listFetched = true;
        return adapter.upsertMany(state, payload as ProjectRequest[]);
      }
    }),
    CustomThunk.override<ProjectRequest>(
      deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
      {
        fulfilled: (adapter, state, { payload }) => {
          state.state = 'idle';
          return adapter.removeOne(state, payload as string);
        }
      }
    ),
    CustomThunk.override<ProjectRequest>(listOpenProjectRequestsThunk, {
      pending: (_, state) => {
        state.state = 'pending';
        state.subQueries = { ...state.subQueries, open: 'pending' };
        return state;
      },
      rejected: (action, state) => {
        state.error = (action as any).error;
        state.subQueries = { ...state.subQueries, open: 'fresh' };
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        state.subQueries = { ...state.subQueries, open: 'fetched' };
        return adapter.upsertMany(state, payload as ProjectRequest[]);
      }
    }),
    ...Object.values(thunks)
  ]
);

export const projectRequestSelectors = createAdapterSelectors(
  'project-requests',
  projectRequestSlice.adapter
);

export const projectRequestAPI: EntityAPI<ProjectRequest> = {
  selectors: projectRequestSelectors,
  thunks: projectRequestThunk
};

export default projectRequestSlice;
