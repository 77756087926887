import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { LoginFormInterface } from '../redux/actions/login.thunk';
import { FormikTextField } from '../../../components/input/FormikTextField';
import { LoadingButton } from '../../../components/input/button/LoadingButton';
import { useLogin } from '../hooks/login.hook';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  }
}));

const loginSchema: yup.SchemaOf<LoginFormInterface> = yup.object().shape({
  email: yup.string().email('must be email').required('required'),
  password: yup.string().required('required')
});

const initialLoginState: LoginFormInterface = {
  email: '',
  password: ''
};

export function LoginForm() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { login, authState } = useLogin();

  return (
    <Formik
      initialValues={initialLoginState}
      validationSchema={loginSchema}
      onSubmit={login}
    >
      {({ submitForm }) => (
        <Form className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextField
                variant="outlined"
                name="email"
                label="Email"
                // FIXME naming
                id="LoginForm-Email-TextField"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField
                variant="outlined"
                name="password"
                label="Password"
                type="password"
                // FIXME naming
                id="LoginForm-Password-TextField"
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                loading={authState === 'login-pending'}
                onClick={submitForm}
                // FIXME naming
                id="LoginForm-Login-Button"
              >
                {t('login')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
