import React from 'react';
import { Avatar, createStyles, Typography } from '@material-ui/core';
import { CreateUserRoleDTO } from 'model';
import { EntityId, unwrapResult } from '@reduxjs/toolkit';
import RolesIcon from '@material-ui/icons/Security';
import { TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createRoleThunk } from '../../redux/actions/create-role.thunk';
import { NoOp } from '../../../../utils';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { FormikPermissionTree, treeElementToIds } from './PermissionTree';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import { usePermissionTree } from '../../../auth/hooks/permission-tree.hook';
import {
  clearRolesError,
  selectRolesError,
  selectRolesState
} from '../../redux/roles.slice';

const useStyles = makeStyles((theme) =>
  createStyles({
    roleNameContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      paddingBottom: theme.spacing(3)
    },
    roleNameTextField: {
      marginLeft: theme.spacing(2)
    },
    permissionTreeContainer: {
      marginTop: theme.spacing(1)
    }
  })
);

const roleSchema: yup.SchemaOf<CreateUserRoleDTO> = yup.object().shape({
  name: yup
    .string()
    .required('Role Name is required')
    .max(255, 'Max. 255 characters')
    .min(2, 'Role Name has to be at lest 2 charachters length'),
  permissions: yup
    .array()
    .of(yup.number().required())
    .min(1, 'Chose at least one permission')
});

const initialValues: CreateUserRoleDTO = {
  name: '',
  permissions: []
};

export function RoleDialogForm() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const createRole = (args: CreateUserRoleDTO) =>
    dispatch(createRoleThunk(args)).then(unwrapResult).catch(NoOp);

  const { permissionTree } = usePermissionTree();

  const navigateToDetails = (id?: EntityId) => {
    history.replace(`/users/roles/${id}`);
  };

  return (
    <DialogForm
      label="New Role"
      identifier="role"
      form={{
        initialValues,
        validationSchema: roleSchema
      }}
      api={{
        onSubmit: createRole,
        stateSelector: selectRolesState,
        errorSelector: selectRolesError,
        clearError: clearRolesError
      }}
      actions={[
        { label: t('Save and Exit'), doSubmit: true },
        {
          label: t('Save and Details'),
          doSubmit: true,
          onClick: navigateToDetails
        }
      ]}
    >
      {({ touched, errors }) => (
        <>
          <div className={classes.roleNameContainer}>
            <Avatar>
              <RolesIcon />
            </Avatar>
            <FormikTextField
              className={classes.roleNameTextField}
              variant="outlined"
              autoComplete="off"
              name="name"
              label={t('Role Name')}
            />
          </div>
          <Typography variant="body2" color="textSecondary">
            {t('Allowed permissions for role:')}
          </Typography>
          <Typography variant="body2" color="error">
            {touched.permissions && errors.permissions}
          </Typography>
          {permissionTree && (
            <TreeView
              className={classes.permissionTreeContainer}
              defaultExpanded={treeElementToIds(permissionTree)}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              <FormikPermissionTree treeElement={permissionTree} />
            </TreeView>
          )}
        </>
      )}
    </DialogForm>
  );
}
