import { AddressDTO, Client } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const createAddressThunk = createErrorHandlingThunk<
  Client,
  { clientId: string; dto: AddressDTO }
>('client/address/create', async (args) => {
  await apiClient.post<Client>(`/client/${args.clientId}/addresses`, args.dto);

  const clientResult = await apiClient.get<Client>(`/client/${args.clientId}`);
  return clientResult.data;
});
