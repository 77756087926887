import { Avatar, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { createStyles } from '@material-ui/core/styles';
import { User, userAvatarSelfPermission } from 'model';
import { Skeleton } from '@material-ui/lab';
import { UserAvatar } from '../../../components/image/UserAvatar';

const useStyles = makeStyles((theme) =>
  createStyles({
    rootButton: {
      borderRadius: 10
    },
    root: {
      display: 'flex'
    },
    avatar: {
      marginRight: theme.spacing(2)
    },
    content: {
      textAlign: 'left'
    }
  })
);

interface ActiveUserDisplayProps {
  link?: string;
  value?: User | null;
}

export function UserDisplay({ link, value }: ActiveUserDisplayProps) {
  const classes = useStyles();

  const textColor = link ? 'textSecondary' : 'textPrimary';

  const content = (
    <>
      <Avatar className={classes.avatar}>
        {value && (
          <UserAvatar
            avatar={value?.avatar}
            permission={userAvatarSelfPermission}
            grant={userAvatarSelfPermission.createGrant(value as User)}
          />
        )}
      </Avatar>
      <div className={classes.content}>
        <Typography variant="body1" color={textColor}>
          {value?.displayName || <Skeleton variant="text" width={60} />}
        </Typography>
        <Typography variant="body2" color={textColor}>
          {value?.email || <Skeleton variant="text" width={170} />}
        </Typography>
      </div>
    </>
  );

  return link ? (
    <ButtonBase className={classes.rootButton} component={RouterLink} to={link}>
      {content}
    </ButtonBase>
  ) : (
    <div className={classes.root}>{content}</div>
  );
}
