import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Project } from 'model';
import {
  Badge,
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  Help,
  ThumbDown,
  ThumbUp
} from '@material-ui/icons';
import { IoMdTrash } from 'react-icons/io';
import { useProjectCandidates } from '../hooks/project-candidates.hook';
import { CandidateDisplay } from '../components/candidates/CandidateDisplay';
import { CandidateCategory } from '../components/candidates/CandidateCategory';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DecisionIconMap = {
  confirmed: <ThumbUp htmlColor="green" />,
  declined: <ThumbDown htmlColor="red" />,
  undetermined: <Help />
};

export interface ProjectCandidatesTabProps {
  project: Project;
}

export function ProjectCandidatesTab({ project }: ProjectCandidatesTabProps) {
  const { projectCandidates } = useProjectCandidates(project.id);
  const [expandTrash, setExpandTrash] = React.useState(false);
  if (projectCandidates.dataState === 'fetched') {
    const projectCandidateMap = {
      undetermined: projectCandidates.candidates.filter(
        (p) => !p.removedOn && !p.decision && p.contactedOn === null
      ),
      contacted: projectCandidates.candidates.filter(
        (p) => !p.removedOn && !p.decision && p.contactedOn !== null
      ),
      decided: projectCandidates.candidates
        .filter((p) => !p.removedOn && !!p.decision)
        .sort((a, b) => {
          const statusA = a.decision?.status === 'confirmed' ? -1 : 1;
          const statusB = b.decision?.status === 'confirmed' ? -1 : 1;
          return statusA - statusB;
        }),
      removed: projectCandidates.candidates.filter((p) => !!p.removedOn)
    };
    return (
      <Card>
        <CardContent>
          <Grid container xs={12}>
            <Grid item xs={4} style={{ padding: '2em' }}>
              <CandidateCategory
                candidates={projectCandidateMap.undetermined}
                project={project}
                withAdd
                title="Undetermined"
              />
            </Grid>
            <Grid item xs={4} style={{ padding: '2em' }}>
              Contacted
              <Divider />
              <List dense>
                {projectCandidateMap.contacted.map((candidate) => (
                  <CandidateDisplay project={project} candidate={candidate} />
                ))}
              </List>
            </Grid>
            <Grid item xs={4} style={{ padding: '2em' }}>
              Decided
              <Divider />
              <List dense>
                {projectCandidateMap.decided.map((candidate) => (
                  <CandidateDisplay project={project} candidate={candidate} />
                ))}
              </List>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <IconButton
              disabled={projectCandidateMap.removed.length === 0}
              onClick={() => setExpandTrash(!expandTrash)}
            >
              <Badge
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={projectCandidateMap.removed.length}
                color="primary"
              >
                <IoMdTrash />
              </Badge>
              {expandTrash ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        </CardActions>
        <Collapse in={expandTrash}>
          <Grid item xs={12} style={{ padding: '2em' }}>
            Sorted out
            <Divider />
            <CandidateCategory
              candidates={projectCandidateMap.removed}
              project={project}
              removed
            />
          </Grid>
        </Collapse>
      </Card>
    );
  }
  return <Skeleton />;
}
