import { User } from 'model';
import { createEntityDocument } from '../../../components/actions/DocumentLink';
import { userAPI } from '../redux/users.slice';

export const { entityDocument: userDocument, EntityLink: UserLink } =
  createEntityDocument(
    User,
    'User',
    userAPI,
    (s) => `/users/accounts/${s.id}`,
    (s, format = 'name' as 'name' | 'email') =>
      format === 'name' ? `${s.displayName}` ?? s.email : s.email
  );
