"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WrapRequestPositionDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const BaseRequestPositionDTO_1 = require("./BaseRequestPositionDTO");
const ServiceRequestPositionDTO_1 = require("./ServiceRequestPositionDTO");
const PlainRequestPositionDTO_1 = require("./PlainRequestPositionDTO");
class WrapRequestPositionDTO {
}
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => BaseRequestPositionDTO_1.BaseRequestPositionDTO, {
        discriminator: {
            property: 'positionType',
            subTypes: [
                {
                    value: ServiceRequestPositionDTO_1.ServiceRequestPositionDTO,
                    name: 'service-position'
                },
                {
                    value: PlainRequestPositionDTO_1.PlainRequestPositionDTO,
                    name: 'plain-position'
                }
            ]
        }
    }),
    __metadata("design:type", Object)
], WrapRequestPositionDTO.prototype, "requestPosition", void 0);
exports.WrapRequestPositionDTO = WrapRequestPositionDTO;
