import { ClientContactDTO, Client } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const updateClientContactThunk = createErrorHandlingThunk<
  Client,
  { clientId: string; contactId: string; dto: ClientContactDTO }
>('client/contact/update', async (args) => {
  await apiClient.put<Client>(
    `/client/${args.clientId}/contacts/${args.contactId}`,
    args.dto
  );
  const clientResult = await apiClient.get<Client>(`/client/${args.clientId}`);
  return clientResult.data;
});
