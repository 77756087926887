"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LangTypeMap = exports.LangScopeMap = void 0;
exports.LangScopeMap = {
    I: 'individual',
    M: 'macro'
};
exports.LangTypeMap = {
    A: 'acient',
    C: 'constructed',
    E: 'exstinct',
    H: 'historical',
    L: 'living'
};
/* async function test() {
  const readInterface = readline.createInterface({
    input: fs.createReadStream('./scripts.csv'),
    crlfDelay: Infinity
  });
  const result: Record<string, AlphabetType> = {};
  // eslint-disable-next-line no-restricted-syntax
  for await (const line of readInterface) {
    const splitData = line.split(';').map((l) => l.trim());
    const entry = {
      code: splitData[0].trim(),
      number: parseInt(splitData[1].trim(), 10),
      name: splitData[2]
    };
    result[entry.code.toLowerCase()] = entry;
  }
  fs.writeFileSync('./AlphabetMap.ts', JSON.stringify(result));
} */
/*
async function test() {
  const map: Record<string, MapState> = {};
  const ensureState = (key: string) => {
    if (map[key] === undefined) {
      map[key] = {
        parent: null,
        children: []
      };
    }
    return map[key];
  };

  const readInterfaceMap = readline.createInterface({
    input: fs.createReadStream('./lang-macro.tab'),
    crlfDelay: Infinity
  });
  // eslint-disable-next-line no-restricted-syntax
  for await (const line of readInterfaceMap) {
    const splitData = line.split('\t').map((l) => l.trim());
    if (splitData[2] === 'A') {
      const [p, c] = splitData;
      ensureState(p).children.push(c);
      ensureState(c).parent = p;
    }
  }
  const readInterface = readline.createInterface({
    input: fs.createReadStream('./lang-codes.tab'),
    crlfDelay: Infinity
  });
  const result: Record<string, ISOLanguage> = {};
  // eslint-disable-next-line no-restricted-syntax
  for await (const line of readInterface) {
    // Each line in input.txt will be successively available here as `line`.
    const splitData = line.split('\t').map((l) => l.trim());
    if (splitData[5] !== 'S' && splitData[4] !== 'S') {
      const iso3 = splitData[0];
      const lang: ISOLanguage = {
        'iso-1': splitData[3],
        'iso-2': splitData[2],
        'iso-2-b': splitData[1],
        'iso-3': iso3,
        scope: splitData[4],
        type: splitData[5],
        name: splitData[6],
        parent: map[iso3]?.parent ?? null,
        children: map[iso3]?.children ?? []
      } as ISOLanguage;
      result[iso3] = lang;
    }
  }
  fs.writeFileSync('./LangList.ts', JSON.stringify(result));
} */
/*

test();
*/
