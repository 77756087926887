import React from 'react';
import { Grid } from '@material-ui/core';
import { PriceUnitPatchDTO } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  buildDTOView,
  useTypedField
} from '../../../transformer/DTOViewSchema';
import { FormikSelect } from '../../../components/select/FormikSelect';
import { useCardMode } from '../../../components/input/card/context';
import { EditableCheckboxDisplay } from '../../../components/input/checkbox/EditableCheckboxDisplay';
import { FormikTextField } from '../../../components/input/FormikTextField';

export const PriceUnitBasicView = buildDTOView<PriceUnitPatchDTO>()
  .all()
  .withSchema(
    yup
      .object({
        name: yup.string().required('required').min(2, 'min length 2'),
        description: yup
          .string()
          .max(255, 'Max. 255 characters')
          .required('required'),
        type: yup.string().defined().nullable().default(null) as any,
        workHours: yup.number().when('type', {
          is: 'work-time',
          then: yup.number().min(0.1).max(2000).required(),
          otherwise: yup.number().nullable().default(null)
        }) as any,
        differServiceSteps: yup.boolean().required()
      })
      .defined()
  );

export function PriceUnitBasicForm() {
  const { t } = useTranslation();
  const [, typeMeta] = useTypedField(PriceUnitBasicView.path.type);
  const cardMode = useCardMode();
  const editable = cardMode && cardMode !== 'view';
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12}>
          <FormikTextField
            required
            name={PriceUnitBasicView.path.name}
            label={t('Name')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikSelect
            name={PriceUnitBasicView.path.type}
            label={t('Type')}
            options={[
              {
                value: 'work-time',
                label: t('Worktime')
              },
              {
                value: 'amount',
                label: t('Amount')
              }
            ]}
            disabled={!editable}
          />
        </Grid>
        {typeMeta.value === 'work-time' && (
          <Grid item xs={6}>
            <FormikTextField
              name={PriceUnitBasicView.path.workHours}
              label={t('Hours')}
              type="number"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <EditableCheckboxDisplay
            label={t('Differ Service Steps')}
            name={PriceUnitBasicView.path.differServiceSteps}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            label={t('Description')}
            name={PriceUnitBasicView.path.description}
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
