"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createRelationTransformer = exports.createDTOTransformer = exports.createExcludingDTOTransformer = exports.nestedTransform = exports.nestedArrayTransform = exports.transformIds = exports.transformId = void 0;
const lodash_1 = require("lodash");
function transformId(rename) {
    return [
        rename,
        (value) => {
            var _a;
            if (value === undefined) {
                return undefined;
            }
            return ((_a = value === null || value === void 0 ? void 0 : value.id) !== null && _a !== void 0 ? _a : null);
        }
    ];
}
exports.transformId = transformId;
function transformIds(rename) {
    return [
        rename,
        (value) => {
            if (value === undefined) {
                return undefined;
            }
            return value.map((b) => b.id);
        }
    ];
}
exports.transformIds = transformIds;
function nestedArrayTransform(mapper, rename) {
    return [
        rename,
        (value) => {
            if (value === undefined) {
                return undefined;
            }
            return value.map((v) => mapper(v));
        }
    ];
}
exports.nestedArrayTransform = nestedArrayTransform;
function nestedTransform(mapper, rename) {
    return [
        rename,
        (value) => {
            if (value === undefined) {
                return undefined;
            }
            if (value === null) {
                return null;
            }
            return mapper(value);
        }
    ];
}
exports.nestedTransform = nestedTransform;
function createExcludingDTOTransformer(a, b, excludes) {
    const omits = [...excludes];
    return (v) => ({
        ...(0, lodash_1.omit)(v, ...omits)
    });
}
exports.createExcludingDTOTransformer = createExcludingDTOTransformer;
function createDTOTransformer(a, b, excludes, mappings) {
    const omits = [
        ...excludes,
        ...Object.keys(mappings)
    ];
    const preparedMappers = Object.entries(mappings).map(([oldKey, [newKey, mapper]]) => {
        return [newKey, (o) => mapper(o[oldKey], oldKey, o)];
    });
    return (v) => ({
        ...(0, lodash_1.omit)(v, ...omits),
        ...Object.fromEntries(preparedMappers.map(([k, m]) => [k, m(v)]))
    });
}
exports.createDTOTransformer = createDTOTransformer;
function createRelationTransformer(a, b, exclude, transform, rename) {
    return createDTOTransformer(a, b, [exclude], {
        [transform]: [rename, (v) => v.id]
    });
}
exports.createRelationTransformer = createRelationTransformer;
