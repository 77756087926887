import { PriceUnitDTO, PriceUnitPatchDTO, priceUnitTransformer } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { NoOp } from '../../../utils';
import { DialogForm } from '../../../components/input/form/DialogForm';
import priceUnitsSlice, {
  priceUnitsSelectors
} from '../../prices/redux/price-units.slice';
import { priceUnitsThunk } from '../../prices/redux/price-units.thunk';
import { PriceUnitBasicForm, PriceUnitBasicView } from './PriceUnitBasicForm';

const initialValues: PriceUnitDTO = {
  name: '',
  description: '',
  type: 'amount',
  workHours: null,
  differServiceSteps: false
};
export function PriceUnitFormDialog({ stepId }: { stepId?: string }) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const priceUnit = useAppSelector((state) =>
    priceUnitsSelectors.selectById(state, stepId ?? '')
  );

  const priceUnitDTO = priceUnit ? priceUnitTransformer(priceUnit) : undefined;

  const createPriceUnit = (dto: PriceUnitDTO | PriceUnitPatchDTO) => {
    if (priceUnit) {
      return dispatch(
        priceUnitsThunk.update({
          id: priceUnit.id,
          input: dto
        })
      )
        .then(unwrapResult)
        .catch(NoOp);
    }
    return dispatch(
      priceUnitsThunk.create({
        input: dto as PriceUnitDTO
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <DialogForm
      identifier={priceUnit?.id ?? 'price-unit'}
      maxWidth="md"
      label={priceUnit?.id ? 'Edit Price Unit' : 'New Price Unit'}
      form={{
        initialValues: priceUnitDTO ?? initialValues,
        validationSchema: PriceUnitBasicView.validationSchema
      }}
      api={{
        clearError: priceUnitsSlice.actions.clearError,
        errorSelector: priceUnitsSelectors.selectError,
        onSubmit: createPriceUnit,
        stateSelector: priceUnitsSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <PriceUnitBasicForm />
    </DialogForm>
  );
}
