import {
  Client,
  formatProjectNumber,
  permissions,
  Project,
  ProjectStatusList,
  ProjectStatusType,
  ProjectVariation,
  Supplier
} from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { CancelOutlined, EuroOutlined } from '@material-ui/icons';
import { Box, Chip, Tooltip, Typography } from '@material-ui/core';
import { differenceInSeconds, format } from 'date-fns';
import { Skeleton } from '@material-ui/lab';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import {
  GenericTable,
  useCreateCells
} from '../../../../components/table/GenericTable';
import { ApiState } from '../../../auth/redux/types';
import { entityColumnBuilder } from '../../../../components/table/columns.builder';
import { StyledLink } from '../../../../components/input/link/StyledLink';
import { TextColumnFilter } from '../../../../components/table/filter/TextColumnFilter';
import { ColumnFilter } from '../../../../components/table/filter/column-filter.types';
import {
  defineEntityMap,
  EntityMapType,
  useEntityMap
} from '../../../../redux/entity-map';
import { clientAPI } from '../../../clients/redux/clients.slice';
import { supplierApi } from '../../../suppliers/redux/suppliers.slice';
import { projectVariationAPI } from '../../../services/redux/project-variations.slice';
import { projectTagAPI } from '../../redux/project-tag.slice';
import {
  entityAccessor,
  lazyConf
} from '../../../../components/table/renderer/TableDataRenderer';
import { MultipleEntityColumnFilter } from '../../../../components/table/filter/MultipleEntityColumnFilter';
import { projectRequestAPI } from '../../redux/project-requests.slice';
import { EnumColumnFilter } from '../../../../components/table/filter/EnumColumnFilter';
import { DateCellRenderer } from '../../../../components/table/renderer/DateCellRenderer';
import { ProjectManagersFilter } from './ProjectManagersFilter';
import { selectUserAsNullable } from '../../../auth/redux/auth.slice';
import { useSuppliers } from '../../../suppliers/hooks/suppliers.hook';
import { AbsenceDBManager } from '../../../suppliers/hooks/absence-cover.hook';
import { ProjectLink } from '../basic/ProjectLink';
import { ClientLink } from '../../../clients/components/basic/ClientLink';
import { SupplierLink } from '../../../suppliers/components/SupplierLink';
import {
  useAreaStorage,
  usePageArea
} from '../../../../hooks/page-area.context';
import { InvoiceColumnFilter } from '../../../../components/table/filter/InvoiceFilter';
import { useProjectRequests } from '../../hooks/project-requests.hook';
import { useAppSelector } from '../../../../redux/redux.hooks';

const projectEntityMap = defineEntityMap({
  client: clientAPI,
  projectRequests: projectRequestAPI,
  supplier: supplierApi,
  projectVariation: projectVariationAPI,
  projectTag: projectTagAPI
});

export const ProjectDeadlineColors = {
  'in-time': '#35ad35',
  upcoming: '#eca901',
  'over-due': '#af1616',
  'delivered-or-final': 'inherit'
};

export const DeadlineDateFormat = 'EEE., dd MMM yyyy HH:mm';

export const ProjectStatusColors: Record<
  ProjectStatusType,
  { bgColor?: string; color?: string } | undefined
> = {
  'in-progress': { bgColor: 'rgba(144, 215, 255, 1)' },
  'not-yet-commisioned': undefined,
  'ready-to-plan': { bgColor: '#FFCB47' },
  cancelled: { bgColor: '#DB5461' },
  delivered: { bgColor: 'rgba(143, 213, 166, 1)' },
  final: { bgColor: 'rgba(50, 159, 91, 1)', color: 'white' }
};

export function determineDeadlineStatus(
  deadline: Date,
  project: Project
): keyof typeof ProjectDeadlineColors {
  if (
    project.projectStatus === 'delivered' ||
    project.projectStatus === 'final'
  ) {
    return 'delivered-or-final';
  }
  try {
    const daysLeft = differenceInSeconds(deadline, new Date()) / (60 * 60 * 24);
    if (daysLeft < 1) {
      return 'over-due';
    }
    return daysLeft >= 3 ? 'in-time' : 'upcoming';
  } catch (e: any) {
    return 'over-due';
  }
}

function buildTableColumns(entityMap: EntityMapType<typeof projectEntityMap>) {
  return entityColumnBuilder<Project>()
    .addDefaultColumn('project_name', 'projectName', 'Projectname', {
      createElement: (_, s) => {
        return <ProjectLink value={s} />;
      },
      createText: (_, data) => [data.projectName]
    })
    .addDefaultColumn(
      'project_number',
      (p) =>
        p.projectNumber !== null && p.projectNumber !== undefined
          ? formatProjectNumber(p.projectNumber)
          : null,
      'Projectnumber',
      {
        createElement: (v, s) => {
          return <ProjectLink value={s} format="number" />;
        },
        filter: TextColumnFilter<Project>({
          showReset: true,
          nullable: true,
          overlayOnFocus: false,
          hideSettings: true
        }) as ColumnFilter<Project, any, any>
      }
    )
    .addDefaultColumn('project_status', 'projectStatus', 'Status', {
      createElement: (value) => (
        <Chip
          size="small"
          style={{
            backgroundColor:
              ProjectStatusColors[value as ProjectStatusType]?.bgColor,
            color: ProjectStatusColors[value as ProjectStatusType]?.color
          }}
          label={<>{value}</>}
        />
      ),
      filter: EnumColumnFilter(
        ProjectStatusList.map((ps) => ({ value: ps, label: ps }))
      )
    })
    .addHiddenColumn(
      'project_request_date',
      entityAccessor(entityMap, ['projectRequests'], (data, eM1) =>
        data.requestId
          ? eM1.projectRequests[data.requestId].dateOfRequest
          : null
      ),
      'Date of Request',
      { renderer: lazyConf(DateCellRenderer('date-time', true)) }
    )
    .addColumn(
      'project_client',
      entityAccessor(
        entityMap,
        ['client'],
        (data, entityMap1) => entityMap1.client[data.clientId]
      ),
      'Client',
      {
        renderer: lazyConf({
          createElement: (client) => {
            return <ClientLink value={client} />;
          },
          createText: (client) => [client.name],
          filter: MultipleEntityColumnFilter<Project, Client, Client>(
            clientAPI,
            (v) => v.name,
            (p) => [p.id]
          )
        })
      }
    )
    .addColumn(
      'project_variation',
      entityAccessor(entityMap, ['projectVariation'], (data, entityMap1) =>
        data.projectVariationId
          ? entityMap1.projectVariation[data.projectVariationId]
          : null
      ),
      'Variation',
      {
        renderer: lazyConf({
          createElement: (p) => {
            return p ? (
              <StyledLink to={`/projects/project-variation/${p.id}`}>
                {p.name}
              </StyledLink>
            ) : (
              <span>-</span>
            );
          },
          createText: (p) => (p ? [p.name] : []),
          filter: MultipleEntityColumnFilter<
            Project,
            ProjectVariation,
            ProjectVariation | null
          >(
            projectVariationAPI,
            (v) => v.name,
            (p) => (p ? [p.id] : [])
          )
        })
      }
    )
    .addColumn(
      'project_manager',
      entityAccessor(
        entityMap,
        ['supplier'],
        (data, entityMap1) => entityMap1.supplier[data.projectManagerId]
      ),
      'Projectmanager',
      {
        renderer: lazyConf({
          createElement: (data) => {
            return <SupplierLink value={data} />;
          },
          createText: (data) => [data.email],
          filter: MultipleEntityColumnFilter<Project, Supplier, Supplier>(
            supplierApi,
            (v) => v.email,
            (p) => [p.id],
            (s) => s.userId !== null
          )
        })
      }
    )
    .addColumn(
      'project_all_managers',
      entityAccessor(entityMap, ['supplier'], (data, entityMap1) => [
        entityMap1.supplier[data.projectManagerId],
        ...(data.supportingProjectManagerIds?.map(
          (id) => entityMap1.supplier[id]
        ) ?? [])
      ]),
      'Projectmanagers',
      {
        renderer: lazyConf({
          createElement: (data) => {
            const mainPm = data[0];
            return (
              <Box position="relative">
                <SupplierLink value={mainPm} />
                {data.length > 1 && (
                  <Tooltip
                    title={data
                      .slice(1)
                      .map((spm) =>
                        spm ? `${spm.name.first} ${spm.name.last}` : '?'
                      )
                      .join(', ')}
                  >
                    <span
                      style={{
                        marginLeft: '5px',
                        fontSize: '0.8em',
                        backgroundColor: 'rgba(230,230,230,0.7)',
                        borderRadius: '50%',
                        paddingLeft: '0.5em',
                        paddingRight: '0.5em',
                        paddingTop: '0.2em',
                        paddingBottom: '0.2em'
                      }}
                    >{`+${data.length - 1}`}</span>
                  </Tooltip>
                )}
              </Box>
            );
          },
          createText: (data) => data.map((p) => p.email),
          filter: ProjectManagersFilter()
        })
      }
    )
    .addBooleanColumn(
      'project_created_in_wordbee',
      'createdInWordbee',
      'Wordbee'
    )
    .addHiddenBooleanColumn(
      'project_tm_consolidated',
      'tmConsolidated',
      'TM Consolidated'
    )
    .addColumn('project_deadline', 'deadline', 'Deadline', {
      renderer: {
        ...DateCellRenderer('date-time', true),
        createElement: (value, data) => {
          const color =
            value !== null
              ? ProjectDeadlineColors[
                  determineDeadlineStatus(new Date(value), data)
                ]
              : undefined;
          try {
            return (
              <Typography variant="subtitle1" style={{ color }}>
                {value === null
                  ? ''
                  : format(new Date(value), DeadlineDateFormat)}
              </Typography>
            );
          } catch (e: any) {
            return (
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                unknown
              </Typography>
            );
          }
        }
      }
    })
    .addDefaultColumn(
      'project_outgoing_invoice_number',
      (p) => p.outgoingInvoices ?? [],
      'Invoice Nr. (out)',
      {
        createElement: (invoiceNumber) => {
          return (
            <>
              {invoiceNumber.map((iNum) => (
                <Typography variant="body2">
                  {iNum.invoiceNumber}{' '}
                  {iNum.paid ? <EuroOutlined /> : <CancelOutlined />}
                </Typography>
              ))}
            </>
          );
        },
        filter: InvoiceColumnFilter(),
        createText: (invoiceNumber) => invoiceNumber.map((i) => i.invoiceNumber)
      }
    )
    .addBooleanColumn(
      'project_outgoing_invoice_paid',
      (p) =>
        Boolean(
          p.outgoingInvoices.length > 0 &&
            p.outgoingInvoices.every((i) => i.paid)
        ),
      'Paid'
    ).columns;
}

function buildTableColumnsWithoutClient(
  entityMap: EntityMapType<typeof projectEntityMap>
) {
  return entityColumnBuilder<Project>()
    .addDefaultColumn('project_name', 'projectName', 'Projectname', {
      createElement: (_, s) => {
        return <ProjectLink value={s} />;
      },
      createText: (_, data) => [data.projectName]
    })
    .addDefaultColumn(
      'project_number',
      (p) =>
        p.projectNumber !== null && p.projectNumber !== undefined
          ? formatProjectNumber(p.projectNumber)
          : null,
      'Projectnumber',
      {
        createElement: (v, s) => {
          return <ProjectLink value={s} format="number" />;
        },
        filter: TextColumnFilter<Project>({
          showReset: true,
          nullable: true
        }) as ColumnFilter<Project, any, any>
      }
    )
    .addColumn(
      'project_variation',
      entityAccessor(entityMap, ['projectVariation'], (data, entityMap1) =>
        data.projectVariationId
          ? entityMap1.projectVariation[data.projectVariationId]
          : null
      ),
      'Variation',
      {
        renderer: lazyConf({
          createElement: (p) => {
            return p ? (
              <StyledLink to={`/projects/project-variation/${p.id}`}>
                {p.name}
              </StyledLink>
            ) : (
              <span>-</span>
            );
          },
          createText: (p) => (p ? [p.name] : []),
          filter: MultipleEntityColumnFilter<
            Project,
            ProjectVariation,
            ProjectVariation | null
          >(
            projectVariationAPI,
            (v) => v.name,
            (p) => (p ? [p.id] : [])
          )
        })
      }
    )
    .addHiddenColumn(
      'project_request_date',
      entityAccessor(entityMap, ['projectRequests'], (data, eM1) =>
        data.requestId
          ? eM1.projectRequests[data.requestId].dateOfRequest
          : null
      ),
      'Date of Request',
      { renderer: lazyConf(DateCellRenderer('date-time', true)) }
    )
    .addColumn(
      'project_manager',
      entityAccessor(
        entityMap,
        ['supplier'],
        (data, entityMap1) => entityMap1.supplier[data.projectManagerId]
      ),
      'Projectmanager',
      {
        renderer: lazyConf({
          createElement: (data) => {
            return <SupplierLink value={data} />;
          },
          createText: (data) => [data.email],
          filter: MultipleEntityColumnFilter<Project, Supplier, Supplier>(
            supplierApi,
            (v) => v.email,
            (p) => [p.id],
            (s) => s.userId !== null
          )
        })
      }
    )
    .addColumn(
      'project_all_managers',
      entityAccessor(entityMap, ['supplier'], (data, entityMap1) => [
        entityMap1.supplier[data.projectManagerId],
        ...(data.supportingProjectManagerIds?.map(
          (id) => entityMap1.supplier[id]
        ) ?? [])
      ]),
      'Projectmanagers',
      {
        renderer: lazyConf({
          createElement: (data) => {
            const mainPm = data[0];
            return (
              <Box position="relative">
                <SupplierLink value={mainPm} />
                {data.length > 1 && (
                  <Tooltip
                    title={data
                      .slice(1)
                      .map((spm) =>
                        spm ? `${spm.name.first} ${spm.name.last}` : '?'
                      )
                      .join(', ')}
                  >
                    <span
                      style={{
                        marginLeft: '5px',
                        fontSize: '0.8em',
                        backgroundColor: 'rgba(230,230,230,0.7)',
                        borderRadius: '50%',
                        paddingLeft: '0.5em',
                        paddingRight: '0.5em',
                        paddingTop: '0.2em',
                        paddingBottom: '0.2em'
                      }}
                    >{`+${data.length - 1}`}</span>
                  </Tooltip>
                )}
              </Box>
            );
          },
          createText: (data) => data.map((p) => p.email),
          filter: ProjectManagersFilter()
        })
      }
    )
    .addBooleanColumn(
      'project_created_in_wordbee',
      'createdInWordbee',
      'Wordbee'
    )
    .addHiddenBooleanColumn(
      'project_tm_consolidated',
      'tmConsolidated',
      'TM Consolidated'
    )
    .addColumn('project_deadline', 'deadline', 'Deadline', {
      renderer: {
        ...DateCellRenderer('date-time', true),
        createElement: (value, data) => {
          const color =
            value !== null
              ? ProjectDeadlineColors[
                  determineDeadlineStatus(new Date(value), data)
                ]
              : undefined;
          try {
            return (
              <Typography variant="subtitle1" style={{ color }}>
                {value === null
                  ? ''
                  : format(new Date(value), DeadlineDateFormat)}
              </Typography>
            );
          } catch (e: any) {
            return (
              <Typography variant="subtitle1" style={{ color: 'red' }}>
                unknown
              </Typography>
            );
          }
        }
      }
    }).columns;
}

interface ProjectTableProps {
  projects: Project[];
  onRefresh: () => void;
  apiState: ApiState;
  withoutClient?: boolean;
  onlyActive?: boolean;
  uiMode?: 'standalone' | 'widget';
  initialColumnLayout?: string[];
  sortByDeadline?: boolean;
  defaultPmFilter?: string[] | null;
}

export interface ProjectManagersFilterDefaultState {
  onlyAssigned: boolean;
}

export function ProjectTable({
  projects,
  onRefresh,
  apiState,
  withoutClient,
  onlyActive,
  uiMode = 'standalone',
  initialColumnLayout,
  sortByDeadline = false,
  defaultPmFilter = undefined
}: ProjectTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const entityMap = useEntityMap(projectEntityMap);
  const area = usePageArea();
  const configStorage = useAreaStorage<ProjectManagersFilterDefaultState>(
    'project-managers-filter',
    area.specifier.deriveSpecifier('table', t('Projects'))
  );
  const { onlyAssigned } = configStorage.readValue({ onlyAssigned: true });

  const tableCells = useCreateCells(
    withoutClient
      ? buildTableColumnsWithoutClient(entityMap)
      : buildTableColumns(entityMap),
    [entityMap, withoutClient]
  );

  const user = useAppSelector(selectUserAsNullable);
  const { suppliers, listFetched } = useSuppliers();
  const { listFetched: requestsFetched } = useProjectRequests();
  const currentSupplier = suppliers.find((s) => s.userId === user?.id);
  if (!requestsFetched) {
    return <Skeleton />;
  }
  if (user?.id && !listFetched && !currentSupplier) {
    return <Skeleton />;
  }
  return (
    <GenericTable
      uiMode={uiMode}
      label={t('Projects')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Project',
        onClick: () => history.push(`${url}?type=create#project`),
        permission: permissions.project.project.create
      }}
      initialFilter={{
        project_number: {
          filterState: {
            options: {
              nullMode: 'non-null'
            },
            filterValue: ''
          },
          active: true
        },
        ...(defaultPmFilter !== undefined &&
        (defaultPmFilter !== null || onlyAssigned)
          ? {
              project_all_managers: {
                active: true,
                filterState:
                  defaultPmFilter === null
                    ? {
                        assigned: true,
                        ids: [
                          currentSupplier?.id as string,
                          ...Object.values(AbsenceDBManager.list()).map(
                            (av) => av.coveredSupplierId
                          )
                        ]
                      }
                    : { assigned: false, ids: defaultPmFilter }
              }
            }
          : undefined),
        project_status: onlyActive
          ? {
              filterState: [
                'not-yet-commisioned',
                'ready-to-plan',
                'in-progress'
              ],
              active: true
            }
          : undefined
      }}
      data={projects}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      initialSort={
        sortByDeadline
          ? { column: 'project_deadline', direction: 'asc' }
          : { column: 'project_request_date', direction: 'asc' }
      }
      rowActions={[
        {
          permissions: permissions.project.projectRequest.edit,
          entry: (step) => ({
            icon: <HiOutlineDocumentDuplicate />,
            label: 'Duplicate',
            link: `/projects/project-requests?type=duplicate#${step.id}`
          })
        }
      ]}
      toolbarActions={[]}
      initialColumnLayout={initialColumnLayout as any}
    />
  );
}
