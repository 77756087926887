import React from 'react';
import { ServiceLanguageConfigDTO } from 'model/dist/supplier/dto/ServiceLanguageConfigDTO';
import { ServiceClassType, SupplierLanguage } from 'model';
import { ArrowForward } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  PropertyPath,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { LanguageSelect } from '../../../languages/components/LanguageSelect';

interface ServiceLanguagesSelectProps {
  path: PropertyPath<any, ServiceLanguageConfigDTO>;
  classType: ServiceClassType;
  languages: SupplierLanguage[];
  withLabel?: boolean;
}

export function ServiceLanguagesSelect({
  path,
  classType,
  languages,
  withLabel
}: ServiceLanguagesSelectProps) {
  const { t } = useTranslation();
  const [, meta, helpers] = useTypedField(path);

  const nativeLanguages = languages
    .filter((l) => l.native)
    .map((l) => l.languageId);

  const otherLanguages = (
    nativeLanguages.length < 2 ? languages.filter((l) => !l.native) : languages
  ).map((l) => l.languageId);

  if (classType === 'only-target') {
    return (
      <LanguageSelect
        label={withLabel ? t('Target Language') : undefined}
        filter={(desc) => nativeLanguages.includes(desc.language.id)}
        value={meta.value.targetLanguage}
        onChange={(val) =>
          helpers.setValue({ ...meta.value, targetLanguage: val as string })
        }
      />
    );
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LanguageSelect
          value={meta.value.sourceLanguage}
          label={withLabel ? t('Source Language') : undefined}
          filter={(desc) => otherLanguages.includes(desc.language.id)}
          onChange={(val) =>
            helpers.setValue({ ...meta.value, sourceLanguage: val as string })
          }
        />
        <ArrowForward
          color="action"
          style={{
            marginRight: 10,
            marginLeft: 10,
            marginTop: withLabel ? 16 : 0
          }}
        />
        <LanguageSelect
          label={withLabel ? t('Target Language') : undefined}
          value={meta.value.targetLanguage}
          filter={(desc) => nativeLanguages.includes(desc.language.id)}
          onChange={(val) =>
            helpers.setValue({ ...meta.value, targetLanguage: val as string })
          }
        />
      </div>
    </div>
  );
}
