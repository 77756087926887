import { InitialDataAggregation, ServiceClass } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { serviceClassesThunk } from './service-classes.thunk';
import { fetchInitialDataThunk } from '../../../redux/initial-data.thunk';

const { list, delete: deleteThunk, ...thunks } = serviceClassesThunk;

const serviceClassesSlice = createEntitySlice<ServiceClass>('service-classes', [
  CustomThunk.override<ServiceClass>(list, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as ServiceClass[]);
    }
  }),
  CustomThunk.override<any>(fetchInitialDataThunk, {
    pending: (_, state) => {
      return state;
    },
    rejected: (_, state) => {
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      const dataPayload = payload as InitialDataAggregation;
      if (dataPayload.serviceClasses) {
        state.state = state.state === 'fresh' ? 'idle' : state.state;
        state.listFetched = true;
        return adapter.upsertMany(state, dataPayload.serviceClasses);
      }
      return state;
    }
  }),
  CustomThunk.override<ServiceClass>(
    deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  ...Object.values(thunks)
]);
export const serviceClassesSelectors = createAdapterSelectors(
  'service-classes',
  serviceClassesSlice.adapter
);

export const serviceClassAPI: EntityAPI<ServiceClass> = {
  selectors: serviceClassesSelectors,
  thunks: serviceClassesThunk
};
export default serviceClassesSlice;
