import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikTextField } from '../../../components/input/FormikTextField';

export function SupplierDomainBasicForm() {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12}>
          <FormikTextField required name="name" label="Name" />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            label={t('Description')}
            name="description"
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
