import { IETFTag } from 'model';
import LanguageIcon from '@material-ui/icons/Language';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React from 'react';

export function IETFIcon(props: { tag: IETFTag }) {
  if (props.tag.language) {
    if (props.tag.region) {
      return <span>{props.tag.region.flag}</span>;
    }
    return <LanguageIcon fontSize="small" />;
  }
  return <CheckBoxOutlineBlankIcon />;
}
