import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { usersSelectors } from '../redux/users.slice';
import { userThunks } from '../redux/actions/users.thunk';

export function useUser(userId: string | null | undefined) {
  const dispatch = useAppDispatch();
  const apiState = useAppSelector(usersSelectors.selectState);
  const listUsers = () => dispatch(userThunks.list());

  React.useEffect(() => {
    if (apiState === 'fresh') listUsers();
  }, []);
  const user = useAppSelector((state) =>
    usersSelectors.selectById(state, userId ?? '')
  );
  return { user };
}
