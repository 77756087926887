import React from 'react';
import {
  Client,
  ProjectOffer,
  ProjectOfferDTO,
  projectOfferTransformer
} from 'model';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { ProjectOfferView } from '../ProjectOfferForm';
import { useAppDispatch } from '../../../../../../redux/redux.hooks';
import { createProjectOfferThunk } from '../../../../redux/project-offers.thunk';
import { NoOp } from '../../../../../../utils';
import { CreateOfferInitialCommentForm } from './CreateOfferInitialCommentForm';
import { projectRequestThunk } from '../../../../redux/project-requests.thunk';
import { DialogForm } from '../../../../../../components/input/form/DialogForm';
import projectOffersSlice, {
  projectOfferSelectors
} from '../../../../redux/project-offer.slice';
import { OfferDocumentBaseForm } from './EditCurrentOfferDocumentForm';

export function CreateOfferDocumentForm({
  client,
  requestId
}: {
  client: Client;
  requestId: string;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const createOffer = (offerDto: ProjectOfferDTO) =>
    dispatch(createProjectOfferThunk({ requestId, input: offerDto }))
      .then(unwrapResult)
      .then((res) => {
        dispatch(projectRequestThunk.get({ id: requestId }));
        return res;
      })
      .catch(NoOp);

  return (
    <DialogForm
      maxWidth="lg"
      identifier="new-offer"
      label="New Offer"
      form={{
        initialValues: projectOfferTransformer((s) => btoa(s))(
          new ProjectOffer({
            offerDate: new Date().toISOString() as unknown as Date,
            validUntil: null,
            taxPercentage: null,
            totalPrice: null,
            positions: [],
            currency: client.defaultCurrency
          })
        ),
        validationSchema: ProjectOfferView.validationSchema
      }}
      api={{
        clearError: projectOffersSlice.actions.clearError,
        errorSelector: projectOfferSelectors.selectError,
        onSubmit: createOffer,
        stateSelector: projectOfferSelectors.selectState
      }}
      actions={[{ label: t('Create Offer'), doSubmit: true }]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <OfferDocumentBaseForm client={client} />
        </Grid>
        <Grid item xs={12}>
          <CreateOfferInitialCommentForm />
        </Grid>
      </Grid>
    </DialogForm>
  );
}
