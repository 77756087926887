import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { useRawTableSearchMatcher } from '../search/search-match.hook';
import { useRawTableFilterMatcher } from '../filter/column-filter-matcher.hook';
import { TableFilterContextData } from '../filter/column-filter.context';
import { useRawTableDataSort } from '../sort/data-sorter.hook';
import { TableSortContextData } from '../sort/sort.context';

type DataIdMap<T extends BaseTableRow> = Record<T['id'], T>;

export interface TableDataActiveContextData<T extends BaseTableRow> {
  activeDataIds: T['id'][];
  setActiveDataIds: (ids: T['id'][]) => void;
}

export function useTableDataContext<T extends BaseTableRow>(
  data: T[] | DataIdMap<T>
) {
  return React.useMemo(() => {
    if (Array.isArray(data)) {
      return Object.fromEntries(
        data.map((entry) => [entry.id, entry])
      ) as DataIdMap<T>;
    }
    return data;
  }, [data]);
}

export function useTransformTableData<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(
  data: DataIdMap<T>,
  searchKeys: string[],
  filterState: TableFilterContextData<T, C>['filterState'],
  sortState: TableSortContextData<T, C>['sortState'],
  displayedColumns: Partial<C>,
  allColumns: C
) {
  const searchMatcher = useRawTableSearchMatcher<T, C>(searchKeys, allColumns);
  const filterMatcher = useRawTableFilterMatcher<T, C>(filterState, allColumns);
  const dataSorter = useRawTableDataSort<T, C>(allColumns, sortState);
  const filteredData = React.useMemo(() => {
    return Object.values(data).filter((entry) => {
      return searchMatcher(entry as T) && filterMatcher(entry as T);
    }) as T[];
  }, [data, searchMatcher, filterMatcher]);

  return React.useMemo(() => {
    return dataSorter(filteredData).map((d) => (d as T).id) as T['id'][];
  }, [filteredData, dataSorter]);
}
/*

export function useTableActiveDataContext<T extends BaseTableRow>(
  ids: T['id'][]
): TableDataActiveContextData<T> {
  const [activeDataIds, setActiveDataIds] = React.useState<T['id'][]>(ids);
  return { activeDataIds, setActiveDataIds };
}
*/
