import React from 'react';
import { InitialPlainPositionDTO, InitialServicePositionDTO } from 'model';
import { Add, Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@material-ui/core';
import { useTypedField } from '../../../../../../transformer/DTOViewSchema';
import { RequestCreatorView } from '../../request-creator.view';
import { RequestPositionForm } from './RequestPositionForm';
import { PlainPositionForm } from './PlainPositionForm';
import { ActionsMenu } from '../../../../../../components/actions/ActionsMenu';

function PositionAddButton() {
  const { t } = useTranslation();

  const [, meta, helpers] = useTypedField<
    (InitialServicePositionDTO | InitialPlainPositionDTO)[]
  >(RequestCreatorView.path.positions);

  const addEmptyServicePosition = () => {
    helpers.setValue([
      ...meta.value,
      {
        positionType: 'service-position',
        serviceClassId: null as unknown as string,
        priceUnitId: null,
        estimatedAmount: null,
        deadline: null,
        expressPosition: false,
        comment: {
          comment: '',
          attachedFiles: []
        },
        languages: {
          sourceLanguage: null,
          targetLanguages: []
        }
      }
    ]);
  };

  const addEmptyPlainServicePosition = () => {
    helpers.setValue([
      ...meta.value,
      {
        positionType: 'plain-position',
        name: '',
        priceUnitId: null,
        estimatedAmount: null,
        deadline: null,
        expressPosition: false
      }
    ]);
  };

  return (
    <ActionsMenu
      iconButtonProps={{ size: 'small' }}
      items={[
        {
          label: t('add service'),
          onClick: addEmptyServicePosition
        },
        {
          label: t('add other'),
          onClick: addEmptyPlainServicePosition
        }
      ]}
    >
      <Add fontSize="small" />
    </ActionsMenu>
  );
}

export function RequestPositionsForm() {
  const [, meta, helpers] = useTypedField(RequestCreatorView.path.positions);

  const getRemoveRequestPosition = (i: number) => () =>
    helpers.setValue(meta.value.filter((_, index) => i !== index));

  return (
    <div>
      {meta.value.map((v, i) => (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="small"
              style={{ marginBottom: 1 }}
              onClick={getRemoveRequestPosition(i)}
            >
              <Delete fontSize="inherit" />
            </IconButton>
            <Typography variant="body2" color="textSecondary">
              Service {i + 1}
            </Typography>
          </div>
          <div style={{ paddingLeft: 30, paddingTop: 10, paddingBottom: 30 }}>
            {v.positionType === 'service-position' ? (
              <RequestPositionForm
                basePath={RequestCreatorView.path.positions.at(i) as any}
              />
            ) : (
              <PlainPositionForm
                basePath={RequestCreatorView.path.positions.at(i) as any}
              />
            )}
          </div>
        </>
      ))}
      <PositionAddButton />
    </div>
  );
}
