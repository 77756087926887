import React from 'react';
import { OrderServiceGroupTask, OrderStepTask } from 'model';
import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { OrderProjectTaskList } from './OrderProjectTaskList';
import { OrderTaskScopePhases } from './OrderTaskScopePhases';
import { useTaskMap } from './order-task.context';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { apiClient } from '../../../../api/apiClient';
import { projectOrderTasksThunk } from '../../redux/project-order-tasks.thunk';
import { NoOp } from '../../../../utils';
import { TasksDoneAlert } from '../TasksDoneAlert';
import { usePositionsOfRequest } from '../../hooks/request-positions.hook';
import { useProject } from '../../hooks/project.hook';

export function OrderTasks({ projectId }: { projectId: string }) {
  const dispatch = useAppDispatch();
  const { project } = useProject(projectId);
  const { positions } = usePositionsOfRequest(project?.requestId);
  const hasPlainPositions = positions.some(
    (p) => p.positionType === 'plain-position'
  );
  const taskMap = useTaskMap();

  const { t } = useTranslation();
  return (
    <div style={{ paddingBottom: 80 }}>
      <TasksDoneAlert projectId={projectId} />
      {Object.keys(taskMap).length === 0 && (
        <>
          <Button
            style={{ marginBottom: 20 }}
            color="primary"
            onClick={() => {
              apiClient
                .post(`/projects/project/${projectId}/tasks/generate`)
                .then((res) => {
                  dispatch(projectOrderTasksThunk.list({ projectId }));
                  return res;
                })
                .catch(NoOp);
            }}
          >
            {t('Generate from Request')}
          </Button>
          {hasPlainPositions && (
            <Alert color="warning">
              <AlertTitle>
                {/* eslint-disable-next-line max-len */}
                {t(`the request contains at least one plan service position. This
                position will not automatically be added to the project tasks.
                Please add associated task manually`)}
              </AlertTitle>
            </Alert>
          )}
        </>
      )}
      <OrderTaskScopePhases
        projectId={projectId}
        scope="project"
        provisionComponent={({ tasks }) => (
          <OrderProjectTaskList
            projectId={projectId}
            tasks={tasks as (OrderStepTask | OrderServiceGroupTask)[]}
          />
        )}
        showVerticalDivider
        showResources
        showPreparation
        showPostDelivery
      />
    </div>
  );
  /* return (
    <div>
      <OrderResourceList
        resources={resources.map((v) => v.value as OrderResource)}
        projectId={projectId}
      />
      <PhaseStepTaskContainer
        scope="project"
        tasks={preparation.map((v) => v.value as OrderStepTask)}
        phase="preparation"
        projectId={projectId}
      />
      <OrderProjectTaskList
        projectId={projectId}
        tasks={provision.map(
          (l) => l.value as OrderStepTask | OrderServiceGroupTask
        )}
      />
      <ServiceGroupCreator projectId={projectId} />
      <PhaseStepTaskContainer
        scope="project"
        tasks={postDelivery.map((v) => v.value as OrderStepTask)}
        phase="post-delivery"
        projectId={projectId}
      />
    </div>
  ); */
}
