"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierAttachment = void 0;
const typeorm_1 = require("typeorm");
// eslint-disable-next-line import/no-cycle
const UploadFile_1 = require("../../files/entity/UploadFile");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("./Supplier");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const CircularReference_1 = require("../../serialization/CircularReference");
let SupplierAttachment = class SupplierAttachment extends JournaledEntity_1.JournaledEntity {
};
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], SupplierAttachment.prototype, "attachmentType", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier, (supplier) => supplier.addresses),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", Supplier_1.Supplier)
], SupplierAttachment.prototype, "supplier", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sa) => sa.supplier),
    __metadata("design:type", String)
], SupplierAttachment.prototype, "supplierId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", Boolean)
], SupplierAttachment.prototype, "pin", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => UploadFile_1.UploadFile),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], SupplierAttachment.prototype, "attachedFiles", void 0);
SupplierAttachment = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.TableInheritance)({ column: { type: 'varchar', name: 'attachmentType' } })
], SupplierAttachment);
exports.SupplierAttachment = SupplierAttachment;
