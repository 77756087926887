import React, { useEffect } from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { BaseTableRow } from '../TableDataCell';

export interface TableSelectionContextData<T extends BaseTableRow> {
  selectedIds: T['id'][];
  setSelectedIds: (ids: EntityId[]) => void;
}

export function useTableSelectionContext<T extends BaseTableRow>(
  displayedIds: T['id'][]
): TableSelectionContextData<T> {
  const [selectedIds, setSelectedIds] = React.useState<T['id'][]>([]);
  useEffect(() => {
    if (selectedIds.length) {
      setSelectedIds(selectedIds.filter((i) => displayedIds.includes(i)));
    }
  }, [displayedIds]);
  return { selectedIds, setSelectedIds };
}
