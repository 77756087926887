import { shallowEqual } from 'react-redux';
import React from 'react';
import { useEntityAPI } from '../../../redux/entity-api.hook';
import {
  projectRequestAPI,
  projectRequestSelectors
} from '../redux/project-requests.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { listOpenProjectRequestsThunk } from '../redux/project-requests.thunk';

export function useProjectRequests() {
  return useEntityAPI(projectRequestAPI);
}

const openQuerySelector = projectRequestAPI.selectors.selectSubQuery('open');

export function useOpenProjectRequests(pmIds: string[], forceReload = false) {
  const dispatch = useAppDispatch();
  const entities = useAppSelector(
    projectRequestSelectors.selectAll,
    shallowEqual
  );

  const queryState = useAppSelector(openQuerySelector, shallowEqual);
  const apiState = useAppSelector(projectRequestSelectors.selectState);
  const apiError = useAppSelector(projectRequestSelectors.selectError);
  const listFetched = useAppSelector(projectRequestSelectors.selectListFetched);

  const listEntities = React.useCallback(
    () => dispatch(listOpenProjectRequestsThunk({ pmIds })),
    [pmIds]
  );
  React.useEffect(() => {
    if (
      forceReload ||
      (pmIds.length > 0 &&
        apiState !== 'unauthorized' &&
        apiState !== 'pending' &&
        (apiState === 'fresh' || listFetched === false) &&
        (queryState === 'fresh' || queryState === undefined) &&
        !apiError)
    ) {
      listEntities();
    }
  }, [apiState, listEntities]);

  return React.useMemo(
    () => ({ entities, apiState, listEntities, listFetched }),
    [entities, apiState, listEntities, listFetched]
  );
}
