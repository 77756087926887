import React from 'react';
import { SupplierPatchDTO } from 'model';
import * as yup from 'yup';
import {
  CircularProgress,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  buildDTOView,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { FormikEntitySelect } from '../../../../components/select/FormikEntitySelect';
import { largeScaleProjectApi } from '../../../projects/redux/large-scale-projects.slice';
import { useCardMode } from '../../../../components/input/card/context';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { LspAssignmentTableHeader } from './LspAssignmentTableHeader';
import { FormikSelect } from '../../../../components/select/FormikSelect';

export const LspAssignmentView = buildDTOView<SupplierPatchDTO>()
  .include('largeScaleProjectAssignments')
  .withSchema(
    yup
      .object({
        largeScaleProjectAssignments: yup
          .array()
          .of(
            yup
              .object()
              .required()
              .shape({
                projectId: yup.string().required('required'),
                weeklyHours: yup
                  .number()
                  .required('required')
                  .min(1, 'min 1 Hour'),
                active: yup.boolean(),
                comment: yup.string()
              })
          )
          .default(undefined)
          .defined()
      })
      .defined()
  );

export function LspAssignmentForm() {
  const { t } = useTranslation();
  const [, meta, helpers] = useTypedField(
    LspAssignmentView.path.largeScaleProjectAssignments
  );

  const addEmptyLspAssignment = () =>
    helpers.setValue([
      ...meta.value,
      { projectId: '', comment: '', weeklyHours: 0, active: true }
    ]);

  const mode = useCardMode();
  const editable = mode !== undefined;

  return meta.value !== undefined ? (
    <div>
      <TableContainer>
        <Table>
          <LspAssignmentTableHeader />
          {meta.value.map((_, lspIndex) => (
            <TableRow>
              <TableCell component="th" scope="row" width={200}>
                <FormikEntitySelect
                  entityApi={largeScaleProjectApi}
                  optionLabel={(lsp) => lsp.name}
                  name={
                    LspAssignmentView.path.largeScaleProjectAssignments.at(
                      lspIndex
                    ).projectId
                  }
                />
              </TableCell>
              <TableCell>
                <FormikTextField
                  name={`largeScaleProjectAssignments[${lspIndex}].comment`}
                />
              </TableCell>
              <TableCell>
                <FormikSelect
                  name={`largeScaleProjectAssignments[${lspIndex}].active`}
                  options={[
                    { label: t('Active'), value: true },
                    { label: t('Inactive'), value: false }
                  ]}
                />
              </TableCell>
              <TableCell align="right">
                <FormikTextField
                  inputProps={{ style: { textAlign: 'right' } }}
                  type="number"
                  name={`largeScaleProjectAssignments[${lspIndex}].weeklyHours`}
                />
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
      {editable && (
        <IconButton
          title={t('Assign Large Scale Project')}
          onClick={addEmptyLspAssignment}
        >
          <Add />
        </IconButton>
      )}
    </div>
  ) : (
    <CircularProgress />
  );
}
