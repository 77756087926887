import React from 'react';
import {
  Avatar,
  Container,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { LockOpenOutlined as LockOutlinedIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { LoginForm } from '../components/LoginForm';
import { clearAuthError, selectAuthError } from '../redux/auth.slice';
import { KontextorError } from '../../error/KontextorError';
import { RootPageAreaSpecifier } from '../../../hooks/page-area.context';
import { PageAreaBoundary } from '../../../hooks/page-area.components';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}));
export const LoginAppSpecifier = RootPageAreaSpecifier.deriveSpecifier(
  'app',
  'login'
);
export function LoginScreen() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <PageAreaBoundary specifier={LoginAppSpecifier}>
      <Container
        component="main"
        maxWidth="xs"
        className={classes.loginContainer}
      >
        <KontextorError clear={clearAuthError} selector={selectAuthError}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon
              // FIXME naming
              id="LogInScreen-Lock-Icon"
            />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <LoginForm />
          <Link component={RouterLink} to="forgot-password" variant="body2">
            {t('Forgot password?')}
          </Link>
        </KontextorError>
      </Container>
    </PageAreaBoundary>
  );
}
