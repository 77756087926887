import React from 'react';
import { OutgoingInvoiceDTO } from 'model/dist/project/dto/OutgoingInvoiceDTO';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { buildDTOView } from '../../../../transformer/DTOViewSchema';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { FormikSwitch } from '../../../../components/input/switch/FormikSwitch';

export const outgoingInvoiceView = buildDTOView<OutgoingInvoiceDTO>()
  .all()
  .withSchema(
    yup.object().defined().shape({
      invoiceNumber: yup.string().required(),
      paid: yup.boolean().required()
    })
  );

export function OutgoingInvoiceForm() {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex' }}>
      <FormikTextField
        name={outgoingInvoiceView.path.invoiceNumber}
        label={t('Invoice Number')}
        style={{ width: '20%' }}
      />
      <FormikSwitch name={outgoingInvoiceView.path.paid} label={t('Paid')} />
    </div>
  );
}
