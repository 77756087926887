import React from 'react';
import { TabbedDetails } from '../../../components/details/TabbedDetails';
import { PriceBoundTargetDetails } from '../components/PriceBoundTargetDetails';

export function PriceBoundScreen() {
  return (
    <TabbedDetails
      header={() => <div />}
      tabs={[
        {
          name: 'Suppliers',
          subPaths: ['', '/suppliers'],
          component: () => <PriceBoundTargetDetails limitTarget="supplier" />
        },
        {
          name: 'Clients',
          subPaths: ['/clients'],
          component: () => <PriceBoundTargetDetails limitTarget="client" />
        }
      ]}
    />
  );
}
