import React from 'react';
import { OrderServiceGroupTask, ServiceTaskDTO } from 'model';
import {
  Avatar,
  Button,
  Fade,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core';
import {
  AirportShuttle,
  ArrowForward,
  AssignmentTurnedIn,
  Block,
  ExpandLess,
  HourglassEmptyOutlined,
  Info,
  Settings
} from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import PlusIcon from '@material-ui/icons/Add';
import { Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { LanguageDisplay } from '../../../languages/components/LanguageDisplay';
import { TransitionActionComponent } from './TransitionActionComponent';
import { formatDate } from '../../../../localized-formats';
import { useServiceClass } from '../../../services/hooks/service-class.hook';
import { useTaskState, useTaskTransitions } from './order-task-state.hook';
import { LanguageSelectPopover } from './LanguageSelectPopover';
import {
  createOrderSubTaskThunk,
  projectOrderTasksThunk
} from '../../redux/project-order-tasks.thunk';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { NoOp } from '../../../../utils';
import { useTaskMap } from './order-task.context';

const StateUIMap = (theme: Theme) => ({
  blocked: { label: 'Blocked', icon: Block, color: undefined },
  pending: {
    label: 'Pending',
    icon: HourglassEmptyOutlined,
    color: theme.palette.warning.light
  },
  'in-progress': {
    label: 'In Progress',
    icon: Settings,
    color: theme.palette.warning.light
  },
  delivered: { label: 'Delivered', icon: AirportShuttle, color: undefined },
  completed: {
    label: 'Final',
    icon: AssignmentTurnedIn,
    color: theme.palette.success.light
  }
});
export function ServiceGroupHeader({
  projectId,
  task,
  targetLanguageIds,
  open,
  toggleOpen
}: {
  projectId: string;
  task: OrderServiceGroupTask;
  targetLanguageIds: string[];
  open: boolean;
  toggleOpen: () => void;
}) {
  const { serviceClass } = useServiceClass(task.serviceClassId);
  const theme = useTheme();

  const { aggState } = useTaskState(task);
  const { t } = useTranslation();
  const transitionActions = useTaskTransitions(task);
  const dispatch = useAppDispatch();
  const stateUI = StateUIMap(theme)[aggState];
  const StateIcon = stateUI.icon;
  const taskMap = useTaskMap();
  const de = Object.values(taskMap).some(
    (ts) => ts.value.parentTaskId === task.id
  );

  const deleteStepTask = () => {
    dispatch(projectOrderTasksThunk.delete({ projectId, id: task.id }))
      .then((res) => {
        dispatch(projectOrderTasksThunk.list({ projectId }));
        return res;
      })
      .catch(NoOp);
  };
  return (
    <div style={{ display: 'flex', paddingLeft: 10, paddingTop: 10 }}>
      <Avatar style={{ width: 30, height: 30, backgroundColor: stateUI.color }}>
        <StateIcon fontSize="small" />
      </Avatar>
      <div style={{ paddingLeft: 10, minWidth: 250 }}>
        <div style={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
          <Typography style={{ flexGrow: 1, paddingRight: '10%' }} variant="h6">
            {serviceClass?.name ?? ''}
          </Typography>
          {!de && (
            <IconButton size="small" onClick={deleteStepTask}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
          <LanguageSelectPopover
            onInput={(lang) => {
              if (lang) {
                const taskDto: ServiceTaskDTO = {
                  targetLanguageId: lang?.id ?? null,
                  dependencies: [],
                  attachedFiles: [],
                  deadline: null,
                  description: '',
                  state: 'pending',
                  taskType: 'service-task'
                };
                dispatch(
                  createOrderSubTaskThunk({
                    projectId,
                    parentId: task.id,
                    input: {
                      task: taskDto
                    }
                  })
                );
              }
            }}
            button={({ onClick }) => (
              <Button
                startIcon={<PlusIcon edgeMode="left" />}
                size="small"
                style={{
                  color: 'grey'
                }}
                onClick={(e) => onClick(e.target as HTMLElement)}
              >
                {t('Language')}
              </Button>
            )}
          />
        </div>
        {task.description && (
          <div style={{ display: 'flex' }}>
            <Info fontSize="small" color="action" style={{ marginRight: 3 }} />
            <Typography variant="subtitle1" color="textSecondary">
              {task.description}
            </Typography>
          </div>
        )}
      </div>
      <div style={{ flexGrow: 1, display: 'flex' }}>
        <Fade in={!open}>
          <div style={{ display: 'flex' }}>
            {targetLanguageIds.map((tl) => (
              <div
                style={{
                  marginLeft: 30,
                  display: 'flex'
                }}
              >
                {task.sourceLanguageId && (
                  <LanguageDisplay languageId={task.sourceLanguageId} />
                )}
                <ArrowForward
                  fontSize="small"
                  style={{ marginLeft: 5, marginRight: 5 }}
                />
                <LanguageDisplay languageId={tl ?? ''} />
              </div>
            ))}
          </div>
        </Fade>
      </div>
      <div style={{ display: 'flex' }}>
        <div>
          {transitionActions.map((action) => {
            return (
              <TransitionActionComponent
                action={action}
                task={task}
                projectId={projectId}
              />
            );
          })}
        </div>
        {task.deadline && (
          <div style={{ marginLeft: 30, marginRight: 30 }}>
            <Typography variant="caption" color="textSecondary">
              {t('Deadline')}
            </Typography>
            <Typography variant="body2">
              {formatDate(task.deadline, 'date')}
            </Typography>
          </div>
        )}
      </div>
      <IconButton size="small" onClick={toggleOpen}>
        {open ? <ExpandLess /> : <ExpandMoreIcon />}
      </IconButton>
    </div>
  );
}
