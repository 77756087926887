import { InitialDataAggregation, RequestStatus } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { requestStatusThunk } from './request-status.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { fetchInitialDataThunk } from '../../../redux/initial-data.thunk';

const { list, delete: deleteThunk, ...thunks } = requestStatusThunk;

const requestStatusSlice = createEntitySlice<RequestStatus>('request-status', [
  CustomThunk.override<RequestStatus>(list, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as RequestStatus[]);
    }
  }),
  CustomThunk.override<any>(fetchInitialDataThunk, {
    pending: (_, state) => {
      return state;
    },
    rejected: (_, state) => {
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      const dataPayload = payload as InitialDataAggregation;
      if (dataPayload.requestStatus) {
        state.state = state.state === 'fresh' ? 'idle' : state.state;
        state.listFetched = true;
        return adapter.upsertMany(state, dataPayload.requestStatus);
      }
      return state;
    }
  }),
  CustomThunk.override<RequestStatus>(
    deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  ...Object.values(thunks)
]);
export const requestStatusSelectors = createAdapterSelectors(
  'request-status',
  requestStatusSlice.adapter
);

export const requestStatusAPI: EntityAPI<RequestStatus> = {
  selectors: requestStatusSelectors,
  thunks: requestStatusThunk
};
export default requestStatusSlice;
