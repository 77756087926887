import React from 'react';
import {
  BaseRequestPositionDTO,
  plainPositionTransformer,
  PlainRequestPosition,
  PlainRequestPositionDTO,
  ProjectRequest,
  RequestPosition,
  servicePositionTransformer,
  ServiceRequestPosition,
  ServiceRequestPositionDTO
} from 'model';
import { shallowEqual } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../../redux/redux.hooks';
import { requestPositionSelectors } from '../../../redux/request-positions.slice';
import { RequestPositionDisplay } from './display/RequestPositionDisplay';
import { EditableListCard } from '../../../../../components/card/editableListCard/EditableListCard';
import {
  RequestPositionForm,
  RequestServicePositionView
} from './form/RequestPositionForm';
import {
  PlainPositionForm,
  RequestPlainPositionView
} from './form/PlainPositionForm';
import { requestPositionThunk } from '../../../redux/request-positions.thunk';
import { NoOp } from '../../../../../utils';

export function RequestPositionsCard({
  projectRequest
}: {
  projectRequest: ProjectRequest;
}) {
  const dispatch = useAppDispatch();

  const requestPositions = useAppSelector(
    (state) =>
      requestPositionSelectors.selectByRequest(state, projectRequest.id),
    shallowEqual
  );

  const createRequestPosition = (
    u: ServiceRequestPositionDTO | PlainRequestPositionDTO
  ) =>
    dispatch(
      requestPositionThunk.create({
        requestId: projectRequest.id,
        input: { requestPosition: u }
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  const updateRequestPosition = (
    u: ServiceRequestPositionDTO | PlainRequestPositionDTO,
    id: string
  ) =>
    dispatch(
      requestPositionThunk.update({
        requestId: projectRequest.id,
        id,
        input: { requestPosition: u }
      })
    )
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <EditableListCard<RequestPosition, BaseRequestPositionDTO>
      title="Positions"
      identifierFactory={(entity) => `position-${entity.id}`}
      items={
        requestPositions.map((rp) => ({
          value: rp as ServiceRequestPosition | PlainRequestPosition,
          display: { component: RequestPositionDisplay },
          form: {
            onSubmit: (
              v: ServiceRequestPositionDTO | PlainRequestPositionDTO
            ) => updateRequestPosition(v, rp.id),
            component: () =>
              rp.positionType === 'service-position' ? (
                <RequestPositionForm />
              ) : (
                <PlainPositionForm />
              ),
            validationSchema:
              rp.positionType === 'service-position'
                ? RequestServicePositionView.validationSchema
                : RequestPlainPositionView.validationSchema,
            viewFactory:
              rp.positionType === 'service-position'
                ? RequestServicePositionView.viewFactory
                : RequestPlainPositionView.viewFactory,
            dtoTransformer:
              rp.positionType === 'service-position'
                ? servicePositionTransformer
                : plainPositionTransformer
          }
        })) as any
      }
      creator={{
        type: 'collection',
        creators: [
          {
            title: 'Add Plain Position',
            type: 'creator',
            initialValueFactory: () => ({
              positionType: 'plain-position',
              name: '',
              description: '',
              priceUnitId: null,
              estimatedAmount: null,
              deadline: null,
              expressPosition: false,
              resourceIds: [],
              requiredPositionIds: []
            }),
            component: () => <PlainPositionForm />,
            validationSchema: RequestPlainPositionView.validationSchema,
            onSubmit: createRequestPosition,
            viewFactory: RequestPlainPositionView.viewFactory,
            dtoTransformer: plainPositionTransformer
          },
          {
            title: 'Add Service Position',
            type: 'creator',
            initialValueFactory: () => ({
              positionType: 'service-position',
              serviceClassId: null as unknown as string,
              priceUnitId: null,
              description: '',
              estimatedAmount: null,
              deadline: null,
              expressPosition: false,
              resourceIds: [],
              requiredPositionIds: [],
              comment: {
                comment: '',
                attachedFiles: []
              },
              languages: {
                sourceLanguage: null,
                targetLanguages: []
              }
            }),
            component: () => <RequestPositionForm />,
            validationSchema: RequestServicePositionView.validationSchema,
            onSubmit: createRequestPosition,
            viewFactory: RequestServicePositionView.viewFactory,
            dtoTransformer: servicePositionTransformer
          }
        ]
      }}
    />
  );
}
