"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.orderResourceTransformer = exports.OrderResourceDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const OrderTaskDTO_1 = require("./OrderTaskDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const OrderResource_1 = require("../entity/OrderResource");
const FileAccessDTO_1 = require("../../files/dto/FileAccessDTO");
const OrderTaskGrant_1 = require("../entity/OrderTaskGrant");
class OrderResourceDTO extends OrderTaskDTO_1.OrderTaskDTO {
}
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], OrderResourceDTO.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], OrderResourceDTO.prototype, "estimatedDelivery", void 0);
exports.OrderResourceDTO = OrderResourceDTO;
const orderResourceTransformer = (encoder) => (0, DTOTransformer_1.createDTOTransformer)(OrderResource_1.OrderResource, OrderResourceDTO, [
    'id',
    'updatedAt',
    'createdAt',
    'orderId',
    'order',
    'parentTask',
    'parentTaskId',
    'importDataRef'
], {
    attachedFiles: [
        'attachedFiles',
        (v, k, o) => {
            var _a;
            return ((_a = v === null || v === void 0 ? void 0 : v.map((f) => FileAccessDTO_1.FileAccessDTO.createUnsigned(encoder, f.id, OrderTaskGrant_1.orderTaskPermission, o))) !== null && _a !== void 0 ? _a : []);
        }
    ],
    dependencies: ['dependencies', (v) => v.map((d) => d.id)]
});
exports.orderResourceTransformer = orderResourceTransformer;
