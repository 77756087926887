import React, { PropsWithChildren } from 'react';
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { BaseSchema } from 'yup';
import {
  Close as CancelIcon,
  Edit as EditIcon,
  Save as SaveIcon
} from '@material-ui/icons';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';
import { createStyles, lighten, makeStyles } from '@material-ui/core/styles';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { NoOp } from '../../../utils';
import { ApiState } from '../../../features/auth/redux/types';
import { CardSelectionContext } from './context';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      backgroundColor:
        theme.palette.type === 'light'
          ? lighten(theme.palette.grey.A100, 0.6)
          : theme.palette.grey.A700
    }
  })
);

export interface EditableCardProps<T> {
  identifier: string;
  title: string;
  variant?: 'outlined' | 'elevation';
  validationSchema?: BaseSchema<any>;
  onSubmit: (value: T) => Promise<any> | void;
  value: T;
  // eslint-disable-next-line react/no-unused-prop-types
  apiState?: ApiState;
}

export function EditableCard<T>(
  props: PropsWithChildren<EditableCardProps<T>>
) {
  const classes = useStyles();
  const history = useHistory();

  const { reference, type } = useEditorLocation();
  const { url } = useRouteMatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editSuccess, setEditSuccess] = React.useState(false);

  let titlePrefix = '';

  if (type === 'create' && reference === props.identifier) {
    titlePrefix = 'New ';
  }

  return (
    <CardSelectionContext.Provider value={{ reference: props.identifier }}>
      <Formik
        initialValues={props.value}
        validationSchema={props.validationSchema}
        onSubmit={(validValues) => props.onSubmit(validValues)}
        enableReinitialize
      >
        {({ submitForm, resetForm }) => {
          const isEditable =
            reference === props.identifier &&
            (type === 'edit' || type === 'create');
          return (
            <>
              <Prompt
                message="All changes will be lost if you navigate now1"
                when={isEditable && !editSuccess}
              />
              <Card
                variant={props.variant}
                raised={reference === props.identifier}
                id={props.identifier}
              >
                <div className={classes.cardHeader}>
                  <Typography color="textSecondary" variant="h6">
                    {titlePrefix + props.title}
                  </Typography>
                  {isEditable ? (
                    <div>
                      <IconButton
                        size="small"
                        style={{ padding: 2, marginRight: 2 }}
                        onClick={() =>
                          submitForm()
                            .then((res) => {
                              const result = res as any;
                              if (result !== undefined) {
                                setEditSuccess(true);
                                history.replace({ search: undefined });
                              }
                              return res;
                            })
                            .catch(NoOp)
                        }
                      >
                        <SaveIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        style={{ padding: 2 }}
                        onClick={() => {
                          history.goBack();
                          resetForm();
                        }}
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <>
                      {type === 'view' && (
                        <IconButton
                          size="small"
                          style={{ padding: 2 }}
                          onClick={() =>
                            history.push(`${url}?type=edit#${props.identifier}`)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </>
                  )}
                </div>
                <Divider />
                <CardContent>
                  <Form>{props.children}</Form>
                </CardContent>
              </Card>
            </>
          );
        }}
      </Formik>
    </CardSelectionContext.Provider>
  );
}
