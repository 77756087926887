import { SupplierPatchDTO } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { suppliersThunk } from '../redux/suppliers.thunk';
import { NoOp } from '../../../utils';

export function useSupplierUpdate(supplierId: string) {
  const dispatch = useAppDispatch();

  return (input: SupplierPatchDTO) =>
    dispatch(suppliersThunk.update({ id: supplierId, input }))
      .then(unwrapResult)
      .catch(NoOp);
}
