import React, { ChangeEvent } from 'react';
import {
  Chip,
  CircularProgress,
  IconButton,
  IconButtonProps,
  SvgIcon,
  SvgIconProps
} from '@material-ui/core';
import {
  Cancel,
  Check,
  ErrorOutline,
  Publish as FileUploadIcon,
  Refresh
} from '@material-ui/icons';
import { UploadFile } from 'model';
import { useSeafileUpload } from '../../file/searfile-upload.hook';

interface FileUploadIconButtonProps {
  onFilesSelected?: (files: File[]) => void;
  icon?: typeof SvgIcon;
  iconProps?: SvgIconProps;
  iconButtonProps?: IconButtonProps;
  onFileChange?: (
    attachedFiles: (UploadFile & { grant: string })[],
    done: boolean
  ) => void;
  uploadDisabled?: boolean;
}

export function FileUploadIconButton({
  onFilesSelected,
  onFileChange,
  icon = FileUploadIcon,
  iconProps,
  iconButtonProps,
  uploadDisabled
}: FileUploadIconButtonProps) {
  const IconComponent = icon;

  const { uploadStates, uploadFiles } = useSeafileUpload({ onFileChange });

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      if (onFilesSelected) {
        onFilesSelected(Array.from(event.target.files));
      }
      if (onFileChange) {
        uploadFiles(Array.from(event.target.files));
      }
    }
  };

  const handleClick = () => hiddenFileInput.current?.click();

  const firstUpload = Object.values(uploadStates)[0];

  const progressValue =
    firstUpload?.state === 'pending'
      ? Math.round(firstUpload.progress * 100)
      : 0;

  return (
    <div>
      {!uploadDisabled && (
        <IconButton onClick={handleClick} {...iconButtonProps}>
          <IconComponent {...iconProps} />
          {firstUpload?.state === 'pending' && (
            <CircularProgress
              size="inherit"
              value={progressValue}
              style={{ position: 'absolute' }}
            />
          )}
        </IconButton>
      )}
      {firstUpload && (
        <div>
          <Chip
            icon={
              firstUpload.state === 'error' ? (
                <ErrorOutline fontSize="small" />
              ) : (
                <Check fontSize="small" />
              )
            }
            label={
              firstUpload.state === 'error' ? (
                <>
                  {firstUpload.file.name}
                  <IconButton onClick={firstUpload.retry} size="small">
                    <Refresh fontSize="small" />
                  </IconButton>
                  <IconButton onClick={firstUpload.remove} size="small">
                    <Cancel fontSize="small" />
                  </IconButton>
                </>
              ) : (
                <>
                  {firstUpload.file.name}
                  {firstUpload.state === 'uploaded' && (
                    <IconButton onClick={firstUpload.remove} size="small">
                      <Cancel fontSize="small" />
                    </IconButton>
                  )}
                </>
              )
            }
            color={firstUpload.state === 'error' ? 'primary' : 'default'}
          />
        </div>
      )}
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
}
