"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountryCode = exports.CountryCodeConstraint = void 0;
const class_validator_1 = require("class-validator");
const Countries_1 = require("../constants/Countries");
let CountryCodeConstraint = class CountryCodeConstraint {
    // eslint-disable-next-line class-methods-use-this
    validate(value) {
        return typeof value === 'string' && Countries_1.CountryCodeMap[value] !== undefined;
    }
    // eslint-disable-next-line class-methods-use-this
    defaultMessage() {
        return 'Invalid country code';
    }
};
CountryCodeConstraint = __decorate([
    (0, class_validator_1.ValidatorConstraint)({ name: 'CountryCode' })
], CountryCodeConstraint);
exports.CountryCodeConstraint = CountryCodeConstraint;
function CountryCode(validationOptions) {
    return (object, propertyName) => {
        (0, class_validator_1.registerDecorator)({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: CountryCodeConstraint
        });
    };
}
exports.CountryCode = CountryCode;
