import { DocumentType, DocumentTypeDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const documentTypesThunk = createFeatureThunks<
  DocumentType,
  DocumentTypeDTO,
  DocumentTypeDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/suppliers/document-type${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'document-types'
});
