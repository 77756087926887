import {
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';
import React, { PropsWithChildren } from 'react';

export function RightTimelineItem() {
  return (
    <TimelineOppositeContent style={{ flex: 'unset', display: 'block' }} />
  );
}
export interface CommentTimelineItemProps {
  timelineSymbol: JSX.Element;
  itemProps?: Partial<Parameters<typeof TimelineItem>[0]>;
}

export function CommentTimelineItem({
  timelineSymbol,
  children,
  itemProps
}: PropsWithChildren<CommentTimelineItemProps>) {
  return (
    <TimelineItem {...itemProps}>
      {RightTimelineItem()}
      <TimelineSeparator>
        {timelineSymbol}
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>{children}</TimelineContent>
    </TimelineItem>
  );
}
