import { ProjectVariationDTO, projectVariationTransformer } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { NoOp } from '../../../utils';
import { DialogForm } from '../../../components/input/form/DialogForm';
import projectVariationsSlice, {
  projectVariationsSelectors
} from '../redux/project-variations.slice';
import { projectVariationsThunk } from '../redux/project-variantions.thunk';
import {
  ProjectVariationBasicForm,
  ProjectVariationBasicView
} from './ProjectVariationBasicForm';

const initialValues: ProjectVariationDTO = {
  name: '',
  description: ''
};
export function ProjectVariationFormDialog({ stepId }: { stepId?: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const projectVariation = useAppSelector((state) =>
    projectVariationsSelectors.selectById(state, stepId ?? '')
  );

  const projectVariationDTO = projectVariation
    ? projectVariationTransformer(projectVariation)
    : undefined;

  const createProjectVariation = (dto: ProjectVariationDTO) => {
    if (projectVariation) {
      return dispatch(
        projectVariationsThunk.update({
          id: projectVariation.id,
          input: dto
        })
      )
        .then(unwrapResult)
        .catch(NoOp);
    }
    return dispatch(
      projectVariationsThunk.create({
        input: dto
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <DialogForm
      identifier={projectVariation?.id ?? 'project-variation'}
      maxWidth="md"
      label={
        projectVariation?.id
          ? 'Edit Project Variation'
          : 'New Project Variation'
      }
      form={{
        initialValues: projectVariationDTO ?? initialValues,
        validationSchema: ProjectVariationBasicView.validationSchema
      }}
      api={{
        clearError: projectVariationsSlice.actions.clearError,
        errorSelector: projectVariationsSelectors.selectError,
        onSubmit: createProjectVariation,
        stateSelector: projectVariationsSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <ProjectVariationBasicForm />
    </DialogForm>
  );
}
