"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.testAttachmentTransformer = exports.TestAttachmentPatchDTO = void 0;
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const TestSupplierAttachmentDTO_1 = require("./TestSupplierAttachmentDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const FileAccessDTO_1 = require("../../files/dto/FileAccessDTO");
const SupplierAttachmentGrant_1 = require("../entity/SupplierAttachmentGrant");
const SupplierTestAttachment_1 = require("../entity/SupplierTestAttachment");
const SupplierTestResult_1 = require("../entity/SupplierTestResult");
const SupplierTestResultDTO_1 = require("./SupplierTestResultDTO");
class TestAttachmentPatchDTO extends (0, partial_type_helper_1.PartialType)(TestSupplierAttachmentDTO_1.TestSupplierAttachmentDTO) {
}
exports.TestAttachmentPatchDTO = TestAttachmentPatchDTO;
const testAttachmentTransformer = (encoder) => (0, DTOTransformer_1.createDTOTransformer)(SupplierTestAttachment_1.SupplierTestAttachment, TestAttachmentPatchDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'supplier',
    'supplierId',
    'testState',
    'testType',
    'tester'
], {
    result: (0, DTOTransformer_1.nestedTransform)((0, DTOTransformer_1.createDTOTransformer)(SupplierTestResult_1.SupplierTestResult, SupplierTestResultDTO_1.SupplierTestResultDTO, ['id', 'updatedAt', 'createdAt', 'resultState', 'test', 'testId'], {}), 'result'),
    attachedFiles: [
        'attachedFiles',
        (v, k, o) => {
            return v.map((f) => FileAccessDTO_1.FileAccessDTO.createUnsigned(encoder, f.id, SupplierAttachmentGrant_1.supplierAttachmentPermission, o));
        }
    ]
});
exports.testAttachmentTransformer = testAttachmentTransformer;
