import React from 'react';
import { useSupplierQualifications } from '../hooks/supplier-qualification.hook';
import { SupplierQualificationsTable } from '../components/SupplierQualificationsTable';

export function SupplierQualificationsScreen() {
  const { supplierQualifications, apiState, listSupplierQualification } =
    useSupplierQualifications();
  return (
    <SupplierQualificationsTable
      supplierQualifications={supplierQualifications}
      onRefresh={listSupplierQualification}
      apiState={apiState}
    />
  );
}
