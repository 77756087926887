"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadFileGrant = exports.exposeUploadFileGrantGroup = void 0;
const class_transformer_1 = require("class-transformer");
const uploadFileGrantPrefix = 'upload-file-grant';
function exposeUploadFileGrantGroup(constructorName) {
    return `${uploadFileGrantPrefix}-${constructorName}`;
}
exports.exposeUploadFileGrantGroup = exposeUploadFileGrantGroup;
function UploadFileGrant(type) {
    return (target, propertyKey) => {
        const constructorName = type ? type.name : target.constructor.name;
        return (0, class_transformer_1.Expose)({
            groups: [exposeUploadFileGrantGroup(constructorName)]
        })(target, propertyKey);
    };
}
exports.UploadFileGrant = UploadFileGrant;
