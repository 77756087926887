import React from 'react';
import { Client } from 'model';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PhoneNumberComponent } from '../../../../components/text/PhoneNumberComponent';
import { EmailComponent } from '../../../../components/text/EmailComponent';
import { formatDate } from '../../../../localized-formats';

interface ClientBasicDisplayProps {
  value: Client;
}

export function ClientBasicDisplay({ value }: ClientBasicDisplayProps) {
  const { t } = useTranslation();
  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Box>
          <Box>
            <Typography variant="caption" color="textSecondary">
              {t('Name')}
            </Typography>
            <Typography>{value.name}</Typography>
          </Box>
          <Box>
            <Typography variant="caption" color="textSecondary">
              {t('Client Number')}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {value.clientNumber
                ? `${value.clientNumber.number} (${formatDate(
                    value.clientNumber.referenceDate,
                    'date'
                  )})`
                : '-'}
            </Typography>
          </Box>
        </Box>
        <Box marginTop={1}>
          <Typography variant="caption" color="textSecondary">
            {t('CEO')}
          </Typography>
          <Typography variant="body2">{value.ceo}</Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="textSecondary">
            {t('Tax ID')}
          </Typography>
          <Typography variant="body2">{value.taxId}</Typography>
        </Box>
        <Box marginTop={1}>
          <Typography variant="body2">{value.description}</Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <EmailComponent email={value.email} />
        <PhoneNumberComponent number={value.phone} />
      </Box>
    </Box>
  );
}
