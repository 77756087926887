"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectRequest = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const Project_1 = require("./Project");
// eslint-disable-next-line import/no-cycle
const RequestSource_1 = require("./RequestSource");
// eslint-disable-next-line import/no-cycle
const RequestPosition_1 = require("./RequestPosition");
// eslint-disable-next-line import/no-cycle
const ProjectOffer_1 = require("./ProjectOffer");
const CircularReference_1 = require("../../serialization/CircularReference");
// eslint-disable-next-line import/no-cycle
const RequestResource_1 = require("./RequestResource");
// eslint-disable-next-line import/no-cycle
const RequestComment_1 = require("./RequestComment");
const RequestStatus_1 = require("./RequestStatus");
let ProjectRequest = class ProjectRequest extends JournaledEntity_1.JournaledEntity {
    /*
    @RelationId((r: ProjectRequest) => r.comments)
    commentIds: string[];
  */
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.OneToOne)(() => Project_1.Project, (p) => p.request),
    (0, typeorm_1.JoinColumn)(),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", Project_1.Project)
], ProjectRequest.prototype, "project", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pr) => pr.project),
    __metadata("design:type", String)
], ProjectRequest.prototype, "projectId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => RequestSource_1.RequestSource, (rs) => rs.requests),
    __metadata("design:type", RequestSource_1.RequestSource)
], ProjectRequest.prototype, "requestSource", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pr) => pr.requestSource),
    __metadata("design:type", String)
], ProjectRequest.prototype, "requestSourceId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => RequestStatus_1.RequestStatus),
    __metadata("design:type", RequestStatus_1.RequestStatus)
], ProjectRequest.prototype, "requestStatus", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pr) => pr.requestStatus),
    __metadata("design:type", String)
], ProjectRequest.prototype, "requestStatusId", void 0);
__decorate([
    (0, typeorm_1.Column)('timestamp'),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], ProjectRequest.prototype, "dateOfRequest", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], ProjectRequest.prototype, "expressProject", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => RequestPosition_1.RequestPosition, (r) => r.request, {
        nullable: true,
        cascade: ['insert']
    }),
    (0, CircularReference_1.CircularRef)(RequestPosition_1.RequestPosition),
    __metadata("design:type", Array)
], ProjectRequest.prototype, "positions", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => RequestResource_1.RequestResource, (r) => r.request, {
        nullable: true,
        cascade: ['insert']
    }),
    (0, CircularReference_1.CircularRef)(RequestResource_1.RequestResource),
    __metadata("design:type", Array)
], ProjectRequest.prototype, "resources", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => ProjectOffer_1.ProjectOffer, (p) => p.request),
    __metadata("design:type", Array)
], ProjectRequest.prototype, "offers", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => ProjectOffer_1.ProjectOffer, { nullable: true }),
    (0, typeorm_1.JoinColumn)(),
    __metadata("design:type", ProjectOffer_1.ProjectOffer)
], ProjectRequest.prototype, "currentOffer", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pr) => pr.currentOffer),
    __metadata("design:type", String)
], ProjectRequest.prototype, "currentOfferId", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => RequestComment_1.RequestComment, (rc) => rc.request),
    __metadata("design:type", Array)
], ProjectRequest.prototype, "comments", void 0);
ProjectRequest = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], ProjectRequest);
exports.ProjectRequest = ProjectRequest;
