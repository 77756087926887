import { RequestStatusDTO, requestStatusTransformer } from 'model';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { NoOp } from '../../../utils';
import { DialogForm } from '../../../components/input/form/DialogForm';
import requestStatusSlice, {
  requestStatusSelectors
} from '../redux/request-status.slice';
import { requestStatusThunk } from '../redux/request-status.thunk';
import { RequestStatusForm, RequestStatusView } from './RequestStatusForm';

const initialValues: RequestStatusDTO = {
  name: '',
  description: '',
  type: 'open',
  color: 'rgba(255,255,255,1)'
};

export function RequestStatusDialog({ statusId }: { statusId?: string }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const requestStatus = useAppSelector((state) =>
    requestStatusSelectors.selectById(state, statusId ?? '')
  );

  const requestStatusDTO = requestStatus
    ? requestStatusTransformer(requestStatus)
    : undefined;

  const createRequestStatus = (dto: RequestStatusDTO) => {
    if (requestStatus) {
      return dispatch(
        requestStatusThunk.update({
          id: requestStatus.id,
          input: dto
        })
      )
        .then(unwrapResult)
        .catch(NoOp);
    }
    return dispatch(
      requestStatusThunk.create({
        input: dto
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <DialogForm
      identifier={requestStatus?.id ?? 'request-status'}
      maxWidth="md"
      label={
        requestStatus?.id ? t('Edit Request Status') : t('New Request Status')
      }
      form={{
        initialValues: requestStatusDTO ?? initialValues,
        validationSchema: RequestStatusView.validationSchema
      }}
      api={{
        clearError: requestStatusSlice.actions.clearError,
        errorSelector: requestStatusSelectors.selectError,
        onSubmit: createRequestStatus,
        stateSelector: requestStatusSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <RequestStatusForm />
    </DialogForm>
  );
}
