import React from 'react';
import { DocumentSupplierAttachmentDTO } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  buildDTOView,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { useDocumentType } from '../../hooks/document-type.hook';
import { FormikSelect } from '../../../../components/select/FormikSelect';
import { CommentEditor } from '../../../../components/comment/CommentEditor';

export const supplierAttachments = buildDTOView<DocumentSupplierAttachmentDTO>()
  .all()
  .withSchema(
    yup
      .object({
        attachedFiles: yup.array().of(
          yup
            .object({
              fileAccessId: yup.string().uuid().required() as any,
              token: yup.string().required() as any
            })
            .required()
        ),
        pin: yup.boolean().required(),
        attachmentType: yup.mixed().oneOf(['document']),
        documentTypeId: yup.string().defined('required').required('required'),
        documentStateId: yup.string().required('required').defined('required'),
        comment: yup.string().nullable().default(null)
      })
      .defined()
  );

export function SupplierAttachmentForm() {
  const { t } = useTranslation();
  const [, typeMeta] = useTypedField(supplierAttachments.path.documentTypeId);
  const [, commentMeta, commentHelpers] = useTypedField(
    supplierAttachments.path.comment
  );
  const [, fileMeta, fileHelpers] = useTypedField(
    supplierAttachments.path.attachedFiles
  );
  const { documentType } = useDocumentType(typeMeta.value);
  if (!documentType) {
    return <></>;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikSelect
          label={t('Status')}
          name={supplierAttachments.path.documentStateId}
          options={documentType.states.map((state) => ({
            label: state.name,
            value: state.id
          }))}
        />
      </Grid>
      <Grid item xs={12}>
        <CommentEditor
          value={commentMeta.value ?? ''}
          onChange={(s) => commentHelpers.setValue(s.trim() !== '' ? s : null)}
          onFileChange={(attachedFiles, done) => {
            if (done) {
              fileHelpers.setValue([
                ...fileMeta.value,
                ...attachedFiles.map((af) => ({
                  fileAccessId: af.id,
                  token: af.grant
                }))
              ]);
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
