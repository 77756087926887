"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestTypeState = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
const JournaledEntity_1 = require("../../JournaledEntity");
const CircularReference_1 = require("../../serialization/CircularReference");
// eslint-disable-next-line import/no-cycle
const NormalTestType_1 = require("./NormalTestType");
let TestTypeState = class TestTypeState extends JournaledEntity_1.JournaledEntity {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => NormalTestType_1.NormalTestType, (t) => t.testStates),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", NormalTestType_1.NormalTestType)
], TestTypeState.prototype, "testType", void 0);
__decorate([
    (0, typeorm_1.RelationId)((dts) => dts.testType),
    __metadata("design:type", String)
], TestTypeState.prototype, "testTypeId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], TestTypeState.prototype, "name", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], TestTypeState.prototype, "color", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], TestTypeState.prototype, "resultsAvailable", void 0);
TestTypeState = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.Unique)(['testType', 'name']),
    __metadata("design:paramtypes", [Object])
], TestTypeState);
exports.TestTypeState = TestTypeState;
