import React from 'react';
import { permissions, DocumentType } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Delete as DeleteIcon, Edit } from '@material-ui/icons';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { ApiState } from '../../auth/redux/types';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { documentTypesThunk } from '../redux/document-type.thunk';

function buildTableColumns() {
  return entityColumnBuilder<DocumentType>()
    .addDefaultColumn('document_type_name', 'name', 'Name')
    .addDefaultColumn('document_type_description', 'description', 'Description')
    .addBooleanColumn(
      'document_type_activation_constraint',
      'activationConstraint',
      'Activation Constraint'
    ).columns;
}

interface DocumentTypesTableProps {
  documentTypes: DocumentType[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function DocumentTypesTable({
  documentTypes,
  onRefresh,
  apiState
}: DocumentTypesTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  const dispatch = useAppDispatch();
  const deleteDocumentType = (id: string) => {
    dispatch(documentTypesThunk.delete({ id }));
  };

  return (
    <GenericTable
      label={t('Document Types')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Document Type',
        onClick: () => history.push(`${url}?type=create#document-type`)
      }}
      data={documentTypes}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.suppliers.documentType.edit,
          entry: (domain) => ({
            label: 'Edit',
            icon: Edit,
            link: `${url}?type=edit#${domain.id}`
          })
        },
        {
          permissions: permissions.suppliers.documentType.delete,
          entry: (d) => ({
            icon: <DeleteIcon />,
            label: 'Delete',
            alert: {
              title: 'Delete Document Type',
              content: `${t(
                'Are you  sure you want to delete the document type'
              )} "${d.name}"?`,
              onConfirm: () => {
                deleteDocumentType(d.id);
              }
            }
          })
        }
      ]}
      toolbarActions={
        [
          /*        {
                    icon: <PlusIcon />,
                    label: 'Merge',
                    showWhen: 'selected',
                    onClick: () => {}
                  } */
        ]
      }
    />
  );
}
