import {
  createEntityAdapter,
  createSelector,
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit';
import { UserProjectTemplate } from 'model';
import {
  listUserProjectTemplatesThunk,
  markUserProjectTemplateThunk,
  removeUserProjectTemplateThunk
} from './actions/list-user-project-templates.thunk';
import { getInitialApiAdapterState } from '../../auth/redux/types';
import { createAdapterSelectors } from '../../../redux/enitySlice';

const templateAdapter = createEntityAdapter<UserProjectTemplate>();
export const userProjectTemplateSlice = createSlice({
  name: 'user-project-templates',
  initialState: templateAdapter.getInitialState(getInitialApiAdapterState()),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addMatcher(
        isFulfilled(listUserProjectTemplatesThunk),
        (state, { payload }) => {
          state.state = 'idle';
          state.listFetched = true;
          templateAdapter.setAll(state, payload);
        }
      )
      .addMatcher(
        isFulfilled(markUserProjectTemplateThunk),
        (state, { payload }) => {
          state.state = 'idle';
          return templateAdapter.upsertOne(state, payload);
        }
      )
      .addMatcher(
        isFulfilled(removeUserProjectTemplateThunk),
        (state, { payload }) => {
          state.state = 'idle';
          return templateAdapter.removeOne(state, payload.id);
        }
      )
      .addMatcher(
        isPending(
          listUserProjectTemplatesThunk,
          markUserProjectTemplateThunk,
          removeUserProjectTemplateThunk
        ),
        (state) => {
          state.state = 'pending';
        }
      )
      .addMatcher(
        isRejected(
          listUserProjectTemplatesThunk,
          markUserProjectTemplateThunk,
          removeUserProjectTemplateThunk
        ),
        (state) => {
          state.state = 'idle';
        }
      )
});

const adapterSelectors = createAdapterSelectors(
  'user-project-templates',
  templateAdapter
);
export const userProjectTemplateSelectors = {
  ...adapterSelectors,
  selectByUserAndProject: createSelector(
    [
      adapterSelectors.selectAll,
      (state, args: { userId: string; projectId: string }) => args
    ],
    (items, { userId, projectId }) =>
      items.find((e) => e?.userId === userId && e?.projectId === projectId)
  )
};
