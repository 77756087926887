import { PriceBound, PriceBoundDTO, PriceBoundPatchDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const priceBoundsThunk = createFeatureThunks<
  PriceBound,
  PriceBoundDTO,
  PriceBoundPatchDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/prices/price-bound${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'price-bounds'
});

export const definePriceBoundThunk = createErrorHandlingThunk<
  PriceBound,
  { input: PriceBoundDTO }
>('price-bounds/define-bound', async ({ input }) => {
  const result = await apiClient.put(`/prices/price-bound`, input);
  return (await apiClient.get(`/prices/price-bound/${result.data.id}`)).data;
});
