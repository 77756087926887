import { InitialServicePositionDTO, ServiceRequestPositionDTO } from 'model';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  buildDTOView,
  useTypedField
} from '../../../../../../transformer/DTOViewSchema';
import { FormikEntitySelect } from '../../../../../../components/select/FormikEntitySelect';
import { serviceClassAPI } from '../../../../../services/redux/service-classes.slice';
import { priceUnitsAPI } from '../../../../../prices/redux/price-units.slice';
import { FormikTextField } from '../../../../../../components/input/FormikTextField';
import { FormikDatePicker } from '../../../../../../components/input/date/FormikDatePicker';
import { RequestPositionLanguageSelect } from './RequestPositionLanguageSelect';
import { useServiceClasses } from '../../../../../services/hooks/service-classes.hook';

export const RequestServicePositionView = buildDTOView<
  InitialServicePositionDTO | ServiceRequestPositionDTO
>()
  .all()
  .withSchema(
    yup
      .object({
        description: yup.string().defined().default(''),
        serviceClassId: yup.string().uuid().required(),
        priceUnitId: yup.string().uuid().defined().nullable().default(null),
        estimatedAmount: yup.number().defined().nullable().default(null),
        deadline: yup.date().defined().nullable().default(null),
        comment: yup
          .object({
            comment: yup.string().defined().default('') as any,
            attachedFiles: yup
              .array()
              .of(
                yup
                  .object({
                    fileAccessId: yup.string().uuid().required() as any,
                    token: yup.string().required() as any
                  })
                  .required()
              )
              .notRequired()
              .default(undefined)
          })
          .nullable()
          .default(null),
        languages: yup
          .object({
            sourceLanguage: yup
              .string()
              .uuid()
              .defined()
              .nullable()
              .default(null),
            targetLanguages: yup
              .array(yup.string().uuid().required())
              .min(1, 'min 1')
              .defined() as any
          })
          .defined()
          .nullable()
          .default(null),
        positionType: yup.mixed().required().oneOf(['service-position']),
        expressPosition: yup.boolean().defined().nullable().default(null)
      })
      .defined()
  );

export function RequestPositionForm({
  basePath = RequestServicePositionView.path
}: {
  basePath?: (typeof RequestServicePositionView)['path'];
}) {
  const { t } = useTranslation();
  const { entities: serviceClasses } = useServiceClasses();
  const [, langMeta, langHelper] = useTypedField<
    ServiceRequestPositionDTO['languages']
  >(basePath.sub_path('languages'));
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormikEntitySelect
          entityApi={serviceClassAPI}
          optionLabel={(v) => v.name}
          name={basePath.sub_path('serviceClassId')}
          textFieldProps={{ label: t('Service') }}
          onChange={(a) => {
            const serviceClass = serviceClasses.find((sc) => sc.id === a);
            if (serviceClass) {
              const oldSource = langMeta.value?.sourceLanguage ?? null;
              const oldTargets = langMeta.value?.targetLanguages ?? [];
              if (serviceClass.type === 'no-language') {
                langHelper.setValue(null);
              } else {
                langHelper.setValue({
                  sourceLanguage:
                    serviceClass.type === 'source-target' ? oldSource : null,
                  targetLanguages: oldTargets
                });
              }
            }
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          name={basePath.sub_path('description')}
          label="Description"
          multiline
        />
      </Grid>
      <Grid item xs={4}>
        <FormikEntitySelect
          entityApi={priceUnitsAPI}
          optionLabel={(v) => v.name}
          name={basePath.sub_path('priceUnitId')}
          textFieldProps={{ label: t('Price Unit') }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name={basePath.sub_path('estimatedAmount')}
          type="number"
          label="Estimated Amount"
        />
      </Grid>
      <Grid item xs={4}>
        <FormikDatePicker
          label={t('Deadline')}
          name={basePath.sub_path('deadline')}
        />
      </Grid>

      <Grid item xs={12}>
        <RequestPositionLanguageSelect basePath={basePath} />
      </Grid>
    </Grid>
  );
}
