import { SerializedError } from '@reduxjs/toolkit';

export interface SliceError {
  error: SerializedError | null;
}

export type ApiState = 'fresh' | 'idle' | 'pending' | 'unauthorized';

export interface ApiAdapterState extends SliceError {
  state: ApiState;
  listFetched?: boolean;
  subQueries?: Record<string, 'fetched' | 'pending' | 'fresh'>;
}

export const getInitialApiAdapterState = (): ApiAdapterState => ({
  error: null,
  state: 'fresh',
  listFetched: false,
  subQueries: {}
});
