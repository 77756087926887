"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.defineRequestAttachmentPermission = exports.RequestAttachmentGrant = void 0;
const class_validator_1 = require("class-validator");
const UploadAccessPermissionGrant_1 = require("../../auth/UploadAccessPermissionGrant");
class RequestAttachmentGrant extends UploadAccessPermissionGrant_1.UploadAccessPermissionGrant {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}
__decorate([
    (0, class_validator_1.IsUUID)(),
    __metadata("design:type", String)
], RequestAttachmentGrant.prototype, "request", void 0);
exports.RequestAttachmentGrant = RequestAttachmentGrant;
function defineRequestAttachmentPermission(name, createGrant) {
    return {
        name,
        grantType: RequestAttachmentGrant,
        createGrant,
        signed: false
    };
}
exports.defineRequestAttachmentPermission = defineRequestAttachmentPermission;
