"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.contactDataParser = exports.ClientContactImportData = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const lodash_1 = require("lodash");
const IsNullable_1 = require("../../transformation/IsNullable");
const csv_columns_descriptor_1 = require("../../csv/csv-columns.descriptor");
const column_descriptor_1 = require("../../csv/column-descriptor");
class ClientContactImportData {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.Matches)(/^[0-9]{5}$/),
    __metadata("design:type", String)
], ClientContactImportData.prototype, "clientNumber", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.Length)(2),
    __metadata("design:type", String)
], ClientContactImportData.prototype, "contactName", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsEmail)(),
    __metadata("design:type", String)
], ClientContactImportData.prototype, "contactEmail", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], ClientContactImportData.prototype, "mainContact", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", String)
], ClientContactImportData.prototype, "mobile", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", String)
], ClientContactImportData.prototype, "phone", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", String)
], ClientContactImportData.prototype, "comment", void 0);
exports.ClientContactImportData = ClientContactImportData;
exports.contactDataParser = csv_columns_descriptor_1.CSVColumnBuilder.create()
    .append({
    clientNumber: column_descriptor_1.ColumnDescriptor.create().withValidation((d) => /^[0-9]{5}$/.test(d))
})
    .skip()
    .append({
    contactName: column_descriptor_1.ColumnDescriptor.create().withValidation((v) => v !== ''),
    contactEmail: column_descriptor_1.ColumnDescriptor.create()
        .withValidation((v) => (0, class_validator_1.isEmail)(v))
        .orEmpty(null),
    mainContact: column_descriptor_1.ColumnDescriptor.create()
        .toCase('lower')
        .valueMap({ ja: true, nein: false, '': false }),
    mobile: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
    phone: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
    comment: column_descriptor_1.ColumnDescriptor.create().orEmpty(null)
})
    .build({
    exclude: (data) => Object.values((0, lodash_1.omit)(data, 'clientNumber')).every((v) => v === '')
});
