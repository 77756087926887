"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestCommentPermission = exports.RequestComment = void 0;
const typeorm_1 = require("typeorm");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const ProjectRequest_1 = require("./ProjectRequest");
// eslint-disable-next-line import/no-cycle
const User_1 = require("../../user/entity/User");
// eslint-disable-next-line import/no-cycle
const ProjectOffer_1 = require("./ProjectOffer");
// eslint-disable-next-line import/no-cycle
const RequestResource_1 = require("./RequestResource");
// eslint-disable-next-line import/no-cycle
const RequestPosition_1 = require("./RequestPosition");
// eslint-disable-next-line import/no-cycle
const UploadFile_1 = require("../../files/entity/UploadFile");
const CircularReference_1 = require("../../serialization/CircularReference");
const RequestAttachmentGrant_1 = require("./RequestAttachmentGrant");
// eslint-disable-next-line import/no-cycle
const ProjectCandidate_1 = require("./ProjectCandidate");
let RequestComment = class RequestComment extends JournaledEntity_1.JournaledEntity {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectRequest_1.ProjectRequest),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", ProjectRequest_1.ProjectRequest)
], RequestComment.prototype, "request", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rc) => rc.request),
    __metadata("design:type", String)
], RequestComment.prototype, "requestId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => User_1.User),
    __metadata("design:type", User_1.User)
], RequestComment.prototype, "author", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rc) => rc.author),
    __metadata("design:type", String)
], RequestComment.prototype, "authorId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], RequestComment.prototype, "comment", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectOffer_1.ProjectOffer, { nullable: true }),
    (0, CircularReference_1.CircularRef)(ProjectOffer_1.ProjectOffer),
    __metadata("design:type", ProjectOffer_1.ProjectOffer)
], RequestComment.prototype, "offer", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rc) => rc.offer),
    __metadata("design:type", String)
], RequestComment.prototype, "offerId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => RequestResource_1.RequestResource, { nullable: true }),
    (0, CircularReference_1.CircularRef)(RequestResource_1.RequestResource),
    __metadata("design:type", RequestResource_1.RequestResource)
], RequestComment.prototype, "resource", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rc) => rc.resource),
    __metadata("design:type", String)
], RequestComment.prototype, "resourceId", void 0);
__decorate([
    (0, CircularReference_1.CircularRef)(RequestPosition_1.RequestPosition),
    (0, CircularReference_1.CircularRef)('PlainRequestPosition'),
    (0, CircularReference_1.CircularRef)('ServiceRequestPosition'),
    (0, typeorm_1.ManyToOne)(() => RequestResource_1.RequestResource, { nullable: true }),
    __metadata("design:type", RequestPosition_1.RequestPosition)
], RequestComment.prototype, "position", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rc) => rc.position),
    __metadata("design:type", String)
], RequestComment.prototype, "positionId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectCandidate_1.ProjectCandidate, { nullable: true }),
    (0, CircularReference_1.CircularRef)(ProjectCandidate_1.ProjectCandidate),
    __metadata("design:type", ProjectCandidate_1.ProjectCandidate)
], RequestComment.prototype, "candidate", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rc) => rc.candidate),
    __metadata("design:type", String)
], RequestComment.prototype, "candidateId", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => UploadFile_1.UploadFile),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], RequestComment.prototype, "attachedFiles", void 0);
__decorate([
    (0, typeorm_1.Column)('jsonb'),
    __metadata("design:type", Object)
], RequestComment.prototype, "importDataRef", void 0);
RequestComment = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], RequestComment);
exports.RequestComment = RequestComment;
exports.requestCommentPermission = (0, RequestAttachmentGrant_1.defineRequestAttachmentPermission)('projectRequestComment', (c) => new RequestAttachmentGrant_1.RequestAttachmentGrant({
    type: 'projectRequestComment',
    id: c.id,
    request: c.requestId
}));
