import { Qualification, QualificationDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const supplierQualificationsThunk = createFeatureThunks<
  Qualification,
  QualificationDTO,
  QualificationDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/suppliers/qualification${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'supplier-qualifications'
});
