import { TemplateScope } from 'model';
import { useSequenceTemplates } from './sequence-templates.hook';

export function useSequenceTemplateSelector(
  projectVariationId: string | null,
  scope: TemplateScope
) {
  const { sequenceTemplates } = useSequenceTemplates();
  return sequenceTemplates.length > 0
    ? sequenceTemplates.find(
        (st) =>
          st.projectVariationId === projectVariationId && st.scope === scope
      )
    : undefined;
}
