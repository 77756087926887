import React from 'react';
import { ProjectRequest } from 'model';
import { Chip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { StyledLink } from '../../../../../components/input/link/StyledLink';
import { useRequestStatus } from '../../../hooks/request-status.hook';
import { formatDate } from '../../../../../localized-formats';

export function RequestBasicDisplay({ value }: { value: ProjectRequest }) {
  const { t } = useTranslation();
  const { requestStatus } = useRequestStatus(value.requestStatusId);
  // const { project } = useProject(value.projectId);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Chip
          title={requestStatus?.description}
          size="small"
          variant="outlined"
          style={{ backgroundColor: requestStatus?.color }}
          label={requestStatus?.name || <Skeleton />}
        />
        {value.expressProject && (
          <Chip style={{ marginTop: 5 }} size="small" label={t('Express')} />
        )}
      </div>
      <div>
        <Typography variant="caption" color="textSecondary">
          {t('Requested on')}
        </Typography>
        <Typography variant="body2">
          {formatDate(value.dateOfRequest, 'date')}
        </Typography>
      </div>
      {value.requestSource && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="caption" color="textSecondary">
            {t('Request Resource')}
          </Typography>
          <StyledLink
            to={`/projects/request-source/${value.requestSource?.id}`}
          >
            {value.requestSource?.name}
          </StyledLink>
        </div>
      )}
    </div>
  );
}
