"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var ClientComment_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientComment = void 0;
const typeorm_1 = require("typeorm");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const User_1 = require("../../user/entity/User");
// eslint-disable-next-line import/no-cycle
const Client_1 = require("./Client");
// eslint-disable-next-line import/no-cycle
const UploadFile_1 = require("../../files/entity/UploadFile");
const CircularReference_1 = require("../../serialization/CircularReference");
let ClientComment = ClientComment_1 = class ClientComment extends JournaledEntity_1.JournaledEntity {
    static forClient(client) {
        const comment = new ClientComment_1();
        comment.client = client;
        return comment;
    }
    static forClientAndCreator(client, creator) {
        const comment = ClientComment_1.forClient(client);
        comment.author = creator;
        return comment;
    }
};
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], ClientComment.prototype, "comment", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => UploadFile_1.UploadFile),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], ClientComment.prototype, "attachedFiles", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => User_1.User),
    __metadata("design:type", User_1.User)
], ClientComment.prototype, "author", void 0);
__decorate([
    (0, typeorm_1.RelationId)((c) => c.author),
    __metadata("design:type", String)
], ClientComment.prototype, "authorId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Client_1.Client, (client) => client.comments),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", Client_1.Client)
], ClientComment.prototype, "client", void 0);
__decorate([
    (0, typeorm_1.RelationId)((c) => c.client),
    __metadata("design:type", String)
], ClientComment.prototype, "clientId", void 0);
ClientComment = ClientComment_1 = __decorate([
    (0, typeorm_1.Entity)()
], ClientComment);
exports.ClientComment = ClientComment;
