"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadFile = exports.fileUploadPermission = void 0;
const typeorm_1 = require("typeorm");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const User_1 = require("../../user/entity/User");
const UploadAccessPermissionGrant_1 = require("../../auth/UploadAccessPermissionGrant");
const UploadAccessPermission_1 = require("../../auth/UploadAccessPermission");
exports.fileUploadPermission = (0, UploadAccessPermission_1.defineUploadPermission)('fileUpload', UploadAccessPermissionGrant_1.UploadAccessPermissionGrant, 
// eslint-disable-next-line no-use-before-define
(file) => new UploadAccessPermissionGrant_1.UploadAccessPermissionGrant({
    type: 'fileUpload',
    id: file.id
}), true);
let UploadFile = class UploadFile extends JournaledEntity_1.JournaledEntity {
};
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], UploadFile.prototype, "name", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], UploadFile.prototype, "fileType", void 0);
__decorate([
    (0, typeorm_1.Column)({
        nullable: true
    }),
    __metadata("design:type", String)
], UploadFile.prototype, "comment", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => User_1.User),
    __metadata("design:type", User_1.User)
], UploadFile.prototype, "uploader", void 0);
__decorate([
    (0, typeorm_1.RelationId)((uf) => uf.uploader),
    __metadata("design:type", String)
], UploadFile.prototype, "uploaderId", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'varchar', array: true }),
    __metadata("design:type", Array)
], UploadFile.prototype, "references", void 0);
UploadFile = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.TableInheritance)({ column: { type: 'varchar', name: 'fileType' } })
], UploadFile);
exports.UploadFile = UploadFile;
