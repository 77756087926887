import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import React from 'react';
import { Box, Popover, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import { useUserProjectTemplates } from '../../../../users/hooks/user-project-templates.hook';
import { ProjectTemplateList } from '../../templates/ProjectTemplateList';

function DuplicateRequestTableActionList() {
  const { entities, listFetched } = useUserProjectTemplates();
  const { t } = useTranslation();
  return (
    <Box width="100%" padding="10px">
      {listFetched || <Skeleton />}
      {listFetched && entities.length === 0 && (
        <Typography
          variant="subtitle1"
          style={{ marginLeft: '2em', color: 'grey' }}
        >
          - {t('You have not marked any project as a template yet')} -
        </Typography>
      )}
      {listFetched && entities.length > 0 && (
        <ProjectTemplateList templates={entities} hideDelete />
      )}
    </Box>
  );
}

export function DuplicateRequestTableAction() {
  const [open, setOpen] = React.useState<SVGElement | null>(null);
  return (
    <>
      <HiOutlineDocumentDuplicate
        onClick={(evt) => {
          setOpen(evt.target as SVGElement);
        }}
      />
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {!!open && <DuplicateRequestTableActionList />}
      </Popover>
    </>
  );
}
