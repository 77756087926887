import { InitialDataAggregation, ServiceStep } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { serviceStepsThunk } from './service-steps.thunk';
import { RootState } from '../../../redux/store';
import { fetchInitialDataThunk } from '../../../redux/initial-data.thunk';

const { list, delete: deleteThunk, ...thunks } = serviceStepsThunk;

const serviceStepsSlice = createEntitySlice<ServiceStep>('service-steps', [
  CustomThunk.override<ServiceStep>(list, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as ServiceStep[]);
    }
  }),
  CustomThunk.override<any>(fetchInitialDataThunk, {
    pending: (_, state) => {
      return state;
    },
    rejected: (_, state) => {
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      const dataPayload = payload as InitialDataAggregation;
      if (dataPayload.serviceSteps) {
        state.state = state.state === 'fresh' ? 'idle' : state.state;
        state.listFetched = true;
        return adapter.upsertMany(state, dataPayload.serviceSteps);
      }
      return state;
    }
  }),
  CustomThunk.override<ServiceStep>(
    deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  ...Object.values(thunks)
]);
export const serviceStepsSelectors = {
  ...createAdapterSelectors('service-steps', serviceStepsSlice.adapter),
  selectPriceable: (rs: RootState) =>
    serviceStepsSelectors.selectAll(rs).filter((step) => step.priceable)
};

export const serviceStepAPI: EntityAPI<ServiceStep> = {
  selectors: serviceStepsSelectors,
  thunks: serviceStepsThunk
};
export default serviceStepsSlice;
