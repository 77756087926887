import React from 'react';
import { SupplierDomain } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { IconButton, List, ListItem } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '../../../../error/ErrorBoundary';
import { supplierDomainsSelectors } from '../../../redux/supplier-domains.slice';
import { supplierDomainsThunk } from '../../../redux/supplier-domains.thunk';
import { EntityAPI } from '../../../../../redux/entity-api.hook';
import { FormikQuickaddEntitySelect } from '../../../../../components/select/FormikQuickaddEntitySelect';
import { useAppDispatch } from '../../../../../redux/redux.hooks';
import { NoOp } from '../../../../../utils';

export function SupplierDomainsForm() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const addDomain = (name: string) => {
    return dispatch(
      supplierDomainsThunk.create({
        input: {
          name,
          description: ''
        }
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <ErrorBoundary context="SupplierDomainsForm">
      <List dense>
        <ListItem dense>
          <FormikQuickaddEntitySelect
            entityApi={
              {
                selectors: supplierDomainsSelectors,
                thunks: supplierDomainsThunk
              } as EntityAPI<SupplierDomain>
            }
            optionLabel={(d) => d.name}
            textFieldProps={{ placeholder: t('Select new domain') }}
            name="domainIds"
            quickAdd={(v, onSubmit) =>
              v !== undefined && v.length > 1 ? (
                <IconButton
                  onClick={() => {
                    addDomain(v)
                      .then((d) => {
                        onSubmit(d as SupplierDomain);
                        return d;
                      })
                      .catch(NoOp);
                  }}
                >
                  <PlusIcon />
                </IconButton>
              ) : (
                <></>
              )
            }
            renderer={(v) => v.name}
          />
        </ListItem>
      </List>
    </ErrorBoundary>
  );
}
