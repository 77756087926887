import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import {
  textComponentStyleOverrides,
  typographyOptions
} from './typographyOptions';

export const plainLightTheme: ThemeOptions = {
  palette: {
    type: 'light',
    // background: {
    //   default: '#FFFFFF',
    //   paper: '#ef4035'
    // },
    primary: {
      main: '#EF4035',
      light: '#FF7460',
      dark: '#B5000C',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#F79F9A',
      light: '#FCD9D7',
      dark: '#F79F9A',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    action: {
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      focus: 'rgba(0, 0, 0, 0.12)'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    info: {
      main: '#EF4035',
      light: '#64B6F7',
      dark: '#BDBDBD',
      contrastText: 'rgba(255, 255, 255, 0.87)'
    },
    success: {
      main: '#94D58F',
      light: '#7BC67E',
      dark: '#3B873E',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#B5000C',
      light: 'rgba(153, 19, 9, 0.5)',
      dark: '#B5000C',
      contrastText: '#FFFFFF'
    },
    // warning: {
    //   main: '#212121',
    //   light: '#EEEEEE',
    //   dark: '#9E9E9E',
    //   contrastText: '#EEEEEE'
    // },
    grey: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#D5D5D5',
      A200: '#AAAAAA',
      A400: '#616161',
      A700: '#303030'
    },
    common: {
      black: '#000000',
      white: '#FFFFFF'
    }
  },
  spacing: 10,
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#FAFAFA',
        zIndex: 2
      }
    },
    MuiLink: {
      root: {
        color: 'rgba(47, 128, 237, 1)'
      }
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid #e8e8e8',
        minHeight: 40
      },
      indicator: {
        display: 'none'
      }
    },
    MuiTab: {
      root: {
        minHeight: 40,
        borderTop: '1px solid #e8e8e8',
        borderRight: '1px solid #e8e8e8',
        borderLeft: '1px solid #e8e8e8',
        marginRight: 5,
        backgroundColor: 'rgba(239, 239, 239, 0.5)',
        fontSize: '1.1em',
        lineHeight: '1.2em',
        letterSpacing: '0.009375em',
        fontFamily: 'AvenirBlack',
        '&.Mui-selected': {
          backgroundColor: 'white',
          color: 'rgba(0, 3, 25, 0.54)'
        },
        '&:hover': {
          color: 'rgba(0, 3, 25, 0.54)',
          opacity: 1
        }
      },
      textColorInherit: {
        color: 'rgba(180, 180, 180, 1)'
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FAFAFA'
      }
    },
    ...textComponentStyleOverrides
  },
  typography: typographyOptions
};

export const lightTheme = createMuiTheme(plainLightTheme);
