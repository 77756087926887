import { ServiceClassPatchDTO } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import React from 'react';
import {
  buildDTOView,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { FormikSelect } from '../../../../components/select/FormikSelect';
import { FormikEntitySelect } from '../../../../components/select/FormikEntitySelect';
import { priceUnitsAPI } from '../../../prices/redux/price-units.slice';
import { FormikTextField } from '../../../../components/input/FormikTextField';

export const serviceClassBasicView = buildDTOView<ServiceClassPatchDTO>()
  .exclude('requiredQualifications')
  .withSchema(
    yup
      .object({
        name: yup.string().required().min(2, 'At least 2 characters'),
        description: yup.string().max(255, 'Max. 255 characters').defined(),
        type: yup
          .string()
          .defined()
          .matches(/^((no-language)|(only-target)|(source-target))$/) as any,
        defaultPriceUnitId: yup.string().defined('required').uuid(),
        usablePriceUnitIds: yup
          .array(yup.string().required().uuid())
          .min(1, 'select min one unit')
          .required()
      })
      .defined() as any
  );

export function ServiceClassBasicForm() {
  const { t } = useTranslation();

  const [, useablePriceUnitIdsMeta] = useTypedField(
    serviceClassBasicView.path.usablePriceUnitIds
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12}>
          <FormikTextField
            required
            name={serviceClassBasicView.path.name}
            label={t('Name')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikEntitySelect
            textFieldProps={{ label: 'Usable price units', required: true }}
            multiple
            entityApi={priceUnitsAPI}
            optionLabel={(p) => p.name}
            name={serviceClassBasicView.path.usablePriceUnitIds}
          />
        </Grid>
        {useablePriceUnitIdsMeta.value.length > 0 && (
          <Grid item xs={6}>
            <FormikEntitySelect
              textFieldProps={{ label: 'Default price unit', required: true }}
              entityApi={priceUnitsAPI}
              filter={(u) => useablePriceUnitIdsMeta.value.includes(u.id)}
              optionLabel={(p) => p.name}
              name={serviceClassBasicView.path.defaultPriceUnitId}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormikSelect
            name={serviceClassBasicView.path.type}
            label={t('Type')}
            options={[
              {
                value: 'no-language',
                label: t('No Language')
              },
              {
                value: 'only-target',
                label: t('Only target language')
              },
              {
                value: 'source-target',
                label: t('Source and Target Language')
              }
            ]}
          />
        </Grid>

        <Grid item xs={12}>
          <FormikTextField
            required
            label={t('Description')}
            name={serviceClassBasicView.path.description}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
