import React from 'react';
import { Client } from 'model';
import { Chip } from '@material-ui/core';
import PopoverPreview from '../../../../components/preview/PopoverPreview';

export function ContactsColumnRenderer({ client: data }: { client: Client }) {
  const mainContacts = data.contacts.filter((c) => c.mainContact);
  return (
    <div>
      {mainContacts.map((contact) => {
        return (
          <PopoverPreview
            key={`preview-contact-${contact.id}`}
            ready
            content={
              <div>
                <span>{`${contact.name.first} ${contact.name.last}`}</span>
                <br />
                <span>{contact.email}</span>
                <br />
                <span>{contact.description}</span>
                <br />
                <span>{contact.comment} </span>
                <br />
              </div>
            }
          >
            <Chip label={`${contact.name.first} ${contact.name.last}`} />
          </PopoverPreview>
        );
      })}
    </div>
  );
}
