"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectOfferPermission = exports.ProjectOffer = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const ProjectRequest_1 = require("./ProjectRequest");
// eslint-disable-next-line import/no-cycle
const ProjectOrder_1 = require("./ProjectOrder");
// eslint-disable-next-line import/no-cycle
const UploadFile_1 = require("../../files/entity/UploadFile");
// eslint-disable-next-line import/no-cycle
const RequestComment_1 = require("./RequestComment");
// eslint-disable-next-line import/no-cycle
const User_1 = require("../../user/entity/User");
const CircularReference_1 = require("../../serialization/CircularReference");
const RequestAttachmentGrant_1 = require("./RequestAttachmentGrant");
// eslint-disable-next-line import/no-cycle
const OfferPosition_1 = require("./OfferPosition");
let ProjectOffer = class ProjectOffer extends JournaledEntity_1.JournaledEntity {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectRequest_1.ProjectRequest, (p) => p.offers),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", ProjectRequest_1.ProjectRequest)
], ProjectOffer.prototype, "request", void 0);
__decorate([
    (0, typeorm_1.RelationId)((po) => po.request),
    __metadata("design:type", String)
], ProjectOffer.prototype, "requestId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => User_1.User),
    __metadata("design:type", User_1.User)
], ProjectOffer.prototype, "author", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rc) => rc.author),
    __metadata("design:type", String)
], ProjectOffer.prototype, "authorId", void 0);
__decorate([
    (0, typeorm_1.Column)('timestamp'),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], ProjectOffer.prototype, "offerDate", void 0);
__decorate([
    (0, typeorm_1.Column)('timestamp', { nullable: true }),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], ProjectOffer.prototype, "validUntil", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'jsonb', nullable: true }),
    __metadata("design:type", Object)
], ProjectOffer.prototype, "totalPrice", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => RequestComment_1.RequestComment, { nullable: true }),
    (0, typeorm_1.JoinColumn)(),
    __metadata("design:type", RequestComment_1.RequestComment)
], ProjectOffer.prototype, "rejection", void 0);
__decorate([
    (0, typeorm_1.RelationId)((po) => po.rejection),
    __metadata("design:type", String)
], ProjectOffer.prototype, "rejectionId", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => ProjectOrder_1.ProjectOrder, (o) => o.offer, { nullable: true }),
    (0, typeorm_1.JoinColumn)(),
    __metadata("design:type", ProjectOrder_1.ProjectOrder)
], ProjectOffer.prototype, "order", void 0);
__decorate([
    (0, typeorm_1.RelationId)((po) => po.order),
    __metadata("design:type", String)
], ProjectOffer.prototype, "orderId", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => UploadFile_1.UploadFile),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], ProjectOffer.prototype, "attachedFiles", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => RequestComment_1.RequestComment, (rc) => rc.offer),
    __metadata("design:type", Array)
], ProjectOffer.prototype, "comments", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rc) => rc.comments),
    __metadata("design:type", Array)
], ProjectOffer.prototype, "commentIds", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], ProjectOffer.prototype, "currency", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true, type: 'numeric' }),
    (0, class_transformer_1.Type)(() => Number),
    __metadata("design:type", Number)
], ProjectOffer.prototype, "taxPercentage", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => OfferPosition_1.OfferPosition, (p) => p.offer),
    __metadata("design:type", Array)
], ProjectOffer.prototype, "positions", void 0);
__decorate([
    (0, typeorm_1.RelationId)((po) => po.positions),
    __metadata("design:type", Array)
], ProjectOffer.prototype, "positionIds", void 0);
ProjectOffer = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], ProjectOffer);
exports.ProjectOffer = ProjectOffer;
exports.projectOfferPermission = (0, RequestAttachmentGrant_1.defineRequestAttachmentPermission)('projectRequestOffer', (a) => new RequestAttachmentGrant_1.RequestAttachmentGrant({
    type: 'projectRequestOffer',
    id: a.id,
    request: a.requestId
}));
