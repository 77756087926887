"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WordbeeStaticList = void 0;
exports.WordbeeStaticList = [
    { name: 'Afrikaans', code: 'af' },
    { name: 'Afrikaans (Namibia)', code: 'af-NA' },
    { name: 'Afrikaans (South Africa)', code: 'af-ZA' },
    { name: 'Akan', code: 'ak' },
    { name: 'Akan (Fante)', code: 'ak-Fant', ietf: 'fat' },
    { name: 'Akan (Fante, Ghana)', code: 'ak-Fant-GH', ietf: 'fat-GH' },
    { name: 'Albanian', code: 'sq' },
    { name: 'Albanian (Albania)', code: 'sq-AL' },
    { name: 'Albanian (Former Yugoslav Republic of Macedonia)', code: 'sq-MK' },
    { name: 'Albanian (Gheg)', code: 'sq-ALN', ietf: 'aln' },
    { name: 'Albanian (Kosovo)', code: 'sq-XK' },
    { name: 'Albanian (Tosk)', code: 'sq-ALS', ietf: 'als' },
    { name: 'Alsatian', code: 'gsw' },
    { name: 'Alsatian (France)', code: 'gsw-FR' },
    { name: 'Amharic', code: 'am' },
    { name: 'Amharic (Ethiopia)', code: 'am-ET' },
    { name: 'Amharic (United States)', code: 'am-US' },
    { name: 'Arabic', code: 'ar' },
    { name: 'Arabic (Algeria)', code: 'ar-DZ' },
    { name: 'Arabic (Bahrain)', code: 'ar-BH' },
    { name: 'Arabic (Chad)', code: 'ar-TD' },
    { name: 'Arabic (Egypt)', code: 'ar-EG' },
    { name: 'Arabic (Iraq)', code: 'ar-IQ' },
    { name: 'Arabic (Israel)', code: 'ar-IL' },
    { name: 'Arabic (Jordan)', code: 'ar-JO' },
    { name: 'Arabic (Kuwait)', code: 'ar-KW' },
    { name: 'Arabic (Lebanon)', code: 'ar-LB' },
    { name: 'Arabic (Libya)', code: 'ar-LY' },
    { name: 'Arabic (Mauritania)', code: 'ar-MR' },
    { name: 'Arabic (Morocco)', code: 'ar-MA' },
    { name: 'Arabic (Oman)', code: 'ar-OM' },
    { name: 'Arabic (Other)', code: 'ar-AA' },
    { name: 'Arabic (Palestine)', code: 'ar-PS' },
    { name: 'Arabic (Qatar)', code: 'ar-QA' },
    { name: 'Arabic (Saudi Arabia)', code: 'ar-SA' },
    { name: 'Arabic (Syria)', code: 'ar-SY' },
    { name: 'Arabic (Tunisia)', code: 'ar-TN' },
    { name: 'Arabic (U.A.E.)', code: 'ar-AE' },
    { name: 'Arabic (Yemen)', code: 'ar-YE' },
    { name: 'Armenian', code: 'hy' },
    { name: 'Armenian (Armenia)', code: 'hy-AM' },
    { name: 'Armenian (East)', code: 'hy-east', ietf: 'hye-ear' },
    { name: 'Armenian (West)', code: 'hy-west', ietf: 'hyw' },
    { name: 'Assamese', code: 'as' },
    { name: 'Assamese (India)', code: 'as-IN' },
    { name: 'Assyrian Neo-Aramaic', code: 'aii' },
    { name: 'Azeri', code: 'az' },
    { name: 'Azeri (Cyrillic)', code: 'az-Cyrl' },
    { name: 'Azeri (Cyrillic, Azerbaijan)', code: 'az-Cyrl-AZ' },
    { name: 'Azeri (Latin)', code: 'az-Latn' },
    { name: 'Azeri (Latin, Azerbaijan)', code: 'az-Latn-AZ' },
    { name: 'Badini', code: 'xbd' },
    { name: 'Bashkir', code: 'ba' },
    { name: 'Bashkir (Russia)', code: 'ba-RU' },
    { name: 'Basque', code: 'eu' },
    { name: 'Basque (Basque)', code: 'eu-ES' },
    { name: 'Belarusian', code: 'be' },
    { name: 'Belarusian (Belarus)', code: 'be-BY' },
    { name: 'Bemba', code: 'bem' },
    { name: 'Bengali', code: 'bn' },
    { name: 'Bengali (Bangladesh)', code: 'bn-BD' },
    { name: 'Bengali (India)', code: 'bn-IN' },
    { name: 'Berber', code: 'ber' },
    { name: 'Blin', code: 'byn' },
    { name: 'Bosnian', code: 'bs' },
    { name: 'Bosnian (Cyrillic)', code: 'bs-Cyrl' },
    { name: 'Bosnian (Cyrillic, Bosnia and Herzegovina)', code: 'bs-Cyrl-BA' },
    { name: 'Bosnian (Latin)', code: 'bs-Latn' },
    { name: 'Bosnian (Latin, Bosnia and Herzegovina)', code: 'bs-Latn-BA' },
    { name: 'Breton', code: 'br' },
    { name: 'Breton (France)', code: 'br-FR' },
    { name: 'Bulgarian', code: 'bg' },
    { name: 'Bulgarian (Bulgaria)', code: 'bg-BG' },
    { name: 'Burmese', code: 'my' },
    { name: 'Burmese (Myanmar)', code: 'my-MM' },
    { name: 'Cantonese (Simplified, China)', code: 'yue-CN' },
    { name: 'Cantonese (Simplified, Malaysia)', code: 'yue-MY' },
    { name: 'Cape Verdean Creole', code: 'kea' },
    { name: 'Caribbean Hindustani', code: 'hns' },
    { name: 'Catalan', code: 'ca' },
    { name: 'Catalan (Catalan)', code: 'ca-ES' },
    { name: 'Cebuano (Philippines)', code: 'ceb-PH' },
    { name: 'Cebuano Davao (Philippines)', code: 'ceb-dav-PH' },
    { name: 'Chinese', code: 'zh' },
    { name: 'Chinese (Simplified)', code: 'zh-Hans' },
    { name: 'Chinese (Simplified) Legacy', code: 'zh-CHS' },
    {
        name: 'Chinese (Simplified, Hong Kong S.A.R.)',
        code: 'zh-Cn-HK',
        ietf: 'zh-Hans-HK'
    },
    { name: 'Chinese (Simplified, Malaysia)', code: 'zh-MY', ietf: 'zh-Hans-MY' },
    { name: 'Chinese (Simplified, PRC)', code: 'zh-CN', ietf: 'zh-Hans-CN' },
    {
        name: 'Chinese (Simplified, Singapore)',
        code: 'zh-SG',
        ietf: 'zh-Hans-SG'
    },
    { name: 'Chinese (Traditional)', code: 'zh-Hant' },
    { name: 'Chinese (Traditional) Legacy', code: 'zh-CHT' },
    {
        name: 'Chinese (Traditional, Hong Kong S.A.R.)',
        code: 'zh-HK',
        ietf: 'zh-Hant-HK'
    },
    {
        name: 'Chinese (Traditional, Macao S.A.R.)',
        code: 'zh-MO',
        ietf: 'zh-Hant-MO'
    },
    { name: 'Chinese (Traditional, Taiwan)', code: 'zh-TW', ietf: 'zh-Hant-TW' },
    {
        name: 'Chinese (Australia, Simplified)',
        code: 'zh-Cn-AU',
        ietf: 'zh-Hans-AU'
    },
    { name: 'Chuvash', code: 'cv' },
    { name: 'Corsican', code: 'co' },
    { name: 'Corsican (France)', code: 'co-FR' },
    { name: 'Creole (English)', code: 'cpe' },
    { name: 'Creole (French)', code: 'cpf' },
    { name: 'Creole (Portuguese)', code: 'cpp' },
    { name: 'Croatian', code: 'hr' },
    { name: 'Croatian (Croatia)', code: 'hr-HR' },
    { name: 'Croatian (Latin, Bosnia and Herzegovina)', code: 'hr-BA' },
    { name: 'Czech', code: 'cs' },
    { name: 'Czech (Czech Republic)', code: 'cs-CZ' },
    { name: 'Danish', code: 'da' },
    { name: 'Danish (Denmark)', code: 'da-DK' },
    { name: 'Danish (Greenland)', code: 'da-GL' },
    { name: 'Dari', code: 'prs' },
    { name: 'Dari (Afghanistan)', code: 'prs-AF' },
    { name: 'Divehi', code: 'dv' },
    { name: 'Divehi (Maldives)', code: 'dv-MV' },
    { name: 'Dutch', code: 'nl' },
    { name: 'Dutch (Belgium)', code: 'nl-BE' },
    { name: 'Dutch (Netherlands)', code: 'nl-NL' },
    { name: 'English', code: 'en' },
    { name: 'English (Argentina)', code: 'en-AR' },
    { name: 'English (Australia)', code: 'en-AU' },
    { name: 'English (Belize)', code: 'en-BZ' },
    { name: 'English (Cameroon)', code: 'en-CM' },
    { name: 'English (Canada)', code: 'en-CA' },
    { name: 'English (Caribbean)', code: 'en-029' },
    { name: 'English (Cyprus)', code: 'en-CY' },
    { name: 'English (Gambia)', code: 'en-GM' },
    { name: 'English (Ghana)', code: 'en-GH' },
    { name: 'English (Hong Kong)', code: 'en-HK' },
    { name: 'English (India)', code: 'en-IN' },
    { name: 'English (Indonesia)', code: 'en-ID' },
    { name: 'English (Ireland)', code: 'en-IE' },
    { name: 'English (Israel)', code: 'en-IL' },
    { name: 'English (Jamaica)', code: 'en-JM' },
    { name: 'English (Japan)', code: 'en-JP' },
    { name: 'English (Liberia)', code: 'en-LR' },
    { name: 'English (Malaysia)', code: 'en-MY' },
    { name: 'English (Malta)', code: 'en-MT' },
    { name: 'English (Middle East and Africa)', code: 'en-MEA' },
    { name: 'English (New Zealand)', code: 'en-NZ' },
    { name: 'English (Nigeria)', code: 'en-NG' },
    { name: 'English (Oman)', code: 'en-OM' },
    { name: 'English (PRC)', code: 'en-CN' },
    { name: 'English (Republic of the Philippines)', code: 'en-PH' },
    { name: 'English (Singapore)', code: 'en-SG' },
    { name: 'English (South Africa)', code: 'en-ZA' },
    { name: 'English (Switzerland)', code: 'en-CH' },
    { name: 'English (Thailand)', code: 'en-TH' },
    { name: 'English (Togo)', code: 'en-TG' },
    { name: 'English (Trinidad and Tobago)', code: 'en-TT' },
    { name: 'English (United Kingdom)', code: 'en-GB' },
    { name: 'English (United States)', code: 'en-US' },
    { name: 'English (Zimbabwe)', code: 'en-ZW' },
    { name: 'English (Rwanda)', code: 'en-RW' },
    { name: 'Esperanto', code: 'eo' },
    { name: 'Estonian', code: 'et' },
    { name: 'Estonian (Estonia)', code: 'et-EE' },
    { name: 'Ewe', code: 'ee' },
    { name: 'Faroese', code: 'fo' },
    { name: 'Faroese (Faroe Islands)', code: 'fo-FO' },
    { name: 'Fijian', code: 'fj' },
    { name: 'Filipino', code: 'fil' },
    { name: 'Filipino (Philippines)', code: 'fil-PH' },
    { name: 'Finnish', code: 'fi' },
    { name: 'Finnish (Finland)', code: 'fi-FI' },
    { name: 'French', code: 'fr' },
    { name: 'French (Algeria)', code: 'fr-DZ' },
    { name: 'French (Belgium)', code: 'fr-BE' },
    { name: 'French (Benin)', code: 'fr-BJ' },
    { name: 'French (Burkina Faso)', code: 'fr-BF' },
    { name: 'French (Cameroon)', code: 'fr-CM' },
    { name: 'French (Canada)', code: 'fr-CA' },
    { name: 'French (Central African Republic)', code: 'fr-CF' },
    { name: 'French (Chad)', code: 'fr-TD' },
    { name: 'French (Congo)', code: 'fr-CG' },
    { name: "French (Cote d'Ivoire)", code: 'fr-CI' },
    { name: 'French (Equatorial Guinea)', code: 'fr-GQ' },
    { name: 'French (France)', code: 'fr-FR' },
    { name: 'French (Gabon)', code: 'fr-GA' },
    { name: 'French (Guinea)', code: 'fr-GN' },
    { name: 'French (Guinea-Bissau)', code: 'fr-GW' },
    { name: 'French (Luxembourg)', code: 'fr-LU' },
    { name: 'French (Mali)', code: 'fr-ML' },
    { name: 'French (Mauritania)', code: 'fr-MR' },
    { name: 'French (Middle East and Africa)', code: 'fr-MEA' },
    { name: 'French (Monaco)', code: 'fr-MC' },
    { name: 'French (Morocco)', code: 'fr-MA' },
    { name: 'French (Niger)', code: 'fr-NE' },
    { name: 'French (Senegal)', code: 'fr-SN' },
    { name: 'French (Sierra Leone)', code: 'fr-SL' },
    { name: 'French (Switzerland)', code: 'fr-CH' },
    { name: 'French (Tunisia)', code: 'fr-TN' },
    { name: 'French (Rwanda)', code: 'fr-RW' },
    { name: 'Frisian', code: 'fy' },
    { name: 'Frisian (Netherlands)', code: 'fy-NL' },
    { name: 'Galician', code: 'gl' },
    { name: 'Galician (Galician)', code: 'gl-ES' },
    { name: 'Georgian', code: 'ka' },
    { name: 'Georgian (Georgia)', code: 'ka-GE' },
    { name: 'German', code: 'de' },
    { name: 'German (Austria)', code: 'de-AT' },
    { name: 'German (Belgium)', code: 'de-BE' },
    { name: 'German (Germany)', code: 'de-DE' },
    { name: 'German (Italy)', code: 'de-IT' },
    { name: 'German (Liechtenstein)', code: 'de-LI' },
    { name: 'German (Luxembourg)', code: 'de-LU' },
    { name: 'German (Switzerland)', code: 'de-CH' },
    { name: 'German (Pennsylvania)', code: 'pdc' },
    { name: 'Greek', code: 'el' },
    { name: 'Greek (Cyprus)', code: 'el-CY' },
    { name: 'Greek (Greece)', code: 'el-GR' },
    { name: 'Greenlandic', code: 'kl' },
    { name: 'Greenlandic (Greenland)', code: 'kl-GL' },
    { name: 'Gujarati', code: 'gu' },
    { name: 'Gujarati (India)', code: 'gu-IN' },
    { name: 'Gujarati (United Kingdom)', code: 'gu-GB' },
    { name: 'Haitian (Creole)', code: 'ht' },
    { name: 'Hausa', code: 'ha' },
    { name: 'Hausa (Arabic)', code: 'ha-Arab' },
    { name: 'Hausa (Latin)', code: 'ha-Latn' },
    { name: 'Hausa (Latin, Nigeria)', code: 'ha-Latn-NG' },
    { name: 'Hawaiian (United States)', code: 'haw-US' },
    { name: 'Hebrew', code: 'he' },
    { name: 'Hebrew (Israel)', code: 'he-IL' },
    { name: 'Hiligaynon (Philippines)', code: 'hil-PH' },
    { name: 'Hindi', code: 'hi' },
    { name: 'Hindi (Canada)', code: 'hi-CA' },
    { name: 'Hindi (India)', code: 'hi-IN' },
    // { name: 'Hmong', code: 'blu' },
    { name: 'Hungarian', code: 'hu' },
    { name: 'Hungarian (Hungary)', code: 'hu-HU' },
    { name: 'Hungarian (Slovakia)', code: 'hu-SK' },
    { name: 'Icelandic', code: 'is' },
    { name: 'Icelandic (Iceland)', code: 'is-IS' },
    { name: 'Igbo', code: 'ig' },
    { name: 'Igbo (Nigeria)', code: 'ig-NG' },
    { name: 'Ilokano (Philippines)', code: 'ilo-PH' },
    { name: 'Indonesian', code: 'id' },
    { name: 'Indonesian (Indonesia)', code: 'id-ID' },
    { name: 'Inuktitut', code: 'iu' },
    { name: 'Inuktitut (Latin)', code: 'iu-Latn' },
    { name: 'Inuktitut (Latin, Canada)', code: 'iu-Latn-CA' },
    { name: 'Inuktitut (Syllabics)', code: 'iu-Cans' },
    { name: 'Inuktitut (Syllabics, Canada)', code: 'iu-Cans-CA' },
    { name: 'Irish', code: 'ga' },
    { name: 'Irish (Ireland)', code: 'ga-IE' },
    { name: 'isiXhosa', code: 'xh' },
    { name: 'isiXhosa (South Africa)', code: 'xh-ZA' },
    { name: 'Italian', code: 'it' },
    { name: 'Italian (Italy)', code: 'it-IT' },
    { name: 'Italian (Malta)', code: 'it-MT' },
    { name: 'Italian (Switzerland)', code: 'it-CH' },
    { name: 'Japanese', code: 'ja' },
    { name: 'Japanese (Japan)', code: 'ja-JP' },
    { name: 'Japanese (Latin)', code: 'ja-latn' },
    { name: 'Javanese', code: 'jv' },
    { name: 'Kannada', code: 'kn' },
    { name: 'Kannada (India)', code: 'kn-IN' },
    { name: 'Karen', code: 'kar' },
    { name: 'Kashmiri', code: 'ks' },
    { name: 'Kazakh', code: 'kk' },
    { name: 'Kazakh (Kazakhstan)', code: 'kk-KZ' },
    { name: 'Khmer', code: 'km' },
    { name: 'Khmer (Cambodia)', code: 'km-KH' },
    { name: "K'iche", code: 'qut' },
    { name: "K'iche (Guatemala)", code: 'qut-GT' },
    { name: 'Kinyarwanda', code: 'rw' },
    { name: 'Kinyarwanda (Rwanda)', code: 'rw-RW' },
    { name: 'Kirundi', code: 'rn' },
    { name: 'Konkani', code: 'kok' },
    { name: 'Konkani (India)', code: 'kok-IN' },
    { name: 'Korean', code: 'ko' },
    { name: 'Korean (Korea)', code: 'ko-KR' },
    { name: 'Krio', code: 'kri' },
    { name: 'Kurdish', code: 'ku' },
    { name: 'Kurmanji', code: 'kmr' },
    { name: 'Kyrgyz', code: 'ky' },
    { name: 'Kyrgyz (Kyrgyzstan)', code: 'ky-KG' },
    { name: 'Lao', code: 'lo' },
    { name: 'Lao (Lao P.D.R.)', code: 'lo-LA' },
    { name: 'Latin', code: 'la' },
    { name: 'Latvian', code: 'lv' },
    { name: 'Latvian (Latvia)', code: 'lv-LV' },
    { name: 'Lingala', code: 'ln' },
    { name: 'Lithuanian', code: 'lt' },
    { name: 'Lithuanian (Lithuania)', code: 'lt-LT' },
    { name: 'Lower Sorbian', code: 'dsb' },
    { name: 'Lower Sorbian (Germany)', code: 'dsb-DE' },
    { name: 'lu Mien', code: 'ium' },
    { name: 'Luganda', code: 'lug' },
    { name: 'Luganda (Uganda)', code: 'lug-UG' },
    { name: 'Luo (Kenya)', code: 'luo-KE' },
    { name: 'Luxembourgish', code: 'lb' },
    { name: 'Luxembourgish (Luxembourg)', code: 'lb-LU' },
    { name: 'Macedonian (Former Yugoslav Republic of Macedonia)', code: 'mk-MK' },
    { name: 'Macedonian (FYROM)', code: 'mk' },
    { name: 'Malagasy', code: 'mg' },
    { name: 'Malay', code: 'ms' },
    { name: 'Malay (Brunei Darussalam)', code: 'ms-BN' },
    { name: 'Malay (Malaysia)', code: 'ms-MY' },
    { name: 'Malay (Singapore)', code: 'ms-SG' },
    { name: 'Malayalam', code: 'ml' },
    { name: 'Malayalam (India)', code: 'ml-IN' },
    { name: 'Maltese', code: 'mt' },
    { name: 'Maltese (Malta)', code: 'mt-MT' },
    { name: 'Mandarin Chinese (Simplified, United States)', code: 'cmn-US' },
    { name: 'Mandarin Chinese (Traditional, Hong Kong S.A.R.)', code: 'cmn-HK' },
    { name: 'Maori', code: 'mi' },
    { name: 'Maori (New Zealand)', code: 'mi-NZ' },
    { name: 'Mapudungun', code: 'arn' },
    { name: 'Mapudungun (Chile)', code: 'arn-CL' },
    { name: 'Marathi', code: 'mr' },
    { name: 'Marathi (India)', code: 'mr-IN' },
    { name: 'Mohawk', code: 'moh' },
    { name: 'Mohawk (Mohawk)', code: 'moh-CA' },
    { name: 'Mongolian', code: 'mn' },
    { name: 'Mongolian (Cyrillic)', code: 'mn-Cyrl' },
    { name: 'Mongolian (Cyrillic, Mongolia)', code: 'mn-MN' },
    { name: 'Mongolian (Traditional Mongolian)', code: 'mn-Mong' },
    { name: 'Mongolian (Traditional Mongolian, PRC)', code: 'mn-Mong-CN' },
    { name: 'Montenegrin', code: 'cnr' },
    { name: 'Montenegrin (Montenegro)', code: 'me-ME', ietf: 'cnr-ME' },
    { name: 'Mulenge (Congo)', code: 'kin-MUL' },
    { name: 'Nepali', code: 'ne' },
    { name: 'Nepali (Nepal)', code: 'ne-NP' },
    { name: 'Norwegian', code: 'no' },
    { name: 'Norwegian (Bokmål)', code: 'nb' },
    { name: 'Norwegian (Denmark)', code: 'no-DK' },
    { name: 'Norwegian (Norway)', code: 'no-no' },
    { name: 'Norwegian (Nynorsk)', code: 'nn' },
    { name: 'Norwegian, Bokmål (Norway)', code: 'nb-NO' },
    { name: 'Norwegian, Nynorsk (Norway)', code: 'nn-NO' },
    { name: 'Occitan', code: 'oc' },
    { name: 'Occitan (France)', code: 'oc-FR' },
    { name: 'Oriya', code: 'or' },
    { name: 'Oriya (India)', code: 'or-IN' },
    { name: 'Oromo', code: 'om' },
    { name: 'Papiamento', code: 'pap' },
    { name: 'Papiamentu', code: 'pap-pu', ietf: 'pap-pau' },
    { name: 'Pashto', code: 'ps' },
    { name: 'Pashto (Afghanistan)', code: 'ps-AF' },
    { name: 'Persian', code: 'fa' },
    { name: 'Persian', code: 'fa-IR' },
    { name: 'Persian (Old)', code: 'peo' },
    { name: 'Polish', code: 'pl' },
    { name: 'Polish (Poland)', code: 'pl-PL' },
    { name: 'Portuguese', code: 'pt' },
    { name: 'Portuguese (Angola)', code: 'pt-AO' },
    { name: 'Portuguese (Brazil)', code: 'pt-BR' },
    { name: 'Portuguese (Equatorial Guinea)', code: 'pt-GQ' },
    { name: 'Portuguese (Guinea-Bissau)', code: 'pt-GW' },
    { name: 'Portuguese (Portugal)', code: 'pt-PT' },
    { name: 'Punjabi', code: 'pa' },
    { name: 'Punjabi (Canada)', code: 'pa-CA' },
    { name: 'Punjabi (India)', code: 'pa-IN' },
    { name: 'Quechua', code: 'quz' },
    { name: 'Quechua (Bolivia)', code: 'quz-BO' },
    { name: 'Quechua (Ecuador)', code: 'quz-EC' },
    { name: 'Quechua (Peru)', code: 'quz-PE' },
    { name: 'Rohingya', code: 'rhg' },
    { name: 'Romani', code: 'rom' },
    { name: 'Romanian', code: 'ro' },
    { name: 'Romanian (Moldova)', code: 'ro-MD' },
    { name: 'Romanian (Romania)', code: 'ro-RO' },
    { name: 'Romansh', code: 'rm' },
    { name: 'Romansh (Switzerland)', code: 'rm-CH' },
    { name: 'Russian', code: 'ru' },
    { name: 'Russian (Belarus)', code: 'ru-BY' },
    { name: 'Russian (Estonia)', code: 'ru-EE' },
    { name: 'Russian (Georgia)', code: 'ru-GE' },
    { name: 'Russian (Israel)', code: 'ru-IL' },
    { name: 'Russian (Latvia)', code: 'ru-LV' },
    { name: 'Russian (Lithuania)', code: 'ru-LT' },
    { name: 'Russian (Moldova)', code: 'ru-MD' },
    { name: 'Russian (Russia)', code: 'ru-RU' },
    { name: 'Russian (Ukraine)', code: 'ru-UA' },
    { name: 'Sakha', code: 'sah' },
    { name: 'Sakha (Russia)', code: 'sah-RU' },
    { name: 'Sami (Inari)', code: 'smn' },
    { name: 'Sami (Lule)', code: 'smj' },
    { name: 'Sami (Northern)', code: 'se' },
    { name: 'Sami (Skolt)', code: 'sms' },
    { name: 'Sami (Southern)', code: 'sma' },
    { name: 'Sami, Inari (Finland)', code: 'smn-FI' },
    { name: 'Sami, Lule (Norway)', code: 'smj-NO' },
    { name: 'Sami, Lule (Sweden)', code: 'smj-SE' },
    { name: 'Sami, Northern (Finland)', code: 'se-FI' },
    { name: 'Sami, Northern (Norway)', code: 'se-NO' },
    { name: 'Sami, Northern (Sweden)', code: 'se-SE' },
    { name: 'Sami, Skolt (Finland)', code: 'sms-FI' },
    { name: 'Sami, Southern (Norway)', code: 'sma-NO' },
    { name: 'Sami, Southern (Sweden)', code: 'sma-SE' },
    { name: 'Samoan', code: 'sm' },
    { name: 'Sanskrit', code: 'sa' },
    { name: 'Sanskrit (India)', code: 'sa-IN' },
    { name: 'Scottish Gaelic', code: 'gd' },
    { name: 'Scottish Gaelic (United Kingdom)', code: 'gd-GB' },
    { name: 'Serbian', code: 'sr' },
    { name: 'Serbian (Cyrillic)', code: 'sr-Cyrl' },
    { name: 'Serbian (Cyrillic, Bosnia and Herzegovina)', code: 'sr-Cyrl-BA' },
    { name: 'Serbian (Cyrillic, Montenegro)', code: 'sr-Cyrl-ME' },
    {
        name: 'Serbian (Cyrillic, Serbia and Montenegro (Former))',
        code: 'sr-Cyrl-CS'
    },
    { name: 'Serbian (Cyrillic, Serbia)', code: 'sr-Cyrl-RS' },
    { name: 'Serbian (Kosovo)', code: 'sr-XK' },
    { name: 'Serbian (Latin)', code: 'sr-Latn' },
    { name: 'Serbian (Latin, Bosnia and Herzegovina)', code: 'sr-Latn-BA' },
    { name: 'Serbian (Latin, Montenegro)', code: 'sr-Latn-ME' },
    {
        name: 'Serbian (Latin, Serbia and Montenegro (Former))',
        code: 'sr-Latn-CS'
    },
    { name: 'Serbian (Latin, Serbia)', code: 'sr-Latn-RS' },
    { name: 'Serbo-Croatian', code: 'sh' },
    { name: 'Sesotho', code: 'st' },
    { name: 'Sesotho (South Africa)', code: 'st-ZA' },
    { name: 'Sesotho sa Leboa', code: 'nso' },
    { name: 'Sesotho sa Leboa (South Africa)', code: 'nso-ZA' },
    { name: 'Setswana', code: 'tn' },
    { name: 'Setswana (South Africa)', code: 'tn-ZA' },
    { name: 'Sinhala', code: 'si' },
    { name: 'Sinhala (Sri Lanka)', code: 'si-LK' },
    { name: 'Slovak', code: 'sk' },
    { name: 'Slovak (Slovakia)', code: 'sk-SK' },
    { name: 'Slovenian', code: 'sl' },
    { name: 'Slovenian (Slovenia)', code: 'sl-SI' },
    { name: 'Somali', code: 'so' },
    { name: 'Sorani', code: 'ckb' },
    { name: 'Spanish', code: 'es' },
    { name: 'Spanish (Argentina)', code: 'es-AR' },
    { name: 'Spanish (Bolivarian Republic of Venezuela)', code: 'es-VE' },
    { name: 'Spanish (Bolivia)', code: 'es-BO' },
    { name: 'Spanish (Chile)', code: 'es-CL' },
    { name: 'Spanish (Colombia)', code: 'es-CO' },
    { name: 'Spanish (Costa Rica)', code: 'es-CR' },
    { name: 'Spanish (Cuba)', code: 'es-CU' },
    { name: 'Spanish (Dominican Republic)', code: 'es-DO' },
    { name: 'Spanish (Ecuador)', code: 'es-EC' },
    { name: 'Spanish (El Salvador)', code: 'es-SV' },
    { name: 'Spanish (Equatorial Guinea)', code: 'es-GQ' },
    { name: 'Spanish (Guatemala)', code: 'es-GT' },
    { name: 'Spanish (Honduras)', code: 'es-HN' },
    { name: 'Spanish (Latin America)', code: 'es-419' },
    { name: 'Spanish (Mexico)', code: 'es-MX' },
    { name: 'Spanish (Nicaragua)', code: 'es-NI' },
    { name: 'Spanish (Panama)', code: 'es-PA' },
    { name: 'Spanish (Paraguay)', code: 'es-PY' },
    { name: 'Spanish (Peru)', code: 'es-PE' },
    { name: 'Spanish (Puerto Rico)', code: 'es-PR' },
    { name: 'Spanish (Spain)', code: 'es-ES' },
    { name: 'Spanish (United States)', code: 'es-US' },
    { name: 'Spanish (Uruguay)', code: 'es-UY' },
    { name: 'Sranan', code: 'srn' },
    { name: 'Swahili', code: 'sw' },
    { name: 'Swahili (Kenya)', code: 'sw-KE' },
    { name: 'Swedish', code: 'sv' },
    { name: 'Swedish (Aland)', code: 'sv-AX' },
    { name: 'Swedish (Denmark)', code: 'sv-DK' },
    { name: 'Swedish (Finland)', code: 'sv-FI' },
    { name: 'Swedish (Sweden)', code: 'sv-SE' },
    { name: 'Syriac', code: 'syr' },
    { name: 'Syriac (Syria)', code: 'syr-SY' },
    { name: 'Tagalog', code: 'tl' },
    { name: 'Tagalog (Philippines)', code: 'tl-PH' },
    { name: 'Taiwanese (Taiwan)', code: 'nan-TW' },
    { name: 'Tajik', code: 'tg' },
    { name: 'Tajik (Cyrillic)', code: 'tg-Cyrl' },
    { name: 'Tajik (Cyrillic, Tajikistan)', code: 'tg-Cyrl-TJ' },
    { name: 'Tajik (Latin)', code: 'tg-latn' },
    { name: 'Tamazight', code: 'tzm' },
    { name: 'Tamazight (Latin)', code: 'tzm-Latn' },
    { name: 'Tamazight (Latin, Algeria)', code: 'tzm-Latn-DZ' },
    { name: 'Tamil', code: 'ta' },
    { name: 'Tamil (India)', code: 'ta-IN' },
    { name: 'Tamil (Malaysia)', code: 'ta-MY' },
    { name: 'Tamil (Singapore)', code: 'ta-SG' },
    { name: 'Tamil (Sri Lanka)', code: 'ta-LK' },
    { name: 'Tatar', code: 'tt' },
    { name: 'Tatar (Russia)', code: 'tt-RU' },
    { name: 'Telugu', code: 'te' },
    { name: 'Telugu (India)', code: 'te-IN' },
    { name: 'Thai', code: 'th' },
    { name: 'Thai (Thailand)', code: 'th-TH' },
    { name: 'Tibetan', code: 'bo' },
    { name: 'Tibetan (PRC)', code: 'bo-CN' },
    { name: 'Tigrinya', code: 'ti' },
    { name: 'Tongan', code: 'to' },
    { name: 'Turkish', code: 'tr' },
    { name: 'Turkish (Cyprus)', code: 'tr-CY' },
    { name: 'Turkish (Germany)', code: 'tr-DE' },
    { name: 'Turkish (Turkey)', code: 'tr-TR' },
    { name: 'Turkmen', code: 'tk' },
    { name: 'Turkmen (Turkmenistan)', code: 'tk-TM' },
    { name: 'Twi', code: 'twi' },
    { name: 'Ukrainian', code: 'uk' },
    { name: 'Ukrainian (Ukraine)', code: 'uk-UA' },
    { name: 'Upper Sorbian', code: 'hsb' },
    { name: 'Upper Sorbian (Germany)', code: 'hsb-DE' },
    { name: 'Urdu', code: 'ur' },
    { name: 'Urdu (India)', code: 'ur-IN' },
    { name: 'Urdu (Islamic Republic of Pakistan)', code: 'ur-PK' },
    { name: 'Uyghur', code: 'ug' },
    { name: 'Uyghur (PRC)', code: 'ug-CN' },
    { name: 'Uzbek', code: 'uz' },
    { name: 'Uzbek (Cyrillic)', code: 'uz-Cyrl' },
    { name: 'Uzbek (Cyrillic, Uzbekistan)', code: 'uz-Cyrl-UZ' },
    { name: 'Uzbek (Latin)', code: 'uz-Latn' },
    { name: 'Uzbek (Latin, Uzbekistan)', code: 'uz-Latn-UZ' },
    { name: 'Valencian', code: 'ca-val' },
    { name: 'Vietnamese', code: 'vi' },
    { name: 'Vietnamese (Canada)', code: 'vi-CA' },
    { name: 'Vietnamese (Vietnam)', code: 'vi-VN' },
    { name: 'Welsh', code: 'cy' },
    { name: 'Welsh (United Kingdom)', code: 'cy-GB' },
    { name: 'Wolof', code: 'wo' },
    { name: 'Wolof (Senegal)', code: 'wo-SN' },
    { name: 'Yi', code: 'ii' },
    { name: 'Yi (PRC)', code: 'ii-CN' },
    { name: 'Yiddish', code: 'yi' },
    { name: 'Yoruba', code: 'yo' },
    { name: 'Yoruba (Nigeria)', code: 'yo-NG' },
    { name: 'Zulu', code: 'zu' },
    { name: 'Zulu (South Africa)', code: 'zu-ZA' }
];
/* const dtos = WordbeeStaticList.map((e) => {
  const spec = parseExactSpecificationCode(e.code);

  const specInfo = buildSpecInfo(spec);
  return {
    languageCode: specInfo.languageCode,
    displayCode: specInfo.displayCode ?? null,
    alternativeCodes: specInfo.possibleCodes.filter(
      (c) => c !== specInfo.languageCode
    ),
    name: e.name,
    wordbeeCode: e.code,
    prevalent: false
  };
});
const lC: Record<string, LanguageDTO> = {};
const wC: Record<string, LanguageDTO> = {};
const dC: Record<string, LanguageDTO> = {};

dtos.forEach(d => {
  if (lC[d.languageCode]) {
    console.log("duplicate lc", d);
  }
  lC[d.languageCode] = d;
  if (d.displayCode) {
    if (dC[d.displayCode]) {
      console.log("dup dc", d);
    }
    dC[d.displayCode] = d;
  }
  if (wC[d.wordbeeCode]) {
    console.log()
  }
}) */
/*
console.log(
  new Set(
    WordbeeStaticList.flatMap((entry) => {
      const entryParts = entry.code.split('-').map((c) => c.trim());
      const langCode = entryParts[0];

      if (Object.values(LanguageMaps).every((d) => d[langCode] === undefined)) {
        console.log(entry);
      }
      let langLessEntryParts = entryParts.slice(1);
      if (
        langLessEntryParts.length > 0 &&
        CountryCodeMap[
          langLessEntryParts[langLessEntryParts.length - 1].toUpperCase()
        ] !== undefined
      ) {
        langLessEntryParts = langLessEntryParts.slice(0, -1);
      }
      return langLessEntryParts;
    })
  )
);
*/
