import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import {
  clientAddressDataParser,
  clientDataParser,
  contactDataParser,
  ParserResultType
} from 'model';
import { upperFirst } from 'lodash';
import { CSVParserView } from '../../../components/import/CSVParserView';
import { apiClient } from '../../../api/apiClient';

const importTabs = {
  client: (
    <CSVParserView
      key="client"
      parser={clientDataParser}
      importAction={(pR: ParserResultType<typeof clientDataParser>) => {
        apiClient.post('/client/import', {
          cleanMode: 'references',
          data: pR.result
        });
      }}
    />
  ),
  addresses: (
    <CSVParserView
      key="addresses"
      parser={clientAddressDataParser}
      importAction={(pR: ParserResultType<typeof clientAddressDataParser>) => {
        apiClient.post('/client/import', {
          cleanMode: 'references',
          addresses: pR.result
        });
      }}
    />
  ),
  contacts: (
    <CSVParserView
      key="contacts"
      parser={contactDataParser}
      importAction={(pR: ParserResultType<typeof contactDataParser>) => {
        apiClient.post('/client/import', {
          cleanMode: 'references',
          contacts: pR.result
        });
      }}
    />
  )
};
export function ClientImportScreen() {
  const [tab, setTab] = React.useState<keyof typeof importTabs>('client');
  return (
    <>
      <Tabs value={tab} onChange={(_, value) => setTab(value)}>
        {Object.keys(importTabs).map((k) => (
          <Tab value={k} key={k} label={upperFirst(k)} />
        ))}
      </Tabs>
      <div>{importTabs[tab]}</div>
    </>
  );
}
