import React from 'react';
import { useField } from 'formik';
import { SupplierLanguageDTO } from 'model';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { uniqBy } from 'lodash';
import {
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Switch,
  TextField,
  Tooltip
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import LanguageIcon from '@material-ui/icons/Language';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import TranslateIcon from '@material-ui/icons/Translate';
import {
  Add,
  Hearing as NonNativeIcon,
  RecordVoiceOver as NativeSpeakerIcon
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { IETFIcon } from '../../../../languages/components/IETFIcon';
import {
  determineContextLanguages,
  formatLanguageLabel
} from '../../../../languages/components/LanguageSelect';
import { LanguageDescriptor } from '../../../../languages/hooks/language-index-context.hook';
import { useLanguageIndex } from '../../../../languages/hooks/language-index.hook';
import { useLanguages } from '../../../../languages/hooks/languages.hook';
import { ErrorBoundary } from '../../../../error/ErrorBoundary';
import { LanguageNativeIcon } from '../../../../languages/components/LanguageNativeIcon';

export function SupplierLanguagesForm() {
  const { t } = useTranslation();
  const languages = useLanguages();
  const languageIndex = useLanguageIndex();
  const [, meta, helpers] = useField<SupplierLanguageDTO[]>('languages');
  const [inputValue, setInputValue] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const [showAutoComplete, setShowAutoComplete] = React.useState(false);

  const assignmentMap = Object.fromEntries(
    languages.languages
      .filter((d) => meta.value.find((sl) => sl.languageId === d.id))
      .map((d) => [d.id, d])
  );

  const filterOptions = (
    options: LanguageDescriptor[],
    state: FilterOptionsState<LanguageDescriptor>
  ): LanguageDescriptor[] => {
    const normalizedInput = state.inputValue.trim().toLowerCase();

    if (normalizedInput === '') {
      return options;
    }

    const prefixCodeMatches = Object.keys(languageIndex.codeMap)
      .filter((c) => c.startsWith(normalizedInput))
      .sort((a, b) => a.length - b.length);

    const semanticMatches = determineContextLanguages(
      languageIndex,
      normalizedInput
    );

    const nameMatches = Object.values(languageIndex.descriptors).filter((d) =>
      d.language.name.toLowerCase().includes(normalizedInput)
    );

    return uniqBy(
      [
        ...prefixCodeMatches.map(
          (p) => languageIndex.descriptors[languageIndex.codeMap[p].language.id]
        ),
        ...semanticMatches,
        ...nameMatches
      ],
      (d) => d.language.id
    );
  };

  if (languages.apiState !== 'idle') {
    return <CircularProgress />;
  }

  const onAddLanguage = (v: LanguageDescriptor | null) => {
    if (v) {
      helpers.setValue([
        ...new Set([
          ...meta.value,
          {
            languageId: v.language.id,
            native: false
          }
        ])
      ]);
      setInputValue('');
      setOpen(false);
      setShowAutoComplete(false);
    }
  };

  const onRemoveLanguage = (languageIdx: number) => {
    helpers.setValue(meta.value.filter((_, i) => i !== languageIdx));
  };

  const autocomplete = (
    <Autocomplete
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={null}
      onChange={(c, newValue) => {
        onAddLanguage(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={Object.values(languageIndex.descriptors)}
      autoHighlight
      clearOnEscape
      blurOnSelect
      getOptionLabel={(option) => formatLanguageLabel(option)}
      filterOptions={filterOptions}
      renderOption={(option) => {
        // eslint-disable-next-line no-nested-ternary
        const TypeIcon = option.specification.language ? (
          option.specification.region ? (
            <span>{option.specification.region.flag}</span>
          ) : (
            <LanguageIcon />
          )
        ) : (
          <CheckBoxOutlineBlankIcon />
        );
        return (
          <>
            {TypeIcon}
            <TranslateIcon
              style={{
                visibility: option.specification.script ? 'visible' : 'hidden'
              }}
            />
            {formatLanguageLabel(option)}
          </>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          placeholder={t('Select new language')}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
        />
      )}
    />
  );

  return (
    <ErrorBoundary context="SupplierLanguagesForm">
      <List dense>
        {meta.value.map((sl, index) => {
          const { specification } = languageIndex.descriptors[sl.languageId];
          return (
            <ListItem dense style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{ paddingTop: 2, marginRight: 5, alignSelf: 'center' }}
              >
                <LanguageNativeIcon isNative={Boolean(sl.native)} />
              </span>
              <span style={{ marginRight: 6 }}>
                <IETFIcon tag={specification} />
              </span>
              {assignmentMap[sl.languageId].name}
              {assignmentMap[sl.languageId].languageCode}
              <ListItemSecondaryAction>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  justify="center"
                  spacing={1}
                >
                  <Grid item>
                    <Tooltip title={`${t('Non native')}`}>
                      <NonNativeIcon
                        fontSize="small"
                        color="action"
                        style={{ paddingTop: 2 }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={sl.native}
                      onChange={(_, checked) => {
                        helpers.setValue(
                          meta.value.map((s) => {
                            if (s.languageId === sl.languageId) {
                              return { ...s, native: checked };
                            }
                            return s;
                          })
                        );
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title={`${t('Native speaker')}`}>
                      <NativeSpeakerIcon
                        fontSize="small"
                        color="action"
                        style={{ paddingTop: 2 }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <IconButton
                      title="Remove"
                      size="small"
                      onClick={() => onRemoveLanguage(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem style={{ paddingTop: showAutoComplete ? 0 : 20 }}>
          {showAutoComplete ? (
            autocomplete
          ) : (
            <ListItemSecondaryAction>
              <IconButton
                style={{ textAlign: 'end' }}
                size="small"
                title={`${t('Add language')}`}
                onClick={() => setShowAutoComplete(true)}
              >
                <Add fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>
    </ErrorBoundary>
  );
}
