import React from 'react';
import { Client, ClientPrice, permissions } from 'model';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Edit as EditIcon } from '@material-ui/icons';
import { entityColumnBuilder } from '../../../../components/table/columns.builder';
import { ApiState } from '../../../auth/redux/types';
import {
  GenericTable,
  useCreateCells
} from '../../../../components/table/GenericTable';
import { usePriceUnit } from '../../../prices/hooks/price-unit.hook';
import { useServiceClass } from '../../../services/hooks/service-class.hook';
import { useLanguageIndex } from '../../../languages/hooks/language-index.hook';
import { PriceDisplay } from './PriceDisplay';

function ServiceClassCellRenderer(props: { id: string | null }) {
  const { serviceClass } = useServiceClass(props.id);
  return <Typography variant="inherit">{serviceClass?.name}</Typography>;
}

function PriceUnitCellRenderer(props: { id: string }) {
  const { priceUnit } = usePriceUnit(props.id);
  return <Typography variant="inherit">{priceUnit?.name}</Typography>;
}

function LanguageCellRenderer(props: { id: string | null }) {
  const index = useLanguageIndex();
  const language = props.id ? index.descriptors[props.id] : null;
  return (
    <Typography variant="inherit">{language?.language.name ?? '-'}</Typography>
  );
}

function buildTableColumns(client: Client) {
  return entityColumnBuilder<ClientPrice>()
    .addColumn(
      'client_price_default',
      (p) => `${p.price.default}`,
      'Defaultprice',
      {
        renderer: {
          createElement: (_, sp) => (
            <PriceDisplay
              amount={sp.price.default}
              currencyCode={sp.price.currency}
              defaultCurrencyCode={client.defaultCurrency}
            />
          ),
          createText: (_, sp) => [`${sp.price.default}`]
        }
      }
    )
    .addColumn(
      'client_price_express',
      (p) => `${p.price.express}`,
      'Express price',
      {
        renderer: {
          createElement: (_, sp) => (
            <PriceDisplay
              amount={sp.price.express}
              currencyCode={sp.price.currency}
              defaultCurrencyCode={client.defaultCurrency}
            />
          ),
          createText: (_, sp) => [`${sp.price.express}`]
        }
      }
    )
    .addColumn('client_price_unit', 'priceUnitId', 'Unit', {
      renderer: {
        createElement: (priceUnitId) => (
          <PriceUnitCellRenderer id={priceUnitId} />
        ),
        createText: (priceUnitId: string) => [priceUnitId]
      }
    })
    .addColumn(
      'client_price_service_class',
      'serviceClassId',
      'Service Class',
      {
        renderer: {
          createElement: (serviceClassId) => (
            <ServiceClassCellRenderer id={serviceClassId} />
          ),
          createText: (serviceClassId: string | null) => [serviceClassId ?? '']
        }
      }
    )
    .addColumn(
      'client_price_source_language',
      'sourceLanguageId',
      'Source Language',
      {
        renderer: {
          createElement: (sourceLanguageId) => (
            <LanguageCellRenderer id={sourceLanguageId} />
          ),
          createText: (sourceLanguageId: string | null) => [
            sourceLanguageId ?? ''
          ]
        }
      }
    )
    .addColumn(
      'client_price_target_language',
      'targetLanguageId',
      'Target Language',
      {
        renderer: {
          createElement: (targetLanguageId) => (
            <LanguageCellRenderer id={targetLanguageId} />
          ),
          createText: (targetLanguageId: string | null) => [
            targetLanguageId ?? ''
          ]
        }
      }
    )
    .addDefaultColumn(
      'client_price_custom_costraint',
      'customConstraint',
      'Custom Constraint'
    )
    .addDefaultColumn('client_price_description', 'description', 'Description')
    .columns;
}

interface ClientPricesTableProps {
  client: Client;
  prices: ClientPrice[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function ClientPricesTable({
  prices,
  onRefresh,
  apiState,
  client
}: ClientPricesTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(client), []);
  return (
    <GenericTable
      label={t('Prices')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Price',
        onClick: () => history.push(`${url}?type=create#price`)
      }}
      rowActions={[
        {
          permissions: permissions.clients.client.prices.define,
          entry: (v) => ({
            label: 'Edit',
            icon: EditIcon,
            link: `${url}?type=edit#${v.id}`
          })
        }
      ]}
      data={prices}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      toolbarActions={[]}
    />
  );
}
