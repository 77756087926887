import * as yup from 'yup';
import { Name, NameDTO } from 'model';

export const nameSchema: yup.SchemaOf<NameDTO> = yup.object().shape({
  first: yup.string().required('required'),
  last: yup.string().required('required')
});

export const getInitialNameValues = (): Name => ({
  first: '',
  last: ''
});
