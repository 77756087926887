import {
  ChangeDefaultStatusTypeDTO,
  SupplierStatusType,
  WrapStatusTypeDTO
} from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const supplierStatusTypesThunk = createFeatureThunks<
  SupplierStatusType,
  WrapStatusTypeDTO,
  WrapStatusTypeDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/suppliers/status-type${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'status-types'
});

export const changeStatusTypeDefaultThunk = createErrorHandlingThunk<
  SupplierStatusType[],
  ChangeDefaultStatusTypeDTO
>('status-type/change-default', async (dto) => {
  await apiClient.patch(`/suppliers/status-type`, dto);
  return (await apiClient.get(`/suppliers/status-type`)).data;
});
