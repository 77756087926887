import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from '@material-ui/core';
import { OrderResourceDTO } from 'model';
import React from 'react';
import * as yup from 'yup';
import {
  buildDTOView,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { FormikDatePicker } from '../../../../components/input/date/FormikDatePicker';
import { FileUploadIconButton } from '../../../../components/input/button/FileUploadIconButton';

export const OrderResourceView = buildDTOView<OrderResourceDTO>()
  .all()
  .withSchema(
    yup
      .object({
        name: yup.string().required(),
        estimatedDelivery: yup.date().notRequired().nullable().default(null),
        description: yup.string().required(),
        dependencies: yup
          .array()
          .of(yup.string().uuid().required())
          .notRequired()
          .default(undefined),
        taskType: yup
          .mixed()
          .oneOf([
            'resource',
            'step-task',
            'service-task',
            'service-group-task'
          ]),
        deadline: yup.date().notRequired().nullable().default(null),
        state: yup.mixed().oneOf(['pending', 'in-progress', 'completed']),
        attachedFiles: yup
          .array()
          .of(
            yup
              .object({
                fileAccessId: yup.string().uuid().required() as any,
                token: yup.string().required() as any
              })
              .required()
          )
          .notRequired()
          .default(undefined)
      })
      .defined()
  );

export function OrderResourceForm({
  basePath = OrderResourceView.path
}: {
  basePath?: (typeof OrderResourceView)['path'];
}) {
  const { t } = useTranslation();
  const [, stateMeta, stateHelper] = useTypedField(
    OrderResourceView.path.state
  );
  const [, attachedFilesMeta, attachedFilesHelper] = useTypedField(
    basePath.attachedFiles
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <FormikTextField required name={basePath.name} label={t('Name')} />
      </Grid>
      <Grid item xs={11}>
        <FormikTextField
          name={OrderResourceView.path.description}
          label={t('Description')}
          multiline
        />
      </Grid>
      <Grid item xs={11}>
        {stateMeta.value !== 'completed' && (
          <FormikDatePicker
            name={OrderResourceView.path.estimatedDelivery}
            label={t('Estimated Delivery')}
          />
        )}
      </Grid>
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={stateMeta.value === 'completed'}
              onChange={(_, checked) =>
                stateHelper.setValue(checked ? 'completed' : 'pending')
              }
            />
          }
          label={<Typography variant="body2">{t('Available')}</Typography>}
          labelPlacement="start"
        />

        <FileUploadIconButton
          iconButtonProps={{ size: 'small', style: { marginLeft: 5 } }}
          iconProps={{ fontSize: 'small' }}
          onFileChange={(attachedFiles) => {
            attachedFilesHelper.setValue(
              attachedFiles.map((af) => ({
                fileAccessId: af.id,
                token: af.grant
              }))
            );
          }}
          uploadDisabled={
            !!attachedFilesMeta?.value?.length ||
            stateMeta.value !== 'completed'
          }
        />
      </Grid>
    </Grid>
  );
}
