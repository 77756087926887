"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseExactSpecificationCode = exports.buildSpecInfo = void 0;
const Alphabets_1 = require("./mappings/Alphabets");
const LanguageCodes_1 = require("./mappings/LanguageCodes");
const Regions_1 = require("../shared/constants/Regions");
function buildSpecInfo(spec) {
    var _a;
    const components = [...spec.components];
    const { region, alphabet, language } = spec.context;
    if (region) {
        components[region.position] = region.value.code;
    }
    if (alphabet) {
        components[alphabet.position] = alphabet.value.code;
    }
    const [langComponent, ...restComponents] = components;
    const languages = language
        ? language.value.possibleCodes
        : [langComponent.toLowerCase()];
    return {
        languageCode: [
            language ? language.value.code : langComponent.toLowerCase(),
            ...restComponents
        ].join('-'),
        displayCode: ((_a = language === null || language === void 0 ? void 0 : language.value) === null || _a === void 0 ? void 0 : _a.shortCode)
            ? [language.value.shortCode, ...restComponents].join('-')
            : undefined,
        originalCode: spec.components.join('-'),
        possibleCodes: languages.map((l) => [l, ...restComponents].join('-'))
    };
}
exports.buildSpecInfo = buildSpecInfo;
const specificationRegex = /^[a-zA-Z]+(-[a-zA-Z0-9]+)*$/;
function parseExactSpecificationCode(code) {
    if (!code.trim().match(specificationRegex)) {
        return undefined;
    }
    const spec = {
        components: code.split('-').map((c) => c.trim()),
        context: {}
    };
    const [langComponent, ...otherComponents] = spec.components;
    const langDef = (0, LanguageCodes_1.findLanguageCode)(langComponent.toLowerCase());
    if (langDef !== undefined) {
        spec.context.language = {
            value: langDef,
            position: 0
        };
    }
    if (otherComponents.length > 0) {
        const possibleCountryCode = otherComponents[otherComponents.length - 1].toUpperCase();
        if (Regions_1.RegionCodeMap[possibleCountryCode]) {
            otherComponents.pop();
            spec.context.region = {
                value: Regions_1.RegionCodeMap[possibleCountryCode],
                position: spec.components.length - 1
            };
        }
        const alphabet = otherComponents.findIndex((c) => Object.keys(Alphabets_1.Alphabets).includes(c.toLowerCase()));
        if (alphabet !== -1) {
            spec.context.alphabet = {
                value: Alphabets_1.Alphabets[otherComponents[alphabet].toLowerCase()],
                position: alphabet + 1
            };
        }
    }
    return spec;
}
exports.parseExactSpecificationCode = parseExactSpecificationCode;
