import { ProjectNumber, ProjectOrder } from 'model';
import { TimelineDot } from '@material-ui/lab';
import { Badge, makeStyles, Paper } from '@material-ui/core';
import { FaGlassCheers, FaHandshake } from 'react-icons/fa';
import { ThumbUp, Timer } from '@material-ui/icons';
import React from 'react';
import { formatDate } from '../../../../localized-formats';
import { useOffersOfRequest } from '../../hooks/request-offers.hook';
import { CommentTimelineItem } from './ProjectCommentTimeline';
import { CommentAuthorDisplay } from './CommentAuthorDisplay';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  }
}));
export interface ProjectCommentOrderProps {
  requestId: string;
  order: ProjectOrder;
  projectNumber: ProjectNumber;
}

export function ProjectCommentOrder({
  requestId,
  order,
  projectNumber
}: ProjectCommentOrderProps) {
  const { offers } = useOffersOfRequest(requestId);
  const classes = useStyles();
  const offerPos = offers?.findIndex((o) => o.id === order.offerId);
  // const offer = offers?.find((o) => o.id === order.offerId);
  return (
    <CommentTimelineItem
      timelineSymbol={
        <TimelineDot>
          {projectNumber.firstAcquisition ? (
            <Badge
              style={{ backgroundColor: '#BBBBBB' }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              overlap="rectangle"
              badgeContent={<FaGlassCheers color="black" size="1.5em" />}
            >
              <FaHandshake color="green" size="2em" />
            </Badge>
          ) : (
            <FaHandshake color="green" size="2em" />
          )}
        </TimelineDot>
      }
    >
      <Paper elevation={3} className={classes.paper}>
        <CommentAuthorDisplay
          author={{ type: 'system' }}
          date={order.createdAt}
          actions={[
            {
              icon: <ThumbUp style={{ fontSize: '1em' }} />,
              text: `accepted Quote #${
                offerPos !== undefined ? offerPos + 1 : ''
              }`
            },
            {
              icon: <Timer style={{ fontSize: '1em' }} />,
              text: `Order Date: ${formatDate(order.orderDate, 'date-time')}`
            }
          ]}
        />
      </Paper>
    </CommentTimelineItem>
  );
}
