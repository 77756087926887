import { PermissionTreeNode } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const getPermissionsTreeThunk = createErrorHandlingThunk(
  'permissions/tree',
  async () => {
    const result = await apiClient.get<PermissionTreeNode>('permissions/tree');
    return result.data;
  }
);
