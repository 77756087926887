import { Supplier, SupplierHistory } from 'model';
import { suppliersThunk } from './suppliers.thunk';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { RootState } from '../../../redux/store';
import { deleteSupplierHistoryThunk } from './supplier-history.thunk';

const supplierHistorysSlice = createEntitySlice<SupplierHistory>(
  'supplier-history',
  [
    CustomThunk.override<SupplierHistory>(deleteSupplierHistoryThunk, {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }),
    CustomThunk.override<Supplier, SupplierHistory>(suppliersThunk.get, {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.upsertMany(state, (payload as Supplier).history);
      }
    }) as any
  ]
);

export const supplierHistorysSelectors = {
  ...createAdapterSelectors('supplier-history', supplierHistorysSlice.adapter),
  selectBySupplier(state: RootState, supplierId: string) {
    return this.selectAll(state).filter((a) => a.supplierId === supplierId);
  }
};

export default supplierHistorysSlice;
