import React from 'react';
import {
  Link,
  makeStyles,
  Typography,
  TypographyProps
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { Phone as PhoneIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    phone: {
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    icon: {
      marginRight: 5
    }
  })
);

interface PhoneNumberProps extends TypographyProps {
  number?: string | null;
  showIcon?: boolean;
}

export function PhoneNumberComponent({
  number,
  showIcon = true,
  ...typographyProps
}: PhoneNumberProps) {
  const classes = useStyles();
  return (
    <Typography
      variant={typographyProps.variant || 'body2'}
      component={Link}
      href={`tel:${number}`}
      className={classes.phone}
    >
      {showIcon && number && (
        <PhoneIcon fontSize="inherit" className={classes.icon} />
      )}
      {number || typographyProps.children}
    </Typography>
  );
}
