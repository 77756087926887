import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { candidatesSelectors } from '../redux/project-candidates.slice';
import { listProjectCandidatesThunk } from '../redux/projects.thunk';

export function useProjectCandidates(projectId: string | null | undefined) {
  const currentState = useAppSelector((rs) =>
    candidatesSelectors.selectById(rs, projectId ?? '')
  ) ?? { id: projectId, apiState: 'fresh', dataState: 'none' };
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (
      projectId &&
      (currentState.apiState === 'fresh' ||
        (currentState.dataState === 'none' &&
          currentState.apiState !== 'loading'))
    ) {
      dispatch(listProjectCandidatesThunk({ projectId }));
    }
  }, [currentState]);
  return { projectCandidates: currentState };
}
