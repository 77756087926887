import React, { ChangeEvent } from 'react';
import {
  Checkbox,
  makeStyles,
  TextField,
  TextFieldProps,
  Tooltip
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import { createStyles } from '@material-ui/core/styles';
import { useCardMode } from './context';
import { EditorType } from '../../../hooks/editor-location.hook';
import {
  PropertyPath,
  useTypedField
} from '../../../transformer/DTOViewSchema';

const formatDate = (date: string | Date) =>
  format(new Date(date), 'dd.MM.yyyy');

const useStyles = makeStyles((theme) =>
  createStyles({
    displayLabel: { fontSize: theme.typography.h6.fontSize },
    noHover: {
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomWidth: '1px'
      }
    }
  })
);

export type EditableTextDisplayValue = string | number | null;
type EditableTextDisplayProps = Omit<TextFieldProps, 'name'> & {
  name: string | PropertyPath<any, EditableTextDisplayValue>;
  isDate?: boolean;
  notEditable?: boolean;
  nullifyingCheckBox?: {
    checkedToolTip: string;
    uncheckedToolTip: string;
    when?: EditorType[];
  };
};

export function EditableTextDisplay(props: EditableTextDisplayProps) {
  const classes = useStyles();

  const [, meta, helpers] = useTypedField<EditableTextDisplayValue>(props.name);

  const type = useCardMode();
  const isEditable = type === 'edit' || type === 'create';

  const showCheckbox =
    type !== undefined &&
    (props.nullifyingCheckBox?.when?.includes(type) ||
      (props.nullifyingCheckBox && !props.nullifyingCheckBox.when));

  const checkBox =
    showCheckbox && props.nullifyingCheckBox ? (
      <Tooltip
        title={
          <>
            {meta.value === null
              ? props.nullifyingCheckBox?.uncheckedToolTip
              : props.nullifyingCheckBox?.checkedToolTip}
          </>
        }
      >
        <Checkbox
          style={{ padding: 0 }}
          size="small"
          disabled={!isEditable}
          checked={meta.value !== null}
          onChange={(_, checked) => {
            helpers.setValue(checked ? '' : null);
          }}
        />
      </Tooltip>
    ) : null;

  const textFieldProps =
    isEditable && !props.notEditable
      ? {
          onChange: (
            e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            if (props.type === 'number') {
              helpers.setValue(parseFloat(e.target.value));
            } else {
              helpers.setValue(e.target.value);
            }
          },
          disabled: showCheckbox && meta.value === null,
          error: meta.touched && Boolean(meta.error),
          helperText: meta.touched && meta.error,
          InputProps: {
            endAdornment: (
              <>
                {checkBox}
                {props.InputProps?.endAdornment}
                {type === 'edit' && (
                  <EditIcon
                    style={{
                      marginLeft: isEditable && checkBox ? 10 : undefined
                    }}
                    color="disabled"
                  />
                )}
              </>
            )
          }
        }
      : {
          className: classes.noHover,
          focused: false,
          InputProps: {
            endAdornment: checkBox
          },
          inputProps: {
            style: { caretColor: 'transparent', cursor: 'default' }
          },
          InputLabelProps: { className: classes.displayLabel },
          autoComplete: 'off'
        };

  const formatedValue =
    props.isDate && meta.value ? formatDate(meta.value as string) : meta.value;
  const { name, ...restProps } = props;
  return type === 'create' && props.isDate ? (
    <></>
  ) : (
    <TextField
      fullWidth
      {...restProps}
      value={formatedValue || ''}
      {...textFieldProps}
    />
  );
}
