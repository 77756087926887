import React from 'react';
import { BaseTableRow, DataCell, DataCellMap } from '../TableDataCell';
import { useTableConfigContext } from '../context/DataTableContext';
import { useDisplayedTableColumns } from '../layout/displayed-columns.hook';

export function useRawTableSearchMatcher<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(keys: string[], displayedColumns: Partial<C>) {
  const preparedKeys = React.useMemo(() => {
    return keys.map((key) => key.trim().toLowerCase());
  }, [keys]);
  const preparedRenderers = React.useMemo(() => {
    return Object.values(displayedColumns).map((v: DataCell<T, C>) => {
      return (data: T) =>
        preparedKeys.length === 0 ||
        v.renderer.createText(v.accessor(data), data, v).some((t) => {
          const val = t.toLowerCase();
          return preparedKeys.some((k) => val.includes(k));
        });
    });
  }, [displayedColumns, preparedKeys]);

  return React.useCallback(
    (data: T) => preparedRenderers.some((r) => r(data)),
    [preparedRenderers]
  );
}
export function useTableSearchMatcher<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const {
    tableSearch: { keys }
  } = useTableConfigContext<T, C>();
  const displayedColumns = useDisplayedTableColumns<T, C>();
  return useRawTableSearchMatcher<T, C>(keys, displayedColumns);
}
