import { Box, Checkbox, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { ProjectOfferDTO } from 'model';
import { useTypedField } from '../../../../../transformer/DTOViewSchema';
import { ProjectOfferView } from './ProjectOfferForm';
import { PriceAmountInput } from './PriceAmountInput';
import { calculatePositionTotal } from './OfferPositionsForm';
import {
  MoneyFormat,
  MoneyNumberFormat
} from '../../../../clients/components/prices/FormikPriceInput';

export const PercentageFormat = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  minimumIntegerDigits: 1
});
function TaxPercentageEditor({ nettoTotal }: { nettoTotal: number }) {
  const [, meta, helpers] = useTypedField(ProjectOfferView.path.taxPercentage);
  return (
    <Box
      width="100%"
      display="flex"
      alignContent="center"
      justifyContent="space-between"
    >
      <Typography>Tax</Typography>
      <Box
        display="flex"
        flexGrow={1}
        justifyContent="left"
        alignContent="center"
      >
        <Checkbox
          style={{
            padding: 'unset'
          }}
          checked={meta.value !== null}
          onChange={() => helpers.setValue(meta.value === null ? 0.0 : null)}
        />
        {meta.value !== null && (
          <TextField
            value={meta.value}
            onChange={(e) => helpers.setValue(parseFloat(e.target.value))}
            id="money-input"
            inputProps={{
              style: {
                marginTop: '3px',
                padding: 'unset',
                textAlign: 'right',
                maxWidth: '4.25em',
                fontSize: '1rem'
              }
            }}
            InputLabelProps={{
              style: {
                right: 0
              }
            }}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              inputComponent: MoneyFormat as any,
              endAdornment: <span style={{ marginTop: '3px' }}>%</span>
            }}
          />
        )}
      </Box>
      <Box>
        {MoneyNumberFormat.format(
          meta.value === null ? 0 : nettoTotal * (meta.value / 100)
        )}
      </Box>
    </Box>
  );
}

export function OfferTotalSum() {
  // const [, meta, helpers] = useTypedField(ProjectOfferView.path.totalPrice);
  const [, offerMeta] = useTypedField(ProjectOfferView.path);
  const calculatedSum = calculatePositionTotal(offerMeta.value);
  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="space-between"
      flexDirection="column"
      width="30%"
      border="1px solid"
      borderRadius="1em"
      padding="0.5em"
    >
      <Box
        width="100%"
        display="flex"
        alignContent="center"
        justifyContent="space-between"
      >
        <Typography>Total netto</Typography>
        <PriceAmountInput
          /* eslint-disable-next-line no-underscore-dangle */
          dtoField={ProjectOfferView.path.totalPrice._name}
          calculatedPrice={calculatedSum}
        />
      </Box>
      <TaxPercentageEditor
        nettoTotal={offerMeta.value.totalPrice ?? calculatedSum}
      />
      <Box
        width="100%"
        display="flex"
        alignContent="center"
        justifyContent="space-between"
      >
        <Typography>Total brutto</Typography>
        <span>
          {MoneyNumberFormat.format(
            (offerMeta.value.totalPrice ?? calculatedSum) *
              (1 +
                (offerMeta.value.taxPercentage !== null
                  ? offerMeta.value.taxPercentage / 100
                  : 0))
          )}
        </span>
      </Box>
    </Box>
  );
}

export function OfferTotalSumDisplay({
  total,
  positions,
  currency,
  taxPercentage
}: {
  total: number | null;
  taxPercentage: number | null;
  positions: ProjectOfferDTO['positions'];
  currency: string;
}) {
  const calculatedSum =
    positions
      ?.map((pos) => calculatePositionTotal(pos))
      ?.reduce((acc, c) => acc + c, 0) ?? 0;
  const realSum = total ?? calculatedSum;
  return (
    <Box
      display="flex"
      alignContent="center"
      justifyContent="space-between"
      width="30%"
      border="1px solid"
      borderRadius="1em"
      padding="0.5em"
      flexDirection="column"
    >
      <Box
        width="100%"
        display="flex"
        alignContent="center"
        justifyContent="space-between"
      >
        <span style={{ fontSize: '14px' }}>Total</span>
        <Typography style={{ fontSize: '14px' }}>
          {MoneyNumberFormat.format(realSum)} {currency}
        </Typography>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignContent="center"
        justifyContent="space-between"
      >
        <span style={{ fontSize: '14px' }}>
          Tax {PercentageFormat.format(taxPercentage ?? 0)}%
        </span>
        <Typography style={{ fontSize: '14px' }}>
          {MoneyNumberFormat.format(realSum * ((taxPercentage ?? 0) / 100))}
          {currency}
        </Typography>
      </Box>
      <Box
        width="100%"
        display="flex"
        alignContent="center"
        justifyContent="space-between"
      >
        <span style={{ fontSize: '14px' }}>Total Brutto</span>
        <Typography style={{ fontSize: '14px' }}>
          {MoneyNumberFormat.format(realSum * (1 + (taxPercentage ?? 0) / 100))}
          {currency}
        </Typography>
      </Box>
    </Box>
  );
}
