import React from 'react';
import * as yup from 'yup';
import { Qualification, QualificationDTO } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { DialogForm } from '../../../components/input/form/DialogForm';
import suppliersSlice, { suppliersSelectors } from '../redux/suppliers.slice';
import { NoOp } from '../../../utils';
import { supplierQualificationsThunk } from '../redux/supplier-qualifications.thunk';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { supplierQualificationsSelectors } from '../redux/supplier-qualifications.slice';
import { RootState } from '../../../redux/store';
import { SupplierQualificationsBasicForm } from './SupplierQualifiicationsBasicForm';

const qualificationSchema: yup.SchemaOf<Partial<QualificationDTO>> = yup
  .object()
  .shape({
    name: yup.string().min(3).required(),
    description: yup.string()
  });

const initialValues: QualificationDTO = {
  name: '',
  description: ''
};

export function SupplierQualificationEditFormDialog() {
  const dispatch = useAppDispatch();
  const { type, reference } = useEditorLocation();
  const qualification = useSelector((rs: RootState) =>
    supplierQualificationsSelectors.selectById(rs, reference ?? '')
  );
  if (!reference || type !== 'edit' || !qualification) {
    return <></>;
  }
  return (
    <SupplierQualificationFormDialog
      save={(dto: QualificationDTO) =>
        dispatch(
          supplierQualificationsThunk.update({
            id: qualification.id,
            input: dto
          })
        )
          .then(unwrapResult)
          .catch(NoOp)
      }
      qualification={qualification}
    />
  );
}
export function SupplierQualificationFormDialog({
  save,
  qualification
}: {
  save?: (d: QualificationDTO) => Promise<void | Qualification>;
  qualification?: Qualification;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const createQualification =
    save ??
    ((dto: QualificationDTO) =>
      dispatch(supplierQualificationsThunk.create({ input: dto }))
        .then(unwrapResult)
        .catch(NoOp));

  return (
    <DialogForm
      identifier={qualification?.id ?? 'supplier-qualification'}
      maxWidth="md"
      label="New Qualification"
      form={{
        initialValues: qualification ?? initialValues,
        validationSchema: qualificationSchema
      }}
      api={{
        clearError: suppliersSlice.actions.clearError,
        errorSelector: suppliersSelectors.selectError,
        onSubmit: createQualification,
        stateSelector: suppliersSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <SupplierQualificationsBasicForm />
    </DialogForm>
  );
}
