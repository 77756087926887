import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../redux/redux.hooks';
import { supplierPricesSelectors } from '../redux/supplier-price.slice';

export function usePricesOfSupplier(supplierId: string) {
  const prices = useAppSelector(
    (state) => supplierPricesSelectors.selectBySupplier(state, supplierId),
    shallowEqual
  );
  return { prices };
}
