import {
  createEntityAdapter,
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit';
import { ProjectExpense, ProjectExpensesSummary, Supplier } from 'model';
import { createAdapterSelectors } from '../../../redux/enitySlice';
import { getInitialApiAdapterState } from '../../auth/redux/types';
import {
  aggregateProjectExpensesThunk,
  bookProjectExpensesThunk,
  removeProjectExpensesThunk
} from './project-expenses.thunk';

export interface ProjectExpenseAggregate {
  supplier: Supplier;
  expenses: ProjectExpense[];
}

export type ProjectExpenses = {
  id: string;
  apiState: 'fresh' | 'loading' | 'error' | 'done';
} & (
  | { dataState: 'fetched'; expenses: ProjectExpensesSummary }
  | { dataState: 'none'; expenses?: undefined }
);

export const expensesAdapter = createEntityAdapter<ProjectExpenses>();
export const projectExpensesSlice = createSlice({
  name: 'project-expenses',
  initialState: expensesAdapter.getInitialState(getInitialApiAdapterState()),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isFulfilled(aggregateProjectExpensesThunk),
      (state, { payload, meta }) => {
        return expensesAdapter.upsertOne(state, {
          id: meta.arg.projectId,
          apiState: 'done',
          dataState: 'fetched',
          expenses: payload
        });
      }
    );
    builder.addMatcher(
      isFulfilled(bookProjectExpensesThunk),
      (state, { payload, meta }) => {
        return expensesAdapter.upsertOne(state, {
          id: meta.arg.projectId,
          apiState: 'done',
          dataState: 'fetched',
          expenses: payload
        });
      }
    );
    builder.addMatcher(
      isFulfilled(removeProjectExpensesThunk),
      (state, { payload, meta }) => {
        return expensesAdapter.upsertOne(state, {
          id: meta.arg.projectId,
          apiState: 'done',
          dataState: 'fetched',
          expenses: payload
        });
      }
    );
    builder.addMatcher(
      isPending(
        aggregateProjectExpensesThunk,
        bookProjectExpensesThunk,
        removeProjectExpensesThunk
      ),
      (state, action) => {
        return expensesAdapter.upsertOne(state, {
          ...(state.entities[action.meta.arg.projectId] ?? {
            id: action.meta.arg.projectId,
            dataState: 'none'
          }),
          apiState: 'loading'
        });
      }
    );
    builder.addMatcher(
      isRejected(
        aggregateProjectExpensesThunk,
        bookProjectExpensesThunk,
        removeProjectExpensesThunk
      ),
      (state, action) => {
        return expensesAdapter.upsertOne(state, {
          ...(state.entities[action.meta.arg.projectId] ?? {
            id: action.meta.arg.projectId,
            dataState: 'none'
          }),
          apiState: 'error'
        });
      }
    );
  }
});

export const expensesSelectors = createAdapterSelectors(
  'project-expenses',
  expensesAdapter
);
