import {
  Link,
  makeStyles,
  Typography,
  TypographyProps
} from '@material-ui/core';
import React from 'react';
import { Email as EmailIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  icon: {
    marginRight: 5
  }
}));

interface EmailComponentProps extends TypographyProps {
  email?: string | null;
  showIcon?: boolean;
}

export function EmailComponent({
  email,
  showIcon = true,
  ...typographyProps
}: EmailComponentProps) {
  const classes = useStyles();
  return email ? (
    <Typography
      component={Link}
      variant={typographyProps.variant}
      href={`mailto:${email}`}
      {...typographyProps}
      className={classes.wrapIcon}
    >
      {showIcon && <EmailIcon fontSize="inherit" className={classes.icon} />}
      {email || typographyProps.children}
    </Typography>
  ) : (
    <></>
  );
}
