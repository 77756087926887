import React, { FC } from 'react';
import { Language } from 'model';
import { Grid, IconButton, Popover } from '@material-ui/core';
import { Cancel, Check } from '@material-ui/icons';
import { useLanguageIndex } from '../../../languages/hooks/language-index.hook';
import { LanguageSelect } from '../../../languages/components/LanguageSelect';

export function LanguageSelectPopover({
  button: Button,
  onInput
}: {
  onInput: (selection: Language | null) => void;
  button: FC<{
    onClick: (anchor: HTMLElement) => void;
  }>;
}) {
  const languageIndex = useLanguageIndex();
  const [language, setLanguage] = React.useState<Language | null>(null);
  const [anchor, setAnchor] = React.useState<HTMLElement>();

  return (
    <>
      <Button onClick={setAnchor} />{' '}
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(undefined)}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Grid
          container
          xs={12}
          style={{ padding: 20, minWidth: 300, overflow: 'hidden' }}
        >
          <Grid item xs={10}>
            <LanguageSelect
              value={language?.id ?? null}
              onChange={(id) =>
                setLanguage(id ? languageIndex.descriptors[id].language : null)
              }
            />
          </Grid>
          <Grid item xs={2}>
            {language && (
              <IconButton
                onClick={() => {
                  onInput(language);
                  setAnchor(undefined);
                }}
              >
                <Check />
              </IconButton>
            )}
            <IconButton
              onClick={() => {
                onInput(null);
                setAnchor(undefined);
              }}
            >
              <Cancel />
            </IconButton>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
