"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepSequenceTemplateDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const IsNullable_1 = require("../../transformation/IsNullable");
const SequenceTemplateStepDTO_1 = require("./SequenceTemplateStepDTO");
class StepSequenceTemplateDTO {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.Length)(2),
    __metadata("design:type", String)
], StepSequenceTemplateDTO.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", String)
], StepSequenceTemplateDTO.prototype, "projectVariationId", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsIn)(['no-language', 'only-target', 'source-target'], { each: true }),
    __metadata("design:type", Array)
], StepSequenceTemplateDTO.prototype, "serviceClassTypes", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsUUID)(),
    __metadata("design:type", String)
], StepSequenceTemplateDTO.prototype, "serviceClassId", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => SequenceTemplateStepDTO_1.SequenceTemplateStepDTO),
    __metadata("design:type", Array)
], StepSequenceTemplateDTO.prototype, "sequenceSteps", void 0);
exports.StepSequenceTemplateDTO = StepSequenceTemplateDTO;
