import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { ColumnKey } from '../types';
import { useAreaStorage } from '../../../hooks/page-area.context';
import { TableConfigStorage } from '../context/table.hook';

export interface TableColumnVisibility<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  cellId: ColumnKey<T, C>;
  visible: boolean;
}

export type TableColumnVisibilityMap<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> = Record<keyof C, TableColumnVisibility<T, C>>;

export interface TableColumnVisibilityContextData<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  columnConfig: TableColumnVisibilityMap<T, C>;
  setColumnConfig: (config: TableColumnVisibilityMap<T, C>) => void;
}

export function useColumnVisibilityContext<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(
  tableCells: C,
  initialConfig?: TableColumnVisibilityMap<T, C>
): TableColumnVisibilityContextData<T, C> {
  const [columnConfig, setColumnConfig] = React.useState<
    TableColumnVisibilityMap<T, C>
  >(() => {
    const changedConfig = (initialConfig ?? {}) as Partial<
      TableColumnVisibilityMap<T, C>
    >;
    return Object.fromEntries(
      Object.entries(tableCells).map(([cellId, { hiddenByDefault }]) => [
        cellId,
        changedConfig[cellId] ?? { cellId, visible: !hiddenByDefault }
      ])
    ) as TableColumnVisibilityMap<T, C>;
  });
  const configStorage = useAreaStorage<TableConfigStorage>('table-config');
  return React.useMemo(
    () => ({
      columnConfig,
      setColumnConfig: (config) => {
        setColumnConfig(config);
        configStorage.mergeVal({ columnVisibilityMap: config }, {});
      }
    }),
    [columnConfig, setColumnConfig]
  );
}

export interface TableColumnPosition<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  cellId: keyof C;
  position: number;
}

export type TableColumnPositionMap<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> = Record<keyof C, TableColumnPosition<T, C>>;

export interface TableColumnPositionContextData<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  columnConfig: TableColumnPositionMap<T, C>;
  setColumnConfig: (config: TableColumnPositionMap<T, C>) => void;
}

export function useColumnPositionContext<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(
  tableCells: C,
  initialConfig?: TableColumnPositionMap<T, C>
): TableColumnPositionContextData<T, C> {
  const [columnConfig, setColumnConfig] = React.useState<
    TableColumnPositionMap<T, C>
  >(() => {
    const changedConfig = (initialConfig ?? {}) as Partial<
      TableColumnPositionMap<T, C>
    >;
    return Object.fromEntries(
      Object.entries(tableCells).map(([cellId], index) => [
        cellId,
        changedConfig[cellId] ?? { cellId, position: index }
      ])
    ) as TableColumnPositionMap<T, C>;
  });
  return React.useMemo(
    () => ({
      columnConfig,
      setColumnConfig
    }),
    [columnConfig, setColumnConfig]
  );
}
