import React from 'react';
import { BaseTableRow } from '../TableDataCell';
import { useTableConfigContext } from '../context/DataTableContext';

export function useTableSelectionSelector<T extends BaseTableRow>(id: T['id']) {
  const {
    tableSelection: { selectedIds, setSelectedIds }
  } = useTableConfigContext<T, any>();
  return React.useMemo(() => {
    return {
      selected: selectedIds.includes(id),
      setSelected: (selected: boolean) => {
        if (!selected) {
          setSelectedIds(selectedIds.filter((i) => i !== id));
        } else {
          setSelectedIds([...selectedIds, id]);
        }
      }
    };
  }, [selectedIds, id]);
}
