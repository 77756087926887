import React from 'react';

export class PageAreaPathSpecifier {
  parent?: PageAreaPathSpecifier;

  type: string;

  specifier: string;

  constructor(
    parent: PageAreaPathSpecifier | undefined,
    type: string,
    specifier: string
  ) {
    this.parent = parent;
    this.type = type;
    this.specifier = specifier;
  }

  format() {
    return `/${this.type}:${this.specifier}`;
  }

  formatFullQualified(): string {
    return (this.parent?.formatFullQualified() ?? '') + this.format();
  }

  deriveSpecifier(type: string, specifier: string) {
    return new PageAreaPathSpecifier(this, type, specifier);
  }

  instanceElement(
    parent?: PageAreaPathElement,
    identifier?: string
  ): PageAreaPathElement {
    return {
      parent,
      specifier: this,
      identifier
    };
  }
}

export const RootPageAreaSpecifier = new PageAreaPathSpecifier(
  undefined,
  'root',
  ''
);

export const RootPageArea = RootPageAreaSpecifier.instanceElement();

export interface PageAreaPathElement {
  parent?: PageAreaPathElement;
  specifier: PageAreaPathSpecifier;
  identifier?: string;
}

export function toIdentifiedPath(pathElement: PageAreaPathElement): string {
  return (
    (pathElement.parent ? toIdentifiedPath(pathElement.parent) : '') +
    pathElement.specifier.format() +
    (pathElement.identifier ? `[${pathElement.identifier}]` : '')
  );
}

export function derivePathElement(
  parent: PageAreaPathElement,
  spec: PageAreaPathSpecifier,
  identifier?: string
) {
  return { parent, specifier: spec, identifier };
}

export const PageAreaContext = React.createContext(RootPageArea);

export function usePageArea() {
  return React.useContext(PageAreaContext);
}

export function useDerivedPageArea(
  specifier: PageAreaPathSpecifier,
  identifier?: string
) {
  const parentArea = usePageArea();
  return React.useMemo(
    () => derivePathElement(parentArea, specifier, identifier),
    [parentArea, specifier, identifier]
  );
}

export function useAreaStorage<T>(
  storageKey: string,
  area?: PageAreaPathSpecifier
) {
  const currentPageArea = usePageArea();
  const pageArea = area ?? currentPageArea.specifier;
  const fullKey = `${storageKey}::${pageArea.formatFullQualified()}`;
  return React.useMemo(
    () => ({
      readValue(defaultVal: T) {
        const currentVal = localStorage.getItem(fullKey);
        return currentVal ? (JSON.parse(currentVal) as T) : defaultVal;
      },
      writeVal(val: T) {
        localStorage.setItem(fullKey, JSON.stringify(val));
      },
      mergeVal(mergeVal: Partial<T>, defaultVal: T) {
        this.writeVal({ ...this.readValue(defaultVal), ...mergeVal });
      }
    }),
    [storageKey, pageArea]
  );
}
