import React from 'react';
import { Backdrop, BackdropProps, CircularProgress } from '@material-ui/core';

export function CircularProgressBackdrop(props: BackdropProps) {
  return (
    <Backdrop {...props}>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
}
