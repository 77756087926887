import React from 'react';
import { Chip, List, ListItem } from '@material-ui/core';
import { useCatTools } from '../../../hooks/cat-tools.hook';
import { ErrorBoundary } from '../../../../error/ErrorBoundary';

interface SupplierCatToolsDisplayProps {
  supplierCatTools: string[];
}

export function SupplierCatToolsDisplay({
  supplierCatTools
}: SupplierCatToolsDisplayProps) {
  const catTools = useCatTools();

  return (
    <ErrorBoundary context="SupplierCatToolsDisplay">
      <List dense>
        <ListItem dense style={{ display: 'flex', flexWrap: 'wrap' }}>
          {catTools.catTools
            .filter((c) => supplierCatTools.includes(c.id))
            .map((tool) => (
              <Chip style={{ margin: 1 }} label={tool.name} />
            ))}
        </ListItem>
      </List>
    </ErrorBoundary>
  );
}
