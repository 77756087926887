import { Route, useRouteMatch } from 'react-router-dom';
import { Switch as RouterSwitch } from 'react-router';
import React from 'react';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { ServiceClassScreen } from './screens/ServiceClass.screen';
import { ServiceStepScreen } from './screens/ServiceStepScreen';
import { ServiceClassFormDialog } from './components/ServiceClassFormDialog';
import { getUuidUrlParamMatcher } from '../../components/navigation/utils';
import { ServiceClassDetailsScreen } from './screens/ServiceClassDetails.screen';
import { ServiceStepFormDialog } from './components/SupplierStepFormDialog';
import { PriceUnitScreen } from './screens/PriceUnit.screen';
import { PriceUnitFormDialog } from './components/PriceUnitFormDialog';
import { PriceBoundScreen } from '../prices/screens/PriceBoundScreen';
import { KontextorError } from '../error/KontextorError';
import serviceClassesSlice, {
  serviceClassesSelectors
} from './redux/service-classes.slice';

export function ServicesFeature() {
  const { path } = useRouteMatch();

  return (
    <ErrorBoundary context="servicesFeature">
      <KontextorError
        clear={serviceClassesSlice.actions.clearError}
        selector={serviceClassesSelectors.selectError}
      >
        <ServiceStepFormDialog />
        <ServiceClassFormDialog />
        <PriceUnitFormDialog />
        <RouterSwitch>
          <Route
            path={`${path}/service-class/${getUuidUrlParamMatcher(
              'serviceClassId'
            )}`}
          >
            <ServiceClassDetailsScreen />
          </Route>
          <Route path={`${path}/service-class`}>
            <ServiceClassScreen />
          </Route>
          <Route path={`${path}/service-step`}>
            <ServiceStepScreen />
          </Route>
          <Route path={`${path}/price-unit`}>
            <PriceUnitScreen />
          </Route>
          <Route path={`${path}/price-bound`}>
            <PriceBoundScreen />
          </Route>
        </RouterSwitch>
      </KontextorError>
    </ErrorBoundary>
  );
}
