import React from 'react';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function AboutDialog() {
  const history = useHistory();

  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={12} xl={12}>
        <Dialog open onClose={history.goBack}>
          <DialogTitle>{t('About TEO')}</DialogTitle>
          <DialogContent>
            {' '}
            {t('Version number')} {process.env.REACT_APP_VERSION}
          </DialogContent>
          <DialogContent>
            {t('Developed by')}{' '}
            <a
              href="https://devconsult.berlin/"
              target="_blank"
              rel="noreferrer"
            >
              {t('DevConsult Berlin')}
            </a>{' '}
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
}
