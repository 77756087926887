import React from 'react';
import { Grid } from '@material-ui/core';
import { ProjectTagDTO } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { buildDTOView } from '../../../../transformer/DTOViewSchema';
import { FormikTextField } from '../../../../components/input/FormikTextField';

export const ProjectTagBasicView = buildDTOView<ProjectTagDTO>()
  .all()
  .withSchema(
    yup
      .object({
        name: yup.string().required('required').min(2, 'min length 2'),
        description: yup
          .string()
          .max(255, 'Max. 255 characters')
          .defined('required')
      })
      .defined()
  );

export function ProjectTagBasicForm() {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12}>
          <FormikTextField
            required
            name={ProjectTagBasicView.path.name}
            label={t('Name')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            label={t('Description')}
            name={ProjectTagBasicView.path.description}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
