import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../redux/redux.hooks';
import { requestPositionSelectors } from '../redux/request-positions.slice';

export function usePositionsOfRequest(requestId?: string | null) {
  const positions = useAppSelector(
    (state) => requestPositionSelectors.selectByRequest(state, requestId ?? ''),
    shallowEqual
  );
  return { positions };
}
