"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.patchSupplierPriceTransformer = exports.SupplierPriceDTO = void 0;
const class_validator_1 = require("class-validator");
const IsNullable_1 = require("../../transformation/IsNullable");
const PriceConfigurationDTO_1 = require("../../prices/dto/PriceConfigurationDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const SupplierPrice_1 = require("../entity/SupplierPrice");
class SupplierPriceDTO extends PriceConfigurationDTO_1.PriceConfigurationDTO {
}
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsUUID)(),
    __metadata("design:type", String)
], SupplierPriceDTO.prototype, "serviceStepId", void 0);
exports.SupplierPriceDTO = SupplierPriceDTO;
exports.patchSupplierPriceTransformer = (0, DTOTransformer_1.createDTOTransformer)(SupplierPrice_1.SupplierPrice, SupplierPriceDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'supplierId',
    'supplier',
    'priceUnit',
    'serviceClass',
    'sourceLanguage',
    'targetLanguage',
    'serviceStep'
], {
    price: [
        'price',
        (p) => ({
            price: p.default,
            currency: p.currency,
            expressPrice: p.express
        })
    ]
});
