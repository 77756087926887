import { ProjectRequest, RequestComment } from 'model';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { createRequestCommentThunk } from './request-comment.thunk';
import { projectRequestThunk } from './project-requests.thunk';
import { RootState } from '../../../redux/store';
import { createOfferCommentThunk } from './project-offers.thunk';
import { commentProjectCandidateThunk } from './projects.thunk';

const requestCommentSlice = createEntitySlice<RequestComment>(
  'request-comments',
  [
    CustomThunk.override<ProjectRequest, RequestComment>(
      projectRequestThunk.get,
      {
        fulfilled: (adapter, state, { payload }) => {
          return adapter.upsertMany(
            state,
            (payload as ProjectRequest).comments
          );
        }
      }
    ) as any,
    CustomThunk.override<RequestComment, RequestComment>(
      commentProjectCandidateThunk,
      {
        fulfilled: (adapter, state, { payload }) => {
          return adapter.upsertOne(state, payload as RequestComment);
        }
      }
    ) as any,
    createRequestCommentThunk,
    createOfferCommentThunk
  ]
);

export const requestCommentSelectors = {
  ...createAdapterSelectors('request-comments', requestCommentSlice.adapter),
  selectByRequest(state: RootState, requestId: string) {
    return this.selectAll(state).filter((a) => a.requestId === requestId);
  }
};
export default requestCommentSlice;
