import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';
import { Info } from 'model';
import { apiClient } from '../../api/apiClient';
import { useAppSelector } from '../../redux/redux.hooks';
import { selectUserAsNullable } from '../../features/auth/redux/auth.slice';

const userIdsToAlert = [
  '97e8ca4d-a880-4009-99ba-4bb7fc507352',
  '1400efda-3eb6-4a1d-8167-4a582a447933',
  '9afb50f1-9392-4106-a75b-20b6c747d36f',
  '99b5f534-c04c-4b01-80bb-855e30f94a94'
];

function getSnackbarState(
  userId: string | undefined | null,
  environment?: Info['environment']
) {
  if (!userId) {
    return false;
  }
  if (environment === 'prod') {
    return userIdsToAlert.includes(userId);
  }
  return environment === 'staging';
}

export function InfoAlert() {
  const [info, setInfo] = React.useState<Info | null>(null);
  const user = useAppSelector(selectUserAsNullable);

  React.useEffect(() => {
    apiClient.get<Info>('/info').then((res) => setInfo(res.data));
  }, []);
  return (
    <Snackbar
      style={{ width: '50%' }}
      open={getSnackbarState(user?.id, info?.environment)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        style={{
          width: '100%',
          backgroundColor: info?.environment === 'prod' ? '#ff4621' : undefined
        }}
        severity={info?.environment === 'prod' ? 'error' : 'warning'}
      >
        <AlertTitle>ATTENTION</AlertTitle>
        <strong>
          {info?.environment === 'prod' ? 'PRODUCTION' : 'STAGING'}
        </strong>{' '}
        ENVIRONMENT
      </Alert>
    </Snackbar>
  );
}
