import React from 'react';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { useRequestStatusList } from '../hooks/request-status-list.hook';
import { RequestStatusDialog } from '../components/RequestStatusDialog';
import { RequestStatusTable } from '../components/RequestStatusTable';

export function RequestStatusScreen() {
  const { entities, apiState, listEntities } = useRequestStatusList();
  const { type, reference } = useEditorLocation();

  return (
    <>
      {type === 'edit' && reference !== undefined && (
        <RequestStatusDialog statusId={reference} />
      )}
      <RequestStatusTable
        requestStatusList={entities}
        onRefresh={listEntities}
        apiState={apiState}
      />
    </>
  );
}
