import React from 'react';
import { Badge } from '@material-ui/core';
import { useResourceTranslations } from './translations.hook';

export function TranslationsNavLabel() {
  const { untranslated } = useResourceTranslations();
  return (
    <Badge
      color="primary"
      badgeContent={untranslated}
      showZero={false}
      overlap="rectangle"
      max={999}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      Translations
    </Badge>
  );
}
