import {
  NormalTestTypeDTO,
  SimpleTestTypeDTO,
  TestResultStateDTO,
  TestTypeStateDTO
} from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import React from 'react';
import PlusIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  buildDTOView,
  PropertyPath,
  useTypedField
} from '../../../transformer/DTOViewSchema';
import ColorPicker, {
  cssRGBA,
  fromCSSRGBA
} from '../../../components/color/ColorPicker';
import { FormikTextField } from '../../../components/input/FormikTextField';
import { EditableCheckboxDisplay } from '../../../components/input/checkbox/EditableCheckboxDisplay';

export const TestTypeSimpleBasicView = buildDTOView<SimpleTestTypeDTO>()
  .all()
  .withSchema(
    yup
      .object({
        testVariant: yup.mixed().oneOf(['simple', 'normal']),
        name: yup.string().required(),
        description: yup.string().defined().default('')
      })
      .defined()
  );

export const TestTypeNormalBasicView = buildDTOView<NormalTestTypeDTO>()
  .all()
  .withSchema(
    yup
      .object({
        testVariant: yup.mixed().oneOf(['simple', 'normal']),
        name: yup.string().required(),
        description: yup.string().defined().default(''),
        testStates: yup
          .array()
          .required()
          .of(
            yup
              .object({
                id: yup.string().notRequired(),
                name: yup.string().required(),
                color: yup.string().required(),
                resultsAvailable: yup.boolean().required()
              })
              .defined()
          ),
        resultStates: yup
          .array()
          .required()
          .of(
            yup
              .object({
                id: yup.string().notRequired(),
                name: yup.string().required(),
                color: yup.string().required(),
                passed: yup.boolean().required()
              })
              .defined()
          )
      })
      .defined()
  );
function ResultStateEntry({
  path,
  onRemove
}: {
  path: PropertyPath<any, TestResultStateDTO>;
  onRemove: () => void;
}) {
  const { t } = useTranslation();
  const [, meta, helpers] = useTypedField(path);
  return (
    <ListItem>
      <ListItemIcon>
        <ColorPicker
          value={fromCSSRGBA(meta.value.color)}
          onValueChange={(color) =>
            helpers.setValue({ ...meta.value, color: cssRGBA(color) })
          }
        />
      </ListItemIcon>
      <ListItemText>
        <FormikTextField name={path.name} label={t('Name')} />
        <EditableCheckboxDisplay name={path.passed} label={t('Passed')} />
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton size="small" onClick={() => onRemove()}>
          <MinusIcon fontSize="small" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function ResultStateList() {
  const { t } = useTranslation();
  const [, meta, helpers] = useTypedField(
    TestTypeNormalBasicView.path.resultStates
  );
  return (
    <Grid item xs={6}>
      <Box display="flex" justifyContent="space-between">
        <Typography>{t('Result States')}</Typography>
        <IconButton
          size="small"
          onClick={() => {
            helpers.setValue([
              ...meta.value,
              {
                name: '',
                color: 'rgba(255,255,255,1)',
                passed: false
              }
            ]);
          }}
        >
          <PlusIcon fontSize="small" />
        </IconButton>
      </Box>
      <List>
        {meta.value.map((_, index) => {
          return (
            <ResultStateEntry
              path={TestTypeNormalBasicView.path.resultStates.at(index)}
              onRemove={() => {
                helpers.setValue(meta.value.filter((_1, i) => i !== index));
              }}
            />
          );
        })}
      </List>
    </Grid>
  );
}

function TestStateEntry({
  path,
  onRemove
}: {
  path: PropertyPath<any, TestTypeStateDTO>;
  onRemove: () => void;
}) {
  const { t } = useTranslation();
  const [, meta, helpers] = useTypedField(path);
  return (
    <ListItem>
      <ListItemIcon>
        <ColorPicker
          value={fromCSSRGBA(meta.value.color)}
          onValueChange={(color) =>
            helpers.setValue({ ...meta.value, color: cssRGBA(color) })
          }
        />
      </ListItemIcon>
      <ListItemText>
        <FormikTextField name={path.name} label={t('Name')} />
        <EditableCheckboxDisplay
          name={path.resultsAvailable}
          label={t('With Results')}
        />
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton size="small" onClick={() => onRemove()}>
          <MinusIcon fontSize="small" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function TestStateList() {
  const { t } = useTranslation();
  const [, meta, helpers] = useTypedField(
    TestTypeNormalBasicView.path.testStates
  );
  return (
    <Grid item xs={6}>
      <Box display="flex" justifyContent="space-between">
        <Typography>{t('Test States')}</Typography>
        <IconButton
          size="small"
          onClick={() => {
            helpers.setValue([
              ...meta.value,
              {
                name: '',
                color: 'rgba(255,255,255,1)',
                resultsAvailable: false
              }
            ]);
          }}
        >
          <PlusIcon fontSize="small" />
        </IconButton>
      </Box>
      <List>
        {meta.value.map((_, index) => {
          return (
            <TestStateEntry
              path={TestTypeNormalBasicView.path.testStates.at(index)}
              onRemove={() => {
                helpers.setValue(meta.value.filter((_1, i) => i !== index));
              }}
            />
          );
        })}
      </List>
    </Grid>
  );
}

export function TestTypeBasicForm({
  basePath,
  showTypeSelect
}: {
  showTypeSelect: boolean;
  basePath:
    | typeof TestTypeSimpleBasicView.path
    | typeof TestTypeNormalBasicView.path;
}) {
  const { t } = useTranslation();
  const [, variantMeta, variantHelpers] = useTypedField(basePath.testVariant);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={6}>
          <FormikTextField required name={basePath.name} label={t('Name')} />
        </Grid>
        <Grid item xs={6}>
          <FormikTextField
            label={t('Description')}
            name={basePath.description}
            multiline
            rows={3}
          />
        </Grid>
        {showTypeSelect && (
          <Grid item xs={12} justify="flex-start">
            <ToggleButtonGroup
              value={variantMeta.value}
              exclusive
              onChange={(_, value) => variantHelpers.setValue(value)}
            >
              <ToggleButton value="simple">Simple</ToggleButton>
              <ToggleButton value="normal">Normal</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
        {variantMeta.value === 'normal' && (
          <>
            <TestStateList />
            <ResultStateList />
          </>
        )}
      </Grid>
    </Grid>
  );
}
