"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.priceUnitTransformer = exports.PriceUnitPatchDTO = void 0;
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const PriceUnitDTO_1 = require("./PriceUnitDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const PriceUnit_1 = require("../entity/PriceUnit");
class PriceUnitPatchDTO extends (0, partial_type_helper_1.PartialType)(PriceUnitDTO_1.PriceUnitDTO) {
}
exports.PriceUnitPatchDTO = PriceUnitPatchDTO;
exports.priceUnitTransformer = (0, DTOTransformer_1.createDTOTransformer)(PriceUnit_1.PriceUnit, PriceUnitDTO_1.PriceUnitDTO, ['id', 'updatedAt', 'createdAt'], {});
