"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var RequestFeedEntry_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestFeedEntry = void 0;
const typeorm_1 = require("typeorm");
const ProjectRequest_1 = require("./ProjectRequest");
const JournaledEntity_1 = require("../../JournaledEntity");
const User_1 = require("../../user/entity/User");
let RequestFeedEntry = RequestFeedEntry_1 = class RequestFeedEntry extends JournaledEntity_1.JournaledEntity {
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectRequest_1.ProjectRequest),
    __metadata("design:type", ProjectRequest_1.ProjectRequest)
], RequestFeedEntry.prototype, "request", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => User_1.User),
    __metadata("design:type", User_1.User)
], RequestFeedEntry.prototype, "creator", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => RequestFeedEntry_1, (e) => e.subEntries, { nullable: true }),
    __metadata("design:type", RequestFeedEntry)
], RequestFeedEntry.prototype, "parentEntry", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => RequestFeedEntry_1, (e) => e.parentEntry),
    __metadata("design:type", Array)
], RequestFeedEntry.prototype, "subEntries", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], RequestFeedEntry.prototype, "type", void 0);
__decorate([
    (0, typeorm_1.Column)({
        type: 'jsonb'
    }),
    __metadata("design:type", Object)
], RequestFeedEntry.prototype, "content", void 0);
RequestFeedEntry = RequestFeedEntry_1 = __decorate([
    (0, typeorm_1.Entity)()
], RequestFeedEntry);
exports.RequestFeedEntry = RequestFeedEntry;
