import React from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps
} from '@material-ui/core';
import {
  PropertyPath,
  useTypedField
} from '../../../transformer/DTOViewSchema';

interface FormikSwitchProps {
  name: string | PropertyPath<any, boolean>;
  label: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
}

export function FormikSwitch(
  props: FormikSwitchProps & Omit<SwitchProps, 'name'>
) {
  const [, meta, helpers] = useTypedField<boolean>(props.name);
  return (
    <FormControlLabel
      checked={meta.value}
      control={
        <Switch
          disabled={props.disabled}
          onChange={(_, checked) => helpers.setValue(checked)}
          color="primary"
        />
      }
      label={props.label}
      labelPlacement={props.labelPlacement || 'top'}
    />
  );
}
