import { ProjectVariation, ProjectVariationDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const projectVariationsThunk = createFeatureThunks<
  ProjectVariation,
  ProjectVariationDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/services/project-variation${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'project-variations'
});
