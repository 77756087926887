"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectPatchTransformer = exports.ProjectPatchDTO = void 0;
const ProjectDTO_1 = require("./ProjectDTO");
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const Project_1 = require("../entity/Project");
class ProjectPatchDTO extends (0, partial_type_helper_1.PartialType)(ProjectDTO_1.ProjectDTO) {
}
exports.ProjectPatchDTO = ProjectPatchDTO;
exports.projectPatchTransformer = (0, DTOTransformer_1.createDTOTransformer)(Project_1.Project, ProjectPatchDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'order',
    'request',
    'projectNumber',
    'client',
    'projectVariation',
    'projectManager',
    'projectTags',
    'supportingProjectManagers',
    'requestId',
    'outgoingInvoices',
    'projectCandidates',
    'projectExpenses',
    'duplicatedFrom',
    'duplicatedFromId',
    'templateProject'
], {});
