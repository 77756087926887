"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestResourceTransformer = exports.ResourceRequestDTO = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const RequestResource_1 = require("../entity/RequestResource");
const RequestCommentDTO_1 = require("./RequestCommentDTO");
const FileAccessDTO_1 = require("../../files/dto/FileAccessDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
class ResourceRequestDTO {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsIn)(Object.keys(RequestResource_1.ResourceTypes)),
    __metadata("design:type", String)
], ResourceRequestDTO.prototype, "type", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ResourceRequestDTO.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], ResourceRequestDTO.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], ResourceRequestDTO.prototype, "available", void 0);
__decorate([
    (0, class_validator_1.IsDate)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], ResourceRequestDTO.prototype, "estimatedDelivery", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => FileAccessDTO_1.FileAccessDTO),
    __metadata("design:type", Array)
], ResourceRequestDTO.prototype, "attachedFiles", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => RequestCommentDTO_1.RequestCommentDTO),
    __metadata("design:type", RequestCommentDTO_1.RequestCommentDTO)
], ResourceRequestDTO.prototype, "comment", void 0);
exports.ResourceRequestDTO = ResourceRequestDTO;
const requestResourceTransformer = (encoder) => (0, DTOTransformer_1.createDTOTransformer)(RequestResource_1.RequestResource, ResourceRequestDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'requestId',
    'request',
    'comments',
    'commentIds'
], {
    attachedFiles: [
        'attachedFiles',
        (v, k, o) => {
            var _a;
            return ((_a = v === null || v === void 0 ? void 0 : v.map((f) => FileAccessDTO_1.FileAccessDTO.createUnsigned(encoder, f.id, RequestResource_1.requestResourcePermission, o))) !== null && _a !== void 0 ? _a : []);
        }
    ]
});
exports.requestResourceTransformer = requestResourceTransformer;
