import React from 'react';
import {
  includes,
  permissions,
  Qualification,
  ServiceClass,
  ServiceStep,
  Supplier,
  SupplierAttachment,
  SupplierCategory,
  SupplierDomain,
  SupplierLanguage,
  SupplierQualification,
  SupplierService,
  SupplierServiceStep,
  SupplierStatus,
  SupplierStatusType,
  SupplierTestAttachment,
  SupplierTestType
} from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  ContactMail as CopyEmailsIcon,
  FindInPage as FindByServiceIcon
} from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import { entityColumnBuilder } from '../../../../components/table/columns.builder';
import {
  GenericTable,
  useCreateCells
} from '../../../../components/table/GenericTable';
import { ApiState } from '../../../auth/redux/types';
import { LanguageColumnRenderer } from './LanguageColumnRenderer';
import { ServicesColumnRenderer } from './ServicesColumnRenderer';
import { DomainColumnRenderer } from './DomainColumnRenderer';
import { QualificationsColumnRenderer } from './QulificationsColumnRenderer';
import { SupplierStatusColumnRenderer } from './SupplierStatusColumnRenderer';
import {
  SearchConfigChip,
  ServiceSearchState,
  SupplierServiceSearchDialogForm
} from './SupplierServiceSearchDialogForm';
import { SupplierTestColumnRenderer } from './SupplierTestColumnRenderer';
import { MultipleLangColumnFilter } from '../../../../components/table/filter/MultipleLangColumnFilter';
import { LanguageIndexType } from '../../../languages/hooks/language-index-context.hook';
import { useLanguageIndex } from '../../../languages/hooks/language-index.hook';
import { EntityColumnFilter } from '../../../../components/table/filter/EntityColumnFilter';
import { supplierCategoriesAPI } from '../../redux/supplier-categories.slice';
import { MultipleEntityColumnFilter } from '../../../../components/table/filter/MultipleEntityColumnFilter';
import { serviceClassAPI } from '../../../services/redux/service-classes.slice';
import { supplierDomainAPI } from '../../redux/supplier-domains.slice';
import { qualificationApi } from '../../redux/supplier-qualifications.slice';
import { testTypeAPI } from '../../redux/test-types.slice';
import { statusTypeAPI } from '../../redux/status-type.slice';
import {
  defineEntityMap,
  EntityMapType,
  useEntityMap
} from '../../../../redux/entity-map';
import { serviceStepAPI } from '../../../services/redux/service-steps.slice';
import {
  DataAccessorError,
  entityAccessor,
  lazyConf
} from '../../../../components/table/renderer/TableDataRenderer';
import { languageAPI } from '../../../languages/redux/languages.slice';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { SupplierLink } from '../SupplierLink';

const supplierEntityMap = defineEntityMap({
  supplierDomain: supplierDomainAPI,
  qualification: qualificationApi,
  supplierCategory: supplierCategoriesAPI,
  statusType: statusTypeAPI,
  serviceClass: serviceClassAPI,
  serviceStep: serviceStepAPI,
  language: languageAPI,
  testType: testTypeAPI
});

function buildTableColumns(
  langIndex: LanguageIndexType,
  entityMap: EntityMapType<typeof supplierEntityMap>
) {
  return entityColumnBuilder<Supplier>()
    .addDefaultColumn(
      'supplier_name',
      (s) => `${s.name.first} ${s.name.last}`,
      'Name',
      {
        createElement: (_, s: Supplier) => {
          return <SupplierLink value={s} />;
          /*
          const name = `${s.name.first} ${s.name.last}`;
          return (
            <StyledLink to={`/suppliers/supplier/${s.id}`}>{name}</StyledLink>
          ); */
        },
        createText: (_, data: Supplier) => [data.name.first, data.name.last]
      }
    )
    .addColumn(
      'supplier_status',
      entityAccessor(entityMap, ['statusType'], (data, entityMap1) => {
        if (!entityMap1.statusType[data.status.statusTypeId]) {
          throw new DataAccessorError();
        }
        return {
          ...data.status,
          statusType: entityMap1.statusType[data.status.statusTypeId]
        };
      }),
      'Status',
      {
        renderer: lazyConf({
          createElement: (value: SupplierStatus) => (
            <SupplierStatusColumnRenderer status={value} />
          ),
          createText: (value: SupplierStatus) => [
            `${value.statusType.name}(${
              value.until ? `Until ${value.until}` : 'unlimited'
            } / ${value.explanation ?? '-'})`
          ],
          filter: MultipleEntityColumnFilter<
            Supplier,
            SupplierStatusType,
            SupplierStatus
          >(
            statusTypeAPI,
            (sst) => sst.name,
            (s) => [s.statusTypeId]
          ),
          comparator: ([a], [b]) => {
            if (a.statusType.statusType !== b.statusType.statusType) {
              return a.statusType.statusType.localeCompare(
                b.statusType.statusType
              );
            }
            return a.statusType.name.localeCompare(b.statusType.name);
          }
        })
      }
    )
    .addHiddenColumn('supplier_email', 'email', 'Email', {})
    .addHiddenDateColumn(
      'supplier_first_contact',
      'date',
      'firstContact',
      'First Contact',
      true
    )
    .addHiddenDefaultColumn(
      'supplier_import_reference',
      'importReference',
      'Import Ref'
    )
    .addBooleanColumn(
      'supplier_trados_license',
      (s) => s.catTools.includes('trados'),
      'Trados License',
      { hiddenByDefault: true }
    )
    .addColumn(
      'supplier_category',
      entityAccessor(
        entityMap,
        ['supplierCategory'],
        (data, entityMap1) =>
          entityMap1.supplierCategory[data.supplierCategoryId]
      ),
      'Category',
      {
        renderer: lazyConf({
          createText: (value: SupplierCategory) => [value.name],
          createElement: (value: SupplierCategory) => (
            <Chip label={value.name} size="small" />
          ),
          filter: EntityColumnFilter<
            Supplier,
            SupplierCategory,
            SupplierCategory
          >(
            supplierCategoriesAPI,
            (v) => v.name,
            (v) => [v.id]
          ),
          comparator: (
            a: [SupplierCategory, Supplier],
            b: [SupplierCategory, Supplier]
          ) => a[0].name.localeCompare(b[0].name)
        })
      }
    )
    .addColumn(
      'supplier_native_languages',
      entityAccessor(entityMap, ['language'], (data, entityMap1) =>
        data.languages
          ?.filter((sl) => sl.native)
          .map((sq) => {
            const element = entityMap1.language[sq.languageId];
            if (element === undefined) {
              throw new DataAccessorError();
            }
            return { ...sq, language: element };
          })
      ),
      'Native Languages',
      {
        renderer: lazyConf({
          createElement: (langs) => (
            <LanguageColumnRenderer languages={langs.filter((l) => l.native)} />
          ),
          createText: (langs) => langs.map((l) => l.language.name),
          filter: MultipleLangColumnFilter<Supplier, SupplierLanguage[]>(
            langIndex,
            (v) => v.filter((sL) => sL.native).map((sl) => sl.languageId)
          )
        })
      }
    )
    .addColumn(
      'supplier_non_native_languages',
      entityAccessor(entityMap, ['language'], (data, entityMap1) =>
        data.languages
          ?.filter((sl) => !sl.native)
          .map((sq) => {
            const element = entityMap1.language[sq.languageId];
            if (element === undefined) {
              throw new DataAccessorError();
            }
            return { ...sq, language: element };
          })
      ),
      'Non-Native Languages',
      {
        renderer: lazyConf({
          createElement: (langs) => (
            <LanguageColumnRenderer
              languages={langs.filter((l) => !l.native)}
            />
          ),
          createText: (langs) => langs.map((l) => l.language.name),
          filter: MultipleLangColumnFilter<Supplier, SupplierLanguage[]>(
            langIndex,
            (v) => v.filter((sL) => !sL.native).map((sl) => sl.languageId)
          )
        })
      }
    )
    .addColumn(
      'supplier_service_steps',
      entityAccessor(entityMap, ['serviceStep'], (data, entityMap1) =>
        data.serviceSteps?.map((sq) => {
          const element = entityMap1.serviceStep[sq.serviceStepId];
          if (element === undefined) {
            throw new DataAccessorError();
          }
          return { ...sq, serviceStep: element };
        })
      ),
      'Service Step',
      {
        renderer: lazyConf({
          createElement: (steps: SupplierServiceStep[]) => (
            <>
              {steps.map((step) => (
                <Chip size="small" label={step.serviceStep.name} />
              ))}
            </>
          ),
          createText: (steps) => steps.map((l) => l.serviceStep.name),
          filter: MultipleEntityColumnFilter<
            Supplier,
            ServiceStep,
            SupplierServiceStep[]
          >(
            serviceStepAPI,
            (step) => step.name,
            (v) => v.map((sl) => sl.serviceStepId)
          )
        })
      }
    )
    .addColumn(
      'supplier_services',
      entityAccessor(entityMap, ['serviceClass'], (data, entityMap1) =>
        data.services?.map((sq) => {
          const element = entityMap1.serviceClass[sq.serviceClassId];
          if (element === undefined) {
            throw new DataAccessorError();
          }
          return { ...sq, serviceClass: element };
        })
      ),
      'Services',
      {
        renderer: lazyConf({
          createElement: (v, s) => (
            <ServicesColumnRenderer
              languages={s.languages}
              supplierServices={v}
            />
          ),
          createText: (services) => services.map((l) => l.serviceClass.name),
          filter: MultipleEntityColumnFilter<
            Supplier,
            ServiceClass,
            SupplierService[]
          >(
            serviceClassAPI,
            (sc) => sc.name,
            (v) => v.map((s) => s.serviceClassId)
          )
        })
      }
    )
    .addDefaultColumn('supplier_profession', 'profession', 'Profession')
    .addColumn(
      'supplier_domains',
      entityAccessor(entityMap, ['supplierDomain'], (data, entityMap1) =>
        data.domains?.map((sq) => {
          const element = entityMap1.supplierDomain[sq.id];
          if (element === undefined) {
            throw new DataAccessorError();
          }
          return element;
        })
      ),
      'Domains',
      {
        renderer: lazyConf({
          createText: (domains) => domains.map((d) => d.name),
          createElement: (value) => <DomainColumnRenderer domains={value} />,
          filter: MultipleEntityColumnFilter<
            Supplier,
            SupplierDomain,
            SupplierDomain[]
          >(
            supplierDomainAPI,
            (sc) => sc.name,
            (v) => v.map((s) => s.id)
          )
        })
      }
    )
    .addColumn(
      'supplier_qualifications',
      entityAccessor(entityMap, ['qualification'], (data, entityMap1) =>
        data.qualifications?.map((sq) => {
          const element = entityMap1.qualification[sq.qualificationId];
          if (element === undefined) {
            throw new DataAccessorError();
          }
          return { ...sq, qualification: element };
        })
      ),
      'Qualifications',
      {
        renderer: lazyConf({
          createElement: (_, s) => (
            <QualificationsColumnRenderer qualifications={s.qualifications} />
          ),
          createText: (qualis) =>
            qualis.map((q) =>
              q.qualification.name + q.details ? ` (${q.details})` : ''
            ),
          filter: MultipleEntityColumnFilter<
            Supplier,
            Qualification,
            SupplierQualification[]
          >(
            qualificationApi,
            (sc) => sc.name,
            (v) => v.map((s) => s.qualificationId)
          )
        })
      }
    )
    .addColumn(
      'supplier_tests',
      entityAccessor(entityMap, ['testType'], (data, entityMap1) =>
        data.attachments
          ?.filter((a) => a.attachmentType === 'test')
          ?.map((a) => {
            const sq = a as SupplierTestAttachment;
            const element = entityMap1.testType[sq.testTypeId];
            if (element === undefined) {
              throw new DataAccessorError();
            }
            return { ...sq, testType: element };
          })
      ),
      'Tests',
      {
        renderer: lazyConf({
          createElement: (_, s) => (
            <SupplierTestColumnRenderer supplierId={s.id} />
          ),
          createText: (tests) =>
            tests
              .filter(
                (a) =>
                  a.attachmentType === 'test' &&
                  ((a as SupplierTestAttachment).testType.testVariant ===
                    'simple' ||
                    (a as SupplierTestAttachment).result?.resultState?.passed)
              )
              .map((a) => (a as SupplierTestAttachment).testType.name),
          filter: MultipleEntityColumnFilter<
            Supplier,
            SupplierTestType,
            SupplierAttachment[]
          >(
            testTypeAPI,
            (sc) => sc.name,
            (v) => v.map((s) => (s as SupplierTestAttachment).testTypeId)
          )
        })
      }
    ).columns;
}

interface SuppliersTableProps {
  suppliers: Supplier[];
  onRefresh: () => void;
  apiState: ApiState;
  initialStatusTypes: SupplierStatusType[];
}

export function SuppliersTable({
  suppliers,
  onRefresh,
  apiState,
  initialStatusTypes
}: SuppliersTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const languageIndex = useLanguageIndex();
  const entityMap = useEntityMap(supplierEntityMap);

  const tableCells = useCreateCells(
    buildTableColumns(languageIndex, entityMap),
    [languageIndex, entityMap]
  );

  const [searchConfig, setSearchConfig] = React.useState<ServiceSearchState>();

  const { reference } = useEditorLocation();

  const filterSupplierSearch = (supplier: Supplier) => {
    if (
      !entityMap.serviceClass ||
      !supplier.languages ||
      !searchConfig ||
      !supplier.services
    ) {
      return false;
    }
    const serviceClass = entityMap.serviceClass[searchConfig.serviceClassId];
    if (!serviceClass) {
      return false;
    }
    const supplierHasService = supplier.services.some(
      (s) => s.serviceClassId === serviceClass.id
    );
    if (!supplierHasService) {
      return false;
    }
    if (
      searchConfig.languages.some(
        (l) => !l.sourceLanguageId && l.targetLanguageIds.length === 0
      ) ||
      serviceClass.type === 'no-language'
    ) {
      return true;
    }

    const nativeLanguages = supplier.languages
      .filter((l) => l.native)
      .map((l) => l.languageId);

    const otherLanguages = (
      nativeLanguages.length < 2
        ? supplier.languages.filter((l) => !l.native)
        : supplier.languages
    ).map((l) => l.languageId);

    const calcAllLanguages = (): {
      sourceLanguage: string | undefined;
      targetLanguage: string;
    }[] => {
      if (!serviceClass || serviceClass.type === 'no-language') {
        return [];
      }
      if (serviceClass.type === 'only-target') {
        return nativeLanguages.map((li) => ({
          sourceLanguage: undefined,
          targetLanguage: li
        }));
      }
      return nativeLanguages.flatMap((nl) =>
        otherLanguages
          .filter((ol) => ol !== nl)
          .map((ol) => ({
            sourceLanguage: ol,
            targetLanguage: nl
          }))
      );
    };
    if (
      searchConfig.ignoreServiceLanguages ||
      !supplier.services.find((s) => s.serviceClassId === serviceClass.id)
        ?.languages?.length
    ) {
      const combinations = calcAllLanguages();
      return (
        combinations.find((c) => {
          return (
            (c.sourceLanguage === undefined ||
              searchConfig.languages[0].sourceLanguageId === undefined ||
              searchConfig.languages[0].sourceLanguageId === c.sourceLanguage ||
              includes(
                languageIndex.descriptors[
                  searchConfig.languages[0].sourceLanguageId
                ].specification,
                languageIndex.descriptors[c.sourceLanguage].specification
              )) &&
            (searchConfig.languages[0].targetLanguageIds.length === 0 ||
              searchConfig.languages[0].targetLanguageIds.includes(
                c.targetLanguage
              ) ||
              searchConfig.languages[0].targetLanguageIds.find((tL) =>
                includes(
                  languageIndex.descriptors[tL].specification,
                  languageIndex.descriptors[c.targetLanguage].specification
                )
              ))
          );
        }) !== undefined
      );
    }
    const languageConfig = supplier.services.find(
      (s) => s.serviceClassId === serviceClass.id
    )?.languages as Exclude<SupplierService['languages'], null>;
    return (
      languageConfig.find((lc) => {
        return (
          (lc.sourceLanguage === undefined ||
            searchConfig.languages[0].sourceLanguageId === undefined ||
            searchConfig.languages[0].sourceLanguageId === lc.sourceLanguage ||
            includes(
              languageIndex.descriptors[
                searchConfig.languages[0].sourceLanguageId
              ].specification,
              languageIndex.descriptors[lc.sourceLanguage].specification
            )) &&
          (searchConfig.languages[0].targetLanguageIds.length === 0 ||
            searchConfig.languages[0].targetLanguageIds.includes(
              lc.targetLanguage
            ) ||
            searchConfig.languages[0].targetLanguageIds.find((tL) =>
              includes(
                languageIndex.descriptors[tL].specification,
                languageIndex.descriptors[lc.targetLanguage].specification
              )
            ))
        );
      }) !== undefined
    );
  };
  return (
    <>
      <GenericTable
        extraSearchKeys={
          searchConfig
            ? [
                {
                  onDelete: () => setSearchConfig(undefined),
                  label: <SearchConfigChip searchConfig={searchConfig} />
                }
              ]
            : []
        }
        label={t('Suppliers')}
        refresh={onRefresh}
        primaryButton={{
          label: 'New Supplier',
          onClick: () => history.push(`${url}?type=create#supplier`),
          permission: permissions.suppliers.supplier.create
        }}
        data={searchConfig ? suppliers.filter(filterSupplierSearch) : suppliers}
        isLoading={apiState === 'pending'}
        tableCells={tableCells}
        rowActions={[]}
        initialFilter={{
          supplier_status: {
            filterState: initialStatusTypes.map((s) => s.id),
            active: true
          }
        }}
        toolbarActions={[
          {
            label: 'Copy Email adresses to clipboard',
            showWhen: 'selected',
            icon: <CopyEmailsIcon />,
            onClick: (selectedSuppliers, allSuppliers) => {
              const users =
                selectedSuppliers.length === 0
                  ? allSuppliers
                  : selectedSuppliers;
              navigator.clipboard.writeText(
                users.map((acc) => acc.email).join('; ')
              );
            }
          },
          {
            label: 'Search for services',
            showWhen: 'always',
            icon: <FindByServiceIcon />,
            onClick: () => {
              history.push(`${url}?type=edit#search`);
            }
          }
        ]}
      >
        {reference === 'search' && (
          <SupplierServiceSearchDialogForm
            state={searchConfig}
            onChange={setSearchConfig}
          />
        )}
      </GenericTable>
    </>
  );
}
