import React from 'react';
import { OutgoingInvoice, permissions } from 'model';
import {
  OutgoingInvoiceDTO,
  outgoingInvoiceTransformer
} from 'model/dist/project/dto/OutgoingInvoiceDTO';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { NewEditableCard } from '../../../../components/card/editableCard/NewEditableCard';
import {
  OutgoingInvoiceForm,
  outgoingInvoiceView
} from './OutgoingInvoiceForm';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import {
  createOutgoingInvoiceThunk,
  outgoingInvoiceThunk,
  updateOutgoingInvoiceThunk
} from '../../redux/projects.thunk';
import { NoOp } from '../../../../utils';
import { OutgoingInvoiceDisplay } from './OutgoingInvoiceDisplay';
import { EditableListCard } from '../../../../components/card/editableListCard/EditableListCard';

export function OutgoingInvoiceCard({
  invoice,
  projectId
}: {
  invoice: OutgoingInvoice | null;
  projectId: string;
}) {
  const dispatch = useAppDispatch();

  const updateInvoice = (dto: OutgoingInvoiceDTO) =>
    dispatch(outgoingInvoiceThunk({ input: dto, id: projectId }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <NewEditableCard
      identifier="outgoing-invoice"
      title="Outgoing Invoice"
      value={invoice || ({ invoiceNumber: '', paid: false } as OutgoingInvoice)}
      display={{ component: OutgoingInvoiceDisplay }}
      form={{
        component: OutgoingInvoiceForm,
        validationSchema: outgoingInvoiceView.validationSchema,
        dtoTransformer: outgoingInvoiceTransformer,
        viewFactory: outgoingInvoiceView.viewFactory,
        onSubmit: updateInvoice,
        permissions: permissions.project.project.edit
      }}
    />
  );
}

export function OutgoingInvoicesCard({
  invoices,
  projectId
}: {
  invoices: OutgoingInvoice[];
  projectId: string;
}) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const updateInvoice = (u: OutgoingInvoiceDTO, id: string) => {
    return dispatch(
      updateOutgoingInvoiceThunk({ invoiceId: id, projectId, input: u })
    )
      .then(unwrapResult)
      .catch(NoOp) as any;
  };
  const createInvoice = (u: OutgoingInvoiceDTO) => {
    return dispatch(createOutgoingInvoiceThunk({ projectId, input: u }))
      .then(unwrapResult)
      .catch(NoOp) as Promise<any>;
  };
  return (
    <EditableListCard<OutgoingInvoice, OutgoingInvoiceDTO>
      title={t('Outgoing Invoices')}
      identifierFactory={(i) => i.id}
      items={invoices.map((i) => ({
        display: { component: OutgoingInvoiceDisplay },
        form: {
          component: OutgoingInvoiceForm,
          validationSchema: outgoingInvoiceView.validationSchema,
          dtoTransformer: outgoingInvoiceTransformer,
          viewFactory: outgoingInvoiceView.viewFactory,
          onSubmit: (u) => updateInvoice(u, i.id),
          permissions: permissions.project.project.edit
        },
        value: i
      }))}
      creator={{
        title: t('Create Outgoing invoice'),
        initialValueFactory: () =>
          new OutgoingInvoice({ invoiceNumber: '', paid: false }),
        type: 'creator',
        component: OutgoingInvoiceForm,
        validationSchema: outgoingInvoiceView.validationSchema,
        dtoTransformer: outgoingInvoiceTransformer,
        viewFactory: outgoingInvoiceView.viewFactory,
        onSubmit: createInvoice
      }}
    />
  );
}
