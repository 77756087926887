import React from 'react';
import { NormalTestType, TestAttachmentPatchDTO } from 'model';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  buildDTOView,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { FormikSelect } from '../../../../components/select/FormikSelect';
import { CommentEditor } from '../../../../components/comment/CommentEditor';
import { useTestType } from '../../hooks/test-type.hook';
import { FormikEntitySelect } from '../../../../components/select/FormikEntitySelect';
import { supplierApi } from '../../redux/suppliers.slice';
import { FormikTextField } from '../../../../components/input/FormikTextField';

export const supplierTestView = buildDTOView<TestAttachmentPatchDTO>()
  .all()
  .withSchema(
    yup.object({
      attachedFiles: yup.array().of(
        yup
          .object({
            fileAccessId: yup.string().uuid().required() as any,
            token: yup.string().required() as any
          })
          .required()
      ),
      pin: yup.boolean().required(),
      attachmentType: yup.mixed().oneOf(['test']),
      testTypeId: yup.string().uuid().required(),
      testStateId: yup.string().defined().nullable().default(null),
      testerId: yup.string().uuid().defined().nullable().default(null),
      result: yup
        .object({
          resultStateId: yup
            .string()
            .uuid()
            .required('required')
            .nullable() as any,
          resultContent: yup.string().required('required') as any,
          comment: yup.string().defined() as any
        })
        .defined()
        .nullable()
        .default(null),
      comment: yup.string().nullable().default(null)
    })
  );

export function SupplierTestForm() {
  const { t } = useTranslation();
  const [, typeMeta] = useTypedField(supplierTestView.path.testTypeId);

  const [, commentMeta, commentHelpers] = useTypedField(
    supplierTestView.path.comment
  );

  const [, fileMeta, fileHelpers] = useTypedField(
    supplierTestView.path.attachedFiles
  );
  const [, resultMeta, resultHelper] = useTypedField(
    supplierTestView.path.result
  );
  const [, testStateMeta] = useTypedField(supplierTestView.path.testStateId);
  const { testType } = useTestType(typeMeta.value);
  if (!testType) {
    return <></>;
  }
  const testState =
    testStateMeta.value && testType.testVariant === 'normal'
      ? (testType as NormalTestType).testStates.find(
          (ts) => ts.id === testStateMeta.value
        )
      : null;
  return (
    <div>
      {testType.testVariant === 'normal' && (
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12}>
            <FormikSelect
              label={t('Status')}
              onChange={(v: string | null) => {
                const type = (testType as NormalTestType).testStates.find(
                  (ts) => ts.id === v
                );
                if (type?.resultsAvailable) {
                  resultHelper.setValue(
                    resultMeta.value ?? {
                      resultStateId: null as unknown as string,
                      comment: 'df',
                      resultContent: ''
                    }
                  );
                } else {
                  resultHelper.setValue(null);
                }
              }}
              name={supplierTestView.path.testStateId}
              options={(testType as NormalTestType).testStates.map((state) => ({
                label: state.name,
                value: state.id
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <FormikEntitySelect
              textFieldProps={{ label: t('Tester') }}
              entityApi={supplierApi}
              optionLabel={(s) => `${s.name.first} ${s.name.last}`}
              name={supplierTestView.path.testerId}
            />
          </Grid>
          {testState?.resultsAvailable && (
            <>
              <Grid item xs={12}>
                <FormikSelect
                  required
                  label={t('Statuss')}
                  name={(supplierTestView.path.result as any).resultStateId}
                  options={(testType as NormalTestType).resultStates.map(
                    (rs) => ({ value: rs.id, label: rs.name })
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField
                  multiline
                  required
                  label="Result Description"
                  name={(supplierTestView.path.result as any).resultContent}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
      <div style={{ marginTop: 30 }}>
        <CommentEditor
          value={commentMeta.value ?? ''}
          onChange={(s) => commentHelpers.setValue(s.trim() !== '' ? s : null)}
          onFileChange={(attachedFiles, done) => {
            if (done) {
              fileHelpers.setValue([
                ...fileMeta.value,
                ...attachedFiles.map((af) => ({
                  fileAccessId: af.id,
                  token: af.grant
                }))
              ]);
            }
          }}
        />
      </div>
    </div>
  );
}
