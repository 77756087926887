import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/redux/auth.slice';
import usersReducer from '../features/users/redux/users.slice';
import rolesReducer from '../features/users/redux/roles.slice';
import permissionsReducer from '../features/users/redux/permissions.slice';
import languagesReducer from '../features/languages/redux/languages.slice';
import clientsSlice from '../features/clients/redux/clients.slice';
import suppliersSlice from '../features/suppliers/redux/suppliers.slice';
import catToolsSlice from '../features/suppliers/redux/cat-tool.slice';
import priceUnitsSlice from '../features/prices/redux/price-units.slice';
import serviceClassesSlice from '../features/services/redux/service-classes.slice';
import serviceStepsSlice from '../features/services/redux/service-steps.slice';
import projectVariationsSlice from '../features/services/redux/project-variations.slice';
import supplierDomainsSlice from '../features/suppliers/redux/supplier-domains.slice';
import supplierStatusTypesSlice from '../features/suppliers/redux/status-type.slice';
import supplierQualificationsSlice from '../features/suppliers/redux/supplier-qualifications.slice';
import supplierAttachmentsSlice from '../features/suppliers/redux/supplier-attachments.slice';
import documentTypesSlice from '../features/suppliers/redux/document-type.slice';
import largeScaleProjectsSlice from '../features/projects/redux/large-scale-projects.slice';
import projectTagSlice from '../features/projects/redux/project-tag.slice';
import requestSourceSlice from '../features/projects/redux/request-sources.slice';
import projectSlice from '../features/projects/redux/projects.slice';
import projectRequestsSlice from '../features/projects/redux/project-requests.slice';
import supplierTestTypesSlice from '../features/suppliers/redux/test-types.slice';
import supplierHistorysSlice from '../features/suppliers/redux/supplier-history.slice';
import requestStatusSlice from '../features/projects/redux/request-status.slice';
import requestResourcesSlice from '../features/projects/redux/request-resources.slice';
import requestPositionsSlice from '../features/projects/redux/request-positions.slice';
import requestCommentSlice from '../features/projects/redux/request-comment.slice';
import projectOffersSlice from '../features/projects/redux/project-offer.slice';
import priceBoundsSlice from '../features/prices/redux/price-bounds.slice';
import supplierPricesSlice from '../features/suppliers/redux/supplier-price.slice';
import clientPricesSlice from '../features/clients/redux/client-prices.slice';
import supplierCategoriesSlice from '../features/suppliers/redux/supplier-categories.slice';
import projectOrderTasksSlice from '../features/projects/redux/project-order-tasks.slice';
import sequenceTemplatesSlice from '../features/services/redux/sequence-template.slice';
import translationsSlice from '../components/file/translations.slice';
import { projectCandidatesSlice } from '../features/projects/redux/project-candidates.slice';
import { supplierRecruitmentsSlice } from '../features/suppliers/redux/supplier-recruitments.slice';
import { projectExpensesSlice } from '../features/projects/redux/project-expenses.slice';
import { userProjectTemplateSlice } from '../features/users/redux/user-project-template.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer.reducer,
    clients: clientsSlice.reducer,
    'client-prices': clientPricesSlice.reducer,
    orderTasks: projectOrderTasksSlice.reducer,
    suppliers: suppliersSlice.reducer,
    'supplier-domains': supplierDomainsSlice.reducer,
    'status-types': supplierStatusTypesSlice.reducer,
    'test-types': supplierTestTypesSlice.reducer,
    'supplier-qualifications': supplierQualificationsSlice.reducer,
    'supplier-attachments': supplierAttachmentsSlice.reducer,
    'supplier-prices': supplierPricesSlice.reducer,
    'supplier-history': supplierHistorysSlice.reducer,
    'supplier-categories': supplierCategoriesSlice.reducer,
    'cat-tools': catToolsSlice.reducer,
    'price-units': priceUnitsSlice.reducer,
    'price-bounds': priceBoundsSlice.reducer,
    'service-classes': serviceClassesSlice.reducer,
    'service-steps': serviceStepsSlice.reducer,
    'project-variations': projectVariationsSlice.reducer,
    'large-scale-projects': largeScaleProjectsSlice.reducer,
    'project-tag': projectTagSlice.reducer,
    'project-requests': projectRequestsSlice.reducer,
    'project-offers': projectOffersSlice.reducer,
    'request-source': requestSourceSlice.reducer,
    'request-resources': requestResourcesSlice.reducer,
    'request-positions': requestPositionsSlice.reducer,
    'request-status': requestStatusSlice.reducer,
    'request-comments': requestCommentSlice.reducer,
    'document-types': documentTypesSlice.reducer,
    'sequence-templates': sequenceTemplatesSlice.reducer,
    'project-candidates': projectCandidatesSlice.reducer,
    'project-expenses': projectExpensesSlice.reducer,
    'supplier-recruitments': supplierRecruitmentsSlice.reducer,
    'user-project-templates': userProjectTemplateSlice.reducer,
    projects: projectSlice.reducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    languages: languagesReducer,
    translations: translationsSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
