import { Supplier } from 'model';
import { supplierApi } from '../redux/suppliers.slice';
import { createEntityDocument } from '../../../components/actions/DocumentLink';

export const { entityDocument: supplierDocument, EntityLink: SupplierLink } =
  createEntityDocument(
    Supplier,
    'Supplier',
    supplierApi,
    (s) => `/suppliers/supplier/${s.id}`,
    (s, format = 'name' as 'name' | 'email') =>
      format === 'name' ? `${s.name.first} ${s.name.last}` : s.email
  );
