import { Client, ClientNumberDTO } from 'model';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import { FormikDatePicker } from '../../../../components/input/date/FormikDatePicker';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import {
  assignClientNumberThunk,
  updateClientNumberThunk
} from '../../redux/actions/client-number.thunk';
import clientSlice, { clientSelectors } from '../../redux/clients.slice';
import { NoOp } from '../../../../utils';
import { useAlert } from '../../../../components/alert/alert.hook';
import { useClients } from '../../hooks/clients.hook';

const validationSchema = (clientNumbers: string[]) =>
  yup.object({
    clientNumber: yup
      .string()
      .required()
      .matches(/^[0-9]{5}$/, 'The client number contain exactly 5 digits')
      .test(
        'duplicate_client_number',
        'Duplicate client number',
        (str) => !clientNumbers.includes(str ?? '')
      ),
    referenceDate: yup.date().required()
  });
interface ClientNumberDialogProps {
  client: Client;
}

export function ClientNumberDialog({ client }: ClientNumberDialogProps) {
  const dispatch = useAppDispatch();
  const { showAlert } = useAlert();
  const { clients } = useClients();
  const clientNumbers = React.useMemo(
    () =>
      clients.map((c) => c.clientNumber?.number).filter((c) => !!c) as string[],
    [clients]
  );
  const { t } = useTranslation();

  const { type } = useEditorLocation();

  const createClientNumber = client.clientNumber
    ? (dto: ClientNumberDTO) => {
        return new Promise((resolve, reject) => {
          showAlert({
            title: `${t('Modify client number')}?`,
            content: (
              <>
                <Typography>
                  {t(
                    // eslint-disable-next-line max-len
                    'Changing an existing client number may cause inconsistent data (e.g. project numbers). Are you sure?'
                  )}
                </Typography>
              </>
            ),
            onConfirm: () => {
              dispatch(updateClientNumberThunk({ id: client.id, input: dto }))
                .then((res) => {
                  resolve(res);
                  return res;
                })
                .catch((err) => {
                  reject(err);
                });
            }
          });
        })
          .then(() => client)
          .catch(NoOp);
      }
    : (dto: ClientNumberDTO) =>
        dispatch(assignClientNumberThunk({ id: client.id, input: dto }))
          .then(() => client)
          .catch(NoOp);

  return (
    <DialogForm
      label={type === 'edit' ? t('Change Number') : t('Assign Number')}
      identifier="client-number"
      form={{
        initialValues: client.clientNumber
          ? {
              clientNumber: client.clientNumber.number,
              referenceDate: new Date(client.clientNumber.referenceDate)
            }
          : {
              clientNumber: '',
              referenceDate: new Date()
            },
        validationSchema: validationSchema(clientNumbers)
      }}
      api={{
        stateSelector: clientSelectors.selectState,
        errorSelector: clientSelectors.selectError,
        onSubmit: createClientNumber,
        clearError: clientSlice.actions.clearError
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      {() => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormikTextField name="clientNumber" label="Client Number" />
            </Grid>
            <Grid item xs={12}>
              <FormikDatePicker name="referenceDate" label="Reference Date" />
            </Grid>
          </Grid>
        );
      }}
    </DialogForm>
  );
}
