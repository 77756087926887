import { ParserResultType, requestDataParser, RequestImportDTO } from 'model';
import { upperFirst } from 'lodash';
import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { CSVParserView } from '../../../components/import/CSVParserView';
import { useClients } from '../../clients/hooks/clients.hook';
import { useSuppliers } from '../../suppliers/hooks/suppliers.hook';
import { apiClient } from '../../../api/apiClient';

const importTabs = {
  request: <div />
};
export function RequestImportScreen() {
  const { clients } = useClients();
  const { suppliers } = useSuppliers();

  const [tab, setTab] = React.useState<keyof typeof importTabs>('request');
  return (
    <>
      <Tabs value={tab} onChange={(_, value) => setTab(value)}>
        {Object.keys(importTabs).map((k) => (
          <Tab value={k} key={k} label={upperFirst(k)} />
        ))}
      </Tabs>
      <div>
        <CSVParserView
          importAction={(
            data: ParserResultType<ReturnType<typeof requestDataParser>>
          ) => {
            (async function blub() {
              try {
                // console.log(data);
                const dto: RequestImportDTO = {
                  requests:
                    data.result2?.map((r) => ({
                      comment: r.comments,
                      projectNumber: r.projectNumber,
                      clientName: r.clientName,
                      clientNumber: r.clientNumber,
                      requestSource: r.requestSource,
                      dateOfOffer: r.dateOfOffer,
                      dateOfRequest: r.dateOfRequest,
                      dateOfOrder: r.dateOfOrder,
                      status: r.status,
                      projectManagers: r.projectManagers.map(
                        (pm) =>
                          suppliers.find((s) => s.importReference === pm)
                            ?.id as string
                      )
                    })) ?? []
                };
                await apiClient.post('/projects/project-request/import', dto);
              } catch (e: any) {
                // console.log('error', e);
              }
            })();
          }}
          headerRows={1}
          key="request"
          parser={requestDataParser(
            (c) => suppliers.find((s) => s.importReference === c) ?? null,
            (c) => clients.find((cl) => cl.clientNumber?.number === c) ?? null
          )}
        />
      </div>
    </>
  );
}
