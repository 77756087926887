import React from 'react';
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Popover,
  Typography
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { useTableConfigContext } from '../context/DataTableContext';
import { useTableFilterControl } from './column-filter-control.hook';
import { useTableColumn } from '../context/table-column.hook';

function ColumnFilterPopoverItem({ columnKey }: { columnKey: string }) {
  const { filter, ...cell } = useTableColumn(columnKey);
  const { state, active, resetState, changeState, setActive } =
    useTableFilterControl(columnKey);
  if (filter === undefined) {
    return <></>;
  }
  const Component = filter.component;
  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <IconButton size="small" onClick={() => setActive(!active)}>
          <FilterListIcon
            fontSize="small"
            color={active ? 'primary' : undefined}
          />
        </IconButton>
        <Typography variant="button">{cell.label}</Typography>
      </Grid>
      {active && (
        <Grid item xs={12} style={{ paddingLeft: '2.5rem' }}>
          <Box display="flex">
            <Component
              cell={cell}
              filterState={state}
              onFilterChange={changeState as any}
              onFilterReset={resetState}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

function ColumnFilterPopover() {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tableFilterState: { filterState },
    tableColumnVisibility: { columnConfig },
    tableCells
  } = useTableConfigContext<any, any>();
  return (
    <Box padding="1rem" maxWidth="40vw">
      <Grid container spacing={1}>
        {Object.entries(filterState)
          .filter(
            ([k, ov]) =>
              (!!ov && ov.active) ||
              (columnConfig[k]?.visible && tableCells[k]?.renderer?.filter)
          )
          .map(([k]) => (
            <ColumnFilterPopoverItem key={k} columnKey={k} />
          ))}
      </Grid>
    </Box>
  );
}

export function ColumnFilterPopoverButton<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const [open, setOpen] = React.useState<HTMLElement | null>(null);
  const {
    tableFilterState: { filterState }
  } = useTableConfigContext<T, C>();
  const activeFilters = Object.values(filterState).filter(
    (ov) => !!ov && ov.active
  ).length;
  return (
    <>
      <Badge badgeContent={activeFilters} color="primary">
        <IconButton
          onClick={(event) => setOpen(open ? null : event.currentTarget)}
        >
          <FilterListIcon color={activeFilters > 0 ? 'primary' : undefined} />
        </IconButton>
      </Badge>
      <Popover
        keepMounted
        open={!!open}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <ColumnFilterPopover />
      </Popover>
    </>
  );
}
