"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WrapStatusTypeDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const ActiveStatusTypeDTO_1 = require("./ActiveStatusTypeDTO");
const InactiveStatusTypeDTO_1 = require("./InactiveStatusTypeDTO");
const SupplierStatusTypeDTO_1 = require("./SupplierStatusTypeDTO");
class WrapStatusTypeDTO {
}
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => SupplierStatusTypeDTO_1.SupplierStatusTypeDTO, {
        discriminator: {
            property: 'statusType',
            subTypes: [
                {
                    value: ActiveStatusTypeDTO_1.ActiveStatusTypeDTO,
                    name: 'active'
                },
                {
                    value: InactiveStatusTypeDTO_1.InactiveStatusTypeDTO,
                    name: 'inactive'
                }
            ]
        },
        keepDiscriminatorProperty: true
    }),
    __metadata("design:type", Object)
], WrapStatusTypeDTO.prototype, "type", void 0);
exports.WrapStatusTypeDTO = WrapStatusTypeDTO;
