import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../redux/redux.hooks';
import { requestCommentSelectors } from '../redux/request-comment.slice';

export function useCommentsOfRequest(requestId: string) {
  const comments = useAppSelector(
    (state) => requestCommentSelectors.selectByRequest(state, requestId),
    shallowEqual
  );
  return { comments };
}
