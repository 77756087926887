import React from 'react';
import { useTranslation } from 'react-i18next';
import { Price, SupplierMinimumPriceDTO } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { defineMinimumSupplierPrice } from '../../redux/supplier-prices.thunk';
import { NoOp } from '../../../../utils';
import { buildDTOView } from '../../../../transformer/DTOViewSchema';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import suppliersSlice, {
  suppliersSelectors
} from '../../redux/suppliers.slice';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { FormikPriceInput } from '../../../clients/components/prices/FormikPriceInput';

const validationSchema: yup.SchemaOf<SupplierMinimumPriceDTO> = yup
  .object()
  .defined()
  .shape({
    price: yup
      .object()
      .required()
      .shape({
        price: yup.number().required(),
        currency: yup.string().default(null).nullable()
      }),
    description: yup.string().default(undefined).notRequired()
  });

// Todo any

const supplierMinPriceView = buildDTOView<SupplierMinimumPriceDTO>()
  .all()
  .withSchema(validationSchema as any);

export function SupplierMinPriceFormDialog({
  supplierId,
  defaultPrice,
  description
}: {
  supplierId: string;
  description?: string;
  defaultPrice?: Price;
}) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const editMinPrice = (input: SupplierMinimumPriceDTO) =>
    dispatch(defineMinimumSupplierPrice({ id: supplierId, input }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <DialogForm
      identifier="min-price"
      label="Min Price"
      form={{
        validationSchema,
        initialValues: {
          price: {
            price: defaultPrice?.default || 0,
            currency: defaultPrice?.currency ?? null
          },
          description
        }
      }}
      api={{
        onSubmit: editMinPrice,
        stateSelector: suppliersSelectors.selectState,
        errorSelector: suppliersSelectors.selectError,
        clearError: suppliersSlice.actions.clearError
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormikPriceInput
            label={t('Price')}
            amountPath={supplierMinPriceView.path.price.price}
            currencyPath={supplierMinPriceView.path.price.currency}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikTextField
            label={t('Comment')}
            name={supplierMinPriceView.path.description}
          />
        </Grid>
      </Grid>
    </DialogForm>
  );
}
