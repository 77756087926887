import React from 'react';
import { Client } from 'model';
import { useRouteMatch } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useClientPrices } from '../../hooks/client-prices.hook';
import { ClientPricesTable } from './ClientPricesTable';
import { NoOp } from '../../../../utils';
import { ClientPriceFormDialog } from './ClientPriceFormDialog';
import { ClientMinPriceFormDialog } from './ClientMinPriceFormDialog';
import { IconButtonLink } from '../../../../components/input/button/IconButtonLink';
import { clientPricesSelectors } from '../../redux/client-prices.slice';
import { useAppSelector } from '../../../../redux/redux.hooks';
import { PriceDisplay } from './PriceDisplay';

export function ClientPrices({ client }: { client: Client }) {
  const { t } = useTranslation();
  const { prices } = useClientPrices(client.id);
  const { url } = useRouteMatch();
  const apiState = useAppSelector(clientPricesSelectors.selectState);

  return (
    <div>
      <ClientMinPriceFormDialog
        clientId={client.id}
        defaultPrice={client.minimumPrice?.price}
        description={client.minimumPrice?.description}
      />
      <ClientPriceFormDialog clientId={client.id} />

      <Typography variant="h5">
        Minimum Price:{' '}
        <PriceDisplay
          amount={client.minimumPrice?.price.default}
          currencyCode={client.minimumPrice?.price.currency}
          defaultCurrencyCode={client.defaultCurrency}
          typographyProps={{ variant: 'inherit' }}
        />
        <IconButtonLink title={t('edit')} to={`${url}?type=edit#min-price`}>
          <Edit />
        </IconButtonLink>
      </Typography>
      <ClientPricesTable
        prices={prices}
        onRefresh={NoOp}
        apiState={apiState}
        client={client}
      />
    </div>
  );
}
