import { AuthTokensWithUser, InitialDataAggregation } from 'model';
import { apiClient, refreshAuthTokens } from '../../../../api/apiClient';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { tokenService } from '../../token.service';
import { fetchInitialDataThunk } from '../../../../redux/initial-data.thunk';

export const loginSilentThunk = createErrorHandlingThunk<AuthTokensWithUser>(
  'login-silent',
  refreshAuthTokens
);

export type AuthWithData = {
  auth: AuthTokensWithUser;
  initialData: InitialDataAggregation;
};
// eslint-disable-next-line max-len
export const loginSilentWithDataThunk =
  createErrorHandlingThunk<AuthTokensWithUser>(
    'login-silent-with-data',
    async (_, { dispatch }): Promise<AuthTokensWithUser> => {
      const refreshToken = tokenService.getRefreshToken();
      const result = await apiClient.post<AuthWithData>(
        '/auth/refreshWithData',
        undefined,
        {
          headers: { Authorization: `Bearer ${refreshToken}` }
        }
      );
      tokenService.setAuthTokens(result.data.auth);
      await dispatch(fetchInitialDataThunk(result.data.initialData));
      return result.data.auth;
    }
  );
