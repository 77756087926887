/* eslint-disable no-console */
import { CreateFrontendLogDTO } from 'model';
import { apiClient } from '../api/apiClient';

const isLocal = () => process.env.NODE_ENV === 'development';

export const Log = {
  createAsyncLog(message: string, context: string) {
    const input: CreateFrontendLogDTO = { message, trace: context };
    apiClient.post('/log/frontend/error', input);
  },
  warn(...args: any) {
    if (isLocal()) {
      console.warn(args);
    }
  },
  info(...args: any) {
    if (isLocal()) {
      console.info(args);
    }
  },
  log(...args: any) {
    if (isLocal()) {
      console.log(args);
    }
  },
  error(message: string, context: string) {
    if (isLocal()) {
      console.error(message, context);
    } else {
      this.createAsyncLog(message, context);
    }
  }
};
