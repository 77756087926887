import React from 'react';
import { OrderServiceTask, OrderStepTask } from 'model';
import { Button, IconButton } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/Add';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ArrowForward } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { OrderTaskScopePhases } from './OrderTaskScopePhases';
import { StepTaskCard } from './StepTaskCard';
import { ServiceStepSelectPopover } from './ServiceStepSelectPopover';
import { LanguageDisplay } from '../../../languages/components/LanguageDisplay';
import { useTaskTransitions } from './order-task-state.hook';
import { TransitionActionComponent } from './TransitionActionComponent';
import { useTaskMap } from './order-task.context';
import { projectOrderTasksThunk } from '../../redux/project-order-tasks.thunk';
import { NoOp } from '../../../../utils';
import { useAppDispatch } from '../../../../redux/redux.hooks';

function ServiceLaneItems({
  tasks,
  projectId,
  laneTask
}: {
  tasks: OrderStepTask[];
  projectId: string;
  laneTask: OrderServiceTask;
}) {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <>
      {tasks.map((pt) => (
        <StepTaskCard stepTask={pt as OrderStepTask} projectId={projectId} />
      ))}
      {/*  {type === 'create' && reference ===
      `${laneTask.id}-provision-task` && (
        <StepTaskCreator
          dependencyIds={tasks.length > 0 ? [tasks[tasks.length - 1].id] : []}
          scope="service-lane"
          phase="provision"
          projectId={projectId}
          parentTask={laneTask}
        />
      )} */}
      <div style={{ marginTop: 10 }}>
        <ServiceStepSelectPopover
          dependencyIds={tasks.length > 0 ? [tasks[tasks.length - 1].id] : []}
          scope="service-lane"
          phase="provision"
          projectId={projectId}
          parentTask={laneTask}
          onInput={(step) => {
            if (step !== null) {
              history.push(
                // eslint-disable-next-line max-len
                `${url}?type=create&serviceStepId=${step.id}#${laneTask.id}-provision-task`
              );
            }
          }}
          /* filter={(step) => step.scopes['service-lane']
                        ?.phase === 'provision'} */
          filter={() => true}
          button={({ onClick }) => (
            <Button
              startIcon={<PlusIcon edgeMode="left" />}
              size="small"
              style={{
                color: 'grey'
              }}
              onClick={(e) => onClick(e.target as HTMLElement)}
            >
              Task
            </Button>
          )}
        />
      </div>
    </>
  );
}

export function ServiceLaneView({
  projectId,
  laneTask,
  sourceLanguageId
}: {
  projectId: string;
  laneTask: OrderServiceTask;
  sourceLanguageId?: string | null;
}) {
  const dispatch = useAppDispatch();
  const taskMap = useTaskMap();
  const de = Object.values(taskMap).some(
    (ts) => ts.value.parentTaskId === laneTask.id
  );

  const deleteStepTask = () => {
    dispatch(projectOrderTasksThunk.delete({ projectId, id: laneTask.id }))
      .then((res) => {
        dispatch(projectOrderTasksThunk.list({ projectId }));
        return res;
      })
      .catch(NoOp);
  };

  const transitionsActions = useTaskTransitions(laneTask);
  return (
    <div
      style={{
        paddingTop: 10,
        /* paddingRight: 10, */
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
      }}
    >
      <div
        style={{
          display: 'flex',
          marginTop: 10,
          marginBottom: 5
        }}
      >
        {sourceLanguageId && <LanguageDisplay languageId={sourceLanguageId} />}
        <ArrowForward
          fontSize="small"
          style={{ marginLeft: 5, marginRight: 5 }}
        />
        {laneTask.targetLanguageId && (
          <LanguageDisplay languageId={laneTask.targetLanguageId} />
        )}
        <div>
          {transitionsActions.map((action) => {
            return (
              <TransitionActionComponent
                action={action}
                task={laneTask}
                projectId={projectId}
              />
            );
          })}
        </div>
        {!de && (
          <IconButton size="small" onClick={deleteStepTask}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      <OrderTaskScopePhases
        projectId={projectId}
        scope="service-lane"
        parentTask={laneTask}
        provisionComponent={({ tasks }) => (
          <ServiceLaneItems
            tasks={tasks as OrderStepTask[]}
            projectId={projectId}
            laneTask={laneTask}
          />
        )}
      />
    </div>
  );
}
