"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolymorphSubtype = exports.POLYMORPH_SUBTYPE_KEY = void 0;
const PolymorphSupertype_1 = require("./PolymorphSupertype");
exports.POLYMORPH_SUBTYPE_KEY = 'polymorph_subtype';
function PolymorphSubtype(superType, discriminatorValue) {
    return (target) => {
        Reflect.defineMetadata(exports.POLYMORPH_SUBTYPE_KEY, {
            discriminator: Reflect.getMetadata(PolymorphSupertype_1.POLYMORPH_SUPERTYPE_KEY, superType),
            discriminatorValue,
            superType
        }, target);
    };
}
exports.PolymorphSubtype = PolymorphSubtype;
