import { StepSequenceTemplate } from 'model';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const sequenceTemplateThunk = createErrorHandlingThunk(
  `sequence-templates/list`,
  async () => {
    const result = await apiClient.get<StepSequenceTemplate[]>(
      '/services/sequence-template'
    );
    return result.data;
  }
);
