import { Supplier, SupplierAttachment } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import { suppliersThunk } from './suppliers.thunk';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { supplierAttachmentsThunk } from './supplier-attachments.thunk';
import { RootState } from '../../../redux/store';

const { list, delete: deleteThunk, ...thunks } = supplierAttachmentsThunk;

const supplierAttachmentsSlice = createEntitySlice<SupplierAttachment>(
  'supplier-attachments',
  [
    CustomThunk.override<SupplierAttachment>(list, {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.upsertMany(state, payload as SupplierAttachment[]);
      }
    }),
    CustomThunk.override<SupplierAttachment>(
      deleteThunk as AsyncThunk<
        EntityId,
        { supplierId: string; id: EntityId },
        any
      >,
      {
        fulfilled: (adapter, state, { payload }) => {
          state.state = 'idle';
          return adapter.removeOne(state, payload as string);
        }
      }
    ),
    CustomThunk.override<Supplier, SupplierAttachment>(suppliersThunk.list, {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.upsertMany(
          state,
          (payload as Supplier[]).flatMap((s) => s.attachments ?? [])
        );
      }
    }),
    CustomThunk.override<Supplier, SupplierAttachment>(
      suppliersThunk.listDetails,
      {
        fulfilled: (adapter, state, { payload }) => {
          state.state = 'idle';
          return adapter.upsertMany(
            state,
            (payload as Supplier[]).flatMap((s) => s.attachments ?? [])
          );
        }
      }
    ),
    CustomThunk.override<Supplier, SupplierAttachment>(suppliersThunk.get, {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.upsertMany(
          state,
          (payload as Supplier).attachments ?? []
        );
      }
    }),
    ...Object.values(thunks)
  ]
);
export const supplierAttachmentsSelectors = {
  ...createAdapterSelectors(
    'supplier-attachments',
    supplierAttachmentsSlice.adapter
  ),
  selectBySupplier(state: RootState, supplierId: string) {
    return this.selectAll(state).filter((a) => a.supplierId === supplierId);
  }
};

export default supplierAttachmentsSlice;
