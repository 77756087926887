"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierTestAttachment = void 0;
const typeorm_1 = require("typeorm");
const SupplierAttachment_1 = require("./SupplierAttachment");
const SupplierTestType_1 = require("./SupplierTestType");
const TestTypeState_1 = require("./TestTypeState");
const Supplier_1 = require("./Supplier");
// eslint-disable-next-line import/no-cycle
const SupplierTestResult_1 = require("./SupplierTestResult");
let SupplierTestAttachment = class SupplierTestAttachment extends SupplierAttachment_1.SupplierAttachment {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => SupplierTestType_1.SupplierTestType),
    __metadata("design:type", SupplierTestType_1.SupplierTestType)
], SupplierTestAttachment.prototype, "testType", void 0);
__decorate([
    (0, typeorm_1.RelationId)((a) => a.testType),
    __metadata("design:type", String)
], SupplierTestAttachment.prototype, "testTypeId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => TestTypeState_1.TestTypeState, { nullable: true }),
    __metadata("design:type", TestTypeState_1.TestTypeState)
], SupplierTestAttachment.prototype, "testState", void 0);
__decorate([
    (0, typeorm_1.RelationId)((a) => a.testState),
    __metadata("design:type", String)
], SupplierTestAttachment.prototype, "testStateId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier, { nullable: true }),
    __metadata("design:type", Supplier_1.Supplier)
], SupplierTestAttachment.prototype, "tester", void 0);
__decorate([
    (0, typeorm_1.RelationId)((a) => a.tester),
    __metadata("design:type", String)
], SupplierTestAttachment.prototype, "testerId", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => SupplierTestResult_1.SupplierTestResult, (r) => r.test, {
        nullable: true,
        cascade: ['insert']
    }),
    __metadata("design:type", SupplierTestResult_1.SupplierTestResult)
], SupplierTestAttachment.prototype, "result", void 0);
SupplierTestAttachment = __decorate([
    (0, typeorm_1.ChildEntity)('test'),
    __metadata("design:paramtypes", [Object])
], SupplierTestAttachment);
exports.SupplierTestAttachment = SupplierTestAttachment;
