import React from 'react';
import { useUsers } from '../hooks/users.hook';
import { ErrorBoundary } from '../../error/ErrorBoundary';
import { AccountsTable } from '../components/AccountsTable';

export function AccountsScreen() {
  const { users, apiState, listUsers } = useUsers();

  return (
    <ErrorBoundary context="accountsScreen">
      <AccountsTable
        accounts={users}
        onRefresh={listUsers}
        apiState={apiState}
      />
    </ErrorBoundary>
  );
}
