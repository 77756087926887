import React from 'react';
import { makeStyles, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, Route, useRouteMatch } from 'react-router-dom';
import { createStyles } from '@material-ui/core/styles';
import { Switch as RouterSwitch } from 'react-router';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { minHeight: '91%' },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2)
    },
    timeContainer: {
      marginLeft: theme.spacing(1)
    },
    actionsContainer: {
      marginLeft: 'auto'
    },
    content: {
      paddingTop: theme.spacing(3),
      position: 'relative'
    },
    badge: {
      fontSize: 8,
      height: 15,
      width: 15
    }
  })
);

export interface DetailsTabDefinition {
  name: string;
  subPaths: string[];
  component: () => React.ReactNode;
}

interface TabbedDetailsProps {
  header: () => JSX.Element;
  tabs: DetailsTabDefinition[];
}

export function TabbedDetails({
  header: DetailsHeader,
  tabs
}: TabbedDetailsProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { path, url } = useRouteMatch();

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <DetailsHeader />
      </div>
      <RouterSwitch>
        {tabs.map((tab) => (
          <Route
            key={tab.name}
            exact
            path={tab.subPaths.map((sP) => path + sP)}
          >
            <Tabs value={tab.name}>
              {tabs.map((td) => (
                <Tab
                  key={td.name}
                  value={td.name}
                  label={t(td.name)}
                  component={RouterLink}
                  to={url + td.subPaths[0]}
                />
              ))}
              {/*            <div className={classes.actionsContainer}>
              {props.actions &&
                props.actions.map((action) => (
                  <IconButton
                    size="small"
                    style={{ marginRight: 5 }}
                    title={t(action.label)}
                    onClick={action.onClick}
                  >
                    <action.icon />
                  </IconButton>
                ))}
              {props.renderComments && (
                <IconButtonLink
                  title={t('Comments')}
                  style={{ marginRight: 5 }}
                  to={`${url}#comments`}
                  size="small"
                >
                  <CommentIcon />
                </IconButtonLink>
              )}
            </div> */}
            </Tabs>
            <div className={classes.content}>{tab.component()}</div>
          </Route>
        ))}
      </RouterSwitch>
      {/*    {props.renderComments && (
      <CommentsDrawer>{props.renderComments(entity)}</CommentsDrawer>
    )} */}
    </div>
  );
}
