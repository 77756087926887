import React from 'react';
import { BaseEntity } from 'model';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from './redux.hooks';
import { EntityAPI } from './entity-api.hook';

export function useEntityIdSelector<T extends BaseEntity>(
  entityApi: EntityAPI<T>,
  entityId: T['id'] | null | undefined
) {
  const dispatch = useAppDispatch();

  const apiState = useAppSelector(entityApi.selectors.selectState);

  const entity = useAppSelector(
    (state) => entityApi.selectors.selectById(state, entityId ?? ''),
    shallowEqual
  );

  React.useEffect(() => {
    if (
      apiState !== 'unauthorized' &&
      !entity &&
      entityId &&
      apiState !== 'pending'
    ) {
      dispatch(entityApi.thunks.get({ id: entityId }));
    }
  }, [entity]);

  return { entity };
}
