import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import React from 'react';
import { FormikEntitySelect } from '../../../../../components/select/FormikEntitySelect';
import { requestSourceAPI } from '../../../redux/request-sources.slice';
import { FormikDatePicker } from '../../../../../components/input/date/FormikDatePicker';
import { EditableCheckboxDisplay } from '../../../../../components/input/checkbox/EditableCheckboxDisplay';
import { FormikCommentEditor } from '../../../../../components/input/comment/FormikCommentEditor';
import { RequestBasicView } from './RequestBasicView';

export function RequestBasicForm({
  basePath = RequestBasicView.path,
  withCommentEditor
}: {
  basePath?: (typeof RequestBasicView)['path'];
  withCommentEditor?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      {/*  <Grid item xs={3}>
        <FormikEntitySelect
          entityApi={requestStatusAPI}
          optionLabel={(v) => v.name}
          name={basePath.requestStatusId}
          textFieldProps={{ label: t('Request Status') }}
        />
      </Grid> */}
      <Grid item xs={3}>
        <FormikEntitySelect
          entityApi={requestSourceAPI}
          optionLabel={(v) => v.name}
          name={basePath.requestSourceId}
          textFieldProps={{ label: t('Request Source'), required: true }}
        />
      </Grid>
      <Grid item xs={3}>
        <FormikDatePicker
          label={t('Date of Request')}
          name={basePath.dateOfRequest}
        />
      </Grid>
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <EditableCheckboxDisplay
          label={t('Express Project')}
          name={basePath.expressProject}
        />
      </Grid>
      {withCommentEditor && (
        <Grid item xs={12}>
          <FormikCommentEditor label="Comment" name={basePath.comment} />
        </Grid>
      )}
    </Grid>
  );
}
