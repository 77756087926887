import { CreateRequestDTO, ProjectRequest } from 'model';
import { useTranslation } from 'react-i18next';
import { EntityId, unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import React from 'react';
import projectsSlice, { projectSelectors } from '../../redux/projects.slice';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import { ProjectBasicForm } from '../basic/ProjectBasicForm';
import { RequestBasicForm } from './basic/RequestBasicForm';
import { useAppDispatch, useAppSelector } from '../../../../redux/redux.hooks';
import { NoOp } from '../../../../utils';
import { projectRequestThunk } from '../../redux/project-requests.thunk';
import { RequestCreatorView } from './request-creator.view';
import { CardWithHeader } from '../../../../components/card/cardWithHeader/CardWithHeader';
import { RequestResourcesForm } from './resource/form/RequestResourcesForm';
import { RequestPositionsForm } from './positions/form/RequestPositionsForm';
import { useRequestStatusList } from '../../hooks/request-status-list.hook';
import { projectThunk } from '../../redux/projects.thunk';
import { selectUserAsNullable } from '../../../auth/redux/auth.slice';

const initialValues: (
  s: string,
  projectManagerId?: string
) => CreateRequestDTO = (
  defaultStatusId: string,
  projectManagerId?: string
) => ({
  duplicatedFromId: null,
  project: {
    projectName: '',
    projectTagIds: [],
    projectVariationId: null,
    clientId: null as unknown as string,
    projectManagerId: (projectManagerId ?? null) as unknown as string,
    supportingProjectManagerIds: [],
    createdInWordbee: false,
    tmConsolidated: false,
    projectStatus: 'not-yet-commisioned',
    deadline: null
  },
  request: {
    requestSourceId: null as unknown as string,
    requestStatusId: defaultStatusId,
    dateOfRequest: new Date(),
    expressProject: false
  },
  resources: [],
  positions: []
});

export function RequestFormDialog() {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserAsNullable);
  const { t } = useTranslation();

  const requestStatusList = useRequestStatusList();

  const createRequest = (dto: CreateRequestDTO) => {
    return dispatch(
      projectRequestThunk.create({
        input: dto
      })
    )
      .then(unwrapResult)
      .then((res) => {
        dispatch(projectThunk.list());
        return res;
      })
      .catch(NoOp);
  };

  const history = useHistory();

  const navigateToDetails = (
    id: EntityId | undefined,
    result?: ProjectRequest
  ) => history.replace(`/projects/projects/${result?.projectId ?? ''}`);

  if (requestStatusList.entities.length === 0) {
    return <></>;
  }

  return (
    <DialogForm
      identifier="project-request"
      maxWidth="md"
      label={t('New Project Request')}
      actions={[
        { label: t('Save and Exit'), doSubmit: true },
        {
          label: t('Save and Details'),
          doSubmit: true,
          onClick: navigateToDetails
        }
      ]}
      form={{
        initialValues: initialValues(
          requestStatusList.entities.find(
            (s) => s.defaultStatus && s.type === 'open'
          )?.id as string,
          user?.supplier?.id
        ),
        validationSchema: RequestCreatorView.validationSchema
      }}
      api={{
        clearError: projectsSlice.actions.clearError,
        errorSelector: projectSelectors.selectError,
        onSubmit: createRequest,
        stateSelector: projectSelectors.selectState
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardWithHeader title="Project">
            <ProjectBasicForm
              basePath={RequestCreatorView.path.project as any}
              isNewRequest
            />
          </CardWithHeader>
        </Grid>
        <Grid item xs={12}>
          <CardWithHeader title="Basic">
            <RequestBasicForm
              basePath={RequestCreatorView.path.request as any}
              withCommentEditor
            />
          </CardWithHeader>
        </Grid>
        <Grid item xs={12}>
          <CardWithHeader title="Resources">
            <RequestResourcesForm />
          </CardWithHeader>
        </Grid>
        <Grid item xs={12}>
          <CardWithHeader title="Positions">
            <RequestPositionsForm />
          </CardWithHeader>
        </Grid>
      </Grid>
    </DialogForm>
  );
}
