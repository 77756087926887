import React from 'react';
import { Chip, List, ListItem } from '@material-ui/core';
import { useSupplierDomains } from '../../../hooks/supplier-domains.hook';
import { ErrorBoundary } from '../../../../error/ErrorBoundary';

interface SupplierDomainsDisplayProps {
  domainIds: string[];
}

export function SupplierDomainsDisplay({
  domainIds
}: SupplierDomainsDisplayProps) {
  const domains = useSupplierDomains();

  const domainsOfSupplier = domains.supplierDomains.filter((d) =>
    domainIds.some((id) => d.id === id)
  );

  return (
    <ErrorBoundary context="SupplierDomainsDisplay">
      <List dense>
        <ListItem dense style={{ display: 'flex', flexWrap: 'wrap' }}>
          {domainsOfSupplier.map((d) => (
            <Chip style={{ margin: 1 }} label={d.name} />
          ))}
        </ListItem>
      </List>
    </ErrorBoundary>
  );
}
