import {
  ClientComment,
  ClientCommentDTO,
  clientCommentFilePermission
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { createClientCommentThunk } from '../../redux/actions/create-client-comment.thunk';
import { NoOp } from '../../../../utils';
import { CommentList } from '../../../../components/comment/CommentList';

interface ClientCommentListProps {
  clientId: string;
  clientComments: ClientComment[];
}

export function ClientCommentList({
  clientId,
  clientComments
}: ClientCommentListProps) {
  const dispatch = useAppDispatch();

  const createComment = (dto: ClientCommentDTO) =>
    dispatch(createClientCommentThunk({ clientId, dto }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <CommentList
      comments={clientComments}
      filePermission={clientCommentFilePermission}
      onCreateComment={(comment, attachedFiles) =>
        createComment({
          comment,
          attachedFiles: attachedFiles.map((af) => ({
            fileAccessId: af.id,
            token: af.grant
          }))
        })
      }
    />
  );
}
