import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import _ from 'lodash';
import { CountryCodeMap, includes, parseIETFTag } from 'model';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import LanguageIcon from '@material-ui/icons/Language';
import TranslateIcon from '@material-ui/icons/Translate';
import { useSelectLanguage } from '../hooks/select-language.hook';
import { useLanguageIndex } from '../hooks/language-index.hook';
import {
  LanguageDescriptor,
  LanguageIndexType
} from '../hooks/language-index-context.hook';

export function formatLanguageLabel({ language }: LanguageDescriptor) {
  const codes = `[${[language.displayCode, language.languageCode]
    .filter((c) => !!c)
    .join(', ')}]`;
  return `${language.name} ${codes}`;
}

export interface LanguageSelectProps {
  value: string | null;
  onChange: (val: string | null) => void;
  filter?: (tag: LanguageDescriptor) => boolean;
  label?: string;
}

export function determineContextLanguages(
  index: LanguageIndexType,
  input: string
) {
  const ietfTag = [parseIETFTag(input)];
  if (ietfTag[0] === undefined) {
    const parts = input.split('-');
    if (parts.length > 1) {
      ietfTag[0] = parseIETFTag(parts.slice(0, -1).join('-'));
      if (ietfTag[0] !== undefined) {
        const prefix = parts.slice(-1)[0];

        return Object.values(index.descriptors).filter(
          (d) =>
            includes(ietfTag[0], d.specification) &&
            (prefix === '' ||
              (d.specification.region !== undefined &&
                d.specification.region.code.startsWith(prefix.toUpperCase())))
        );
      }
    }
  }
  const parts = input.toLowerCase().trim().split('-');
  const [langPart, ...otherParts] = parts;
  const langDescriptor = index.codeMap[langPart];
  const endPart = otherParts.pop();
  if (!endPart || !langDescriptor) {
    return [];
  }
  const possibleCountries = Object.keys(CountryCodeMap).filter((key) =>
    key.toLowerCase().startsWith(endPart)
  );
  return Object.values(index.descriptors).filter(
    (d) =>
      d.specification &&
      d.specification.language.code === langDescriptor.language.languageCode &&
      possibleCountries.includes(d.specification.region?.code ?? '')
  );
}

export function LanguageSelect({
  value,
  onChange,
  filter = () => true,
  label
}: LanguageSelectProps) {
  const languageIndex = useLanguageIndex();
  const selectedLanguage = useSelectLanguage(value);
  const selectedDescriptor = selectedLanguage
    ? languageIndex.descriptors[selectedLanguage.id]
    : null;

  const [inputValue, setInputValue] = React.useState('');

  const filterOptions = (
    options: LanguageDescriptor[],
    state: FilterOptionsState<LanguageDescriptor>
  ): LanguageDescriptor[] => {
    const normalizedInput = state.inputValue.trim().toLowerCase();
    if (normalizedInput === '') {
      return options;
    }
    const prefixCodeMatches = Object.keys(languageIndex.codeMap)
      .filter((c) => c.startsWith(normalizedInput))
      .sort((a, b) => a.length - b.length);
    const semanticMatches = determineContextLanguages(
      languageIndex,
      normalizedInput
    );
    const nameMatches = Object.values(languageIndex.descriptors).filter((d) =>
      d.language.name.toLowerCase().includes(normalizedInput)
    );

    return _.uniqBy(
      [
        ...prefixCodeMatches.map(
          (p) => languageIndex.descriptors[languageIndex.codeMap[p].language.id]
        ),
        ...semanticMatches,
        ...nameMatches
      ],
      (d) => d.language.id
    );
  };
  return (
    <Autocomplete
      value={selectedDescriptor}
      fullWidth
      onChange={(c, newValue) => onChange(newValue?.language?.id ?? null)}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={Object.values(languageIndex.descriptors).filter((desc) =>
        filter(desc)
      )}
      autoHighlight
      clearOnEscape
      blurOnSelect
      getOptionLabel={(option) => formatLanguageLabel(option)}
      filterOptions={filterOptions}
      renderOption={(option) => {
        // eslint-disable-next-line no-nested-ternary
        const TypeIcon = option.specification.language ? (
          option.specification.region ? (
            <span>{option.specification.region.flag}</span>
          ) : (
            <LanguageIcon />
          )
        ) : (
          <CheckBoxOutlineBlankIcon />
        );

        return (
          <>
            {TypeIcon}
            <TranslateIcon
              style={{
                visibility: option.specification.script ? 'visible' : 'hidden'
              }}
            />
            {formatLanguageLabel(option)}
          </>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
        />
      )}
    />
  );
}
