import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { App } from './App';
import { store } from './redux/store';
import { ErrorBoundary } from './features/error/ErrorBoundary';
/* import { Log } from './logger/logger';  */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* window.onerror = function onerror(msg, src, lineno, colno, error) {
  Log.error(error?.message || 'window.onerror', 'window.onerror');
}; */

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <React.StrictMode>
      <ErrorBoundary context="global">
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>
  </DndProvider>,
  document.getElementById('root')
);
