import React, { PropsWithChildren } from 'react';
import {
  GroupOfferPositionDTO,
  ProjectOfferDTO,
  ScalarOfferPositionDTO
} from 'model';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  Typography,
  withStyles
} from '@material-ui/core';
import {
  calculatePositionTotal,
  PositionsHeaderCell
} from './OfferPositionsForm';
import { usePriceUnit } from '../../../../prices/hooks/price-unit.hook';
import { OfferTotalSumDisplay } from './OfferTotalSum';
import { MoneyNumberFormat } from '../../../../clients/components/prices/FormikPriceInput';

const PositionCell = withStyles({
  root: {
    borderBottom: '1px dashed',
    height: 'unset',
    padding: '2px 5px'
  }
})(TableCell);

function BasePositionRow({
  row,
  index,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  positionAmount,
  parentIndex,
  style,
  children,
  currency
}: PropsWithChildren<{
  row: GroupOfferPositionDTO | ScalarOfferPositionDTO;
  index: number;
  parentIndex?: number;
  positionAmount: number;
  style?: TableRowProps['style'];
  currency: string;
}>) {
  const totalPrice = calculatePositionTotal(row);
  // const [, currencyMeta] = useTypedField(ProjectOfferView.path.currency);
  /*  const currencyCode = currencyMeta.value ?? DefaultCurrency.code; */
  return (
    <TableRow style={{ verticalAlign: 'top', ...style }}>
      {parentIndex === undefined ? (
        <PositionCell
          size="small"
          style={{
            paddingRight: '4px'
          }}
        >
          {`${index + 1}`}
        </PositionCell>
      ) : (
        <>
          <PositionCell style={{ borderBottom: 'none' }} />
          <PositionCell size="small" style={{}}>{`${parentIndex + 1}.${
            index + 1
          }`}</PositionCell>
        </>
      )}
      <PositionCell size="small">
        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{row.name}</span>
        {row.description && (
          <>
            <br />
            <span style={{ fontSize: '11px' }}>{row.description}</span>
          </>
        )}
      </PositionCell>
      {children}
      <PositionCell align="right">
        <span style={{ fontSize: '14px' }}>
          {MoneyNumberFormat.format(totalPrice)} {currency}
        </span>
      </PositionCell>
    </TableRow>
  );
}

export function GroupPositionRow({
  row,
  index,
  positionAmount,
  currency
}: {
  row: GroupOfferPositionDTO;
  index: number;
  positionAmount: number;
  currency: string;
}) {
  return (
    <>
      <BasePositionRow
        currency={currency}
        row={row}
        index={index}
        positionAmount={positionAmount}
      >
        <PositionCell />
        <PositionCell />
        <PositionCell />
      </BasePositionRow>
      {row.positions.map((sp, spi) => (
        <ScalarPositionRow
          currency={currency}
          row={sp}
          parentIndex={index}
          index={spi}
          positionAmount={row.positions.length}
        />
      ))}
    </>
  );
}

export function ScalarPositionRow({
  row,
  parentIndex,
  index,
  positionAmount,
  currency
}: {
  row: ScalarOfferPositionDTO;
  parentIndex?: number;
  index: number;
  positionAmount: number;
  currency: string;
}) {
  const { priceUnit } = usePriceUnit(row.priceUnitId);
  return (
    <BasePositionRow
      currency={currency}
      style={{
        borderBottom:
          parentIndex !== undefined ? undefined : '1px solid #c5c7cd',
        marginLeft: parentIndex !== undefined ? '2em' : undefined,
        color: parentIndex !== undefined ? 'grey' : undefined
      }}
      row={row}
      index={index}
      positionAmount={positionAmount}
      parentIndex={parentIndex}
    >
      <PositionCell align="right">
        <span style={{ fontSize: '14px' }}>{row.amount}</span>
        {row.amountDescription && (
          <>
            <br />
            <span style={{ fontSize: '11px' }}>{row.amountDescription}</span>
          </>
        )}
      </PositionCell>
      <PositionCell align="right">
        {priceUnit ? (
          <span style={{ fontSize: '14px' }}>{priceUnit.name}</span>
        ) : (
          <span
            style={{
              fontSize: '14px',
              fontStyle: 'italic'
            }}
          >
            unit
          </span>
        )}
      </PositionCell>
      <PositionCell align="right">
        <span style={{ fontSize: '14px' }}>
          {MoneyNumberFormat.format(row.unitPrice)} {currency}
        </span>
        {row.unitPriceDescription && (
          <>
            <br />
            <span style={{ fontSize: '11px' }}>{row.unitPriceDescription}</span>
          </>
        )}
      </PositionCell>
    </BasePositionRow>
  );
}
export function OffersPositionTable({
  offerDto,
  currency,
  total,
  taxPercentage
}: {
  offerDto: ProjectOfferDTO['positions'];
  total: number | null;
  currency: string;
  taxPercentage: number | null;
}) {
  return (
    <>
      <TableContainer>
        <Table
          id="current-offer-positions-table"
          size="small"
          style={{
            border: '1px solid #c5c7cd'
          }}
        >
          <TableHead
            style={{
              backgroundColor: '#f5f5f5'
            }}
          >
            <TableRow>
              <PositionsHeaderCell
                size="small"
                style={{
                  padding: '4px'
                }}
              >
                <Typography variant="subtitle2">Pos.</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell style={{ width: '55%' }} size="small">
                <Typography variant="subtitle2">Name</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell
                style={{ width: '12%' }}
                size="small"
                align="right"
              >
                <Typography variant="subtitle2">Amount</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell
                style={{ width: '5%' }}
                size="small"
                align="right"
              >
                <Typography variant="subtitle2">Unit</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell
                style={{ width: '12%' }}
                size="small"
                align="right"
              >
                <Typography variant="subtitle2">Price</Typography>
              </PositionsHeaderCell>
              <PositionsHeaderCell
                style={{ width: '12%' }}
                size="small"
                align="right"
              >
                <Typography variant="subtitle2">Total</Typography>
              </PositionsHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offerDto?.map((pos, index) =>
              ScalarOfferPositionDTO.isScalarPos(pos) ? (
                <ScalarPositionRow
                  index={index}
                  currency={currency}
                  positionAmount={offerDto.length}
                  row={pos}
                />
              ) : (
                <GroupPositionRow
                  index={index}
                  currency={currency}
                  positionAmount={offerDto.length}
                  row={pos}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        width="100%"
        id="current-offer-total-sum"
        justifyContent="space-between"
        marginTop="1em"
      >
        <div />
        <OfferTotalSumDisplay
          total={total}
          positions={offerDto}
          currency={currency}
          taxPercentage={taxPercentage}
        />
      </Box>
    </>
  );
}
