import React from 'react';
import { Chip } from '@material-ui/core';
import { SupplierDomain } from 'model';
import { useSupplierDomain } from '../../hooks/supplier-domain.hook';

export function DomainDisplay({ id }: { id: string }) {
  const { domain } = useSupplierDomain(id);
  return <Chip size="small" label={domain?.name} />;
}

export function DomainColumnRenderer({
  domains
}: {
  domains: SupplierDomain[];
}) {
  const [showAllDomains, setShowAllDomains] = React.useState(false);

  const displayDomains = domains.slice(0, showAllDomains ? undefined : 2);

  return (
    <>
      {displayDomains.map((domain) => (
        <Chip size="small" label={domain.name} />
      ))}
      {domains.length > 2 && !showAllDomains && (
        <Chip
          size="small"
          label="..."
          onClick={() => setShowAllDomains(true)}
        />
      )}
    </>
  );
}
