import { LargeScaleProject } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { largeScaleProjectThunk } from './large-scale-projects.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { RootState } from '../../../redux/store';

const { list, delete: deleteThunk, ...thunks } = largeScaleProjectThunk;

const largeScaleProjectsSlice = createEntitySlice<LargeScaleProject>(
  'large-scale-projects',
  [
    CustomThunk.override<LargeScaleProject>(list, {
      pending: (_, state) => {
        state.state = 'pending';
        state.listFetched = undefined;
        state.error = null;
        return state;
      },
      rejected: (_, state, action) => {
        state.state = 'idle';
        state.listFetched = false;
        state.error = (action as any).error;
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        state.listFetched = true;
        return adapter.upsertMany(state, payload as LargeScaleProject[]);
      }
    }),
    CustomThunk.override<LargeScaleProject>(
      deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
      {
        fulfilled: (adapter, state, { payload }) => {
          state.state = 'idle';
          return adapter.removeOne(state, payload as string);
        }
      }
    ),
    ...Object.values(thunks)
  ]
);
export const largeScaleProjectSelectors = createAdapterSelectors(
  'large-scale-projects',
  largeScaleProjectsSlice.adapter
);
export const selectClientLsps = (clientId: string) => (state: RootState) =>
  largeScaleProjectSelectors
    .selectAll(state)
    .filter((lsp) => lsp.client.id === clientId);

export const largeScaleProjectApi: EntityAPI<LargeScaleProject> = {
  selectors: largeScaleProjectSelectors,
  thunks: largeScaleProjectThunk
};

export default largeScaleProjectsSlice;
