import React from 'react';
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useField } from 'formik';
import {
  SupplierDomain,
  SupplierLanguageDTO,
  SupplierPatchDTO,
  SupplierQualificationDTO
} from 'model';
import { uniqBy } from 'lodash';
import { Autocomplete } from '@material-ui/lab';

import LanguageIcon from '@material-ui/icons/Language';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import TranslateIcon from '@material-ui/icons/Translate';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import * as yup from 'yup';
import { Hearing, RecordVoiceOver } from '@material-ui/icons';
import { useSupplierQualifications } from '../hooks/supplier-qualification.hook';
import { useCatTools } from '../hooks/cat-tools.hook';
import { useCardMode } from '../../../components/input/card/context';
import { useLanguages } from '../../languages/hooks/languages.hook';
import {
  determineContextLanguages,
  formatLanguageLabel
} from '../../languages/components/LanguageSelect';
import { useLanguageIndex } from '../../languages/hooks/language-index.hook';
import { LanguageDescriptor } from '../../languages/hooks/language-index-context.hook';
import { buildDTOView } from '../../../transformer/DTOViewSchema';
import { FormikEntitySelect } from '../../../components/select/FormikEntitySelect';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { supplierDomainsThunk } from '../redux/supplier-domains.thunk';
import { supplierDomainsSelectors } from '../redux/supplier-domains.slice';
import { useSupplierDomains } from '../hooks/supplier-domains.hook';
import { IETFIcon } from '../../languages/components/IETFIcon';

export const SkillsSupplierView = buildDTOView<SupplierPatchDTO>()
  .include('catTools', 'qualifications', 'domainIds', 'languages')
  .withSchema(
    yup.object().shape({
      catTools: yup.array().required().of(yup.string().min(1).required()),
      domainIds: yup.array().required().of(yup.string().min(1).required()),
      qualifications: yup
        .array()
        .required()
        .of(
          yup.object().shape({
            qualificationId: yup.string().required().min(1),
            details: yup.string().defined().notRequired().default('')
          })
        ),
      languages: yup
        .array()
        .required()
        .of(
          yup.object().shape({
            languageId: yup.string().required().min(1),
            native: yup.boolean().defined()
          })
        )
    })
  );

function SupplierCatToolsSelect() {
  const catTools = useCatTools();
  const [, meta, helpers] = useField<string[]>('catTools');
  const mode = useCardMode();
  return (
    <>
      {catTools.catTools.map((tool) => {
        return mode === 'view' ? (
          <Chip label={tool.name} />
        ) : (
          <FormControlLabel
            control={
              <Checkbox
                checked={meta.value.includes(tool.id)}
                onChange={(_, checked) =>
                  helpers.setValue(
                    checked
                      ? [
                          ...new Set([
                            ...catTools.catTools.map((t) => t.id),
                            tool.id
                          ])
                        ]
                      : meta.value.filter((t) => t !== tool.id)
                  )
                }
              />
            }
            label={tool.name}
            labelPlacement="start"
          />
        );
      })}
    </>
  );
}

function SupplierLanguageList() {
  const languages = useLanguages();
  const languageIndex = useLanguageIndex();
  const [, meta, helpers] = useField<SupplierLanguageDTO[]>('languages');
  const [inputValue, setInputValue] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(false);
  const assignmentMap = Object.fromEntries(
    languages.languages
      .filter((d) => meta.value.find((sl) => sl.languageId === d.id))
      .map((d) => [d.id, d])
  );
  const filterOptions = (
    options: LanguageDescriptor[],
    state: FilterOptionsState<LanguageDescriptor>
  ): LanguageDescriptor[] => {
    const normalizedInput = state.inputValue.trim().toLowerCase();
    if (normalizedInput === '') {
      return options;
    }
    const prefixCodeMatches = Object.keys(languageIndex.codeMap)
      .filter((c) => c.startsWith(normalizedInput))
      .sort((a, b) => a.length - b.length);
    const semanticMatches = determineContextLanguages(
      languageIndex,
      normalizedInput
    );
    const nameMatches = Object.values(languageIndex.descriptors).filter((d) =>
      d.language.name.toLowerCase().includes(normalizedInput)
    );

    return uniqBy(
      [
        ...prefixCodeMatches.map(
          (p) => languageIndex.descriptors[languageIndex.codeMap[p].language.id]
        ),
        ...semanticMatches,
        ...nameMatches
      ],
      (d) => d.language.id
    );
  };

  const mode = useCardMode();
  const editable = mode && mode !== 'view';

  if (languages.apiState !== 'idle') {
    return <CircularProgress />;
  }

  if (mode === 'view') {
    return (
      <List dense>
        {meta.value.map((sl) => {
          const { specification } = languageIndex.descriptors[sl.languageId];
          return (
            <ListItem dense>
              <span style={{ marginRight: 5 }}>
                {sl.native ? (
                  <Tooltip title="native">
                    <RecordVoiceOver color="action" />
                  </Tooltip>
                ) : (
                  <Tooltip title="non native">
                    <Hearing color="action" />
                  </Tooltip>
                )}
              </span>
              <span style={{ marginRight: 6 }}>
                <IETFIcon tag={specification} />
              </span>
              {assignmentMap[sl.languageId].name}
              {assignmentMap[sl.languageId].languageCode}
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <>
      <List dense>
        {meta.value.map((sl) => {
          const { specification } = languageIndex.descriptors[sl.languageId];
          return (
            <ListItem dense>
              <ListItemText>
                <span style={{ marginRight: 6 }}>
                  <IETFIcon tag={specification} />
                </span>
                {assignmentMap[sl.languageId].name}
                {assignmentMap[sl.languageId].languageCode}
              </ListItemText>
              <ListItemSecondaryAction>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>other</Grid>
                  <Grid item>
                    <Switch
                      disabled={!editable}
                      checked={sl.native}
                      onChange={(_, checked) => {
                        helpers.setValue(
                          meta.value.map((s) => {
                            if (s.languageId === sl.languageId) {
                              return { ...s, native: checked };
                            }
                            return s;
                          })
                        );
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item>native</Grid>
                </Grid>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      {editable && (
        <Autocomplete
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={null}
          onChange={(c, newValue) => {
            if (newValue) {
              helpers.setValue([
                ...new Set([
                  ...meta.value,
                  {
                    languageId: newValue.language.id,
                    native: false
                  }
                ])
              ]);
              setInputValue('');
              setOpen(false);
            }
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={Object.values(languageIndex.descriptors)}
          autoHighlight
          clearOnEscape
          blurOnSelect
          getOptionLabel={(option) => formatLanguageLabel(option)}
          filterOptions={filterOptions}
          renderOption={(option) => {
            // eslint-disable-next-line no-nested-ternary
            const TypeIcon = option.specification.language ? (
              option.specification.region ? (
                <span>{option.specification.region.flag}</span>
              ) : (
                <LanguageIcon />
              )
            ) : (
              <CheckBoxOutlineBlankIcon />
            );

            return (
              <>
                {TypeIcon}
                <TranslateIcon
                  style={{
                    visibility: option.specification.script
                      ? 'visible'
                      : 'hidden'
                  }}
                />
                {formatLanguageLabel(option)}
              </>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Language"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
            />
          )}
        />
      )}
    </>
  );
}
function SupplierQualificationSet() {
  const qualifications = useSupplierQualifications();
  const [, meta, helpers] =
    useField<SupplierQualificationDTO[]>('qualifications');
  const mode = useCardMode();
  const assignedQualificationsMap = Object.fromEntries(
    meta.value.map((sq) => [sq.qualificationId, sq])
  );
  return mode === 'view' ? (
    <>
      {qualifications.supplierQualifications
        .filter(
          (q) =>
            meta.value.find((sq) => sq.qualificationId === q.id) !== undefined
        )
        .map((q) => (
          <Chip label={q.name} />
        ))}
    </>
  ) : (
    <List dense>
      {qualifications.supplierQualifications.map((q) => {
        return (
          <ListItem dense>
            <ListItemIcon>
              <Checkbox
                checked={!!assignedQualificationsMap[q.id]}
                onChange={(_, checked) => {
                  if (checked) {
                    if (!assignedQualificationsMap[q.id]) {
                      helpers.setValue([
                        ...meta.value,
                        {
                          qualificationId: q.id,
                          details: ''
                        }
                      ]);
                    }
                  } else {
                    helpers.setValue(
                      meta.value.filter((sq) => sq.qualificationId !== q.id)
                    );
                  }
                }}
              />
            </ListItemIcon>
            <ListItemText>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12}>
                  <Tooltip title={q.description}>
                    <span>{q.name}</span>
                  </Tooltip>
                </Grid>
                {assignedQualificationsMap[q.id] && (
                  <Grid item xs={12}>
                    <TextField
                      value={assignedQualificationsMap[q.id].details}
                      onChange={(e) => {
                        helpers.setValue(
                          meta.value.map((sq) => {
                            if (sq.qualificationId === q.id) {
                              return { ...sq, details: e.target.value };
                            }
                            return sq;
                          })
                        );
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
}

export function SupplierDomainsView() {
  const mode = useCardMode();
  const [, meta] = useField<string[]>('domainIds');

  const { supplierDomains } = useSupplierDomains();

  const domainsOfSupplier = supplierDomains.filter((d) =>
    meta.value.some((id) => d.id === id)
  );

  return mode === 'view' ? (
    <>
      {domainsOfSupplier.map((d) => (
        <Chip label={d.name} />
      ))}
    </>
  ) : (
    <FormikEntitySelect
      entityApi={
        {
          selectors: supplierDomainsSelectors,
          thunks: supplierDomainsThunk
        } as EntityAPI<SupplierDomain>
      }
      textFieldProps={{ label: 'Domains' }}
      optionLabel={(d) => d.name}
      name="domainIds"
      multiple
    />
  );
}
export function SupplierSkillsForm() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography>Languages</Typography>
      </Grid>
      <Grid item xs={12}>
        <SupplierLanguageList />
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={3}>
          <Typography>Qualifications</Typography>
        </Grid>
        <Grid item xs={9}>
          <SupplierQualificationSet />
        </Grid>
        <Grid item xs={3}>
          <Typography>Domains</Typography>
        </Grid>
        <Grid item xs={9}>
          <SupplierDomainsView />
        </Grid>
        <Grid item xs={3}>
          <Typography>CAT Tools</Typography>
        </Grid>
        <Grid item xs={9}>
          <SupplierCatToolsSelect />
        </Grid>
      </Grid>
    </Grid>
  );
}
