"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierAddress = void 0;
const typeorm_1 = require("typeorm");
const lodash_1 = require("lodash");
const Address_1 = require("../../shared/entity/Address");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("./Supplier");
const CircularReference_1 = require("../../serialization/CircularReference");
let SupplierAddress = class SupplierAddress extends Address_1.Address {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, (0, lodash_1.omit)(init, 'country'));
            if (init['countryCode']) {
                this.country = init['countryCode'];
            }
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier, (supplier) => supplier.addresses),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", Supplier_1.Supplier)
], SupplierAddress.prototype, "supplier", void 0);
__decorate([
    (0, typeorm_1.RelationId)((add) => add.supplier),
    __metadata("design:type", String)
], SupplierAddress.prototype, "supplierId", void 0);
SupplierAddress = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], SupplierAddress);
exports.SupplierAddress = SupplierAddress;
