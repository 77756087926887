import React from 'react';
import { shallowEqual } from 'react-redux';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { LargeScaleProject } from 'model';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/redux.hooks';
import {
  largeScaleProjectSelectors,
  selectClientLsps
} from '../../../projects/redux/large-scale-projects.slice';
import { largeScaleProjectThunk } from '../../../projects/redux/large-scale-projects.thunk';
import { StyledLink } from '../../../../components/input/link/StyledLink';
import { ErrorBoundary } from '../../../error/ErrorBoundary';
import { CardWithHeader } from '../../../../components/card/cardWithHeader/CardWithHeader';

export function useClientLsps(clientId: string) {
  const dispatch = useAppDispatch();
  const clientLsps = useAppSelector(selectClientLsps(clientId), shallowEqual);
  const listClientLsps = () => dispatch(largeScaleProjectThunk.list());
  const apiState = useAppSelector(largeScaleProjectSelectors.selectState);

  React.useEffect(() => {
    listClientLsps();
  }, [clientId]);
  return { clientLsps, listClientLsps, apiState };
}

export function ClientLspRow({ lsp }: { lsp: LargeScaleProject }) {
  return (
    <TableRow>
      <TableCell component="th" scope="row" width={200}>
        <StyledLink to={`/projects/large-scale-projects/${lsp.id}`}>
          {lsp.name}
        </StyledLink>
      </TableCell>
      <TableCell>{lsp.description}</TableCell>
      <TableCell align="right">
        {lsp.supplierAssignments.reduce(
          (acc, cur) => acc + Number(cur.weeklyHours),
          0
        )}
      </TableCell>
    </TableRow>
  );
}

export function ClientLsps({ clientId }: { clientId: string }) {
  const { t } = useTranslation();
  const { clientLsps } = useClientLsps(clientId);

  return (
    <ErrorBoundary context="ClientLsps">
      {clientLsps.length > 0 && (
        <CardWithHeader
          title="Large Scale Projects"
          cardProps={{ style: { width: '50%', marginBottom: 20 } }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontWeight: 'bold' }}
                    >
                      {t('Project Name')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontWeight: 'bold' }}
                    >
                      {t('Description')}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontWeight: 'bold' }}
                    >
                      {t('Weekly Hours')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientLsps.map((lsp) => (
                  <ClientLspRow lsp={lsp} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardWithHeader>
      )}
    </ErrorBoundary>
  );
}
