import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { useTableConfigContext } from '../context/DataTableContext';
import { TablePaginationState } from './pagination.context';

export function useRawPaginateData<T extends BaseTableRow>(
  dataIds: T['id'][],
  paginationState: TablePaginationState
) {
  return React.useMemo(() => {
    const { rowsPerPage, page } = paginationState;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, dataIds.length - page * rowsPerPage);

    const displayedData = dataIds.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );

    return [displayedData, emptyRows] as const;
  }, [paginationState, dataIds]);
}

export function usePaginateData<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(data: T[]) {
  const {
    tablePagination: { paginationState }
  } = useTableConfigContext<T, C>();
  return useRawPaginateData<T>(data as any, paginationState);
}
