import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FilterListIcon from '@material-ui/icons/FilterList';
import TableCell from '@material-ui/core/TableCell';
import { BaseTableRow, DataCellMap } from './TableDataCell';
import { ColumnKey } from './types';
import { useTableColumn } from './context/table-column.hook';
import { DraggableTableCell } from './layout/DraggableTableCell';
import { ColumnSortLabel } from './sort/ColumnSortLabel';
import { ColumnHeaderFilter } from './filter/ColumnHeaderFilter';
import { useTableFilterControl } from './filter/column-filter-control.hook';

export interface TableHeaderCellProps<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  column: ColumnKey<T, C>;
  // eslint-disable-next-line react/no-unused-prop-types
  index: number;
  uiMode: 'widget' | 'standalone';
}
/* const useStyles = makeStyles({
  absoluteFilter: {
    '&:not(:focus-within)': {
      display: 'none'
    },
    '&:focus-within': {
      position: 'absolute',
      width: '35vw',
      backgroundColor: 'white'
    }
  }
}); */
export function TableHeaderCell<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>({ column: columnKey, uiMode }: TableHeaderCellProps<T, C>) {
  const column = useTableColumn<T, C>(columnKey);
  const { active, setActive } = useTableFilterControl<T, C>(columnKey);
  const { t } = useTranslation();

  return (
    <>
      <DraggableTableCell<T, C>
        column={columnKey}
        padding={uiMode === 'widget' ? 'none' : undefined}
        style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
      >
        <Box
          style={uiMode === 'widget' ? { padding: '1px' } : undefined}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="body1"
            noWrap
            style={{
              fontWeight: 'bold',
              fontSize: uiMode === 'widget' ? '0.85em' : undefined
            }}
          >
            <ColumnSortLabel<T, C> column={columnKey} multisortEnabled>
              {t(column.label)}
            </ColumnSortLabel>
          </Typography>
          {column.filterable && (
            <IconButton size="small" disabled={uiMode === 'widget'}>
              <FilterListIcon
                color={active ? 'primary' : 'inherit'}
                fontSize="small"
                onClick={() => setActive(!active)}
              />
            </IconButton>
          )}
        </Box>
      </DraggableTableCell>
    </>
  );
}

export function TableHeaderFilterCell<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>({ column: columnKey, uiMode }: TableHeaderCellProps<T, C>) {
  const column = useTableColumn<T, C>(columnKey);
  const { active } = useTableFilterControl<T, C>(columnKey);
  const [filterFocus, setFilterFocus] = React.useState(false);
  return (
    <TableCell
      padding={uiMode === 'widget' ? 'none' : undefined}
      style={{
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        verticalAlign: 'top'
      }}
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-start"
        flexDirection="row"
        width="100%"
      >
        {uiMode !== 'widget' && column.filterable && (
          <div
            key="main-filter"
            style={
              filterFocus && column.filter?.overlayOnFocus !== false
                ? {
                    position: 'absolute',
                    minWidth: '20vw',
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    // padding: '15px',
                    zIndex: 9999,
                    border: '1px solid rgba(200,200,200,0.5)'
                  }
                : {
                    width: '100%'
                  }
            }
            onFocus={(event) => {
              setFilterFocus(true);
              event.preventDefault();
            }}
            onBlur={() => setFilterFocus(false)}
          >
            <ColumnHeaderFilter columnKey={columnKey} />
          </div>
        )}
        {uiMode !== 'widget' &&
          column.filterable &&
          column.filter?.overlayOnFocus !== false &&
          active &&
          filterFocus && (
            <div key="placeholder-filter" style={{ display: 'hidden' }}>
              {/* <ColumnHeaderFilter columnKey={columnKey} /> */}
            </div>
          )}
      </Box>
    </TableCell>
  );
}
