import React from 'react';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { logoutThunk } from '../redux/actions/logout.thunk';
import { StyledButton } from '../../../components/input/button/StyledButton';

export function LogoutButton() {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(logoutThunk());
  };

  return (
    <StyledButton variant="contained" color="primary" onClick={handleLogout}>
      logout
    </StyledButton>
  );
}
