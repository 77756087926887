import { LargeScaleProject, LargeScaleProjectDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const largeScaleProjectThunk = createFeatureThunks<
  LargeScaleProject,
  LargeScaleProjectDTO,
  LargeScaleProjectDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/projects/large-scale-project${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'large-scale-projects'
});
