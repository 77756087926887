import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { FormikTextField } from '../../../components/input/FormikTextField';
import { LoadingButton } from '../../../components/input/button/LoadingButton';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  }
}));

const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email('must be email').required('required')
});

const initialState = { email: '' };

interface ForgotPasswordFormProps {
  onSubmit: (email: string) => Promise<void>;
}

export function ForgotPasswordForm({ onSubmit }: ForgotPasswordFormProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialState}
      validationSchema={forgotPasswordSchema}
      onSubmit={(values) => onSubmit(values.email)}
    >
      {({ submitForm }) => (
        <Form className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextField
                name="email"
                variant="outlined"
                label={t('Email')}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                // loading={authState === 'login-pending'}
                onClick={submitForm}
              >
                {t('Retrieve Account')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
