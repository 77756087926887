import React from 'react';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { useProjectVariations } from '../hooks/project-variations.hook';
import { ProjectVariationTable } from '../components/ProjectVariationTable';
import { ProjectVariationFormDialog } from '../components/ProjectVariationFormDialog';

export function ProjectVariationScreen() {
  const { entities, apiState, listEntities } = useProjectVariations();
  const { type, reference } = useEditorLocation();

  return (
    <>
      {type === 'edit' && reference !== undefined && (
        <ProjectVariationFormDialog stepId={reference} />
      )}
      <ProjectVariationTable
        projectVariations={entities}
        onRefresh={listEntities}
        apiState={apiState}
      />
    </>
  );
}
