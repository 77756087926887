import React from 'react';
import { UploadFile } from 'model';
import { FileUploadState } from './types';
import { apiClient } from '../../api/apiClient';
import { Log } from '../../logger/logger';

interface SeafileUploadConfig {
  onFileChange?: (
    attachedFiles: (UploadFile & { grant: string })[],
    done: boolean
  ) => void;
}

let uploadInterceptor: (() => void) | undefined;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
document.failSeafileUploads = () => {
  uploadInterceptor = () => {
    throw new Error('test error for upload');
  };
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
document.doSealfileUploads = () => {
  uploadInterceptor = undefined;
};

export function useSeafileUpload(config: SeafileUploadConfig) {
  const [uploadStates, setUploadStates] = React.useState<
    Record<string, FileUploadState>
  >({});

  const uploadFiles = (files: File[]) => {
    const additionalStates = Object.fromEntries(
      files.map((cf) => {
        const fid = crypto.randomUUID();
        return [
          fid,
          { id: fid, file: cf, state: 'pending' as const, progress: 0 }
        ];
      })
    );

    setUploadStates((state) => {
      const newState = { ...state, ...additionalStates };
      const attachedFiles = Object.values(newState)
        .map((fs) => (fs.state === 'uploaded' ? fs.uploadFile : undefined))
        .filter((v) => v !== undefined) as (UploadFile & { grant: string })[];
      if (config.onFileChange)
        config.onFileChange(
          attachedFiles,
          attachedFiles.length >= Object.keys(newState).length
        );
      return newState;
    });
    const performUpload = (
      uploadFileList: (typeof additionalStates)[keyof typeof additionalStates][]
    ) => {
      uploadFileList.forEach(async (fs) => {
        const formData = new FormData();
        formData.append('file', fs.file);
        formData.append('uploadType', 'seafile-upload');
        formData.append('subPath', 'cmments');
        try {
          if (uploadInterceptor) {
            const doIntercept = uploadInterceptor;
            await (async () => {
              doIntercept();
            })();
          }
          const uploadFile = (
            await apiClient.post<UploadFile & { grant: string }>(
              '/files',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (e) => {
                  const progress = e.loaded / (e.total ?? 1);
                  setUploadStates((states) => ({
                    ...states,
                    ...(states[fs.id] !== undefined
                      ? { [fs.id]: { ...fs, progress } }
                      : {})
                  }));
                }
              }
            )
          ).data;

          setUploadStates((states) => {
            const newState = {
              ...states,
              ...(states[fs.id] !== undefined
                ? {
                    [fs.id]: {
                      id: fs.id,
                      file: fs.file,
                      remove: () => {
                        setUploadStates((subStates) => {
                          const newSubStates = { ...subStates };
                          delete newSubStates[fs.id];
                          const attachedFiles = Object.values(newSubStates)
                            .map((fs1) =>
                              fs1.state === 'uploaded'
                                ? fs1.uploadFile
                                : undefined
                            )
                            .filter((v) => v !== undefined) as (UploadFile & {
                            grant: string;
                          })[];

                          if (config.onFileChange)
                            config.onFileChange(
                              attachedFiles,
                              attachedFiles.length >=
                                Object.keys(newSubStates).length
                            );
                          return newSubStates;
                        });
                      },
                      state: 'uploaded' as const,
                      uploadFile
                    }
                  }
                : {})
            };

            const attachedFiles = Object.values(newState)
              .map((fs1) =>
                fs1.state === 'uploaded' ? fs1.uploadFile : undefined
              )
              .filter((v) => v !== undefined) as (UploadFile & {
              grant: string;
            })[];

            if (config.onFileChange)
              config.onFileChange(
                attachedFiles,
                attachedFiles.length >= Object.keys(newState).length
              );

            return newState;
          });
        } catch (e) {
          setUploadStates((states) => {
            const newState = {
              ...states,
              ...(states[fs.id] !== undefined
                ? {
                    [fs.id]: {
                      id: fs.id,
                      file: fs.file,
                      state: 'error' as const,
                      error: e,
                      retry: () => {
                        Log.error(
                          `Retry file upload button for comments`,
                          'file upload'
                        );
                        performUpload([
                          {
                            id: fs.id,
                            file: fs.file,
                            state: 'pending' as const,
                            progress: 0
                          }
                        ]);
                      },
                      remove: () => {
                        setUploadStates((subStates) => {
                          const newSubStates = { ...subStates };
                          delete newSubStates[fs.id];
                          const attachedFiles = Object.values(newSubStates)
                            .map((fs1) =>
                              fs1.state === 'uploaded'
                                ? fs1.uploadFile
                                : undefined
                            )
                            .filter((v) => v !== undefined) as (UploadFile & {
                            grant: string;
                          })[];

                          if (config.onFileChange)
                            config.onFileChange(
                              attachedFiles,
                              attachedFiles.length >=
                                Object.keys(newSubStates).length
                            );
                          return newSubStates;
                        });
                      }
                    }
                  }
                : {})
            };
            const attachedFiles = Object.values(newState)
              .map((fs1) =>
                fs1.state === 'uploaded' ? fs1.uploadFile : undefined
              )
              .filter((v) => v !== undefined) as (UploadFile & {
              grant: string;
            })[];
            if (config.onFileChange)
              config.onFileChange(
                attachedFiles,
                attachedFiles.length >= Object.keys(newState).length
              );
            return newState;
          });
        }
      });
    };
    performUpload(Object.values(additionalStates));
  };

  return { uploadStates, uploadFiles };
}
