import { CreateClientDTO, Client } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const createClientThunk = createErrorHandlingThunk<
  Client,
  CreateClientDTO
>('client/create', async (args) => {
  const result = await apiClient.post<Client>('/client', args);

  const clientResult = await apiClient.get<Client>(`/client/${result.data.id}`);
  return clientResult.data;
});
