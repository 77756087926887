import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { ColumnFilter } from './column-filter.types';

export type CellFilterType<
  T extends BaseTableRow,
  C extends DataCellMap<T>,
  K extends keyof C
> = C[K]['renderer']['filter'] extends ColumnFilter<T, any, infer S>
  ? {
      filterState: S;
      active: boolean;
    }
  : undefined;

export type FilterStateMap<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> = Record<keyof C, CellFilterType<T, C, keyof C>>;

export interface TableFilterContextData<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  filterState: FilterStateMap<T, C>;
  setFilterState: (val: FilterStateMap<T, C>) => void;
}

function createInitialFilterStates<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(tableCells: C): FilterStateMap<T, C> {
  return Object.fromEntries(
    Object.entries(tableCells).map(([columnId, cell]) => [
      columnId,
      cell?.renderer?.filter !== undefined
        ? { filterState: cell?.renderer?.filter?.initialState, active: false }
        : undefined
    ])
  ) as FilterStateMap<T, C>;
}

export function useTableFilterContext<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(
  tableCells: C,
  initialStateMap?: Partial<FilterStateMap<T, C>>
): TableFilterContextData<T, C> {
  const [filterState, setFilterState] = React.useState<FilterStateMap<T, C>>({
    ...createInitialFilterStates(tableCells),
    ...initialStateMap
  });
  return React.useMemo(
    () => ({ filterState, setFilterState }),
    [filterState, setFilterState]
  );
}
