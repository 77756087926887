import { Client, ClientAddress, CountryCodeMap } from 'model';
import React from 'react';
import { CountryLabel } from '../../../../components/locale/country/CountryLabel';
import PopoverPreview from '../../../../components/preview/PopoverPreview';

export function shortAddressPreview(address: ClientAddress) {
  return `${address.address?.[0] ?? ''}, ${address.zipCode ?? ''} ${
    address.city
  }, ${CountryCodeMap[address.country].name}`;
}

function AddressPreviewView({ address }: { address: ClientAddress }) {
  return (
    <div>
      {address.address.map((a) => (
        <span>
          {a}
          <br />
        </span>
      ))}
      <span>
        {address.zipCode ?? ''} {address.city}
        <br />
      </span>
      <CountryLabel country={address.country} />
      {address.comment && (
        <span>
          <br />
          Comment: {address.comment}
        </span>
      )}
    </div>
  );
}

export function AddressColumnRenderer({ client: data }: { client: Client }) {
  const invoiceAddress = data.invoiceAddressId
    ? data.addresses.find((a) => a.id === data.invoiceAddressId)
    : null;
  const headQuarterAddress = data.headerQuarterAddressId
    ? data.addresses.find((a) => a.id === data.headerQuarterAddressId)
    : null;
  return (
    <div>
      {invoiceAddress && (
        <PopoverPreview
          ready
          content={<AddressPreviewView address={invoiceAddress} />}
        >
          <b>Rechnungsadresse: </b>
          {shortAddressPreview(invoiceAddress)}
        </PopoverPreview>
      )}
      {headQuarterAddress && (
        <PopoverPreview
          ready
          content={<AddressPreviewView address={headQuarterAddress} />}
        >
          <b>Firmensitz:</b> {shortAddressPreview(headQuarterAddress)}
        </PopoverPreview>
      )}
    </div>
  );
}
