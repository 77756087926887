import React from 'react';
import {
  OrderServiceGroupTask,
  OrderServiceTask,
  OrderStepTask,
  OrderTask
} from 'model';
import { Collapse, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { usePhaseChilds } from './order-task.context';
import { ServiceGroupHeader } from './ServiceGroupHeader';
import { OrderTaskScopePhases } from './OrderTaskScopePhases';
import { ServiceLaneView } from './ServiceLaneView';
import { ServiceGroupCreator } from './ServiceGroupCreator';

function ServiceGroupTaskItems({
  tasks,
  projectId,
  groupTask
}: {
  tasks: OrderTask[];
  projectId: string;
  groupTask: OrderServiceGroupTask;
}) {
  const provSize = [0, 12, 6, 4, 3][Math.min(tasks.length, 4)];
  return (
    <Grid container>
      {tasks.map((ct) => (
        <Grid item xs={provSize as any}>
          <ServiceLaneView
            sourceLanguageId={groupTask.sourceLanguageId}
            projectId={projectId}
            laneTask={ct as OrderServiceTask}
          />
        </Grid>
      ))}
    </Grid>
  );
}
function ServiceGroupTaskView({
  task,
  projectId
}: {
  task: OrderServiceGroupTask;
  projectId: string;
}) {
  const { provision } = usePhaseChilds(task);

  const targetLanguageIds = React.useMemo(() => {
    return provision
      .filter(
        (to) =>
          to.value.taskType === 'service-task' &&
          (to.value as OrderServiceTask).targetLanguageId
      )
      .map((to) => (to.value as OrderServiceTask).targetLanguageId);
  }, [provision]);

  const [open, setOpen] = React.useState(true);
  const toggleOpen = () => setOpen((p) => !p);

  return (
    <div style={{ marginBottom: 20 }}>
      <ServiceGroupHeader
        projectId={projectId}
        task={task}
        targetLanguageIds={targetLanguageIds as string[]}
        open={open}
        toggleOpen={toggleOpen}
      />
      <Collapse in={open}>
        <div style={{ display: 'flex', paddingLeft: 40 }}>
          <OrderTaskScopePhases
            projectId={projectId}
            scope="service"
            parentTask={task}
            provisionComponent={({ tasks }) => (
              <ServiceGroupTaskItems
                tasks={tasks}
                projectId={projectId}
                groupTask={task}
              />
            )}
          />
        </div>
      </Collapse>
    </div>
  );
}
export function OrderProjectTaskList({
  tasks,
  projectId
}: {
  projectId: string;
  tasks: (OrderStepTask | OrderServiceGroupTask)[];
}) {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ display: 'flex', paddingBottom: 10 }}>
        <Typography
          variant="h5"
          color="textSecondary"
          style={{ paddingRight: '20%' }}
        >
          {t('production')}
          {'\u00A0\u00A0\u00A0'}
        </Typography>
        <ServiceGroupCreator projectId={projectId} key="serviceGroupCreator" />
      </div>
      <div style={{ paddingLeft: 20 }}>
        {tasks.map((task) => (
          <ServiceGroupTaskView
            key={task.id}
            task={task as OrderServiceGroupTask}
            projectId={projectId}
          />
        ))}
      </div>
    </>
  );
}
