import {
  Supplier,
  SupplierMinimumPriceDTO,
  SupplierPrice,
  SupplierPriceDTO
} from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const supplierPricesThunk = createFeatureThunks<
  SupplierPrice,
  SupplierPriceDTO,
  SupplierPriceDTO,
  { supplierId: string }
>({
  urlFactory: ({ supplierId, id } = { supplierId: '', id: undefined }) =>
    `/suppliers/supplier/${supplierId}/prices${
      id !== undefined ? `/${id}` : ''
    }`,
  thunkName: 'suppliers-prices'
});

export const defineMinimumSupplierPrice = createErrorHandlingThunk<
  Supplier,
  { id: string; input: SupplierMinimumPriceDTO }
>('supplier/minimum-price', async ({ id, input }) => {
  const result = await apiClient.put<Supplier>(
    `/suppliers/supplier/${id}/minimum-price`,
    input
  );
  return result.data;
});
