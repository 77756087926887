import React from 'react';
import { useTableConfigContext } from '../context/DataTableContext';

export function useTablePaginationControl() {
  const {
    tablePagination: {
      paginationState: { page, rowsPerPage },
      setPaginationState
    }
  } = useTableConfigContext();
  return {
    page,
    rowsPerPage,
    handleChangePage: (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => setPaginationState({ rowsPerPage, page: newPage }),

    handleChangeRowsPerPage: (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      return setPaginationState({
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10)
      });
    }
  };
}
