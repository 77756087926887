import React from 'react';
import { ExpenseSummary, Project, ProjectExpenseDTO, Supplier } from 'model';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import { Cancel, Check, Delete, EditOutlined } from '@material-ui/icons';
import { ErrorBoundary } from '../../error/ErrorBoundary';
import { OutgoingInvoicesCard } from './invoice/OutgoingInvoiceCard';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import {
  aggregateProjectExpensesThunk,
  bookProjectExpensesThunk,
  removeProjectExpensesThunk
} from '../redux/project-expenses.thunk';
import { expensesSelectors } from '../redux/project-expenses.slice';
import { useSupplier } from '../../suppliers/hooks/supplier.hook';
import { CardWithHeader } from '../../../components/card/cardWithHeader/CardWithHeader';
import { useViewHovered } from '../../../components/view-hovered.hook';
import { MoneyNumberFormat } from '../../clients/components/prices/FormikPriceInput';
import { SupplierLink } from '../../suppliers/components/SupplierLink';

const hourFormat = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0,
  minimumIntegerDigits: 1
});

function ProjectExpenseEditor({
  type,
  project,
  expense,
  supplier,
  hovered
}: {
  project: Project;
  type: keyof ProjectExpenseDTO;
  expense: ExpenseSummary | null;
  supplier: Supplier;
  hovered: boolean;
}) {
  const [showEditor, setShowEditor] = React.useState(false);
  const [editorInput, setEditorInput] = React.useState<number>(0);

  const dispatch = useAppDispatch();
  const currentTime = expense?.[type] ?? null;
  return (
    <Box display="flex" justifyContent="flex-end">
      {!showEditor &&
        (currentTime !== null
          ? `${hourFormat.format(currentTime / 60)}h`
          : ' ? ')}
      {showEditor && (
        <>
          <TextField
            type="number"
            style={{ width: '6em' }}
            value={editorInput}
            inputProps={{
              style: {
                textAlign: 'right'
              }
            }}
            onChange={(evt) =>
              setEditorInput(Number.parseFloat(evt.target.value))
            }
          />
          <IconButton
            size="small"
            onClick={() => {
              dispatch(
                bookProjectExpensesThunk({
                  projectId: project.id,
                  supplierId: supplier.id,
                  dto: {
                    expensedMinutes:
                      type === 'expensedMinutes'
                        ? editorInput * 60
                        : expense?.expensedMinutes ?? null,
                    plannedMinutes:
                      type === 'plannedMinutes'
                        ? editorInput * 60
                        : expense?.plannedMinutes ?? null
                  }
                })
              );
              setShowEditor(false);
              setEditorInput(0);
            }}
          >
            <Check fontSize="small" style={{ fontSize: '0.9em' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowEditor(false);
              setEditorInput(0);
            }}
            size="small"
          >
            <Cancel fontSize="small" style={{ fontSize: '0.9em' }} />
          </IconButton>
        </>
      )}
      <Box>
        {!showEditor && hovered && (
          <Box position="absolute" marginLeft="0.5em">
            <IconButton
              onClick={() => {
                setShowEditor(true);
                setEditorInput((currentTime ?? 0) / 60);
              }}
              size="small"
            >
              <EditOutlined fontSize="small" style={{ fontSize: '0.9em' }} />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}

function ProjectExpenseItem({
  summary,
  supplierId,
  project
}: {
  summary: ExpenseSummary | null;
  supplierId: string;
  project: Project;
}) {
  const { supplier } = useSupplier(supplierId);
  const dispatch = useAppDispatch();

  const viewHover = useViewHovered();

  if (!supplier) {
    return <Skeleton />;
  }

  return (
    <TableRow {...viewHover.listeners}>
      <TableCell>
        <SupplierLink value={supplier} />
      </TableCell>
      <TableCell />
      <TableCell align="right">
        <ProjectExpenseEditor
          hovered={viewHover.hovered}
          project={project}
          type="plannedMinutes"
          supplier={supplier}
          expense={summary}
        />
      </TableCell>
      <TableCell align="right">
        <ProjectExpenseEditor
          hovered={viewHover.hovered}
          project={project}
          type="expensedMinutes"
          supplier={supplier}
          expense={summary}
        />
      </TableCell>
      <TableCell />
      <TableCell align="right">
        {summary !== null && viewHover.hovered && (
          <IconButton
            onClick={() => {
              dispatch(
                removeProjectExpensesThunk({
                  projectId: project.id,
                  supplierId: supplier.id
                })
              );
            }}
            size="small"
          >
            <Delete fontSize="small" style={{ fontSize: '0.9em' }} />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}

function calculateDelta(totalInfo: ExpenseSummary | null) {
  if (
    totalInfo === null ||
    totalInfo.quoteMinutes === null ||
    totalInfo.expensedMinutes === null
  ) {
    return null;
  }
  const deltaMinutes = totalInfo.expensedMinutes - totalInfo.quoteMinutes;
  return {
    minutes: deltaMinutes,
    color: deltaMinutes > 0 ? 'red' : 'green',
    percent: (100 / totalInfo.quoteMinutes) * deltaMinutes
  };
}

function ProjectExpensesCard({ project }: { project: Project }) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const refreshExpenses = () => {
    dispatch(aggregateProjectExpensesThunk({ projectId: project.id }));
  };

  const data = useAppSelector((rS) =>
    expensesSelectors.selectById(rS, project.id)
  );

  React.useEffect(() => {
    if (!data || (data.apiState !== 'loading' && data.dataState === 'none')) {
      refreshExpenses();
    }
  }, [data]);
  if (!data || !data.expenses) {
    return <Skeleton />;
  }
  const deltaInfo = calculateDelta(data.expenses.total);
  return (
    <CardWithHeader title={t('Expenses')}>
      <Table>
        <TableHead
          style={{
            borderBottom: '2px solid'
          }}
        >
          <TableCell>{t('Supplier')}</TableCell>
          <TableCell align="right">{t('Hours (quote)')}</TableCell>
          <TableCell align="right">{t('Planned Hours')}</TableCell>
          <TableCell align="right">{t('Hours (actual)')}</TableCell>
          <TableCell align="right">{t('Delta')}</TableCell>
          <TableCell />
        </TableHead>
        <TableBody>
          {data.expenses.suppliers &&
            Object.entries(data.expenses.suppliers).map(
              ([supplierId, summary]) => (
                <ProjectExpenseItem
                  project={project}
                  summary={summary}
                  supplierId={supplierId}
                />
              )
            )}
        </TableBody>
        <TableFooter style={{ borderTop: '2px solid' }}>
          <TableCell>{t('Total')}</TableCell>
          <TableCell align="right">
            {' '}
            {`${hourFormat.format(
              (data.expenses.total?.quoteMinutes ?? 0) / 60
            )}h`}
          </TableCell>
          <TableCell align="right">
            {`${hourFormat.format(
              (data.expenses?.total?.plannedMinutes ?? 0) / 60
            )}h`}
          </TableCell>
          <TableCell align="right">
            {`${hourFormat.format(
              (data.expenses?.total?.expensedMinutes ?? 0) / 60
            )}h`}
          </TableCell>
          <TableCell align="right">
            {deltaInfo && (
              <span style={{ color: deltaInfo.color }}>
                {deltaInfo.minutes > 0 && '+'}
                {hourFormat.format(deltaInfo.minutes / 60)}h{' '}
                {Number.isFinite(deltaInfo.percent) && (
                  <>
                    ({deltaInfo.minutes > 0 && '+'}
                    {MoneyNumberFormat.format(deltaInfo.percent)}%)
                  </>
                )}
              </span>
            )}
          </TableCell>
          <TableCell />
        </TableFooter>
      </Table>
    </CardWithHeader>
  );
}

export function ProjectInvoiceTab({ project }: { project: Project }) {
  return (
    <ErrorBoundary context="ProjectInvoices">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ProjectExpensesCard project={project} />
        </Grid>
        <Grid item xs={6}>
          <OutgoingInvoicesCard
            invoices={project.outgoingInvoices}
            projectId={project.id}
          />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
}
