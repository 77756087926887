import { Client } from 'model';
import { createEntityDocument } from '../../../../components/actions/DocumentLink';
import { clientAPI } from '../../redux/clients.slice';

export const { entityDocument: clientDocument, EntityLink: ClientLink } =
  createEntityDocument(
    Client,
    'Client',
    clientAPI,
    (s) => `/clients/${s.id}`,
    (s, format = 'name' as 'name' | 'number') =>
      format === 'name' ? `${s.name}` : s.clientNumber?.number ?? ''
  );
