"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Supplier = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
const JournaledEntity_1 = require("../../JournaledEntity");
const Name_1 = require("../../shared/entity/Name");
// eslint-disable-next-line import/no-cycle
const SupplierPhone_1 = require("./SupplierPhone");
// eslint-disable-next-line import/no-cycle
const SupplierAddress_1 = require("./SupplierAddress");
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
const SupplierAttachment_1 = require("./SupplierAttachment");
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
const User_1 = require("../../user/entity/User");
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
const SupplierDomain_1 = require("./SupplierDomain");
// eslint-disable-next-line import/no-cycle
const SupplierQualification_1 = require("./SupplierQualification");
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
const SupplierLargeScaleProjectAssignment_1 = require("./SupplierLargeScaleProjectAssignment");
// eslint-disable-next-line import/no-cycle
const SupplierLanguage_1 = require("./SupplierLanguage");
// eslint-disable-next-line import/no-cycle
const SupplierService_1 = require("./SupplierService");
// eslint-disable-next-line import/no-cycle
const SupplierStatus_1 = require("./SupplierStatus");
// eslint-disable-next-line import/no-cycle
const SupplierHistory_1 = require("./SupplierHistory");
// eslint-disable-next-line import/no-cycle
const SupplierServiceStep_1 = require("./SupplierServiceStep");
// eslint-disable-next-line import/no-cycle
const SupplierPrice_1 = require("./SupplierPrice");
// eslint-disable-next-line import/no-cycle
const SupplierMinimumPrice_1 = require("./SupplierMinimumPrice");
// eslint-disable-next-line import/no-cycle
const SupplierCategory_1 = require("./SupplierCategory");
// eslint-disable-next-line import/no-cycle
const SupplierCommentAttachment_1 = require("./SupplierCommentAttachment");
let Supplier = class Supplier extends JournaledEntity_1.JournaledEntity {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.Column)(() => Name_1.Name),
    __metadata("design:type", Name_1.Name)
], Supplier.prototype, "name", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => SupplierCategory_1.SupplierCategory),
    __metadata("design:type", SupplierCategory_1.SupplierCategory)
], Supplier.prototype, "supplierCategory", void 0);
__decorate([
    (0, typeorm_1.RelationId)((s) => s.supplierCategory),
    __metadata("design:type", String)
], Supplier.prototype, "supplierCategoryId", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], Supplier.prototype, "importReference", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => User_1.User, { nullable: true }),
    (0, typeorm_1.JoinColumn)(),
    __metadata("design:type", User_1.User)
], Supplier.prototype, "user", void 0);
__decorate([
    (0, typeorm_1.RelationId)((s) => s.user),
    __metadata("design:type", String)
], Supplier.prototype, "userId", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], Supplier.prototype, "profession", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true, type: 'numeric' }),
    (0, class_transformer_1.Type)(() => Number),
    __metadata("design:type", Number)
], Supplier.prototype, "stars", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'date', nullable: true }),
    __metadata("design:type", Date)
], Supplier.prototype, "firstContact", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'varchar', array: true }),
    __metadata("design:type", Array)
], Supplier.prototype, "catTools", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], Supplier.prototype, "email", void 0);
__decorate([
    (0, typeorm_1.Column)('varchar', { array: true }),
    __metadata("design:type", Array)
], Supplier.prototype, "websites", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], Supplier.prototype, "defaultCurrency", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => SupplierStatus_1.SupplierStatus, (a) => a.supplier, {
        cascade: ['insert']
    }),
    __metadata("design:type", SupplierStatus_1.SupplierStatus)
], Supplier.prototype, "status", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SupplierPhone_1.SupplierPhone, (number) => number.supplier, {
        cascade: ['insert', 'update']
    }),
    __metadata("design:type", Array)
], Supplier.prototype, "phoneNumbers", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SupplierAddress_1.SupplierAddress, (address) => address.supplier, {
        cascade: ['insert', 'update']
    }),
    __metadata("design:type", Array)
], Supplier.prototype, "addresses", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SupplierAttachment_1.SupplierAttachment, (attachment) => attachment.supplier),
    __metadata("design:type", Array)
], Supplier.prototype, "attachments", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SupplierHistory_1.SupplierHistory, (h) => h.supplier),
    __metadata("design:type", Array)
], Supplier.prototype, "history", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SupplierQualification_1.SupplierQualification, (q) => q.supplier, {
        cascade: ['insert', 'update']
    }),
    __metadata("design:type", Array)
], Supplier.prototype, "qualifications", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SupplierLanguage_1.SupplierLanguage, (l) => l.supplier, {
        cascade: ['insert', 'update']
    }),
    __metadata("design:type", Array)
], Supplier.prototype, "languages", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SupplierService_1.SupplierService, (l) => l.supplier, {
        cascade: ['insert', 'update']
    }),
    __metadata("design:type", Array)
], Supplier.prototype, "services", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SupplierServiceStep_1.SupplierServiceStep, (l) => l.supplier, {
        cascade: ['insert', 'update']
    }),
    __metadata("design:type", Array)
], Supplier.prototype, "serviceSteps", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => SupplierMinimumPrice_1.SupplierMinimumPrice, (l) => l.supplier, { nullable: true }),
    __metadata("design:type", SupplierMinimumPrice_1.SupplierMinimumPrice)
], Supplier.prototype, "minimumPrice", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SupplierPrice_1.SupplierPrice, (l) => l.supplier),
    __metadata("design:type", Array)
], Supplier.prototype, "prices", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => SupplierCommentAttachment_1.SupplierCommentAttachment, { nullable: true }),
    (0, typeorm_1.JoinColumn)(),
    __metadata("design:type", SupplierCommentAttachment_1.SupplierCommentAttachment)
], Supplier.prototype, "priceComment", void 0);
__decorate([
    (0, typeorm_1.RelationId)((s) => s.priceComment),
    __metadata("design:type", String)
], Supplier.prototype, "priceCommentId", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => SupplierDomain_1.SupplierDomain, (domain) => domain.suppliers),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], Supplier.prototype, "domains", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SupplierLargeScaleProjectAssignment_1.SupplierLargeScaleProjectAssignment, (q) => q.supplier, {
        cascade: ['insert', 'update']
    }),
    __metadata("design:type", Array)
], Supplier.prototype, "largeScaleProjectAssignments", void 0);
Supplier = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], Supplier);
exports.Supplier = Supplier;
