import { SupplierTestType, WrapTestTypeDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const supplierTestTypesThunk = createFeatureThunks<
  SupplierTestType,
  WrapTestTypeDTO,
  WrapTestTypeDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/suppliers/test-type${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'test-types'
});
