"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.supplierPatchTransformer = exports.SupplierPatchDTO = void 0;
const class_validator_1 = require("class-validator");
const SupplierBaseDTO_1 = require("./SupplierBaseDTO");
const NameDTO_1 = require("../../shared/dto/NameDTO");
const Supplier_1 = require("../entity/Supplier");
const SupplierLanguage_1 = require("../entity/SupplierLanguage");
const SupplierLanguageDTO_1 = require("./SupplierLanguageDTO");
const SupplierAddress_1 = require("../entity/SupplierAddress");
const AddressDTO_1 = require("../../shared/dto/AddressDTO");
const SupplierLargeScaleProjectAssignment_1 = require("../entity/SupplierLargeScaleProjectAssignment");
const LargeScaleProjectAssignmentDTO_1 = require("./LargeScaleProjectAssignmentDTO");
const SupplierQualification_1 = require("../entity/SupplierQualification");
const SupplierQualificationDTO_1 = require("./SupplierQualificationDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const SupplierService_1 = require("../entity/SupplierService");
const SupplierServiceDTO_1 = require("./SupplierServiceDTO");
const ServiceLanguageConfigDTO_1 = require("./ServiceLanguageConfigDTO");
const SupplierPhone_1 = require("../entity/SupplierPhone");
const PhoneNumberDTO_1 = require("../../shared/dto/PhoneNumberDTO");
const SupplierServiceStep_1 = require("../entity/SupplierServiceStep");
const SupplierServiceStepDTO_1 = require("./SupplierServiceStepDTO");
class SupplierPatchDTO extends SupplierBaseDTO_1.SupplierBaseDTO {
}
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsDefined)(),
    __metadata("design:type", NameDTO_1.NameDTO)
], SupplierPatchDTO.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsUUID)(),
    __metadata("design:type", String)
], SupplierPatchDTO.prototype, "supplierCategoryId", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsEmail)(),
    __metadata("design:type", String)
], SupplierPatchDTO.prototype, "email", void 0);
__decorate([
    (0, class_validator_1.IsOptional)(),
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.IsString)({ each: true }),
    (0, class_validator_1.IsNotEmpty)({ each: true }),
    __metadata("design:type", Array)
], SupplierPatchDTO.prototype, "catTools", void 0);
exports.SupplierPatchDTO = SupplierPatchDTO;
exports.supplierPatchTransformer = (0, DTOTransformer_1.createDTOTransformer)(Supplier_1.Supplier, SupplierPatchDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'attachments',
    'prices',
    'minimumPrice',
    // 'priceIds',
    // 'minimumPriceId',
    'user',
    'status',
    'history',
    'importReference',
    'priceComment',
    'priceCommentId',
    'supplierCategory',
    'projects'
], {
    domains: (0, DTOTransformer_1.transformIds)('domainIds'),
    languages: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createExcludingDTOTransformer)(SupplierLanguage_1.SupplierLanguage, SupplierLanguageDTO_1.SupplierLanguageDTO, [
        'supplier',
        'language'
    ]), 'languages'),
    phoneNumbers: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createExcludingDTOTransformer)(SupplierPhone_1.SupplierPhone, PhoneNumberDTO_1.PhoneNumberDTO, [
        'supplier'
    ]), 'phoneNumbers'),
    addresses: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createDTOTransformer)(SupplierAddress_1.SupplierAddress, AddressDTO_1.AddressDTO, ['id', 'supplier', 'supplierId'], {
        country: ['countryCode', (v) => v]
    }), 'addresses'),
    largeScaleProjectAssignments: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createDTOTransformer)(SupplierLargeScaleProjectAssignment_1.SupplierLargeScaleProjectAssignment, LargeScaleProjectAssignmentDTO_1.LargeScaleProjectAssignmentDTO, ['supplier', 'largeScaleProject', 'supplierId'], {
        largeScaleProjectId: ['projectId', (v) => v]
    }), 'largeScaleProjectAssignments'),
    qualifications: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createExcludingDTOTransformer)(SupplierQualification_1.SupplierQualification, SupplierQualificationDTO_1.SupplierQualificationDTO, ['supplier', 'qualification']), 'qualifications'),
    serviceSteps: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createDTOTransformer)(SupplierServiceStep_1.SupplierServiceStep, SupplierServiceStepDTO_1.SupplierServiceStepDTO, ['supplier', 'serviceStep', 'supplierId'], {}), 'serviceSteps'),
    services: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createDTOTransformer)(SupplierService_1.SupplierService, SupplierServiceDTO_1.SupplierServiceDTO, ['supplier', 'serviceClass', 'supplierId'], {
        languages: [
            'languages',
            (v) => v !== null
                ? v.map((c) => new ServiceLanguageConfigDTO_1.ServiceLanguageConfigDTO(c))
                : []
        ]
    }), 'services')
});
