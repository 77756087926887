import React from 'react';
import { Switch as MuiSwitch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../theme/KontextorThemeProvider';

export function ThemeToggle() {
  const { setTheme, state } = React.useContext(ThemeContext);

  const { t } = useTranslation();
  return (
    <div>
      {t('dark')}
      <MuiSwitch
        checked={state === 'light'}
        onChange={() =>
          setTheme && setTheme(state === 'dark' ? 'light' : 'dark')
        }
      />
      {t('light')}
    </div>
  );
}
