import { AddressDTO, Client } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const updateAddressThunk = createErrorHandlingThunk<
  Client,
  { clientId: string; addressId: string; dto: AddressDTO }
>('client/address/update', async (args) => {
  await apiClient.put<Client>(
    `/client/${args.clientId}/addresses/${args.addressId}`,
    args.dto
  );

  const clientResult = await apiClient.get<Client>(`/client/${args.clientId}`);
  return clientResult.data;
});
