import { BaseEntity } from 'model';
import React from 'react';
import { BaseTableRow } from '../TableDataCell';
import { ColumnFilter } from './column-filter.types';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { EntityReferenceSelect } from '../../select/EntityReferenceSelect';

export function MultipleEntityColumnFilter<
  T extends BaseTableRow,
  E extends BaseEntity,
  V
>(
  entityAPI: EntityAPI<E>,
  optionsLabel: (data: E) => string,
  entityMapper: (v: V) => string[],
  filter?: (e: E) => boolean,
  overlayOnFocus?: boolean
): ColumnFilter<T, V, string[]> {
  return {
    overlayOnFocus,
    initialState: [],
    createFilter: (cell, state) => {
      return state.length < 1
        ? () => true
        : (value: V) => {
            const dataVals = entityMapper(value);
            return dataVals.length > 0
              ? state.some((sI) => dataVals.some((i) => i === sI))
              : false;
          };
    },
    // eslint-disable-next-line react/prop-types
    component: ({ filterState, onFilterChange }) => (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <EntityReferenceSelect<E, E['id'][]>
        multiple
        filter={filter}
        currentValue={filterState as E['id'][]}
        entityApi={entityAPI}
        optionLabel={optionsLabel}
        selectValue={(v) => onFilterChange(v)}
      />
    ),
    isEffective: (cell, state) => state !== undefined
  };
}
