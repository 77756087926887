import React from 'react';
import { PropertyPath, useTypedField } from '../../transformer/DTOViewSchema';
import { LanguageSelect } from '../../features/languages/components/LanguageSelect';
import { MultipleLanguageSelect } from '../../features/languages/components/MultipleLanguageSelect';

interface FormikLanguageSelectProps {
  name: string | PropertyPath<any, string | null>;
  label: string;
}
interface FormikMultipleLanguageSelectProps {
  name: string | PropertyPath<any, string[]>;
  label: string;
}

export function FormikLanguageSelect(props: FormikLanguageSelectProps) {
  const [, meta, helpers] = useTypedField(props.name);

  return (
    <LanguageSelect
      label={props.label}
      value={meta.value}
      onChange={helpers.setValue}
    />
  );
}

export function FormikMultipleLanguageSelect(
  props: FormikMultipleLanguageSelectProps
) {
  const [, meta, helpers] = useTypedField(props.name);
  return (
    <MultipleLanguageSelect
      label={props.label}
      value={meta.value}
      onChange={helpers.setValue}
    />
  );
}
