import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { suppliersSelectors } from '../redux/suppliers.slice';
import { suppliersThunk } from '../redux/suppliers.thunk';

export function useSuppliers() {
  const dispatch = useAppDispatch();

  const suppliers = useAppSelector(suppliersSelectors.selectAll);

  const apiState = useAppSelector(suppliersSelectors.selectState);

  const listFetched = useAppSelector(suppliersSelectors.selectListFetched);

  const listSuppliers = () => dispatch(suppliersThunk.list());

  React.useEffect(() => {
    if (apiState === 'fresh' || listFetched === false) {
      listSuppliers();
    }
  }, [apiState, listFetched]);

  return { suppliers, apiState, listSuppliers, listFetched };
}
