import React from 'react';
import { useTestTypes } from '../hooks/test-types.hook';
import { SupplierTestTypesTable } from '../components/SupplierTestTypesTable';

export function SupplierTestTypesScreen() {
  const { entities, apiState, listEntities } = useTestTypes();
  return (
    <SupplierTestTypesTable
      supplierTestTypes={entities}
      onRefresh={listEntities}
      apiState={apiState}
    />
  );
}
