import { Divider, Grid, IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { SupplierLanguage, SupplierServiceDTO } from 'model';
import { useTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { createStyles } from '@material-ui/core/styles';
import { FormikEntitySelect } from '../../../../components/select/FormikEntitySelect';
import { serviceClassAPI } from '../../../services/redux/service-classes.slice';
import { ServiceLanguagesSelect } from '../../../services/components/serviceLanguages/ServiceLanguagesSelect';

import {
  PropertyPath,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { useServiceClass } from '../../../services/hooks/service-class.hook';
import { FormikTextField } from '../../../../components/input/FormikTextField';

const useStyles = makeStyles(() =>
  createStyles({
    serviceClassTextField: {}
  })
);

interface SupplierServiceFormElementProps {
  path: PropertyPath<any, SupplierServiceDTO>;
  onRemove: () => void;
  languages: SupplierLanguage[];
}

export function SupplierServiceFormElement({
  path,
  onRemove,
  languages
}: SupplierServiceFormElementProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [, meta, helpers] = useTypedField(path);

  const { serviceClass } = useServiceClass(meta.value.serviceClassId);

  return (
    <>
      <Grid item xs={12} style={{ display: 'flex' }}>
        <Grid container spacing={1} style={{ paddingRight: 20 }}>
          <Grid item xs={12}>
            <FormikEntitySelect
              textFieldProps={{
                label: t('Service Class'),
                classes: {
                  root: classes.serviceClassTextField
                }
              }}
              entityApi={serviceClassAPI}
              optionLabel={(sc) => sc.name}
              name={path.serviceClassId}
            />
          </Grid>
          <Grid item xs={12}>
            {meta.value.languages.map((lang, index) => {
              return serviceClass ? (
                <div style={{ marginTop: 10 }}>
                  <ServiceLanguagesSelect
                    languages={languages}
                    path={path.languages.at(index)}
                    classType={serviceClass.type}
                    withLabel={index === 0}
                  />
                </div>
              ) : (
                <Skeleton />
              );
            })}
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'end' }}>
            {serviceClass && (
              <IconButton
                size="small"
                title={t('Add Service-Language')}
                onClick={() =>
                  helpers.setValue({
                    ...meta.value,
                    languages: [
                      ...meta.value.languages,
                      {
                        sourceLanguage: null,
                        targetLanguage: null as unknown as string
                      }
                    ]
                  })
                }
              >
                <Add fontSize="small" />
              </IconButton>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'inline-flex', alignItems: 'center' }}
          >
            <FormikTextField
              variant="outlined"
              multiline
              rows={3}
              name={path.comment}
              label={t('Comment')}
            />
          </Grid>
        </Grid>
        <div>
          <IconButton size="small" onClick={onRemove}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />
      </Grid>
    </>
  );
}
