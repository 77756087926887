"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clientAddressDataParser = exports.ClientAddressImportData = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const lodash_1 = require("lodash");
const IsNullable_1 = require("../../transformation/IsNullable");
const CountryCodeMatcher_1 = require("../../shared/decorators/CountryCodeMatcher");
const csv_columns_descriptor_1 = require("../../csv/csv-columns.descriptor");
const column_descriptor_1 = require("../../csv/column-descriptor");
const Regions_1 = require("../../shared/constants/Regions");
class ClientAddressImportData {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.Matches)(/^[0-9]{5}$/),
    __metadata("design:type", String)
], ClientAddressImportData.prototype, "clientNumber", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", String)
], ClientAddressImportData.prototype, "street", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", String)
], ClientAddressImportData.prototype, "houseNumber", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", String)
], ClientAddressImportData.prototype, "zipCode", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], ClientAddressImportData.prototype, "city", void 0);
__decorate([
    (0, CountryCodeMatcher_1.CountryCode)(),
    __metadata("design:type", String)
], ClientAddressImportData.prototype, "country", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], ClientAddressImportData.prototype, "headQuarter", void 0);
__decorate([
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], ClientAddressImportData.prototype, "invoice", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", String)
], ClientAddressImportData.prototype, "comment", void 0);
exports.ClientAddressImportData = ClientAddressImportData;
exports.clientAddressDataParser = csv_columns_descriptor_1.CSVColumnBuilder.create()
    .append({
    clientNumber: column_descriptor_1.ColumnDescriptor.create().withValidation((d) => /^[0-9]{5}$/.test(d))
})
    .skip()
    .append({
    street: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
    houseNumber: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
    zipCode: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
    city: column_descriptor_1.ColumnDescriptor.create().withValidation((c) => c !== ''),
    country: column_descriptor_1.ColumnDescriptor.create()
        .toCase('upper')
        .withValidation((c) => Regions_1.CountryCodeMap[c] !== undefined),
    headQuarter: column_descriptor_1.ColumnDescriptor.create()
        .toCase('lower')
        .valueMap({ ja: true, nein: false }),
    invoice: column_descriptor_1.ColumnDescriptor.create()
        .toCase('lower')
        .valueMap({ ja: true, nein: false }),
    comment: column_descriptor_1.ColumnDescriptor.create().orEmpty(null)
})
    .build({
    exclude: (data) => Object.values((0, lodash_1.omit)(data, 'clientNumber')).every((v) => v === '')
});
