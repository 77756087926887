import React from 'react';
import { ProjectRequest } from 'model';
import { Grid } from '@material-ui/core';
import { RequestBasicCard } from './basic/RequestBasicCard';
import { useProject } from '../../hooks/project.hook';
import { ProjectBasicCard } from '../basic/ProjectBasicCard';
import { RequestPositionsCard } from './positions/RequestPositionsCard';
import { ProjectCommentsCard } from '../comments/ProjectCommentsCard';
import { RequestResourcesCard } from './resource/RequestResourceCard';
import { RequestStatusAlert } from './status/RequestStatusAlert';
import { RequestStatusDialog } from './status/RequestStatusDialog';

interface ProjectRequestMainDetailsProps {
  request: ProjectRequest;
}

export function ProjectRequestOverview(props: ProjectRequestMainDetailsProps) {
  const { project } = useProject(props.request.project.id);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RequestStatusAlert request={props.request} />
        <RequestStatusDialog request={props.request} />
      </Grid>
      <Grid item xs={6}>
        <RequestBasicCard request={props.request} />
      </Grid>
      {project && (
        <Grid item xs={6}>
          <ProjectBasicCard title="Project" project={project} />
        </Grid>
      )}
      <Grid item xs={6}>
        <RequestPositionsCard projectRequest={props.request} />
      </Grid>
      <Grid item xs={6}>
        <RequestResourcesCard request={props.request} />
      </Grid>
      <Grid item xs={6}>
        <ProjectCommentsCard requestId={props.request.id} />
      </Grid>
    </Grid>
  );
}
