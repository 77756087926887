import React from 'react';
import { Route as PublicRoute, Switch as RouterSwitch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Toaster } from 'react-hot-toast';
import { LoginScreen } from './features/auth/screens/Login.screen';
import { AuthenticatedApp } from './features/auth/AuthenticatedApp';
import { PrivateRoute } from './features/auth/components/PrivateRoute';
import { KontextorThemeProvider } from './theme/KontextorThemeProvider';
import { useLoginSilent } from './features/auth/hooks/login-silent.hook';
import KontextorAlert from './components/alert/KontextorAlert';
import { ForgotPasswordScreen } from './features/auth/screens/ForgotPassword.screen';
import { InfoAlert } from './components/info/InfoAlert';

export function App() {
  useLoginSilent();

  return (
    <KontextorThemeProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KontextorAlert>
          <InfoAlert />
          <CssBaseline />
          <RouterSwitch>
            <PublicRoute path="/login">
              <LoginScreen />
            </PublicRoute>
            <PublicRoute path="/forgot-password">
              <ForgotPasswordScreen />
            </PublicRoute>
            <PrivateRoute path="/">
              <AuthenticatedApp />
            </PrivateRoute>
          </RouterSwitch>
        </KontextorAlert>
        <Toaster />
      </MuiPickersUtilsProvider>
    </KontextorThemeProvider>
  );
}
