"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMonitoringLogger = void 0;
const configDefaults = {
    defaultScope: 'default',
    loggerEndpoint: 'https://logstash.monitoring.devconsult.berlin',
    networkConfig: {
        retries: 2,
        retryInterval: [1000, 5000]
    }
};
const sleep = (millis) => new Promise((resolve) => setTimeout(resolve, millis));
function createMonitoringLogger(source, instance, httpAdapter, customConfig) {
    const config = { ...configDefaults, ...customConfig };
    if (Array.isArray(config.networkConfig.retryInterval) &&
        config.networkConfig.retryInterval.length !== config.networkConfig.retries) {
        throw new Error('Retry interval array length should equal retry amount');
    }
    else if (!Array.isArray(config.networkConfig.retryInterval)) {
        config.networkConfig.retryInterval = Array.from({ length: config.networkConfig.retries }, () => config.networkConfig.retryInterval);
    }
    if (config.loggerEndpoint.endsWith('/')) {
        config.loggerEndpoint = config.loggerEndpoint.substring(0, config.loggerEndpoint.length - 1);
    }
    return {
        log: async (entryInput) => {
            const requestData = {
                occurredOn: new Date().toISOString(),
                scope: config.defaultScope,
                ...entryInput
            };
            const requestUrl = `${config.loggerEndpoint}/${source}/${instance}`;
            for (let i = 0; i <= config.networkConfig.retries; i += 1) {
                try {
                    // eslint-disable-next-line max-len
                    // see https://eslint.org/docs/latest/rules/no-await-in-loop#when-not-to-use-it
                    // eslint-disable-next-line no-await-in-loop
                    await httpAdapter.postData(requestUrl, requestData);
                    return;
                }
                catch (e) {
                    if (i < config.networkConfig.retries) {
                        // eslint-disable-next-line max-len
                        // see https://eslint.org/docs/latest/rules/no-await-in-loop#when-not-to-use-it
                        // eslint-disable-next-line no-await-in-loop
                        await sleep(config.networkConfig.retryInterval[i]);
                    }
                }
            }
        }
    };
}
exports.createMonitoringLogger = createMonitoringLogger;
