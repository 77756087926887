import React from 'react';
import { ClientContact } from 'model';
import {
  Card,
  CardContent,
  IconButton,
  Link,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  RecentActors as MainContactIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';
import { useRouteMatch } from 'react-router-dom';
import MinusIcon from '@material-ui/icons/Remove';
import PlusIcon from '@material-ui/icons/Add';
import {
  ActionMenuEntry,
  ActionsMenu
} from '../../../components/actions/ActionsMenu';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { updateClientContactThunk } from '../redux/actions/patch-client-contact.thunk';
import { deleteClientContactThunk } from '../redux/actions/delete-client-contact.thunk';
import { PhoneNumberDisplay } from '../../../components/phone/PhoneNumberDisplay';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex'
    },
    leftSide: {
      flex: 2
    },
    rightSide: {
      flex: 1,
      textAlign: 'end',
      alignItems: 'center'
    }
  })
);

function ClientContactActions({
  clientId,
  contact
}: {
  clientId: string;
  contact: ClientContact;
}) {
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();

  const setMainContact = (value: boolean) => {
    dispatch(
      updateClientContactThunk({
        clientId,
        contactId: contact.id,
        dto: {
          ...contact,
          userId: contact.user?.id ?? null,
          mainContact: value
        }
      })
    );
  };

  const deleteContact = () => {
    dispatch(deleteClientContactThunk({ clientId, contactId: contact.id }));
  };

  const menuItems: ActionMenuEntry[] = [
    {
      icon: EditIcon,
      label: 'Edit',
      link: `${url}?type=edit#${contact.id}`
    },
    {
      icon: contact.mainContact ? MinusIcon : PlusIcon,
      label: 'Main Contact',
      onClick: () => setMainContact(!contact.mainContact)
    },
    {
      icon: DeleteIcon,
      label: 'Delete',
      onClick: deleteContact
    }
  ];

  return <ActionsMenu items={menuItems} />;
}

interface ClientContactDisplayProps {
  contact: ClientContact;
  clientId: string;
}

export function ClientContactDisplay({
  contact,
  clientId
}: ClientContactDisplayProps) {
  const classes = useStyles();
  return (
    <Card variant="outlined">
      <CardContent className={classes.root}>
        <div key="content" className={classes.leftSide}>
          <Typography variant="body1" color="inherit">
            {contact.name.first} {contact.name.last}
          </Typography>
          <Typography
            style={{ marginBottom: 5 }}
            variant="body2"
            color="textSecondary"
          >
            {contact.description}
          </Typography>
          <Typography
            component={Link}
            variant="body2"
            href={`mailto:${contact.email}`}
          >
            {contact.email}
          </Typography>
          <div style={{ marginTop: 10 }}>
            {contact.phoneNumbers?.map((phone) => (
              <PhoneNumberDisplay
                key={`${phone.number}-${phone.type}`}
                phoneNumber={phone}
              />
            ))}
          </div>
          <Typography
            style={{ marginTop: 10 }}
            variant="body2"
            color="textSecondary"
          >
            Comment: {contact.comment}
          </Typography>
        </div>
        <div key="buttons" className={classes.rightSide}>
          {contact.mainContact && (
            <Tooltip title="is main contact">
              <IconButton>
                <MainContactIcon />
              </IconButton>
            </Tooltip>
          )}
          <ClientContactActions contact={contact} clientId={clientId} />
        </div>
      </CardContent>
    </Card>
  );
}
