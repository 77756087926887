import { Project, ProjectStatusType } from 'model';
import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';
import {
  HiOutlineStar,
  HiStar,
  HiOutlineDocumentDuplicate
} from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  EntityDetails,
  TabDefinition
} from '../../../components/details/EntityDetails';
import { useProject } from '../hooks/project.hook';
import { ProjectOverview } from '../components/ProjectOverview';
import { ProjectTasks } from '../components/tasks/ProjectTasks';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { projectThunk } from '../redux/projects.thunk';
import { NewProjectRequestOffers } from '../components/request/offers/NewProjectRequestOffers';
import { useProjectRequest } from '../hooks/project-request.hook';
import { projectRequestThunk } from '../redux/project-requests.thunk';
import { ProjectInvoiceTab } from '../components/ProjectInvoiceTab';
import { ProjectCandidatesTab } from './ProjectCandidatesTab';
import { ProjectCommentSection } from '../components/comments/ProjectCommentSection';
import { useUserProjectTemplateState } from '../../users/hooks/user-project-templates.hook';

function ProjectQuotesLoader({ project }: { project: Project }) {
  const { projectRequest } = useProjectRequest(project.requestId);
  return projectRequest ? (
    <NewProjectRequestOffers request={projectRequest} />
  ) : (
    <Skeleton />
  );
}

const tabs = (status: ProjectStatusType): TabDefinition<Project>[] => [
  {
    name: 'overview',
    subPaths: (
      ['not-yet-commisioned', 'cancelled'] as ProjectStatusType[]
    ).includes(status)
      ? ['', '/overview']
      : ['/overview'],
    component: (s: Project) => <ProjectOverview project={s} />
  },
  {
    name: 'quotes',
    subPaths: ['/quotes'],
    component: (s: Project) => <ProjectQuotesLoader project={s} />
  },
  {
    name: 'candidates',
    subPaths: ['/candidates'],
    component: (s: Project) => <ProjectCandidatesTab project={s} />
  },
  {
    name: 'tasks',
    subPaths: (
      ['ready-to-plan', 'in-progress', 'delivered'] as ProjectStatusType[]
    ).includes(status)
      ? ['', '/tasks']
      : ['/tasks'],
    component: (project) => (
      <ProjectTasks projectId={project.id} orderId={project.order?.id} />
    )
  },
  {
    name: 'accounting',
    subPaths: (['final'] as ProjectStatusType[]).includes(status)
      ? ['', '/accounting']
      : ['/accounting'],
    component: (project) => <ProjectInvoiceTab project={project} />
  }
];

// Todo remove hack
export function useExplicitProject(projectId: string | undefined) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (projectId) {
      dispatch(projectThunk.get({ id: projectId }));
    }
  }, [projectId]);
}

export function useExplicitProjectRequest(
  requestId: string | null | undefined
) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (requestId) {
      dispatch(projectRequestThunk.get({ id: requestId }));
    }
  }, [requestId]);
}

function ProjectTemplateIcon({
  templateState
}: {
  templateState?: { template: boolean; toggle: () => void };
}) {
  if (!templateState) {
    return <CircularProgress size="1rem" />;
  }
  if (!templateState.template) {
    return <HiOutlineStar />;
  }
  return <HiStar style={{ color: '#FFA41C' }} />;
}

export function ProjectDetailsScreen() {
  const { projectId } = useParams<{ projectId: string }>();
  const { project } = useProject(projectId);
  const history = useHistory();

  // Todo remove hack
  useExplicitProject(projectId);
  useExplicitProjectRequest(project?.requestId);

  const { t } = useTranslation();

  const templateState = useUserProjectTemplateState(projectId);

  return (
    <>
      <EntityDetails
        entityName="Project"
        title="projectName"
        tabs={tabs(project?.projectStatus ?? 'not-yet-commisioned')}
        entity={project}
        renderTail={(project1) => <ProjectCommentSection project={project1} />}
        actions={[
          {
            label: 'Duplicate Request',
            icon: <HiOutlineDocumentDuplicate />,
            onClick: () => {
              history.push(
                // eslint-disable-next-line max-len
                `/projects/project-requests?type=duplicate#${project?.id}`
              );
            }
          },
          {
            label: !templateState?.template
              ? t('Add to templates')
              : t('Remove from templates'),
            icon: <ProjectTemplateIcon templateState={templateState} />,
            onClick: templateState?.toggle ?? (() => {})
          }
        ]}
      />
    </>
  );
}
