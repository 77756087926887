import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { ColumnKey } from '../types';
import { useTableConfigContext } from '../context/DataTableContext';
import {
  SortDirection,
  SortDirectionSequence,
  TableSortState
} from './sort.context';

export function useColumnSort<T extends BaseTableRow, C extends DataCellMap<T>>(
  column: ColumnKey<T, C>
) {
  const {
    tableCells,
    tableSort: { sortState, setSortState }
  } = useTableConfigContext<T, C>();

  const sortable = tableCells[column].renderer.comparator !== undefined;
  const sortStateElement = sortState[column];
  const sortStateIndex =
    Object.keys(sortState).length > 1
      ? Object.keys(sortState).indexOf(column)
      : undefined;
  const columnSortState = React.useMemo(
    () =>
      sortStateElement
        ? {
            ...sortStateElement,
            position: sortStateIndex
          }
        : undefined,
    [sortStateElement, sortStateIndex]
  );

  const requestSort: (options: {
    multisort?: boolean;
    sortDirection?: SortDirection | null;
  }) => void = React.useCallback(
    sortable
      ? ({ multisort, sortDirection }) => {
          const nextDirection =
            sortDirection !== undefined
              ? sortDirection ?? undefined
              : SortDirectionSequence[
                  (SortDirectionSequence.indexOf(columnSortState?.direction) +
                    1) %
                    SortDirectionSequence.length
                ];
          const nextState: TableSortState<T, C> = nextDirection
            ? ({
                [column]: { cellId: column, direction: nextDirection }
              } as TableSortState<T, C>)
            : {};
          if (!multisort) {
            setSortState(nextState);
          } else if (!columnSortState) {
            setSortState({ ...sortState, ...nextState });
          } else {
            setSortState(
              Object.fromEntries(
                Object.entries(sortState)
                  .map((e) =>
                    e[0] === column ? [column, nextState[column]] : e
                  )
                  .filter((e) => e[1] !== undefined)
              )
            );
          }
        }
      : () => {},
    [SortDirectionSequence, columnSortState, sortState]
  );
  return {
    sortable,
    columnSortState,
    requestSort
  };
}
