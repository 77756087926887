import { ServiceClass, ServiceClassDTO, ServiceClassPatchDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const serviceClassesThunk = createFeatureThunks<
  ServiceClass,
  ServiceClassDTO,
  ServiceClassPatchDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/services/service-class${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'service-classes'
});
