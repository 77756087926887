import { UploadAccessPermission, UploadFile } from 'model';
import React from 'react';
import { apiClient } from '../../api/apiClient';
import { SeafileImageCacheContext } from './SeafileImageCacheContext';

export interface SeafileAccess {
  file?: UploadFile | null;
  // Todo Manu correct typing
  grant: any;
  permission: UploadAccessPermission<any, any, any>;
}
export function useSeafileImageCache() {
  return React.useContext(SeafileImageCacheContext);
}
export function useSeafileFileUrl(fileProps: SeafileAccess) {
  const { files, setFileState } = useSeafileImageCache();
  const grantBase64 = btoa(
    JSON.stringify({
      signed: fileProps?.permission.signed,
      token: btoa(JSON.stringify(fileProps?.grant))
    })
  );

  React.useEffect(() => {
    if (fileProps.file && files[fileProps.file.id] === undefined) {
      (async () => {
        setFileState(fileProps.file?.id ?? '', null);
        try {
          const fileUrl = (
            await apiClient.get(
              `/files/${fileProps.file?.id ?? ''}/${grantBase64}`
            )
          ).data;

          const blob = await fetch(fileUrl).then((r) => r.blob());
          setFileState(
            fileProps.file?.id ?? '',
            await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = () => resolve(reader.result as string);
              reader.readAsDataURL(blob);
            })
          );
        } catch (e: any) {
          setFileState(fileProps.file?.id ?? '', undefined);
        }
      })();
    }
  }, [fileProps.file?.id, files]);

  return files[fileProps.file?.id ?? ''];
}
