import {
  createSlice,
  isAnyOf,
  isPending,
  isRejected,
  SerializedError
} from '@reduxjs/toolkit';
import { AuthUser } from 'model';
import { RootState } from '../../../redux/store';
import { loginThunk } from './actions/login.thunk';
import { logoutThunk } from './actions/logout.thunk';
import {
  loginSilentThunk,
  loginSilentWithDataThunk
} from './actions/login-silent.thunk';
import { patchUserProfileThunk } from '../../users/redux/actions/patch-user-profile.thunk';

export type AuthState =
  | 'login-silent'
  | 'login-pending'
  | 'logged-in'
  | 'logged-out';

interface AuthStateInterface {
  state: AuthState;
  user: AuthUser | null;
  error: SerializedError | null;
}

const initialState: AuthStateInterface = {
  state: 'login-silent',
  user: null,
  error: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthError(state) {
      state.error = null;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(patchUserProfileThunk.fulfilled, (state, { payload }) => {
        state.state = 'logged-in';
        if (state.user) {
          state.user.avatar = payload.avatar;
          state.user.displayName = payload.displayName;
        }
      })
      .addCase(logoutThunk.fulfilled, (state) => {
        state.user = initialState.user;
        state.error = initialState.error;
        state.state = 'logged-out';
      })
      .addMatcher(
        isAnyOf(
          loginThunk.fulfilled,
          loginSilentThunk.fulfilled,
          loginSilentWithDataThunk.fulfilled
        ),
        (state, action) => {
          state.state = 'logged-in';
          state.user = action.payload.user;
        }
      )
      .addMatcher(isPending(loginThunk, logoutThunk), (state) => {
        state.error = null;
        state.state = 'login-pending';
      })
      .addMatcher(isRejected(loginThunk), (state, action) => {
        state.error = action.error;
        state.state = 'logged-out';
      })
      .addMatcher(isRejected(loginSilentThunk), (state) => {
        state.state = 'logged-out';
      })
      .addMatcher(isRejected(loginSilentWithDataThunk), (state) => {
        state.state = 'logged-out';
      })
});

export const { clearAuthError } = authSlice.actions;

export const selectUserAsNullable = (state: RootState) => state.auth.user;

export const selectUser = (state: RootState) => state.auth.user as AuthUser;

export const selectAuthState = (state: RootState) => state.auth.state;

export const selectAuthError = (state: RootState) => state.auth.error;

export default authSlice.reducer;
