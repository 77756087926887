import React from 'react';
import { useTranslation } from 'react-i18next';
import { permissions, supplierPatchTransformer } from 'model';
import { SupplierComponent } from '../types';
import { NewEditableCard } from '../../../../components/card/editableCard/NewEditableCard';
import { SupplierSkillSetDisplay } from './display/SupplierSkillSetDisplay';
import { useSupplierUpdate } from '../../hooks/supplier-update.hook';
import { SkillsSupplierView } from '../SupplierSkillsForm';
import { SupplierSkillSetForm } from './form/SupplierSkillSetForm';

export function SupplierSkillSetCard({ supplier }: SupplierComponent) {
  const { t } = useTranslation();
  const updateSupplier = useSupplierUpdate(supplier.id);
  return (
    <NewEditableCard
      identifier="skillset"
      title={t('Skill Set')}
      value={supplier}
      display={{ component: SupplierSkillSetDisplay }}
      form={{
        component: SupplierSkillSetForm,
        dtoTransformer: supplierPatchTransformer,
        validationSchema: SkillsSupplierView.validationSchema,
        viewFactory: SkillsSupplierView.viewFactory,
        onSubmit: updateSupplier,
        permissions: permissions.suppliers.supplier.edit
      }}
    />
  );
}
