import React from 'react';
import { InitialServicePositionDTO } from 'model';
import { useTranslation } from 'react-i18next';
import { ArrowForward } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import { useTypedField } from '../../../../../../transformer/DTOViewSchema';
import { RequestServicePositionView } from './RequestPositionForm';
import { LanguageSelect } from '../../../../../languages/components/LanguageSelect';
import { useServiceClass } from '../../../../../services/hooks/service-class.hook';
import { MultipleLanguageSelect } from '../../../../../languages/components/MultipleLanguageSelect';

export function RequestPositionLanguageSelect({
  basePath = RequestServicePositionView.path
}: {
  basePath?: (typeof RequestServicePositionView)['path'];
}) {
  const { t } = useTranslation();
  const [, languageMeta, languageHelpers] = useTypedField<
    InitialServicePositionDTO['languages']
  >(basePath.sub_path('languages'));

  const [, serviceClassIdMeta] = useTypedField<
    InitialServicePositionDTO['serviceClassId']
  >(basePath.sub_path('serviceClassId'));

  const { serviceClass } = useServiceClass(serviceClassIdMeta.value);

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      {serviceClass?.type === 'source-target' && (
        <>
          <LanguageSelect
            label={t('Source Language')}
            value={languageMeta.value?.sourceLanguage ?? null}
            onChange={(val) =>
              languageHelpers.setValue({
                ...(languageMeta.value ?? {
                  targetLanguages: []
                }),
                sourceLanguage: val
              })
            }
          />
          <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <ArrowForward />
          </div>
        </>
      )}
      {serviceClass && serviceClass?.type !== 'no-language' && (
        <MultipleLanguageSelect
          label={t('Target Language')}
          value={languageMeta.value?.targetLanguages ?? []}
          onChange={(val) =>
            languageHelpers.setValue({
              sourceLanguage: languageMeta.value?.sourceLanguage ?? null,
              targetLanguages: val
            })
          }
        />
      )}
      <Typography variant="subtitle1" color="error">
        <ErrorMessage name={basePath?.sub_path('languages.targetLanguages')} />
      </Typography>
    </div>
  );
}
