import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  PropertyPath,
  useTypedField
} from '../../../transformer/DTOViewSchema';

interface FormikDatePickerProps {
  name: string | PropertyPath<any, Date | null>;
  label?: string;
  fullWidth?: boolean;
  small?: boolean;
}

export function FormikDatePicker({
  name,
  label,
  fullWidth = true,
  small
}: FormikDatePickerProps) {
  const [, meta, helpers] = useTypedField<Date | null>(name);
  return (
    <KeyboardDatePicker
      style={small ? { width: 140 } : undefined}
      fullWidth={fullWidth}
      disableToolbar
      variant="inline"
      format="dd.MM.yyyy"
      autoOk
      id="date-picker-inline"
      label={label}
      value={meta.value ?? null}
      error={meta.touched && Boolean(meta.error)}
      onChange={(date) => helpers.setValue(date)}
    />
  );
}
