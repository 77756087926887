import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { useTableDataContext } from '../context/DataTableContext';
import { useTableSelectionSelector } from '../selection/selection-selector.hook';

export function useTableRow<T extends BaseTableRow, C extends DataCellMap<T>>(
  id: T['id']
) {
  const { data } = useTableDataContext<T, C>();
  const { selected, setSelected } = useTableSelectionSelector<T>(id);
  const rowData = data[id];
  return React.useMemo(
    () => ({
      row: rowData,
      selected,
      setSelected
    }),
    [rowData, selected, setSelected]
  );
}
