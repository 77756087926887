import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { ControlPoint as AddRoleIcon } from '@material-ui/icons';
import {
  Link as RouterLink,
  Route,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import { permissions } from 'model';
import { ErrorBoundary } from '../../error/ErrorBoundary';
import { RoleAccordion } from '../components/role/RoleAccordion';
import { useRoles } from '../hooks/roles.hook';
import { PermittedComponent } from '../../../components/auth/PermittedComponent';
import { RoleDialogForm } from '../components/role/RoleDialogForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    screenTitle: {
      marginBottom: theme.spacing(2)
    },
    roleAccordion: {
      marginBottom: theme.spacing(1)
    }
  })
);

export function RolesScreen() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { roles } = useRoles();
  const { url, path } = useRouteMatch();
  const history = useHistory();

  const navigateToDetails = (id: string) => history.push(`${path}/${id}`);
  const navigateBack = () => history.push(path);

  return (
    <ErrorBoundary context="roles">
      <RoleDialogForm />
      <Typography variant="h4" className={classes.screenTitle}>
        {t('Roles')}
      </Typography>
      {roles.map((role) => (
        <Route path={[`${path}/:roleId`, path]} key={role.id}>
          <RoleAccordion
            navigateToDetails={navigateToDetails}
            role={role}
            key={`role-${role.id}`}
            navigateBack={navigateBack}
            className={classes.roleAccordion}
          />
        </Route>
      ))}
      <PermittedComponent permission={permissions.users.role.create}>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<AddRoleIcon fontSize="small" />}
            component={RouterLink}
            to={`${url}?type=create#role`}
          >
            {t('new Role')}
          </Button>
        </div>
      </PermittedComponent>
    </ErrorBoundary>
  );
}
