import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { PermissionTreeNode } from 'model';
import { ApiAdapterState } from '../../auth/redux/types';
import { getPermissionsTreeThunk } from '../../auth/redux/actions/get-permission-tree.thunk';
import { RootState } from '../../../redux/store';

interface PermissionsTreeState extends ApiAdapterState {
  tree: PermissionTreeNode | null;
}

const initialState: PermissionsTreeState = {
  state: 'idle',
  error: null,
  tree: null
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    clearPermissionsTreeError(state) {
      state.error = null;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getPermissionsTreeThunk.fulfilled, (state, { payload }) => {
        state.state = 'idle';
        state.listFetched = true;
        state.tree = payload;
      })
      .addMatcher(isPending(getPermissionsTreeThunk), (state) => {
        state.state = 'pending';
        state.error = null;
      })
      .addMatcher(isRejected(getPermissionsTreeThunk), (state, action) => {
        state.state = 'idle';
        state.error = action.error;
      })
});

export const { clearPermissionsTreeError } = permissionsSlice.actions;

export const selectPermissionTree = (state: RootState) =>
  state.permissions.tree;

export default permissionsSlice.reducer;
