import React from 'react';
import { useTranslation } from 'react-i18next';
import { permissions, Supplier, supplierPatchTransformer } from 'model';
import { NewEditableCard } from '../../../../components/card/editableCard/NewEditableCard';
import { SupplierServicesDisplay } from './SupplierServicesDisplay';
import {
  SupplierServiceForm,
  SupplierServiceView
} from '../SupplierServiceForm';
import { useSupplierUpdate } from '../../hooks/supplier-update.hook';

export function SupplierServicesCard(props: { value: Supplier }) {
  const { t } = useTranslation();
  const updateSupplier = useSupplierUpdate(props.value.id);
  const formComponent = React.useCallback(
    () => <SupplierServiceForm languages={props.value.languages} />,
    props.value.languages
  );

  return (
    <NewEditableCard
      identifier="services"
      title={t('Services')}
      value={props.value}
      display={{
        component: SupplierServicesDisplay
      }}
      form={{
        component: formComponent,
        onSubmit: updateSupplier,
        validationSchema: SupplierServiceView.validationSchema,
        dtoTransformer: supplierPatchTransformer,
        viewFactory: SupplierServiceView.viewFactory,
        permissions: permissions.suppliers.supplier.edit
      }}
    />
  );
}
