import { ProjectPatchDTO, ProjectStatusList } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import React from 'react';
import {
  buildDTOView,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { FormikEntitySelect } from '../../../../components/select/FormikEntitySelect';
import { projectVariationAPI } from '../../../services/redux/project-variations.slice';
import { clientAPI } from '../../../clients/redux/clients.slice';
import { ProjectTagQuickAdd } from '../tags/ProjectTagQuickAdd';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { supplierApi } from '../../../suppliers/redux/suppliers.slice';
import { EditableCheckboxDisplay } from '../../../../components/input/checkbox/EditableCheckboxDisplay';
import { FormikSelect } from '../../../../components/select/FormikSelect';
import { FormikDateTimePicker } from '../../../../components/input/date/FormikDateTimePicker';

export const ProjectBasicView = buildDTOView<ProjectPatchDTO>()
  .all()
  .withSchema(
    yup
      .object({
        projectName: yup.string().required('required'),
        projectTagIds: yup
          .array()
          .required()
          .of(yup.string().uuid().required()),
        clientId: yup.string().nullable().uuid().required('required'),
        projectManagerId: yup.string().nullable().uuid().required('required'),
        projectVariationId: yup
          .string()
          .uuid()
          .defined()
          .nullable()
          .default(null),
        supportingProjectManagerIds: yup
          .array()
          .required()
          .of(yup.string().uuid().required()),
        createdInWordbee: yup.boolean().required(),
        tmConsolidated: yup.boolean().required(),
        projectStatus: yup.mixed().oneOf(ProjectStatusList).required(),
        deadline: yup.date().defined().nullable().default(null)
      })
      .defined()
  );

export function ProjectBasicForm({
  basePath = ProjectBasicView.path,
  isNewRequest
}: {
  basePath?: (typeof ProjectBasicView)['path'];
  isNewRequest?: boolean;
}) {
  const { t } = useTranslation();

  const [, projectManagerIdMeta] = useTypedField(basePath.projectManagerId);
  const [, supportingProjectManagersIdsMeta] = useTypedField(
    basePath.supportingProjectManagerIds
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={4}>
          <FormikTextField
            required
            name={basePath.projectName}
            label={t('Project Name')}
          />
        </Grid>
        <Grid item xs={3}>
          <FormikEntitySelect
            entityApi={projectVariationAPI}
            optionLabel={(v) => v.name}
            name={basePath.projectVariationId}
            textFieldProps={{ label: t('Project Variation') }}
          />
        </Grid>
        <Grid item xs={5}>
          <ProjectTagQuickAdd basePath={basePath} />
        </Grid>
        <Grid item xs={4}>
          <FormikEntitySelect
            entityApi={clientAPI}
            optionLabel={(c) => c.name}
            name={basePath.clientId}
            textFieldProps={{ label: t('Client'), required: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <FormikEntitySelect
            entityApi={supplierApi}
            optionLabel={(s) => `${s.name.first} ${s.name.last}`}
            name={basePath.projectManagerId}
            filter={(p) =>
              !supportingProjectManagersIdsMeta.value.includes(p.id) &&
              p.userId !== null
            }
            textFieldProps={{ label: t('Project Manager'), required: true }}
          />
        </Grid>
        <Grid item xs={5}>
          <FormikEntitySelect
            entityApi={supplierApi}
            multiple
            optionLabel={(s) => `${s.name.first} ${s.name.last}`}
            filter={(s) =>
              s.id !== projectManagerIdMeta.value && s.userId !== null
            }
            name={basePath.supportingProjectManagerIds}
            textFieldProps={{ label: t('Supporting Project Managers') }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikDateTimePicker
            timeOptional
            label={t('Deadline')}
            name={basePath.deadline}
          />
        </Grid>
        <Grid item xs={4}>
          <EditableCheckboxDisplay
            name={basePath.createdInWordbee}
            label={t('Created in Wordbee')}
          />
        </Grid>
        {!isNewRequest && (
          <>
            <Grid item xs={4}>
              <EditableCheckboxDisplay
                name={basePath.tmConsolidated}
                label={t('TM consolidated')}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikSelect
                name={basePath.projectStatus}
                options={ProjectStatusList.map((s) => ({
                  value: s,
                  label: t(s)
                }))}
                label={t('Project Status')}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
}
