import {
  CreateRequestDTO,
  PlainRequestPosition,
  PlainRequestPositionDTO,
  Project,
  ProjectRequest,
  RequestPosition,
  ServiceRequestPosition,
  ServiceRequestPositionDTO
} from 'model';
import { useTranslation } from 'react-i18next';
import { EntityId, unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { useRequestStatusList } from '../../hooks/request-status-list.hook';
import { projectRequestThunk } from '../../redux/project-requests.thunk';
import { projectThunk } from '../../redux/projects.thunk';
import { NoOp } from '../../../../utils';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import { RequestCreatorView } from '../request/request-creator.view';
import projectsSlice, { projectSelectors } from '../../redux/projects.slice';
import { CardWithHeader } from '../../../../components/card/cardWithHeader/CardWithHeader';
import { ProjectBasicForm } from '../basic/ProjectBasicForm';
import { RequestBasicForm } from '../request/basic/RequestBasicForm';
import { RequestPositionsForm } from '../request/positions/form/RequestPositionsForm';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { useProject } from '../../hooks/project.hook';
import { useProjectRequest } from '../../hooks/project-request.hook';
import { usePositionsOfRequest } from '../../hooks/request-positions.hook';
import {
  useExplicitProject,
  useExplicitProjectRequest
} from '../../screens/ProjectDetails.screen';

const initialValues = (
  defaultStatusId: string,
  project: Project,
  request: ProjectRequest,
  positions: RequestPosition[]
): CreateRequestDTO => ({
  duplicatedFromId: project.id,
  project: {
    projectName: project.projectName,
    projectTagIds: project.projectTagIds,
    projectVariationId: project.projectVariationId,
    clientId: project.clientId,
    projectManagerId: project.projectManagerId,
    supportingProjectManagerIds: project.supportingProjectManagerIds,
    createdInWordbee: false,
    tmConsolidated: false,
    projectStatus: 'not-yet-commisioned',
    deadline: null
  },
  request: {
    requestSourceId: request.requestSourceId,
    requestStatusId: defaultStatusId,
    dateOfRequest: new Date(),
    expressProject: request.expressProject
  },
  resources: [],
  positions: positions.map((p) =>
    p.positionType === 'service-position'
      ? ({
          positionType: 'service-position' as const,
          deadline: null,
          expressPosition: p.expressPosition,
          description: p.description,
          estimatedAmount: p.estimatedAmount,
          priceUnitId: p.priceUnitId,
          resourceIds: [],
          requiredPositionIds: [],
          serviceClassId: (p as ServiceRequestPosition).serviceClassId,
          languages: (p as ServiceRequestPosition).languages
        } as ServiceRequestPositionDTO)
      : ({
          positionType: 'plain-position' as const,
          deadline: null,
          expressPosition: p.expressPosition,
          description: p.description,
          estimatedAmount: p.estimatedAmount,
          priceUnitId: p.priceUnitId,
          resourceIds: [],
          requiredPositionIds: [],
          name: (p as PlainRequestPosition).name
        } as PlainRequestPositionDTO)
  )
});

export function ProjectDuplicateDialog() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { type, reference } = useEditorLocation();
  const correctLocation = type === 'duplicate';
  const { project } = useProject(correctLocation ? reference : '');
  const { projectRequest } = useProjectRequest(project?.requestId);
  const { positions } = usePositionsOfRequest(project?.requestId);

  useExplicitProject(correctLocation ? reference : undefined);
  useExplicitProjectRequest(project?.requestId);

  const requestStatusList = useRequestStatusList();

  const createRequest = (dto: CreateRequestDTO) => {
    return dispatch(
      projectRequestThunk.create({
        input: dto
      })
    )
      .then(unwrapResult)
      .then((res) => {
        dispatch(projectThunk.list());
        return res;
      })
      .catch(NoOp);
  };

  const history = useHistory();

  const navigateToDetails = (
    id: EntityId | undefined,
    result?: ProjectRequest
  ) => history.replace(`/projects/projects/${result?.projectId ?? ''}`);

  if (
    requestStatusList.entities.length === 0 ||
    !project ||
    !projectRequest ||
    !projectRequest.positions
  ) {
    return <></>;
  }

  return (
    <DialogForm
      identifier={reference}
      maxWidth="md"
      label={t('Duplicate Project')}
      actions={[
        { label: t('Save and Exit'), doSubmit: true },
        {
          label: t('Save and Details'),
          doSubmit: true,
          onClick: navigateToDetails
        }
      ]}
      form={{
        initialValues: initialValues(
          requestStatusList.entities.find(
            (s) => s.defaultStatus && s.type === 'open'
          )?.id as string,
          project,
          projectRequest,
          positions
        ),
        validationSchema: RequestCreatorView.validationSchema
      }}
      api={{
        clearError: projectsSlice.actions.clearError,
        errorSelector: projectSelectors.selectError,
        onSubmit: createRequest,
        stateSelector: projectSelectors.selectState
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardWithHeader title="Project">
            <ProjectBasicForm
              basePath={RequestCreatorView.path.project as any}
              isNewRequest
            />
          </CardWithHeader>
        </Grid>
        <Grid item xs={12}>
          <CardWithHeader title="Basic">
            <RequestBasicForm
              basePath={RequestCreatorView.path.request as any}
              withCommentEditor
            />
          </CardWithHeader>
        </Grid>
        <Grid item xs={12}>
          <CardWithHeader title="Positions">
            <RequestPositionsForm />
          </CardWithHeader>
        </Grid>
      </Grid>
    </DialogForm>
  );
}
