"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = void 0;
const typeorm_1 = require("typeorm");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const ClientContact_1 = require("./ClientContact");
// eslint-disable-next-line import/no-cycle
const ClientAddress_1 = require("./ClientAddress");
// eslint-disable-next-line import/no-cycle
const ClientComment_1 = require("./ClientComment");
// eslint-disable-next-line import/no-cycle
const LargeScaleProject_1 = require("../../project/entity/LargeScaleProject");
// eslint-disable-next-line import/no-cycle
const ClientPrice_1 = require("./ClientPrice");
// eslint-disable-next-line import/no-cycle
const ClientMinimumPrice_1 = require("./ClientMinimumPrice");
// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
const ClientNumber_1 = require("./ClientNumber");
let Client = class Client extends JournaledEntity_1.JournaledEntity {
};
__decorate([
    (0, typeorm_1.OneToOne)(() => ClientNumber_1.ClientNumber, (cn) => cn.client, { nullable: true }),
    __metadata("design:type", ClientNumber_1.ClientNumber)
], Client.prototype, "clientNumber", void 0);
__decorate([
    (0, typeorm_1.Column)({ unique: true }),
    __metadata("design:type", String)
], Client.prototype, "name", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], Client.prototype, "description", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true, unique: true }),
    __metadata("design:type", String)
], Client.prototype, "taxId", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], Client.prototype, "ceo", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], Client.prototype, "email", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], Client.prototype, "phone", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], Client.prototype, "defaultCurrency", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => ClientContact_1.ClientContact, (contact) => contact.client),
    __metadata("design:type", Array)
], Client.prototype, "contacts", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => ClientPrice_1.ClientPrice, (price) => price.client),
    __metadata("design:type", Array)
], Client.prototype, "prices", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => ClientComment_1.ClientComment, { nullable: true }),
    (0, typeorm_1.JoinColumn)(),
    __metadata("design:type", ClientComment_1.ClientComment)
], Client.prototype, "priceComment", void 0);
__decorate([
    (0, typeorm_1.RelationId)((s) => s.priceComment),
    __metadata("design:type", String)
], Client.prototype, "priceCommentId", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => ClientMinimumPrice_1.ClientMinimumPrice, (price) => price.client, {
        nullable: true
    }),
    __metadata("design:type", ClientMinimumPrice_1.ClientMinimumPrice)
], Client.prototype, "minimumPrice", void 0);
__decorate([
    (0, typeorm_1.RelationId)((c) => c.minimumPrice),
    __metadata("design:type", String)
], Client.prototype, "minimumPriceId", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => ClientComment_1.ClientComment, (comment) => comment.client),
    __metadata("design:type", Array)
], Client.prototype, "comments", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => ClientAddress_1.ClientAddress, (address) => address.client),
    __metadata("design:type", Array)
], Client.prototype, "addresses", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => ClientAddress_1.ClientAddress, { nullable: true }),
    (0, typeorm_1.JoinColumn)(),
    __metadata("design:type", ClientAddress_1.ClientAddress)
], Client.prototype, "invoiceAddress", void 0);
__decorate([
    (0, typeorm_1.RelationId)((c) => c.invoiceAddress),
    __metadata("design:type", String)
], Client.prototype, "invoiceAddressId", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => ClientAddress_1.ClientAddress, { nullable: true }),
    (0, typeorm_1.JoinColumn)(),
    __metadata("design:type", ClientAddress_1.ClientAddress)
], Client.prototype, "headQuarterAddress", void 0);
__decorate([
    (0, typeorm_1.RelationId)((c) => c.headQuarterAddress),
    __metadata("design:type", String)
], Client.prototype, "headerQuarterAddressId", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => LargeScaleProject_1.LargeScaleProject, (p) => p.client),
    __metadata("design:type", Array)
], Client.prototype, "largeScaleProjects", void 0);
Client = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.Unique)(['name']),
    (0, typeorm_1.Unique)(['taxId'])
], Client);
exports.Client = Client;
