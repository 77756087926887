import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { ColumnKey } from '../types';
import { useTableConfigContext } from '../context/DataTableContext';
import { ColumnFilter } from './column-filter.types';

export function useTableFilterControl<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(key: ColumnKey<T, C>) {
  const {
    tableCells,
    tableFilterState: { filterState, setFilterState }
  } = useTableConfigContext<T, C>();
  return React.useMemo(
    () => ({
      filterable: tableCells[key].renderer.filter !== undefined,
      state: filterState[key]?.filterState,
      active: filterState[key]?.active,
      setActive: (active: boolean) => {
        setFilterState({
          ...filterState,
          [key]: { ...filterState[key], active }
        });
      },
      changeState: (
        value: C[ColumnKey<T, C>]['renderer']['filter'] extends ColumnFilter<
          any,
          any,
          infer S
        >
          ? S
          : never
      ) => {
        setFilterState({
          ...filterState,
          [key]: { ...filterState[key], filterState: value }
        });
      },
      resetState: () => {
        setFilterState({
          ...filterState,
          [key]: {
            ...filterState[key],
            filterState: tableCells[key].renderer.filter?.initialState
          }
        });
      }
    }),
    [tableCells, filterState, setFilterState]
  );
}
