import React, { useRef } from 'react';
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import 'react-mde/lib/styles/css/react-mde-all.css';
import { useDropzone } from 'react-dropzone';
import { UploadFile } from 'model';
import {
  Cancel,
  CheckCircleOutline as CheckCircleOutlineIcon,
  ErrorOutline as ErrorOutlineIcon,
  Refresh
} from '@material-ui/icons';
import { IconButton, LinearProgress, makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { useSeafileUpload } from '../file/searfile-upload.hook';

export interface ClientCommentEditorProps {
  value: string;
  autoFocus?: boolean;
  onChange: (val: string) => void;
  onFileChange?: (
    attachedFiles: (UploadFile & { grant: string })[],
    done: boolean
  ) => void;
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '1px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const useStyles = makeStyles((theme) =>
  createStyles({
    successIcon: {
      color: theme.palette.success.main
    }
  })
);

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

export function CommentEditor({
  value,
  onChange,
  onFileChange,
  autoFocus = false
}: ClientCommentEditorProps) {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = React.useState<'write' | 'preview'>(
    'write'
  );

  const { uploadFiles, uploadStates } = useSeafileUpload({ onFileChange });

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: uploadFiles
  });
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  React.useEffect(() => {
    if (autoFocus && textAreaRef.current !== null) {
      textAreaRef.current?.focus();
    }
  }, [textAreaRef, textAreaRef.current]);
  return (
    <div>
      <ReactMde
        refs={{
          textarea: textAreaRef
        }}
        maxEditorHeight={100}
        value={value}
        onChange={onChange}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(converter.makeHtml(markdown))
        }
        childProps={{
          writeButton: {
            tabIndex: -1
          }
        }}
      />
      {onFileChange && (
        <div
          {...getRootProps({ className: 'dropzone', style: baseStyle as any })}
        >
          <input {...getInputProps()} />
          <p>Drag and drop some files here, or click to select files</p>
        </div>
      )}
      {onFileChange && (
        <div>
          {Object.values(uploadStates).map((state) => {
            return (
              <div>
                {state.file.name}: {state.state} /{' '}
                {state.state === 'pending' && (
                  <LinearProgress
                    variant="determinate"
                    value={Math.round(state.progress * 100)}
                  />
                )}
                {state.state === 'error' && (
                  <div>
                    Upload failed <ErrorOutlineIcon color="error" />
                    <IconButton onClick={state.retry} size="small">
                      <Refresh fontSize="small" />
                    </IconButton>
                    <IconButton onClick={state.remove} size="small">
                      <Cancel fontSize="small" />
                    </IconButton>
                  </div>
                )}
                {state.state === 'uploaded' && (
                  <div>
                    Upload successful
                    <CheckCircleOutlineIcon className={classes.successIcon} />
                    <IconButton onClick={state.remove} size="small">
                      <Cancel fontSize="small" />
                    </IconButton>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
