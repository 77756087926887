import React from 'react';
import { Avatar, Box, Grid } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { User, userAvatarSelfPermission } from 'model';
import { useCardMode } from '../../../components/input/card/context';
import { ActiveUserDisplay } from './ActiveUserDisplay';
import { EditableTextDisplay } from '../../../components/input/card/EditableTextDisplay';
import { UserAvatar } from '../../../components/image/UserAvatar';
import { FileUploadIconButton } from '../../../components/input/button/FileUploadIconButton';

interface UserProfileFormProps {
  onSelectAvatar: (file: File) => void;
  user: User;
}

export function UserProfileForm(props: UserProfileFormProps) {
  const { t } = useTranslation();
  const cardMode = useCardMode();

  const [newAvatarFileUrl, setNewAvatarFileUrl] = React.useState<string>();

  const handleFileSelected = (files: File[]) => {
    setNewAvatarFileUrl(URL.createObjectURL(files[0]));
    props.onSelectAvatar(files[0]);
  };

  return cardMode === 'view' ? (
    <ActiveUserDisplay />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EditableTextDisplay name="displayName" label={t('Display Name')} />
      </Grid>
      <Grid item xs={12}>
        <Box display="inline-flex" alignItems="center">
          {newAvatarFileUrl ? (
            <Avatar src={newAvatarFileUrl} />
          ) : (
            <UserAvatar
              avatar={props.user.avatar}
              permission={userAvatarSelfPermission}
              grant={userAvatarSelfPermission.createGrant(props.user)}
            />
          )}
          <FileUploadIconButton
            icon={Edit}
            onFilesSelected={handleFileSelected}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
