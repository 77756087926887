import { Project, ProjectCandidate, Supplier } from 'model';
import { useTranslation } from 'react-i18next';
import {
  Cancel,
  Check,
  History,
  IndeterminateCheckBox,
  ThumbDown,
  ThumbUp
} from '@material-ui/icons';
import React from 'react';
import { Box, Popover, Typography } from '@material-ui/core';
import { Skeleton, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { compareAsc } from 'date-fns';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import {
  defineEntityMap,
  EntityMapType,
  useEntityMap
} from '../../../redux/entity-map';
import { supplierApi } from '../redux/suppliers.slice';
import { projectAPI } from '../../projects/redux/projects.slice';
import {
  entityAccessor,
  lazyConf
} from '../../../components/table/renderer/TableDataRenderer';
import { formatDate } from '../../../localized-formats';
import { DateCellRenderer } from '../../../components/table/renderer/DateCellRenderer';
import { ProjectLink } from '../../projects/components/basic/ProjectLink';
import { recruitmentsSelectors } from '../redux/supplier-recruitments.slice';
import { listSupplierRecruimentsThunk } from '../redux/supplier-recruitments.thunk';
import { CandidateTimeline } from '../../projects/components/candidates/CandidateTimeline';
import { projectRequestThunk } from '../../projects/redux/project-requests.thunk';
import {
  TextColumnFilter,
  TextColumnFilterState
} from '../../../components/table/filter/TextColumnFilter';
import { ColumnFilter } from '../../../components/table/filter/column-filter.types';

function RecruitmentHistoryOverlay({
  project,
  candidate
}: {
  project: Project;
  candidate: ProjectCandidate;
}) {
  const [anchor, setAnchor] = React.useState<HTMLElement>();
  const dispatch = useAppDispatch();
  return (
    <>
      <ToggleButton
        value="check"
        size="small"
        selected={!!anchor}
        onChange={(e) => {
          setAnchor(anchor ? undefined : (e.target as HTMLElement));
          if (!anchor) {
            dispatch(
              projectRequestThunk.get({ id: project.requestId as string })
            );
          }
        }}
      >
        <History fontSize="small" />
      </ToggleButton>
      <Popover
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={() => setAnchor(undefined)}
        open={!!anchor}
        anchorEl={anchor}
      >
        <Box minWidth="25vw" fontSize="1.3em">
          <CandidateTimeline
            initialTimelineState="full"
            project={project}
            candidate={candidate}
          />
        </Box>
      </Popover>
    </>
  );
}

const statusOrder = {
  confirmed: 0,
  declined: 1,
  undetermined: 2
};
const recruitmentsEntityMap = defineEntityMap({
  project: projectAPI,
  supplier: supplierApi
});
function buildTableColumns(
  entityMap: EntityMapType<typeof recruitmentsEntityMap>
) {
  return (
    /*    .addDefaultColumn(
      'candidate_decided',
      (c) => c.decision ?? null,
      'Decided',
      {
        ...DateCellRenderer('date-time', true),
        createElement: (value) =>
          value ? <Check title={formatDate(value, 'date-time')} /> : <Cancel />
      }
    ) */
    entityColumnBuilder<ProjectCandidate>()
      .addDefaultColumn(
        'recruitment_project_name',
        entityAccessor(
          entityMap,
          ['project'],
          (d, eM) => eM.project[d.projectId]
        ),
        'Name',
        lazyConf({
          // eslint-disable-next-line func-names
          filter: (function () {
            const delegate = TextColumnFilter<ProjectCandidate>({});
            return {
              component: (props) => delegate.component(props as any),
              initialState: delegate.initialState,
              createFilter: (cell, state) => {
                const delFilter = delegate.createFilter(cell as any, state);
                return (value, data) => {
                  return delFilter(value.projectName, data);
                };
              },
              isEffective: delegate.isEffective
            } as ColumnFilter<ProjectCandidate, Project, TextColumnFilterState>;
          })(),
          comparator: ([a], [b]) => a.projectName.localeCompare(b.projectName),
          createText: (project) => [project.projectName],
          createElement: (project, candidate) => (
            <>
              <RecruitmentHistoryOverlay
                project={project}
                candidate={candidate}
              />
              <Box display="inline" padding="0.5em" />
              <ProjectLink value={project} />
            </>
          )
        })
      )
      .addDefaultColumn(
        'candidate_contacted_on',
        (c) => (c.contactedOn ?? null) as string | Date | null,
        'Contacted',
        {
          ...DateCellRenderer('date-time', true),
          createElement: (value) =>
            value ? (
              <Typography variant="subtitle2">
                <Check />
                {formatDate(value, 'date-time')}
              </Typography>
            ) : (
              <Cancel />
            )
        }
      )
      .addDefaultColumn(
        'candidate_decided_on',
        (c) => c.decision ?? null,
        'Decided',
        {
          createElement: (value) => {
            if (value) {
              return (
                <Typography variant="subtitle2">
                  {value.status === 'confirmed' ? (
                    <ThumbUp fontSize="small" htmlColor="green" />
                  ) : (
                    <ThumbDown fontSize="small" htmlColor="red" />
                  )}
                  {formatDate(value.createdAt, 'date-time')}
                </Typography>
              );
            }
            return <IndeterminateCheckBox fontSize="small" />;
          },
          createText: (value) => {
            return [value?.status ?? 'undetermined'];
          },
          comparator: ([a, a1], [b, b1]) => {
            const aStO = statusOrder[a?.status ?? 'undetermined'];
            const bStO = statusOrder[b?.status ?? 'undetermined'];
            if (aStO === bStO) {
              if (a?.createdAt && b?.createdAt) {
                return compareAsc(new Date(a.createdAt), new Date(b.createdAt));
              }
              return compareAsc(new Date(a1.createdAt), new Date(b1.createdAt));
            }
            return aStO - bStO;
          },
          filter: {
            initialState: {
              confirmed: true,
              declined: true,
              undetermined: true
            },
            createFilter: (cell, state) => {
              return (value) => {
                if (!value) {
                  return state.undetermined;
                }
                return !!state[value.status];
              };
            },
            isEffective: (_, state) =>
              !state.confirmed || !state.declined || !state.undetermined,
            // eslint-disable-next-line react/prop-types
            component: ({ onFilterChange, filterState }) => {
              return (
                <ToggleButtonGroup
                  value={Object.entries(filterState)
                    .filter(([, v]) => v === true)
                    .map(([k]) => k)}
                  onChange={(_, newState) =>
                    onFilterChange({
                      confirmed: newState.includes('confirmed'),
                      declined: newState.includes('declined'),
                      undetermined: newState.includes('undetermined')
                    })
                  }
                >
                  <ToggleButton size="small" value="confirmed">
                    <ThumbUp fontSize="small" htmlColor="green" />
                  </ToggleButton>
                  <ToggleButton size="small" value="declined">
                    <ThumbDown fontSize="small" htmlColor="red" />
                  </ToggleButton>
                  <ToggleButton size="small" value="undetermined">
                    <IndeterminateCheckBox fontSize="small" />
                  </ToggleButton>
                </ToggleButtonGroup>
              );
            }
          }
        }
      ).columns
  );
}

export function RecruitmentsTable({ supplier }: { supplier: Supplier }) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const refreshRecruitments = () => {
    dispatch(listSupplierRecruimentsThunk({ supplierId: supplier.id }));
  };

  const entityMap = useEntityMap(recruitmentsEntityMap);
  const tableCells = useCreateCells(buildTableColumns(entityMap), [entityMap]);

  const data = useAppSelector((rS) =>
    recruitmentsSelectors.selectById(rS, supplier.id)
  );

  React.useEffect(() => {
    if (!data || (data.apiState !== 'loading' && data.dataState === 'none')) {
      refreshRecruitments();
    }
  }, [data]);
  if (!data || !data.recruitments) {
    return <Skeleton />;
  }
  return (
    <GenericTable
      uiMode="widget"
      toolbarActions={[]}
      label={t('Recruitments')}
      refresh={refreshRecruitments}
      rowActions={[]}
      data={data.recruitments.filter((r) => !r.removedOn)}
      isLoading={data.apiState === 'loading'}
      tableCells={tableCells}
    />
  );
}
