import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import React from 'react';
import { ResourceRequestDTO, ResourceTypes } from 'model';
import * as yup from 'yup';
import { FormikDatePicker } from '../../../../../../components/input/date/FormikDatePicker';
import { EditableCheckboxDisplay } from '../../../../../../components/input/checkbox/EditableCheckboxDisplay';
import {
  buildDTOView,
  useTypedField
} from '../../../../../../transformer/DTOViewSchema';
import { FormikSelect } from '../../../../../../components/select/FormikSelect';
import { FileUploadIconButton } from '../../../../../../components/input/button/FileUploadIconButton';
import { FormikTextField } from '../../../../../../components/input/FormikTextField';

export const RequestResourceView = buildDTOView<ResourceRequestDTO>()
  .all()
  .withSchema(
    yup
      .object({
        name: yup.string().required('required').min(2, 'min length 2'),
        type: yup.mixed().oneOf(Object.keys(ResourceTypes)).required(),
        available: yup.boolean().required(),
        estimatedDelivery: yup.date().defined().nullable().default(null),
        description: yup.string().default(''),
        comment: yup
          .object({
            comment: yup.string().defined() as any,
            attachedFiles: yup
              .array()
              .of(
                yup
                  .object({
                    fileAccessId: yup.string().uuid().required() as any,
                    token: yup.string().required() as any
                  })
                  .required()
              )
              .notRequired()
              .default(undefined)
          })
          .nullable()
          .default(null),
        attachedFiles: yup.array().of(
          yup
            .object({
              fileAccessId: yup.string().uuid().required() as any,
              token: yup.string().required() as any
            })
            .required()
        )
      })
      .defined()
  );

export function RequestResourceForm({
  basePath = RequestResourceView.path
}: {
  basePath?: (typeof RequestResourceView)['path'];
}) {
  const { t } = useTranslation();

  const [, attachedFilesMeta, attachedFilesHelper] = useTypedField<
    ResourceRequestDTO['attachedFiles']
  >(basePath?.sub_path('attachedFiles') ?? '');

  const [, availableMeta] = useTypedField(basePath.available);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormikTextField required name={basePath.name} label={t('Name')} />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          name={basePath.description}
          label={t('Description')}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <FormikSelect
          required
          name={basePath.type}
          label={t('Resource Type')}
          options={Object.entries(ResourceTypes).map(([type, desc]) => ({
            label: t(desc.name),
            value: type
          }))}
        />
      </Grid>
      {!availableMeta.value && (
        <Grid item xs={12}>
          <FormikDatePicker
            name={basePath.estimatedDelivery}
            label={t('Estimated Delivery')}
          />
        </Grid>
      )}
      <Grid item xs={3} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <EditableCheckboxDisplay
          name={basePath.available}
          label={t('Available')}
        />
      </Grid>

      <Grid item xs={1}>
        <FileUploadIconButton
          uploadDisabled={
            !!attachedFilesMeta?.value?.length || !availableMeta.value
          }
          onFileChange={(attachedFiles) => {
            attachedFilesHelper.setValue([
              ...(attachedFilesMeta?.value ?? []),
              ...attachedFiles.map((af) => ({
                fileAccessId: af.id,
                token: af.grant
              }))
            ]);
          }}
        />
      </Grid>
    </Grid>
  );
}
