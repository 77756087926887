import React from 'react';
import { UploadFile, User, userAvatarPermission } from 'model';
import { TimelineDot } from '@material-ui/lab';
import { AddComment, Close as CancelIcon } from '@material-ui/icons';
import { Box, Button, IconButton, TextField } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../../../../redux/redux.hooks';
import { selectUserAsNullable } from '../../../auth/redux/auth.slice';
import { UserAvatar } from '../../../../components/image/UserAvatar';
import { CommentEditor } from '../../../../components/comment/CommentEditor';
import { createRequestCommentThunk } from '../../redux/request-comment.thunk';
import { NoOp } from '../../../../utils';
import { CommentTimelineItem } from './ProjectCommentTimeline';

export interface ProjectCommentEditorProps {
  requestId: string;
}

function EditorSkeleton({
  user,
  onActivated
}: {
  user: User;
  onActivated: () => void;
}) {
  return (
    <Box display="flex" alignItems="center">
      <UserAvatar
        style={{ width: '2em', height: '2em', margin: '0.5em' }}
        avatar={user.avatar}
        grant={userAvatarPermission.createGrant(user)}
        permission={userAvatarPermission}
      />
      <TextField
        fullWidth
        onFocus={onActivated}
        variant="outlined"
        margin="dense"
      />
    </Box>
  );
}

function ProjectCommentActiveEditor({
  requestId,
  onInactivate
}: {
  requestId: string;
  onInactivate: () => void;
}) {
  const [commentValue, setCommentValue] = React.useState('');
  const dispatch = useAppDispatch();
  const [attachedFiles, setAttachedFiles] = React.useState<
    (UploadFile & { grant: string })[] | null
  >([]);

  return (
    <>
      <CommentEditor
        autoFocus
        value={commentValue}
        onChange={setCommentValue}
        onFileChange={(attachedFiles1, done) =>
          setAttachedFiles(done ? attachedFiles1 : null)
        }
      />
      <div style={{ textAlign: 'end' }}>
        <IconButton onClick={onInactivate}>
          <CancelIcon />
        </IconButton>
        <Button
          disabled={attachedFiles === null}
          onClick={() => {
            if (attachedFiles !== null) {
              dispatch(
                createRequestCommentThunk({
                  requestId,
                  input: {
                    comment: commentValue,
                    attachedFiles: attachedFiles.map((f) => ({
                      fileAccessId: f.id,
                      token: f.grant
                    }))
                  }
                })
              )
                .then(unwrapResult)
                .catch(NoOp);
              setCommentValue('');
              onInactivate();
            }
          }}
          variant="outlined"
          color="primary"
        >
          Submit Comment
        </Button>
      </div>
    </>
  );
}

export function ProjectCommentEditor({ requestId }: ProjectCommentEditorProps) {
  const [active, setActive] = React.useState(false);

  const user = useAppSelector(selectUserAsNullable);
  return (
    user && (
      <CommentTimelineItem
        timelineSymbol={
          <TimelineDot>
            <AddComment />
          </TimelineDot>
        }
        itemProps={{ style: { opacity: active ? 1 : 0.3 } }}
      >
        <>
          {!active && (
            <EditorSkeleton user={user} onActivated={() => setActive(true)} />
          )}
          {active && (
            <ProjectCommentActiveEditor
              requestId={requestId}
              onInactivate={() => setActive(false)}
            />
          )}
        </>
      </CommentTimelineItem>
    )
  );
}
