import React from 'react';
import { permissions, Supplier, supplierPatchTransformer } from 'model';
import { useTranslation } from 'react-i18next';
import { NewEditableCard } from '../../../../components/card/editableCard/NewEditableCard';
import { SupplierBasicDisplay } from './SupplierBasicDisplay';
import { BasicSupplierView, SupplierBasicForm } from './SupplierBasicForm';
import { useSupplierUpdate } from '../../hooks/supplier-update.hook';

export function SupplierBasicCard({ supplier }: { supplier: Supplier }) {
  const { t } = useTranslation();
  const updateSupplier = useSupplierUpdate(supplier.id);
  return (
    <NewEditableCard
      identifier="basic"
      value={supplier}
      display={{ component: SupplierBasicDisplay }}
      form={{
        component: SupplierBasicForm,
        dtoTransformer: supplierPatchTransformer,
        validationSchema: BasicSupplierView.validationSchema,
        viewFactory: BasicSupplierView.viewFactory,
        onSubmit: updateSupplier,
        permissions: permissions.suppliers.supplier.edit
      }}
      title={t('Basic')}
    />
  );
}
