"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectCandidateDecision = exports.DecisionStatusNames = void 0;
const typeorm_1 = require("typeorm");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const ProjectCandidate_1 = require("./ProjectCandidate");
// eslint-disable-next-line import/no-cycle
const RequestComment_1 = require("./RequestComment");
const CircularReference_1 = require("../../serialization/CircularReference");
exports.DecisionStatusNames = ['confirmed', 'declined'];
let ProjectCandidateDecision = class ProjectCandidateDecision extends JournaledEntity_1.JournaledEntity {
};
__decorate([
    (0, typeorm_1.OneToOne)(() => ProjectCandidate_1.ProjectCandidate, (pc) => pc.decision),
    (0, typeorm_1.JoinColumn)(),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", ProjectCandidate_1.ProjectCandidate)
], ProjectCandidateDecision.prototype, "projectCandidate", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pc) => pc.projectCandidate),
    __metadata("design:type", String)
], ProjectCandidateDecision.prototype, "projectCandidateId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], ProjectCandidateDecision.prototype, "status", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => RequestComment_1.RequestComment, undefined, { nullable: true }),
    __metadata("design:type", RequestComment_1.RequestComment)
], ProjectCandidateDecision.prototype, "comment", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pc) => pc.comment),
    __metadata("design:type", String)
], ProjectCandidateDecision.prototype, "commentId", void 0);
ProjectCandidateDecision = __decorate([
    (0, typeorm_1.Entity)()
], ProjectCandidateDecision);
exports.ProjectCandidateDecision = ProjectCandidateDecision;
