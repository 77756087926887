import React from 'react';
import { largeScaleProjectSelectors } from '../redux/large-scale-projects.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { largeScaleProjectThunk } from '../redux/large-scale-projects.thunk';

export function useLargeScaleProject(id: string) {
  const dispatch = useAppDispatch();

  const largeScaleProject = useAppSelector((state) =>
    largeScaleProjectSelectors.selectById(state, id)
  );

  React.useEffect(() => {
    dispatch(largeScaleProjectThunk.get({ id }));
  }, []);

  return largeScaleProject;
}
