import React, { PropsWithChildren } from 'react';

export const SeafileImageCacheContext = React.createContext({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFileState: (id: string, state?: string | null) => {},
  files: {} as Record<string, string | null | undefined>
});

export function SeafileImageCacheProvider(props: PropsWithChildren<any>) {
  const [files, setFiles] = React.useState(
    {} as Record<string, string | null | undefined>
  );
  return (
    <SeafileImageCacheContext.Provider
      value={{
        files,
        setFileState: (id, state) => {
          setFiles({ ...files, [id]: state });
        }
      }}
    >
      {props.children}
    </SeafileImageCacheContext.Provider>
  );
}
