import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { PropertyPath, useTypedField } from '../../transformer/DTOViewSchema';

interface FormikTextFieldProps {
  name: string | PropertyPath<any, number | string | null>;
  label?: string;
}

export function FormikTextField(
  props: FormikTextFieldProps & Omit<TextFieldProps, 'name'>
) {
  const [, meta, helpers] = useTypedField<number | string | null>(props.name);
  const { name, type, ...restProps } = props;
  return (
    <TextField
      fullWidth
      {...restProps}
      type={type === 'password' || type === 'number' ? type : undefined}
      value={meta.value || ''}
      onChange={(e) =>
        type === 'number'
          ? helpers.setValue(parseFloat(e.target.value))
          : helpers.setValue(e.target.value)
      }
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}
