import React from 'react';
import {
  permissions,
  ProjectRequest,
  RequestPatchDTO,
  requestPatchTransform
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { NewEditableCard } from '../../../../../components/card/editableCard/NewEditableCard';
import { RequestBasicDisplay } from './RequestBasicDisplay';
import { RequestBasicForm } from './RequestBasicForm';
import { NoOp } from '../../../../../utils';
import { RequestBasicView } from './RequestBasicView';
import { useAppDispatch } from '../../../../../redux/redux.hooks';
import { projectRequestThunk } from '../../../redux/project-requests.thunk';

interface RequestBasicCardProps {
  request: ProjectRequest;
  title?: string;
}

export function RequestBasicCard({
  request,
  title = 'Basic'
}: RequestBasicCardProps) {
  const dispatch = useAppDispatch();

  const editRequest = (input: RequestPatchDTO) =>
    dispatch(projectRequestThunk.update({ id: request.id, input }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <NewEditableCard
      identifier="request-basic"
      title={title}
      value={request}
      display={{ component: RequestBasicDisplay }}
      form={{
        component: () => <RequestBasicForm />,
        validationSchema: RequestBasicView.validationSchema,
        viewFactory: RequestBasicView.viewFactory,
        dtoTransformer: requestPatchTransform,
        onSubmit: editRequest,
        permissions: permissions.project.projectRequest.edit
      }}
    />
  );
}
