import { RequestPatchDTO } from 'model';
import * as yup from 'yup';
import { buildDTOView } from '../../../../../transformer/DTOViewSchema';

export const RequestBasicView = buildDTOView<RequestPatchDTO>()
  .exclude('projectId')
  .withSchema(
    yup
      .object({
        requestSourceId: yup.string().nullable().uuid().required('required'),
        dateOfRequest: yup.date().defined() as any,
        deadline: yup.date().defined().nullable().default(null),
        expressProject: yup.boolean().required(),
        comment: yup
          .object({
            comment: yup.string().defined() as any,
            attachedFiles: yup
              .array()
              .of(
                yup
                  .object({
                    fileAccessId: yup.string().uuid().required() as any,
                    token: yup.string().required() as any
                  })
                  .required()
              )
              .notRequired()
              .default(undefined)
          })
          .nullable()
          .default(null),
        requestStatusId: yup.string().uuid().required()
      })
      .defined()
  );
