"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectCandidate = void 0;
const typeorm_1 = require("typeorm");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const Project_1 = require("./Project");
// eslint-disable-next-line import/no-cycle
const RequestPosition_1 = require("./RequestPosition");
// eslint-disable-next-line import/no-cycle
const ProjectCandidateDecision_1 = require("./ProjectCandidateDecision");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("../../supplier/entity/Supplier");
let ProjectCandidate = class ProjectCandidate extends JournaledEntity_1.JournaledEntity {
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => Project_1.Project, (p) => p.projectCandidates),
    __metadata("design:type", Project_1.Project)
], ProjectCandidate.prototype, "project", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pc) => pc.project),
    __metadata("design:type", String)
], ProjectCandidate.prototype, "projectId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => RequestPosition_1.RequestPosition, undefined, { nullable: true }),
    __metadata("design:type", RequestPosition_1.RequestPosition)
], ProjectCandidate.prototype, "position", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pc) => pc.position),
    __metadata("design:type", String)
], ProjectCandidate.prototype, "positionId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier),
    __metadata("design:type", Supplier_1.Supplier)
], ProjectCandidate.prototype, "supplier", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pc) => pc.supplier),
    __metadata("design:type", String)
], ProjectCandidate.prototype, "supplierId", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'timestamp', nullable: true }),
    __metadata("design:type", Date)
], ProjectCandidate.prototype, "contactedOn", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'timestamp', nullable: true }),
    __metadata("design:type", Date)
], ProjectCandidate.prototype, "removedOn", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => ProjectCandidateDecision_1.ProjectCandidateDecision, (pc) => pc.projectCandidate, {
        nullable: true
    }),
    __metadata("design:type", ProjectCandidateDecision_1.ProjectCandidateDecision)
], ProjectCandidate.prototype, "decision", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pc) => pc.decision),
    __metadata("design:type", String)
], ProjectCandidate.prototype, "decisionId", void 0);
ProjectCandidate = __decorate([
    (0, typeorm_1.Entity)()
], ProjectCandidate);
exports.ProjectCandidate = ProjectCandidate;
