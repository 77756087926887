import { SupplierDomain, SupplierDomainDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const supplierDomainsThunk = createFeatureThunks<
  SupplierDomain,
  SupplierDomainDTO,
  SupplierDomainDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/suppliers/domain${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'supplier-domains'
});
