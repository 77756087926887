import { ProjectOfferDTO } from 'model';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import React from 'react';
import {
  buildDTOView,
  useTypedField
} from '../../../../../transformer/DTOViewSchema';
import { FormikDatePicker } from '../../../../../components/input/date/FormikDatePicker';
import { FormikTextField } from '../../../../../components/input/FormikTextField';
import { CommentEditor } from '../../../../../components/comment/CommentEditor';
import { FormikCurrencySelect } from '../../../../../components/select/FormikCurrencySelect';

export const ProjectOfferView = buildDTOView<ProjectOfferDTO>()
  .all()
  .withSchema(
    yup
      .object({
        offerDate: yup.date().required() as any,
        validUntil: yup.date().defined().nullable().default(null),
        currency: yup.string().defined().nullable().default(null),
        taxPercentage: yup
          .number()
          .min(0)
          .max(100)
          .defined()
          .nullable()
          .default(null),
        totalPrice: yup.number().defined().nullable().default(null),
        positions: yup
          .array(
            yup
              .object({
                positionType: yup.string().oneOf(['scalar', 'group']).required()
              })
              // eslint-disable-next-line no-template-curly-in-string
              .test('is-position-type', '${path} is no valid position', (v) => {
                if (v.positionType === 'scalar') {
                  return yup
                    .object({
                      id: yup.string().uuid().optional().default(undefined),
                      positionType: yup.mixed().oneOf(['scalar']).required(),
                      totalPrice: yup
                        .number()
                        .defined()
                        .nullable()
                        .default(null),
                      name: yup.string().required(),
                      description: yup
                        .string()
                        .defined()
                        .nullable()
                        .default(null),
                      amount: yup.number().required(),
                      amountDescription: yup
                        .string()
                        .defined()
                        .nullable()
                        .default(null),
                      unitPriceDescription: yup
                        .string()
                        .defined()
                        .nullable()
                        .default(null),
                      priceUnitId: yup
                        .string()
                        .uuid()
                        .defined()
                        .nullable()
                        .default(null),
                      unitPrice: yup.number().required()
                    })
                    .defined()
                    .isValid(v);
                }
                if (v.positionType === 'group') {
                  return yup
                    .object({
                      id: yup.string().uuid().optional().default(undefined),
                      positionType: yup.mixed().oneOf(['group']).required(),
                      totalPrice: yup
                        .number()
                        .defined()
                        .nullable()
                        .default(null),
                      name: yup.string().required(),
                      description: yup.string().required(),
                      positions: yup
                        .array(
                          yup
                            .object({
                              id: yup
                                .string()
                                .uuid()
                                .optional()
                                .default(undefined),
                              positionType: yup
                                .mixed()
                                .oneOf(['scalar'])
                                .required(),
                              totalPrice: yup
                                .number()
                                .defined()
                                .nullable()
                                .default(null),
                              name: yup.string().required(),
                              description: yup.string().required(),
                              amount: yup.number().required(),
                              priceUnitId: yup
                                .string()
                                .uuid()
                                .defined()
                                .nullable()
                                .default(null),
                              unitPrice: yup.number().required()
                            })
                            .required()
                        )
                        .required()
                    })
                    .required()
                    .isValid(v);
                }
                return Promise.resolve(false);
              })
              .required()
          )
          .defined() as any,
        attachedFiles: yup
          .array()
          .of(
            yup
              .object({
                fileAccessId: yup.string().uuid().required() as any,
                token: yup.string().required() as any
              })
              .required()
          )
          .notRequired()
          .default(undefined),
        comment: yup
          .object({
            comment: yup.string().defined().default(''),
            attachedFiles: yup
              .array()
              .of(
                yup
                  .object({
                    fileAccessId: yup.string().uuid().required() as any,
                    token: yup.string().required() as any
                  })
                  .required()
              )
              .notRequired()
              .default(undefined)
          })
          .nullable()
          .default(null)
      })
      .defined()
  );

export function RequestOfferForm() {
  const [, commentMeta, commentHelpers] = useTypedField(
    ProjectOfferView.path.comment
  );
  const [, filesMeta, filesHelpers] = useTypedField(
    ProjectOfferView.path.attachedFiles
  );

  return (
    <Grid container xs={12}>
      <Grid item xs={4}>
        <FormikCurrencySelect
          name={ProjectOfferView.path.currency}
          textFieldProps={{ label: 'Currency' }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormikDatePicker
          name={ProjectOfferView.path.offerDate}
          label="Offer Date"
        />
      </Grid>
      <Grid item xs={4}>
        <FormikDatePicker
          name={ProjectOfferView.path.validUntil}
          label="Valid Until"
        />
      </Grid>
      <Grid item xs={4}>
        <FormikTextField
          name={(ProjectOfferView.path.totalPrice as any).amount}
          type="number"
          label="Total Price"
        />
      </Grid>
      <Grid item xs={12}>
        <CommentEditor
          value={commentMeta.value?.comment ?? ''}
          onChange={(value) =>
            commentHelpers.setValue({ ...commentMeta.value, comment: value })
          }
          onFileChange={(attachedFiles, done) => {
            if (done) {
              commentHelpers.setValue({
                ...commentMeta.value,
                comment: commentMeta.value?.comment ?? '',
                attachedFiles: [
                  ...(commentMeta.value?.attachedFiles ?? []),
                  ...attachedFiles.map((af) => ({
                    fileAccessId: af.id,
                    token: af.grant
                  }))
                ]
              });
              filesHelpers.setValue([
                ...(filesMeta.value ?? []),
                ...attachedFiles.map((af) => ({
                  fileAccessId: af.id,
                  token: af.grant
                }))
              ]);
            }
          }}
        />
      </Grid>
    </Grid>
  );
}
