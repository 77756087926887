import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  Supplier,
  supplierAttachmentPermission,
  SupplierCommentAttachment,
  UploadFile,
  User,
  userAvatarPermission
} from 'model';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core';
import { Attachment, Close as CancelIcon, GetApp } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import * as Showdown from 'showdown';
import { unwrapResult } from '@reduxjs/toolkit';
import { CardWithHeader } from '../../../../components/card/cardWithHeader/CardWithHeader';
import { useAppDispatch, useAppSelector } from '../../../../redux/redux.hooks';
import { RootState } from '../../../../redux/store';
import { supplierAttachmentsSelectors } from '../../redux/supplier-attachments.slice';
import { UserAvatar } from '../../../../components/image/UserAvatar';
import { selectUserAsNullable } from '../../../auth/redux/auth.slice';
import { CommentAuthorDisplay } from '../../../projects/components/comments/CommentAuthorDisplay';
import { createFileGrant } from '../../../../utils';
import { CommentEditor } from '../../../../components/comment/CommentEditor';
import { supplierAttachmentsThunk } from '../../redux/supplier-attachments.thunk';

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

function SupplierCommentEditorSkeleton({
  user,
  onActivated
}: {
  user: User;
  onActivated: () => void;
}) {
  return (
    <Box display="flex" alignItems="center">
      <UserAvatar
        style={{ width: '2em', height: '2em', margin: '0.5em' }}
        avatar={user.avatar}
        grant={userAvatarPermission.createGrant(user)}
        permission={userAvatarPermission}
      />
      <TextField
        fullWidth
        onFocus={onActivated}
        variant="outlined"
        margin="dense"
      />
    </Box>
  );
}

function SupplierCommentEditor({
  supplierId,
  onInactivate
}: {
  supplierId: string;
  onInactivate: () => void;
}) {
  const [commentValue, setCommentValue] = React.useState('');
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const [attachedFiles, setAttachedFiles] = React.useState<
    (UploadFile & { grant: string })[] | null
  >([]);

  return (
    <>
      <CommentEditor
        autoFocus
        value={commentValue}
        onChange={setCommentValue}
        onFileChange={(attachedFiles1, done) =>
          setAttachedFiles(done ? attachedFiles1 : null)
        }
      />
      {error && (
        <Typography color="error">Fehler beim Speicher: {error}</Typography>
      )}
      <div style={{ textAlign: 'end' }}>
        <IconButton onClick={onInactivate}>
          <CancelIcon />
        </IconButton>
        <Button
          startIcon={
            loading ? (
              <CircularProgress style={{ fontSize: '0.3em' }} />
            ) : undefined
          }
          disabled={attachedFiles === null || loading}
          onClick={() => {
            if (attachedFiles !== null) {
              setLoading(true);
              setError(null);
              dispatch(
                supplierAttachmentsThunk.create({
                  supplierId,
                  input: {
                    attachment: {
                      comment: commentValue,
                      pin: false,
                      attachmentType: 'comment',
                      attachedFiles: attachedFiles.map((f) => ({
                        fileAccessId: f.id,
                        token: f.grant
                      }))
                    }
                  }
                })
              )
                .then(unwrapResult)
                .then((res) => {
                  setLoading(false);
                  setCommentValue('');
                  onInactivate();
                  return res;
                })
                .catch((err) => {
                  setLoading(false);
                  setError(`${err}`);
                });
            }
          }}
          variant="outlined"
          color="primary"
        >
          Submit Comment
        </Button>
      </div>
    </>
  );
}

function SupplierCommentView({
  comment
}: {
  comment: SupplierCommentAttachment;
}) {
  return (
    <Box style={{ padding: '0.5em 0.5em', margin: 0 }}>
      <CommentAuthorDisplay
        author={{ type: 'user', user: comment.author }}
        date={comment.createdAt}
        actions={[]}
      />
      <Box display="flex" paddingLeft="2.5em" justifyContent="flex-end">
        {comment.comment.trim() !== '' && (
          <Box display="flex" flexGrow={1}>
            <Box display="flex" flexDirection="column" flexGrow={1}>
              <div
                style={{ textAlign: 'left' }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(comment.comment)
                }}
              />
            </Box>
          </Box>
        )}
        <Box display="flex">
          {comment.attachedFiles?.length > 0 && (
            <>
              <Box display="flex" height="100%" paddingX="1em">
                <Divider orientation="vertical" flexItem />
              </Box>
              <Box>
                <Box display="flex" alignItems="center">
                  <Attachment fontSize="small" />
                  <Typography variant="subtitle1">Attachments</Typography>
                </Box>
                <List dense style={{ minWidth: '25em' }}>
                  {comment.attachedFiles.map((af) => (
                    <ListItem dense>
                      <ListItemText>
                        <Typography
                          title={af.name}
                          variant="caption"
                          style={{
                            maxWidth: '25em',
                            maxLines: 1,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            display: 'block'
                          }}
                        >
                          {af.name}
                        </Typography>
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton
                          size="small"
                          component={RouterLink}
                          to={`/files/${af.id}/${
                            createFileGrant(
                              af.id,
                              supplierAttachmentPermission,
                              comment as any
                            ).token
                          }`}
                          target="blank"
                        >
                          <GetApp />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box display="flex" height="100%" paddingX="1em">
                <Divider orientation="vertical" flexItem />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export function SupplierCommentCard({ supplier }: { supplier: Supplier }) {
  const { t } = useTranslation();
  const [active, setActive] = React.useState(false);

  const user = useAppSelector(selectUserAsNullable);

  const attachments = useAppSelector((rootState: RootState) =>
    supplierAttachmentsSelectors.selectBySupplier(rootState, supplier.id)
  );

  const comments = attachments.filter(
    (a) => a.attachmentType === 'comment'
  ) as SupplierCommentAttachment[];
  const sortedComments = comments.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );
  return (
    <CardWithHeader
      cardProps={{
        style: {
          height: '100%'
        }
      }}
      title={t('Comments')}
      actions={[]}
    >
      {user && (
        <Box style={{ opacity: active ? undefined : 0.5 }}>
          {!active && (
            <SupplierCommentEditorSkeleton
              user={user}
              onActivated={() => setActive(true)}
            />
          )}
          {active && (
            <SupplierCommentEditor
              supplierId={supplier.id}
              onInactivate={() => setActive(false)}
            />
          )}
        </Box>
      )}
      <Box
        style={{
          maxHeight: '35vh',
          overflow: 'auto'
        }}
      >
        {sortedComments.map((comment) => (
          <Box style={{ width: '100%' }}>
            <Divider style={{ marginTop: '0.25em', marginBottom: '0.25em' }} />
            <SupplierCommentView comment={comment} />
          </Box>
        ))}
      </Box>
    </CardWithHeader>
  );
}
