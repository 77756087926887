import { ClientNumberDTO } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';
import { loadClientThunk } from './load-client.thunk';

export const assignClientNumberThunk = createErrorHandlingThunk<
  void,
  { id: string; input: ClientNumberDTO }
>('client/assign-client-number', async ({ id, input }, thunkAPI) => {
  await apiClient.post(`/client/${id}/client-number`, input);
  thunkAPI.dispatch(loadClientThunk({ id }));
});

export const updateClientNumberThunk = createErrorHandlingThunk<
  void,
  { id: string; input: ClientNumberDTO }
>('client/update-client-number', async ({ id, input }, thunkAPI) => {
  await apiClient.put(`/client/${id}/client-number`, input);
  thunkAPI.dispatch(loadClientThunk({ id }));
});

export const removeClientNumberThunk = createErrorHandlingThunk<
  void,
  { id: string }
>('client/remove-client-number', async ({ id }, thunkAPI) => {
  await apiClient.delete(`/client/${id}/client-number`);
  thunkAPI.dispatch(loadClientThunk({ id }));
});
