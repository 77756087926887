"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Alphabets = void 0;
exports.Alphabets = {
    adlm: { code: 'Adlm', number: 166, name: 'Adlam' },
    afak: { code: 'Afak', number: 439, name: 'Afaka' },
    aghb: { code: 'Aghb', number: 239, name: 'Caucasian Albanian' },
    ahom: { code: 'Ahom', number: 338, name: 'Ahom, Tai Ahom' },
    arab: { code: 'Arab', number: 160, name: 'Arabic' },
    aran: { code: 'Aran', number: 161, name: 'Arabic (Nastaliq variant)' },
    armi: { code: 'Armi', number: 124, name: 'Imperial Aramaic' },
    armn: { code: 'Armn', number: 230, name: 'Armenian' },
    avst: { code: 'Avst', number: 134, name: 'Avestan' },
    bali: { code: 'Bali', number: 360, name: 'Balinese' },
    bamu: { code: 'Bamu', number: 435, name: 'Bamum' },
    bass: { code: 'Bass', number: 259, name: 'Bassa Vah' },
    batk: { code: 'Batk', number: 365, name: 'Batak' },
    beng: { code: 'Beng', number: 325, name: 'Bengali (Bangla)' },
    bhks: { code: 'Bhks', number: 334, name: 'Bhaiksuki' },
    blis: { code: 'Blis', number: 550, name: 'Blissymbols' },
    bopo: { code: 'Bopo', number: 285, name: 'Bopomofo' },
    brah: { code: 'Brah', number: 300, name: 'Brahmi' },
    brai: { code: 'Brai', number: 570, name: 'Braille' },
    bugi: { code: 'Bugi', number: 367, name: 'Buginese' },
    buhd: { code: 'Buhd', number: 372, name: 'Buhid' },
    cakm: { code: 'Cakm', number: 349, name: 'Chakma' },
    cans: {
        code: 'Cans',
        number: 440,
        name: 'Unified Canadian Aboriginal Syllabics'
    },
    cari: { code: 'Cari', number: 201, name: 'Carian' },
    cham: { code: 'Cham', number: 358, name: 'Cham' },
    cher: { code: 'Cher', number: 445, name: 'Cherokee' },
    chrs: { code: 'Chrs', number: 109, name: 'Chorasmian' },
    cirt: { code: 'Cirt', number: 291, name: 'Cirth' },
    copt: { code: 'Copt', number: 204, name: 'Coptic' },
    cpmn: { code: 'Cpmn', number: 402, name: 'Cypro-Minoan' },
    cprt: { code: 'Cprt', number: 403, name: 'Cypriot syllabary' },
    cyrl: { code: 'Cyrl', number: 220, name: 'Cyrillic' },
    cyrs: {
        code: 'Cyrs',
        number: 221,
        name: 'Cyrillic (Old Church Slavonic variant)'
    },
    deva: { code: 'Deva', number: 315, name: 'Devanagari (Nagari)' },
    diak: { code: 'Diak', number: 342, name: 'Dives Akuru' },
    dogr: { code: 'Dogr', number: 328, name: 'Dogra' },
    dsrt: { code: 'Dsrt', number: 250, name: 'Deseret (Mormon)' },
    dupl: {
        code: 'Dupl',
        number: 755,
        name: 'Duployan shorthand, Duployan stenography'
    },
    egyd: { code: 'Egyd', number: 70, name: 'Egyptian demotic' },
    egyh: { code: 'Egyh', number: 60, name: 'Egyptian hieratic' },
    egyp: { code: 'Egyp', number: 50, name: 'Egyptian hieroglyphs' },
    elba: { code: 'Elba', number: 226, name: 'Elbasan' },
    elym: { code: 'Elym', number: 128, name: 'Elymaic' },
    ethi: { code: 'Ethi', number: 430, name: 'Ethiopic (Geʻez)' },
    geok: {
        code: 'Geok',
        number: 241,
        name: 'Khutsuri (Asomtavruli and Nuskhuri)'
    },
    geor: {
        code: 'Geor',
        number: 240,
        name: 'Georgian (Mkhedruli and Mtavruli)'
    },
    glag: { code: 'Glag', number: 225, name: 'Glagolitic' },
    gong: { code: 'Gong', number: 312, name: 'Gunjala Gondi' },
    gonm: { code: 'Gonm', number: 313, name: 'Masaram Gondi' },
    goth: { code: 'Goth', number: 206, name: 'Gothic' },
    gran: { code: 'Gran', number: 343, name: 'Grantha' },
    grek: { code: 'Grek', number: 200, name: 'Greek' },
    gujr: { code: 'Gujr', number: 320, name: 'Gujarati' },
    guru: { code: 'Guru', number: 310, name: 'Gurmukhi' },
    hanb: {
        code: 'Hanb',
        number: 503,
        name: 'Han with Bopomofo (alias for Han + Bopomofo)'
    },
    hang: { code: 'Hang', number: 286, name: 'Hangul (Hangŭl, Hangeul)' },
    hani: { code: 'Hani', number: 500, name: 'Han (Hanzi, Kanji, Hanja)' },
    hano: { code: 'Hano', number: 371, name: 'Hanunoo (Hanunóo)' },
    hans: { code: 'Hans', number: 501, name: 'Han (Simplified variant)' },
    hant: { code: 'Hant', number: 502, name: 'Han (Traditional variant)' },
    hatr: { code: 'Hatr', number: 127, name: 'Hatran' },
    hebr: { code: 'Hebr', number: 125, name: 'Hebrew' },
    hira: { code: 'Hira', number: 410, name: 'Hiragana' },
    hluw: {
        code: 'Hluw',
        number: 80,
        name: 'Anatolian Hieroglyphs (Luwian Hieroglyphs, Hittite Hieroglyphs)'
    },
    hmng: { code: 'Hmng', number: 450, name: 'Pahawh Hmong' },
    hmnp: { code: 'Hmnp', number: 451, name: 'Nyiakeng Puachue Hmong' },
    hrkt: {
        code: 'Hrkt',
        number: 412,
        name: 'Japanese syllabaries (alias for Hiragana + Katakana)'
    },
    hung: { code: 'Hung', number: 176, name: 'Old Hungarian (Hungarian Runic)' },
    inds: { code: 'Inds', number: 610, name: 'Indus (Harappan)' },
    ital: {
        code: 'Ital',
        number: 210,
        name: 'Old Italic (Etruscan, Oscan, etc.)'
    },
    jamo: {
        code: 'Jamo',
        number: 284,
        name: 'Jamo (alias for Jamo subset of Hangul)'
    },
    java: { code: 'Java', number: 361, name: 'Javanese' },
    jpan: {
        code: 'Jpan',
        number: 413,
        name: 'Japanese (alias for Han + Hiragana + Katakana)'
    },
    jurc: { code: 'Jurc', number: 510, name: 'Jurchen' },
    kali: { code: 'Kali', number: 357, name: 'Kayah Li' },
    kana: { code: 'Kana', number: 411, name: 'Katakana' },
    khar: { code: 'Khar', number: 305, name: 'Kharoshthi' },
    khmr: { code: 'Khmr', number: 355, name: 'Khmer' },
    khoj: { code: 'Khoj', number: 322, name: 'Khojki' },
    kitl: { code: 'Kitl', number: 505, name: 'Khitan large script' },
    kits: { code: 'Kits', number: 288, name: 'Khitan small script' },
    knda: { code: 'Knda', number: 345, name: 'Kannada' },
    kore: { code: 'Kore', number: 287, name: 'Korean (alias for Hangul + Han)' },
    kpel: { code: 'Kpel', number: 436, name: 'Kpelle' },
    kthi: { code: 'Kthi', number: 317, name: 'Kaithi' },
    lana: { code: 'Lana', number: 351, name: 'Tai Tham (Lanna)' },
    laoo: { code: 'Laoo', number: 356, name: 'Lao' },
    latf: { code: 'Latf', number: 217, name: 'Latin (Fraktur variant)' },
    latg: { code: 'Latg', number: 216, name: 'Latin (Gaelic variant)' },
    latn: { code: 'Latn', number: 215, name: 'Latin' },
    leke: { code: 'Leke', number: 364, name: 'Leke' },
    lepc: { code: 'Lepc', number: 335, name: 'Lepcha (Róng)' },
    limb: { code: 'Limb', number: 336, name: 'Limbu' },
    lina: { code: 'Lina', number: 400, name: 'Linear A' },
    linb: { code: 'Linb', number: 401, name: 'Linear B' },
    lisu: { code: 'Lisu', number: 399, name: 'Lisu (Fraser)' },
    loma: { code: 'Loma', number: 437, name: 'Loma' },
    lyci: { code: 'Lyci', number: 202, name: 'Lycian' },
    lydi: { code: 'Lydi', number: 116, name: 'Lydian' },
    mahj: { code: 'Mahj', number: 314, name: 'Mahajani' },
    maka: { code: 'Maka', number: 366, name: 'Makasar' },
    mand: { code: 'Mand', number: 140, name: 'Mandaic, Mandaean' },
    mani: { code: 'Mani', number: 139, name: 'Manichaean' },
    marc: { code: 'Marc', number: 332, name: 'Marchen' },
    maya: { code: 'Maya', number: 90, name: 'Mayan hieroglyphs' },
    medf: {
        code: 'Medf',
        number: 265,
        name: 'Medefaidrin (Oberi Okaime, Oberi Ɔkaimɛ)'
    },
    mend: { code: 'Mend', number: 438, name: 'Mende Kikakui' },
    merc: { code: 'Merc', number: 101, name: 'Meroitic Cursive' },
    mero: { code: 'Mero', number: 100, name: 'Meroitic Hieroglyphs' },
    mlym: { code: 'Mlym', number: 347, name: 'Malayalam' },
    modi: { code: 'Modi', number: 324, name: 'Modi, Moḍī' },
    mong: { code: 'Mong', number: 145, name: 'Mongolian' },
    moon: {
        code: 'Moon',
        number: 218,
        name: 'Moon (Moon code, Moon script, Moon type)'
    },
    mroo: { code: 'Mroo', number: 264, name: 'Mro, Mru' },
    mtei: { code: 'Mtei', number: 337, name: 'Meitei Mayek (Meithei, Meetei)' },
    mult: { code: 'Mult', number: 323, name: 'Multani' },
    mymr: { code: 'Mymr', number: 350, name: 'Myanmar (Burmese)' },
    nand: { code: 'Nand', number: 311, name: 'Nandinagari' },
    narb: {
        code: 'Narb',
        number: 106,
        name: 'Old North Arabian (Ancient North Arabian)'
    },
    nbat: { code: 'Nbat', number: 159, name: 'Nabataean' },
    newa: { code: 'Newa', number: 333, name: 'Newa, Newar, Newari, Nepāla lipi' },
    nkdb: {
        code: 'Nkdb',
        number: 85,
        name: 'Naxi Dongba (na²¹ɕi³³ to³³ba²¹, Nakhi Tomba)'
    },
    nkgb: {
        code: 'Nkgb',
        number: 420,
        name: "Naxi Geba (na²¹ɕi³³ gʌ²¹ba²¹, 'Na-'Khi ²Ggŏ-¹baw, Nakhi Geba)"
    },
    nkoo: { code: 'Nkoo', number: 165, name: 'N’Ko' },
    nshu: { code: 'Nshu', number: 499, name: 'Nüshu' },
    ogam: { code: 'Ogam', number: 212, name: 'Ogham' },
    olck: {
        code: 'Olck',
        number: 261,
        name: 'Ol Chiki (Ol Cemet’, Ol, Santali)'
    },
    orkh: { code: 'Orkh', number: 175, name: 'Old Turkic, Orkhon Runic' },
    orya: { code: 'Orya', number: 327, name: 'Oriya (Odia)' },
    osge: { code: 'Osge', number: 219, name: 'Osage' },
    osma: { code: 'Osma', number: 260, name: 'Osmanya' },
    ougr: { code: 'Ougr', number: 143, name: 'Old Uyghur' },
    palm: { code: 'Palm', number: 126, name: 'Palmyrene' },
    pauc: { code: 'Pauc', number: 263, name: 'Pau Cin Hau' },
    pcun: { code: 'Pcun', number: 15, name: 'Proto-Cuneiform' },
    pelm: { code: 'Pelm', number: 16, name: 'Proto-Elamite' },
    perm: { code: 'Perm', number: 227, name: 'Old Permic' },
    phag: { code: 'Phag', number: 331, name: 'Phags-pa' },
    phli: { code: 'Phli', number: 131, name: 'Inscriptional Pahlavi' },
    phlp: { code: 'Phlp', number: 132, name: 'Psalter Pahlavi' },
    phlv: { code: 'Phlv', number: 133, name: 'Book Pahlavi' },
    phnx: { code: 'Phnx', number: 115, name: 'Phoenician' },
    plrd: { code: 'Plrd', number: 282, name: 'Miao (Pollard)' },
    piqd: { code: 'Piqd', number: 293, name: 'Klingon (KLI pIqaD)' },
    prti: { code: 'Prti', number: 130, name: 'Inscriptional Parthian' },
    psin: { code: 'Psin', number: 103, name: 'Proto-Sinaitic' },
    qaaa: { code: 'Qaaa', number: 900, name: 'Reserved for private use (start)' },
    qabx: { code: 'Qabx', number: 949, name: 'Reserved for private use (end)' },
    ranj: { code: 'Ranj', number: 303, name: 'Ranjana' },
    rjng: { code: 'Rjng', number: 363, name: 'Rejang (Redjang, Kaganga)' },
    rohg: { code: 'Rohg', number: 167, name: 'Hanifi Rohingya' },
    roro: { code: 'Roro', number: 620, name: 'Rongorongo' },
    runr: { code: 'Runr', number: 211, name: 'Runic' },
    samr: { code: 'Samr', number: 123, name: 'Samaritan' },
    sara: { code: 'Sara', number: 292, name: 'Sarati' },
    sarb: { code: 'Sarb', number: 105, name: 'Old South Arabian' },
    saur: { code: 'Saur', number: 344, name: 'Saurashtra' },
    sgnw: { code: 'Sgnw', number: 95, name: 'SignWriting' },
    shaw: { code: 'Shaw', number: 281, name: 'Shavian (Shaw)' },
    shrd: { code: 'Shrd', number: 319, name: 'Sharada, Śāradā' },
    shui: { code: 'Shui', number: 530, name: 'Shuishu' },
    sidd: { code: 'Sidd', number: 302, name: 'Siddham, Siddhaṃ, Siddhamātṛkā' },
    sind: { code: 'Sind', number: 318, name: 'Khudawadi, Sindhi' },
    sinh: { code: 'Sinh', number: 348, name: 'Sinhala' },
    sogd: { code: 'Sogd', number: 141, name: 'Sogdian' },
    sogo: { code: 'Sogo', number: 142, name: 'Old Sogdian' },
    sora: { code: 'Sora', number: 398, name: 'Sora Sompeng' },
    soyo: { code: 'Soyo', number: 329, name: 'Soyombo' },
    sund: { code: 'Sund', number: 362, name: 'Sundanese' },
    sylo: { code: 'Sylo', number: 316, name: 'Syloti Nagri' },
    syrc: { code: 'Syrc', number: 135, name: 'Syriac' },
    syre: { code: 'Syre', number: 138, name: 'Syriac (Estrangelo variant)' },
    syrj: { code: 'Syrj', number: 137, name: 'Syriac (Western variant)' },
    syrn: { code: 'Syrn', number: 136, name: 'Syriac (Eastern variant)' },
    tagb: { code: 'Tagb', number: 373, name: 'Tagbanwa' },
    takr: { code: 'Takr', number: 321, name: 'Takri, Ṭākrī, Ṭāṅkrī' },
    tale: { code: 'Tale', number: 353, name: 'Tai Le' },
    talu: { code: 'Talu', number: 354, name: 'New Tai Lue' },
    taml: { code: 'Taml', number: 346, name: 'Tamil' },
    tang: { code: 'Tang', number: 520, name: 'Tangut' },
    tavt: { code: 'Tavt', number: 359, name: 'Tai Viet' },
    telu: { code: 'Telu', number: 340, name: 'Telugu' },
    teng: { code: 'Teng', number: 290, name: 'Tengwar' },
    tfng: { code: 'Tfng', number: 120, name: 'Tifinagh (Berber)' },
    tglg: { code: 'Tglg', number: 370, name: 'Tagalog (Baybayin, Alibata)' },
    thaa: { code: 'Thaa', number: 170, name: 'Thaana' },
    thai: { code: 'Thai', number: 352, name: 'Thai' },
    tibt: { code: 'Tibt', number: 330, name: 'Tibetan' },
    tirh: { code: 'Tirh', number: 326, name: 'Tirhuta' },
    tnsa: { code: 'Tnsa', number: 275, name: 'Tangsa' },
    toto: { code: 'Toto', number: 294, name: 'Toto' },
    ugar: { code: 'Ugar', number: 40, name: 'Ugaritic' },
    vaii: { code: 'Vaii', number: 470, name: 'Vai' },
    visp: { code: 'Visp', number: 280, name: 'Visible Speech' },
    vith: { code: 'Vith', number: 228, name: 'Vithkuqi' },
    wara: { code: 'Wara', number: 262, name: 'Warang Citi (Varang Kshiti)' },
    wcho: { code: 'Wcho', number: 283, name: 'Wancho' },
    wole: { code: 'Wole', number: 480, name: 'Woleai' },
    xpeo: { code: 'Xpeo', number: 30, name: 'Old Persian' },
    xsux: { code: 'Xsux', number: 20, name: 'Cuneiform, Sumero-Akkadian' },
    yezi: { code: 'Yezi', number: 192, name: 'Yezidi' },
    yiii: { code: 'Yiii', number: 460, name: 'Yi' },
    zanb: {
        code: 'Zanb',
        number: 339,
        name: 
        // eslint-disable-next-line max-len
        'Zanabazar Square (Zanabazarin Dörböljin Useg, Xewtee Dörböljin Bicig, Horizontal Square Script)'
    },
    zinh: { code: 'Zinh', number: 994, name: 'Code for inherited script' },
    zmth: { code: 'Zmth', number: 995, name: 'Mathematical notation' },
    zsye: { code: 'Zsye', number: 993, name: 'Symbols (Emoji variant)' },
    zsym: { code: 'Zsym', number: 996, name: 'Symbols' },
    zxxx: { code: 'Zxxx', number: 997, name: 'Code for unwritten documents' },
    zyyy: { code: 'Zyyy', number: 998, name: 'Code for undetermined script' },
    zzzz: { code: 'Zzzz', number: 999, name: 'Code for uncoded script' }
};
