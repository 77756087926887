import React from 'react';
import { permissions, SupplierDomain } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Delete as DeleteIcon, Edit } from '@material-ui/icons';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { ApiState } from '../../auth/redux/types';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { supplierDomainsThunk } from '../redux/supplier-domains.thunk';

function buildTableColumns() {
  return entityColumnBuilder<SupplierDomain>()
    .addDefaultColumn('supplier_domain_name', 'name', 'Name')
    .addDefaultColumn(
      'supplier_domain_description',
      'description',
      'Description'
    ).columns;
}

interface SupplierDomainsTableProps {
  supplierDomains: SupplierDomain[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function SupplierDomainsTable({
  supplierDomains,
  onRefresh,
  apiState
}: SupplierDomainsTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  const dispatch = useAppDispatch();
  const deleteDomain = (id: string) => {
    dispatch(supplierDomainsThunk.delete({ id }));
  };

  return (
    <GenericTable
      label={t('Domains')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Domain',
        onClick: () => history.push(`${url}?type=create#supplier-domain`)
      }}
      data={supplierDomains}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.suppliers.domain.edit,
          entry: (domain) => ({
            label: 'Edit',
            icon: Edit,
            link: `${url}?type=edit#${domain.id}`
          })
        },
        {
          permissions: permissions.suppliers.domain.delete,
          entry: (d) => ({
            icon: DeleteIcon,
            label: t('Delete'),
            alert: {
              title: t('Delete Domain'),
              content: `${t('Are you  sure you want to delete the domain ')} "${
                d.name
              }"?`,
              onConfirm: () => {
                deleteDomain(d.id);
              }
            }
          })
        }
      ]}
      toolbarActions={[]}
    />
  );
}
