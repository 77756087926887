import React from 'react';
import { formatProjectNumber, Project, ProjectTag, Supplier } from 'model';
import { Box, Chip, Grid, Typography } from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckCircleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { format } from 'date-fns';
import { StyledLink } from '../../../../components/input/link/StyledLink';
import { useProjectTags } from '../../hooks/project-tags.hook';
import { useClient } from '../../../clients/hooks/client.hook';
import { useSuppliers } from '../../../suppliers/hooks/suppliers.hook';
import {
  DeadlineDateFormat,
  determineDeadlineStatus,
  ProjectDeadlineColors
} from '../table/ProjectsTable';
import { ProjectLink } from './ProjectLink';
import { useProject } from '../../hooks/project.hook';

export function ProjectBasicDisplay({ value }: { value: Project }) {
  const { t } = useTranslation();
  const { entities: projectTags } = useProjectTags();
  const { client } = useClient(value.clientId);
  const { suppliers } = useSuppliers();
  const projectManager = suppliers.find((v) => v.id === value.projectManagerId);
  const { project: duplicatedFromProject } = useProject(value.duplicatedFromId);
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <StyledLink
          to={`/projects/projects/${value.id}`}
          style={{ flexGrow: 1 }}
          variant="body1"
        >
          {value.projectName}
        </StyledLink>
        <div>
          {value.projectTagIds
            .map((id) => projectTags.find((t1) => t1.id === id) as ProjectTag)
            .filter((v) => v !== undefined)
            .map((tag) => (
              <Chip size="small" label={tag.name} />
            ))}
        </div>
      </div>
      <Typography variant="subtitle1" color="textSecondary">
        {value.projectNumber ? (
          <>
            {formatProjectNumber(value.projectNumber)}
            {value.projectNumber.firstAcquisition && (
              <Chip
                color="default"
                icon={<CheckCircleOutline fontSize="small" />}
                size="small"
                label={t('First Acquisition')}
              />
            )}
          </>
        ) : (
          '-'
        )}
      </Typography>
      {value.deadline && (
        <div>
          <Typography variant="caption" color="textSecondary">
            {t('Deadline')}
          </Typography>
          <Typography
            variant="body2"
            style={{
              color:
                value.deadline !== null
                  ? ProjectDeadlineColors[
                      determineDeadlineStatus(new Date(value.deadline), value)
                    ]
                  : undefined
            }}
          >
            {format(new Date(value.deadline), DeadlineDateFormat)}
          </Typography>
        </div>
      )}
      <Typography variant="body2" color="textSecondary">
        {value.projectVariation?.name}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textSecondary">
              {t('Client')}
            </Typography>
            {client && (
              <StyledLink to={`/clients/${client.id}`}>
                {client.name}
              </StyledLink>
            )}
          </Box>
        </Grid>
        <Grid item xs={4}>
          {value.createdInWordbee ? (
            <CheckBoxOutlinedIcon
              style={{
                position: 'relative',
                top: 8
              }}
            />
          ) : (
            <CheckBoxOutlineBlank
              style={{
                position: 'relative',
                top: 8
              }}
            />
          )}
          {t('Wordbee')}
        </Grid>
        <Grid item xs={4}>
          {value.tmConsolidated ? (
            <CheckBoxOutlinedIcon
              style={{
                position: 'relative',
                top: 8
              }}
            />
          ) : (
            <CheckBoxOutlineBlank
              style={{
                position: 'relative',
                top: 8
              }}
            />
          )}
          {t('TM Consolidated')}
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textSecondary">
              {t('Project Status')}
            </Typography>
            <div>
              <Chip size="small" label={value.projectStatus} />
            </div>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption" color="textSecondary">
              {t('Project Manager')}
            </Typography>
            {projectManager && (
              <StyledLink to={`/suppliers/supplier/${projectManager.id}`}>
                {/* eslint-disable-next-line max-len */}
                {`${projectManager.name.first} ${projectManager.name.last}`}
              </StyledLink>
            )}
          </Box>
        </Grid>
        {value.supportingProjectManagerIds &&
          value.supportingProjectManagerIds
            .map((id) => suppliers.find((s) => s.id === id) as Supplier)
            .filter((s) => s !== undefined)
            .map((sPm) => (
              <Grid item xs={4}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="caption" color="textSecondary">
                    {t('Supporting Project Manager')}
                  </Typography>
                  <StyledLink to={`/suppliers/supplier/${sPm.id}`}>
                    {`${sPm.name.first} ${sPm.name.last}`}
                  </StyledLink>
                </Box>
              </Grid>
            ))}
        {duplicatedFromProject && (
          <Grid item xs={4}>
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" color="textSecondary">
                {t('Duplicated from project')}
              </Typography>
              <ProjectLink value={duplicatedFromProject} format="name" />
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
