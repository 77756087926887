import React from 'react';
import { Grid } from '@material-ui/core';
import { permissions } from 'model';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { ProjectTable } from '../projects/components/table/ProjectsTable';
import { useProjects } from '../projects/hooks/projects.hook';
import { NewClientsWidget } from '../clients/NewClients.widget';
import { PermittedComponent } from '../../components/auth/PermittedComponent';
import { ProjectAcquisitionsWidget } from '../projects/ProjectAcquisitions.widget';
import { useProjectRequests } from '../projects/hooks/project-requests.hook';
import { useRequestStatusList } from '../projects/hooks/request-status-list.hook';
import { ProjectRequestTable } from '../projects/components/request/table/ProjectRequestsTable';
import { SubPageArea } from '../../hooks/page-area.components';
import { ProjectTemplateDashboardCard } from '../projects/components/templates/ProjectTemplateDashboardCard';

export function DashboardScreen() {
  // const user = useAppSelector(selectUserAsNullable, shallowEqual);
  const {
    entities: projects,
    apiState: projectsApiState,
    listEntities: listProjects
  } = useProjects();

  const {
    entities: requests,
    apiState: requestsApiState,
    listEntities: listRequests
  } = useProjectRequests();

  const assignedProjects = projects; /* React.useMemo(
    () =>
      projects.filter(
        (p) =>
          p.projectManagerId === user?.supplier?.id ||
          p.supportingProjectManagerIds.includes(user?.supplier?.id || '')
      ),
    [projects, user]
  ); */
  const { entities: requestStatusList } = useRequestStatusList();
  const openStatusIds = React.useMemo(
    () =>
      requestStatusList.filter((rS) => rS.type === 'open').map((rS) => rS.id),
    [requestStatusList]
  );

  const openRequests = React.useMemo(
    () => requests.filter((r) => openStatusIds.includes(r.requestStatusId)),
    [requests]
  );
  /* const { entities: requestStatusList } = useRequestStatusList();
  const openStatusIds = React.useMemo(
    () =>
      requestStatusList.filter((rS) => rS.type === 'open').map((rS) => rS.id),
    [requestStatusList]
  );
    const {
    entities: requests,
    apiState: requestsApiState,
    listEntities: listRequests
  } = useProjectRequests();
  const openRequests = React.useMemo(
    () => requests.filter((r) => openStatusIds.includes(r.requestStatusId)),
    [requests]
  ); */

  return (
    <ErrorBoundary context="dashboard">
      <Grid container spacing={1} style={{ marginBottom: '1rem' }}>
        {/*
         <PermittedComponent permission={permissions.dashboard.newUsers}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <NewUsersWidget />
          </Grid>
        </PermittedComponent> */}
        <Grid item container xs={12} spacing={2}>
          <SubPageArea type="dashboard-widget" specifier="new-clients">
            <PermittedComponent permission={permissions.dashboard.newClients}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <NewClientsWidget />
              </Grid>
            </PermittedComponent>
          </SubPageArea>
          <SubPageArea type="dashboard-widget" specifier="project-acquisitions">
            <PermittedComponent
              permission={permissions.dashboard.projectAcquisitions}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ProjectAcquisitionsWidget />
              </Grid>
            </PermittedComponent>
          </SubPageArea>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <SubPageArea type="dashboard-widget" specifier="projects">
            <PermittedComponent permission={permissions.dashboard.projects}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ProjectTable
                  initialColumnLayout={[
                    'project_deadline',
                    'project_client',
                    'project_name',
                    'project_number',
                    'project_all_managers'
                  ]}
                  defaultPmFilter={null}
                  sortByDeadline
                  uiMode="widget"
                  onlyActive
                  projects={assignedProjects}
                  onRefresh={listProjects}
                  apiState={projectsApiState}
                />
              </Grid>
            </PermittedComponent>
          </SubPageArea>
          <SubPageArea type="dashboard-widget" specifier="requests">
            <PermittedComponent permission={permissions.dashboard.requests}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ProjectRequestTable
                  initialColumnLayout={[
                    'project_deadline',
                    'project_request_project',
                    'project_client',
                    'project_request_offer_status',
                    'project_all_managers'
                  ]}
                  defaultPmFilter={null}
                  uiMode="widget"
                  projectRequests={openRequests}
                  onRefresh={listRequests}
                  apiState={requestsApiState}
                />
              </Grid>
            </PermittedComponent>
          </SubPageArea>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <SubPageArea type="dashboard-widget" specifier="project-templates">
            <PermittedComponent permission={permissions.dashboard.templates}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <ProjectTemplateDashboardCard />
              </Grid>
            </PermittedComponent>
          </SubPageArea>
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
}
