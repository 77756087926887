"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supplierDataParser = exports.SupplierImporterData = void 0;
const class_validator_1 = require("class-validator");
const csv_columns_descriptor_1 = require("../../csv/csv-columns.descriptor");
const column_descriptor_1 = require("../../csv/column-descriptor");
const Regions_1 = require("../../shared/constants/Regions");
const SupplierImportMappings_1 = require("../import/SupplierImportMappings");
class SupplierImporterData {
}
exports.SupplierImporterData = SupplierImporterData;
function supplierDataParser(
// eslint-disable-next-line @typescript-eslint/no-unused-vars
languageSource) {
    const ndaOptions = ['yes', 'no', 'yes contract'];
    const csvColumnBuilder = csv_columns_descriptor_1.CSVColumnBuilder.create().append({
        importId: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((s) => /^[0-9]{3}-[0-9]{3}$/.test(s))
            .skipBefore(3),
        roles: column_descriptor_1.ColumnDescriptor.createList(',', []),
        agency: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true }),
        active: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true }),
        surname: column_descriptor_1.ColumnDescriptor.create().withValidation((d) => d !== ''),
        name: column_descriptor_1.ColumnDescriptor.create().withValidation((d) => d !== ''),
        employee: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true }),
        ebayCS: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true }),
        ebayCSHours: column_descriptor_1.ColumnDescriptor.create().withParse((v) => {
            const number = parseFloat(v);
            if (Number.isNaN(number)) {
                return null;
            }
            return number;
        }),
        ebaySiteContent: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true }),
        ebaySiteContentHours: column_descriptor_1.ColumnDescriptor.create().withParse((v) => {
            const number = parseFloat(v);
            if (Number.isNaN(number)) {
                return null;
            }
            return number;
        }),
        comment: column_descriptor_1.ColumnDescriptor.create().orEmpty(null).skipBefore(1),
        nativeCodes: column_descriptor_1.ColumnDescriptor.createList((l) => l === '' ? [] : l.split(','))
            .withValidation((codes) => codes.every((c) => languageSource(c) !== undefined)
            ? true
            : 'unknown language code')
            .skipBefore(2),
        street: column_descriptor_1.ColumnDescriptor.create().orEmpty(null).skipBefore(2),
        houseNumber: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
        zipCode: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
        city: column_descriptor_1.ColumnDescriptor.create(),
        country: column_descriptor_1.ColumnDescriptor.create()
            .toCase('upper')
            .withValidation((c) => Regions_1.CountryCodeMap[c] !== undefined ? true : 'unknown country code')
            .skipBefore(1),
        mobile: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
        phone: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
        email: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((v) => (0, class_validator_1.isEmail)(v))
            .orEmpty(null),
        website: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
        taxId: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true }),
        nda: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .withValidation((v) => ndaOptions.includes(v)
            ? true
            : `unknown option [${ndaOptions.join(',')}]`),
        languageCodes: column_descriptor_1.ColumnDescriptor.createList((l) => l === '' ? [] : l.split(','))
            .withValidation((codes) => codes.every((c) => languageSource(c) !== undefined)
            ? true
            : 'unknown language code')
            .skipBefore(1),
        profession: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
        graduatedTranslator: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true }),
        certifiedTranslator: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true }),
        universityDegree: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true }),
        swornTranslator: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true })
            .skipBefore(1),
        swornCountry: column_descriptor_1.ColumnDescriptor.create(),
        domains: column_descriptor_1.ColumnDescriptor.createList((v) => {
            const valid = Array.from(v).reduce((acc, c) => {
                if (acc < 0) {
                    return acc;
                }
                if (c === '(') {
                    return acc > 0 ? -1 : 1;
                }
                if (c === ')') {
                    return acc === 1 ? 0 : -1;
                }
                return acc;
            }, 0) === 0;
            const prepared = valid
                ? v.replace(/\([^()]+\)/g, (substring) => {
                    return substring.replace(/[,\n]/g, ';');
                })
                : v;
            return prepared
                .split(',')
                .flatMap((v1) => v1.split('\n'))
                .map((v1) => v1.trim())
                .filter((s) => s !== '');
        }, [])
            .withValidation((v) => v.every((t) => t !== '') ? true : 'empty list element')
            .skipBefore(1),
        trados: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true })
            .skipBefore(1),
        testNames: column_descriptor_1.ColumnDescriptor.createList(',')
            .withValidation((d) => (d.length === 1 && d[0] === '') ||
            d.every((n) => {
                const result = Object.keys(SupplierImportMappings_1.SupplierImportMappings.tests).includes(n);
                if (!result) {
                    // console.log(
                    //   'error test names',
                    //   d,
                    //   Object.keys(SupplierImportMappings.tests)
                    // );
                }
                return result;
            }))
            .skipBefore(11),
        testStates: column_descriptor_1.ColumnDescriptor.createList(',').withValidation((d) => (d.length === 1 && d[0] === '') ||
            d.every((s) => Object.keys(SupplierImportMappings_1.SupplierImportMappings.testStates).includes(s.toLowerCase()))),
        testerIds: column_descriptor_1.ColumnDescriptor.createList(',')
            .withParse((tid) => (tid === '' ? null : tid))
            .skipBefore(1),
        testResults: column_descriptor_1.ColumnDescriptor.createList(';'),
        pricePerHour: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((v) => /^[0-9]+(\.[0-9]+)?$/.test(v))
            .orEmpty(null),
        pricePerWord: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((v) => /^[0-9]+(\.[0-9]+)?$/.test(v))
            .orEmpty(null),
        pricePerReview: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((v) => /^[0-9]+(\.[0-9]+)?$/.test(v))
            .orEmpty(null),
        priceComments: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
        firstContact: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((v) => /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/.test(v))
            .orEmpty(null),
        cv: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ no: false, yes: true }),
        cvFile: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
        cvDate: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
        interpretingSourceLangCodes: column_descriptor_1.ColumnDescriptor.createList((l) => l === '' ? [] : l.split(','))
            .withValidation((codes) => codes.every((c) => languageSource(c) !== undefined)
            ? true
            : 'unknown language code')
            .skipBefore(1),
        interpretingTargetLangCodes: column_descriptor_1.ColumnDescriptor.createList((l) => l === '' ? [] : l.split(','))
            .withValidation((codes) => codes.every((c) => languageSource(c) !== undefined)
            ? true
            : 'unknown language code')
            .skipBefore(1)
    });
    /*    .skip(3)
      .append(firstMap)
      .skip()
      .append(commentMap)
      .skip(2)
      .append(nativeCodes)
      .skip()
      .append(addressMap)
      .skip()
      .append(secondMap)
      .skip()
      .append(thirdMap)
      .skip()
      .append(swornMap)
      .skip()
      .append(domainsMap)
      .skip()
      .append(tradosMap)
      .skip(11)
      .append(testsMap)
      .skip()
      .append(testPriceMap)
      .skip()
      .append(secondInterMap)
      .skip()
      .append(firstInterMap); */
    return csvColumnBuilder.build({
        validateRow: (row) => {
            if ([row.testNames, row.testStates, row.testerIds, row.testResults]
                .map((tl) => tl.length)
                .some((l) => l !== row.testNames.length)) {
                return [
                    {
                        cells: ['testNames', 'testStates', 'testerIds', 'testResults'],
                        message: 'inconsistent test amount'
                    }
                ];
            }
            if (row.testNames.some((rn) => rn === '') &&
                (row.testNames.length > 1 ||
                    [row.testStates, row.testerIds, row.testResults].some((rn) => !!rn[0] || rn.length > 1))) {
                return [
                    {
                        cells: ['testNames'],
                        message: 'invalid test name list'
                    }
                ];
            }
            if (row.testStates.some((rn) => rn === '') &&
                (row.testStates.length > 1 ||
                    [row.testNames, row.testerIds, row.testResults].some((rn) => !!rn[0] || rn.length > 1))) {
                return [
                    {
                        cells: ['testStates'],
                        message: 'invalid test state list'
                    }
                ];
            }
            return [];
        }
    });
}
exports.supplierDataParser = supplierDataParser;
