import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { Switch as RouterSwitch } from 'react-router';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { getUuidUrlParamMatcher } from '../../components/navigation/utils';
import { ClientsScreen } from './screens/Clients.screen';
import { ClientFormDialog } from './components/ClientFormDialog';
import { ClientDetailsScreen } from './screens/ClientDetails.screen';
import { ClientImportScreen } from './screens/ClientImport.screen';
import clientReducer, { clientSelectors } from './redux/clients.slice';
import { KontextorError } from '../error/KontextorError';

export function ClientsFeature() {
  const { path } = useRouteMatch();

  return (
    <ErrorBoundary context="clientsFeature">
      <KontextorError
        clear={clientReducer.actions.clearError}
        selector={clientSelectors.selectError}
      >
        <ClientFormDialog />
        <RouterSwitch>
          <Route path={`${path}/${getUuidUrlParamMatcher('clientId')}`}>
            <ClientDetailsScreen />
          </Route>
          <Route path={`${path}/import`}>
            <ClientImportScreen />
          </Route>
          <Route path={`${path}`}>
            <ClientsScreen />
          </Route>
        </RouterSwitch>
      </KontextorError>
    </ErrorBoundary>
  );
}
