import { IconButton, TextFieldProps, Typography } from '@material-ui/core';
import React from 'react';
import { currencyMap, DefaultCurrency } from 'model';
import { ExpandMore } from '@material-ui/icons';
import { CurrencySelect } from './CurrencySelect';
import { PropertyPath, useTypedField } from '../../transformer/DTOViewSchema';

export interface FormikCurrencySelectProps {
  name: string | PropertyPath<any, string | null>;
  filter?: (code: string) => boolean;
  nullable?: boolean;
  disabled?: boolean;
  textFieldProps?: Partial<TextFieldProps>;
  withoutToggle?: boolean;
  alignRight?: boolean;
}

export function FormikCurrencySelect({
  filter,
  disabled,
  name,
  textFieldProps,
  nullable,
  withoutToggle,
  alignRight
}: FormikCurrencySelectProps) {
  const [, meta, helpers] = useTypedField<string | null>(name);

  const [showCurrencySelect, setShowCurrencySelect] = React.useState(false);
  return (
    <div>
      {withoutToggle || showCurrencySelect ? (
        <CurrencySelect
          selectedCurrency={meta.value || DefaultCurrency.code}
          onChange={(v) => helpers.setValue(v)}
          textFieldProps={{
            ...textFieldProps,
            inputProps: {
              style: {
                ...textFieldProps?.inputProps,
                textAlign: alignRight ? 'end' : undefined
              }
            }
          }}
          disabled={!!disabled}
          filter={filter}
          nullable={nullable}
        />
      ) : (
        <IconButton
          size="small"
          disabled={disabled}
          onClick={() => setShowCurrencySelect(true)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography>
            {meta.value
              ? currencyMap[
                  (meta.value ??
                    DefaultCurrency.code) as keyof typeof currencyMap
                ]?.symbol
              : DefaultCurrency.symbol}
          </Typography>
          {!disabled && (
            <ExpandMore
              fontSize="small"
              style={{ fontSize: 15, alignSelf: 'center' }}
            />
          )}
        </IconButton>
      )}
    </div>
  );
}
