import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, TextFieldProps } from '@material-ui/core';
import { currencyMap, DefaultCurrency } from 'model';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export interface CurrencySelectProps {
  selectedCurrency: string | null;
  onChange: (newCurrency: string | null) => void;
  filter?: (code: string) => boolean;
  nullable?: boolean;
  disabled?: boolean;
  textFieldProps?: Partial<TextFieldProps>;
}
const useStyles = makeStyles(() =>
  createStyles({
    popper: {
      minWidth: 230
    }
  })
);

export function CurrencySelect({
  selectedCurrency,
  onChange,
  filter = () => true,
  nullable = true,
  disabled = false,
  textFieldProps = {}
}: CurrencySelectProps) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState<string>('');
  return (
    <Autocomplete
      value={
        selectedCurrency !== undefined
          ? currencyMap[selectedCurrency as keyof typeof currencyMap]
          : null
      }
      onChange={(_, v) => onChange(v?.code ?? null)}
      options={Object.entries(currencyMap)
        .filter(([k]) => filter(k))
        .map(([, v]) => v)}
      autoHighlight
      disabled={disabled}
      defaultValue={DefaultCurrency}
      clearOnEscape
      blurOnSelect
      classes={{ popper: classes.popper }}
      disableClearable={!nullable}
      getOptionLabel={(v) => (v.symbol ? `${v.symbol} ${v.name}` : v.name)}
      inputValue={inputValue}
      onInputChange={(_, v) => setInputValue(v)}
      renderInput={(params) => {
        const enhancedParams = {
          ...params,
          inputProps: {
            ...params.inputProps,
            value: `${
              selectedCurrency
                ? currencyMap[selectedCurrency as keyof typeof currencyMap]
                    ?.symbol || DefaultCurrency.symbol
                : DefaultCurrency.symbol
            }`
          }
        };
        return (
          <TextField
            {...enhancedParams}
            {...textFieldProps}
            inputProps={{
              ...enhancedParams.inputProps
            }}
          >
            {`${selectedCurrency}`
              ? currencyMap[selectedCurrency as keyof typeof currencyMap]
                  ?.symbol || DefaultCurrency.symbol
              : DefaultCurrency.symbol}
          </TextField>
        );
      }}
    />
  );
}
