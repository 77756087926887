import React from 'react';
import { IconButton } from '@material-ui/core';
import { OrderTask } from 'model';
import { useTranslation } from 'react-i18next';
import { TaskTransitionAction } from './order-task-state.hook';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { taskStateTransitionThunk } from '../../redux/project-order-tasks.thunk';

export function TransitionActionComponent({
  action,
  task,
  projectId
}: {
  action: TaskTransitionAction;
  task: OrderTask;
  projectId: string;
}) {
  const dispatch = useAppDispatch();
  const onClick = () => {
    dispatch(
      taskStateTransitionThunk({
        projectId,
        id: task.id,
        input: { state: action.state }
      })
    );
  };

  const { t } = useTranslation();
  const Icon = action.icon;
  return (
    <IconButton
      disabled={!action.consistent}
      onClick={onClick}
      title={t(action.title)}
      size="small"
    >
      <Icon fontSize="small" />
    </IconButton>
  );
}
