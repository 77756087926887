import React from 'react';
import { requestCommentPermission } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { useCommentsOfRequest } from '../../hooks/request-comments.hook';
import { CommentList } from '../../../../components/comment/CommentList';
import { CardWithHeader } from '../../../../components/card/cardWithHeader/CardWithHeader';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { createRequestCommentThunk } from '../../redux/request-comment.thunk';
import { NoOp } from '../../../../utils';

// Todo Manu create comment

interface ProjectCommentsCardProps {
  requestId: string;
}

export function ProjectCommentsList(props: ProjectCommentsCardProps) {
  const dispatch = useAppDispatch();
  const { comments } = useCommentsOfRequest(props.requestId);

  return (
    <CommentList
      onCreateComment={(value, attachedFiles) => {
        return dispatch(
          createRequestCommentThunk({
            requestId: props.requestId,
            input: {
              comment: value,
              attachedFiles: attachedFiles.map((f) => ({
                fileAccessId: f.id,
                token: f.grant
              }))
            }
          })
        )
          .then(unwrapResult)
          .catch(NoOp);
      }}
      comments={comments}
      filePermission={requestCommentPermission}
    />
  );
}
export function ProjectCommentsCard(props: ProjectCommentsCardProps) {
  return (
    <CardWithHeader title="Comments" actions={[]}>
      <ProjectCommentsList {...props} />
    </CardWithHeader>
  );
}
