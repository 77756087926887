import React from 'react';
import { Grid } from '@material-ui/core';
import { pick } from 'lodash';
import { Supplier, SupplierPatchDTO } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { buildDTOView } from '../../../../transformer/DTOViewSchema';
import { FormikEntitySelect } from '../../../../components/select/FormikEntitySelect';
import { FormikCurrencySelect } from '../../../../components/select/FormikCurrencySelect';
import { userAPI } from '../../../users/redux/users.slice';
import { FormikDatePicker } from '../../../../components/input/date/FormikDatePicker';
import { EditableTextArray } from '../../../../components/input/EditableTextArray';
import { supplierCategoriesAPI } from '../../redux/supplier-categories.slice';
import { FormikTextField } from '../../../../components/input/FormikTextField';

export function initialBasicsFromSupplier(supplier: Supplier) {
  return pick(supplier, [
    'name',
    'email',
    'profession',
    'stars',
    'employee'
  ]) as SupplierPatchDTO;
}

const supplierBasicValidationSchema = yup
  .object({
    name: yup
      .object({
        first: yup.string().required(),
        last: yup.string().required()
      })
      .required(),
    email: yup.string().email().required(),
    supplierCategoryId: yup.string().uuid().required(),
    profession: yup.string().defined().nullable().default(null),
    firstContact: yup.date().defined().nullable().default(null),
    websites: yup.array().of(yup.string().required()).required(),
    userId: yup.string().defined().nullable().default(null),
    defaultCurrency: yup.string().defined().nullable().default(null)
  })
  .defined();

export const BasicSupplierView = buildDTOView<SupplierPatchDTO>()
  .include(
    'name',
    'email',
    'profession',
    'firstContact',
    'supplierCategoryId',
    'userId',
    'defaultCurrency',
    'websites'
  )
  .withSchema(supplierBasicValidationSchema);

export function SupplierBasicForm() {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} container spacing={1}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <FormikTextField
            required
            name={BasicSupplierView.path.name.first}
            label={t('Surname')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormikTextField
            required
            name={BasicSupplierView.path.name.last}
            label={t('Name')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormikTextField
            required
            name={BasicSupplierView.path.email}
            label={t('E-Mail')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormikTextField
            name={BasicSupplierView.path.profession}
            label={t('Profession')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormikDatePicker
            name={BasicSupplierView.path.firstContact}
            label={t('Date of first contact')}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} />
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormikEntitySelect
            textFieldProps={{ label: t('User') }}
            entityApi={userAPI}
            optionLabel={(u) => u.email}
            name={BasicSupplierView.path.userId}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormikEntitySelect
            entityApi={supplierCategoriesAPI}
            optionLabel={(c) => c.name}
            textFieldProps={{ label: t('Supplier Category'), required: true }}
            name={BasicSupplierView.path.supplierCategoryId}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormikCurrencySelect
            withoutToggle
            name={BasicSupplierView.path.defaultCurrency}
            textFieldProps={{ label: t('Default Currency') }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <EditableTextArray
            allowEmpty
            name={BasicSupplierView.path.websites}
            primaryLabel={t('websites')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
