import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { projectOrderTasksThunk } from '../redux/project-order-tasks.thunk';
import { projectOrderTasksSelectors } from '../redux/project-order-tasks.slice';

export function useOrderTasks(projectId: string, orderId: string) {
  const dispatch = useAppDispatch();

  const orderTasks = useAppSelector((state) =>
    projectOrderTasksSelectors
      .selectAll(state)
      .filter((oT) => oT.orderId === orderId)
  );

  React.useEffect(() => {
    dispatch(projectOrderTasksThunk.list({ projectId }));
  }, []);

  return { orderTasks };
}
