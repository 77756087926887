import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { Drawer, IconButton, makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { useLocationHash } from '../../hooks/location-hash.hook';
import { CardWithHeader } from '../card/cardWithHeader/CardWithHeader';

const useStyles = makeStyles(() =>
  createStyles({
    drawerPaper: {
      minWidth: window.innerWidth / 3,
      maxHeight: '100%'
    }
  })
);

export interface CommentsDrawerProps {
  children?: ReactNode;
}

export function CommentsDrawer(props: CommentsDrawerProps) {
  const classes = useStyles();
  const history = useHistory();
  const urlHash = useLocationHash();
  return (
    <Drawer
      open={urlHash === 'comments'}
      anchor="right"
      classes={{
        paper: classes.drawerPaper
      }}
      variant="temporary"
      onClose={history.goBack}
    >
      <IconButton
        onClick={history.goBack}
        style={{ position: 'absolute', right: 10 }}
      >
        <Close />
      </IconButton>
      <CardWithHeader title="Comments" actions={[]}>
        {props.children}
      </CardWithHeader>
    </Drawer>
  );
}
