import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    colorIndicator: {
      height: 18,
      width: 18,
      marginRight: 8,
      borderRadius: '50%',
      display: 'inline-block'
    },
    colorIndicatorActive: {
      backgroundColor: theme.palette.success.main
    },
    colorIndicatorInactive: {
      backgroundColor: 'rgba(242, 201, 76, 1)'
    }
  })
);

export function AccountStatusDisplay({ enabled }: { enabled: boolean }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span
        className={clsx(
          classes.colorIndicator,
          enabled
            ? classes.colorIndicatorActive
            : classes.colorIndicatorInactive
        )}
      />
      <Typography>{enabled ? 'enabled' : 'disabled'}</Typography>
    </div>
  );
}
