import {
  Project,
  ProjectCandidate,
  Supplier,
  supplierAttachmentPermission,
  SupplierDocumentAttachment
} from 'model';
import React, { PropsWithChildren } from 'react';
import { compareDesc } from 'date-fns';
import {
  Badge,
  Box,
  ButtonBase,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Check,
  Close as CancelIcon,
  MailOutline,
  ThumbDown,
  ThumbUp,
  Undo
} from '@material-ui/icons';
import { IoMdTrash } from 'react-icons/io';
import { IoDocumentAttach } from 'react-icons/io5';
import { Skeleton } from '@material-ui/lab';
import { ImAttachment } from 'react-icons/im';
import { RiArrowRightCircleLine } from 'react-icons/ri';
import { Link as RouterLink } from 'react-router-dom';
import { useCommentsOfRequest } from '../../hooks/request-comments.hook';
import { useSupplier } from '../../../suppliers/hooks/supplier.hook';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { StyledLink } from '../../../../components/input/link/StyledLink';
import { formatDate } from '../../../../localized-formats';
import {
  commentProjectCandidateThunk,
  makeProjectCandidateDecisionThunk,
  removeProjectCandidateDecisionThunk,
  removeProjectCandidateThunk,
  updateProjectCandidateThunk
} from '../../redux/projects.thunk';
import { AppDispatch } from '../../../../redux/store';
import { ActionsMenu } from '../../../../components/actions/ActionsMenu';
import { CandidateTimeline } from './CandidateTimeline';
import { useAttachmentsOfSupplier } from '../../../suppliers/hooks/supplier-attachments.hook';
import { useDocumentType } from '../../../suppliers/hooks/document-type.hook';
import { createFileGrant } from '../../../../utils';

export function BadgedIcon({
  badgeIcon,
  children
}: PropsWithChildren<{ badgeIcon: JSX.Element }>) {
  return (
    <Badge
      style={{ margin: '0.25em' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      badgeContent={badgeIcon}
    >
      {children}
    </Badge>
  );
}

interface CandidateAction {
  value: (pc: ProjectCandidate) => React.ReactNode | undefined;
  active: (pc: ProjectCandidate, undo: boolean) => boolean;
  additional: (pc: ProjectCandidate, undo: boolean) => boolean;
  icon: JSX.Element;
  actionIcon: JSX.Element;
  title: string;
  stateTooltip: string;
  actionTooltip: string;
  action: (
    dispatch: AppDispatch,
    projectId: string,
    candidate: ProjectCandidate,
    undo: boolean
  ) => Promise<void>;
}

export const CandidateActions: CandidateAction[] = [
  {
    value: (pc) =>
      pc.contactedOn ? formatDate(pc.contactedOn, 'date') : undefined,
    active: (pc) => !pc.removedOn,
    additional: (pc, undo) => !!pc.decision || undo,
    icon: <MailOutline style={{ fontSize: '1.25em' }} />,
    actionIcon: <RiArrowRightCircleLine style={{ fontSize: '1.25em' }} />,
    title: 'Contacted',
    stateTooltip: 'Candidate was contacted',
    actionTooltip: 'Mark candidate contacted',
    action: (dispatch, projectId, candidate, undo) => {
      return (
        (
          undo
            ? dispatch(
                updateProjectCandidateThunk({
                  projectId,
                  candidateId: candidate.id,
                  dto: {
                    supplierId: candidate.supplierId,
                    positionId: candidate.positionId,
                    contactedOn: null
                  }
                })
              )
            : dispatch(
                updateProjectCandidateThunk({
                  projectId,
                  candidateId: candidate.id,
                  dto: {
                    supplierId: candidate.supplierId,
                    positionId: candidate.positionId,
                    contactedOn: new Date()
                  }
                })
              )
        )
          .then(() => undefined)
          // eslint-disable-next-line promise/no-return-wrap
          .catch((err) => Promise.reject(err))
      );
    }
  },
  {
    value: (pc) =>
      pc.decision?.status === 'confirmed'
        ? formatDate(pc.decision.createdAt, 'date')
        : undefined,
    active: (pc) => !pc.removedOn && pc.decision?.status !== 'declined',
    additional: (_, undo) => undo,
    icon: <ThumbUp htmlColor="green" style={{ fontSize: '1.25em' }} />,
    actionIcon: <RiArrowRightCircleLine style={{ fontSize: '1.25em' }} />,
    stateTooltip: 'Candidate was accepted',
    actionTooltip: 'Mark candidate accepted',
    title: 'Accept',
    action: (dispatch, projectId, candidate, undo) => {
      return (
        (
          undo
            ? dispatch(
                removeProjectCandidateDecisionThunk({
                  projectId,
                  candidateId: candidate.id
                })
              )
            : dispatch(
                makeProjectCandidateDecisionThunk({
                  projectId,
                  candidateId: candidate.id,
                  dto: {
                    status: 'confirmed',
                    comment: null
                  }
                })
              )
        )
          .then(() => undefined)
          // eslint-disable-next-line promise/no-return-wrap
          .catch((err) => Promise.reject(err))
      );
    }
  },
  {
    value: (pc) =>
      pc.decision?.status === 'declined'
        ? formatDate(pc.decision.createdAt, 'date')
        : undefined,
    active: (pc) => !pc.removedOn && pc.decision?.status !== 'confirmed',
    additional: (_, undo) => undo,
    icon: (
      <ThumbDown
        htmlColor="red"
        fontSize="small"
        style={{ fontSize: '1.25em' }}
      />
    ),
    stateTooltip: 'Candidate was rejected',
    actionTooltip: 'Mark candidate rejected',
    actionIcon: <RiArrowRightCircleLine style={{ fontSize: '1.25em' }} />,
    title: 'Decline',
    action: (dispatch, projectId, candidate, undo) => {
      return (
        (
          undo
            ? dispatch(
                removeProjectCandidateDecisionThunk({
                  projectId,
                  candidateId: candidate.id
                })
              )
            : dispatch(
                makeProjectCandidateDecisionThunk({
                  projectId,
                  candidateId: candidate.id,
                  dto: {
                    status: 'declined',
                    comment: null
                  }
                })
              )
        )
          .then(() => undefined)
          // eslint-disable-next-line promise/no-return-wrap
          .catch((err) => Promise.reject(err))
      );
    }
  },
  {
    value: (pc) =>
      pc.removedOn ? formatDate(pc.removedOn, 'date') : undefined,
    active: () => true,
    additional: () => true,
    icon: <IoMdTrash style={{ fontSize: '1.25em' }} />,
    stateTooltip: 'Candidate was sorted out',
    actionTooltip: 'Sort out candidate',
    actionIcon: <RiArrowRightCircleLine style={{ fontSize: '1.25em' }} />,
    title: 'Sort out',
    action: (dispatch, projectId, candidate) => {
      return (
        dispatch(
          removeProjectCandidateThunk({
            projectId,
            candidateId: candidate.id
          })
        )
          .then(() => undefined)
          // eslint-disable-next-line promise/no-return-wrap
          .catch((err) => Promise.reject(err))
      );
    }
  }
];

function SupplierDocumentItem({
  document
}: {
  document: SupplierDocumentAttachment;
}) {
  const { documentType } = useDocumentType(document.documentTypeId);
  if (!documentType) {
    return <Skeleton />;
  }
  return (
    <ListItem dense>
      <ListItemIcon>
        <IoDocumentAttach fontSize="1.25em" />
        <Chip
          size="small"
          style={{
            backgroundColor: document.documentState?.color ?? 'grey',
            height: '1.4em'
          }}
          label={document.documentState?.name ?? ''}
        />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="subtitle1">{documentType.name}</Typography>
        <div style={{ paddingLeft: '0.75em' }}>
          {document.attachedFiles?.map((a) => (
            <RouterLink
              to={`/files/${a.id}/${
                createFileGrant(a.id, supplierAttachmentPermission, document)
                  .token
              }`}
              target="blank"
            >
              {a.name}
            </RouterLink>
          ))}
        </div>
      </ListItemText>
    </ListItem>
  );
}

function SupplierDocumentPopover({ supplier }: { supplier: Supplier }) {
  const { attachments } = useAttachmentsOfSupplier(supplier.id);
  const documents = React.useMemo(
    () =>
      attachments
        .filter((a) => a.attachmentType === 'document')
        .sort(
          (a, b) =>
            (b.attachedFiles?.length ?? 0) - (a.attachedFiles?.length ?? 0)
        ),
    [attachments]
  ) as SupplierDocumentAttachment[];
  const [anchorEl, setAnchorEl] = React.useState<Element>();
  return (
    <>
      <IconButton
        size="small"
        onClick={(ev) => setAnchorEl(ev.target as Element)}
      >
        {' '}
        <ImAttachment />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(undefined)}
      >
        <List dense>
          {documents.length === 0 ? (
            <ListItem>
              <ListItemText>
                <Typography variant="subtitle1">No documents</Typography>
              </ListItemText>
            </ListItem>
          ) : (
            documents.map((doc) => <SupplierDocumentItem document={doc} />)
          )}
        </List>
      </Popover>
    </>
  );
}
export function CandidateDisplay({
  project,
  candidate,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removed = false
}: {
  project: Project;
  candidate: ProjectCandidate;
  removed?: boolean;
}) {
  const { comments } = useCommentsOfRequest(project.requestId as string);
  const candidateComments = React.useMemo(
    () =>
      comments
        .filter((c) => c.candidateId === candidate.id)
        .sort((a, b) =>
          compareDesc(new Date(a.createdAt), new Date(b.createdAt))
        ),
    [comments, candidate.id]
  );
  const { supplier } = useSupplier(candidate.supplierId);

  const dispatch = useAppDispatch();
  const [showComments] = React.useState(false);
  const [showCommentEditor, setShowCommentEditor] = React.useState(false);
  const [commentValue, setCommentValue] = React.useState('');
  return (
    <ListItem dense style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}>
      {supplier ? (
        <ListItemText>
          <Box display="flex">
            <StyledLink
              to={`/suppliers/supplier/${supplier?.id}`}
            >{`${supplier.name.first} ${supplier.name.last}`}</StyledLink>
            {supplier && <SupplierDocumentPopover supplier={supplier} />}
          </Box>
          <Box />
          <CandidateTimeline candidate={candidate} project={project} />
          {showComments && (
            <Box display="flex" flexDirection="column" marginTop="0.5em">
              {showCommentEditor ? (
                <Badge
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <Box display="flex">
                      <IconButton
                        onClick={() => setShowCommentEditor(false)}
                        size="small"
                      >
                        <CancelIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          dispatch(
                            commentProjectCandidateThunk({
                              projectId: project.id,
                              candidateId: candidate.id,
                              dto: {
                                comment: commentValue
                              }
                            })
                          );
                          setCommentValue('');
                          setShowCommentEditor(false);
                        }}
                        size="small"
                      >
                        <Check fontSize="small" />
                      </IconButton>
                    </Box>
                  }
                >
                  <TextField
                    inputProps={{
                      style: {
                        padding: 0
                      }
                    }}
                    /* eslint-disable-next-line react/jsx-no-duplicate-props */
                    InputProps={{
                      style: {
                        padding: '0.2em'
                      }
                    }}
                    variant="outlined"
                    placeholder=""
                    fullWidth
                    multiline
                    rows={1}
                    style={{ padding: 0 }}
                    onChange={(e) => setCommentValue(e.target.value)}
                    value={commentValue}
                  />
                </Badge>
              ) : (
                <TextField
                  inputProps={{
                    style: {
                      padding: 0
                    }
                  }}
                  variant="outlined"
                  placeholder=""
                  fullWidth
                  style={{ padding: 0 }}
                  onFocus={() => setShowCommentEditor(true)}
                  value={commentValue}
                />
              )}
              {candidateComments.length > 0 && (
                <Box display="flex" flexDirection="column">
                  {candidateComments.map((cc) => (
                    <>
                      <Typography variant="subtitle2">
                        {formatDate(cc.createdAt, 'date-time')}
                      </Typography>
                      <Typography variant="body2">{cc.comment}</Typography>
                    </>
                  ))}
                </Box>
              )}
            </Box>
          )}
        </ListItemText>
      ) : (
        <Skeleton />
      )}
      <ListItemSecondaryAction style={{ top: '1.25em' }}>
        {CandidateActions.filter(
          (ac) =>
            ac.active(candidate, ac.value(candidate) !== undefined) &&
            !ac.additional(candidate, ac.value(candidate) !== undefined)
        ).map((ac) => {
          const undo = ac.value(candidate) !== undefined;
          return (
            <ButtonBase
              title={ac.actionTooltip}
              onClick={() => {
                ac.action(dispatch, project.id, candidate, undo);
              }}
            >
              <BadgedIcon
                badgeIcon={undo ? <Undo fontSize="small" /> : ac.actionIcon}
              >
                {ac.icon}
              </BadgedIcon>
            </ButtonBase>
          );
        })}

        <ActionsMenu
          iconButtonProps={{ size: 'small', style: { fontSize: '1.25em' } }}
          items={CandidateActions.filter(
            (ac) =>
              ac.active(candidate, ac.value(candidate) !== undefined) &&
              ac.additional(candidate, ac.value(candidate) !== undefined)
          ).map((ac) => {
            const undo = ac.value(candidate) !== undefined;
            return {
              icon: undo ? <Undo fontSize="small" /> : ac.icon,
              label: (undo ? 'Undo ' : '') + ac.title,
              onClick: () => {
                ac.action(
                  dispatch,
                  project.id,
                  candidate,
                  ac.value(candidate) !== undefined
                );
              }
            };
          })}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
