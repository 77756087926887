import React from 'react';
import * as yup from 'yup';
import { AddressDTO, ClientCommentDTO, CreateClientDTO } from 'model';
import { EntityId, unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { Avatar, Grid } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BusinessIcon from '@material-ui/icons/Business';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { DialogForm } from '../../../components/input/form/DialogForm';
import clientSlice, { clientSelectors } from '../redux/clients.slice';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { createClientThunk } from '../redux/actions/create-client.thunk';
import { NoOp } from '../../../utils';
import { FormikTextField } from '../../../components/input/FormikTextField';
import { AddressCard } from './AddressFormElement';
import { ClientProfileForm } from './ClientProfileForm';
import { CardWithHeader } from '../../../components/card/cardWithHeader/CardWithHeader';

const initialCommentSchema: yup.SchemaOf<ClientCommentDTO> = yup
  .object()
  .shape({
    comment: yup.string().required('required'),
    attachedFiles: yup.array().of(
      yup
        .object({
          fileAccessId: yup.string().uuid().required() as any,
          token: yup.string().required() as any
        })
        .required()
    )
  });
export const clientSchema: yup.SchemaOf<CreateClientDTO> = yup.object().shape({
  client: yup
    .object()
    .required()
    .shape({
      name: yup
        .string()
        .max(255, 'Max. 255 characters')
        .required('required')
        .min(3, 'at leat 3 characters'),
      description: yup.string().max(255, 'Max. 255 characters').nullable(),
      taxId: yup.string().max(255, 'Max. 255 characters').nullable(),
      ceo: yup.string().max(255, 'Max. 255 characters').nullable(),
      email: yup.string().max(255, 'Max. 255 characters').nullable(),
      phone: yup.string().max(255, 'Max. 255 characters').nullable()
    }),
  initialComment: initialCommentSchema.notRequired().default(undefined),
  initialAddresses: yup
    .object()
    .notRequired()
    .default(undefined)
    .shape({
      headQuarterAddress: yup
        .object()
        .shape({
          address: yup
            .array()
            .required()
            .of(yup.string().max(255, 'Max. 255 characters').required()),
          zipCode: yup
            .string()
            .max(255, 'Max. 255 characters')
            .default(null)
            .nullable(),
          city: yup
            .string()
            .max(255, 'Max. 255 characters')
            .required('required'),
          countryCode: yup.string().max(255, 'Max. 255 characters').required(),
          comment: yup
            .string()
            .max(255, 'Max. 255 characters')
            .default(null)
            .nullable()
        })
        .default(undefined)
        .nullable()
        .notRequired(),
      invoiceAddress: yup
        .object()
        .shape({
          address: yup
            .array()
            .required()
            .of(yup.string().max(255, 'Max. 255 characters').required()),
          zipCode: yup
            .string()
            .max(255, 'Max. 255 characters')
            .default(null)
            .nullable(),
          city: yup
            .string()
            .max(255, 'Max. 255 characters')
            .required('required'),
          countryCode: yup.string().max(255, 'Max. 255 characters').required(),
          comment: yup
            .string()
            .max(255, 'Max. 255 characters')
            .default(null)
            .nullable()
        })
        .default(undefined)
        .nullable()
        .notRequired(),
      additionalAddresses: yup
        .array()
        .required()
        .of(
          yup
            .object()
            .required()
            .shape({
              address: yup
                .array()
                .required()
                .of(yup.string().max(255, 'Max. 255 characters').required()),
              zipCode: yup
                .string()
                .max(255, 'Max. 255 characters')
                .default(null)
                .nullable(),
              city: yup
                .string()
                .max(255, 'Max. 255 characters')
                .required('required'),
              countryCode: yup
                .string()
                .max(255, 'Max. 255 characters')
                .required(),
              comment: yup
                .string()
                .max(255, 'Max. 255 characters')
                .default(null)
                .nullable()
            })
        )
    })
}) as any;

const initialValues: CreateClientDTO = {
  client: {
    name: '',
    ceo: null,
    description: null,
    email: null,
    phone: null,
    taxId: null,
    defaultCurrency: null
  },
  initialAddresses: {
    additionalAddresses: []
  }
};

const initialAddressValues: () => AddressDTO = () => ({
  address: [''],
  zipCode: null,
  city: '',
  countryCode: '',
  comment: null
});

const useStyles = makeStyles((theme) =>
  createStyles({
    commentCardContainer: {
      marginTop: theme.spacing(2)
    },
    cardHeader: {
      paddingTop: theme.spacing(1),
      paddingBottom: 0
    },
    addressAvatar: {
      marginLeft: theme.spacing(1),
      height: 26,
      width: 26
    }
  })
);
export function ClientFormDialog() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const createClient = (dto: CreateClientDTO) =>
    dispatch(createClientThunk(dto)).then(unwrapResult).catch(NoOp);

  const history = useHistory();

  const navigateToDetails = (id?: EntityId) =>
    history.replace(`/clients/${id}`);

  return (
    <DialogForm
      maxWidth="md"
      identifier="client"
      label="New Client"
      form={{
        initialValues,
        validationSchema: clientSchema
      }}
      api={{
        clearError: clientSlice.actions.clearError,
        errorSelector: clientSelectors.selectError,
        onSubmit: createClient,
        stateSelector: clientSelectors.selectState
      }}
      actions={[
        { label: t('Save and Exit'), doSubmit: true },
        {
          label: t('Save and Details'),
          doSubmit: true,
          onClick: navigateToDetails
        }
      ]}
    >
      {({ values, setFieldValue }) => (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CardWithHeader title="Basic">
              <ClientProfileForm fieldBasePath="client" />
            </CardWithHeader>
            <CardWithHeader
              title="Initial Comment"
              cardProps={{ className: classes.commentCardContainer }}
            >
              <FormikTextField
                multiline
                rows={3}
                name="initialComment.comment"
                label="Comment"
              />
            </CardWithHeader>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <CardWithHeader title="Addresses">
              <AddressCard
                label="HeadQuarter Address"
                icon={
                  <Avatar className={classes.addressAvatar}>
                    <BusinessIcon color="action" fontSize="inherit" />
                  </Avatar>
                }
                onChange={() =>
                  setFieldValue(
                    'initialAddresses.headQuarterAddress',
                    values.initialAddresses?.headQuarterAddress
                      ? null
                      : initialAddressValues()
                  )
                }
                name={
                  values.initialAddresses?.headQuarterAddress
                    ? 'initialAddresses.headQuarterAddress'
                    : null
                }
              />
              <AddressCard
                label="Invoice Address"
                icon={
                  <Avatar style={{ marginLeft: 10, height: 26, width: 26 }}>
                    <AttachMoneyIcon color="action" fontSize="inherit" />
                  </Avatar>
                }
                onChange={() =>
                  setFieldValue(
                    'initialAddresses.invoiceAddress',
                    values.initialAddresses?.invoiceAddress
                      ? null
                      : initialAddressValues()
                  )
                }
                name={
                  values.initialAddresses?.invoiceAddress
                    ? 'initialAddresses.invoiceAddress'
                    : null
                }
              />
              {values.initialAddresses?.additionalAddresses.map(
                (address, index) => {
                  return (
                    <AddressCard
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={`additional-address-${index}`}
                      label="Additional Address"
                      onChange={() =>
                        setFieldValue(
                          `initialAddresses.additionalAddresses`,
                          [
                            ...(values.initialAddresses?.additionalAddresses ??
                              [])
                          ].filter((_, i) => i !== index)
                        )
                      }
                      name={`initialAddresses.additionalAddresses[${index}]`}
                    />
                  );
                }
              )}
              <AddressCard
                label="Additional Address"
                name={null}
                onChange={() =>
                  setFieldValue('initialAddresses.additionalAddresses', [
                    ...(values.initialAddresses?.additionalAddresses ?? []),
                    initialAddressValues()
                  ])
                }
              />
            </CardWithHeader>
          </Grid>
        </Grid>
      )}
    </DialogForm>
  );
}
