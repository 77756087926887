import {
  ClientContact,
  OrderStepTask,
  ServiceStep,
  ServiceTaskAssignee,
  Supplier
} from 'model';
import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import {
  Assignment as ProjectsIcon,
  Cancel,
  Check,
  EmojiPeople as SuppliersIcon
} from '@material-ui/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import ClientsIcon from '@material-ui/icons/SupervisedUserCircle';

import SettingsIcon from '@material-ui/icons/Settings';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useEntityIdSelector } from '../../../../redux/entity-id-selector.hook';
import { EntityAPI } from '../../../../redux/entity-api.hook';
import { EntityReferenceSelect } from '../../../../components/select/EntityReferenceSelect';
import { apiClient } from '../../../../api/apiClient';
import { NoOp } from '../../../../utils';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { projectOrderTasksThunk } from '../../redux/project-order-tasks.thunk';
import { useSupplierAssigneeValidator } from '../../hooks/supplier-assignee-matcher.hook';
import { useAlert } from '../../../../components/alert/alert.hook';
import { aggregateProjectExpensesThunk } from '../../redux/project-expenses.thunk';

const useStyles = makeStyles((theme) =>
  createStyles({
    assigneeDefault: {
      marginRight: 2,
      color: theme.palette.action.active
    },
    assigneeError: {
      marginRight: 2,
      color: theme.palette.error.main
    }
  })
);

const iconMap = {
  'project-manager': ProjectsIcon,
  supplier: SuppliersIcon,
  client: ClientsIcon
};

export const tmpHelper = {
  'project-manager': 'projectManagerAssigneeId',
  supplier: 'supplierAssigneeId',
  client: 'clientContactAssigneeId'
} as const;

export function StepCardAssigneePicker<T extends Supplier | ClientContact>({
  task,
  serviceStep,
  type,
  api,
  projectId
}: {
  task: OrderStepTask;
  serviceStep: ServiceStep;
  type: ServiceTaskAssignee;
  api: EntityAPI<T>;
  projectId: string;
}) {
  const classes = useStyles();
  const [pickMode, setPickMode] = React.useState<'view' | 'pick'>('view');

  const [currentId, setCurrentId] = React.useState(
    task[tmpHelper[type]] as string | null
  );

  const { entity } = useEntityIdSelector(
    api,
    currentId // task[tmpHelper[type]] as string | null
  );
  const dispatch = useAppDispatch();
  const Icon = iconMap[type];

  const { t } = useTranslation();
  const validation = useSupplierAssigneeValidator(
    task,
    type === 'supplier' && entity && (entity as Supplier).services
      ? (entity as Supplier)
      : undefined
  );
  const { showAlert } = useAlert();
  const submit = () => {
    return apiClient
      .put(`/projects/project/${projectId}/tasks/${task.id}/assignee`, {
        type: tmpHelper[type].substring(0, tmpHelper[type].length - 2),
        assigneeId: currentId
      })
      .then(() => {
        dispatch(aggregateProjectExpensesThunk({ projectId }));
        return dispatch(projectOrderTasksThunk.get({ projectId, id: task.id }));
      })
      .then(unwrapResult)
      .then((res) => {
        setPickMode('view');
        return res;
      })
      .catch(NoOp);
  };
  return pickMode === 'view' ? (
    <div
      style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        alignItems: 'flex-end'
      }}
    >
      <div style={{ display: 'flex', paddingRight: 20 }}>
        <Icon
          fontSize="small"
          className={
            serviceStep.assigneeTypes[type]?.required && !entity
              ? classes.assigneeError
              : classes.assigneeDefault
          }
          titleAccess={type}
        />
        <Typography variant="body1" color="textSecondary">{`${
          entity ? `${entity.name.first} ${entity.name.last}` : ''
        }`}</Typography>
      </div>
      <IconButton
        size="small"
        onClick={() => {
          setPickMode('pick');
        }}
      >
        <SettingsIcon fontSize="small" />
      </IconButton>
    </div>
  ) : (
    <>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <EntityReferenceSelect
        currentValue={currentId as T['id'] | null}
        entityApi={api}
        optionLabel={(v) => `${v.name.first} ${v.name.last}`}
        selectValue={(v) => setCurrentId(v)}
        filter={
          type === 'project-manager'
            ? (v: Supplier) => v.userId !== null
            : () => true
        }
      />
      {serviceStep.assigneeTypes[type]?.required && !currentId ? (
        <></>
      ) : (
        <>
          {type !== 'supplier' ||
            (validation && (
              <IconButton
                onClick={() => {
                  if (type === 'supplier' && validation.length > 0) {
                    showAlert({
                      title: t('Invalid supplier'),
                      onConfirm: () => {
                        submit();
                      },
                      content: (
                        <>
                          {validation.map((e) => (
                            <Typography>
                              {e.message} ({e.critical ? t('Critical') : ''})
                            </Typography>
                          ))}
                        </>
                      )
                    });
                  } else {
                    submit();
                  }
                }}
              >
                <Check />
              </IconButton>
            ))}
        </>
      )}
      <IconButton
        onClick={() => {
          setPickMode('view');
        }}
      >
        <Cancel />
      </IconButton>
    </>
  );
}
