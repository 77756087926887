import { NavLink } from './types';
import { urlNavLinkMap } from './mainNavigationMap';

export function parseLocationElements(location: string): NavLink[] {
  const pathElements = location.split('/').filter((s) => s.trim() !== '');
  return pathElements
    .reduce(
      (acc, c) =>
        [`${acc[0]}/${c}`, [...acc[1], urlNavLinkMap[`${acc[0]}/${c}`]]] as [
          string,
          NavLink[]
        ],
      ['', []] as [string, NavLink[]]
    )[1]
    .filter((v) => v !== undefined);
}

export const getUuidUrlParamMatcher = (urlParam: string) =>
  // eslint-disable-next-line max-len
  `:${urlParam}([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})`;
