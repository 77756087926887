import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DocumentAttachmentPatchDTO,
  documentAttachmentTransformer,
  DocumentSupplierAttachmentDTO,
  permissions,
  SupplierDocumentAttachment
} from 'model';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Button,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@material-ui/core';
import { ErrorOutlined } from '@material-ui/icons';
import { createStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { NewEditableCard } from '../../../../components/card/editableCard/NewEditableCard';
import { SupplierAttachmentDisplay } from './SupplierAttachmentDisplay';
import {
  SupplierAttachmentForm,
  supplierAttachments
} from './SupplierAttachmentForm';
import { useDocumentTypes } from '../../hooks/document-types.hook';
import { SupplierComponent } from '../types';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { useDocumentType } from '../../hooks/document-type.hook';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { supplierAttachmentsThunk } from '../../redux/supplier-attachments.thunk';
import { NoOp } from '../../../../utils';
import { useAttachmentsOfSupplier } from '../../hooks/supplier-attachments.hook';
import { suppliersThunk } from '../../redux/suppliers.thunk';

function NewDocumentCard({ supplierId }: { supplierId: string }) {
  const { type, reference } = useEditorLocation();
  const { documentType } = useDocumentType(reference || '');
  const dispatch = useAppDispatch();

  const createDocumentAttachment = (dto: DocumentAttachmentPatchDTO) => {
    return dispatch(
      supplierAttachmentsThunk.create({
        supplierId,
        input: { attachment: dto as DocumentSupplierAttachmentDTO }
      })
    )
      .then((res) => {
        dispatch(suppliersThunk.get({ id: supplierId }));
        return res;
      })
      .then(unwrapResult)
      .catch(NoOp) as Promise<void | SupplierDocumentAttachment>;
  };

  if (!documentType) {
    return <></>;
  }

  return (
    <>
      {type === 'create' && (
        <Grid item xs={6}>
          <NewEditableCard
            identifier={reference || ''}
            title={`New ${documentType?.name}`}
            value={
              new SupplierDocumentAttachment({
                documentTypeId: documentType.id,
                attachmentType: 'document',
                pin: false,
                attachedFiles: [],
                documentStateId: ''
              })
            }
            display={{ component: SupplierAttachmentDisplay }}
            form={{
              component: SupplierAttachmentForm,
              viewFactory: supplierAttachments.viewFactory,
              validationSchema: supplierAttachments.validationSchema,
              dtoTransformer: documentAttachmentTransformer((s) => btoa(s)),
              onSubmit: createDocumentAttachment,
              permissions: permissions.suppliers.supplier.edit
            }}
          />
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    activationConstrainIcon: {
      color: theme.palette.warning.light
    }
  })
);

export function SupplierAttachments({ supplier }: SupplierComponent) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { entities } = useDocumentTypes();
  const { type } = useEditorLocation();

  const history = useHistory();
  const { url } = useRouteMatch();

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickNew = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const supplierDocuments = useAttachmentsOfSupplier(
    supplier.id
  ).attachments.filter(
    (a) => a.attachmentType === 'document'
  ) as SupplierDocumentAttachment[];

  const updateDocumentAttachment =
    (id: string) => (dto: DocumentAttachmentPatchDTO) => {
      return dispatch(
        supplierAttachmentsThunk.update({
          supplierId: supplier.id,
          id,
          input: { attachment: dto }
        })
      )
        .then((res) => {
          dispatch(suppliersThunk.get({ id: supplier.id }));
          return res;
        })
        .then(unwrapResult)
        .catch(NoOp) as Promise<void | SupplierDocumentAttachment>;
    };

  const availableNewDocuments = entities.filter(
    (e) => !supplierDocuments.map((a) => a.documentTypeId).includes(e.id)
  );

  if (!supplier.history) {
    return <Skeleton />;
  }
  return (
    <Grid container spacing={3}>
      {type === 'view' && availableNewDocuments.length > 0 && (
        <Grid item xs={12}>
          <Button color="primary" variant="outlined" onClick={handleClickNew}>
            {t('New Document')}
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {availableNewDocuments.map((doc) => (
              <MenuItem
                onClick={() => {
                  history.push(`${url}?type=create#${doc.id}`);
                  handleCloseMenu();
                }}
              >
                <Typography
                  style={{
                    display: 'inline-flex'
                  }}
                >
                  {doc.name}
                  {doc.activationConstraint && (
                    <Tooltip title={`${t('needed for activation')}`}>
                      <ErrorOutlined
                        style={{ marginLeft: 10 }}
                        className={classes.activationConstrainIcon}
                      />
                    </Tooltip>
                  )}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}
      <NewDocumentCard supplierId={supplier.id} />

      {supplierDocuments.map((a) => (
        <Grid item xs={6}>
          <NewEditableCard
            identifier={a.id as string}
            title={t(a.documentType.name)}
            value={a}
            display={{ component: SupplierAttachmentDisplay }}
            form={{
              component: SupplierAttachmentForm,
              viewFactory: supplierAttachments.viewFactory,
              validationSchema: supplierAttachments.validationSchema,
              dtoTransformer: documentAttachmentTransformer((s) => btoa(s)),
              onSubmit: updateDocumentAttachment(a.id),
              permissions: permissions.suppliers.supplier.edit
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
