import React, { useContext } from 'react';
import { LanguageIndexContext } from './language-index-context.hook';
import { listLanguagesThunk } from '../redux/actions/list-languages.thunk';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { selectLanguagesListFetched } from '../redux/languages.slice';

export function useLanguageIndex() {
  const dispatch = useAppDispatch();
  const listFetched = useAppSelector(selectLanguagesListFetched);

  const languageIndex = useContext(LanguageIndexContext);
  React.useEffect(() => {
    if (
      !Object.values(languageIndex.descriptors).length &&
      listFetched === false
    ) {
      dispatch(listLanguagesThunk());
    }
  }, []);

  return languageIndex;
}
