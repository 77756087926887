import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import { AlertContext, AlertContextState } from './alert.context';

export default function KontextorAlert({
  children
}: PropsWithChildren<unknown>) {
  const [alertState, setAlertState] = React.useState<AlertContextState | null>(
    null
  );
  const { t } = useTranslation();
  const handleClose = () => {
    setAlertState(null);
  };

  const handleConfirm = () => {
    alertState?.onConfirm();
    handleClose();
  };

  return (
    <AlertContext.Provider value={setAlertState}>
      {children}
      <Dialog
        open={!!alertState}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{alertState?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertState?.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('Disagree')}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {t('Agree')}
          </Button>
        </DialogActions>
      </Dialog>
    </AlertContext.Provider>
  );
}
