import React from 'react';
import { BaseEntity } from 'model';
import { Autocomplete } from '@material-ui/lab';
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { EntityAPI, useEntityAPI } from '../../redux/entity-api.hook';
import { useAppDispatch } from '../../redux/redux.hooks';

export type SelectValue<T extends BaseEntity> =
  | (T['id'] | null)
  | T['id'][]
  | (T | null)
  | T[];

export interface BaseEntityReferenceSelectProps<
  T extends BaseEntity,
  V extends SelectValue<T>
> {
  currentValue: V;
  action?: (
    input: string,
    onClear: (v?: T) => void
  ) => Promise<JSX.Element | null>;
  entityApi: EntityAPI<T>;
  optionLabel: (value: T) => string;
  selectValue: (value: V) => void;
  disabled?: boolean;
  textFieldProps?: Omit<TextFieldProps, 'name'>;
  filter?: (value: T) => boolean;
  openOnFocus?: boolean;
  style?: React.CSSProperties;
}
export interface SingleEntityReferenceSelectProps<T extends BaseEntity> {
  currentValue: T | null;
  entity: true;
  multiple?: false;
}
export interface MultipleEntityReferenceSelectProps<T extends BaseEntity> {
  currentValue: T[];
  entity: true;
  multiple: true;
}
export interface SingleEntityIdReferenceSelectProps<T extends BaseEntity> {
  currentValue: T['id'] | null;
  entity?: false;
  multiple?: false;
}
export interface MultipleEntityIdReferenceSelectProps<T extends BaseEntity> {
  currentValue: T['id'][];
  entity?: false;
  multiple: true;
}

type EntitySelType<
  V extends (T | null) | T[],
  T extends BaseEntity
> = V extends T
  ? SingleEntityReferenceSelectProps<T>
  : V extends T[]
  ? MultipleEntityReferenceSelectProps<T>
  : never;

type IdSelType<
  V extends (T['id'] | null) | T['id'][],
  T extends BaseEntity
> = V extends T['id']
  ? SingleEntityIdReferenceSelectProps<T>
  : V extends T['id'][]
  ? MultipleEntityIdReferenceSelectProps<T>
  : never;

export type SelectProps<
  T extends BaseEntity,
  V extends SelectValue<T>
> = BaseEntityReferenceSelectProps<T, V> &
  (V extends null | T['id'] | T['id'][]
    ? IdSelType<V, T>
    : V extends null | T | T[]
    ? EntitySelType<V, T>
    : never) & { resetOnSelect?: boolean };

const useStyles = makeStyles({
  refreshButton: {
    '&:not(:focus-within) > div > button:first-of-type': {
      display: 'none'
    }
  }
});
export function EntityReferenceSelect<
  T extends BaseEntity,
  V extends SelectValue<T>
>({
  entityApi,
  action,
  optionLabel,
  multiple,
  entity,
  currentValue,
  selectValue,
  disabled = false,
  textFieldProps = {},
  resetOnSelect,
  filter = () => true,
  openOnFocus,
  style
}: SelectProps<T, V>) {
  const { apiState, entities, listFetched } = useEntityAPI(entityApi);
  const classes = useStyles();
  const entityMap: Record<T['id'], T> = Object.fromEntries(
    entities.filter(filter).map((e) => [e.id, e])
  ) as Record<T['id'], T>;

  const [inputValue, setInputValue] = React.useState('');
  const dispatch = useAppDispatch();
  const valueMapper =
    entity === true
      ? (v: V) => {
          return v;
        }
      : (v: V) => {
          if (v === null) {
            return null;
          }
          if (multiple === true) {
            return (v as T['id'][]).map((i) => entityMap[i]);
          }
          return entityMap[v as T['id']];
        };

  if (apiState !== 'idle' || !listFetched) {
    return <CircularProgress />;
  }

  const onChange = (
    newValue: V extends T[] | T['id'][]
      ? T[]
      : V extends T | T['id'] | null
      ? T | null
      : never
  ) => {
    if (newValue) {
      if (entity) {
        selectValue(newValue as any);
      } else if (multiple) {
        selectValue((newValue as T[]).map((e) => e.id) as V);
      } else {
        selectValue((newValue as T).id as V);
      }
    } else {
      selectValue((multiple ? [] : null) as V);
    }
    if (resetOnSelect) {
      setInputValue('');
    }
  };

  const customAction = action
    ? action(inputValue, () => {
        setInputValue('');
      })
    : null;

  return (
    <Autocomplete<
      T,
      V extends T[] | T['id'][] ? true : V extends T | T['id'] ? false : never
    >
      fullWidth
      value={valueMapper(currentValue as V) as any}
      onChange={(_, v) =>
        onChange(
          v as unknown as V extends T[] | T['id'][]
            ? T[]
            : V extends null | T | T['id']
            ? T | null
            : never
        )
      }
      style={style}
      options={Object.values(entityMap)}
      autoHighlight
      inputValue={multiple ? undefined : inputValue}
      onInputChange={
        multiple
          ? undefined
          : (_, value) => {
              setInputValue(value);
            }
      }
      multiple={
        multiple as V extends T[] | T['id'][]
          ? true
          : V extends T | T['id']
          ? false
          : never
      }
      disabled={disabled}
      clearOnEscape
      blurOnSelect
      openOnFocus={!!openOnFocus}
      getOptionLabel={optionLabel}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...textFieldProps}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password'
            }}
            /* eslint-disable-next-line react/jsx-no-duplicate-props */
            InputProps={{
              ...params.InputProps,
              className: clsx(
                params.InputProps.className,
                classes.refreshButton
              ),
              startAdornment:
                customAction && inputValue !== '' ? (
                  <InputAdornment position="start">
                    {customAction}
                  </InputAdornment>
                ) : (
                  params.InputProps.startAdornment
                ),
              endAdornment: (
                <div
                  className={
                    (params.InputProps.endAdornment as any).props?.className ??
                    ''
                  }
                >
                  <IconButton
                    style={{
                      background: 'white',
                      position: 'absolute',
                      right: -20
                    }}
                    size="small"
                    onClick={() => dispatch(entityApi.thunks.list())}
                  >
                    <Refresh fontSize="small" />
                  </IconButton>
                  {typeof params.InputProps.endAdornment === 'object' &&
                  (params.InputProps.endAdornment as any).props?.children
                    ? (params.InputProps.endAdornment as any).props?.children
                    : params.InputProps.endAdornment}
                </div>
              )
              // startAdornment:
              // customAction ?<div>{customAction}</div>:undefined
            }}
          />
        );
      }}
    />
  );
}
