import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Client, permissions } from 'model';
import { ClientProfile } from '../components/ClientProfile';
import { ClientAddresses } from '../components/ClientAddresses';
import {
  EntityDetails,
  TabDefinition
} from '../../../components/details/EntityDetails';
import { useClient } from '../hooks/client.hook';
import { ClientContacts } from '../components/ClientContacts';
import { ClientCommentList } from '../components/comments/ClientCommentList';
import { ClientPrices } from '../components/prices/ClientPrices';
import { ClientProjects } from '../components/clientProjects/ClientProjects';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { clientsThunk } from '../redux/clients.thunk';

const tabs: TabDefinition<Client>[] = [
  {
    name: 'profile',
    subPaths: [''],
    component: (c: Client) => <ClientProfile client={c} />
  },
  {
    name: 'projects',
    subPaths: ['/projects'],
    component: (c: Client) => <ClientProjects clientId={c.id} />
  },
  {
    name: 'prices',
    subPaths: ['/prices'],
    component: (c: Client) => <ClientPrices client={c} />,
    permission: permissions.clients.client.prices.tab
  },
  {
    name: 'Addresses',
    subPaths: ['/addresses'],
    component: (c: Client) => <ClientAddresses client={c} />
  },
  {
    name: 'contacts',
    subPaths: ['/contacts'],
    component: (c: Client) => <ClientContacts client={c} />
  }
];
// Todo remove hack
export function useExplicitClient(clientId: string | undefined) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (clientId) {
      dispatch(clientsThunk.get({ id: clientId }));
    }
  }, [clientId]);
}

export function ClientDetailsScreen() {
  const { clientId } = useParams<{ clientId: string }>();
  const { client } = useClient(clientId);
  // useExplicitClient(clientId);
  return (
    <EntityDetails
      entityName="Client"
      entity={client}
      title="name"
      tabs={tabs}
      renderComments={(c) => (
        <ClientCommentList clientId={c.id} clientComments={c.comments} />
      )}
    />
  );
}
