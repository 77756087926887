import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  permissions,
  SupplierTestAttachment,
  TestAttachmentPatchDTO,
  testAttachmentTransformer,
  TestSupplierAttachmentDTO
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import { SupplierComponent } from '../types';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { useTestTypes } from '../../hooks/test-types.hook';
import { useAttachmentsOfSupplier } from '../../hooks/supplier-attachments.hook';
import { supplierAttachmentsThunk } from '../../redux/supplier-attachments.thunk';
import { suppliersThunk } from '../../redux/suppliers.thunk';
import { NoOp } from '../../../../utils';
import { NewEditableCard } from '../../../../components/card/editableCard/NewEditableCard';
import { SupplierTestDisplay } from './SupplierTestDisplay';
import { SupplierTestForm, supplierTestView } from './SupplierTestForm';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { useTestType } from '../../hooks/test-type.hook';

export function NewSupplierTestCard({ supplierId }: { supplierId: string }) {
  const { type, reference } = useEditorLocation();
  const { testType } = useTestType(reference || '');
  const dispatch = useAppDispatch();

  const createTestAttachment = (dto: TestAttachmentPatchDTO) => {
    return dispatch(
      supplierAttachmentsThunk.create({
        supplierId,
        input: { attachment: dto as TestSupplierAttachmentDTO }
      })
    )
      .then((res) => {
        dispatch(suppliersThunk.get({ id: supplierId }));
        return res;
      })
      .then(unwrapResult)
      .catch(NoOp) as Promise<void | SupplierTestAttachment>;
  };

  if (!testType) {
    return <></>;
  }

  return (
    <>
      {type === 'create' && (
        <Grid item xs={6}>
          <NewEditableCard
            identifier={reference || ''}
            title={testType?.name}
            value={
              new SupplierTestAttachment({
                testTypeId: testType.id,
                attachmentType: 'test',
                pin: false,
                attachedFiles: [],
                testStateId: null,
                testerId: null,
                result: null
              })
            }
            display={{ component: SupplierTestDisplay }}
            form={{
              component: SupplierTestForm,
              viewFactory: supplierTestView.viewFactory,
              validationSchema: supplierTestView.validationSchema,
              dtoTransformer: testAttachmentTransformer((s) => btoa(s)),
              permissions: permissions.suppliers.supplier.edit,
              onSubmit: createTestAttachment
            }}
          />
        </Grid>
      )}
    </>
  );
}

export function SupplierTests({ supplier }: SupplierComponent) {
  const { t } = useTranslation();
  const { entities } = useTestTypes();

  const history = useHistory();
  const { url } = useRouteMatch();
  const { type } = useEditorLocation();

  const dispatch = useAppDispatch();

  const supplierTests = useAttachmentsOfSupplier(
    supplier.id
  ).attachments.filter(
    (a) => a.attachmentType === 'test'
  ) as SupplierTestAttachment[];

  const updateSupplierTest = (id: string) => (dto: TestAttachmentPatchDTO) => {
    return dispatch(
      supplierAttachmentsThunk.update({
        supplierId: supplier.id,
        id,
        input: { attachment: dto }
      })
    )
      .then((res) => {
        dispatch(suppliersThunk.get({ id: supplier.id }));
        return res;
      })
      .then(unwrapResult)
      .catch(NoOp) as Promise<void | SupplierTestAttachment>;
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickNew = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const supplierTestOptions = entities.filter(
    (sta) => !supplierTests.map((a) => a.testTypeId).includes(sta.id)
  );

  return (
    <Grid container spacing={3}>
      {type === 'view' && supplierTestOptions.length > 0 && (
        <Grid item xs={12}>
          <Button color="primary" variant="outlined" onClick={handleClickNew}>
            {t('New Test')}
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {supplierTestOptions.map((sT) => (
              <MenuItem
                onClick={() => {
                  history.push(`${url}?type=create#${sT.id}`);
                  handleCloseMenu();
                }}
              >
                <Typography
                  style={{
                    display: 'inline-flex'
                  }}
                >
                  {sT.name}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}

      <NewSupplierTestCard supplierId={supplier.id} />

      {supplierTests.map((a) => (
        <Grid item xs={6}>
          <NewEditableCard
            identifier={a.id as string}
            title={t(a.testType.name)}
            value={a}
            display={{ component: SupplierTestDisplay }}
            form={{
              component: SupplierTestForm,
              viewFactory: supplierTestView.viewFactory,
              validationSchema: supplierTestView.validationSchema,
              dtoTransformer: testAttachmentTransformer((s) => btoa(s)),
              onSubmit: (d) => updateSupplierTest(a.id)(d as any),
              permissions: permissions.suppliers.supplier.edit
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
