import React from 'react';
import { OutgoingInvoice } from 'model';
import { Typography } from '@material-ui/core';
import { CheckBoxOutlined, CheckBoxOutlineBlank } from '@material-ui/icons';

export function OutgoingInvoiceDisplay({
  value
}: {
  value: OutgoingInvoice | null;
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      {value?.invoiceNumber && (
        <>
          <div>
            <Typography variant="caption" color="textSecondary">
              Invoice Number
            </Typography>
            <Typography>{value?.invoiceNumber}</Typography>
          </div>
          <div style={{ display: 'flex', marginLeft: 30 }}>
            <Typography color="textSecondary">Paid</Typography>
            {value.paid ? (
              <CheckBoxOutlined style={{ marginTop: -3 }} />
            ) : (
              <CheckBoxOutlineBlank style={{ marginTop: -3 }} />
            )}
          </div>
        </>
      )}
    </div>
  );
}
