import React from 'react';
import { permissions, User, UserPatchDTO, userPatchTransformer } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import * as yup from 'yup';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { userThunks } from '../redux/actions/users.thunk';
import { NoOp } from '../../../utils';
import { NewEditableCard } from '../../../components/card/editableCard/NewEditableCard';
import { buildDTOView } from '../../../transformer/DTOViewSchema';
import { AccountForm } from './AccountForm';
import { UserCardDisplay } from './UserCardDisplay';

const patchUserView = buildDTOView<UserPatchDTO>()
  .all()
  .withSchema(
    yup
      .object()
      .defined()
      .shape({
        email: yup.string().email().required('required'),
        displayName: yup.string().defined().nullable().default(null),
        enabled: yup.boolean().required(),
        roleIds: yup.array().of(yup.string().required()).required(),
        useLinkedName: yup.boolean().required()
      })
  );

export function UserCard({ user }: { user: User }) {
  const dispatch = useAppDispatch();

  const editUser = (userDTO: UserPatchDTO) =>
    dispatch(userThunks.update({ id: user.id, input: userDTO }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <div>
      <NewEditableCard
        identifier="user"
        title="User"
        value={user}
        display={{ component: UserCardDisplay }}
        form={{
          onSubmit: editUser,
          dtoTransformer: userPatchTransformer,
          component: AccountForm,
          viewFactory: patchUserView.viewFactory,
          validationSchema: patchUserView.validationSchema,
          permissions: permissions.users.user.patch
        }}
      />
    </div>
  );
}
