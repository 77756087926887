import { CatTool } from 'model';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { listCatTools } from './cat-toos.thunk';

const catToolsSlice = createEntitySlice<CatTool>('cat-tools', [
  CustomThunk.override<CatTool>(listCatTools, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as CatTool[]);
    }
  })
]);
export const catToolsSelector = createAdapterSelectors(
  'cat-tools',
  catToolsSlice.adapter
);

export default catToolsSlice;
