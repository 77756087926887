import { RequestComment, RequestCommentDTO } from 'model';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const createRequestCommentThunk = createErrorHandlingThunk<
  RequestComment,
  { requestId: string; input: RequestCommentDTO }
>('request-comments/create', async ({ requestId, input }) => {
  const comment = await apiClient.post<RequestComment>(
    `/projects/project-request/${requestId}/comments`,
    input
  );
  return (
    await apiClient.get(
      `/projects/project-request/${requestId}/comments/${comment.data.id}`
    )
  ).data;
});
