import React, { ChangeEvent } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField
} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { FixedSizeGrid } from 'react-window';
import CancelIcon from '@material-ui/icons/Cancel';
import { ResourceTranslation } from 'model';
import {
  CancelOutlined,
  CloudDownload,
  CloudUpload,
  Edit
} from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import { ExportToCsv } from 'export-to-csv';
import { parse } from 'papaparse';
import { useResourceTranslations } from './translations.hook';
import { useAppDispatch } from '../../redux/redux.hooks';
import {
  importTranslationsThunk,
  updateTranslationsThunk
} from './translations.thunk';
import { NoOp } from '../../utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    toolBar: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      margin: theme.spacing(1)
      // height: theme.spacing(13)
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.5)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark
          },
    leftSide: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    rightSide: {
      flex: 1
    },
    primaryActionButton: {
      backgroundColor:
        theme.palette.type === 'light'
          ? lighten(theme.palette.secondary.light, 0.5)
          : undefined,
      color:
        theme.palette.type === 'light' ? theme.palette.primary.main : undefined,
      marginLeft: theme.spacing(5),
      border: 0
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: theme.spacing(3)
    }
  })
);

function CSVPanel() {
  const [onlyMissing, setOnlyMissing] = React.useState(true);
  const [withHeaders, setWithHeaders] = React.useState(true);
  const [excludeEmpty, setExcludeEmpty] = React.useState(true);
  const { translations } = useResourceTranslations();

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      parse<string[]>(file, {
        complete: (results) => {
          const parseResultDescriptor = results.data.slice(
            withHeaders ? 1 : 0,
            results.data.length &&
              results.data[results.data.length - 1].length === 1
              ? -1
              : 0
          );
          if (
            parseResultDescriptor.some(
              (r) => r.length !== 2 || r[0].trim() === ''
            )
          ) {
            // eslint-disable-next-line no-alert
            alert('Invalid CSV format');
          } else {
            if (
              parseResultDescriptor.some(
                (r) => translations.find((t) => t.key === r[0]) === undefined
              )
            ) {
              // eslint-disable-next-line no-alert
              alert('Unknown translation keys');
            }
            const updates = parseResultDescriptor
              .filter((r) => !excludeEmpty || r[1].trim() !== '')
              .map((r) => ({
                id: translations.find((t) => t.key === r[0])?.id as string,
                translation: r[1]
              }));
            dispatch(importTranslationsThunk(updates));
          }
        }
      });
    }
  };

  const handleClick = () => hiddenFileInput.current?.click();

  return (
    <Card variant="outlined">
      <CardHeader style={{ padding: '3px' }} title="CSV Import/Export" />
      <CardContent style={{ padding: '3px' }}>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={withHeaders}
                  onChange={(_, c) => setWithHeaders(c)}
                  title="With CSV Headers"
                />
              }
              label={<Typography variant="body2">CSV Headers</Typography>}
            />
          </Grid>
          <Grid item xs={12}>
            <IconButton
              onClick={() => {
                const defaultOptions = {
                  fieldSeparator: ',',
                  quoteStrings: '"',
                  decimalSeparator: '.',
                  showLabels: withHeaders,
                  useTextFile: false,
                  useBom: true,
                  useKeysAsHeaders: withHeaders
                };
                const rows = translations
                  .filter((t) => !onlyMissing || t.translation === null)
                  .map((t) => ({
                    key: t.key,
                    translation: t.translation ?? ''
                  }));
                new ExportToCsv({
                  ...defaultOptions,
                  filename: 'teo_translations.csv'
                }).generateCsv(rows);
              }}
            >
              <CloudDownload />
            </IconButton>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={onlyMissing}
                  onChange={(_, c) => setOnlyMissing(c)}
                  title="Only missing translations"
                />
              }
              label={
                <Typography variant="body2">
                  Only missing translations
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12}>
            <IconButton
              onClick={() => {
                handleClick();
              }}
            >
              <CloudUpload />
            </IconButton>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={excludeEmpty}
                  onChange={(_, c) => setExcludeEmpty(c)}
                  title="Only non-empty translations"
                />
              }
              label={
                <Typography variant="body2">
                  Exclude empty translations
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
function TranslationEntry(props: { translation: ResourceTranslation }) {
  const [edit, setEdit] = React.useState<string | null>(null);
  const dispatch = useAppDispatch();
  return (
    <>
      {edit === null ? (
        <>
          {props.translation.translation ?? '-'}
          <IconButton
            size="small"
            onClick={() => setEdit(props.translation.translation ?? '')}
          >
            <Edit fontSize="small" />
          </IconButton>
          {props.translation.translation !== null && (
            <IconButton
              size="small"
              onClick={() => {
                dispatch(
                  updateTranslationsThunk({
                    id: props.translation.id,
                    value: null
                  })
                )
                  .then((res) => {
                    setEdit(null);
                    return res;
                  })
                  .catch(NoOp);
              }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          )}
        </>
      ) : (
        <>
          <TextField value={edit} onChange={(e) => setEdit(e.target.value)} />
          <IconButton
            size="small"
            onClick={() => {
              dispatch(
                updateTranslationsThunk({
                  id: props.translation.id,
                  value: edit
                })
              )
                .then((res) => {
                  setEdit(null);
                  return res;
                })
                .catch(NoOp);
            }}
          >
            <SaveIcon fontSize="small" />
          </IconButton>
        </>
      )}
    </>
  );
}

export function ResourceTranslationScreen() {
  const classes = useStyles();
  const { translations } = useResourceTranslations();
  const [size, setSize] = React.useState({ width: 10, height: 10 });
  const [search, setSearch] = React.useState('');

  const filteredTranslations = React.useMemo(() => {
    const searchString = search.trim().toLowerCase();
    return searchString === ''
      ? translations
      : translations.filter(
          (t) =>
            t.key.toLowerCase().includes(searchString) ||
            (t.translation !== null &&
              t.translation.toLowerCase().includes(searchString))
        );
  }, [search.trim(), translations]);
  return (
    <Paper style={{ height: '100%', padding: '10px' }}>
      <Toolbar className={classes.toolBar}>
        <div className={classes.leftSide}>
          <CSVPanel />
        </div>
        <div className={classes.rightSide}>
          <TextField
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              placeholder: 'Search...',
              endAdornment:
                search.trim() !== '' ? (
                  <IconButton size="small" onClick={() => setSearch('')}>
                    <CancelIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <></>
                )
            }}
          />
        </div>
      </Toolbar>

      <div
        style={{ height: '100%' }}
        ref={(ref) => {
          if (ref) {
            const newSize = {
              width: ref.getBoundingClientRect().width,
              height: window.innerHeight - ref.getBoundingClientRect().top
            };
            if (newSize.width !== size.width || newSize.height !== size.height)
              setSize(newSize);
          }
        }}
      >
        <FixedSizeGrid
          height={size.height}
          width={size.width}
          columnWidth={size.width / 2}
          rowCount={filteredTranslations.length}
          columnCount={2}
          rowHeight={25}
        >
          {({ columnIndex, rowIndex, style }) => (
            <div
              style={{
                ...style,
                backgroundColor: rowIndex % 2 === 0 ? 'white' : 'lightgrey'
              }}
            >
              {columnIndex === 0 ? (
                filteredTranslations[rowIndex].key
              ) : (
                <TranslationEntry
                  translation={filteredTranslations[rowIndex]}
                />
              )}
            </div>
          )}
        </FixedSizeGrid>
      </div>
    </Paper>
  );
}
