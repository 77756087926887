import React from 'react';
import { ProjectRequest, RequestPatchDTO } from 'model';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import * as yup from 'yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../redux/redux.hooks';
import { NoOp } from '../../../../../utils';
import { DialogForm } from '../../../../../components/input/form/DialogForm';
import projectRequestSlice, {
  projectRequestSelectors
} from '../../../redux/project-requests.slice';
import { useProjectOffer } from '../../../hooks/project-offer.hook';
import { useRequestStatusList } from '../../../hooks/request-status-list.hook';
import { FormikSelect } from '../../../../../components/select/FormikSelect';
import { RequestStatusTypeLook } from './RequestStatusAlert';
import { projectRequestThunk } from '../../../redux/project-requests.thunk';

export interface RequestStatusDialogProps {
  request: ProjectRequest;
}
const validationSchema = yup.object({
  requestStatusId: yup.string().uuid().required()
});
export function RequestStatusDialog({ request }: RequestStatusDialogProps) {
  const { projectOffer: currentOffer } = useProjectOffer(
    request.id,
    request.currentOffer?.id
  );
  const { entities } = useRequestStatusList();
  const optionsList = (
    currentOffer?.orderId
      ? entities.filter((f) => f.type === 'accepted')
      : entities.filter((f) => f.type !== 'accepted')
  ).map((o) => {
    const StatusIcon = RequestStatusTypeLook[o.type].icon;
    return {
      value: o.id,
      label: (
        <>
          <StatusIcon fontSize="small" htmlColor={o.color} /> {o.name}
        </>
      )
    };
  });
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory();
  /*
  const { showAlert } = useAlert();
*/
  const { t } = useTranslation();

  // const supplierActivationErrors = useSupplierActivation(supplier);

  const editStatus = (dto: RequestPatchDTO) =>
    dispatch(projectRequestThunk.update({ id: request.id, input: dto }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <DialogForm
      label="Edit Status"
      identifier="request-state"
      form={{
        initialValues: {
          requestStatusId: request.requestStatusId
        },
        validationSchema
      }}
      api={{
        stateSelector: projectRequestSelectors.selectState,
        errorSelector: projectRequestSelectors.selectError,
        onSubmit: editStatus,
        clearError: projectRequestSlice.actions.clearError
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      {() => {
        return (
          <Grid container spacing={1} xs={12}>
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <FormikSelect name="requestStatusId" options={optionsList} />
              </Grid>
            </Grid>
            <Grid item xs={6} />
          </Grid>
        );
      }}
    </DialogForm>
  );
}
