import React from 'react';
import { ButtonProps, CircularProgress } from '@material-ui/core';
import { StyledButton } from './StyledButton';

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export function LoadingButton({
  loading = false,
  ...buttonProps
}: LoadingButtonProps) {
  return (
    <StyledButton
      variant="contained"
      color="primary"
      endIcon={
        loading && (
          <CircularProgress
            color="secondary"
            size="1rem"
            style={{ textAlign: 'right' }}
          />
        )
      }
      {...buttonProps}
    />
  );
}
