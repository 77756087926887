import React from 'react';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  SvgIcon
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { ActionMenuEntry } from './ActionsMenu';
import { useAlert } from '../alert/alert.hook';

export interface ActionsMenuItemProps {
  handleClose: () => void;
  entry: ActionMenuEntry;
}

export function ActionsMenuItem({
  handleClose,
  entry: { icon, onClick, link, label, alert, disabled = false }
}: ActionsMenuItemProps) {
  const { showAlert } = useAlert();

  const handleClickItem = () => {
    if (alert) {
      showAlert(alert);
    }

    const result = onClick ? onClick() : undefined;
    if (result !== false) {
      handleClose();
    }
  };

  const additionalProps =
    link !== undefined
      ? {
          component: RouterLink,
          to: link
        }
      : {};
  const Icon =
    typeof icon === 'object' &&
    ((icon as any).$$typeof as symbol | undefined)?.description ===
      'react.element'
      ? undefined
      : (icon as typeof SvgIcon);

  return (
    <MenuItem
      disabled={disabled}
      onClick={handleClickItem}
      {...additionalProps}
    >
      {icon !== undefined && (
        <ListItemIcon>{Icon ? <Icon fontSize="small" /> : icon}</ListItemIcon>
      )}
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
}
