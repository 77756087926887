import React from 'react';
import { IconButton } from '@material-ui/core';
import { ProjectTag } from 'model';
import PlusIcon from '@material-ui/icons/Add';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { FormikQuickaddEntitySelect } from '../../../../components/select/FormikQuickaddEntitySelect';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { projectTagThunk } from '../../redux/project-tag.thunk';
import { projectTagAPI } from '../../redux/project-tag.slice';
import { NoOp } from '../../../../utils';
import { ProjectBasicView } from '../basic/ProjectBasicForm';

export function ProjectTagQuickAdd({
  basePath = ProjectBasicView.path
}: {
  basePath?: (typeof ProjectBasicView)['path'];
}) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const addProjectTag = (name: string) => {
    return dispatch(
      projectTagThunk.create({
        input: {
          name,
          description: ''
        }
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <FormikQuickaddEntitySelect
      resetOnSelect
      entityApi={projectTagAPI}
      optionLabel={(tag) => tag.name}
      name={basePath.projectTagIds}
      textFieldProps={{ label: t('Select or create Pojects Tags') }}
      quickAdd={(v, onSubmit) =>
        v !== undefined && v.length > 1 ? (
          <IconButton
            size="small"
            onClick={() => {
              addProjectTag(v)
                .then((d) => {
                  onSubmit(d as ProjectTag);
                  return d;
                })
                .catch(NoOp);
            }}
          >
            <PlusIcon fontSize="inherit" />
          </IconButton>
        ) : (
          <></>
        )
      }
      renderer={(v) => v.name}
    />
  );
}
