import React, { ReactNode } from 'react';
import { IconButton, IconButtonProps, Menu, SvgIcon } from '@material-ui/core';
import KebabIcon from '@material-ui/icons/MoreVert';
import {
  executeMatcher,
  PermissionMatcher
} from '../../features/auth/permissions.matchers';
import { ActionsMenuItem } from './ActionsMenuItem';
import { useGrantedPermissions } from '../../features/auth/hooks/granted-permissions.hook';
import { AlertContextState } from '../alert/alert.context';

export interface ActionMenuEntry {
  icon?: typeof SvgIcon | JSX.Element;
  label: string;
  onClick?: () => boolean | void;
  link?: string;
  alert?: AlertContextState;
  disabled?: boolean;
}

export interface PermittedActionMenuEntry {
  entry: ActionMenuEntry;
  permissions?: PermissionMatcher;
}

export interface PermittedActionMenuEntryFactory<T> {
  entry: ActionMenuEntry | ((value: T) => ActionMenuEntry | null) | null;
  permissions?: PermissionMatcher;
}

export interface ActionsMenuProps {
  items: (ActionMenuEntry | null)[];
  children?: ReactNode;
  iconButtonProps?: IconButtonProps;
}

export function useActionMenuItems(items: PermittedActionMenuEntry[]) {
  const userPermissions = useGrantedPermissions();
  return React.useMemo(() => {
    return items
      .filter(
        (item) =>
          item.permissions === undefined ||
          executeMatcher(item.permissions, userPermissions)
      )
      .map((i) => i.entry);
  }, [userPermissions, items]);
}

export function useActionMenuItemFactories<T>(
  itemFactories: PermittedActionMenuEntryFactory<T>[]
): ((val: T) => ActionMenuEntry | null)[] {
  const userPermissions = useGrantedPermissions();
  return React.useMemo(() => {
    return itemFactories
      .filter(
        (item) =>
          item.permissions === undefined ||
          executeMatcher(item.permissions, userPermissions)
      )
      .map((i) =>
        typeof i.entry === 'function'
          ? i.entry
          : ((() => i.entry) as (val: T) => ActionMenuEntry | null)
      );
  }, [userPermissions, itemFactories]);
}

export function ActionsMenu({
  items,
  children,
  iconButtonProps
}: ActionsMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuState = (event?: any) => {
    setAnchorEl(event?.currentTarget ?? null);
  };

  if (items.length < 1) {
    return <></>;
  }

  return (
    <>
      <IconButton
        aria-haspopup="true"
        onClick={handleMenuState}
        {...iconButtonProps}
      >
        {children || <KebabIcon />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuState()}
      >
        {items
          .filter((a) => a !== null)
          .map((item) => (
            <ActionsMenuItem
              key={item?.label}
              handleClose={() => handleMenuState()}
              entry={item as ActionMenuEntry}
            />
          ))}
      </Menu>
    </>
  );
}
