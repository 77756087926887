import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { FormikDatePicker } from '../../../../components/input/date/FormikDatePicker';
import { StepTaskView } from './StepTaskCard';

export function StepTaskForm() {
  const { t } = useTranslation();
  return (
    <Grid container xs={12}>
      <Grid xs={12} item>
        <FormikTextField
          name={StepTaskView.path.description}
          label={t('Description')}
        />
      </Grid>
      <Grid xs={12} item>
        <FormikDatePicker
          label={t('Deadline')}
          name={StepTaskView.path.deadline}
        />
      </Grid>
    </Grid>
  );
}
