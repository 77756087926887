import React, { useEffect } from 'react';
import { Client, ClientContactDTO, PhoneNumberDTO } from 'model';
import PlusIcon from '@material-ui/icons/Add';
import { Button, Grid } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { EditableCard } from '../../../components/input/card/EditableCard';
import { EditableTextDisplay } from '../../../components/input/card/EditableTextDisplay';
import { FormikPhoneNumbers } from '../../../components/input/FormikPhoneNumbers';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { createClientContactThunk } from '../redux/actions/create-client-contact.thunk';
import { NoOp } from '../../../utils';
import { ClientContactDisplay } from './ClientContactDisplay';
import { updateClientContactThunk } from '../redux/actions/patch-client-contact.thunk';
import { clientsThunk } from '../redux/clients.thunk';
import { useClient } from '../hooks/client.hook';

const initialClientAddressValue: ClientContactDTO = {
  comment: null,
  email: null,
  description: null,
  mainContact: false,
  name: { first: '', last: '' },
  phoneNumbers: [],
  userId: null
};

const phoneNumberSchema: yup.SchemaOf<PhoneNumberDTO> = yup.object().shape({
  type: yup.string().max(255, 'Max. 255 characters').required(),
  number: yup.string().max(255, 'Max. 255 characters').required(),
  comment: yup
    .string()
    .max(255, 'Max. 255 characters')
    .nullable()
    .default(null)
    .notRequired()
});

const clientContactValidationSchema: yup.SchemaOf<
  Omit<ClientContactDTO, 'userId'>
> = yup.object().shape({
  name: yup
    .object()
    .required()
    .shape({
      first: yup.string().max(255, 'Max. 255 characters').required(),
      last: yup.string().max(255, 'Max. 255 characters').required()
    }),
  description: yup
    .string()
    .max(255, 'Max. 255 characters')
    .nullable()
    .default(null)
    .defined(),
  comment: yup
    .string()
    .max(255, 'Max. 255 characters')
    .nullable()
    .default(null)
    .defined(),
  email: yup
    .string()
    .max(255, 'Max. 255 characters')
    .email()
    .nullable()
    .default(null)
    .defined(),
  mainContact: yup.boolean().required(),
  phoneNumbers: yup
    .array()
    .required()
    .min(0, 'At least one phone number')
    .of(phoneNumberSchema)
});
// Todo remove hack
export function useExplicitClient(clientId: string | undefined) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (clientId) {
      setTimeout(() => {
        dispatch(clientsThunk.get({ id: clientId }));
      }, 500);
    }
  }, [clientId]);
}
export function ClientContacts({ client: clientRaw }: { client: Client }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const { type, reference } = useEditorLocation();
  useExplicitClient(clientRaw.id);
  const { client } = useClient(clientRaw.id);
  const dispatch = useAppDispatch();
  const createClientContact = (dto: ClientContactDTO) =>
    dispatch(createClientContactThunk({ clientId: clientRaw.id, dto }))
      .then(unwrapResult)
      .catch(NoOp);

  const editClientContact = (dto: ClientContactDTO) =>
    dispatch(
      updateClientContactThunk({
        clientId: clientRaw.id,
        contactId: reference || '',
        dto
      })
    )
      .then(unwrapResult)
      .catch(NoOp);

  const formFields = (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <EditableTextDisplay name="name.first" label="Surname" />
      </Grid>
      <Grid item xs={6}>
        <EditableTextDisplay name="name.last" label="Name" />
      </Grid>
      <Grid item xs={12}>
        <EditableTextDisplay multiline name="description" label="Description" />
      </Grid>
      <Grid item xs={12}>
        <EditableTextDisplay name="email" label="Email" />
      </Grid>
      <Grid item xs={12}>
        <FormikPhoneNumbers name="phoneNumbers" />
      </Grid>
      <Grid item xs={12}>
        <EditableTextDisplay multiline name="comment" label="Comment" />
      </Grid>
    </Grid>
  );

  return (
    <>
      {type === 'view' && (
        <Button
          size="small"
          disabled={type !== 'view'}
          variant="outlined"
          color="primary"
          style={{ marginBottom: 20 }}
          onClick={() => history.push(`${url}?type=create#contact`)}
          startIcon={<PlusIcon />}
        >
          {t('New Contact')}
        </Button>
      )}
      <Grid container spacing={1}>
        {type === 'create' && (
          <Grid key="create-contact" item xs={3}>
            <EditableCard
              identifier="contact"
              title="Contact"
              variant="outlined"
              onSubmit={createClientContact}
              value={initialClientAddressValue}
              validationSchema={clientContactValidationSchema}
            >
              {formFields}
            </EditableCard>
          </Grid>
        )}
        {client?.contacts.map((contact) => (
          <Grid key={contact.id} item xs={6}>
            {type === 'edit' && contact.id === reference ? (
              <EditableCard
                identifier={contact.id}
                title="Edit Contact"
                variant="outlined"
                onSubmit={editClientContact}
                value={{ ...contact, userId: contact.user?.id ?? null }}
                validationSchema={clientContactValidationSchema}
              >
                {formFields}
              </EditableCard>
            ) : (
              <ClientContactDisplay clientId={clientRaw.id} contact={contact} />
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
}
