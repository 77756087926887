"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceConfigurationDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const IsNullable_1 = require("../../transformation/IsNullable");
const ExpressPriceDTO_1 = require("./ExpressPriceDTO");
class PriceConfigurationDTO {
}
__decorate([
    (0, class_validator_1.IsUUID)(),
    __metadata("design:type", String)
], PriceConfigurationDTO.prototype, "priceUnitId", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsUUID)(),
    __metadata("design:type", String)
], PriceConfigurationDTO.prototype, "serviceClassId", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsUUID)(),
    __metadata("design:type", String)
], PriceConfigurationDTO.prototype, "sourceLanguageId", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsUUID)(),
    __metadata("design:type", String)
], PriceConfigurationDTO.prototype, "targetLanguageId", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], PriceConfigurationDTO.prototype, "customConstraint", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], PriceConfigurationDTO.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_transformer_1.Type)(() => ExpressPriceDTO_1.ExpressPriceDTO),
    __metadata("design:type", ExpressPriceDTO_1.ExpressPriceDTO)
], PriceConfigurationDTO.prototype, "price", void 0);
exports.PriceConfigurationDTO = PriceConfigurationDTO;
