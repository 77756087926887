import React from 'react';
import { OrderStepTask, orderStepTaskTransformer, StepTaskDTO } from 'model';
import * as yup from 'yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { Form, Formik } from 'formik';
import { IconButton } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useServiceStep } from '../../../services/hooks/service-step.hook';
import { buildDTOView } from '../../../../transformer/DTOViewSchema';
import { NoOp } from '../../../../utils';
import { projectOrderTasksThunk } from '../../redux/project-order-tasks.thunk';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { useTaskState } from './order-task-state.hook';
import { StepTaskDisplay } from './StepTaskDisplay';
import { StepTaskForm } from './StepTaskForm';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';

export const StepTaskView = buildDTOView<StepTaskDTO>()
  .all() // .exclude('dependencie s')
  .withSchema(
    yup
      .object({
        serviceStepId: yup.string().uuid().required(),
        description: yup.string().required(),
        deadline: yup.date().defined().nullable().default(null),
        state: yup
          .mixed()
          .oneOf(['pending', 'in-progress', 'delivered', 'completed', null]),
        attachedFiles: yup
          .array()
          .of(
            yup
              .object({
                fileAccessId: yup.string().uuid().required() as any,
                token: yup.string().required() as any
              })
              .required()
          )
          .notRequired()
          .default(undefined) as any,
        taskType: yup.mixed().oneOf(['step-task']).required(),
        phase: yup
          .mixed()
          .oneOf(['preparation', 'provision', 'post-delivery'])
          .required(),
        supplierAssigneeId: yup
          .string()
          .uuid()
          .defined()
          .nullable()
          .default(null),
        projectManagerAssigneeId: yup
          .string()
          .uuid()
          .defined()
          .nullable()
          .default(null),
        clientContactAssigneeId: yup
          .string()
          .uuid()
          .defined()
          .nullable()
          .default(null),
        dependencies: yup.array().of(yup.string().uuid().required()).required()
      })
      .defined()
  );

export function StepTaskCard({
  stepTask,
  projectId
}: {
  stepTask: OrderStepTask;
  projectId: string;
}) {
  const { serviceStep } = useServiceStep(stepTask.serviceStepId);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { aggState } = useTaskState(stepTask);
  const { type, reference } = useEditorLocation();

  const updateStepTask = (dto: StepTaskDTO) => {
    return dispatch(
      projectOrderTasksThunk.update({
        projectId,
        id: stepTask.id,
        input: { task: dto }
      })
    )
      .then((v) => unwrapResult(v) as OrderStepTask)
      .catch(NoOp);
  };

  return (
    <div>
      {type === 'edit' && reference === stepTask.id ? (
        <>
          {serviceStep && (
            <Formik
              initialValues={orderStepTaskTransformer((s) => btoa(s))(stepTask)}
              validationSchema={StepTaskView.validationSchema}
              onSubmit={updateStepTask}
            >
              {({ submitForm }) => (
                <Form>
                  <StepTaskForm />
                  <IconButton
                    onClick={() =>
                      submitForm().then((res) => {
                        if (res as any) {
                          history.replace({ search: undefined });
                        }
                        return res;
                      })
                    }
                  >
                    <Check />
                  </IconButton>
                  <IconButton
                    onClick={() => history.replace({ search: undefined })}
                  >
                    <Close />
                  </IconButton>
                </Form>
              )}
            </Formik>
          )}
        </>
      ) : (
        <>
          <StepTaskDisplay
            value={stepTask}
            projectId={projectId}
            serviceStep={serviceStep}
            state={aggState}
          />
        </>
      )}
    </div>
  );
}
