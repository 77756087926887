import React from 'react';
import {
  supplierAttachmentPermission,
  SupplierDocumentAttachment
} from 'model';
import { ButtonBase, Chip, Divider, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { GetApp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { StyledLink } from '../../../../components/input/link/StyledLink';
import { useHistoryOfSupplier } from '../../hooks/supplier-history.hook';
import { CommentDisplay } from '../../../../components/comment/CommentDisplay';
import { CommentInterface } from '../../../../components/comment/types';
import { createFileGrant } from '../../../../utils';

interface SupplierAttachmentDisplayProps {
  value: SupplierDocumentAttachment;
}

export function SupplierAttachmentDisplay(
  props: SupplierAttachmentDisplayProps
) {
  const { t } = useTranslation();

  const documentHistory = useHistoryOfSupplier(
    props.value.supplierId
  ).history.filter(
    (h) => h.referenceId === props.value.id && h.comment !== null
  );

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <StyledLink variant="h6" to="/suppliers/document-types">
            {props.value.documentType.name}
          </StyledLink>
          {props.value.documentType.activationConstraint && (
            <Typography variant="body2" color="textSecondary">
              ({t('Activation Constraint')})
            </Typography>
          )}
        </div>
        <Chip
          style={{
            backgroundColor: props.value.documentState.color
          }}
          label={props.value.documentState.name}
        />
      </div>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      {props.value.attachedFiles &&
        props.value.attachedFiles.map((af) => (
          <ButtonBase style={{ padding: 5, borderRadius: 10 }}>
            <Chip
              size="small"
              component={RouterLink}
              to={`/files/${af.id}/${
                createFileGrant(
                  af.id,
                  supplierAttachmentPermission,
                  props.value
                ).token
              }`}
              target="blank"
              label={
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  {af.name}
                  <GetApp
                    style={{ marginLeft: 4, fontSize: 14 }}
                    fontSize="small"
                    color="action"
                  />
                </div>
              }
            />
          </ButtonBase>
        ))}
      {documentHistory.map((h) => (
        <CommentDisplay
          comment={h as CommentInterface}
          createFileGrant={() => ''}
        />
      ))}
    </div>
  );
}
