import React from 'react';
import { useServiceClasses } from '../hooks/service-classes.hook';
import { ServiceClassTable } from '../components/ServiceClassTable';

export function ServiceClassScreen() {
  const { entities, apiState, listEntities } = useServiceClasses();
  return (
    <ServiceClassTable
      serviceClasses={entities}
      onRefresh={listEntities}
      apiState={apiState}
    />
  );
}
