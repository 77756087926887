import { Client, ClientComment } from 'model';
import { Chip, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { useUser } from '../../../users/hooks/user.hook';
import PopoverPreview from '../../../../components/preview/PopoverPreview';

export function CommentSummaryRenderer({
  comment
}: {
  comment: ClientComment;
}) {
  const { user: author } = useUser(comment.authorId);

  return (
    <>
      <p>
        <Typography variant="overline">
          {author !== undefined ? (
            author.displayName ?? author.email
          ) : (
            <CircularProgress size="2em" />
          )}
          :
        </Typography>
      </p>
      <p>{comment.comment}</p>
    </>
  );
}

export function CommentColumnRenderer({ client: data }: { client: Client }) {
  return (
    <PopoverPreview
      ready
      content={
        <>
          {data.comments.map((c) => (
            <div key={`preview-comment-${c.id}`}>
              <CommentSummaryRenderer comment={c} />
            </div>
          ))}
        </>
      }
    >
      <Chip label={`${data.comments.length} Comment(s)`} />
    </PopoverPreview>
  );
}
