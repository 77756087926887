import { UserRoleTree } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const listRolesThunk = createErrorHandlingThunk(
  'role/list',
  async () => {
    const result = await apiClient.get<UserRoleTree[]>('/roles');
    return result.data;
  }
);
