import { PriceBound } from 'model';
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { usePriceUnits } from '../hooks/price-units.hook';

import { usePriceBoundOfTarget } from '../hooks/price-bound-target.hook';
import { usePriceableServiceSteps } from '../../services/hooks/priceable-service-steps.hook';
import { useServiceClasses } from '../../services/hooks/service-classes.hook';
import { PriceBoundTable } from './PriceBoundTable';
import { SupplierPriceFormDialog } from './PriceBoundDialog';

/* function PriceBoundUnitEntry({
  priceUnit,
  bounds,
  usableServiceClasses
}: {
  priceUnit: PriceUnit | null;
  bounds: PriceBound[];
  usableServiceClasses: ServiceClass[];
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { type, reference } = useEditorLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();
  const [
    unitId = undefined,
    serviceClassId = undefined,
    stepIndex = undefined
  ] = reference !== undefined ? reference.split('_') : [];
  const { priceableSteps } = usePriceableServiceSteps();
  const boundSteps: (ServiceStep | null)[] =
    priceUnit?.type === 'amount' ? priceableSteps : [null];
  const boundMap = Object.fromEntries(
    Object.entries(
      groupBy(bounds, (b) => b.serviceClassId ?? '')
    ).map(([cid, classBounds]) => [
      cid,
      groupBy(classBounds, (sb) => sb.serviceStepId ?? '')
    ])
  );

  const entryReferenced = unitId === (priceUnit?.id ?? '');
  const serviceClassReferenced =
    entryReferenced &&
    serviceClassId !== undefined &&
    (serviceClassId === '' ||
      usableServiceClasses.find((usc) => usc.id === serviceClassId));
  const stepSelected =
    serviceClassReferenced &&
    stepIndex !== undefined &&
    Object.keys(boundSteps)
      .map((k) => `${k}`)
      .includes(stepIndex);

  const displayedServices = [
    null,
    ...sortBy(usableServiceClasses, (sc) => {
      if (
        sc.defaultPriceUnit !== null &&
        sc.defaultPriceUnit.id === priceUnit?.id
      ) {
        return 0;
      }
      if (sc.id !== serviceClassId) {
        return 1;
      }
      return 2;
    }).filter(
      (sc) =>
        (sc.defaultPriceUnit !== null &&
          sc.defaultPriceUnit.id === priceUnit?.id) ||
        (serviceClassReferenced && serviceClassId === sc.id) ||
        bounds.find((pb) => pb.serviceClassId === sc.id)
    )
  ];

  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <PriceBoundUnitAvatar priceUnitId={priceUnit?.id ?? null} />
      </ListItemIcon>
      <ListItemText
        secondary={
          <Grid xs={12} container>
            {displayedServices.map((ds) => {
              return (
                <Grid item container xs={12}>
                  <Grid item xs={4}>
                    {ds && <span>{ds.name}</span>}
                  </Grid>
                  {boundSteps.map((step, sI) => {
                    const value =
                      boundMap[ds?.id ?? '']?.[step?.id ?? '']?.[0] ?? null;
                    const inheritValue =
                      boundMap['']?.[step?.id ?? '']?.[0] ?? null;
                    const inEditMode =
                      type === 'edit' &&
                      stepSelected &&
                      serviceClassId === (ds?.id ?? '') &&
                      stepIndex === `${sI}`;
                    return (
                      <Grid item xs={4}>
                        {inEditMode ? (
                          <Chip
                            label={
                              <>
                                {step && (
                                  <Typography variant="subtitle2">
                                    {step.name}
                                  </Typography>
                                )}
                                <TextField
                                  type="number"
                                  focused
                                  value={`${value?.priceLimit ?? 0.0}`}
                                  InputProps={{
                                    endAdornment: (
                                      <>
                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            dispatch(
                                              definePriceBoundThunk({
                                                input: {
                                                  priceLimit: 10.23,
                                                  priceUnitId:
                                                    priceUnit?.id ?? null,
                                                  rangeTarget: 'supplier',
                                                  serviceClassId:
                                                    ds?.id ?? null,
                                                  serviceStepId:
                                                    step?.id ?? null
                                                }
                                              })
                                            )
                                              .then((res) => {
                                                history.goBack();
                                                return res;
                                              })
                                              .catch(NoOp);
                                          }}
                                        >
                                          <Check fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => history.goBack()}
                                          size="small"
                                        >
                                          <CancelIcon fontSize="small" />
                                        </IconButton>
                                      </>
                                    )
                                  }}
                                />{' '}
                              </>
                            }
                          />
                        ) : (
                          <Chip
                            variant="outlined"
                            onClick={() =>
                              history.push(
                                `${url}?type=edit#${priceUnit?.id ?? ''}_${
                                  ds?.id ?? ''
                                }_${sI}`
                              )
                            }
                            style={{
                              borderColor: value
                                ? '#0000FF'
                                : 'rgba(230,230,230,0.4) '
                            }}
                            label={
                              <Box
                                display="flex"
                                style={{ opacity: value ? 1 : 0.2 }}
                              >
                                <ChipIcon
                                  color={value ? 'primary' : 'inherit'}
                                  fontSize="small"
                                />
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  textAlign="right"
                                  justifyItems="space-around"
                                >
                                  <Typography
                                    variant={
                                      !value && inheritValue
                                        ? 'inherit'
                                        : 'subtitle1'
                                    }
                                  >
                                    {value || inheritValue
                                      ? new Intl.NumberFormat('en-GB', {
                                          style: 'currency',
                                          currency: 'eur'
                                        }).format(
                                          value?.priceLimit ??
                                            inheritValue?.priceLimit
                                        )
                                      : '-'}
                                  </Typography>

                                  {step && (
                                    <Typography variant="inherit" noWrap>
                                      {step.name}
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                            }
                            onDelete={
                              value
                                ? () => {
                                    dispatch(
                                      priceBoundsThunk.delete({ id: value.id })
                                    );
                                  }
                                : undefined
                            }
                          />
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
            <IconButton size="small">
              <ArrowDropDown fontSize="small" />
            </IconButton>
          </Grid>
        }
      />
    </ListItem>
  );
} */

export function PriceBoundTargetDetails({
  limitTarget
}: {
  limitTarget: PriceBound['rangeTarget'];
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const bounds = usePriceBoundOfTarget(limitTarget);
  const priceUnits = usePriceUnits();
  const { entities: serviceClasses } = useServiceClasses();
  const { priceableSteps } = usePriceableServiceSteps();

  if (
    !priceUnits.entities ||
    !priceableSteps.length ||
    !serviceClasses.length
  ) {
    return <CircularProgress />;
  }
  /* const [amountPriceUnits, workTimePriceUnits] = partition(
    Object.values(
      groupBy(
        uniqBy(
          serviceClasses.flatMap((serviceClass) => [
            ...(serviceClass.defaultPriceUnit
              ? [{ priceUnit: serviceClass.defaultPriceUnit, serviceClass }]
              : []),
            ...serviceClass.usablePriceUnits.map((priceUnit) => ({
              priceUnit,
              serviceClass
            }))
          ]),
          (v) => v.priceUnit.id
        ),
        (v) => v.priceUnit.id
      )
    ).map((v) => ({
      priceUnit: v[0].priceUnit,
      serviceClasses: v.map((sv) => sv.serviceClass)
    })),
    (v) => v.priceUnit.type === 'amount'
  ); */

  return (
    <>
      <SupplierPriceFormDialog target={limitTarget} />
      <PriceBoundTable
        apiState="idle"
        bounds={bounds.targetBounds}
        onRefresh={() => {}}
      />
    </>
  );
}
