import React from 'react';
import { IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { PropertyPath, useTypedField } from '../../transformer/DTOViewSchema';
import { FormikTextField } from './FormikTextField';

interface FormikTextArrayProps {
  name: string | PropertyPath<any, string[]>;
  primaryLabel: string;
  secondaryLabel?: string;
  allowEmpty?: boolean;
  required?: boolean;
}

export function EditableTextArray(props: FormikTextArrayProps) {
  const [, meta, helpers] = useTypedField<string[] | undefined>(props.name);

  const textArray =
    props.allowEmpty && meta.value && meta.value.length > 0 ? meta.value : [''];

  if (textArray === undefined) {
    return <></>;
  }

  const addEmptyTextElement = () => helpers.setValue(textArray.concat(''));

  const removeTextElement = (elIndex: number) =>
    helpers.setValue(textArray.filter((_, i) => i !== elIndex));

  return (
    <>
      {textArray.map((text, textIndex) => (
        <FormikTextField
          required={props.required}
          name={
            typeof props.name === 'object'
              ? props.name.at(textIndex)
              : `${props.name}[${textIndex}]`
          }
          label={textIndex === 0 ? props.primaryLabel : props.secondaryLabel}
          InputProps={{
            endAdornment: (
              <>
                <IconButton size="small" onClick={addEmptyTextElement}>
                  <Add fontSize="small" />
                </IconButton>
                {/* {props.allowEmpty && ( */}
                <IconButton
                  size="small"
                  onClick={() => removeTextElement(textIndex)}
                >
                  <Remove fontSize="small" />
                </IconButton>
                {/* )} */}
              </>
            )
          }}
        />
      ))}
      {/* {props.allowEmpty && textArray.length === 0 && ( */}
      {/*  <IconButton size="small" onClick={addEmptyTextElement}> */}
      {/*    <Add fontSize="small" /> */}
      {/*  </IconButton> */}
      {/* )} */}
    </>
  );
}
