import { permissions, RequestStatus } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React from 'react';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Star as SetDefaultIcon
} from '@material-ui/icons';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { ApiState } from '../../auth/redux/types';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { requestStatusThunk } from '../redux/request-status.thunk';

function buildTableColumns() {
  return entityColumnBuilder<RequestStatus>()
    .addDefaultColumn('status_type', 'type', 'Type')
    .addDefaultColumn('status_name', 'name', 'Name')
    .addDefaultColumn('status_description', 'description', 'Description')
    .addDefaultColumn('status_color', 'color', 'Color', {
      createElement: (v) => (
        <>
          <div
            style={{
              width: '2em',
              height: '2em',
              borderRadius: '50%',
              backgroundColor: v as any
            }}
          />
        </>
      )
    })
    .addBooleanColumn('status_defaultStatus', 'defaultStatus', 'Default Status')
    .columns;
}

interface RequestStatusTableProps {
  requestStatusList: RequestStatus[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function RequestStatusTable({
  requestStatusList,
  onRefresh,
  apiState
}: RequestStatusTableProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  const deleteRequestStatus = (id: string) => {
    dispatch(requestStatusThunk.delete({ id }));
  };
  return (
    <GenericTable
      label={t('Request Status')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Request Status',
        onClick: () => history.push(`${url}?type=create#request-status`),
        permission: permissions.project.requestStatus.create
      }}
      data={requestStatusList}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.project.requestStatus.edit,
          entry: (step) => ({
            icon: EditIcon,
            label: 'Edit',
            link: `${url}?type=edit#${step.id}`
          })
        },
        {
          permissions: permissions.project.requestStatus.edit,
          entry: (step) =>
            step.defaultStatus
              ? null
              : {
                  icon: SetDefaultIcon,
                  label: 'Mark Default',
                  onClick: () => {}
                }
        },
        {
          permissions: permissions.project.requestStatus.delete,
          entry: (sc) => ({
            icon: DeleteIcon,
            label: 'Delete',
            alert: {
              title: 'Delete Request Status',
              content: `${t(
                'You may only delete a request status ' +
                  'if it is not referenced anymore. ' +
                  'Otherwise an error will occur. ' +
                  'Are you sure you want to delete request status '
              )} "${sc.name}"?`,
              onConfirm: () => {
                deleteRequestStatus(sc.id);
              }
            }
          })
        }
      ]}
      toolbarActions={[]}
    />
  );
}
