import React from 'react';
import { Client, permissions } from 'model';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Edit } from '@material-ui/icons';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useGrantedPermissions } from '../../../auth/hooks/granted-permissions.hook';
import { executeMatcher } from '../../../auth/permissions.matchers';
import { useAlert } from '../../../../components/alert/alert.hook';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { removeClientNumberThunk } from '../../redux/actions/client-number.thunk';
import { formatDate } from '../../../../localized-formats';

interface ClientNumberAlertProps {
  client: Client;
}

export function ClientNumberAlert({ client }: ClientNumberAlertProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const grantedPermissions = useGrantedPermissions();
  const dispatch = useAppDispatch();

  const { showAlert } = useAlert();

  if (client.clientNumber === null) {
    const createNumberPermitted = executeMatcher(
      permissions.clients.client.clientNumber.create,
      grantedPermissions
    );
    return (
      <Alert
        severity="warning"
        action={
          createNumberPermitted && (
            <Button
              component={RouterLink}
              variant="outlined"
              color="primary"
              to={`${url}?type=create#client-number`}
            >
              {t('Assign number')}
            </Button>
          )
        }
      >
        <AlertTitle>{t('Clientnumber not assigned')}</AlertTitle>
        {t('Click "Assign Number" to add the client number')}
      </Alert>
    );
  }
  const editNumberPermitted = executeMatcher(
    permissions.clients.client.clientNumber.edit,
    grantedPermissions
  );
  const removeNumberPermitted = executeMatcher(
    permissions.clients.client.clientNumber.delete,
    grantedPermissions
  );
  return (
    <Alert
      severity="success"
      action={
        <>
          {editNumberPermitted && (
            <IconButton
              component={RouterLink}
              to={`${url}?type=edit#client-number`}
            >
              <Edit />
            </IconButton>
          )}
          {removeNumberPermitted && (
            <IconButton
              onClick={() => {
                showAlert({
                  title: t('Really delete client number?'),
                  content: (
                    <>
                      <Typography>
                        {`${
                          t('You are about to delete number of client ') +
                          client.name
                        }. `}
                      </Typography>
                      <Typography>
                        {t('This will inactive the client. Are you sure?')}
                      </Typography>
                    </>
                  ),
                  onConfirm: () => {
                    dispatch(removeClientNumberThunk({ id: client.id }));
                  }
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      }
    >
      <AlertTitle>
        <Typography>{client.clientNumber.number}</Typography>
        <Typography variant="caption">
          {t('Active since')}:{' '}
          {formatDate(client.clientNumber.referenceDate, 'date')}
        </Typography>
      </AlertTitle>
    </Alert>
  );
}
