import { RequestStatusDTO } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import React from 'react';
import {
  buildDTOView,
  useTypedField
} from '../../../transformer/DTOViewSchema';
import { FormikSelect } from '../../../components/select/FormikSelect';
import ColorPicker, {
  cssRGBA,
  fromCSSRGBA
} from '../../../components/color/ColorPicker';
import { FormikTextField } from '../../../components/input/FormikTextField';

export const RequestStatusView = buildDTOView<RequestStatusDTO>()
  .all()
  .withSchema(
    yup
      .object({
        name: yup.string().required('required').min(2, 'min length 2'),
        description: yup.string().max(512).defined('required'),
        color: yup.string().required(),
        type: yup
          .mixed()
          .oneOf(['open', 'accepted', 'temporary-closed', 'permanently-closed'])
      })
      .defined()
  );

export function RequestStatusForm() {
  const { t } = useTranslation();
  const [, colorMeta, colorHelpers] = useTypedField(
    RequestStatusView.path.color
  );
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={6}>
          <FormikTextField
            required
            name={RequestStatusView.path.name}
            label={t('Name')}
          />
        </Grid>
        <Grid item xs={5}>
          <FormikSelect
            name={RequestStatusView.path.type}
            options={[
              'open',
              'accepted',
              'temporary-closed',
              'permanently-closed'
            ].map((value) => ({ value, label: t(value) }))}
          />
        </Grid>
        <Grid item xs={1}>
          <ColorPicker
            value={fromCSSRGBA(colorMeta.value)}
            onValueChange={(c) => colorHelpers.setValue(cssRGBA(c))}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            label={t('Description')}
            name={RequestStatusView.path.description}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
