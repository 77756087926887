import { OrderElementState, OrderTask } from 'model';
import { SvgIcon } from '@material-ui/core';
import { DoneAllRounded } from '@material-ui/icons';
import { useTaskMap } from './order-task.context';

export interface BlockingDependency {
  type: 'parent' | 'dependency';
  task: OrderTask;
}

export function useTaskState(stepTask: OrderTask) {
  const taskMap = useTaskMap();

  const parentsMap = (task: OrderTask): OrderTask[] =>
    task.parentTaskId !== null
      ? [
          taskMap[task.parentTaskId].value,
          ...parentsMap(taskMap[task.parentTaskId].value)
        ]
      : [];
  const parentStates = parentsMap(stepTask).map((st) => {
    return [
      st.state,
      st.dependencies
        .map((d) => taskMap[d.id])
        .filter((d) => d.value.state !== 'completed')
        .map((d) => ({ type: 'parent', task: d.value }))
    ] as const;
  });
  const blockedBy = stepTask.dependencies
    .map((d) => taskMap[d.id])
    .filter((d) => d.value.state !== 'completed')
    .map((d) => ({ type: 'dependency', task: d.value }));
  const state =
    stepTask.state === null ||
    (stepTask.state === 'pending' && blockedBy.length > 0)
      ? 'blocked'
      : stepTask.state;
  const aggState = parentStates.some(
    ([ps, pd]) => (ps === null || ps === 'pending') && pd.length > 0
  )
    ? 'blocked'
    : state;
  const blocker =
    state === 'blocked' && blockedBy.length > 0
      ? blockedBy
      : parentStates.find(
          ([ps, pb]) => (ps === 'pending' || ps === null) && pb.length > 0
        )?.[1];
  return {
    state,
    aggState: aggState as 'blocked' | OrderElementState,
    blocker
  };
}

export interface TaskTransitionAction {
  title: string;
  icon: typeof SvgIcon;
  state: OrderElementState;
  consistent: boolean;
}

export function useTaskTransitions(task: OrderTask): TaskTransitionAction[] {
  const { aggState } = useTaskState(task);
  if (task.taskType !== 'step-task' || aggState === 'completed') {
    return [];
  }
  return [
    {
      title: 'Completed',
      icon: DoneAllRounded,
      state: 'completed',
      consistent: aggState !== 'blocked'
    }
  ];
}
