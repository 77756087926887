"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var Project_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Project = exports.ProjectStatusList = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const Client_1 = require("../../client/entity/Client");
// eslint-disable-next-line import/no-cycle
const ProjectRequest_1 = require("./ProjectRequest");
// eslint-disable-next-line import/no-cycle
const ProjectOrder_1 = require("./ProjectOrder");
// eslint-disable-next-line import/no-cycle
const ProjectTag_1 = require("./ProjectTag");
const ProjectVariation_1 = require("../../service/entity/ProjectVariation");
const CircularReference_1 = require("../../serialization/CircularReference");
// eslint-disable-next-line import/no-cycle
const ProjectNumber_1 = require("./ProjectNumber");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("../../supplier/entity/Supplier");
// eslint-disable-next-line import/no-cycle
const OutgoingInvoice_1 = require("./OutgoingInvoice");
// eslint-disable-next-line import/no-cycle
const ProjectCandidate_1 = require("./ProjectCandidate");
// eslint-disable-next-line import/no-cycle
const ProjectExpense_1 = require("./ProjectExpense");
exports.ProjectStatusList = [
    'not-yet-commisioned',
    'ready-to-plan',
    'in-progress',
    'delivered',
    'final',
    'cancelled'
];
let Project = Project_1 = class Project extends JournaledEntity_1.JournaledEntity {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.OneToOne)(() => ProjectNumber_1.ProjectNumber, (pn) => pn.project, { nullable: true }),
    (0, typeorm_1.JoinColumn)(),
    __metadata("design:type", ProjectNumber_1.ProjectNumber)
], Project.prototype, "projectNumber", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => OutgoingInvoice_1.OutgoingInvoice, (pn) => pn.project),
    __metadata("design:type", Array)
], Project.prototype, "outgoingInvoices", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], Project.prototype, "projectName", void 0);
__decorate([
    (0, typeorm_1.Column)('timestamp', { nullable: true }),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], Project.prototype, "deadline", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], Project.prototype, "projectStatus", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => ProjectTag_1.ProjectTag, (t) => t.projects),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], Project.prototype, "projectTags", void 0);
__decorate([
    (0, typeorm_1.RelationId)((p) => p.projectTags),
    __metadata("design:type", Array)
], Project.prototype, "projectTagIds", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectVariation_1.ProjectVariation, undefined, { nullable: true }),
    __metadata("design:type", ProjectVariation_1.ProjectVariation)
], Project.prototype, "projectVariation", void 0);
__decorate([
    (0, typeorm_1.RelationId)((p) => p.projectVariation),
    __metadata("design:type", String)
], Project.prototype, "projectVariationId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Client_1.Client),
    __metadata("design:type", Client_1.Client)
], Project.prototype, "client", void 0);
__decorate([
    (0, typeorm_1.RelationId)((p) => p.client),
    __metadata("design:type", String)
], Project.prototype, "clientId", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => ProjectRequest_1.ProjectRequest, (r) => r.project, { nullable: true }),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", ProjectRequest_1.ProjectRequest)
], Project.prototype, "request", void 0);
__decorate([
    (0, typeorm_1.RelationId)((p) => p.request),
    __metadata("design:type", String)
], Project.prototype, "requestId", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => ProjectCandidate_1.ProjectCandidate, (pc) => pc.project),
    __metadata("design:type", Array)
], Project.prototype, "projectCandidates", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => ProjectExpense_1.ProjectExpense, (pc) => pc.project),
    __metadata("design:type", Array)
], Project.prototype, "projectExpenses", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier),
    __metadata("design:type", Supplier_1.Supplier)
], Project.prototype, "projectManager", void 0);
__decorate([
    (0, typeorm_1.RelationId)((p) => p.projectManager),
    __metadata("design:type", String)
], Project.prototype, "projectManagerId", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => Supplier_1.Supplier),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], Project.prototype, "supportingProjectManagers", void 0);
__decorate([
    (0, typeorm_1.RelationId)((p) => p.supportingProjectManagers),
    __metadata("design:type", Array)
], Project.prototype, "supportingProjectManagerIds", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], Project.prototype, "createdInWordbee", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], Project.prototype, "tmConsolidated", void 0);
__decorate([
    (0, typeorm_1.OneToOne)(() => ProjectOrder_1.ProjectOrder, (o) => o.project, { nullable: true }),
    __metadata("design:type", ProjectOrder_1.ProjectOrder)
], Project.prototype, "order", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Project_1, { nullable: true }),
    __metadata("design:type", Project)
], Project.prototype, "duplicatedFrom", void 0);
__decorate([
    (0, typeorm_1.RelationId)((p) => p.duplicatedFrom),
    __metadata("design:type", String)
], Project.prototype, "duplicatedFromId", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'boolean' }),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], Project.prototype, "templateProject", void 0);
Project = Project_1 = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.Unique)(['projectNumber']),
    __metadata("design:paramtypes", [Object])
], Project);
exports.Project = Project;
