import { InitialPlainPositionDTO, PlainRequestPositionDTO } from 'model';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { buildDTOView } from '../../../../../../transformer/DTOViewSchema';
import { FormikEntitySelect } from '../../../../../../components/select/FormikEntitySelect';
import { priceUnitsAPI } from '../../../../../prices/redux/price-units.slice';
import { FormikTextField } from '../../../../../../components/input/FormikTextField';
import { FormikDatePicker } from '../../../../../../components/input/date/FormikDatePicker';

export const RequestPlainPositionView = buildDTOView<
  InitialPlainPositionDTO | PlainRequestPositionDTO
>()
  .all()
  .withSchema(
    yup
      .object({
        priceUnitId: yup.string().uuid().defined().nullable().default(null),
        estimatedAmount: yup.number().defined().nullable().default(null),
        description: yup.string().required().default(''),
        deadline: yup.date().defined().nullable().default(null),
        comment: yup
          .object({
            comment: yup.string().defined() as any,
            attachedFiles: yup.array().of(
              yup
                .object({
                  fileAccessId: yup.string().uuid().required() as any,
                  token: yup.string().required() as any
                })
                .required()
            ) as any
          })
          .nullable()
          .default(null),
        name: yup.string().required().min(2),
        positionType: yup.mixed().required().oneOf(['plain-position']),
        expressPosition: yup.boolean().defined().nullable().default(null)
      })
      .defined()
  );

export function PlainPositionForm({
  basePath = RequestPlainPositionView.path
}: {
  basePath?: (typeof RequestPlainPositionView)['path'];
}) {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={8}>
          <FormikTextField name={basePath.sub_path('name')} label={t('Name')} />
        </Grid>
        <Grid item xs={4}>
          <FormikEntitySelect
            entityApi={priceUnitsAPI}
            optionLabel={(v) => v.name}
            name={basePath.sub_path('priceUnitId')}
            textFieldProps={{ label: t('Price Unit') }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField
            name={basePath.sub_path('estimatedAmount')}
            type="number"
            label="Estimated Amount per Price Unit"
          />
        </Grid>
        <Grid item xs={4}>
          <FormikDatePicker
            label={t('Deadline')}
            name={basePath.sub_path('deadline')}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <FormikTextField
              name={basePath.sub_path('description')}
              label="Description"
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
