import { ServiceStep, ServiceStepDTO, ServiceStepPatchDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const serviceStepsThunk = createFeatureThunks<
  ServiceStep,
  ServiceStepDTO,
  ServiceStepPatchDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/services/service-step${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'service-steps'
});
