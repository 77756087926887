import { Grid, makeStyles } from '@material-ui/core';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import React from 'react';
import { FormikTextField } from '../../../components/input/FormikTextField';
import { LoadingButton } from '../../../components/input/button/LoadingButton';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  }
}));

const validationSchema = yup.object().shape({
  token: yup.string().required('required')
});

const initialState = { token: '' };

interface ValidatePasswordResetTokenFormProps {
  onSubmit: (token: string) => Promise<void>;
}

export function ValidatePasswordResetTokenForm({
  onSubmit
}: ValidatePasswordResetTokenFormProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialState}
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values.token)}
    >
      {({ submitForm }) => (
        <Form className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormikTextField
                name="token"
                variant="outlined"
                label={t('Token')}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                // loading={authState === 'login-pending'}
                onClick={submitForm}
              >
                {t('Check Token')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
