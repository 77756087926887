"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WrapFileUploadDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const FileUploadDTO_1 = require("./FileUploadDTO");
const ExternalUrlFileDTO_1 = require("./ExternalUrlFileDTO");
const SeafileReferenceDTO_1 = require("./SeafileReferenceDTO");
class WrapFileUploadDTO {
}
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsDefined)(),
    (0, class_transformer_1.Type)(() => FileUploadDTO_1.FileUploadDTO, {
        discriminator: {
            property: 'uploadType',
            subTypes: [
                {
                    name: 'seafile-reference',
                    value: SeafileReferenceDTO_1.SeafileReferenceDTO
                },
                {
                    name: 'external-url',
                    value: ExternalUrlFileDTO_1.ExternalUrlFileDTO
                }
            ]
        },
        keepDiscriminatorProperty: true
    }),
    __metadata("design:type", Object)
], WrapFileUploadDTO.prototype, "fileData", void 0);
exports.WrapFileUploadDTO = WrapFileUploadDTO;
