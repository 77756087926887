import React from 'react';
import { useProjects } from '../hooks/projects.hook';
import { ProjectTable } from '../components/table/ProjectsTable';

export function ProjectScreen() {
  const { entities, apiState, listEntities } = useProjects();
  return (
    <>
      <ProjectTable
        projects={entities}
        onRefresh={listEntities}
        apiState={apiState}
      />
    </>
  );
}
