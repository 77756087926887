import React from 'react';
import { OrderTasks } from './OrderTasks';
import { OrderTaskContext, useMappedTasks } from './order-task.context';
import { useServiceClasses } from '../../../services/hooks/service-classes.hook';
import { useServiceSteps } from '../../../services/hooks/service-steps.hook';

function ProjectTasksContext({
  projectId,
  orderId
}: {
  projectId: string;
  orderId: string;
}) {
  const taskMap = useMappedTasks(projectId, orderId);
  return (
    <OrderTaskContext.Provider value={{ taskMap }}>
      <OrderTasks projectId={projectId} />
    </OrderTaskContext.Provider>
  );
}

export function ProjectTasks({
  projectId,
  orderId
}: {
  projectId: string;
  orderId?: string;
}) {
  useServiceClasses();
  useServiceSteps();
  return (
    <div>
      {orderId && (
        <ProjectTasksContext projectId={projectId} orderId={orderId} />
      )}
    </div>
  );
}
