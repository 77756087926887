import { useParams } from 'react-router-dom';
import React from 'react';
import {
  ServiceClass,
  ServiceClassPatchDTO,
  serviceClassPathTransformer
} from 'model';
import { Grid } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import {
  EntityDetails,
  TabDefinition
} from '../../../components/details/EntityDetails';
import { useServiceClass } from '../hooks/service-class.hook';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { NoOp } from '../../../utils';
import { EditableCard } from '../../../components/input/card/EditableCard';
import { serviceClassesThunk } from '../redux/service-classes.thunk';
import {
  ClassQualificationsView,
  ServiceClassQualificationsForm
} from '../components/ServiceClassQualificationsForm';
import { ServiceClassBasicCard } from '../components/basic/ServiceClassBasicCard';

export function ServiceClassBasicsScreen({
  serviceClass
}: {
  serviceClass: ServiceClass;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const serviceClassDTO = serviceClassPathTransformer(serviceClass);

  const updateServiceClass = (input: ServiceClassPatchDTO) =>
    dispatch(serviceClassesThunk.update({ id: serviceClass.id, input }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <ServiceClassBasicCard serviceClass={serviceClass} />
      </Grid>
      <Grid item xs={6}>
        <EditableCard
          identifier="qualifications"
          title={t('Qualifications')}
          validationSchema={ClassQualificationsView.validationSchema}
          onSubmit={updateServiceClass}
          value={ClassQualificationsView.viewFactory(serviceClassDTO)}
        >
          <ServiceClassQualificationsForm />
        </EditableCard>
      </Grid>
    </Grid>
  );
}

const tabs: TabDefinition<ServiceClass>[] = [
  {
    name: 'basic',
    subPaths: [''],
    component: (s: ServiceClass) => (
      <ServiceClassBasicsScreen serviceClass={s} />
    )
  }
];

export function ServiceClassDetailsScreen() {
  const { serviceClassId } = useParams<{ serviceClassId: string }>();
  const { serviceClass } = useServiceClass(serviceClassId);

  return (
    <EntityDetails
      entityName="ServiceClass"
      title={(s) => `${s.name}`}
      tabs={tabs}
      entity={serviceClass}
    />
  );
}
