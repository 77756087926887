"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateRequestDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const ProjectDTO_1 = require("./ProjectDTO");
const ProjectRequestDTO_1 = require("./ProjectRequestDTO");
const ResourceRequestDTO_1 = require("./ResourceRequestDTO");
const InitialRequestPositionDTO_1 = require("./InitialRequestPositionDTO");
const InitialServicePositionDTO_1 = require("./InitialServicePositionDTO");
const InitialPlainPositionDTO_1 = require("./InitialPlainPositionDTO");
class CreateRequestDTO {
}
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsDefined)(),
    (0, class_transformer_1.Type)(() => ProjectDTO_1.ProjectDTO),
    __metadata("design:type", Object)
], CreateRequestDTO.prototype, "project", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)(),
    (0, class_validator_1.IsDefined)(),
    (0, class_transformer_1.Type)(() => ProjectRequestDTO_1.ProjectRequestDTO),
    __metadata("design:type", Object)
], CreateRequestDTO.prototype, "request", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => ResourceRequestDTO_1.ResourceRequestDTO),
    __metadata("design:type", Array)
], CreateRequestDTO.prototype, "resources", void 0);
__decorate([
    (0, class_validator_1.IsUUID)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], CreateRequestDTO.prototype, "duplicatedFromId", void 0);
__decorate([
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_validator_1.IsArray)(),
    (0, class_transformer_1.Type)(() => InitialRequestPositionDTO_1.InitialRequestPositionDTO, {
        discriminator: {
            property: 'positionType',
            subTypes: [
                { value: InitialServicePositionDTO_1.InitialServicePositionDTO, name: 'service-position' },
                { value: InitialPlainPositionDTO_1.InitialPlainPositionDTO, name: 'plain-position' }
            ]
        },
        keepDiscriminatorProperty: true
    }),
    __metadata("design:type", Array)
], CreateRequestDTO.prototype, "positions", void 0);
exports.CreateRequestDTO = CreateRequestDTO;
