import {
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  SerializedError
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { CreateFrontendLogDTO } from 'model';
import { apiClient } from '../api/apiClient';

export function createErrorHandlingThunk<Returned, ThunkArg = void>(
  prefix: string,
  creator: AsyncThunkPayloadCreator<Returned, ThunkArg>
) {
  return createAsyncThunk<Returned, ThunkArg>(prefix, async (arg, thunkAPI) => {
    try {
      return await creator(arg, thunkAPI);
    } catch (err) {
      const error: AxiosError = err as AxiosError;

      const serializedError: SerializedError = {
        name: error.name,
        message: (error.response?.data as any).message,
        code: String((error.response?.data as any).statusCode)
      };

      const logErrorInput: CreateFrontendLogDTO = {
        message: error.name,
        trace:
          (error.response?.data as any).message ??
          JSON.stringify(error.response?.data ?? 'No response')
      };
      apiClient.post('/log/frontend/error', logErrorInput);

      return Promise.reject(serializedError);
    }
  });
}
