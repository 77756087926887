import React from 'react';
import { ExportToCsv } from 'export-to-csv';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import { BaseTableRow, DataCell } from './TableDataCell';
import {
  useTableConfigContext,
  useTableDataContext
} from './context/DataTableContext';
import { useTableSelectionControl } from './selection/selection-control.hook';

export interface TableCSVExportButtonProps {
  filename: string;
}

const defaultOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true
};

export function TableCSVExportButton<T extends BaseTableRow>({
  filename
}: TableCSVExportButtonProps) {
  const { selectedIds } = useTableSelectionControl();
  const { data } = useTableDataContext<T, Record<string, DataCell<T, any>>>();
  const { tableCells } = useTableConfigContext<
    T,
    Record<string, DataCell<T, any>>
  >();
  const exportToCSV = () => {
    const csvData = selectedIds
      .map((id) => data[id as keyof typeof data])
      .map((row) =>
        Object.fromEntries(
          Object.values(tableCells).map((cell) => [
            cell.label,
            cell.renderer
              .createText(cell.accessor(row as T), row as any, cell)
              .join('\n')
          ])
        )
      );

    new ExportToCsv({ ...defaultOptions, filename }).generateCsv(csvData);
  };
  return <DownloadIcon onClick={exportToCSV} />;
}
