import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { useField } from 'formik';
import { PhoneNumber, PhoneNumberDTO } from 'model';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuid } from 'uuid';
import { EditableTextDisplay } from './card/EditableTextDisplay';

export function FormikPhoneNumber(props: {
  name: string;
  onRemove?: () => void;
  onAdd?: () => void;
}) {
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <EditableTextDisplay name={`${props.name}.type`} label="type" />
        </Grid>
        <Grid item xs={8}>
          <EditableTextDisplay
            name={`${props.name}.number`}
            label="number"
            InputProps={{
              endAdornment: (
                <>
                  {props.onRemove && (
                    <IconButton
                      style={{ padding: 0 }}
                      size="small"
                      onClick={props.onRemove}
                    >
                      <RemoveIcon />
                    </IconButton>
                  )}
                  {props.onAdd && (
                    <IconButton
                      style={{ padding: 0 }}
                      size="small"
                      onClick={props.onAdd}
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                </>
              )
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

const getEmptyPhoneNumber = (): PhoneNumberDTO & { id: string } => ({
  comment: null,
  type: 'Mobile',
  number: '',
  id: uuid()
});

export function FormikPhoneNumbers(props: { name: string }) {
  const [, meta, helpers] = useField<PhoneNumber[]>(props.name);

  const addEmptyPhoneNumber = () =>
    helpers.setValue([...meta.value, getEmptyPhoneNumber()]);

  const removePhoneNumber = (index: number) => {
    helpers.setValue(meta.value.filter((_, i) => i !== index));
  };

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        Phone Numbers
        {!meta.value?.length && (
          <IconButton onClick={addEmptyPhoneNumber}>
            <AddIcon />
          </IconButton>
        )}
      </div>
      {meta.value?.map((_, i) => (
        <FormikPhoneNumber
          onAdd={addEmptyPhoneNumber}
          onRemove={() => removePhoneNumber(i)}
          name={`${props.name}[${i}]`}
        />
      ))}
    </div>
  );
}
