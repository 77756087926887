import {
  GeoAreaType,
  IETFTag,
  Language,
  LanguageDefinition,
  LanguageMap,
  parseIETFTag,
  RegionCodeMap
} from 'model';
import React from 'react';
import _ from 'lodash';
import { useLanguages } from './languages.hook';

export interface LanguageDescriptor {
  language: Language;
  specification: IETFTag;
}

export interface LanguageMatcher {
  language: LanguageDefinition;
  region?: GeoAreaType;
}

export interface LanguageIndexType {
  descriptors: Record<string, LanguageDescriptor>;
  codeMap: Record<string, LanguageDescriptor>;
  matchers: LanguageMatcher[];
}

export const LanguageIndexContext = React.createContext<LanguageIndexType>({
  descriptors: {},
  codeMap: {},
  matchers: []
});

export function useLanguageIndexProvider(): LanguageIndexType {
  const languages = useLanguages();
  const descriptors: LanguageIndexType['descriptors'] = Object.fromEntries(
    languages.languages.map((lang) => {
      const specification = parseIETFTag(lang.languageCode);
      return [
        lang.id,
        {
          language: lang,
          specification
        }
      ];
    })
  );

  const matchers = Object.entries(
    _.groupBy(
      Object.values(descriptors).filter((d) => !!d.specification.language),
      (d) => d.specification.language.code as string
    )
  ).flatMap(([l, v]) => [
    { language: LanguageMap[l] },
    ...v
      .filter((d) => d.specification.region)
      .map((d) => ({
        language: LanguageMap[l],
        region: RegionCodeMap[d.specification.region?.code as string]
      }))
  ]);

  const codeMap: LanguageIndexType['codeMap'] = Object.fromEntries(
    Object.values(descriptors).flatMap((d) =>
      [d.language.languageCode, ...d.language.alternativeCodes].map((co) => [
        co.toLowerCase(),
        d
      ])
    )
  );
  return {
    descriptors,
    codeMap,
    matchers
  };
}
