import React from 'react';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { loginSilentWithDataThunk } from '../redux/actions/login-silent.thunk';

export function useLoginSilent() {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(loginSilentWithDataThunk());
  }, []);
}
