import { useEntityIdSelector } from '../../../redux/entity-id-selector.hook';
import { supplierCategoriesAPI } from '../redux/supplier-categories.slice';

export function useSupplierCategory(id: string | null | undefined) {
  const { entity: supplierCategory } = useEntityIdSelector(
    supplierCategoriesAPI,
    id
  );
  return { supplierCategory };
}
