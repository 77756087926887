"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clientDataParser = exports.ClientImportData = void 0;
const class_validator_1 = require("class-validator");
const IsNullable_1 = require("../../transformation/IsNullable");
const column_descriptor_1 = require("../../csv/column-descriptor");
const csv_columns_descriptor_1 = require("../../csv/csv-columns.descriptor");
class ClientImportData {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.Matches)(/^[0-9]{5}$/),
    __metadata("design:type", String)
], ClientImportData.prototype, "clientNumber", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.Length)(2),
    __metadata("design:type", String)
], ClientImportData.prototype, "name", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], ClientImportData.prototype, "taxId", void 0);
__decorate([
    (0, IsNullable_1.IsNullable)(),
    (0, class_validator_1.IsEmail)(),
    __metadata("design:type", String)
], ClientImportData.prototype, "email", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, IsNullable_1.IsNullable)(),
    __metadata("design:type", String)
], ClientImportData.prototype, "phone", void 0);
exports.ClientImportData = ClientImportData;
exports.clientDataParser = csv_columns_descriptor_1.CSVColumnBuilder.create()
    .append({
    clientNumber: column_descriptor_1.ColumnDescriptor.create().withValidation((d) => /^[0-9]{5}$/.test(d)),
    name: column_descriptor_1.ColumnDescriptor.create().withValidation((v) => v !== ''),
    taxId: column_descriptor_1.ColumnDescriptor.create().orEmpty(null),
    email: column_descriptor_1.ColumnDescriptor.create()
        .withValidation((v) => (0, class_validator_1.isEmail)(v))
        .orEmpty(null),
    phone: column_descriptor_1.ColumnDescriptor.create().orEmpty(null)
})
    .build({
    validateData: (data) => {
        const numberSet = new Set();
        return data.flatMap((r, ri) => {
            if (numberSet.has(r.clientNumber)) {
                return [
                    {
                        rows: [ri],
                        cells: ['clientNumber'],
                        message: `Duplicate client number ${r.clientNumber}`
                    }
                ];
            }
            numberSet.add(r.clientNumber);
            return [];
        });
    }
});
