"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderElement = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const ProjectOrder_1 = require("../../project/entity/ProjectOrder");
// eslint-disable-next-line import/no-cycle
const UploadFile_1 = require("../../files/entity/UploadFile");
let OrderElement = class OrderElement extends JournaledEntity_1.JournaledEntity {
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectOrder_1.ProjectOrder),
    __metadata("design:type", ProjectOrder_1.ProjectOrder)
], OrderElement.prototype, "order", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], OrderElement.prototype, "description", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], OrderElement.prototype, "elementType", void 0);
__decorate([
    (0, typeorm_1.Column)('timestamp', { nullable: true }),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], OrderElement.prototype, "deadline", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], OrderElement.prototype, "state", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => UploadFile_1.UploadFile),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], OrderElement.prototype, "attachedFiles", void 0);
OrderElement = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.TableInheritance)({ column: { name: 'elementType', type: 'varchar' } })
], OrderElement);
exports.OrderElement = OrderElement;
