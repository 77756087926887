import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { useLanguageIndex } from '../hooks/language-index.hook';
import { IETFIcon } from './IETFIcon';

interface LanguageDisplayProps extends TypographyProps {
  languageId: string;
}

export function LanguageDisplay({
  languageId,
  ...typographyProps
}: LanguageDisplayProps) {
  const languageIndex = useLanguageIndex();

  const sourceLanguageDescriptor = languageIndex.descriptors[languageId];

  return (
    <>
      {sourceLanguageDescriptor && (
        <Typography
          {...typographyProps}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <IETFIcon tag={sourceLanguageDescriptor.specification} />
          {sourceLanguageDescriptor.specification.language.name}
        </Typography>
      )}
    </>
  );
}
