import { BaseEntity } from 'model';
import React from 'react';
import { Chip, CircularProgress } from '@material-ui/core';
import {
  EntityReferenceSelect,
  SelectProps,
  SelectValue
} from './EntityReferenceSelect';
import { PropertyPath, useTypedField } from '../../transformer/DTOViewSchema';
import { useEditorLocation } from '../../hooks/editor-location.hook';
import { useEntityAPI } from '../../redux/entity-api.hook';

export type FormikQuickaddEntitySelectProps<
  T extends BaseEntity,
  V extends SelectValue<T>
> = Omit<
  SelectProps<T, V> & {
    name: string | PropertyPath<any, V[]>;
    quickAdd: (value: string, onSubmit: (v: T) => void) => JSX.Element | null;
    renderer: (v: T, onDelete: (v: T) => void) => string | JSX.Element;
  },
  'currentValue' | 'selectValue'
>;

export function FormikQuickaddEntitySelect<
  T extends BaseEntity,
  V extends SelectValue<T>
>({
  name,
  quickAdd,
  renderer,
  ...props
}: FormikQuickaddEntitySelectProps<T, V>) {
  const [, meta, helpers] = useTypedField(name);
  const { type } = useEditorLocation();

  const isEditable =
    type === 'edit' || type === 'create' || type === 'duplicate';

  const { apiState, entities } = useEntityAPI(props.entityApi);

  const entityMap: Record<T['id'], T> = Object.fromEntries(
    entities.map((e) => [e.id, e])
  ) as Record<T['id'], T>;

  const subProps = {
    disabled: !isEditable,
    currentValue: null,
    selectValue: (v: V) => {
      helpers.setValue([...meta.value, v]);
    },
    ...props,
    textFieldProps: {
      ...props.textFieldProps,
      error: meta.touched && Boolean(meta.error),
      helperText: meta.touched && meta.error
    }
  } as unknown as any;

  if (apiState !== 'idle') {
    return <CircularProgress />;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ display: 'flex' }}>
        {(meta.value as T['id'][]).map((v, i) => {
          const entity = entityMap[v];

          const onDelete = () =>
            helpers.setValue(meta.value.filter((id) => id !== v));

          const rendered = renderer(entity, onDelete);

          if (typeof rendered === 'string') {
            return (
              <Chip
                size="small"
                style={{ marginLeft: i > 0 ? 5 : 0 }}
                label={rendered}
                onDelete={onDelete}
              />
            );
          }
          return rendered;
        })}
      </div>
      <EntityReferenceSelect<T, V>
        {...subProps}
        action={(input, c1) =>
          quickAdd(input, (ent: T) => {
            c1(ent);
            helpers.setValue([...(meta.value as any), ent.id]);
          })
        }
      />
    </div>
  );
}
