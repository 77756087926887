import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import {
  TableColumnPositionContextData,
  TableColumnVisibilityContextData
} from '../layout/table-layout.context';
import { TableSortContextData } from '../sort/sort.context';
import { TableSearchContextData } from '../search/search.context';
import { TablePaginationContextData } from '../pagination/pagination.context';
import { TableFilterContextData } from '../filter/column-filter.context';
import { TableSelectionContextData } from '../selection/selection.context';

export interface DataTableConfigContextType<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  tableCells: C;
  displayedColumns: Partial<C>;
  tableColumnVisibility: TableColumnVisibilityContextData<T, C>;
  tableColumnPosition: TableColumnPositionContextData<T, C>;
  tableSort: TableSortContextData<T, C>;
  tableSearch: TableSearchContextData;
  tablePagination: TablePaginationContextData;
  tableFilterState: TableFilterContextData<T, C>;
  tableSelection: TableSelectionContextData<T>;
}

export interface DataTableDataContextType<T extends BaseTableRow> {
  data: Record<T['id'], T>;
  tableData: T['id'][];
  displayedData: T['id'][];
  emptyRows: number;
}

export interface DataTableContextType<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  tableConfig: DataTableConfigContextType<T, C>;
  tableData: DataTableDataContextType<T>;
}
export const DataTableContext = React.createContext<
  DataTableContextType<any, any>
>(undefined as unknown as DataTableContextType<any, any>);
export function useTableContext<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const dataTableContext = React.useContext(DataTableContext);
  if (dataTableContext === undefined) {
    throw new Error('Table context can only be used inside data table');
  }
  return dataTableContext as DataTableContextType<T, C>;
}

export function useTableConfigContext<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const dataTableContext = useTableContext<T, C>();
  return dataTableContext.tableConfig;
}

export function useTableDataContext<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const dataTableContext = useTableContext<T, C>();
  return dataTableContext.tableData;
}
