"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.plainPositionTransformer = exports.PlainRequestPositionDTO = void 0;
const class_validator_1 = require("class-validator");
const BaseRequestPositionDTO_1 = require("./BaseRequestPositionDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const PlainRequestPosition_1 = require("../entity/PlainRequestPosition");
class PlainRequestPositionDTO extends BaseRequestPositionDTO_1.BaseRequestPositionDTO {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    __metadata("design:type", String)
], PlainRequestPositionDTO.prototype, "name", void 0);
exports.PlainRequestPositionDTO = PlainRequestPositionDTO;
exports.plainPositionTransformer = (0, DTOTransformer_1.createDTOTransformer)(PlainRequestPosition_1.PlainRequestPosition, PlainRequestPositionDTO, [
    'id',
    'updatedAt',
    'createdAt',
    'priceUnit',
    'request',
    'requestId',
    'resources',
    'commentIds',
    'comments',
    'requiredPositions'
], {});
