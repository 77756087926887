import { ResourceTranslation } from 'model';
import { createErrorHandlingThunk } from '../../redux/thunk';
import { apiClient } from '../../api/apiClient';

export const listTranslationsThunk = createErrorHandlingThunk<
  ResourceTranslation[],
  void
>(
  `translations/list`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async () => {
    const result = await apiClient.get<ResourceTranslation[]>('/translations');
    return result.data;
  }
);

export const updateTranslationsThunk = createErrorHandlingThunk<
  void,
  { id: string; value: string | null }
>(
  `translations/update`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ id, value }, api) => {
    await apiClient.put<any>(`/translations/${id}`, {
      translation: value
    });
    api.dispatch(listTranslationsThunk());
  }
);

export const importTranslationsThunk = createErrorHandlingThunk<
  void,
  { id: string; translation: string | null }[]
>(
  `translations/update`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (map, api) => {
    await apiClient.put<any>(`/translations/`, map);
    api.dispatch(listTranslationsThunk());
  }
);
