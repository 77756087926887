"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CSVColumnBuilder = void 0;
// eslint-disable-next-line import/no-cycle
const CSVParser_1 = require("./CSVParser");
class CSVColumnBuilder {
    constructor(columnMap, currentPosition = 0) {
        this.columnMap = columnMap;
        this.currentPosition = currentPosition;
    }
    skip(amount = 1) {
        return new CSVColumnBuilder(this.columnMap, this.currentPosition + amount);
    }
    /*  append<N extends string>(...names: N[]): CSVColumnBuilder<K | N> {
      return new CSVColumnBuilder<K | N>([...this.columnMap, ...names]);
    }  */
    append(map) {
        let cellPosition = this.currentPosition;
        const cells = Object.fromEntries(Object.entries(map).map(([k, v]) => {
            cellPosition += v.positionOffset + 1;
            return [k, { name: k, position: cellPosition - 1, column: v }];
        }));
        return new CSVColumnBuilder({ ...this.columnMap, ...cells }, cellPosition);
    }
    static create() {
        return new CSVColumnBuilder({});
    }
    build(config) {
        return new CSVParser_1.CSVParser(this.columnMap, this.currentPosition, config);
    }
}
exports.CSVColumnBuilder = CSVColumnBuilder;
