import {
  Checkbox,
  createStyles,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useField } from 'formik';
import { TreeItem } from '@material-ui/lab';
import React from 'react';
import { PermissionTreeNode } from 'model';
import { useTranslation } from 'react-i18next';

export function treeElementToIds(treeNode: PermissionTreeNode): string[] {
  return treeNode.children
    .flatMap((node) => treeElementToIds(node))
    .concat([String(treeNode.id)]);
}

const useStyles = makeStyles(() =>
  createStyles({
    treeNodeLabel: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
);

interface PermissionTreeProps {
  treeElement: PermissionTreeNode;
}

export function FormikPermissionTree({ treeElement }: PermissionTreeProps) {
  const classes = useStyles();
  const [, meta, helpers] = useField<number[]>('permissions');

  const { t } = useTranslation();
  return (
    <TreeItem
      nodeId={String(treeElement.id)}
      label={
        <div className={classes.treeNodeLabel}>
          <Typography>{t(treeElement.name.replaceAll('.', '_'))}</Typography>
          {treeElement.children.length === 0 && (
            <Checkbox
              checked={meta.value.includes(treeElement.id)}
              onChange={(_, checked) => {
                if (checked) {
                  helpers.setValue([...meta.value, treeElement.id]);
                } else {
                  helpers.setValue(
                    meta.value.filter((id) => id !== treeElement.id)
                  );
                }
              }}
            />
          )}
        </div>
      }
    >
      {treeElement.children.map((treeElementChild) => (
        <FormikPermissionTree treeElement={treeElementChild} />
      ))}
    </TreeItem>
  );
}
