import React, { ReactNode } from 'react';
import { Divider, IconButton, SvgIcon, Typography } from '@material-ui/core';
import { createStyles, lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      backgroundColor:
        theme.palette.type === 'light'
          ? lighten(theme.palette.grey.A100, 0.6)
          : theme.palette.grey.A700
    },
    iconButton: { padding: 2 }
  })
);

export interface CardHeaderAction {
  icon: typeof SvgIcon;
  color?: string;
  title?: string;
  onClick: () => void;
}

interface CardHeaderProps {
  title: ReactNode;
  actions: CardHeaderAction[];
}

export function StyledCardHeader(props: CardHeaderProps) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.cardHeader}>
        <Typography color="textSecondary" variant="h6">
          {props.title}
        </Typography>
        <div>
          {props.actions.map((action) => (
            <IconButton
              title={action.title}
              onClick={action.onClick}
              size="small"
              className={classes.iconButton}
            >
              <action.icon htmlColor={action.color} />
            </IconButton>
          ))}
        </div>
      </div>
      <Divider />
    </>
  );
}
