"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CircularRef = exports.transformCircularEntityRefGroup = void 0;
const class_transformer_1 = require("class-transformer");
const circularReferenceGroupPrefix = 'circular-entity-ref';
function transformCircularEntityRefGroup(constructorName) {
    return `${circularReferenceGroupPrefix}-${constructorName}`;
}
exports.transformCircularEntityRefGroup = transformCircularEntityRefGroup;
function CircularRef(type, array = false) {
    return (0, class_transformer_1.Transform)((t) => {
        var _a, _b, _c, _d;
        const realType = typeof type === 'string' ? type : type === null || type === void 0 ? void 0 : type.name;
        const constructorName = realType || ((_b = (_a = t.value) === null || _a === void 0 ? void 0 : _a.constructor) === null || _b === void 0 ? void 0 : _b.name);
        if (constructorName) {
            if ((_c = t.options.groups) === null || _c === void 0 ? void 0 : _c.includes(transformCircularEntityRefGroup(constructorName))) {
                if (array) {
                    return t.value.map((a) => ({ id: a === null || a === void 0 ? void 0 : a.id }));
                }
                return (_d = t.value) === null || _d === void 0 ? void 0 : _d.id;
            }
        }
        return t.value;
    }, {});
}
exports.CircularRef = CircularRef;
