import React from 'react';
import { CircularProgress, List, ListItem } from '@material-ui/core';
import { SupplierLanguage } from 'model';
import { useLanguages } from '../../../../languages/hooks/languages.hook';
import { useLanguageIndex } from '../../../../languages/hooks/language-index.hook';
import { IETFIcon } from '../../../../languages/components/IETFIcon';
import { ErrorBoundary } from '../../../../error/ErrorBoundary';
import { LanguageNativeIcon } from '../../../../languages/components/LanguageNativeIcon';

interface SupplierLanguagesListProps {
  supplierLanguages: SupplierLanguage[];
}

export function SupplierLanguagesDisplay({
  supplierLanguages
}: SupplierLanguagesListProps) {
  const { languages, apiState } = useLanguages();
  const languageIndex = useLanguageIndex();

  const assignmentMap = Object.fromEntries(
    languages
      .filter((d) => supplierLanguages.find((sl) => sl.languageId === d.id))
      .map((d) => [d.id, d])
  );

  if (apiState === 'pending') {
    return <CircularProgress />;
  }

  return (
    <ErrorBoundary context="SupplierLanguagesDisplay">
      <List dense>
        {supplierLanguages.map((sl) => {
          const { specification } = languageIndex.descriptors[sl.languageId];
          return (
            <ListItem dense style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{ paddingTop: 2, marginRight: 5, alignSelf: 'center' }}
              >
                <LanguageNativeIcon isNative={sl.native} />
              </span>
              <span style={{ marginRight: 6 }}>
                <IETFIcon tag={specification} />
              </span>
              {assignmentMap[sl.languageId].name}
            </ListItem>
          );
        })}
      </List>
    </ErrorBoundary>
  );
}
