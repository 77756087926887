import { RequestPosition, WrapRequestPositionDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const requestPositionThunk = createFeatureThunks<
  RequestPosition,
  WrapRequestPositionDTO,
  WrapRequestPositionDTO,
  { requestId: string }
>({
  urlFactory: ({ id, requestId } = { id: undefined, requestId: '' }) =>
    `/projects/project-request/${requestId}/positions${
      id !== undefined ? `/${id}` : ''
    }`,
  thunkName: 'request-position'
});
