import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { clientSelectors } from '../redux/clients.slice';
import { listClientsThunk } from '../redux/actions/list-clients.thunk';

export function useClients() {
  const dispatch = useAppDispatch();
  const clients = useAppSelector(clientSelectors.selectAll);

  React.useEffect(() => {
    dispatch(listClientsThunk());
  }, []);

  return { clients };
}
