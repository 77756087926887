"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inactiveStatusTypeTransformer = exports.InactiveStatusTypeDTO = void 0;
const SupplierStatusTypeDTO_1 = require("./SupplierStatusTypeDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const InactiveStatusType_1 = require("../entity/InactiveStatusType");
class InactiveStatusTypeDTO extends SupplierStatusTypeDTO_1.SupplierStatusTypeDTO {
}
exports.InactiveStatusTypeDTO = InactiveStatusTypeDTO;
exports.inactiveStatusTypeTransformer = (0, DTOTransformer_1.createDTOTransformer)(InactiveStatusType_1.InactiveStatusType, InactiveStatusTypeDTO, ['id', 'createdAt', 'updatedAt', 'defaultType'], {});
