import {
  CreateRequestDTO,
  InitialPlainPositionDTO,
  InitialServicePositionDTO,
  ProjectStatusList,
  ResourceTypes
} from 'model';
import * as yup from 'yup';
import { buildDTOView } from '../../../../transformer/DTOViewSchema';
import { RequestServicePositionView } from './positions/form/RequestPositionForm';
import { RequestBasicView } from './basic/RequestBasicView';

export const RequestCreatorView = buildDTOView<CreateRequestDTO>()
  .all()
  .withSchema(
    yup
      .object({
        duplicatedFromId: yup
          .string()
          .uuid()
          .nullable()
          .default(null)
          .notRequired()
          .optional() as any,
        project: yup
          .object({
            projectName: yup.string().required('required'),
            projectTagIds: yup
              .array()
              .required()
              .of(yup.string().uuid().required()),
            clientId: yup.string().nullable().uuid().required('required'),
            projectManagerId: yup
              .string()
              .nullable()
              .uuid()
              .required('required'),
            projectVariationId: yup
              .string()
              .uuid()
              .defined()
              .nullable()
              .default(null),
            supportingProjectManagerIds: yup
              .array()
              .required()
              .of(yup.string().uuid().required()),
            createdInWordbee: yup.boolean().required(),
            tmConsolidated: yup.boolean().required(),
            projectStatus: yup.mixed().oneOf(ProjectStatusList).required(),
            deadline: yup.date().defined().nullable().default(null)
          })
          .defined(),
        request: RequestBasicView.validationSchema,
        resources: yup
          .array()
          .of(
            yup
              .object({
                description: yup.string().required().default(''),
                name: yup.string().required('required').min(2, 'min length 2'),
                type: yup.mixed().oneOf(Object.keys(ResourceTypes)).required(),
                available: yup.boolean().required(),
                estimatedDelivery: yup
                  .date()
                  .defined()
                  .nullable()
                  .default(null),
                comment: yup
                  .object({
                    comment: yup.string().defined().default(''),
                    attachedFiles: yup
                      .array()
                      .of(
                        yup
                          .object({
                            fileAccessId: yup.string().uuid().required() as any,
                            token: yup.string().required() as any
                          })
                          .required()
                      )
                      .notRequired()
                      .default(undefined)
                  })
                  .nullable()
                  .default(null),
                attachedFiles: yup.array().of(
                  yup
                    .object({
                      fileAccessId: yup.string().uuid().required() as any,
                      token: yup.string().required() as any
                    })
                    .required()
                )
              })
              .defined()
          )
          .defined(),
        positions: yup
          .array()
          .of(
            yup
              .object()
              .when(
                (v: InitialServicePositionDTO | InitialPlainPositionDTO) => {
                  if (v.positionType === 'service-position') {
                    return RequestServicePositionView.validationSchema;
                  }
                  return yup.object() as any;
                }
              )
              .defined() as any
          )
          .defined()
      })
      .defined()
  );
