import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import ActionsSpeedDial from '../../ActionsSpeedDial';
import { NavigationBar } from '../navigationBar/NavigationBar';
import { NewEntityActions } from '../mainNavigationMap';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  content: {
    flexGrow: 1,
    overflowX: 'auto',
    overflowY: 'hidden',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export function MainFrame({ children }: PropsWithChildren<unknown>) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NavigationBar />
      <ActionsSpeedDial actions={NewEntityActions} />
      <main className={classes.content}>{children}</main>
    </div>
  );
}
