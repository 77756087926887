import { formatProjectNumber, projectDataParser, taskDataParser } from 'model';
import { upperFirst } from 'lodash';
import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { CSVParserView } from '../../../components/import/CSVParserView';
import { useClients } from '../../clients/hooks/clients.hook';
import { useSuppliers } from '../../suppliers/hooks/suppliers.hook';
import { useProjects } from '../hooks/projects.hook';
import { NoOp } from '../../../utils';

const importTabs = {
  project: <div />,
  tasks: <div />
};
export function ProjectImportScreen() {
  const { clients } = useClients();
  const { suppliers } = useSuppliers();
  const { entities: projects } = useProjects();
  const [tab, setTab] = React.useState<keyof typeof importTabs>('project');
  /*  const pretransformTasks = (input: string[][]) => {
    const projectNumber = input[6];
    const taskService = input[12];
  }; */
  return (
    <>
      <Tabs value={tab} onChange={(_, value) => setTab(value)}>
        {Object.keys(importTabs).map((k) => (
          <Tab value={k} key={k} label={upperFirst(k)} />
        ))}
      </Tabs>
      <div>
        {tab === 'project' && (
          <CSVParserView
            importAction={() => {}}
            headerRows={1}
            key="project"
            parser={projectDataParser(
              (c) => suppliers.find((s) => s.importReference === c) ?? null,
              (c) =>
                clients.find((cl) => cl.clientNumber?.number === c) ?? null,
              (c) =>
                projects.find(
                  (p) =>
                    p.projectNumber !== null &&
                    c === formatProjectNumber(p.projectNumber)
                ) ?? null
            )}
          />
        )}
        {tab === 'tasks' && (
          <CSVParserView
            key="tasks"
            headerRows={1}
            importAction={NoOp}
            parser={
              taskDataParser(
                (c) => suppliers.find((s) => s.importReference === c) ?? null,
                (c) =>
                  clients.find((cl) => cl.clientNumber?.number === c) ?? null,
                (c) =>
                  projects.find(
                    (p) =>
                      p.projectNumber !== null &&
                      c === formatProjectNumber(p.projectNumber)
                  ) ?? null
              ) as any
            }
          />
        )}
      </div>
    </>
  );
}
