import React, { PropsWithChildren } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { lightTheme } from './light';
import { darkTheme } from './dark';

const LOCAL_STORAGE_THEME_KEY = 'theme';

type ThemeType = 'light' | 'dark';

interface ThemeContextInterface {
  state: ThemeType;
  setTheme: null | ((t: ThemeType) => void);
}

const getTheme = (themeType: ThemeType) =>
  themeType === 'light' ? lightTheme : darkTheme;

export const ThemeContext = React.createContext<ThemeContextInterface>({
  state: 'light',
  setTheme: null
});

export function KontextorThemeProvider(props: PropsWithChildren<unknown>) {
  const currentTheme = (localStorage.getItem(LOCAL_STORAGE_THEME_KEY) ||
    'light') as ThemeType;

  const [themeType, setThemeType] = React.useState<ThemeType>(currentTheme);

  const theme = getTheme(themeType);

  const setTheme = (type: ThemeType) => {
    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, type);
    setThemeType(type);
  };

  return (
    <ThemeContext.Provider value={{ state: themeType, setTheme }}>
      <ThemeProvider theme={{ ...theme }}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
