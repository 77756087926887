import React, { FC } from 'react';
import {
  OrderStepTask,
  orderStepTaskTransformer,
  OrderTask,
  ServiceStep,
  TemplateScope
} from 'model';
import { Grid, IconButton, Popover } from '@material-ui/core';
import { Formik } from 'formik';
import { unwrapResult } from '@reduxjs/toolkit';
import { Cancel, Check } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { serviceStepAPI } from '../../../services/redux/service-steps.slice';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { FormikEntitySelect } from '../../../../components/select/FormikEntitySelect';
import {
  createOrderSubTaskThunk,
  projectOrderTasksThunk
} from '../../redux/project-order-tasks.thunk';
import { NoOp } from '../../../../utils';
import { StepTaskForm } from './StepTaskForm';

export function ServiceStepSelectPopover({
  button: Button,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  filter,
  onInput,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scope,
  phase,
  projectId,
  parentTask,
  dependencyIds = []
}: {
  scope: TemplateScope;
  phase: 'preparation' | 'provision' | 'post-delivery';
  projectId: string;
  parentTask?: OrderTask;
  dependencyIds?: string[];
  onInput: (selection: ServiceStep | null) => void;
  filter: (serviceStep: ServiceStep) => boolean;
  button: FC<{
    onClick: (anchor: HTMLElement) => void;
  }>;
}) {
  const [anchor, setAnchor] = React.useState<HTMLElement>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  return (
    <div style={{ position: 'relative' }}>
      <Button onClick={setAnchor} />
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={() => setAnchor(undefined)}
      >
        <Formik
          initialValues={
            {
              serviceStepId: null as unknown as string,
              phase,
              taskType: 'step-task',
              state: 'pending',
              deadline: null,
              description: '',
              dependencies: dependencyIds.map((d) => ({ id: d } as OrderTask))
            } as OrderStepTask
          }
          onSubmit={(values) => {
            if (parentTask?.id) {
              return dispatch(
                createOrderSubTaskThunk({
                  projectId,
                  parentId: parentTask.id,
                  input: {
                    task: orderStepTaskTransformer((s) => btoa(s))(values)
                  }
                })
              )
                .then((v) => unwrapResult(v) as OrderStepTask)
                .catch(NoOp);
            }
            return dispatch(
              projectOrderTasksThunk.create({
                input: {
                  task: orderStepTaskTransformer((s) => btoa(s))(values)
                },
                projectId
              })
            )
              .then((v) => unwrapResult(v) as OrderStepTask)
              .catch(NoOp);
          }}
        >
          {({ values, submitForm }) => (
            <Grid
              style={{ padding: 20, minWidth: 300, overflow: 'hidden' }}
              container
              xs={12}
            >
              <Grid item xs={12}>
                <FormikEntitySelect
                  textFieldProps={{ label: t('Service Step') }}
                  entityApi={serviceStepAPI}
                  optionLabel={(step) => step.name}
                  name="serviceStepId"
                />
              </Grid>
              {values.serviceStepId && <StepTaskForm />}
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 20,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                {values.serviceStepId && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      submitForm().then((result) => {
                        if (result as any) {
                          setAnchor(undefined);
                        }
                        return result;
                      });
                    }}
                  >
                    <Check />
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  onClick={() => {
                    onInput(null);
                    setAnchor(undefined);
                  }}
                >
                  <Cancel />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Popover>
    </div>
  );
}
