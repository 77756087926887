import {
  AcceptOfferDTO,
  ProjectOffer,
  ProjectOfferDTO,
  ProjectPatchDTO,
  RejectOfferDTO,
  RequestComment,
  RequestCommentDTO
} from 'model';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';
import { createFeatureThunks } from '../../../redux/featureThunks';
import { projectThunk } from './projects.thunk';

export const projectOfferThunk = createFeatureThunks<
  ProjectOffer,
  ProjectOfferDTO,
  ProjectPatchDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/projects/project-offer${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'project-offer'
});

export const createProjectOfferThunk = createErrorHandlingThunk<
  ProjectOffer,
  { requestId: string; input: ProjectOfferDTO }
>('project-offers/create', async ({ requestId, input }) => {
  const offer = await apiClient.post<RequestComment>(
    `/projects/project-request/${requestId}/offers`,
    {
      ...input,
      totalPrice: input.totalPrice !== null ? Number(input.totalPrice) : null
    }
  );
  return (
    await apiClient.get(
      `/projects/project-request/${requestId}/offers/${offer.data.id}`
    )
  ).data;
});

export const updateProjectOfferThunk = createErrorHandlingThunk<
  ProjectOffer,
  { requestId: string; id: string; input: ProjectOfferDTO }
>('project-offers/update', async ({ requestId, id, input }) => {
  await apiClient.put<RequestComment>(
    `/projects/project-request/${requestId}/offers/${id}`,
    input
  );
  return (
    await apiClient.get(`/projects/project-request/${requestId}/offers/${id}`)
  ).data;
});

export const rejectProjectOfferThunk = createErrorHandlingThunk<
  ProjectOffer,
  { projectId: string; requestId: string; id: string; input: RejectOfferDTO }
>(
  'project-offers/reject',
  async ({ projectId, requestId, id, input }, thunkAPI) => {
    await apiClient.put<RequestComment>(
      `/projects/project-request/${requestId}/offers/${id}/rejection`,
      input
    );
    thunkAPI.dispatch(projectThunk.get({ id: projectId }));
    return (
      await apiClient.get(`/projects/project-request/${requestId}/offers/${id}`)
    ).data;
  }
);

export const acceptProjectOfferThunk = createErrorHandlingThunk<
  ProjectOffer,
  { projectId: string; requestId: string; id: string; input: AcceptOfferDTO }
>(
  'project-offers/reject',
  async ({ projectId, requestId, id, input }, thunkAPI) => {
    await apiClient.put<RequestComment>(
      `/projects/project-request/${requestId}/offers/${id}/order`,
      input
    );
    thunkAPI.dispatch(projectThunk.get({ id: projectId }));
    return (
      await apiClient.get(`/projects/project-request/${requestId}/offers/${id}`)
    ).data;
  }
);

export const createOfferCommentThunk = createErrorHandlingThunk<
  RequestComment,
  { requestId: string; offerId: string; input: RequestCommentDTO }
>('request-comments/offer/create', async ({ requestId, offerId, input }) => {
  const comment = await apiClient.post<RequestComment>(
    `/projects/project-request/${requestId}/offers/${offerId}/comments`,
    input
  );
  return (
    await apiClient.get(
      `/projects/project-request/${requestId}/comments/${comment.data.id}`
    )
  ).data;
});

export const loadRequestOffersThunk = createErrorHandlingThunk<
  ProjectOffer[],
  { requestId: string }
>('request/offers/list', async ({ requestId }) => {
  const result = await apiClient.get(
    `/projects/project-request/${requestId}/offers`
  );
  return result.data;
});
