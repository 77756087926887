import React from 'react';
import { useHistory } from 'react-router-dom';
import { apiClient } from '../../../api/apiClient';

type ResetState = 'initial' | 'validate-token' | 'apply-reset';

export function usePasswordReset() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [resetState, setResetState] = React.useState<ResetState>('initial');
  const [currentToken, setCurrentToken] = React.useState<string>();

  const requestPasswordReset = async (email: string) => {
    setLoading(true);
    await apiClient.post('/user/password-reset', { email });
    setLoading(false);
    setResetState('validate-token');
  };

  const validatePasswordResetToken = async (token: string) => {
    setLoading(true);
    setCurrentToken(token);
    await apiClient.get(`/user/password-reset/${token}`);
    setLoading(false);
    setResetState('apply-reset');
  };

  const applyPasswordReset = async (newPassword: string) => {
    setLoading(true);
    await apiClient.post(`/user/password-reset/${currentToken}`, {
      password: newPassword
    });
    setLoading(false);
    history.replace('/login');
  };

  return {
    loading,
    resetState,
    requestPasswordReset,
    validatePasswordResetToken,
    applyPasswordReset
  };
}
