import React from 'react';
import {
  Box,
  IconButton,
  makeStyles,
  SvgIcon,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { Link as RouterLink, Route, useRouteMatch } from 'react-router-dom';
import { Switch as RouterSwitch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Comment as CommentIcon } from '@material-ui/icons';
import { ErrorBoundary } from '../../features/error/ErrorBoundary';
import { formatDate } from '../../localized-formats';
import { IconButtonLink } from '../input/button/IconButtonLink';
import { CommentsDrawer } from '../comment/CommentsDrawer';
import {
  executeMatcher,
  PermissionMatcher
} from '../../features/auth/permissions.matchers';
import { useGrantedPermissions } from '../../features/auth/hooks/granted-permissions.hook';
import { SubPageArea } from '../../hooks/page-area.components';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: { minHeight: '91%' },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2)
    },
    timeContainer: {
      marginLeft: theme.spacing(1)
    },
    actionsContainer: {
      marginLeft: 'auto'
    },
    content: {
      paddingTop: theme.spacing(3),
      position: 'relative'
    },
    badge: {
      fontSize: 8,
      height: 15,
      width: 15
    }
  })
);

export interface TabDefinition<T> {
  name: string;
  subPaths: string[];
  component: (entity: T) => React.ReactNode;
  permission?: PermissionMatcher;
}

interface EntityDetailsProps<T> {
  entityName?: string;
  entity?: T;
  title: keyof T | ((entity: T) => React.ReactNode);
  titleAdornment?: JSX.Element;
  tabs: TabDefinition<T>[];
  renderComments?: (entity: T) => React.ReactNode;
  actions?: {
    label: string;
    icon: JSX.Element | typeof SvgIcon;
    onClick: () => void;
  }[];
  renderTail?: (entity: T) => React.ReactNode;
}

export function EntityDetails<
  T extends { createdAt: string | Date; updatedAt: string | Date }
>(props: EntityDetailsProps<T>) {
  const grantedPermissions = useGrantedPermissions();
  const { t } = useTranslation();
  const classes = useStyles();
  const { path, url } = useRouteMatch();
  const { entity, entityName, title } = props;

  if (!entity) {
    return <div>{entityName ?? 'Entity'} Not Found</div>;
  }

  const permittedTabs = props.tabs.filter((tab) =>
    executeMatcher(tab.permission, grantedPermissions)
  );

  return (
    <SubPageArea
      type="entity-details"
      specifier={entityName ?? 'Entity?'}
      identifier={(entity as any)?.id}
    >
      <ErrorBoundary context={`entityDetails-${path}`}>
        <div className={classes.root}>
          <div className={classes.headerContainer}>
            <Typography
              variant="h3"
              key="title-header"
              style={{ marginRight: props.titleAdornment ? 20 : 0 }}
            >
              {typeof title === 'function' ? title(entity) : entity[title]}
            </Typography>
            {props.titleAdornment}

            <div className={classes.timeContainer}>
              <Typography
                key="created-at-header"
                variant="caption"
                color="textSecondary"
              >
                {`${t('Created')}: ${formatDate(entity.createdAt, 'date')}`}
              </Typography>
              <br />
              <Typography
                key="updated-at-header"
                variant="caption"
                color="textSecondary"
              >
                {`${t('Updated')}: ${formatDate(entity.updatedAt, 'date')}`}
              </Typography>
            </div>
          </div>
          <RouterSwitch>
            {permittedTabs.map((tab) => (
              <Route
                key={tab.name}
                exact
                path={tab.subPaths.map((sP) => path + sP)}
              >
                <Tabs value={tab.name}>
                  {permittedTabs.map((td) => (
                    <Tab
                      key={`${tab.name}-${td.name}`}
                      value={td.name}
                      label={t(td.name)}
                      component={RouterLink}
                      to={url + td.subPaths[0]}
                    />
                  ))}
                  <div className={classes.actionsContainer}>
                    {props.actions &&
                      props.actions.map((action) => (
                        <IconButton
                          size="small"
                          style={{ marginRight: 5 }}
                          title={t(action.label)}
                          onClick={action.onClick}
                        >
                          {typeof action.icon === 'function' ? (
                            <action.icon />
                          ) : (
                            action.icon
                          )}
                        </IconButton>
                      ))}
                    {props.renderComments && (
                      <IconButtonLink
                        title={t('Comments')}
                        style={{ marginRight: 5 }}
                        to={`${url}#comments`}
                        size="small"
                      >
                        <CommentIcon />
                      </IconButtonLink>
                    )}
                  </div>
                </Tabs>
                <div className={classes.content} key={tab.name}>
                  <SubPageArea type="tab" specifier={tab.name}>
                    {tab.component(entity)}
                  </SubPageArea>
                </div>
              </Route>
            ))}
          </RouterSwitch>
          {props.renderComments && (
            <CommentsDrawer>{props.renderComments(entity)}</CommentsDrawer>
          )}
          {props.renderTail && (
            <Box display="flex" width="100%" marginTop="2em">
              {props.renderTail(entity)}
            </Box>
          )}
        </div>
      </ErrorBoundary>
    </SubPageArea>
  );
}
