import React, { ReactNode } from 'react';
import { Edit as EditIcon } from '@material-ui/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CardWithHeader } from '../cardWithHeader/CardWithHeader';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { EditableCardForm } from './EditableCardForm';
import {
  EditableCardDisplayComponent,
  EditableCardFormBaseProps
} from './types';
import { CardHeaderAction } from '../cardWithHeader/StyledCardHeader';
import { useGrantedPermissions } from '../../../features/auth/hooks/granted-permissions.hook';
import { executeMatcher } from '../../../features/auth/permissions.matchers';
import { SubPageArea } from '../../../hooks/page-area.components';

interface NewEditableCardProps<T, U> {
  identifier: string;
  title: ReactNode;
  value: T;
  display: {
    component: EditableCardDisplayComponent<T>;
    additionalAction?: CardHeaderAction[];
  };
  form: EditableCardFormBaseProps<T, U>;
}

export function NewEditableCard<T, U>({
  identifier,
  title,
  value,
  display,
  form
}: NewEditableCardProps<T, U>) {
  const { t } = useTranslation();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { type, reference } = useEditorLocation();
  const grantedPermissions = useGrantedPermissions();

  const DisplayComponent = display.component;

  const goToEdit = () => history.push(`${url}?type=edit#${identifier}`);

  const isEditable = reference === identifier && type !== 'view';

  const actions = executeMatcher(form.permissions, grantedPermissions)
    ? [
        { title: t('Edit'), icon: EditIcon, onClick: goToEdit },
        ...(display.additionalAction ?? [])
      ]
    : [...(display.additionalAction ?? [])];

  return isEditable ? (
    <SubPageArea
      type="editable-card"
      specifier={identifier}
      identifier={reference}
    >
      <EditableCardForm<T, U>
        focused
        title={title}
        value={value}
        onCancel={history.goBack}
        {...form}
      />
    </SubPageArea>
  ) : (
    <SubPageArea type="editable-card" specifier={identifier}>
      <CardWithHeader title={title} actions={actions}>
        <DisplayComponent value={value} />
      </CardWithHeader>
    </SubPageArea>
  );
}
