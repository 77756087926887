import React from 'react';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { useProjectTags } from '../hooks/project-tags.hook';
import { ProjectTagFormDialog } from '../components/tags/ProjectTagFormDialog';
import { ProjectTagTable } from '../components/tags/ProjectTagsTable';

export function ProjectTagScreen() {
  const { entities, apiState, listEntities } = useProjectTags();
  const { type, reference } = useEditorLocation();

  return (
    <>
      {type === 'edit' && reference !== undefined && (
        <ProjectTagFormDialog tagId={reference} />
      )}
      <ProjectTagTable
        projectTags={entities}
        onRefresh={listEntities}
        apiState={apiState}
      />
    </>
  );
}
