import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography
} from '@material-ui/core';
import { Delete, ExpandMore } from '@material-ui/icons';
import { EntityId } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { UserRoleTree } from 'model';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { useAlert } from '../../../../components/alert/alert.hook';
import { PermissionTree } from '../PermissionTree';
import { deleteRoleThunk } from '../../redux/actions/delete-role.thunk';
import { updateRolePermissionThunk } from '../../redux/actions/update-role.thunk';

interface RoleAccordionProps {
  role: UserRoleTree;
  className?: string;
  navigateToDetails: (id: string) => void;
  navigateBack: () => void;
}

export function RoleAccordion({
  role,
  className,
  navigateToDetails,
  navigateBack
}: RoleAccordionProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { roleId } = useParams<{ roleId: string }>();

  const expanded = roleId === role.id;

  const { showAlert } = useAlert();

  const deleteRole = (id: EntityId) => {
    showAlert({
      title: 'Delete Role',
      content: `Are you sure your want to delete the role "${role.name}". 
      After deleting the role, all users will lose the associated permissions`,
      onConfirm: () => {
        dispatch(deleteRoleThunk(id));
      }
    });
  };

  const updateRolePermission = (permissionId: number, granted: boolean) => {
    const updateRolePermissionInput = {
      roleId: role.id,
      permissionDTO: { permissionId, granted }
    };
    dispatch(updateRolePermissionThunk(updateRolePermissionInput));
  };

  // const isFreshCreated = freshCreatedRoleId === role.id;

  const linkSummaryProps = {
    onClick: () => navigateToDetails(role.id)
  };

  const nonLinkSummaryProps = {
    onClick: () => navigateBack()
  };

  return (
    <Accordion expanded={expanded} className={className}>
      <AccordionSummary
        {...(expanded ? nonLinkSummaryProps : linkSummaryProps)}
        expandIcon={<ExpandMore />}
        aria-controls={`role-${role}-content`}
        id={`role-${role}-header`}
      >
        <Typography variant="h4">{t(role.name)}</Typography>
        <IconButton onClick={() => deleteRole(role.id)}>
          <Delete />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails>
        <PermissionTree
          defaultExpanded
          roleTree={role}
          updateRolePermission={updateRolePermission}
        />
      </AccordionDetails>
    </Accordion>
  );
}
