import React from 'react';
import { Grid } from '@material-ui/core';
import { ServiceStepPatchDTO } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { buildDTOView } from '../../../transformer/DTOViewSchema';
import { EditableCheckboxDisplay } from '../../../components/input/checkbox/EditableCheckboxDisplay';
import { FormikTextField } from '../../../components/input/FormikTextField';

export const ServiceStepBasicView = buildDTOView<ServiceStepPatchDTO>()
  .all()
  .withSchema(
    yup
      .object({
        name: yup.string().required('required').min(2, 'min length 2'),
        description: yup
          .string()
          .max(255, 'Max. 255 characters')
          .defined('required'),
        displayFormat: yup.string().max(255, 'Max. 255 characters').defined(),
        priceable: yup.boolean().required(),
        defaultStep: yup.boolean().required(),
        assigneeTypes: yup
          .object({
            supplier: yup
              .object({
                required: yup.boolean().notRequired(),
                constraints: yup.boolean().notRequired()
              })
              .notRequired()
              .default(undefined),
            'project-manager': yup
              .object({
                required: yup.boolean().notRequired(),
                constraints: yup.boolean().notRequired()
              })
              .notRequired()
              .default(undefined),
            client: yup
              .object({
                required: yup.boolean().notRequired(),
                constraints: yup.boolean().notRequired()
              })
              .notRequired()
              .default(undefined)
          })
          .defined(),
        scopes: yup
          .object({
            project: yup
              .object({
                required: yup.boolean().required(),
                phase: yup
                  .mixed()
                  .oneOf(['preparation', 'provision', 'post-delivery'])
                  .required()
              })
              .notRequired()
              .default(undefined) as any,
            'service-lane': yup
              .object({
                required: yup.boolean().required(),
                phase: yup
                  .mixed()
                  .oneOf(['preparation', 'provision', 'post-delivery'])
                  .required()
              })
              .notRequired()
              .default(undefined) as any,
            service: yup
              .object({
                required: yup.boolean().required(),
                phase: yup
                  .mixed()
                  .oneOf(['preparation', 'provision', 'post-delivery'])
                  .required()
              })
              .notRequired()
              .default(undefined) as any
          })
          .defined()
      })
      .defined()
  );

export function ServiceStepBasicForm() {
  const { t } = useTranslation();
  // const cardMode = useCardMode();
  // const editable = cardMode && cardMode !== 'view';
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={6}>
          <FormikTextField
            required
            name={ServiceStepBasicView.path.name}
            label={t('Name')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikTextField
            required
            name={ServiceStepBasicView.path.displayFormat}
            label={t('Display Format')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            label={t('Description')}
            name={ServiceStepBasicView.path.description}
            multiline
          />
        </Grid>
        <Grid item xs={3}>
          <EditableCheckboxDisplay
            name={ServiceStepBasicView.path.priceable}
            label={t('Priceable')}
          />
        </Grid>
        {/* 1. TODO für manu: Hier required single select für
         assignee type(erstmal nur Supplier oder Projectmanager)
        und checkbox für required und constraints,
         danach nochmal sprechen für speichern */}
        <Grid item xs={3}>
          <EditableCheckboxDisplay
            name={ServiceStepBasicView.path.defaultStep}
            label={t('Default Step')}
          />
        </Grid>

        {/* 2. TODO für manu: Wenn dir was einfällt, wie wir was vor
        denen verstecken können und trotzdem auf Prod nutzen
        dann für jeden scope(project, service, service-lane)
        zwei Checkboxes (enabled und required) und
        ein select für phase (preparation, provision, post-delivery). */}
      </Grid>
    </Grid>
  );
}
