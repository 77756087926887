import React from 'react';
import { serviceClassesSelectors } from '../redux/service-classes.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { serviceClassesThunk } from '../redux/service-classes.thunk';

export function useServiceClass(id: string | null | undefined) {
  const dispatch = useAppDispatch();

  const serviceClass = useAppSelector((state) =>
    serviceClassesSelectors.selectById(state, id ?? '')
  );

  const listFetched = useAppSelector(serviceClassesSelectors.selectListFetched);

  React.useEffect(() => {
    if (
      id &&
      !serviceClass?.requiredQualifications &&
      listFetched !== undefined
    ) {
      dispatch(serviceClassesThunk.get({ id }));
    }
  }, [id, serviceClass]);

  return { serviceClass };
}
