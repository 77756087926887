import {
  createEntityAdapter,
  createSlice,
  isAnyOf,
  isPending,
  isRejected
} from '@reduxjs/toolkit';
import { UserRoleTree } from 'model';
import { getInitialApiAdapterState } from '../../auth/redux/types';
import { listRolesThunk } from './actions/list-roles.thunk';
import { RootState } from '../../../redux/store';
import {
  updateRolePermissionThunk,
  updateRoleThunk
} from './actions/update-role.thunk';
import { createRoleThunk } from './actions/create-role.thunk';
import { deleteRoleThunk } from './actions/delete-role.thunk';

const rolesAdapter = createEntityAdapter<UserRoleTree>();

const rolesSlice = createSlice({
  name: 'roles',
  initialState: rolesAdapter.getInitialState(getInitialApiAdapterState()),
  reducers: {
    clearRolesError(state) {
      state.error = null;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(createRoleThunk.fulfilled, (state, { payload }) => {
        state.state = 'idle';
        rolesAdapter.addOne(state, payload);
      })
      .addCase(deleteRoleThunk.fulfilled, (state, { payload }) => {
        rolesAdapter.removeOne(state, payload);
      })
      .addCase(listRolesThunk.fulfilled, (state, { payload }) => {
        state.state = 'idle';
        state.listFetched = true;
        rolesAdapter.upsertMany(state, payload);
      })
      .addMatcher(
        isAnyOf(updateRoleThunk.fulfilled, updateRolePermissionThunk.fulfilled),
        (state, { payload }) => {
          state.state = 'idle';
          const { id, ...changes } = payload;
          rolesAdapter.updateOne(state, { id, changes });
        }
      )
      .addMatcher(
        isPending(
          listRolesThunk,
          updateRoleThunk,
          deleteRoleThunk,
          createRoleThunk,
          updateRolePermissionThunk
        ),
        (state) => {
          state.state = 'pending';
          state.error = null;
        }
      )
      .addMatcher(
        isRejected(
          listRolesThunk,
          updateRoleThunk,
          createRoleThunk,
          deleteRoleThunk,
          updateRolePermissionThunk
        ),
        (state, action) => {
          state.state = 'idle';
          state.error = action.error;
        }
      )
});

export const {
  selectById: selectRoleById,
  selectIds: selectRoleIds,
  selectEntities: selectRoleEntities,
  selectAll: selectAllRoles,
  selectTotal: selectTotalRoles
} = rolesAdapter.getSelectors((state: RootState) => state.roles);

export const { clearRolesError } = rolesSlice.actions;
export const selectRolesError = (state: RootState) => state.roles.error;
export const selectRolesState = (state: RootState) => state.roles.state;

export default rolesSlice.reducer;
