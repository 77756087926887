import { RequestSourceDTO, requestSourceTransformer } from 'model';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../../redux/redux.hooks';
import { NoOp } from '../../../../../utils';
import { DialogForm } from '../../../../../components/input/form/DialogForm';
import requestSourcesSlice, {
  requestSourceSelectors
} from '../../../redux/request-sources.slice';
import { requestSourceThunk } from '../../../redux/request-sources.thunk';
import {
  RequestSourceBasicForm,
  RequestSourceBasicView
} from './RequestSourceBasicForm';
import { CardWithHeader } from '../../../../../components/card/cardWithHeader/CardWithHeader';

const initialValues: RequestSourceDTO = {
  name: '',
  description: ''
};

export function RequestSourceFormDialog({ sourceId }: { sourceId?: string }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const requestSource = useAppSelector((state) =>
    requestSourceSelectors.selectById(state, sourceId ?? '')
  );

  const requestSourceDTO = requestSource
    ? requestSourceTransformer(requestSource)
    : undefined;

  const createRequestSource = (dto: RequestSourceDTO) => {
    if (requestSource) {
      return dispatch(
        requestSourceThunk.update({
          id: requestSource.id,
          input: dto
        })
      )
        .then(unwrapResult)
        .catch(NoOp);
    }
    return dispatch(
      requestSourceThunk.create({
        input: dto
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <DialogForm
      identifier={requestSource?.id ?? 'request-source'}
      maxWidth="md"
      label={
        requestSource?.id ? t('Edit Request Source') : t('New Request Source')
      }
      form={{
        initialValues: requestSourceDTO ?? initialValues,
        validationSchema: RequestSourceBasicView.validationSchema
      }}
      api={{
        clearError: requestSourcesSlice.actions.clearError,
        errorSelector: requestSourceSelectors.selectError,
        onSubmit: createRequestSource,
        stateSelector: requestSourceSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <CardWithHeader title="Basic">
        <RequestSourceBasicForm />
      </CardWithHeader>
    </DialogForm>
  );
}
