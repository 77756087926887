import { EntityId } from '@reduxjs/toolkit';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const deleteSupplierHistoryThunk = createErrorHandlingThunk<
  EntityId,
  { supplierId: string; id: string }
>('supplier-history/delete', async ({ supplierId, id }) => {
  await apiClient.delete(`/suppliers/${supplierId}/history/${id}`);
  return id;
});
