import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { useTableConfigContext } from '../context/DataTableContext';
import { executeMatcher } from '../../../features/auth/permissions.matchers';
import { TableColumnVisibilityContextData } from './table-layout.context';
import { useGrantedPermissions } from '../../../features/auth/hooks/granted-permissions.hook';

export function useRawDisplayedTableColumns<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(
  tableCells: C,
  columnConfig: TableColumnVisibilityContextData<T, C>['columnConfig']
) {
  const userPermissions: string[] = useGrantedPermissions();
  return React.useMemo(() => {
    return Object.fromEntries(
      Object.values(columnConfig)
        .filter((config) => config.visible)
        .map((config) => [config.cellId, tableCells[config.cellId]] as const)
        .filter(
          ([, cell]) =>
            cell.permissions === undefined ||
            executeMatcher(cell.permissions, userPermissions)
        )
    ) as Partial<C>;
  }, [tableCells, columnConfig, userPermissions]);
}

export function useDisplayedTableColumns<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  /*  const {
    tableCells,
    tableColumnVisibility: { columnConfig }
  } = useTableConfigContext<T, C>();
  return useRawDisplayedTableColumns(tableCells, columnConfig); */
  const { displayedColumns } = useTableConfigContext<T, C>();
  return displayedColumns;
}
