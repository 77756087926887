import React from 'react';
import * as yup from 'yup';
import {
  AddressDTO,
  PhoneNumberDTO,
  SupplierDTO,
  SupplierLanguageDTO,
  SupplierServiceStepDTO
} from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { EntityId, unwrapResult } from '@reduxjs/toolkit';
import { Grid } from '@material-ui/core';
import {
  getInitialNameValues,
  nameSchema
} from '../../../components/schemas/nameSchema';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { DialogForm } from '../../../components/input/form/DialogForm';
import suppliersSlice, { suppliersSelectors } from '../redux/suppliers.slice';
import { suppliersThunk } from '../redux/suppliers.thunk';
import { NoOp } from '../../../utils';
import { SupplierBasicForm } from './basic/SupplierBasicForm';
import { CardWithHeader } from '../../../components/card/cardWithHeader/CardWithHeader';
import { useServiceSteps } from '../../services/hooks/service-steps.hook';

const addressSchema: yup.SchemaOf<AddressDTO> = yup.object().shape({
  address: yup.array().required().of(yup.string().required()),
  zipCode: yup.string().default(null).nullable(),
  city: yup.string().required(),
  countryCode: yup.string().required(),
  comment: yup.string().default(null).nullable()
});

const phoneNumberSchema: yup.SchemaOf<PhoneNumberDTO> = yup.object().shape({
  type: yup.string().required(),
  number: yup.string().required(),
  comment: yup.string().default(null).notRequired().nullable()
});

const supplierLanguageSchema: yup.SchemaOf<SupplierLanguageDTO> = yup
  .object()
  .shape({
    languageId: yup.string().required(),
    native: yup.boolean().notRequired()
  });

export const supplierSchema: yup.SchemaOf<SupplierDTO> = yup.object().shape({
  name: nameSchema.required(),
  userId: yup.string().notRequired().nullable(),
  profession: yup.string().notRequired().nullable(),
  email: yup.string().email().required('required'),
  websites: yup.array().of(yup.string().required()).notRequired(),
  stars: yup.number().notRequired().nullable(),
  firstContact: yup.date().notRequired().nullable(),
  supplierCategoryId: yup.string().uuid().required(),
  catTools: yup.array().required().of(yup.string().required()),
  largeScaleProjectAssignments: yup.array().notRequired(),
  addresses: yup.array().notRequired().of(addressSchema.required()),
  qualifications: yup.array().notRequired(),
  phoneNumbers: yup.array().notRequired().of(phoneNumberSchema.required()),
  domainIds: yup.array().notRequired().of(yup.string().required()),
  defaultCurrency: yup.string().defined().nullable().default(null),
  inactivation: yup
    .object()
    .notRequired()
    .default(undefined)
    .shape({
      reason: yup.string().required(),
      until: yup.date().required(),
      automaticReactivation: yup.boolean().notRequired()
    })
    .notRequired(),
  languages: yup.array().notRequired().of(supplierLanguageSchema.required()),
  services: yup
    .array()
    .notRequired()
    .of(
      yup
        .object({
          serviceClassId: yup.string().required(),
          comment: yup.string().required(),
          languages: yup
            .array()
            .of(
              yup.object({
                sourceLanguage: yup.string().defined().nullable().default(null),
                targetLanguage: yup.string().required()
              })
            )
            .defined()
            .nullable()
            .default(null)
        })
        .required()
    ),
  serviceSteps: yup.array().of(
    yup
      .object({
        serviceStepId: yup.string().uuid().required(),
        comment: yup.string()
      })
      .defined()
  )
  // Todo richtig types
}) as yup.SchemaOf<SupplierDTO>;

const getInitialValues = (
  serviceSteps: SupplierServiceStepDTO[]
): SupplierDTO => ({
  name: getInitialNameValues(),
  email: '',
  catTools: [],
  websites: [],
  supplierCategoryId: '',
  firstContact: new Date(),
  serviceSteps
});

export function SupplierFormDialog() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { entities: serviceSteps } = useServiceSteps();

  const createSupplier = (dto: SupplierDTO) =>
    dispatch(suppliersThunk.create({ input: dto }))
      .then(unwrapResult)
      .catch(NoOp);

  const history = useHistory();

  const navigateToDetails = (id?: EntityId) =>
    history.replace(`/suppliers/supplier/${id}`);

  const initialServiceSteps = serviceSteps
    .filter((sStep) => sStep.defaultStep)
    .map((sStep) => ({ serviceStepId: sStep.id, comment: '' }));

  return (
    <DialogForm
      identifier="supplier"
      maxWidth="md"
      label={t('New Supplier')}
      form={{
        initialValues: getInitialValues(initialServiceSteps),
        validationSchema: supplierSchema
      }}
      api={{
        clearError: suppliersSlice.actions.clearError,
        errorSelector: suppliersSelectors.selectError,
        onSubmit: createSupplier,
        stateSelector: suppliersSelectors.selectState
      }}
      actions={[
        { label: t('Save and Exit'), doSubmit: true },
        {
          label: t('Save and Details'),
          doSubmit: true,
          onClick: navigateToDetails
        }
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardWithHeader title="Basic">
            <SupplierBasicForm />
          </CardWithHeader>
        </Grid>
      </Grid>
    </DialogForm>
  );
}
