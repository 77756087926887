import React from 'react';
import { Supplier } from 'model';
import {
  Chip,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UserDisplay } from '../../../users/components/UserDisplay';
import { formatDate } from '../../../../localized-formats';
import { useUser } from '../../../users/hooks/user.hook';
import { useSupplierCategory } from '../../hooks/supplier-category.hook';

interface SupplierBasicDisplayProps {
  value: Supplier;
}

export function SupplierBasicDisplay({ value }: SupplierBasicDisplayProps) {
  const { t } = useTranslation();
  const { user: supplierUser } = useUser(value.userId);
  const { supplierCategory } = useSupplierCategory(value.supplierCategoryId);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <div>
          <Typography>
            {value.name.first} {value.name.last}
          </Typography>
          <Typography component={Link} href={`mailto:${value.email}`}>
            {value.email}
          </Typography>
          {value.websites.length > 0 && (
            <List dense>
              {value.websites.map((site) => (
                <ListItem>
                  <ListItemText>
                    <Typography
                      component={Link}
                      href={site.startsWith('http') ? site : `https://${site}`}
                      target="_blank"
                    >
                      {site}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          )}
          {value.profession && (
            <div style={{ marginTop: 10 }}>
              <Typography variant="caption" color="textSecondary">
                Profession
              </Typography>
              <Typography>{value.profession}</Typography>
            </div>
          )}
        </div>
        <div>
          {value.firstContact && (
            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ marginBottom: 10 }}
            >
              {`${t('First Contact')}: ${formatDate(
                value.firstContact,
                'date'
              )}`}
            </Typography>
          )}
          {supplierUser && (
            <UserDisplay
              value={supplierUser}
              link={`/users/accounts/${supplierUser.id}`}
            />
          )}
          {/* {value.employee && <Chip label="Employee" size="small" />} */}
          <Chip label={supplierCategory?.name ?? ''} size="small" />
        </div>
      </div>
    </div>
  );
}
