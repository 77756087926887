import React, { useMemo } from 'react';
import { Skeleton } from '@material-ui/lab';
import { useSuppliers } from '../hooks/suppliers.hook';
import { SuppliersTable } from '../components/table/SuppliersTable';
import { useStatusTypes } from '../hooks/status-types.hook';

export function SuppliersScreen() {
  const { suppliers, apiState, listSuppliers } = useSuppliers();
  const { entities: statusTypes } = useStatusTypes();
  const initialStatusTypes = useMemo(
    () => statusTypes.filter((t) => t.statusType === 'active'),
    [statusTypes]
  );
  return initialStatusTypes.length > 0 ? (
    <SuppliersTable
      suppliers={suppliers}
      onRefresh={listSuppliers}
      apiState={apiState}
      initialStatusTypes={initialStatusTypes}
    />
  ) : (
    <Skeleton />
  );
}
