import { formatProjectNumber, Project } from 'model';
import { createEntityDocument } from '../../../../components/actions/DocumentLink';
import { projectAPI } from '../../redux/projects.slice';

export const { entityDocument: projectDocument, EntityLink: ProjectLink } =
  createEntityDocument(
    Project,
    'Project',
    projectAPI,
    (s) => `/projects/projects/${s.id}`,
    (s, format = 'name' as 'name' | 'number') =>
      // eslint-disable-next-line no-nested-ternary
      format === 'name'
        ? `${s.projectName}`
        : s.projectNumber
        ? formatProjectNumber(s.projectNumber)
        : ''
  );
