import React from 'react';
import { Button, Divider, IconButton } from '@material-ui/core';
import { Close as CancelIcon } from '@material-ui/icons';
import { UploadAccessPermission, UploadFile } from 'model';
import { useTranslation } from 'react-i18next';
import { CommentDisplay } from './CommentDisplay';
import { CommentInterface } from './types';
import { CommentEditor } from './CommentEditor';
import { createFileGrant } from '../../utils';

interface CommentListProps<T> {
  onCreateComment: (
    value: string,
    attachedFiles: (UploadFile & { grant: string })[]
  ) => Promise<void | T>;
  comments: CommentInterface[];
  filePermission: UploadAccessPermission<any, any, any>;
}

export function CommentList<T>(props: CommentListProps<T>) {
  const [showSubmitButton, setShowSubmitButton] = React.useState(false);

  const [commentValue, setCommentValue] = React.useState('');

  const { t } = useTranslation();
  const [attachedFiles, setAttachedFiles] = React.useState<
    (UploadFile & { grant: string })[] | null
  >([]);

  const buttonProps = showSubmitButton
    ? {
        disabled: attachedFiles === null,
        onClick: () => {
          props.onCreateComment(commentValue, attachedFiles as any);
          setCommentValue('');
          setShowSubmitButton(false);
        }
      }
    : { onClick: () => setShowSubmitButton(true) };

  return (
    <div style={{ height: '100%' }}>
      {showSubmitButton && (
        <div style={{ minHeight: '10%', paddingTop: 20, paddingBottom: 20 }}>
          <CommentEditor
            value={commentValue}
            onChange={setCommentValue}
            onFileChange={(attachedFiles1, done) =>
              setAttachedFiles(done ? attachedFiles1 : null)
            }
          />
        </div>
      )}
      <div style={{ textAlign: 'end' }}>
        {showSubmitButton && (
          <IconButton onClick={() => setShowSubmitButton(false)}>
            <CancelIcon />
          </IconButton>
        )}
        <Button {...buttonProps} variant="outlined" color="primary">
          {showSubmitButton ? t('Submit Comment') : t('Create Comment')}
        </Button>
      </div>
      <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '50%' }}>
        <div
          style={{
            paddingRight: 20,
            display: 'flex',
            flexDirection: 'column-reverse'
          }}
        >
          {props.comments.map((comment) => (
            <>
              <CommentDisplay
                comment={comment}
                createFileGrant={(af) =>
                  createFileGrant(af.id, props.filePermission, comment as any)
                    .token
                }
              />
              <Divider variant="fullWidth" style={{ margin: '10px 0' }} />
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
