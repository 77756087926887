import React, { PropsWithChildren } from 'react';
import { Box, InputBase } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useTypedField } from '../../../../transformer/DTOViewSchema';

export function OptionalDescriptionInput({
  children,
  descriptionDtoField,
  tooltip,
  right = true
}: PropsWithChildren<{
  descriptionDtoField: string;
  tooltip?: string;
  right?: boolean;
}>) {
  const [, descriptionMeta, descriptionHelpers] = useTypedField<string | null>(
    descriptionDtoField
  );
  const { t } = useTranslation();
  const [toggleDescription, setToggleDescription] = React.useState(false);

  return (
    <Box display="flex" width="100%">
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems={right ? 'flex-end' : 'unset'}
      >
        {children}
        {(toggleDescription || (descriptionMeta.value ?? '').trim() !== '') && (
          <InputBase
            multiline
            value={`${descriptionMeta.value ?? ''}`}
            onChange={(evt) => {
              descriptionHelpers.setValue(evt.target.value);
            }}
            margin="none"
            style={{
              width: '100%',
              fontSize: '1em'
            }}
            placeholder="Description"
            inputProps={{
              style: {
                padding: 0,
                textAlign: right ? 'right' : 'left',
                borderBottom: '1px dotted'
              }
            }}
          />
        )}
      </Box>
      <Box display="flex" alignItems="flex-end">
        <ToggleButton
          title={t(tooltip ?? 'Add comment')}
          disabled={(descriptionMeta.value ?? '').trim() !== ''}
          style={{ padding: 0 }}
          size="small"
          selected={toggleDescription}
          onChange={() => setToggleDescription(!toggleDescription)}
        >
          {toggleDescription ? (
            <ExpandLess fontSize="small" style={{ fontSize: '1em' }} />
          ) : (
            <ExpandMore fontSize="small" style={{ fontSize: '1em' }} />
          )}
        </ToggleButton>
      </Box>
    </Box>
  );
}

export function NameDescriptionInput({
  nameDtoField,
  descriptionDtoField
}: {
  nameDtoField: string;
  descriptionDtoField: string;
}) {
  const [, nameMeta, nameHelpers] = useTypedField<string>(nameDtoField);
  return (
    <OptionalDescriptionInput
      descriptionDtoField={descriptionDtoField}
      tooltip="Add description to position"
      right={false}
    >
      <InputBase
        value={`${nameMeta.value}`}
        onChange={(evt) => {
          nameHelpers.setValue(evt.target.value);
        }}
        margin="none"
        style={{
          width: '100%',
          fontSize: '1em'
        }}
        placeholder="Name"
        inputProps={{
          style: {
            padding: 0,
            textAlign: 'left',
            borderBottom: '1px dotted'
          }
        }}
      />
    </OptionalDescriptionInput>
  );
}
