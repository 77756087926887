import { OrderTask, TaskStateTransitionDTO, WrapOrderTaskDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const projectOrderTasksThunk = createFeatureThunks<
  OrderTask,
  WrapOrderTaskDTO,
  WrapOrderTaskDTO,
  { projectId: string }
>({
  urlFactory: ({ projectId, id }) =>
    `/projects/project/${projectId}/tasks${id ? `/${id}` : ''}`,
  thunkName: 'project-order-task'
});

export const createOrderSubTaskThunk = createErrorHandlingThunk<
  OrderTask,
  {
    projectId: string;
    parentId: string;
    input: WrapOrderTaskDTO;
    reload?: boolean;
  }
>(
  'project-order-task/create-sub-task',
  async ({ projectId, parentId, input, reload }, thunkAPI) => {
    const result = await apiClient.post(
      `/projects/project/${projectId}/tasks/${parentId}`,
      input
    );
    if (reload) thunkAPI.dispatch(projectOrderTasksThunk.list({ projectId }));
    return result.data;
  }
);

export const taskStateTransitionThunk = createErrorHandlingThunk<
  OrderTask,
  { projectId: string; input: TaskStateTransitionDTO; id: string }
>(
  'project-order-task/state-transition',
  async ({ projectId, input, id }, thunkAPI) => {
    const result = await apiClient.put(
      `/projects/project/${projectId}/tasks/${id}/state`,
      input
    );
    thunkAPI.dispatch(projectOrderTasksThunk.list({ projectId }));
    return result.data;
  }
);
