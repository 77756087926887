import React, { PropsWithChildren } from 'react';

import { useLocation } from 'react-router-dom';
import {
  PageAreaContext,
  PageAreaPathSpecifier,
  toIdentifiedPath,
  useDerivedPageArea,
  usePageArea
} from './page-area.context';
import { ErrorBoundary } from '../features/error/ErrorBoundary';

interface PageAreaBoundaryProps {
  specifier: PageAreaPathSpecifier;
  identifier?: string;
}

export function PageAreaBoundary(
  props: PropsWithChildren<PageAreaBoundaryProps>
) {
  const derivedArea = useDerivedPageArea(props.specifier, props.identifier);
  return (
    <PageAreaContext.Provider value={derivedArea}>
      <ErrorBoundary context={toIdentifiedPath(derivedArea)}>
        {props.children}
      </ErrorBoundary>
    </PageAreaContext.Provider>
  );
}

export function SubPageArea(
  props: PropsWithChildren<{
    type: string;
    specifier: string;
    // eslint-disable-next-line react/no-unused-prop-types
    identifier?: string;
  }>
) {
  const parentArea = usePageArea();
  const derivedSpecifier = React.useMemo(
    () => parentArea.specifier.deriveSpecifier(props.type, props.specifier),
    [props.type, props.specifier, parentArea.specifier]
  );
  return (
    <PageAreaBoundary specifier={derivedSpecifier}>
      {props.children}
    </PageAreaBoundary>
  );
}

export function FeaturePageArea(
  props: PropsWithChildren<{
    specifier: string;
    // eslint-disable-next-line react/no-unused-prop-types
    identifier?: string;
  }>
) {
  const parentArea = usePageArea();
  const location = useLocation();
  const subUrl =
    location.pathname
      .substr(props.specifier.length + 1)
      .split('/')
      .filter((s) => s.trim() !== '')[0] ?? 'main';

  const derivedSpecifier = React.useMemo(
    () =>
      parentArea.specifier.deriveSpecifier(
        'feature',
        `${props.specifier}-${subUrl}`
      ),
    [props.specifier, parentArea.specifier, subUrl]
  );
  return (
    <PageAreaBoundary specifier={derivedSpecifier}>
      {props.children}
    </PageAreaBoundary>
  );
}
