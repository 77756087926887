import React from 'react';
import {
  permissions,
  Project,
  ProjectPatchDTO,
  projectPatchTransformer
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { NewEditableCard } from '../../../../components/card/editableCard/NewEditableCard';
import { ProjectBasicDisplay } from './ProjectBasicDisplay';
import { ProjectBasicForm, ProjectBasicView } from './ProjectBasicForm';
import { NoOp } from '../../../../utils';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { projectThunk } from '../../redux/projects.thunk';

interface ProjectBasicCardProps {
  project: Project;
  title?: string;
}

export function ProjectBasicCard({
  project,
  title = 'Basic'
}: ProjectBasicCardProps) {
  const dispatch = useAppDispatch();

  const updateProject = (dto: ProjectPatchDTO) => {
    return dispatch(projectThunk.update({ id: project.id, input: dto }))
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <NewEditableCard
      identifier="project-basic"
      title={title}
      value={project}
      display={{ component: ProjectBasicDisplay }}
      form={{
        component: () => <ProjectBasicForm />,
        validationSchema: ProjectBasicView.validationSchema,
        dtoTransformer: projectPatchTransformer,
        viewFactory: ProjectBasicView.viewFactory,
        onSubmit: updateProject,
        permissions: permissions.project.project.edit
      }}
    />
  );
}
