import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { usersSelectors } from '../redux/users.slice';
import { userThunks } from '../redux/actions/users.thunk';

export function useUsers() {
  const dispatch = useAppDispatch();

  const users = useAppSelector(usersSelectors.selectAll);
  const apiState = useAppSelector(usersSelectors.selectState);

  const listUsers = () => dispatch(userThunks.list());

  React.useEffect(() => {
    listUsers();
  }, []);

  return { users, apiState, listUsers };
}
