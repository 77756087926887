import { Client, CreateClientDTO, PatchClientDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const clientsThunk = createFeatureThunks<
  Client,
  CreateClientDTO,
  PatchClientDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/client${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'clients'
});
