"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScalarOfferPosition = void 0;
const typeorm_1 = require("typeorm");
// eslint-disable-next-line import/no-cycle
const OfferPosition_1 = require("./OfferPosition");
const PriceUnit_1 = require("../../prices/entity/PriceUnit");
// eslint-disable-next-line import/no-cycle
const GroupOfferPosition_1 = require("./GroupOfferPosition");
let ScalarOfferPosition = class ScalarOfferPosition extends OfferPosition_1.OfferPosition {
};
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", Number)
], ScalarOfferPosition.prototype, "amount", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], ScalarOfferPosition.prototype, "amountDescription", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => PriceUnit_1.PriceUnit, { nullable: true }),
    __metadata("design:type", PriceUnit_1.PriceUnit)
], ScalarOfferPosition.prototype, "priceUnit", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sop) => sop.priceUnit),
    __metadata("design:type", String)
], ScalarOfferPosition.prototype, "priceUnitId", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'jsonb' }),
    __metadata("design:type", Object)
], ScalarOfferPosition.prototype, "unitPrice", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", String)
], ScalarOfferPosition.prototype, "unitPriceDescription", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => GroupOfferPosition_1.GroupOfferPosition, (p) => p.positions, {
        nullable: true
    }),
    __metadata("design:type", GroupOfferPosition_1.GroupOfferPosition)
], ScalarOfferPosition.prototype, "parentPosition", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sop) => sop.parentPosition),
    __metadata("design:type", String)
], ScalarOfferPosition.prototype, "parentPositionId", void 0);
ScalarOfferPosition = __decorate([
    (0, typeorm_1.ChildEntity)('scalar')
], ScalarOfferPosition);
exports.ScalarOfferPosition = ScalarOfferPosition;
