import React from 'react';
import { ResourceRequestDTO } from 'model';
import { IconButton, Typography } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { useTypedField } from '../../../../../../transformer/DTOViewSchema';
import { RequestCreatorView } from '../../request-creator.view';
import { RequestResourceForm } from './RequestResourceForm';

function RequestResourceAddButton() {
  const [, meta, helpers] = useTypedField<ResourceRequestDTO[]>(
    RequestCreatorView.path.resources
  );

  const addEmptyResource = () => {
    helpers.setValue([
      ...meta.value,
      {
        type: 'other',
        available: false,
        estimatedDelivery: null,
        name: '',
        description: '',
        attachedFiles: []
      }
    ]);
  };

  return (
    <IconButton size="small" onClick={addEmptyResource}>
      <Add fontSize="inherit" />
    </IconButton>
  );
}

export function RequestResourcesForm() {
  const [, meta, helpers] = useTypedField<ResourceRequestDTO[]>(
    RequestCreatorView.path.resources
  );

  const getRemoveResource = (index: number) => () =>
    helpers.setValue(meta.value.filter((_, i) => i !== index));

  return (
    <div>
      {meta.value.map((_, i) => (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              size="small"
              style={{ marginBottom: 1 }}
              onClick={getRemoveResource(i)}
            >
              <Delete fontSize="inherit" />
            </IconButton>
            <Typography variant="body2" color="textSecondary">
              Resource {i + 1}
            </Typography>
          </div>
          <div style={{ paddingLeft: 30, paddingTop: 10, paddingBottom: 30 }}>
            <RequestResourceForm
              basePath={RequestCreatorView.path.resources.at(i) as any}
            />
          </div>
        </>
      ))}
      <RequestResourceAddButton />
    </div>
  );
}
