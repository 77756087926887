"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findLanguageCode = exports.LanguageMap = exports.LanguageMaps = exports.LegacyCodeMap = exports.ShortLanguageMap = exports.LanguageDefinitions = exports.LongLanguageMap = void 0;
const lodash_1 = require("lodash");
const ISO3LangList_1 = require("./ISO3LangList");
const LangDefs_1 = require("./LangDefs");
const ISO5LangList_1 = require("./ISO5LangList");
const ISO3DeprecatedMap_1 = require("./ISO3DeprecatedMap");
const deprecationReverseMap = (0, lodash_1.groupBy)(Object.values(ISO3DeprecatedMap_1.ISO3DeprecatedMap).filter((i) => i.replacement !== undefined), (i) => i.replacement);
exports.LongLanguageMap = Object.fromEntries(Object.entries(ISO3LangList_1.ISO3Map)
    .map(([k, v]) => {
    var _a;
    const iso1 = v['iso-1'].trim();
    const iso2 = v['iso-2'].trim();
    const iso2b = v['iso-2-b'].trim();
    const iso3 = v['iso-3'].trim();
    const deprecated = ((_a = deprecationReverseMap[iso3]) !== null && _a !== void 0 ? _a : []).map((i) => i.retiredCode);
    return [
        k,
        {
            shortCode: iso1 !== '' ? iso1 : undefined,
            otherCodes: [
                iso2 !== '' ? iso2 : undefined,
                iso2b !== '' ? iso2b : undefined,
                ...deprecated
            ].filter((a) => a !== undefined),
            code: iso3,
            possibleCodes: [
                ...new Set([iso1, iso2, iso2b, iso3, ...deprecated].filter((a) => a !== ''))
            ],
            displayCode: iso1 !== '' ? iso1 : iso3,
            name: v.name,
            type: LangDefs_1.LangTypeMap[v.type],
            scope: LangDefs_1.LangScopeMap[v.scope],
            ...(v.scope === 'M'
                ? {
                    children: []
                }
                : {})
        }
    ];
})
    .concat(ISO5LangList_1.ISO5LangList.map((l) => [
    l.code,
    {
        code: l.code,
        name: l.name,
        parent: l.parent
            ? { code: l.parent }
            : null,
        possibleCodes: [l.code],
        otherCodes: [],
        displayCode: l.code,
        scope: 'group'
    }
])));
Object.values(exports.LongLanguageMap).forEach((v) => {
    if (v.scope === 'group') {
        if (v.parent) {
            // eslint-disable-next-line no-param-reassign
            v.parent = exports.LongLanguageMap[v.parent.code];
        }
    }
    else if (v.scope === 'macro') {
        // eslint-disable-next-line no-param-reassign
        v.children = ISO3LangList_1.ISO3Map[v.code].children.map((c) => exports.LongLanguageMap[c]);
    }
    else if (ISO3LangList_1.ISO3Map[v.code].parent !== null) {
        // eslint-disable-next-line no-param-reassign
        v.parent = exports.LongLanguageMap[ISO3LangList_1.ISO3Map[v.code].parent];
    }
});
exports.LanguageDefinitions = Object.values(exports.LongLanguageMap);
exports.ShortLanguageMap = Object.fromEntries(exports.LanguageDefinitions.filter((c) => c.shortCode !== undefined).map((v) => [
    v.shortCode,
    v
]));
exports.LegacyCodeMap = Object.fromEntries(exports.LanguageDefinitions.flatMap((v) => v.otherCodes.map((cod) => [cod, v])));
exports.LanguageMaps = {
    shortMap: exports.ShortLanguageMap,
    longMap: exports.LongLanguageMap,
    legacyMap: exports.LegacyCodeMap
};
exports.LanguageMap = {
    ...exports.LegacyCodeMap,
    ...exports.ShortLanguageMap,
    ...exports.LongLanguageMap
};
function findLanguageCode(code) {
    return exports.LanguageMap[code];
    // return Object.values(LanguageMaps)
    // .find((m) => m[code] !== undefined)?.[code];
}
exports.findLanguageCode = findLanguageCode;
