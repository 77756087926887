import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClientPriceDTO, patchClientPriceTransformer } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { NoOp } from '../../../../utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/redux.hooks';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import { clientPricesThunk } from '../../redux/actions/client-prices.thunk';
import { ClientPriceForm, clientPriceView } from './ClientPriceForm';
import clientPricesSlice, {
  clientPricesSelectors
} from '../../redux/client-prices.slice';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';

const initialValues: ClientPriceDTO = {
  priceUnitId: '',
  serviceClassId: null,
  price: {
    expressPrice: null,
    currency: null,
    price: null as unknown as number
  },
  sourceLanguageId: null,
  targetLanguageId: null,
  customConstraint: null,
  description: null
};
export function ClientPriceFormDialog({ clientId }: { clientId: string }) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const createPrice = (input: ClientPriceDTO) =>
    dispatch(clientPricesThunk.create({ clientId, input }))
      .then(unwrapResult)
      .catch(NoOp);

  const { type, reference } = useEditorLocation();
  const clientPrice = useAppSelector((state) =>
    clientPricesSelectors.selectById(state, reference ?? '')
  );

  const updatePrice = (input: ClientPriceDTO) =>
    dispatch(
      clientPricesThunk.update({
        clientId,
        id: clientPrice?.id || '',
        input
      })
    )
      .then(unwrapResult)
      .catch(NoOp);

  if (!reference || (type === 'edit' && !clientPrice)) {
    return <></>;
  }
  return (
    <DialogForm
      identifier={type === 'edit' ? reference : 'price'}
      label={type === 'edit' ? t('Edit Client Price') : t('New Client Price')}
      maxWidth="md"
      form={{
        initialValues:
          type === 'edit' && clientPrice
            ? patchClientPriceTransformer(clientPrice)
            : initialValues,
        validationSchema: clientPriceView.validationSchema
      }}
      api={{
        clearError: clientPricesSlice.actions.clearError,
        errorSelector: clientPricesSelectors.selectError,
        onSubmit: type === 'edit' && clientPrice ? updatePrice : createPrice,
        stateSelector: clientPricesSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <ClientPriceForm />
    </DialogForm>
  );
}
