import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { ColumnKey } from '../types';
import { useTableConfigContext } from './DataTableContext';

export function useTableColumn<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(columnKey: ColumnKey<T, C>) {
  const { tableCells } = useTableConfigContext<T, C>();
  const column = tableCells[columnKey];
  return React.useMemo(
    () => ({
      ...column,
      filterable: column.renderer.filter !== undefined,
      cellRenderer: column.renderer.createElement,
      filter: column.renderer.filter
    }),
    [column]
  );
}
