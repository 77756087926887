import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import {
  DocumentType,
  NormalTestType,
  ParserResultType,
  Qualification,
  supplierDataParser,
  SupplierImporterData,
  SupplierImportMappings,
  SupplierImportMappingsType,
  TestResultState,
  TestTypeState
} from 'model';
import { uniq, uniqBy, upperFirst } from 'lodash';
import { CSVParserView } from '../../../components/import/CSVParserView';
import { useLanguageIndex } from '../../languages/hooks/language-index.hook';
import { useServiceClasses } from '../../services/hooks/service-classes.hook';
import { useServiceSteps } from '../../services/hooks/service-steps.hook';
import { useTestTypes } from '../hooks/test-types.hook';
import { useSupplierQualifications } from '../hooks/supplier-qualification.hook';
import { useDocumentTypes } from '../hooks/document-types.hook';
import { useSupplierCategories } from '../hooks/supplier-categories.hook';
import { useLargeScaleProjects } from '../../projects/hooks/large-scale-projects.hook';
import { apiClient } from '../../../api/apiClient';
import { Log } from '../../../logger/logger';

const importTabs = {
  supplier: <div />
};
export function SupplierImportScreen() {
  const languageIndex = useLanguageIndex();
  const { entities: serviceClasses } = useServiceClasses();
  const { entities: serviceSteps } = useServiceSteps();
  const { entities: testTypes } = useTestTypes();
  const { supplierQualifications } = useSupplierQualifications();
  const { largeScaleProjects } = useLargeScaleProjects();
  const { entities: documentTypes } = useDocumentTypes();
  const { entities: categories } = useSupplierCategories();
  const importAction = ({
    result
  }: ParserResultType<ReturnType<typeof supplierDataParser>>) => {
    if (!result) {
      return;
    }

    const testMap = Object.fromEntries(
      Object.entries(SupplierImportMappings.tests).map(([name, def]) => {
        const testType = testTypes.find(
          (l) => l.name.toLowerCase().trim() === def.toLowerCase().trim()
        );
        if (testType === undefined) {
          throw new Error(`Cannot find test ${def}`);
        }
        if (testType.testVariant === 'simple') {
          return [name, { testType, stateMap: {} as any }] as [
            string,
            { testType: typeof testType; stateMap: typeof stateMap }
          ];
        }
        const stateMap = Object.fromEntries(
          Object.entries(SupplierImportMappings.testStates).map(
            ([stateName, stateDef]) => {
              const status = (testType as NormalTestType).testStates.find(
                (ts) => ts.name.toLowerCase().trim() === stateDef.state
              );
              if (status === undefined) {
                throw new Error(
                  `Cannot find teststate ${stateDef.state} for test ${def}`
                );
              }
              const resultState =
                stateDef.resultState !== null
                  ? (testType as NormalTestType).resultStates.find(
                      (rs) =>
                        rs.name.toLowerCase() ===
                        stateDef?.resultState?.toLowerCase()
                    )
                  : null;
              if (resultState === undefined) {
                throw new Error(
                  // eslint-disable-next-line max-len
                  `Cannot find resultstate ${stateDef.resultState} for test ${def}`
                );
              }
              return [stateName, { testState: status, resultState }];
            }
          )
        ) as Record<
          keyof SupplierImportMappingsType['testStates'],
          { testState: TestTypeState; resultState: TestResultState | null }
        >;
        return [name, { testType, stateMap }] as [
          string,
          { testType: typeof testType; stateMap: typeof stateMap }
        ];
      })
    );
    const lspMapping = Object.fromEntries(
      Object.entries(SupplierImportMappings.lsps).map(([name, def]) => {
        const lspId = largeScaleProjects.find(
          (l) => l.name.toLowerCase().trim() === def.toLowerCase().trim()
        )?.id;
        if (lspId === undefined) {
          throw new Error(`Cannot find lsp ${def}`);
        }
        return [name, lspId];
      })
    ) as Record<keyof SupplierImportMappingsType['lsps'], string>;
    const docTypeMap = Object.fromEntries(
      Object.entries(SupplierImportMappings.documents).map(([name, def]) => {
        const docType = documentTypes.find(
          (dt) => dt.name.toLowerCase().trim() === def.toLowerCase().trim()
        );
        if (docType === undefined) {
          throw new Error(`Cannot find document type ${def}`);
        }
        return [name, docType];
      })
    ) as { [k in keyof SupplierImportMappingsType['documents']]: DocumentType };
    const qualificationMap = Object.fromEntries(
      Object.entries(SupplierImportMappings.qualifications).map(
        ([name, def]) => {
          const quali = supplierQualifications.find(
            (q) => q.name.toLowerCase().trim() === def.toLowerCase().trim()
          );
          if (quali === undefined) {
            throw new Error(`Cannot find qualification ${def}`);
          }
          return [name, quali];
        }
      )
    ) as {
      [k in keyof SupplierImportMappingsType['qualifications']]: Qualification;
    };
    const categoryMap = Object.fromEntries(
      Object.entries(SupplierImportMappings.categories).map(([name, def]) => {
        const id = categories.find(
          (c) => c.name.toLowerCase().trim() === def.toLowerCase().trim()
        )?.id;
        if (id === undefined) {
          throw new Error(`Cannot find category ${def}`);
        }
        return [name, id];
      })
    ) as Record<keyof SupplierImportMappingsType['categories'], string>;
    const roleMap = Object.fromEntries(
      Object.entries(SupplierImportMappings.roles).map(([name, def]) => {
        return [
          name,
          def.map((i) => {
            const id =
              i.type === 'service'
                ? serviceClasses.find(
                    (sc) =>
                      sc.name.toLowerCase().trim() ===
                      i.name.toLowerCase().trim()
                  )?.id
                : serviceSteps.find(
                    (step) =>
                      step.name.toLowerCase().trim() ===
                      i.name.toLowerCase().trim()
                  )?.id;
            if (id === undefined) {
              throw new Error(`Cannot find ${i.type} ${i.name}`);
            }
            return { type: i.type, id };
          })
        ];
      })
    ) as Record<
      string,
      {
        type: SupplierImportMappingsType['roles'][string][number]['type'];
        id: string;
      }[]
    >;

    const dtos = result.map((rowResult): SupplierImporterData => {
      const roleMappings = rowResult.roles.flatMap(
        (role) => roleMap[role.toLowerCase().trim()]
      );
      if (roleMappings.some((m) => m === undefined)) {
        throw new Error(
          `Cannot find role mappings: ${rowResult.roles.join(', ')}`
        );
      }
      const uniqMappings = uniqBy(
        roleMappings.flat(),
        (r) => `${r.type}-${r.id}`
      );

      const services = uniqMappings
        .filter((m) => m.type === 'service')
        .map((m) => m.id);

      const steps = roleMappings
        .flat()
        .filter((m) => m.type === 'step')
        .map((m) => m.id);

      if (rowResult.agency && rowResult.employee) {
        throw new Error('Agency and employee true');
      }

      const category =
        categoryMap[
          // eslint-disable-next-line no-nested-ternary
          rowResult.employee
            ? 'employee'
            : rowResult.agency
            ? 'agency'
            : 'freelancer'
        ];

      const lsps: SupplierImporterData['lsps'] = [];
      if (rowResult.ebayCS && rowResult.ebayCSHours !== null) {
        lsps.push({ projectId: lspMapping.cs, hours: rowResult.ebayCSHours });
      }
      if (
        rowResult.ebaySiteContent &&
        rowResult.ebaySiteContentHours !== null
      ) {
        lsps.push({
          projectId: lspMapping['site-content'],
          hours: rowResult.ebaySiteContentHours
        });
      }
      const languages: SupplierImporterData['languages'] = uniq(
        [...rowResult.nativeCodes, ...rowResult.languageCodes].map((c) =>
          c.toLowerCase().trim()
        )
      ).map((r) => ({
        languageId: languageIndex.codeMap[r].language.id,
        native: rowResult.nativeCodes.some(
          (nc) => nc.toLowerCase().trim() === r
        )
      }));

      const documents: SupplierImporterData['documents'] = [];
      if (rowResult.nda !== 'no') {
        documents.push({
          documentId: docTypeMap.nda.id,
          documentState: docTypeMap.nda.states.find(
            (state) =>
              state.name.toLowerCase() ===
              (rowResult.nda.toLowerCase() === 'yes' ? 'yes' : 'yes contract')
          )?.id as string,
          comment: null
        });
      }
      if (rowResult.taxId) {
        documents.push({
          documentId: docTypeMap.tax.id,
          documentState: docTypeMap.tax.states.find((state) => state.completed)
            ?.id as string,
          comment: null
        });
      }
      if (rowResult.cv) {
        documents.push({
          documentId: docTypeMap.cv.id,
          documentState: docTypeMap.cv.states.find((state) => state.completed)
            ?.id as string,
          comment:
            rowResult.cvFile !== null || rowResult.cvDate !== null
              ? `${rowResult.cvFile ?? ''} ${rowResult.cvDate ?? ''}`
              : null
        });
      }

      const qualifications: SupplierImporterData['qualifications'] = [];
      if (rowResult.graduatedTranslator) {
        qualifications.push({
          qualificationId: qualificationMap.graduated.id,
          details: ''
        });
      }
      if (rowResult.universityDegree) {
        qualifications.push({
          qualificationId: qualificationMap.degree.id,
          details: ''
        });
      }
      if (rowResult.certifiedTranslator) {
        qualifications.push({
          qualificationId: qualificationMap.certified.id,
          details: ''
        });
      }
      if (rowResult.swornTranslator) {
        qualifications.push({
          qualificationId: qualificationMap.sworn.id,
          details: rowResult.swornCountry ?? ''
        });
      }

      const tests: SupplierImporterData['tests'] = rowResult.testNames
        .filter((tN) => tN !== '')
        .map((tN, tNI) => {
          const test = testMap[tN];
          if (test === undefined) {
            // console.log('test name', tN);
          }
          const testState =
            test.stateMap[
              rowResult.testStates[
                tNI
              ].toLowerCase() as keyof (typeof test)['stateMap']
            ];
          if (test.testType?.testVariant === 'simple') {
            return {
              id: test.testType.id,
              state: null,
              testerId: rowResult.testerIds[tNI],
              result: null
            };
          }
          return {
            id: test.testType?.id,
            state: testState.testState.id,
            testerId: rowResult.testerIds[tNI],
            result:
              testState.resultState !== null
                ? {
                    resultState: testState.resultState.id,
                    result: rowResult.testResults[tNI] || null
                  }
                : null
          };
        });
      // eslint-disable-next-line max-len
      const interpreting: SupplierImporterData['interpreting'] =
        rowResult.interpretingSourceLangCodes.map((sl, sli) => ({
          source: languageIndex.codeMap[sl.toLowerCase()].language.id,
          target:
            languageIndex.codeMap[
              rowResult.interpretingTargetLangCodes[sli].toLowerCase()
            ].language.id
        }));
      return {
        domains: rowResult.domains.filter((d) => d.trim() !== ''),
        trados: rowResult.trados,
        importId: rowResult.importId,
        name: rowResult.name,
        surname: rowResult.surname,
        lsps,
        comment: rowResult.comment,
        street: `${rowResult.street ?? ''} ${
          rowResult.houseNumber ?? ''
        }`.trim(),
        zipCode: rowResult.zipCode,
        city: rowResult.city,
        countryCode: rowResult.country.toUpperCase(),
        mobile: rowResult.mobile,
        phone: rowResult.phone,
        email: rowResult.email,
        website: rowResult.website,
        profession: rowResult.profession,
        pricePerHour: rowResult.pricePerHour,
        pricePerReview: rowResult.pricePerReview,
        pricePerWord: rowResult.pricePerWord,
        priceComments: rowResult.priceComments,
        tests,
        firstContact: rowResult.firstContact,
        qualifications,
        active: rowResult.active,
        category,
        documents,
        languages,
        services,
        steps,
        interpreting
      };
    });
    apiClient
      .post('/suppliers/supplier/import', { suppliers: dtos })
      .then((res) => {
        return res;
      })
      .catch(() => {});
  };

  const [tab, setTab] = React.useState<keyof typeof importTabs>('supplier');
  return (
    <>
      <Tabs value={tab} onChange={(_, value) => setTab(value)}>
        {Object.keys(importTabs).map((k) => (
          <Tab value={k} key={k} label={upperFirst(k)} />
        ))}
      </Tabs>
      <div>
        <CSVParserView
          importAction={(a) => {
            try {
              importAction(a);
            } catch (e: any) {
              Log.warn('error', e);
            }
          }}
          headerRows={4}
          key="supplier"
          parser={supplierDataParser(
            (c) => languageIndex.codeMap[c.toLowerCase()]?.language?.id
          )}
        />
      </div>
    </>
  );
}
