import { Client } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const loadClientThunk = createErrorHandlingThunk<Client, { id: string }>(
  'clients/load',
  async ({ id }) => {
    const result = await apiClient.get<Client>(`/client/${id}`);
    return result.data;
  }
);
