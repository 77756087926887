import * as yup from 'yup';
import {
  Supplier,
  SupplierDocumentAttachment,
  DocumentType,
  SupplierTestAttachment,
  SupplierAttachment,
  SupplierPrice
} from 'model';
import React from 'react';

import { useDocumentTypes } from '../../hooks/document-types.hook';
import { useAttachmentsOfSupplier } from '../../hooks/supplier-attachments.hook';
import { usePricesOfSupplier } from '../../hooks/supplier-prices.hook';

const supplierActivationSchema = yup.object({
  name: yup
    .object({
      first: yup.string().required(),
      last: yup.string().required()
    })
    .required(),
  email: yup.string().email().required()
});

function validateCustomConstraints(
  supplier: Supplier,
  documentTypes: DocumentType[],
  attachments: SupplierAttachment[],
  prices: SupplierPrice[]
) {
  const errors: yup.ValidationError[] = [];

  if (supplier.addresses.length < 1) {
    errors.push(new yup.ValidationError('Supplier needs at least one address'));
  }

  if (supplier.services.length < 1) {
    errors.push(new yup.ValidationError('Supplier needs at least one service'));
  }

  const supplierDocuments = attachments.filter(
    (a) =>
      a.attachmentType === 'document' &&
      (a as SupplierDocumentAttachment).documentState.completed
  );

  const neededDocuments = documentTypes
    .filter((dT) => dT.activationConstraint)
    .filter(
      (dT) =>
        supplierDocuments.find(
          (sT) => (sT as SupplierDocumentAttachment).documentTypeId === dT.id
        ) === undefined
    );

  if (neededDocuments.length > 0) {
    errors.push(
      ...neededDocuments.map(
        (nD) => new yup.ValidationError(`Supplier is missing ${nD.name}`)
      )
    );
  }

  const validTests = attachments.filter(
    (a) =>
      a.attachmentType === 'test' &&
      ((a as SupplierTestAttachment).testType.testVariant === 'simple' ||
        (a as SupplierTestAttachment).result?.resultState?.passed)
  );

  if (validTests.length < 1) {
    errors.push(new yup.ValidationError('Supplier has not passed any test'));
  }

  if (prices.length < 1) {
    errors.push(new yup.ValidationError('Supplier has no prices yet'));
  }

  if (errors.length > 0) {
    throw new yup.ValidationError(errors);
  }
}

const validateSupplierActivationSchema = (
  supplier: Supplier,
  documentTypes: DocumentType[],
  attachments: SupplierAttachment[],
  prices: SupplierPrice[]
): string[] => {
  try {
    validateCustomConstraints(supplier, documentTypes, attachments, prices);

    supplierActivationSchema.validateSync(supplier, {
      abortEarly: false
    });
    return [];
  } catch (e) {
    const validationError: yup.ValidationError = e as yup.ValidationError;
    return validationError.errors;
  }
};

export function useSupplierActivation(supplier: Supplier) {
  const [activationErrors, setActivationErrors] = React.useState<string[]>([]);
  const { entities: documentTypes } = useDocumentTypes();
  const { attachments } = useAttachmentsOfSupplier(supplier.id);
  const { prices } = usePricesOfSupplier(supplier.id);
  React.useEffect(() => {
    if (documentTypes && attachments) {
      setActivationErrors(
        validateSupplierActivationSchema(
          supplier,
          documentTypes,
          attachments,
          prices
        )
      );
    }
  }, [documentTypes, attachments]);

  return activationErrors;
}
