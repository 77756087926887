import { BaseEntity } from 'model';
import { EntityId } from '@reduxjs/toolkit';
import React, { ReactNode } from 'react';
import { StyledLink } from '../../input/link/StyledLink';

export type EntityReferenceUrlFactory<T extends BaseEntity> = (
  e: T['id']
) => string;

export const EntityReferenceUrlFactories = {
  accounts: (a: EntityId) => `/users/accounts/${a}`
};

interface ReferenceLinkProps<T extends BaseEntity> {
  refUrl:
    | EntityReferenceUrlFactory<T>
    | keyof typeof EntityReferenceUrlFactories;
  display: ((e: T) => ReactNode) | keyof T | JSX.Element;
  value: T;
}
export function ReferenceLink<T extends BaseEntity>({
  refUrl,
  value,
  display
}: ReferenceLinkProps<T>) {
  const url =
    typeof refUrl === 'string'
      ? EntityReferenceUrlFactories[refUrl](value.id)
      : refUrl(value.id);
  const displayString = (() => {
    switch (typeof display) {
      case 'function': {
        return display(value);
      }
      case 'string':
        return value[display];
      default:
        return display;
    }
  })();
  return <StyledLink to={url}>{displayString}</StyledLink>;
}
