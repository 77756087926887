import * as yup from 'yup';
import { DocumentTypeStateDTO, UpdateDocumentTypeDTO } from 'model';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import {
  buildDTOView,
  PropertyPath,
  useTypedField
} from '../../../transformer/DTOViewSchema';
import { EditableCheckboxDisplay } from '../../../components/input/checkbox/EditableCheckboxDisplay';
import ColorPicker, {
  cssRGBA,
  fromCSSRGBA
} from '../../../components/color/ColorPicker';
import { FormikTextField } from '../../../components/input/FormikTextField';

export const DocumentTypeBasicView = buildDTOView<UpdateDocumentTypeDTO>()
  .all()
  .withSchema(
    yup
      .object({
        name: yup
          .string()
          .required('required')
          .max(255, 'Max. 255 characters')
          .min(2, 'At least 2 characters'),
        description: yup
          .string()
          .max(255, 'Max. 255 characters')
          .defined('required'),
        activationConstraint: yup.boolean().required(),
        states: yup
          .array()
          .required()
          .of(
            yup
              .object({
                name: yup
                  .string()
                  .max(255, 'Max. 255 characters')
                  .required('required'),
                color: yup.string().max(255, 'Max. 255 characters').required(),
                completed: yup.boolean().required()
              })
              .defined()
          ) as any
      })
      .defined()
  );

function DocumentTypeStateView({
  path
}: {
  path: PropertyPath<any, DocumentTypeStateDTO>;
}) {
  const [, meta, helpers] = useTypedField(path.color);
  return (
    <Grid container>
      <Grid item xs={1} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <ColorPicker
          value={fromCSSRGBA(meta.value)}
          onValueChange={(color) => helpers.setValue(cssRGBA(color))}
        />
      </Grid>
      <Grid item xs={5}>
        <FormikTextField required name={path.name} label="Name" />
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center'
        }}
      >
        <EditableCheckboxDisplay
          name={path.completed}
          label="Completed"
          labelPlacement="end"
        />
      </Grid>
    </Grid>
  );
}

export function DocumentTypeBasicForm() {
  const { t } = useTranslation();

  const [, meta, helpers] = useTypedField(DocumentTypeBasicView.path.states);
  const addEmptyState = () => {
    helpers.setValue([
      ...meta.value,
      {
        name: '',
        color: 'rgba(100,100,100,1)',
        completed: false
      }
    ]);
  };

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item xs={12}>
        <EditableCheckboxDisplay
          name={DocumentTypeBasicView.path.activationConstraint}
          label={t('Activation Constraint')}
          labelPlacement="end"
        />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField
          required
          name={DocumentTypeBasicView.path.name}
          label="Name"
        />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField
          required
          label={t('Description')}
          name={DocumentTypeBasicView.path.description}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        {meta.value.length > 0 && (
          <Typography style={{ marginTop: 20 }} color="textSecondary">
            {t('States')}
          </Typography>
        )}
        <div style={{ paddingLeft: 20 }}>
          {meta.value.map((_, sI) => {
            return (
              <DocumentTypeStateView
                path={DocumentTypeBasicView.path.states.at(sI)}
              />
            );
          })}
        </div>
        <Grid item xs={12} style={{ textAlign: 'end' }}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => addEmptyState()}
          >
            Add state
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
