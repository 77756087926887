import React from 'react';
import { SupplierQualification } from 'model';
import { Box, Chip, List, ListItem, Typography } from '@material-ui/core';
import { useSupplierQualifications } from '../../../hooks/supplier-qualification.hook';
import { ErrorBoundary } from '../../../../error/ErrorBoundary';

interface SupplierQualificationsDisplayProps {
  supplierQualifications: SupplierQualification[];
}

export function SupplierQualificationsDisplay({
  supplierQualifications
}: SupplierQualificationsDisplayProps) {
  const qualifications = useSupplierQualifications();

  return (
    <ErrorBoundary context="SupplierQualificationsDisplays">
      <List dense>
        <ListItem dense style={{ display: 'flex', flexWrap: 'wrap' }}>
          {qualifications.supplierQualifications
            .filter(
              (q) =>
                supplierQualifications.find(
                  (sq) => sq.qualificationId === q.id
                ) !== undefined
            )
            .map((q) => {
              const suppQual = supplierQualifications.find(
                (sq) => sq.qualificationId === q.id
              ) as SupplierQualification;
              return (
                <Chip
                  style={{ margin: 1 }}
                  label={
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography variant="inherit">{q.name}</Typography>
                      <Typography variant="inherit" color="textSecondary">
                        {suppQual.details}
                      </Typography>
                    </Box>
                  }
                />
              );
            })}
        </ListItem>
      </List>
    </ErrorBoundary>
  );
}
