import React from 'react';
import { useSupplierDomains } from '../hooks/supplier-domains.hook';
import { SupplierDomainsTable } from '../components/SupplierDomainsTable';

export function SupplierDomainsScreen() {
  const { supplierDomains, apiState, listSupplierDomains } =
    useSupplierDomains();
  return (
    <SupplierDomainsTable
      supplierDomains={supplierDomains}
      onRefresh={listSupplierDomains}
      apiState={apiState}
    />
  );
}
