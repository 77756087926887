import React from 'react';
import { Client, ProjectOffer, requestCommentPermission } from 'model';
import { Button, Collapse, Divider, Grid, makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OfferDocumentDisplay } from './offerDocument/OfferDocumentDisplay';
import { OfferDisplayHeader } from './OfferDisplayHeader';
import { useEditorLocation } from '../../../../../hooks/editor-location.hook';
import { EditCurrentOfferDocumentForm } from './offerDocument/EditCurrentOfferDocumentForm';
import { useCommentsOfRequest } from '../../../hooks/request-comments.hook';
import { CommentList } from '../../../../../components/comment/CommentList';
import { createOfferCommentThunk } from '../../../redux/project-offers.thunk';
import { useAppDispatch } from '../../../../../redux/redux.hooks';

interface OfferDisplayProps {
  projectOffer?: ProjectOffer;
  isCurrent?: boolean;
  client: Client;
  offerNr: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    acceptButton: {
      color: theme.palette.success.dark,
      borderColor: theme.palette.success.light
    },
    declineButton: {
      marginLeft: 10,
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main
    }
  })
);

export function OfferDisplay({
  projectOffer,
  isCurrent,
  client,
  offerNr
}: OfferDisplayProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { url } = useRouteMatch();
  const [expanded, setExpanded] = React.useState(isCurrent || false);
  const { comments } = useCommentsOfRequest(projectOffer?.requestId || '');
  const offerComments = comments.filter((c) => c.offerId === projectOffer?.id);
  const { type } = useEditorLocation();

  const { t } = useTranslation();
  return (
    <>
      <div>
        <OfferDisplayHeader
          isCurrent={isCurrent}
          expanded={expanded}
          onToggleExpanded={() => setExpanded((p) => !p)}
          offer={projectOffer}
        />
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        <Collapse in={expanded}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              {type === 'edit' && isCurrent ? (
                <EditCurrentOfferDocumentForm
                  client={client}
                  offerNr={offerNr}
                  offer={projectOffer}
                />
              ) : (
                <>
                  <OfferDocumentDisplay
                    isCurrent={isCurrent}
                    offerNr={offerNr}
                    client={client}
                    offer={projectOffer}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={5}>
              {isCurrent && !projectOffer?.orderId && (
                <div>
                  <Button
                    component={RouterLink}
                    className={classes.acceptButton}
                    size="small"
                    variant="outlined"
                    // eslint-disable-next-line max-len
                    to={`${url}?offerId=${projectOffer?.id}&type=edit#accept-offer`}
                  >
                    {t('Accepted')}
                  </Button>
                  <Button
                    component={RouterLink}
                    className={classes.declineButton}
                    size="small"
                    variant="outlined"
                    color="primary"
                    // eslint-disable-next-line max-len
                    to={`${url}?offerId=${projectOffer?.id}&type=edit#reject-offer`}
                  >
                    {t('Rejected')}
                  </Button>
                </div>
              )}
              <CommentList
                onCreateComment={(value, attachedFiles) =>
                  dispatch(
                    createOfferCommentThunk({
                      requestId: projectOffer?.requestId as string,
                      offerId: projectOffer?.id as string,
                      input: {
                        comment: value,
                        attachedFiles: attachedFiles.map((f) => ({
                          fileAccessId: f.id,
                          token: f.grant
                        }))
                      }
                    })
                  )
                }
                comments={offerComments}
                filePermission={requestCommentPermission}
              />
            </Grid>
          </Grid>
        </Collapse>
      </div>
    </>
  );
}
