import { InitialDataAggregation, SupplierStatusType } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import {
  changeStatusTypeDefaultThunk,
  supplierStatusTypesThunk
} from './status-type.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { fetchInitialDataThunk } from '../../../redux/initial-data.thunk';

const { list, delete: deleteThunk, ...thunks } = supplierStatusTypesThunk;

const supplierStatusTypesSlice = createEntitySlice<SupplierStatusType>(
  'status-types',
  [
    CustomThunk.override<SupplierStatusType>(list, {
      pending: (_, state) => {
        state.state = 'pending';
        state.listFetched = undefined;
        state.error = null;
        return state;
      },
      rejected: (_, state, action) => {
        state.state = 'idle';
        state.listFetched = false;
        state.error = (action as any).error;
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        state.listFetched = true;
        return adapter.upsertMany(state, payload as SupplierStatusType[]);
      }
    }),
    CustomThunk.override<any>(fetchInitialDataThunk, {
      pending: (_, state) => {
        return state;
      },
      rejected: (_, state) => {
        return state;
      },
      fulfilled: (adapter, state, { payload }) => {
        const dataPayload = payload as InitialDataAggregation;
        if (dataPayload.supplierStatusTypes) {
          state.state = state.state === 'fresh' ? 'idle' : state.state;
          state.listFetched = true;
          return adapter.upsertMany(state, dataPayload.supplierStatusTypes);
        }
        return state;
      }
    }),
    CustomThunk.override<SupplierStatusType>(changeStatusTypeDefaultThunk, {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.upsertMany(state, payload as SupplierStatusType[]);
      }
    }),
    CustomThunk.override<SupplierStatusType>(
      deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
      {
        fulfilled: (adapter, state, { payload }) => {
          state.state = 'idle';
          return adapter.removeOne(state, payload as string);
        }
      }
    ),
    ...Object.values(thunks)
  ]
);
export const supplierStatusTypesSelectors = createAdapterSelectors(
  'status-types',
  supplierStatusTypesSlice.adapter
);

export const statusTypeAPI: EntityAPI<SupplierStatusType> = {
  selectors: supplierStatusTypesSelectors,
  thunks: supplierStatusTypesThunk
};
export default supplierStatusTypesSlice;
