import {
  AsyncThunk,
  createEntityAdapter,
  createSlice,
  Draft,
  EntityAdapter,
  EntityId,
  EntitySelectors,
  EntityState,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
  SerializedError
} from '@reduxjs/toolkit';
import { BaseEntity } from 'model';
import {
  ApiAdapterState,
  ApiState,
  getInitialApiAdapterState
} from '../features/auth/redux/types';
import { RootState } from './store';

type WritableDraft<T> = { -readonly [K in keyof T]: Draft<T[K]> };

type AssumeNonEmptyArray<A> = [first: A, ...rest: A[]];

type ThunkCases = 'pending' | 'fulfilled' | 'rejected';

type ThunkMatcherOverride<
  T extends BaseEntity,
  S extends BaseEntity = T
> = Partial<
  Record<
    ThunkCases,
    | undefined
    | null
    | ((
        adapter: EntityAdapter<S>,
        state: WritableDraft<EntityState<S> & ApiAdapterState>,
        action: PayloadAction<T | T[] | string>
      ) => EntityState<S> & ApiAdapterState)
  >
>;

export class CustomThunk<T extends BaseEntity, S extends BaseEntity = T> {
  thunk:
    | AsyncThunk<T, any, any>
    | AsyncThunk<T[], any, any>
    | AsyncThunk<EntityId, any, any>;

  overrides: ThunkMatcherOverride<T, S>;

  constructor(
    thunk:
      | AsyncThunk<T, any, any>
      | AsyncThunk<T[], any, any>
      | AsyncThunk<EntityId, any, any>,
    overrides: ThunkMatcherOverride<T, S>
  ) {
    this.thunk = thunk;
    this.overrides = overrides;
  }

  static override<C extends BaseEntity, S1 extends BaseEntity = C>(
    thunk:
      | AsyncThunk<C, any, any>
      | AsyncThunk<C[], any, any>
      | AsyncThunk<EntityId, any, any>,
    map: ThunkMatcherOverride<C, S1>
  ) {
    return new CustomThunk(thunk, map);
  }
}

export function createEntitySlice<T extends BaseEntity>(
  name: string,
  thunks: (AsyncThunk<T, any, any> | CustomThunk<T>)[]
) {
  const adapter = createEntityAdapter<T>();
  const slice = createSlice({
    name,
    initialState: adapter.getInitialState(getInitialApiAdapterState()),
    reducers: {
      clearError(state) {
        state.error = null;
      }
    },
    extraReducers: (builder) => {
      const mapMatches = (key: ThunkCases) =>
        thunks
          .map((t) => {
            if (t instanceof CustomThunk) {
              if (t.overrides[key] === undefined) {
                return t.thunk;
              }
            } else {
              return t;
            }
            return undefined as unknown as AsyncThunk<T, any, any>;
          })
          .filter((t) => t !== undefined) as AssumeNonEmptyArray<
          AsyncThunk<T, any, any>
        >;
      const fulfilledMatches = mapMatches('fulfilled');
      const rejectedMatches = mapMatches('rejected');
      const pendingMatches = mapMatches('pending');
      let enhancedBuilder = builder;
      enhancedBuilder = thunks.reduce((b, t) => {
        if (t instanceof CustomThunk) {
          let b1 = b;
          Object.entries(t.overrides).forEach(([c, action]) => {
            if (action) {
              b1 = b1.addCase(t.thunk[c as ThunkCases], ((
                s: WritableDraft<EntityState<T> & ApiAdapterState>,
                m: PayloadAction<T>
              ) => action(adapter, s, m)) as any);
            }
          });
          return b1;
        }
        return b;
      }, enhancedBuilder);
      if (fulfilledMatches.length) {
        enhancedBuilder = enhancedBuilder.addMatcher(
          isFulfilled(...fulfilledMatches),
          (state, { payload }) => {
            state.state = 'idle';
            return adapter.upsertOne(state as any, payload);
          }
        ) as any;
      }
      if (rejectedMatches.length) {
        enhancedBuilder = enhancedBuilder.addMatcher(
          isRejected(...rejectedMatches),
          (state, action) => {
            const error = action.error as SerializedError;
            if (error.code === '403') {
              state.state = 'unauthorized';
            } else {
              state.state = 'idle';
            }

            state.error = error;
          }
        ) as any;
      }
      if (pendingMatches.length) {
        enhancedBuilder = enhancedBuilder.addMatcher(
          isPending(...pendingMatches),
          (state) => {
            state.state = 'pending';
            state.error = null;
          }
        ) as any;
      }
      return enhancedBuilder;
    }
  });
  return {
    adapter,
    reducer: slice.reducer,
    actions: slice.actions
  };
}

export type AdapterSelectors<T extends BaseEntity> = EntitySelectors<
  T,
  RootState
> & {
  selectError: (state: RootState) => SerializedError | null;
  selectState: (state: RootState) => ApiState;
  selectListFetched: (state: RootState) => boolean | undefined;
  selectSubQuery: (
    type: string
  ) => (state: RootState) => 'fresh' | 'pending' | 'fetched' | undefined;
};
export function createAdapterSelectors<
  T extends BaseEntity,
  K extends keyof RootState
>(name: K, adapter: EntityAdapter<T>): AdapterSelectors<T> {
  return {
    ...(adapter.getSelectors(
      (state: RootState) => state[name] as unknown as any
    ) as EntitySelectors<T, RootState>),
    selectError: (state: RootState) => state[name].error,
    selectState: (state: RootState) => state[name].state as ApiState,
    selectListFetched: (state: RootState) => (state[name] as any).listFetched,
    selectSubQuery: (type: string) => (state: RootState) => {
      return (state[name] as any).subQueries?.[type];
    }
  };
}
