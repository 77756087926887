import React from 'react';
import { useClientProjects } from '../../../projects/hooks/client-project.hook';
import { ProjectTable } from '../../../projects/components/table/ProjectsTable';
import { ProjectFormDialog } from '../../../projects/components/ProjectFormDialog';
import { ClientLsps } from './ClientLsps';

export function ClientProjects(props: { clientId: string }) {
  const { clientProjects, apiState, listClientProjects } = useClientProjects(
    props.clientId
  );
  return (
    <>
      <ProjectFormDialog />
      <ClientLsps clientId={props.clientId} />
      <ProjectTable
        projects={clientProjects}
        onRefresh={() => listClientProjects(props.clientId)}
        apiState={apiState}
        withoutClient
      />
    </>
  );
}
