import React from 'react';
import {
  Box,
  ButtonBase,
  ListSubheader,
  Menu,
  MenuItem
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ArrowDropDown } from '@material-ui/icons';
import { usePriceUnits } from '../../../../prices/hooks/price-units.hook';
import { useTypedField } from '../../../../../transformer/DTOViewSchema';

export function PriceUnitSelect({ dtoField }: { dtoField: string }) {
  const [, meta, helpers] = useTypedField<string | null>(dtoField);
  const { entities: priceUnits } = usePriceUnits();
  const [showSelect, setShowSelect] = React.useState<Element>();
  const boxRef = React.useRef<HTMLButtonElement | null>(null);
  const currentUnit = React.useMemo(() => {
    return meta.value !== null
      ? priceUnits.find((pu) => pu.id === meta.value)
      : null;
  }, [meta.value, priceUnits]);
  const priceUnitList = React.useMemo(() => {
    return {
      time: priceUnits.filter((pu) => pu.type === 'work-time'),
      amount: priceUnits.filter((pu) => pu.type === 'amount')
    };
  }, [priceUnits]);
  return (
    <>
      <Box>
        {currentUnit && <span>{currentUnit.name}</span>}
        {currentUnit === null && (
          <span style={{ fontStyle: 'italic', color: 'grey' }}>unit</span>
        )}
        {currentUnit === undefined && <Skeleton />}
        <ButtonBase
          ref={boxRef}
          onClick={() =>
            setShowSelect(boxRef.current?.parentElement ?? undefined)
          }
        >
          <ArrowDropDown fontSize="small" />
        </ButtonBase>
      </Box>
      <Menu
        MenuListProps={{
          dense: true,
          style: {
            padding: 0
          }
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        open={!!showSelect}
        anchorEl={showSelect}
        onClose={() => setShowSelect(undefined)}
      >
        <MenuItem
          onClick={() => {
            helpers.setValue(null);
            setShowSelect(undefined);
          }}
          dense
          style={{ fontStyle: 'italic', color: 'grey' }}
        >
          unit
        </MenuItem>
        <ListSubheader>Time</ListSubheader>
        {priceUnitList.time.map((pu) => (
          <MenuItem
            dense
            onClick={() => {
              helpers.setValue(pu.id);
              setShowSelect(undefined);
            }}
            value={pu.id}
          >
            {pu.name}
            {pu.workHours && pu.workHours !== 1 ? ` (${pu.workHours}h)` : ''}
          </MenuItem>
        ))}
        <ListSubheader>Amount</ListSubheader>
        {priceUnitList.amount.map((pu) => (
          <MenuItem
            onClick={() => {
              helpers.setValue(pu.id);
              setShowSelect(undefined);
            }}
            dense
            value={pu.id}
          >
            {pu.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
