"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialPlainPositionDTO = void 0;
const omit_type_helper_1 = require("../../extern-libs/mapped-types/omit-type.helper");
const PlainRequestPositionDTO_1 = require("./PlainRequestPositionDTO");
const intersection_type_helper_1 = require("../../extern-libs/mapped-types/intersection-type.helper");
const InitialRequestPositionDTO_1 = require("./InitialRequestPositionDTO");
class InitialPlainPositionDTO extends (0, intersection_type_helper_1.IntersectionType)(InitialRequestPositionDTO_1.InitialRequestPositionDTO, (0, omit_type_helper_1.OmitType)(PlainRequestPositionDTO_1.PlainRequestPositionDTO, ['resourceIds', 'requiredPositionIds'])) {
}
exports.InitialPlainPositionDTO = InitialPlainPositionDTO;
