import React from 'react';
import { permissions, User } from 'model';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ContactMail as CopyEmailsIcon,
  Delete as DeleteIcon
} from '@material-ui/icons';
import { ApiState } from '../../auth/redux/types';
import { ErrorBoundary } from '../../error/ErrorBoundary';
import { RolesChips } from './role/RolesChips';
import { AccountStatusDisplay } from './AccountStatusDisplay';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { NoOp } from '../../../utils';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import { UserLink } from './UserLink';

interface AccountsTableProps {
  accounts: User[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function AccountsTable(props: AccountsTableProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const columns = useCreateCells(
    entityColumnBuilder<User>()
      .addDefaultColumn('user_email', 'email', 'Email', {
        createElement: (email: string, user) => (
          <UserLink value={user} format="email" />
        )
      })
      .addDefaultColumn('user_display_name', 'displayName', 'Display Name')
      .addColumn('user_roles', 'roles', 'Roles', {
        renderer: {
          createElement: (_, user) => <RolesChips user={user} />,
          createText: (_, user) => {
            return user.roles.map((r) => r.name);
          }
        }
      })
      .addBooleanColumn('user_enabled', 'enabled', 'Status', {
        renderer: {
          createElement: (value: boolean | null | undefined) => (
            <AccountStatusDisplay enabled={!!value} />
          ),
          createText: (value: boolean | null | undefined) => [
            value ? 'enabled' : 'disabled'
          ]
        }
      }).columns,
    []
  );

  return (
    <ErrorBoundary context="accountsTable">
      <GenericTable
        toolbarActions={[
          {
            label: 'Copy Email adresses to clipboard',
            showWhen: 'selected',
            icon: <CopyEmailsIcon />,
            onClick: (selectedUsers, allUsers) => {
              const users =
                selectedUsers.length === 0 ? allUsers : selectedUsers;
              navigator.clipboard.writeText(
                users.map((acc) => acc.email).join('; ')
              );
            }
          },
          {
            label: 'Delete',
            showWhen: 'selected',
            icon: <DeleteIcon />,
            onClick: NoOp
          }
        ]}
        rowActions={[]}
        primaryButton={{
          label: 'New Account',
          onClick: () => history.push('/users/accounts?type=create#account'),
          permission: permissions.users.user.create
        }}
        label={t('Accounts')}
        refresh={props.onRefresh}
        data={props.accounts}
        isLoading={props.apiState === 'pending'}
        tableCells={columns}
      />
    </ErrorBoundary>
  );
}
