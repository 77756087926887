import { permissions, ProjectRequest, RequestStatusType } from 'model';
import React from 'react';
import { Alert, AlertProps, AlertTitle } from '@material-ui/lab';
import {
  CircularProgress,
  IconButton,
  SvgIcon,
  Typography
} from '@material-ui/core';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { Cancel, Check, Edit, Settings, Timelapse } from '@material-ui/icons';
import { executeMatcher } from '../../../../auth/permissions.matchers';
import { useGrantedPermissions } from '../../../../auth/hooks/granted-permissions.hook';
import { useRequestStatus } from '../../../hooks/request-status.hook';

export interface RequestStatusAlertProps {
  request: ProjectRequest;
}

export const RequestStatusTypeLook: Record<
  RequestStatusType,
  { color: AlertProps['severity']; icon: typeof SvgIcon }
> = {
  accepted: {
    color: 'success',
    icon: Check
  },
  'permanently-closed': {
    color: 'error',
    icon: Cancel
  },
  'temporary-closed': {
    color: 'error',
    icon: Timelapse
  },
  open: {
    color: 'success',
    icon: Settings
  }
};
export function RequestStatusAlert({ request }: RequestStatusAlertProps) {
  const { url } = useRouteMatch();
  const grantedPermissions = useGrantedPermissions();
  const { requestStatus } = useRequestStatus(request.requestStatusId);
  const { color: alertColor, icon: StatusIcon } = RequestStatusTypeLook[
    requestStatus?.type ?? ('' as RequestStatusType)
  ] ?? { color: '', icon: <CircularProgress /> };
  const editStatusPermitted = executeMatcher(
    permissions.project.projectRequest.edit,
    grantedPermissions
  );
  return (
    <Alert
      color={alertColor}
      icon={<StatusIcon />}
      action={
        <>
          {editStatusPermitted && (
            <IconButton
              component={RouterLink}
              to={`${url}?type=edit#request-state`}
            >
              <Edit />
            </IconButton>
          )}
        </>
      }
    >
      <AlertTitle>
        <Typography style={{ alignItems: 'center', display: 'flex' }}>
          <div
            style={{
              display: 'inline-flex',
              width: '1.5em',
              height: '1.5em',
              borderRadius: '50%',
              border: '1px solid rgba(20,20,20,0.6)',
              backgroundColor: requestStatus && requestStatus.color,
              marginRight: '1em'
            }}
          />
          {!!requestStatus && requestStatus.name}
        </Typography>
      </AlertTitle>
    </Alert>
  );
}
