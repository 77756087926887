import { RequestStatus, RequestStatusDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const requestStatusThunk = createFeatureThunks<
  RequestStatus,
  RequestStatusDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/projects/request-status${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'request-status'
});
