import { PriceBound } from 'model';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { priceBoundsSelectors } from '../redux/price-bounds.slice';
import { priceBoundsThunk } from '../redux/price-bounds.thunk';

export function usePriceBoundOfTarget(target: PriceBound['rangeTarget']) {
  const dispatch = useAppDispatch();

  const targetBounds = useAppSelector(
    (rs) => priceBoundsSelectors.selectByTarget(rs, target),
    shallowEqual
  );
  const apiState = useAppSelector(priceBoundsSelectors.selectState);
  const listFetched = useAppSelector(priceBoundsSelectors.selectListFetched);

  const listEntities = () => dispatch(priceBoundsThunk.list());
  React.useEffect(() => {
    if (apiState === 'fresh' || listFetched === false) {
      listEntities();
    }
  }, [apiState, listFetched, listEntities]);

  return React.useMemo(() => ({ targetBounds }), [targetBounds]);
}
