import React, { ReactNode } from 'react';
import { Skeleton } from '@material-ui/lab';
import { BaseTableRow, CellAccessorInput, DataCell } from '../TableDataCell';
import { ColumnFilter } from '../filter/column-filter.types';
import { EntityMapType } from '../../../redux/entity-map';
import { DefaultCellRenderer } from './DefaultCellRenderer';

export type CellDataAccessor<T extends BaseTableRow, V = any> = (
  data: T,
  cell: DataCell<T, V>
) => V;

export interface DataCellRenderer<T extends BaseTableRow, V = any> {
  createElement: (value: V, data: T, cell: DataCell<T, V>) => ReactNode;
  createText: (value: V, data: T, cell: DataCell<T, V>) => string[];
  comparator?: (a: [V, T], b: [V, T], cell: DataCell<T, V>) => number;
  filter?: ColumnFilter<T, V, any>;
}

export type EntityCellDataAccessor<
  T extends BaseTableRow,
  E extends EntityMapType<any>,
  V = any
> = (data: T, entityMap: { [e in keyof E]: Exclude<E[e], undefined> }) => V;

export class DataAccessorError extends Error {}

export function entityAccessor<
  T extends BaseTableRow,
  E extends EntityMapType<any>,
  V = any
>(
  entityMap: E,
  requiredEntities: (keyof E)[],
  accessor: EntityCellDataAccessor<T, E, V>
): CellAccessorInput<T, V> {
  return (data) => {
    if (
      Object.entries(entityMap).some(
        ([k, v]) => requiredEntities.includes(k) && v === undefined
      )
    ) {
      return undefined as any;
    }
    try {
      return accessor(data, entityMap as any);
    } catch (e) {
      if (e instanceof DataAccessorError) {
        return undefined as any;
      }
      throw e;
    }
  };
}

export function lazyConf<T extends BaseTableRow, V = any>(
  renderer?: Partial<DataCellRenderer<T, V>>
): DataCellRenderer<T, V> {
  const config = DefaultCellRenderer(renderer ?? {});
  return {
    createElement: (value, data, cell) => {
      if (value !== undefined) {
        return config.createElement(value, data, cell);
      }
      return <Skeleton variant="text" />;
    },
    createText: (value, data, cell) => {
      if (value !== undefined) {
        return config.createText(value, data, cell);
      }
      return ['#unavailable'];
    },
    comparator: config.comparator
      ? (a, b, cell) => {
          if (a !== undefined && b !== undefined) {
            return (config.comparator as any)(a, b, cell);
          }
          return 0;
        }
      : undefined,
    filter: config.filter
      ? {
          component: config.filter.component,
          initialState: config.filter.initialState,
          isEffective: config.filter.isEffective,
          createFilter: (cell, state) => {
            const filter = (config.filter?.createFilter as any)(cell, state);
            return (value, data) => {
              if (value !== undefined) {
                return filter(value, data);
              }
              return false;
            };
          }
        }
      : undefined
  };
}

/*
export function entityIdAccessor<
    T extends BaseTableRow,
    E extends EntityMapType<any>,
    V = any
    >(
    entityMap: E,
    requiredEntities: (keyof E)[],
    accessor: (value: V, data: T, entityMap:
    {[e in keyof E]: Exclude<E[e], undefined>}) => V | undefined
): CellAccessorInput<T, V> {

} */
