"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.patchClientTransformer = exports.ClientDTO = exports.PatchClientDTO = exports.BaseClientDTO = void 0;
// eslint-disable-next-line max-classes-per-file
const class_validator_1 = require("class-validator");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const Client_1 = require("../entity/Client");
class BaseClientDTO {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BaseClientDTO.prototype, "taxId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BaseClientDTO.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BaseClientDTO.prototype, "defaultCurrency", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BaseClientDTO.prototype, "ceo", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BaseClientDTO.prototype, "email", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BaseClientDTO.prototype, "phone", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BaseClientDTO.prototype, "invoiceAddressId", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], BaseClientDTO.prototype, "headQuarterAddressId", void 0);
exports.BaseClientDTO = BaseClientDTO;
class PatchClientDTO extends BaseClientDTO {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MinLength)(3),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], PatchClientDTO.prototype, "name", void 0);
exports.PatchClientDTO = PatchClientDTO;
class ClientDTO extends BaseClientDTO {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.MinLength)(3),
    __metadata("design:type", String)
], ClientDTO.prototype, "name", void 0);
exports.ClientDTO = ClientDTO;
exports.patchClientTransformer = (0, DTOTransformer_1.createDTOTransformer)(Client_1.Client, PatchClientDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'addresses',
    'comments',
    'contacts',
    'largeScaleProjects',
    'prices',
    'minimumPrice',
    'minimumPriceId',
    'clientNumber',
    'priceComment',
    'priceCommentId',
    'headQuarterAddress',
    'invoiceAddress'
], {
    headerQuarterAddressId: [
        'headQuarterAddress',
        (v) => v
    ]
});
