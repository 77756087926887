import React from 'react';
import { RejectOfferDTO } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import * as yup from 'yup';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'formik';
import { createStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../../redux/redux.hooks';
import { rejectProjectOfferThunk } from '../../../redux/project-offers.thunk';
import { NoOp } from '../../../../../utils';
import { DialogForm } from '../../../../../components/input/form/DialogForm';
import { useProjectOffer } from '../../../hooks/project-offer.hook';
import projectOffersSlice, {
  projectOfferSelectors
} from '../../../redux/project-offer.slice';
import { buildDTOView } from '../../../../../transformer/DTOViewSchema';
import { useQuery } from '../../../../../hooks/query-params.hook';
import { FormikSelect } from '../../../../../components/select/FormikSelect';
import { useRequestStatusList } from '../../../hooks/request-status-list.hook';
import { FormikCommentEditor } from '../../../../../components/input/comment/FormikCommentEditor';
import { useProjectRequest } from '../../../hooks/project-request.hook';
import { projectRequestThunk } from '../../../redux/project-requests.thunk';
import { useProject } from '../../../hooks/project.hook';

const useStyles = makeStyles((theme) =>
  createStyles({
    commentErrorMessage: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

const rejectOfferView = buildDTOView<RejectOfferDTO>()
  .all()
  .withSchema(
    yup
      .object({
        rejectionComment: yup
          .object()
          .required()
          .shape({
            comment: yup.string().required('A comment is required'),
            attachedFiled: yup.array().of(
              yup.object({
                fileAccessId: yup.string().required(),
                token: yup.string().required()
              })
            )
          }),
        requestStatusId: yup.string()
      })
      .defined() as unknown as yup.SchemaOf<RejectOfferDTO>
  );

export function RejectOfferFormDialog() {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const queryParams = useQuery();

  const { projectId } = useParams<{
    projectId: string;
  }>();
  const { project } = useProject(projectId);
  const { projectOffer } = useProjectOffer(
    project?.requestId,
    queryParams.get('offerId')
  );
  const { projectRequest } = useProjectRequest(projectOffer?.requestId);
  const { entities: requestStatusList } = useRequestStatusList();
  const [showRequestStatusSelect, setShowRequestStatusSelect] =
    React.useState(false);

  const rejectProjectOffer = (input: RejectOfferDTO) =>
    dispatch(
      rejectProjectOfferThunk({
        projectId: project?.id || '',
        requestId: projectOffer?.requestId || '',
        id: projectOffer?.id || '',
        input
      })
    )
      .then(unwrapResult)
      .then((res) => {
        dispatch(projectRequestThunk.get({ id: projectRequest?.id || '' }));
        return res;
      })
      .catch(NoOp);

  const initialValues: RejectOfferDTO = {
    requestStatusId: projectRequest?.requestStatusId ?? '',
    rejectionComment: { comment: '' }
  };

  return (
    <DialogForm
      identifier="reject-offer"
      label="Reject Offer"
      form={{
        initialValues,
        validationSchema: rejectOfferView.validationSchema
      }}
      api={{
        clearError: projectOffersSlice.actions.clearError,
        errorSelector: projectOfferSelectors.selectError,
        onSubmit: rejectProjectOffer,
        stateSelector: projectOfferSelectors.selectState
      }}
      actions={
        showRequestStatusSelect
          ? [
              {
                label: t('Reject Offer'),
                doSubmit: true
              }
            ]
          : [
              {
                label: t('Edit Request Status'),
                onClick: () => setShowRequestStatusSelect(true),
                doSubmit: false
              },
              {
                label: t('Reject Offer'),
                doSubmit: true
              }
            ]
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color="error" className={classes.commentErrorMessage}>
            <ErrorMessage name="rejectionComment.comment" />
          </Typography>
          <FormikCommentEditor name={rejectOfferView.path.rejectionComment} />
        </Grid>
        {showRequestStatusSelect && (
          <Grid item xs={12}>
            <FormikSelect
              label={t('Request Status')}
              name={rejectOfferView.path.requestStatusId}
              options={requestStatusList
                .filter((rs) => rs.type !== 'accepted')
                .map((rs) => ({
                  value: rs.id,
                  label: rs.name
                }))}
            />
          </Grid>
        )}
      </Grid>
    </DialogForm>
  );
}
