import { Route, useRouteMatch } from 'react-router-dom';
import { Switch as RouterSwitch } from 'react-router';
import React from 'react';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { LanguageScreen } from './screens/Languages.screen';

export function LanguagesFeature() {
  const { path } = useRouteMatch();

  return (
    <ErrorBoundary context="languageFeature">
      <RouterSwitch>
        <Route path={`${path}`}>
          <LanguageScreen />
        </Route>
      </RouterSwitch>
    </ErrorBoundary>
  );
}
