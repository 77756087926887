import { Language } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const listLanguagesThunk = createErrorHandlingThunk(
  'language/list',
  async () => {
    const result = await apiClient.get<Language[]>('/language');
    return result.data;
  }
);
