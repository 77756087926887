import React, { ChangeEvent } from 'react';
import { createProjectDef, formatProjectNumber, monthNames } from 'model';
import { parse } from 'papaparse';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip
} from '@material-ui/core';
import { CheckBox, CloudUpload, Error, Warning } from '@material-ui/icons';
import { useClients } from '../../clients/hooks/clients.hook';
import { useProjects } from '../hooks/projects.hook';
import { useSuppliers } from '../../suppliers/hooks/suppliers.hook';
import { useLanguageIndex } from '../../languages/hooks/language-index.hook';
import { useServiceClasses } from '../../services/hooks/service-classes.hook';
import { useServiceSteps } from '../../services/hooks/service-steps.hook';
import { apiClient } from '../../../api/apiClient';
import { NoOp } from '../../../utils';
import { useUsers } from '../../users/hooks/users.hook';
import { formatDate } from '../../../localized-formats';

export function ProjectImport2Screen() {
  const clients = useClients();
  const projects = useProjects();
  const supplier = useSuppliers();
  const langIndex = useLanguageIndex();
  const serviceClass = useServiceClasses();
  const serviceStep = useServiceSteps();
  const { projectDef, projectGroupDef, createImportData } = createProjectDef({
    client: (n: string) =>
      clients.clients.find((c) => c?.clientNumber?.number === n),
    project: (n: string) =>
      projects.entities.find(
        (p) => p.projectNumber && formatProjectNumber(p.projectNumber) === n
      ),
    supplier: (n: string) =>
      supplier.suppliers.find((s) => s.importReference === n),
    language: (n: string) => langIndex.codeMap[n.trim().toLowerCase()],
    serviceClass: (n: string) =>
      serviceClass.entities.find(
        (e) => e.name.toLowerCase() === n.toLowerCase()
      ),
    serviceStep: (n: string) =>
      serviceStep.entities.find(
        (step) => step.name.toLowerCase() === n.toLowerCase()
      )
  } as any);

  const [parseResult, setParseResult] =
    React.useState<ReturnType<(typeof projectGroupDef)['build']>>();

  //  const [collapsed, setCollapsed] = React.useState(true);
  //   const [showEmpty, setShowEmpty]  = React.useState(false);

  const [withHeaders, setWithHeaders] = React.useState(1);
  const [fromMonth, setFromMonth] = React.useState<{
    year: number;
    month: string;
  }>();
  const [toMonth, setToMonth] = React.useState<{
    year: number;
    month: string;
  }>();
  const [, setBounds] = React.useState<{ start: number; end: number }>({
    start: 0,
    end: -1
  });
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  // const transformer = props.pretransform ?? ((a: string[][]) => a);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      parse<string[]>(file, {
        complete: (results) => {
          const parseResultDescriptor = projectGroupDef.build(
            results.data
              .map((r, i) => ({ rowIndex: i, content: r }))
              .slice(
                withHeaders,
                results.data.length &&
                  results.data[results.data.length - 1].length === 1
                  ? -1
                  : 0
              )
          );
          setBounds({ start: 0, end: -1 });
          setParseResult(parseResultDescriptor);
          const year = parseResultDescriptor.find(
            (yR) =>
              yR.group.cellValues.year.content === '2020' &&
              yR.rows.find((m) => m.group.cellValues.month.content === 'August')
          );
          if (year) {
            setFromMonth({ month: 'August', year: 2020 });
          } else {
            setFromMonth(undefined);
          }
          const end = parseResultDescriptor[parseResultDescriptor.length - 1]
            .group.cellValues.year.value.value as number;
          const endMonth = parseResultDescriptor[
            parseResultDescriptor.length - 1
          ].rows[
            parseResultDescriptor[parseResultDescriptor.length - 1].rows
              .length - 1
          ].group.cellValues.month.value.value?.name as string;
          if (end && endMonth) {
            setToMonth({ year: end, month: endMonth });
          } else {
            setToMonth(undefined);
          }
        }
      });
    }
  };
  const [processing, setProcessing] = React.useState(false);
  const [syncState, setSyncState] =
    React.useState<
      { year: number; createdAt: string; month: number; issuerId: string }[]
    >();
  React.useEffect(() => {
    apiClient
      .get('/projects/project/import')
      .then((res) => {
        setSyncState(res.data);
        return res;
      })
      .catch(NoOp);
  }, []);
  const users = useUsers();
  const handleClick = () => hiddenFileInput.current?.click();

  const createGroupRow = <G extends keyof (typeof projectDef)['groups']>(
    groupName: G,
    rowIndex: number,
    data: Record<
      string,
      {
        content: string;
        errors: { level: 'error' | 'warning'; message: string }[];
      }
    >,
    rowSpan = 1,
    layoutLevel = 0,
    style = 'dashed'
  ) => {
    const levelLayer =
      Object.keys(projectDef.groups).findIndex((g) => g === groupName) ?? 0;
    const levelBorder = (4 - levelLayer) * 0.8;
    return {
      rowIndex,
      elements:
        /* <tr>
        <td>{rowIndex}</td>{ */
        Object.entries(projectDef.groups).flatMap(([name, group], gI) =>
          name !== groupName
            ? [
                // eslint-disable-next-line max-len
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                <td
                  style={{
                    minHeight: '2rem',
                    backgroundColor: 'darkgray',
                    borderTop: `${levelBorder}px ${style} black`,
                    borderRight:
                      gI === Object.keys(projectDef.groups).length - 1
                        ? `${levelBorder}px ${style} black`
                        : ' none'
                  }}
                  colSpan={Object.keys(group.cellMapAccessor()).length}
                />
              ].filter(
                () =>
                  Object.keys(projectDef.groups).findIndex(
                    (p) => p === groupName
                  ) <=
                  Object.keys(projectDef.groups).findIndex((p) => p === name)
              )
            : Object.keys(group.cellMapAccessor()).map((k, ki) => (
                <td
                  rowSpan={rowSpan}
                  style={{
                    borderTop: `${levelBorder}px  ${style} black`,
                    borderLeft:
                      ki === 0 ? `${levelBorder}px  ${style} black` : 'none',
                    borderRight:
                      ki === Object.keys(group.cellMapAccessor()).length - 1 &&
                      gI === Object.keys(projectDef.groups).length - 1
                        ? `${levelBorder}px ${style} black`
                        : 'none',
                    verticalAlign: 'top',
                    // eslint-disable-next-line no-nested-ternary
                    backgroundColor: data[k].errors.length
                      ? data[k].errors.some((e) => e.level === 'error')
                        ? 'red'
                        : 'orange'
                      : layoutLevel % 2 === 0
                      ? 'white'
                      : 'lightgray'
                  }}
                >
                  {data[k].content}
                  {data[k].errors.filter((e) => e.level === 'warning')
                    .length !== 0 && (
                    <Tooltip
                      title={
                        <ul>
                          {data[k].errors
                            .filter((e) => e.level === 'warning')
                            .map((e) => (
                              <li>{e.message}</li>
                            ))}
                        </ul>
                      }
                    >
                      <Warning />
                    </Tooltip>
                  )}{' '}
                  {data[k].errors.filter((e) => e.level === 'error').length !==
                    0 && (
                    <Tooltip
                      title={
                        <ul>
                          {data[k].errors
                            .filter((e) => e.level === 'error')
                            .map((e) => (
                              <li>{e.message}</li>
                            ))}
                        </ul>
                      }
                    >
                      <Error />
                    </Tooltip>
                  )}
                </td>
              ))
        )
    } /* }
      </tr> */;
  };
  const restriction =
    fromMonth || toMonth
      ? {
          from: fromMonth,
          to: toMonth
        }
      : undefined;
  return (
    <Paper>
      <Grid container xs={12}>
        <Grid item xs={12}>
          {parseResult !== undefined &&
            syncState &&
            (restriction
              ? parseResult
                  .filter(
                    (r) =>
                      (r.group.cellValues.year.value?.value ?? 0) >=
                        (restriction.from?.year ?? 0) &&
                      (r.group.cellValues.year.value?.value ??
                        Number.MAX_VALUE) <=
                        (restriction.to?.year ?? Number.MAX_VALUE)
                  )
                  .map((s) => ({
                    ...s,
                    rows: s.rows.filter((r1) => {
                      return (
                        ((s.group.cellValues.year.value?.value ?? 0) >
                          (restriction.from?.year ?? -1) ||
                          (monthNames.find(
                            (mn) =>
                              mn.name.toLowerCase() ===
                              (restriction.from?.month ?? '').toLowerCase()
                          )?.index ?? 0) <=
                            (r1.group.cellValues.month.value?.value?.index ??
                              0)) &&
                        ((s.group.cellValues.year.value?.value ?? 0) <
                          (restriction.to?.year ?? Number.MAX_VALUE) ||
                          (monthNames.find(
                            (mn) =>
                              mn.name.toLowerCase() ===
                              (restriction.to?.month ?? '').toLowerCase()
                          )?.index ?? Number.MAX_VALUE) >=
                            (r1.group.cellValues.month.value?.value?.index ??
                              0))
                      );
                    })
                  }))
              : parseResult
            ).every((p) =>
              p.rows.every(
                (p1) =>
                  !syncState.find(
                    (st) =>
                      st.year === p.group.cellValues.year.value.value &&
                      st.month === p1.group.cellValues.month.value.value?.number
                  )
              )
            ) &&
            !processing && (
              <IconButton
                onClick={() => {
                  try {
                    setProcessing(true);
                    const impData = createImportData(
                      restriction
                        ? parseResult
                            .filter(
                              (r) =>
                                (r.group.cellValues.year.value?.value ?? 0) >=
                                  (restriction.from?.year ?? 0) &&
                                (r.group.cellValues.year.value?.value ??
                                  Number.MAX_VALUE) <=
                                  (restriction.to?.year ?? Number.MAX_VALUE)
                            )
                            .map((s) => ({
                              ...s,
                              rows: s.rows.filter((r1) => {
                                return (
                                  ((s.group.cellValues.year.value?.value ?? 0) >
                                    (restriction.from?.year ?? -1) ||
                                    (monthNames.find(
                                      (mn) =>
                                        mn.name.toLowerCase() ===
                                        (
                                          restriction.from?.month ?? ''
                                        ).toLowerCase()
                                    )?.index ?? 0) <=
                                      (r1.group.cellValues.month.value?.value
                                        ?.index ?? 0)) &&
                                  ((s.group.cellValues.year.value?.value ?? 0) <
                                    (restriction.to?.year ??
                                      Number.MAX_VALUE) ||
                                    (monthNames.find(
                                      (mn) =>
                                        mn.name.toLowerCase() ===
                                        (
                                          restriction.to?.month ?? ''
                                        ).toLowerCase()
                                    )?.index ?? Number.MAX_VALUE) >=
                                      (r1.group.cellValues.month.value?.value
                                        ?.index ?? 0))
                                );
                              })
                            }))
                        : parseResult
                    );
                    apiClient
                      .post('/projects/project/import', {
                        groups: impData
                      })
                      .then((res) => {
                        // eslint-disable-next-line no-alert
                        alert('Successfully imported data');
                        // eslint-disable-next-line promise/no-nesting
                        apiClient
                          .get('/projects/project/import')
                          .then((res2) => {
                            setSyncState(res2.data);
                            return res2;
                          })
                          .catch(NoOp);
                        return res;
                      })
                      .catch(() => {
                        // eslint-disable-next-line no-alert
                        alert('Error while processing import');
                      });
                  } catch (e) {
                    // eslint-disable-next-line no-alert
                    alert('Error in data');
                  } finally {
                    setProcessing(false);
                  }
                }}
              >
                <CheckBox />
              </IconButton>
            )}
          {processing && <CircularProgress />}
          <IconButton onClick={handleClick}>
            <CloudUpload />
          </IconButton>
          <Checkbox
            checked={withHeaders > 0}
            onChange={() => setWithHeaders(withHeaders === 0 ? 1 : 0)}
          />
          Remove Headers
          {withHeaders > 0 && (
            <TextField
              type="number"
              value={withHeaders}
              onChange={(e) => {
                const amount = parseInt(e.target.value, 10);
                if (!Number.isNaN(amount)) {
                  setWithHeaders(amount);
                }
              }}
              size="small"
              inputProps={{ step: 1, min: 1 }}
            />
          )}
          {/*          <Checkbox
            checked={showEmpty}
            onChange={() => setShowEmpty(!showEmpty)}
          />
          Show ignored columns */}
        </Grid>
        {syncState && (
          <Grid xs={12}>
            <Accordion>
              <AccordionSummary>Previous Imports</AccordionSummary>
              <AccordionDetails>
                <li>
                  {syncState
                    .sort((a, b) =>
                      a.year === b.year ? a.month - b.month : a.year - b.year
                    )
                    .map((entry) => (
                      <ul>
                        {/* eslint-disable-next-line max-len */}
                        {entry.month}/{entry.year} {formatDate(entry.createdAt)}{' '}
                        {
                          users.users.find((u) => u.id === entry.issuerId)
                            ?.email
                        }
                      </ul>
                    ))}
                </li>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        <Grid xs={12}>
          {parseResult && (
            <>
              From:
              <Select
                style={{
                  backgroundColor: syncState?.find(
                    (st) =>
                      st.year === fromMonth?.year &&
                      st.month ===
                        monthNames.find((mn) => mn.name === fromMonth?.month)
                          ?.number
                  )
                    ? 'red'
                    : undefined
                }}
                value={fromMonth ? `${fromMonth.year}-${fromMonth.month}` : ''}
                onChange={(e) => {
                  const val = e.target.value;
                  if (val === '') {
                    setFromMonth(undefined);
                  } else if (
                    typeof val === 'string' &&
                    val.split('-').length === 2
                  ) {
                    setFromMonth({
                      year: parseInt(val.split('-')[0], 10),
                      month: val.split('-')[1]
                    });
                  }
                }}
              >
                <MenuItem value="">-</MenuItem>
                {parseResult.flatMap((p) => [
                  <ListSubheader title={p.group.cellValues.year.content}>
                    {p.group.cellValues.year.content}
                  </ListSubheader>,
                  ...p.rows.map((p1) => (
                    <MenuItem
                      style={{
                        color: syncState?.find(
                          (st) =>
                            st.year === p.group.cellValues.year.value.value &&
                            st.month ===
                              p1.group.cellValues.month.value.value?.number
                        )
                          ? 'red'
                          : 'black'
                      }}
                      selected={
                        fromMonth?.month ===
                          p1.group.cellValues.month.content &&
                        fromMonth?.year ===
                          parseInt(p.group.cellValues.year.content, 10)
                      }
                      value={
                        `${p.group.cellValues.year.content}-` +
                        `${p1.group.cellValues.month.content}`
                      }
                    >
                      {p1.group.cellValues.month.content} /{' '}
                      {p.group.cellValues.year.content}
                    </MenuItem>
                  ))
                ])}
              </Select>
              To:
              <Select
                value={toMonth ? `${toMonth.year}-${toMonth.month}` : ''}
                style={{
                  backgroundColor: syncState?.find(
                    (st) =>
                      st.year === toMonth?.year &&
                      st.month ===
                        monthNames.find((mn) => mn.name === toMonth?.month)
                          ?.number
                  )
                    ? 'red'
                    : undefined
                }}
                onChange={(e) => {
                  const val = e.target.value;
                  if (val === '') {
                    setToMonth(undefined);
                  } else if (
                    typeof val === 'string' &&
                    val.split('-').length === 2
                  ) {
                    setToMonth({
                      year: parseInt(val.split('-')[0], 10),
                      month: val.split('-')[1]
                    });
                  }
                }}
              >
                <MenuItem value="">-</MenuItem>
                {parseResult.flatMap((p) => [
                  <ListSubheader title={p.group.cellValues.year.content}>
                    {p.group.cellValues.year.content}
                  </ListSubheader>,
                  ...p.rows.map((p1) => (
                    <MenuItem
                      style={{
                        color: syncState?.find(
                          (st) =>
                            st.year === p.group.cellValues.year.value.value &&
                            st.month ===
                              p1.group.cellValues.month.value.value?.number
                        )
                          ? 'red'
                          : 'black'
                      }}
                      selected={
                        toMonth?.month === p1.group.cellValues.month.content &&
                        toMonth?.year ===
                          parseInt(p.group.cellValues.year.content, 10)
                      }
                      value={
                        `${p.group.cellValues.year.content}-` +
                        `${p1.group.cellValues.month.content}`
                      }
                    >
                      {p1.group.cellValues.month.content} /{' '}
                      {p.group.cellValues.year.content}
                    </MenuItem>
                  ))
                ])}
              </Select>
            </>
          )}
        </Grid>
        <Grid item xs={12} style={{ maxHeight: '85vh', overflow: 'auto' }}>
          <table cellSpacing={0} cellPadding={3} style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>No.</th>
                {Object.entries(projectDef.groups).map(([gN, g]) => {
                  return (
                    <th
                      style={{ border: '1px solid black' }}
                      colSpan={Object.keys(g.cellMapAccessor()).length}
                    >
                      {gN}
                    </th>
                  );
                })}
              </tr>
              <tr>
                <th>No.</th>
                {Object.entries(projectDef.groups).flatMap(([, g]) => {
                  return Object.entries(g.cellMapAccessor()).map(([n], i) => (
                    <th
                      style={
                        i === 0
                          ? { borderLeft: '1px solid black' }
                          : { borderLeft: '1px dotted black' }
                      }
                    >
                      {n}
                    </th>
                  ));
                })}
              </tr>
            </thead>
            <tbody>
              {parseResult &&
                (restriction
                  ? parseResult
                      .filter(
                        (r) =>
                          (r.group.cellValues.year.value?.value ?? 0) >=
                            (restriction.from?.year ?? 0) &&
                          (r.group.cellValues.year.value?.value ??
                            Number.MAX_VALUE) <=
                            (restriction.to?.year ?? Number.MAX_VALUE)
                      )
                      .map((s) => ({
                        ...s,
                        rows: s.rows.filter((r1) => {
                          return (
                            ((s.group.cellValues.year.value?.value ?? 0) >
                              (restriction.from?.year ?? -1) ||
                              (monthNames.find(
                                (mn) =>
                                  mn.name.toLowerCase() ===
                                  (restriction.from?.month ?? '').toLowerCase()
                              )?.index ?? 0) <=
                                (r1.group.cellValues.month.value?.value
                                  ?.index ?? 0)) &&
                            ((s.group.cellValues.year.value?.value ?? 0) <
                              (restriction.to?.year ?? Number.MAX_VALUE) ||
                              (monthNames.find(
                                (mn) =>
                                  mn.name.toLowerCase() ===
                                  (restriction.to?.month ?? '').toLowerCase()
                              )?.index ?? Number.MAX_VALUE) >=
                                (r1.group.cellValues.month.value?.value
                                  ?.index ?? 0))
                          );
                        })
                      }))
                  : parseResult
                )
                  .flatMap((year, yi) => {
                    return [
                      createGroupRow(
                        'year',
                        year.group.dataRow.rowIndex,
                        year.group.cellValues,
                        year.rows
                          .map(
                            (b) =>
                              b.rows
                                .map(
                                  (c) =>
                                    c.rows
                                      .map((d) => d.rows.length + 1)
                                      .reduce((acc3, c3) => acc3 + c3, 0) + 1
                                )
                                .reduce((acc2, c2) => acc2 + c2, 0) + 1
                          )
                          .reduce((acc1, c1) => acc1 + c1, 0) + 1,
                        yi,
                        'dashed'
                      ),
                      ...year.rows.flatMap((month, mi) => [
                        createGroupRow(
                          'month',
                          month.group.dataRow.rowIndex,
                          month.group.cellValues,
                          month.rows
                            .map(
                              (b) =>
                                b.rows
                                  .map((c) => c.rows.length + 1)
                                  .reduce((acc3, c3) => acc3 + c3, 0) + 1
                            )
                            .reduce((acc2, c2) => acc2 + c2, 0) + 1,
                          mi,
                          'dotted'
                        ),
                        ...month.rows.flatMap((project, pi) => {
                          return [
                            createGroupRow(
                              'project',
                              project.group.dataRow.rowIndex,
                              project.group.cellValues,
                              project.rows
                                .map((b) => b.rows.length + 1)
                                .reduce((acc3, c3) => acc3 + c3, 0) + 1,
                              pi,
                              'solid'
                            ),
                            ...project.rows.flatMap((service, si) => [
                              createGroupRow(
                                'service',
                                service.group.dataRow.rowIndex,
                                service.group.cellValues,
                                service.rows.length + 1,
                                si,
                                'dashed'
                              ),
                              ...service.rows.map((task, ti) =>
                                createGroupRow(
                                  'task',
                                  task.dataRow.rowIndex,
                                  task.values.task.cellValues,
                                  1,
                                  ti,
                                  'dotted'
                                )
                              )
                            ])
                          ];
                        })
                      ])
                    ];
                  })
                  .map((c) => ({
                    rowIndex: c.rowIndex,
                    elements: c.elements
                  }))
                  .reduce((acc, c) => {
                    const next = [...acc];
                    if (next[next.length - 1]?.rowIndex !== c.rowIndex) {
                      next.push({
                        rowIndex: c.rowIndex,
                        elements: [c.elements]
                      });
                    } else {
                      next[next.length - 1].elements.push(c.elements);
                    }
                    return next;
                  }, [] as { rowIndex: number; elements: JSX.Element[][] }[])
                  .flatMap((row) => {
                    return [
                      <tr style={{ minHeight: '2.5em' }}>
                        <td
                          style={{ border: '1px dotted blue' }}
                          rowSpan={row.elements.length}
                        >
                          {row.rowIndex}
                        </td>
                        {row.elements[0]}
                      </tr>,
                      ...row.elements.slice(1).map((e) => <tr>{e}</tr>)
                    ];
                  })}
            </tbody>
          </table>
        </Grid>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </Grid>
    </Paper>
  );
}
