import React from 'react';
import { useTypedField } from '../../../../../../transformer/DTOViewSchema';
import { ProjectOfferView } from '../ProjectOfferForm';
import { CommentEditor } from '../../../../../../components/comment/CommentEditor';

export function CreateOfferInitialCommentForm() {
  const [, commentMeta, commentHelpers] = useTypedField(
    ProjectOfferView.path.comment
  );
  const [, filesMeta, filesHelpers] = useTypedField(
    ProjectOfferView.path.attachedFiles
  );

  return (
    <CommentEditor
      value={commentMeta.value?.comment ?? ''}
      onChange={(value) =>
        commentHelpers.setValue({
          ...commentMeta.value,
          comment: value
        })
      }
      onFileChange={(attachedFiles, done) => {
        if (done) {
          commentHelpers.setValue({
            ...commentMeta.value,
            comment: commentMeta.value?.comment ?? '',
            attachedFiles: [
              ...(commentMeta.value?.attachedFiles ?? []),
              ...attachedFiles.map((af) => ({
                fileAccessId: af.id,
                token: af.grant
              }))
            ]
          });
          filesHelpers.setValue([
            ...(filesMeta.value ?? []),
            ...attachedFiles.map((af) => ({
              fileAccessId: af.id,
              token: af.grant
            }))
          ]);
        }
      }}
    />
  );
}
