import React, { PropsWithChildren } from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Badge } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { ColumnKey } from '../types';
import { useColumnSort } from './column-sort.hook';

const useStyles = makeStyles(() =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    }
  })
);

export function ColumnSortLabel<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(
  props: PropsWithChildren<{
    column: ColumnKey<T, C>;
    multisortEnabled: boolean;
  }>
) {
  const { sortable, columnSortState, requestSort } = useColumnSort(
    props.column
  );

  const { t } = useTranslation();
  const classes = useStyles();
  const SortLabel = (
    <TableSortLabel
      active={columnSortState !== undefined}
      direction={columnSortState?.direction ?? 'desc'}
      onClick={
        sortable
          ? (e) =>
              requestSort({ multisort: props.multisortEnabled && e.ctrlKey })
          : () => {}
      }
      hideSortIcon={columnSortState === undefined}
    >
      {props.children}
      {columnSortState && (
        <span className={classes.visuallyHidden}>
          {columnSortState?.direction === 'asc'
            ? t('sorted ascending')
            : t('sorted descending')}
        </span>
      )}
    </TableSortLabel>
  );

  return columnSortState?.position !== undefined ? (
    <Badge badgeContent={columnSortState.position + 1} color="primary">
      {SortLabel}
    </Badge>
  ) : (
    SortLabel
  );
}
