import * as yup from 'yup';
import { ClientMinimumPriceDTO, Price } from 'model';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { Grid } from '@material-ui/core';
import { buildDTOView } from '../../../../transformer/DTOViewSchema';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { NoOp } from '../../../../utils';
import { defineMinimumClientPrice } from '../../redux/actions/client-prices.thunk';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import suppliersSlice, {
  suppliersSelectors
} from '../../../suppliers/redux/suppliers.slice';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { FormikPriceInput } from './FormikPriceInput';

const validationSchema: yup.SchemaOf<ClientMinimumPriceDTO> = yup
  .object()
  .defined()
  .shape({
    price: yup
      .object()
      .required()
      .shape({
        price: yup.number().required(),
        currency: yup.string().default(null).nullable()
      }),
    description: yup.string().max(512).default(undefined).notRequired()
  });

// Todo any

const clientMinPriceView = buildDTOView<ClientMinimumPriceDTO>()
  .all()
  .withSchema(validationSchema as any);

export function ClientMinPriceFormDialog({
  clientId,
  defaultPrice,
  description
}: {
  clientId: string;
  description?: string;
  defaultPrice?: Price;
}) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const editMinPrice = (input: ClientMinimumPriceDTO) =>
    dispatch(defineMinimumClientPrice({ id: clientId, input }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <DialogForm
      identifier="min-price"
      label="Min Price"
      form={{
        validationSchema,
        initialValues: {
          price: {
            price: defaultPrice?.default || 0,
            currency: defaultPrice?.currency ?? null
          },
          description
        }
      }}
      api={{
        onSubmit: editMinPrice,
        stateSelector: suppliersSelectors.selectState,
        errorSelector: suppliersSelectors.selectError,
        clearError: suppliersSlice.actions.clearError
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormikPriceInput
            label={t('Price')}
            amountPath={clientMinPriceView.path.price.price}
            currencyPath={clientMinPriceView.path.price.currency}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikTextField
            label={t('Comment')}
            name={clientMinPriceView.path.description}
          />
        </Grid>
      </Grid>
    </DialogForm>
  );
}
