import React from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Popover,
  Switch,
  TextField
} from '@material-ui/core';
import { Toc as ColumnsIcon } from '@material-ui/icons';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import { ToggleButton } from '@material-ui/lab';
import FilterListIcon from '@material-ui/icons/FilterListOutlined';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import { useTranslation } from 'react-i18next';
import { BaseTableRow, DataCell, DataCellMap } from './TableDataCell';
import {
  useColumnDisplay,
  useTableColumnsDisplay
} from './layout/column-display.hook';
import { useTableConfigContext } from './context/DataTableContext';
import { useTableFilterControl } from './filter/column-filter-control.hook';
import { useColumnSort } from './sort/column-sort.hook';
import { ColumnSortLabel } from './sort/ColumnSortLabel';

function TableColumnItem<T extends BaseTableRow, C extends DataCellMap<T>>({
  column
}: {
  column: DataCell<T, any>;
}) {
  const { visible, setVisibility } = useColumnDisplay<T, C>(column.cellId);
  const { filterable, active, setActive } = useTableFilterControl<T, C>(
    column.cellId
  );
  const { sortable } = useColumnSort(column.cellId);

  const { t } = useTranslation();
  return (
    <ListItem
      style={{
        paddingRight: '80px',
        minWidth: '320px'
      }}
      dense
      button
      onClick={() => setVisibility(!visible)}
    >
      <ListItemIcon>
        <Switch checked={visible} edge="start" />
      </ListItemIcon>
      <ListItemText primary={t(column.label)} />
      <ListItemSecondaryAction>
        {sortable ? (
          <ColumnSortLabel column={column.cellId} multisortEnabled>
            <SortByAlphaIcon fontSize="small" />
          </ColumnSortLabel>
        ) : (
          <SortByAlphaIcon fontSize="small" htmlColor="rgba(230,230,230,0.4)" />
        )}
        {filterable ? (
          <ToggleButton
            size="small"
            selected={active}
            onChange={() => setActive(!active)}
          >
            <FilterListIcon fontSize="small" />
          </ToggleButton>
        ) : (
          <ToggleButton size="small" selected={false} disabled>
            <FilterListIcon
              fontSize="small"
              htmlColor="rgba(230,230,230,0.4)"
            />
          </ToggleButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export function TableColumnsConfig<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const { t } = useTranslation();
  const { tableCells } = useTableConfigContext<T, C>();
  const [search, setSearch] = React.useState<string>('');
  return (
    <>
      <TextField
        placeholder={t('Find Columns')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <List>
        {Object.entries(tableCells)
          .filter(
            (config) =>
              search.trim() === '' || config[1].label.includes(search.trim())
          )
          .map(([cellId, cell]) => (
            <TableColumnItem key={`${cellId}`} column={cell} />
          ))}
      </List>
    </>
  );
}
export function TableColumnConfigView<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const { t } = useTranslation();
  const { visibilities, changeVisibilities } = useTableColumnsDisplay<T, C>();
  return (
    <div
      style={{
        padding: '7px'
      }}
    >
      <TableColumnsConfig />
      <Box display="flex" justifyContent="space-between">
        <Button
          startIcon={<CheckBoxOutlinedIcon fontSize="small" />}
          onClick={() =>
            changeVisibilities(
              Object.fromEntries(
                Object.entries(visibilities)
                  .filter(([, v]) => !v)
                  .map(([k]) => [k, true])
              ) as Record<keyof C, boolean>
            )
          }
        >
          {t('Show all')}
        </Button>
        <Button
          startIcon={<CheckBoxOutlineBlankOutlinedIcon fontSize="small" />}
          onClick={() =>
            changeVisibilities(
              Object.fromEntries(
                Object.entries(visibilities)
                  .filter(([, v]) => v)
                  .map(([k]) => [k, false])
              ) as Record<keyof C, boolean>
            )
          }
        >
          {t('Hide all')}
        </Button>
      </Box>
    </div>
  );
}
export function TableColumnConfigButton<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const [open, setOpen] = React.useState<SVGSVGElement | null>(null);

  return (
    <>
      <ColumnsIcon
        onClick={(event) => setOpen(open ? null : event.currentTarget)}
      />
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={() => setOpen(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {!!open && <TableColumnConfigView<T, C> />}
      </Popover>
    </>
  );
}
