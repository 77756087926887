import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { ColumnKey } from '../types';

export type SortDirection = 'asc' | 'desc';

export const SortDirectionSequence: (SortDirection | undefined)[] = [
  'desc',
  'asc',
  undefined
];

export interface ColumnSortState<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  cellId: ColumnKey<T, C>;
  direction: SortDirection;
}

export type TableSortState<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> = Partial<Record<ColumnKey<T, C>, ColumnSortState<T, C>>>;

export interface TableSortContextData<
  T extends BaseTableRow,
  C extends DataCellMap<T>
> {
  sortState: TableSortState<T, C>;
  setSortState: (state: TableSortState<T, C>) => void;
}

export function useTableSortContext<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(
  defaultSortKey:
    | { column: ColumnKey<T, C>; direction: SortDirection }
    | undefined,
  initialSortState?: TableSortState<T, C>
): TableSortContextData<T, C> {
  const [sortState, setSortState] = React.useState<TableSortState<T, C>>(
    initialSortState ??
      ((defaultSortKey !== undefined
        ? {
            [defaultSortKey.column]: {
              cellId: defaultSortKey.column,
              direction: defaultSortKey.direction
            }
          }
        : {}) as TableSortState<T, C>)
  );
  return React.useMemo(
    () => ({ sortState, setSortState }),
    [sortState, setSortState]
  );
}
