import React from 'react';
import { useLargeScaleProjects } from '../hooks/large-scale-projects.hook';
import { LargeScaleProjectsTable } from '../components/largeScaleProjects/LargeScaleProjectsTable';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { LargeScaleProjectFormDialog } from '../components/largeScaleProjects/LargeScaleProjectFormDialog';

export function LargeScaleProjectsScreen() {
  const { largeScaleProjects, apiState, listLargeScaleProjects } =
    useLargeScaleProjects();

  const { type, reference } = useEditorLocation();

  return (
    <>
      {type === 'edit' && reference !== undefined && (
        <LargeScaleProjectFormDialog lspId={reference} />
      )}
      <LargeScaleProjectsTable
        projects={largeScaleProjects}
        onRefresh={listLargeScaleProjects}
        apiState={apiState}
      />
    </>
  );
}
