import { Route, useRouteMatch } from 'react-router-dom';
import { Switch as RouterSwitch } from 'react-router';
import React from 'react';
import { AccountsScreen } from './screens/Accounts.screen';
import { RolesScreen } from './screens/Roles.screen';
import { AccountDetailsScreen } from './screens/AccountDetails.screen';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { getUuidUrlParamMatcher } from '../../components/navigation/utils';
import { AccountFormDialog } from './components/AccountFormDialog';
import { KontextorError } from '../error/KontextorError';
import usersSlice, { usersSelectors } from './redux/users.slice';

export function UsersFeature() {
  const { path } = useRouteMatch();

  return (
    <ErrorBoundary context="userFeature">
      <KontextorError
        clear={usersSlice.actions.clearError}
        selector={usersSelectors.selectError}
      >
        <AccountFormDialog />
        <RouterSwitch>
          <Route path={`${path}/accounts/${getUuidUrlParamMatcher('userId')}`}>
            <AccountDetailsScreen />
          </Route>
          <Route path={`${path}/accounts`}>
            <AccountsScreen />
          </Route>
          <Route path={`${path}/roles`}>
            <RolesScreen />
          </Route>
        </RouterSwitch>
      </KontextorError>
    </ErrorBoundary>
  );
}
