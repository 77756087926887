import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { useTableConfigContext } from '../context/DataTableContext';
import { ColumnKey } from '../types';

export function useColumnDisplay<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(columnKey: ColumnKey<T, C>) {
  const {
    tableColumnVisibility: { columnConfig, setColumnConfig }
  } = useTableConfigContext<T, C>();

  return React.useMemo(
    () => ({
      visible: columnConfig[columnKey].visible,
      setVisibility: (visible: boolean) =>
        setColumnConfig({
          ...columnConfig,
          [columnKey]: { ...columnConfig[columnKey], visible }
        })
    }),
    [columnConfig, setColumnConfig]
  );
}

export function useTableColumnsDisplay<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const {
    tableColumnVisibility: { columnConfig, setColumnConfig }
  } = useTableConfigContext<T, C>();
  return React.useMemo(
    () => ({
      visibilities: Object.fromEntries(
        Object.entries(columnConfig).map(([k, v]) => [k, v.visible])
      ) as Record<ColumnKey<T, C>, boolean>,
      changeVisibilities: (map: Partial<Record<ColumnKey<T, C>, boolean>>) => {
        setColumnConfig(
          Object.fromEntries(
            Object.entries(columnConfig).map(([k, v]) => [
              k,
              { ...v, visible: map[k] ?? v.visible }
            ])
          ) as typeof columnConfig
        );
      }
    }),
    [columnConfig, setColumnConfig]
  );
}
