import { ProjectOffer, ProjectRequest } from 'model';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { projectRequestThunk } from './project-requests.thunk';
import { RootState } from '../../../redux/store';
import {
  acceptProjectOfferThunk,
  createProjectOfferThunk,
  loadRequestOffersThunk,
  projectOfferThunk,
  rejectProjectOfferThunk,
  updateProjectOfferThunk
} from './project-offers.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';

const projectOffersSlice = createEntitySlice<ProjectOffer>('project-offers', [
  CustomThunk.override<ProjectRequest, ProjectOffer>(projectRequestThunk.get, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.upsertMany(state, (payload as ProjectRequest).offers);
    }
  }) as any,
  CustomThunk.override<ProjectOffer>(loadRequestOffersThunk, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.upsertMany(state, payload as ProjectOffer[]);
    }
  }),
  createProjectOfferThunk,
  updateProjectOfferThunk,
  rejectProjectOfferThunk,
  acceptProjectOfferThunk
]);

export const projectOfferSelectors = {
  ...createAdapterSelectors('project-offers', projectOffersSlice.adapter),
  selectByRequest(state: RootState, requestId: string) {
    return this.selectAll(state).filter((a) => a.requestId === requestId);
  }
};

export const projectOfferAPI: EntityAPI<ProjectOffer> = {
  selectors: projectOfferSelectors,
  thunks: projectOfferThunk
};

export default projectOffersSlice;
