import { CatTool } from 'model';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const listCatTools = createErrorHandlingThunk(
  `cat-tools/list`,
  async () => {
    const result = await apiClient.get<CatTool[]>('/suppliers/cat-tools');
    return result.data;
  }
);
