import { OrderTask } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import {
  createOrderSubTaskThunk,
  projectOrderTasksThunk
} from './project-order-tasks.thunk';

const { list, delete: deleteThunk, ...thunks } = projectOrderTasksThunk;

const projectOrderTasksSlice = createEntitySlice<OrderTask>('orderTasks', [
  CustomThunk.override<OrderTask>(list, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as OrderTask[]);
    }
  }),
  CustomThunk.override<OrderTask>(
    deleteThunk as AsyncThunk<
      EntityId,
      { id: EntityId; projectId: string },
      any
    >,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  createOrderSubTaskThunk,
  ...Object.values(thunks)
]);
export const projectOrderTasksSelectors = createAdapterSelectors(
  'orderTasks',
  projectOrderTasksSlice.adapter
);

export default projectOrderTasksSlice;
