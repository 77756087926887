import React from 'react';
import { BaseTableRow, DataCell, DataCellMap } from '../TableDataCell';
import { useTableConfigContext } from '../context/DataTableContext';
import { useDisplayedTableColumns } from '../layout/displayed-columns.hook';
import { TableFilterContextData } from './column-filter.context';

export function useRawTableFilterMatcher<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(
  filterState: TableFilterContextData<T, C>['filterState'],
  displayedColumns: Partial<C>
) {
  const appliedFilters = React.useMemo(() => {
    return Object.entries(filterState)
      .filter(
        ([cellId, state]) =>
          state?.active &&
          displayedColumns[cellId] !== undefined &&
          (
            displayedColumns[cellId]?.renderer?.filter?.isEffective ??
            (() => true)
          )(displayedColumns[cellId] as DataCell<T, any>, state.filterState)
      )
      .map(([cellId, state]) => {
        const dataCell = displayedColumns[cellId] as DataCell<T, any>;
        const filterMatcher = displayedColumns[
          cellId
        ]?.renderer?.filter?.createFilter(dataCell, state?.filterState);
        return (data: T) =>
          filterMatcher ? filterMatcher(dataCell.accessor(data), data) : true;
      });
  }, [displayedColumns, filterState]);

  return React.useCallback(
    (data: T) => appliedFilters.every((f) => f(data)),
    [appliedFilters]
  );
}

export function useTableFilterMatcher<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const {
    tableFilterState: { filterState }
  } = useTableConfigContext<T, C>();
  const displayedColumns = useDisplayedTableColumns<T, C>();
  return useRawTableFilterMatcher<T, C>(filterState, displayedColumns);
}
