import React from 'react';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  makeStyles,
  useTheme
} from '@material-ui/core';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { Menu as MenuIcon } from '@material-ui/icons';
import TEO_LOGO from './TEO_LOGO_3.png';
import TEO_LOGO_DARK from './TEO_LOGO_DARK.png';
import TEO_LOGO_VERTICAL from './TEO_LOGO_VERTICAL.png';
import TEO_LOGO_VERTICAL_DARK from './TEO_LOGO_VERTICAL_DARK.png';
import { NavLinkListItem } from './NavLinkListItem';
import { useGrantedPermissions } from '../../../features/auth/hooks/granted-permissions.hook';
import {
  filterNavLinks,
  primaryNavLinks,
  secondaryNavLinks
} from '../mainNavigationMap';

const drawerWidth = 210;

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  menuButtonOpen: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  logoContainerOpen: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  logoContainerClosed: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  drawerPaper: {
    height: '100vh',
    position: 'sticky',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(5.4)
  }
}));

export function NavigationBar() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const theme = useTheme();

  const userPermissions = useGrantedPermissions();
  const permittedPrimaryLinks = filterNavLinks(
    primaryNavLinks,
    userPermissions
  );
  const permittedSecondaryLinks = filterNavLinks(
    secondaryNavLinks,
    userPermissions
  );

  const { pathname } = useLocation();

  const logoDark = open ? TEO_LOGO_DARK : TEO_LOGO_VERTICAL_DARK;

  const logoLight = open ? TEO_LOGO : TEO_LOGO_VERTICAL;

  const toggleDrawer = () => setOpen((p) => !p);

  const onNavListItemClicked = open ? undefined : () => setOpen(true);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
      }}
      open={open}
    >
      <div
        className={clsx(
          classes.logoContainer,
          open ? classes.logoContainerOpen : classes.logoContainerClosed
        )}
      >
        <IconButton
          onClick={toggleDrawer}
          className={open ? classes.menuButtonOpen : undefined}
        >
          <MenuIcon />
        </IconButton>
        <img
          alt="TEO-Logo"
          src={theme.palette.type === 'light' ? logoLight : logoDark}
          style={{ width: open ? 170 : 36 }}
        />
      </div>

      <Divider />
      <List>
        {permittedPrimaryLinks.map((nl) => {
          return (
            <NavLinkListItem
              onClick={onNavListItemClicked}
              detailed={open}
              isTopLevel
              key={`permitted-nav-link-${nl.label}`}
              navLink={nl}
              currentPath={pathname}
            />
          );
        })}
      </List>
      <Divider style={{ marginTop: 'auto' }} />
      <List>
        {permittedSecondaryLinks.map((nl) => (
          <NavLinkListItem
            onClick={onNavListItemClicked}
            detailed={open}
            isTopLevel
            key={`permitted-nav-link-${nl.label}`}
            navLink={nl}
            currentPath={pathname}
          />
        ))}
      </List>
    </Drawer>
  );
}
