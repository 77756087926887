import React from 'react';

export function useViewHovered() {
  const [hovered, setHovered] = React.useState(false);
  return {
    hovered,
    setHovered,
    listeners: {
      onMouseLeave: () => setHovered(false),
      onMouseOver: () => setHovered(true)
    }
  };
}
