import { FileAccessDTO, UploadFile, User, UserProfilePatchDTO } from 'model';

import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export interface PatchUserProfileThunkInput {
  displayName?: string | null;
  avatarFile?: File;
}

export const patchUserProfileThunk = createErrorHandlingThunk<
  User,
  { userId: string; input: PatchUserProfileThunkInput }
>('user/patch', async (args) => {
  let avatarAccess: FileAccessDTO | undefined;

  if (args.input.avatarFile) {
    const formData = new FormData();
    formData.append('file', args.input.avatarFile);
    formData.append('subPath', 'user/avatar');
    formData.append('uploadType', 'seafile-upload');

    const fileResult = await apiClient.post<UploadFile & { grant: string }>(
      '/files',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    avatarAccess = {
      fileAccessId: fileResult.data.id,
      token: fileResult.data.grant
    };
  }
  const userPatchDto: UserProfilePatchDTO = {
    displayName: args.input.displayName,
    avatarAccess
  };

  const patchResult = await apiClient.patch<User>(
    `/user/${args.userId}`,
    userPatchDto
  );
  return patchResult.data;
});
