import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { Euro } from '@material-ui/icons';
import {
  PropertyPath,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { FormikCurrencySelect } from '../../../../components/select/FormikCurrencySelect';

interface PriceInputProps {
  label?: string;
  amountPath: PropertyPath<any, number | null> | string;
  currencyPath?: PropertyPath<any, string | null> | string;
  currencyEditable?: boolean;
  adornmentPosition?: 'start' | 'end';
  alignRight?: boolean;
  required?: boolean;
}

interface MoneyFormatProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const MoneyNumberFormatPlain = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  minimumIntegerDigits: 1
});
export const MoneyNumberFormat = {
  format: (n: number) => {
    return MoneyNumberFormatPlain.formatToParts(n)
      .map((p) => {
        if (p.type === 'group') {
          return String.fromCharCode(160);
        }
        if (p.type === 'decimal') {
          return ',';
        }
        return p.value;
      })
      .join('');
  }
};

export function MoneyFormat(props: MoneyFormatProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator={String.fromCharCode(160)}
      isNumericString
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
      decimalSeparator=","
    />
  );
}

export function FormikPriceInput({
  amountPath,
  currencyPath,
  currencyEditable = true,
  label,
  adornmentPosition = 'end',
  required = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  alignRight = true
}: PriceInputProps) {
  const [, meta, helpers] = useTypedField<number | string | null>(amountPath);
  return (
    <TextField
      label={label}
      fullWidth
      value={meta.value}
      onChange={(e) => helpers.setValue(parseFloat(e.target.value))}
      id="money-input"
      required={required}
      InputLabelProps={{
        style: {
          right: 0
        }
      }}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      InputProps={{
        inputComponent: MoneyFormat as any,
        inputProps: { style: { textAlign: currencyPath ? 'right' : 'left' } },
        endAdornment:
          currencyPath && adornmentPosition === 'end' ? (
            <InputAdornment position={adornmentPosition}>
              <FormikCurrencySelect
                disabled={!currencyEditable}
                name={currencyPath}
              />
            </InputAdornment>
          ) : (
            <Euro color="action" fontSize="small" />
          )
      }}
    />
  );
}
