import React from 'react';
import { User } from 'model';
import { UserDisplay } from './UserDisplay';
import { RolesChips } from './role/RolesChips';

export function UserCardDisplay({ value }: { value: User }) {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ paddingRight: 20 }}>
        <UserDisplay value={value} />
      </div>
      <RolesChips user={value} />
    </div>
  );
}
