import React from 'react';
import { useTranslation } from 'react-i18next';
import { PriceBoundDTO, priceBoundTransformer } from 'model';
import * as yup from 'yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { Grid } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { DialogForm } from '../../../components/input/form/DialogForm';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import priceBoundsSlice, {
  priceBoundsSelectors
} from '../redux/price-bounds.slice';
import { buildDTOView } from '../../../transformer/DTOViewSchema';
import { definePriceBoundThunk } from '../redux/price-bounds.thunk';
import { NoOp } from '../../../utils';
import { FormikEntitySelect } from '../../../components/select/FormikEntitySelect';
import { serviceClassAPI } from '../../services/redux/service-classes.slice';
import { serviceStepAPI } from '../../services/redux/service-steps.slice';
import { priceUnitsAPI } from '../redux/price-units.slice';
import { FormikPriceInput } from '../../clients/components/prices/FormikPriceInput';

const priceBoundView = buildDTOView<PriceBoundDTO>()
  .all()
  .withSchema(
    yup
      .object()
      .defined()
      .shape({
        serviceStepId: yup.string().defined().nullable().default(null),
        priceLimit: yup.number().required(),
        priceUnitId: yup.string().defined().nullable().default(null),
        rangeTarget: yup.mixed().oneOf(['client', 'supplier']),
        serviceClassId: yup.string().defined().nullable().default(null)
      })
  );

// Todo Daniel ServiceStep constraints
const getInitialValues = (target: 'client' | 'supplier'): PriceBoundDTO => ({
  priceUnitId: null,
  serviceClassId: null,
  priceLimit: 0,
  serviceStepId: null,
  rangeTarget: target
});

export function SupplierPriceFormDialog({
  target
}: {
  target: 'supplier' | 'client';
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { type, reference } = useEditorLocation();

  const bound = useAppSelector((state) =>
    priceBoundsSelectors.selectById(state, reference ?? '')
  );

  const updateLimit = (dto: PriceBoundDTO) => {
    return dispatch(
      definePriceBoundThunk({
        input: dto
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <DialogForm
      label={`New ${target} price bound`}
      identifier={type === 'edit' ? reference : 'pricebound'}
      form={{
        initialValues:
          type === 'edit' && bound
            ? (priceBoundTransformer(bound) as PriceBoundDTO)
            : getInitialValues(target),
        validationSchema: priceBoundView.validationSchema
      }}
      api={{
        clearError: priceBoundsSlice.actions.clearError,
        errorSelector: priceBoundsSelectors.selectError,
        onSubmit: updateLimit,
        stateSelector: priceBoundsSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormikEntitySelect
            textFieldProps={{ label: t('Service Class') }}
            entityApi={serviceClassAPI}
            optionLabel={(value) => value.name}
            name={priceBoundView.path.serviceClassId}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikEntitySelect
            textFieldProps={{ label: t('Service Step') }}
            entityApi={serviceStepAPI}
            optionLabel={(value) => value.name}
            name={priceBoundView.path.serviceStepId}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikEntitySelect
            textFieldProps={{ label: t('Unit') }}
            entityApi={priceUnitsAPI}
            optionLabel={(value) => value.name}
            name={priceBoundView.path.priceUnitId}
          />
        </Grid>

        <Grid item xs={12}>
          <FormikPriceInput
            amountPath={priceBoundView.path.priceLimit}
            label={t('Price Limit')}
          />
        </Grid>
      </Grid>
    </DialogForm>
  );
}
