import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { largeScaleProjectSelectors } from '../redux/large-scale-projects.slice';
import { largeScaleProjectThunk } from '../redux/large-scale-projects.thunk';

export function useLargeScaleProjects() {
  const dispatch = useAppDispatch();

  const largeScaleProjects = useAppSelector(
    largeScaleProjectSelectors.selectAll
  );

  const apiState = useAppSelector(largeScaleProjectSelectors.selectState);

  const listLargeScaleProjects = () => dispatch(largeScaleProjectThunk.list());

  React.useEffect(() => {
    listLargeScaleProjects();
  }, []);

  return {
    largeScaleProjects,
    apiState,
    listLargeScaleProjects
  };
}
