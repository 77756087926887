"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.projectDataParser = exports.ProjectImportData = void 0;
const lodash_1 = require("lodash");
const csv_columns_descriptor_1 = require("../../csv/csv-columns.descriptor");
const column_descriptor_1 = require("../../csv/column-descriptor");
class ProjectImportData {
}
exports.ProjectImportData = ProjectImportData;
const projectDataParser = (supplierSource, clientSource, projectSource) => {
    return csv_columns_descriptor_1.CSVColumnBuilder.create()
        .skip(3)
        .append({
        projectManagerIds: column_descriptor_1.ColumnDescriptor.createList(',').withValidation((d) => d.every((di) => di !== '' && supplierSource(di) !== null))
    })
        .skip(1)
        .append({
        clientNumber: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((d) => /^[0-9]{5}$/.test(d) && clientSource(d) !== null)
            .orEmpty(null),
        projectNumber: column_descriptor_1.ColumnDescriptor.create().withValidation((d) => /^[0-9]{5}-[0-9]{2}-[0-9]{2,4}$/.test(d) && projectSource(d) !== null),
        projectName: column_descriptor_1.ColumnDescriptor.create()
    })
        .skip(2)
        .append({
        /* clientOffer: ColumnDescriptor.create()
          .toCase('lower')
          .valueMap({ nein: false, ja: true }), */
        createdInWordbee: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ nein: false, ja: true })
            .orEmpty(false),
        comment: column_descriptor_1.ColumnDescriptor.create().orEmpty(null)
    })
        .skip(7)
        .append({
        deliveryDate: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((v) => /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/.test(v))
            .orEmpty(null),
        tmConsolidated: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ nein: false, ja: true, 'n/a': false })
            .orEmpty(false),
        delivered: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ nein: false, ja: true }),
        final: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ nein: false, ja: true }),
        invoiceExists: column_descriptor_1.ColumnDescriptor.create()
            .toCase('lower')
            .valueMap({ nein: false, ja: true }),
        invoiceNumber: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((v) => /^[0-9]+$/.test(v))
            .orEmpty(null)
    })
        .build({
        validateData: (data) => {
            /*        const projectNumbers = data
                        .map((d) => d.projectNumber)
                        .filter((d) => !!d); */
            const numberMap = {};
            data.forEach((d, i) => {
                if (d.projectNumber) {
                    if (!numberMap[d.projectNumber]) {
                        numberMap[d.projectNumber] = [];
                    }
                    numberMap[d.projectNumber].push(i);
                }
            });
            return Object.entries(numberMap)
                .filter(([, n]) => n.length > 1)
                .map(([, r]) => ({
                rows: r,
                cells: ['projectNumber'],
                message: `Duplicate project number`
            }));
        },
        exclude: (data) => Object.values((0, lodash_1.pick)(data, 'projectManagerId', 'clientId', 'projectNumber', 'projectName')).every((v) => v === '' || v === '#NV'),
        validateRow: (data) => {
            const result = [];
            if ((data.invoiceExists && data.invoiceNumber === null) ||
                (!data.invoiceExists && data.invoiceNumber !== null)) {
                result.push({
                    cells: ['invoiceExists', 'invoiceNumber'],
                    message: 'invoice state inconsistency'
                });
            }
            if (data.final && !data.delivered) {
                result.push({
                    cells: ['delivered', 'final'],
                    message: 'final but not delivered'
                });
            }
            if ((data.delivered && data.deliveryDate === null) ||
                (!data.delivered && data.deliveryDate !== null)) {
                result.push({
                    cells: ['delivered', 'deliveryDate'],
                    message: 'delivery state inconsistency'
                });
            }
            /* const confirmed = [
              'Angebot bestätigt',
              'erfolgreich akquiriert'
            ].includes(data.status);
            if (confirmed === !data.projectNumber) {
              result.push({
                cells: ['projectNumber', 'status'],
                message: 'status inconsistency'
              });
            }
            const offerStatus = [
              'Angebot abgelehnt',
              'Angebot unsererseits abgelehnt',
              'Angebot bestätigt',
              'erfolgreich akquiriert',
              'Keine Antwort'
            ].includes(data.status);
            if (
              offerStatus === !data.dateOfOffer &&
              data.status !== 'warten auf Antwort'
            ) {
              result.push({
                cells: ['status', 'dateOfOffer'],
                message: 'status inconsistency (no offer date)'
              });
            }
            /!*        if (confirmed === !data.dateOfOffer) {
                        result.push({
                          cells: ['status', 'dateOfOffer'],
                          message: 'status inconsistency'
                        });
                      } *!/
            if (confirmed === !data.dateOfOrder) {
              result.push({
                cells: ['status', 'dateOfOrder'],
                message: 'status inconsistency (no order date)'
              });
            }
            if (!data.dateOfOffer && data.dateOfOrder) {
              result.push({
                cells: ['dateOfOffer', 'dateOfOrder'],
                message: 'order date without offer date'
              });
            } */
            return result;
        }
    });
};
exports.projectDataParser = projectDataParser;
