import React, { useState } from 'react';
import { Chip, TextField } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      flexGrow: 1
    },
    adornmentContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    chipList: {
      width: '100%'
    },
    chip: {
      margin: theme.spacing(0, 0.5, 0.5, 0)
    }
  })
);

interface GenericTableToolbarProps {
  extraSearchKeys?: { onDelete: () => void; label: React.ReactNode }[];
  search: {
    keys: string[];
    setKeys: (keys: string[]) => void;
  };
  uiMode: 'widget' | 'standalone';
}

export default function SearchBar(props: GenericTableToolbarProps) {
  const classes = useStyles();

  const [newSearchKey, setNewSearchKey] = useState('');

  const addSearchKey = () => {
    props.search.setKeys([...props.search.keys, newSearchKey]);
    setNewSearchKey('');
  };

  const removeSearchKey = (t: string) => {
    props.search.setKeys(props.search.keys.filter((oldTag) => oldTag !== t));
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'Enter':
        return addSearchKey();
      case 'Escape':
        return setNewSearchKey('');
      default:
        return null;
    }
  };

  return (
    <div className={classes.search}>
      <TextField
        fullWidth
        value={newSearchKey}
        onChange={(event) => setNewSearchKey(event.target.value)}
        variant="outlined"
        onKeyUp={handleKeyUp}
        inputProps={{
          style: { padding: props.uiMode === 'widget' ? '0.5em' : undefined }
        }}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          endAdornment: (
            <div className={classes.adornmentContainer}>
              <SearchIcon />
              {props.extraSearchKeys &&
                props.extraSearchKeys.map((esk) => (
                  <Chip
                    className={classes.chip}
                    label={esk.label}
                    onDelete={esk.onDelete}
                  />
                ))}
              {props.search.keys.map((tag) => (
                <Chip
                  className={classes.chip}
                  label={tag}
                  onDelete={() => removeSearchKey(tag)}
                />
              ))}
            </div>
          )
        }}
      />
    </div>
  );
}
