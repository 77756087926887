import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core';
import { userPatchTransformer } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { Route, Link as RouterLink } from 'react-router-dom';
import { ThemeToggle } from '../../components/input/switch/ThemeToggle';
import { LogoutButton } from '../auth/components/LogoutButton';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { EditableCard } from '../../components/input/card/EditableCard';
import { NoOp } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux/redux.hooks';
import { selectUserAsNullable } from '../auth/redux/auth.slice';
import { UserProfileForm } from '../users/components/UserProfileForm';
import {
  patchUserProfileThunk,
  PatchUserProfileThunkInput
} from '../users/redux/actions/patch-user-profile.thunk';
import { AboutDialog } from './components/AboutDialog';

export function SettingsScreen() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUserAsNullable);

  const [newAvatarFile, setNewAvatarFile] = React.useState<File>();

  const patchUser = (input: PatchUserProfileThunkInput) =>
    dispatch(
      patchUserProfileThunk({
        userId: user?.id || '',
        input: { displayName: input.displayName, avatarFile: newAvatarFile }
      })
    )
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <ErrorBoundary context="settingsScreens">
      {user ? (
        <div>
          <Typography variant="h4">{t('Settings')}</Typography>
          <Grid style={{ marginTop: 30 }} container spacing={2}>
            <Grid item sm={12} md={12} xl={12}>
              <EditableCard
                identifier="user-profile"
                title={t('User')}
                onSubmit={patchUser}
                value={userPatchTransformer(user)}
              >
                <UserProfileForm
                  onSelectAvatar={setNewAvatarFile}
                  user={user}
                />
              </EditableCard>
            </Grid>
            <Grid item sm={12} md={12} xl={12}>
              <Card>
                <CardHeader title={t('Theme color')} />
                <CardContent>
                  <ThemeToggle />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <div style={{ position: 'absolute', bottom: 30 }}>
            <LogoutButton />
            <Button
              style={{ left: 30 }}
              variant="contained"
              color="secondary"
              component={RouterLink}
              to="/settings/about"
            >
              {t('About this App')}
            </Button>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
      <Route path="/settings/about">
        <AboutDialog />
      </Route>
    </ErrorBoundary>
  );
}
