"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceQualification = void 0;
const typeorm_1 = require("typeorm");
// eslint-disable-next-line import/no-cycle
const ServiceClass_1 = require("./ServiceClass");
const Qualification_1 = require("../../supplier/entity/Qualification");
// eslint-disable-next-line import/no-cycle
const ServiceStep_1 = require("./ServiceStep");
const CircularReference_1 = require("../../serialization/CircularReference");
let ServiceQualification = class ServiceQualification {
    constructor(init) {
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => ServiceClass_1.ServiceClass, (s) => s.requiredQualifications),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", ServiceClass_1.ServiceClass)
], ServiceQualification.prototype, "serviceClass", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sq) => sq.serviceClass),
    (0, typeorm_1.PrimaryColumn)(),
    __metadata("design:type", String)
], ServiceQualification.prototype, "serviceClassId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Qualification_1.Qualification, undefined),
    __metadata("design:type", Qualification_1.Qualification)
], ServiceQualification.prototype, "qualification", void 0);
__decorate([
    (0, typeorm_1.RelationId)((sq) => sq.qualification),
    (0, typeorm_1.PrimaryColumn)(),
    __metadata("design:type", String)
], ServiceQualification.prototype, "qualificationId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ServiceStep_1.ServiceStep, undefined, { nullable: true }),
    __metadata("design:type", ServiceStep_1.ServiceStep)
], ServiceQualification.prototype, "serviceStep", void 0);
ServiceQualification = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], ServiceQualification);
exports.ServiceQualification = ServiceQualification;
