import {
  createEntityAdapter,
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit';
import { InitialDataAggregation, Language } from 'model';
import { getInitialApiAdapterState } from '../../auth/redux/types';
import { RootState } from '../../../redux/store';
import { listLanguagesThunk } from './actions/list-languages.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { fetchInitialDataThunk } from '../../../redux/initial-data.thunk';

const languageAdapter = createEntityAdapter<Language>();

const languagesSlice = createSlice({
  name: 'languages',
  initialState: languageAdapter.getInitialState(getInitialApiAdapterState()),
  reducers: {
    clearLanguagesError(state) {
      state.error = null;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(listLanguagesThunk.fulfilled, (state, { payload }) => {
        state.state = 'idle';
        state.listFetched = true;
        languageAdapter.upsertMany(state, payload);
      })
      .addMatcher(isPending(listLanguagesThunk), (state) => {
        state.state = 'pending';
        state.error = null;
      })
      .addMatcher(isRejected(listLanguagesThunk), (state, action) => {
        state.state = 'idle';
        state.error = action.error;
      })
      .addMatcher(isFulfilled(fetchInitialDataThunk), (state, { payload }) => {
        const dataPayload = payload as InitialDataAggregation;
        if (dataPayload.languages) {
          state.state = state.state === 'fresh' ? 'idle' : state.state;
          state.listFetched = true;
          return languageAdapter.upsertMany(state, dataPayload.languages);
        }
        return state;
      })
});

const selectors = languageAdapter.getSelectors(
  (state: RootState) => state.languages
);
export const {
  selectById: selectLanguageById,
  selectIds: selectLanguageIds,
  selectEntities: selectLanguageEntities,
  selectAll: selectAllLanguages,
  selectTotal: selectTotalLanguage
} = selectors;

export const { clearLanguagesError } = languagesSlice.actions;
export const selectLanguagesError = (state: RootState) => state.languages.error;
export const selectLanguagesState = (state: RootState) => state.languages.state;
export const selectLanguagesListFetched = (state: RootState) =>
  state.languages.listFetched;

export const languageAPI: EntityAPI<Language> = {
  selectors: {
    ...selectors,
    selectError: selectLanguagesError,
    selectState: selectLanguagesState,
    selectListFetched: selectLanguagesListFetched,
    selectSubQuery: (type) => (state) => state.languages.subQueries?.[type]
  },
  thunks: { list: listLanguagesThunk } as any
};

export default languagesSlice.reducer;
