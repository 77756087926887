import React from 'react';
import { useField } from 'formik';
import { SupplierQualificationDTO } from 'model';
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Tooltip
} from '@material-ui/core';
import { useSupplierQualifications } from '../../../hooks/supplier-qualification.hook';
import { ErrorBoundary } from '../../../../error/ErrorBoundary';

export function SupplierQualificationsForm() {
  const qualifications = useSupplierQualifications();
  const [, meta, helpers] =
    useField<SupplierQualificationDTO[]>('qualifications');
  const assignedQualificationsMap = Object.fromEntries(
    meta.value.map((sq) => [sq.qualificationId, sq])
  );

  return (
    <ErrorBoundary context="SupplierQualificationsForm">
      <List dense>
        {qualifications.supplierQualifications.map((q) => {
          return (
            <ListItem dense>
              <ListItemIcon>
                <Checkbox
                  checked={!!assignedQualificationsMap[q.id]}
                  onChange={(_, checked) => {
                    if (checked) {
                      if (!assignedQualificationsMap[q.id]) {
                        helpers.setValue([
                          ...meta.value,
                          {
                            qualificationId: q.id,
                            details: ''
                          }
                        ]);
                      }
                    } else {
                      helpers.setValue(
                        meta.value.filter((sq) => sq.qualificationId !== q.id)
                      );
                    }
                  }}
                />
              </ListItemIcon>
              <Tooltip title={q.description}>
                <span>{q.name}</span>
              </Tooltip>
              {assignedQualificationsMap[q.id] && (
                <TextField
                  style={{ marginLeft: 10 }}
                  color="secondary"
                  value={assignedQualificationsMap[q.id].details}
                  onChange={(e) => {
                    helpers.setValue(
                      meta.value.map((sq) => {
                        if (sq.qualificationId === q.id) {
                          return { ...sq, details: e.target.value };
                        }
                        return sq;
                      })
                    );
                  }}
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </ErrorBoundary>
  );
}
