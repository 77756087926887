import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SupplierLanguagesForm } from './SupplierLanguagesForm';
import { SupplierQualificationsForm } from './SupplierQualificationsForm';
import { SupplierDomainsForm } from './SupplierDomainsForm';
import { SupplierCatToolsForm } from './SupplierCatToolsForm';
import { ErrorBoundary } from '../../../../error/ErrorBoundary';

export function SupplierSkillSetForm() {
  const { t } = useTranslation();
  return (
    <ErrorBoundary context="SupplierSkillSetForm">
      <Typography variant="caption" color="textSecondary">
        {t('Languages')}
      </Typography>
      <SupplierLanguagesForm />
      <Typography variant="caption" color="textSecondary">
        {t('Qualifications')}
      </Typography>
      <SupplierQualificationsForm />
      <Typography variant="caption" color="textSecondary">
        {t('Domains')}
      </Typography>
      <SupplierDomainsForm />
      <Typography variant="caption" color="textSecondary">
        {t('CAt Tools')}
      </Typography>
      <SupplierCatToolsForm />
    </ErrorBoundary>
  );
}
