"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.regionIncludes = exports.RegionTreeList = exports.WorldRegion = exports.RegionCodeMap = exports.CountryCodeMap = exports.countryToFlagCode = void 0;
// eslint-disable-next-line import/no-cycle
const WorldRegionData_1 = require("./WorldRegionData");
function countryToFlagCode(country) {
    const isoCode = typeof country === 'string' ? country : country.shortCode;
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}
exports.countryToFlagCode = countryToFlagCode;
function mergeWithChildren(node, parent) {
    const area = {
        ...(node.type === 'region'
            ? { ...node, children: [], code: node.id }
            : { ...node, code: node.shortCode }),
        parent
    };
    if (parent) {
        parent.children.push(area);
    }
    return node.type === 'country'
        ? [area]
        : [
            area,
            ...node.children.flatMap((c) => mergeWithChildren(c, area))
        ];
}
const InofficialCountries = ['CS', 'XK'];
const RegionIdMap = Object.fromEntries(mergeWithChildren(WorldRegionData_1.WorldRegionData, null)
    .concat([
    {
        type: 'other',
        id: 'AA',
        name: 'Other',
        code: 'AA',
        parent: null
    }
])
    .map((r) => [r.id, r]));
exports.CountryCodeMap = Object.fromEntries(Object.values(RegionIdMap)
    .filter(isCountryArea)
    .map((c) => [c.shortCode, c]));
exports.RegionCodeMap = {
    ...RegionIdMap,
    ...exports.CountryCodeMap
};
InofficialCountries.forEach((c) => {
    delete exports.CountryCodeMap[c];
});
exports.WorldRegion = RegionIdMap['001'];
function isCountryArea(r) {
    return r.type === 'country';
}
function expandTreeNodes(area, depth = 0) {
    return [
        { area, depth },
        ...(area.type === 'region'
            ? area.children.flatMap((c) => expandTreeNodes(c, depth + 1))
            : [])
    ];
}
exports.RegionTreeList = expandTreeNodes(exports.WorldRegion);
function regionIncludes(parent, child) {
    return (parent.id === child.id ||
        (child.parent !== null &&
            parent.type === 'region' &&
            parent.children.some((s) => regionIncludes(s, child))));
}
exports.regionIncludes = regionIncludes;
