import { useQuery } from './query-params.hook';
import { useLocationHash } from './location-hash.hook';

export type EditorType = 'create' | 'edit' | 'view' | 'duplicate';

export interface EditorLocationState {
  reference: string | undefined;
  type: EditorType;
}

export function useEditorLocation(): EditorLocationState {
  const params = useQuery();
  const reference = useLocationHash();
  const typeValue = params.get('type') ?? 'view';
  return {
    reference,
    type: (['create', 'edit', 'view', 'duplicate'].includes(typeValue)
      ? typeValue
      : 'view') as EditorType
  };
}
