import React, { ReactNode } from 'react';
import {
  Card,
  CardContent,
  CardContentProps,
  CardProps
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StyledCardHeader, CardHeaderAction } from './StyledCardHeader';
import { ErrorBoundary } from '../../../features/error/ErrorBoundary';

export interface CardWithHeaderProps {
  title: ReactNode;
  cardProps?: CardProps;
  cardContentProps?: CardContentProps;
  actions?: CardHeaderAction[];
  children: ReactNode;
  focused?: boolean;
}

export function CardWithHeader(props: CardWithHeaderProps) {
  const { t } = useTranslation();
  return (
    <ErrorBoundary
      context={`CardWithHeader ${
        typeof props.title === 'string' ? props.title : ''
      }`}
    >
      <Card {...props.cardProps} raised={props.focused}>
        <StyledCardHeader
          title={typeof props.title === 'string' ? t(props.title) : props.title}
          actions={props.actions || []}
        />
        <CardContent style={{ height: '100%' }} {...props.cardContentProps}>
          {props.children}
        </CardContent>
      </Card>
    </ErrorBoundary>
  );
}
