import {
  ServiceLanguageConfig,
  SupplierLanguage,
  SupplierService
} from 'model';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useServiceClass } from '../../../services/hooks/service-class.hook';
import { IETFIcon } from '../../../languages/components/IETFIcon';
import { useLanguageIndex } from '../../../languages/hooks/language-index.hook';
import PopoverPreview from '../../../../components/preview/PopoverPreview';

interface SupplierColumnRendererProps {
  supplierServices: SupplierService[];
  languages: SupplierLanguage[];
}

function SupplierServiceRow(props: {
  languages: SupplierLanguage[];
  service: SupplierService;
}) {
  const { serviceClass } = useServiceClass(props.service.serviceClassId);

  const nativeLanguages = props.languages
    .filter((l) => l.native)
    .map((l) => l.languageId);

  const otherLanguages = (
    nativeLanguages.length < 2
      ? props.languages.filter((l) => !l.native)
      : props.languages
  ).map((l) => l.languageId);

  const languageIndex = useLanguageIndex();
  const calcAllLanguages = () => {
    if (!serviceClass || serviceClass.type === 'no-language') {
      return [];
    }
    if (serviceClass.type === 'only-target') {
      return nativeLanguages.map((li) => ({
        sourceLanguage: undefined,
        targetLanguage: li
      }));
    }
    return nativeLanguages.flatMap((nl) =>
      otherLanguages
        .filter((ol) => ol !== nl)
        .map((ol) => ({
          sourceLanguage: ol,
          targetLanguage: nl
        }))
    );
  };
  const languagePossibilities: ServiceLanguageConfig[] = props.service.languages
    ?.length
    ? props.service.languages
    : calcAllLanguages();

  return (
    <div>
      {Object.keys(languageIndex.descriptors).length !== 0 && (
        <PopoverPreview
          ready
          content={
            <div>
              {languagePossibilities.map((lP) => {
                if (
                  lP.sourceLanguage === null ||
                  lP.sourceLanguage === undefined
                ) {
                  return (
                    <>
                      <IETFIcon
                        tag={
                          languageIndex.descriptors[lP.targetLanguage]
                            .specification
                        }
                      />
                      {
                        languageIndex.descriptors[lP.targetLanguage].language
                          .languageCode
                      }
                    </>
                  );
                }
                return (
                  <>
                    <IETFIcon
                      tag={
                        languageIndex.descriptors[lP.sourceLanguage ?? '']
                          .specification
                      }
                    />
                    {
                      languageIndex.descriptors[lP.sourceLanguage ?? '']
                        .language.languageCode
                    }{' '}
                    {'->'}{' '}
                    <IETFIcon
                      tag={
                        languageIndex.descriptors[lP.targetLanguage]
                          .specification
                      }
                    />
                    {
                      languageIndex.descriptors[lP.targetLanguage].language
                        .languageCode
                    }
                  </>
                );
              })}
            </div>
          }
        >
          <Typography variant="inherit">{serviceClass?.name}</Typography>
        </PopoverPreview>
      )}
    </div>
  );
}

export function ServicesColumnRenderer(props: SupplierColumnRendererProps) {
  return (
    <div>
      {props.supplierServices.map((service) => (
        <SupplierServiceRow languages={props.languages} service={service} />
      ))}
    </div>
  );
}
