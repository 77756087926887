import React from 'react';
import { Supplier, SupplierServiceStep } from 'model';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { StyledLink } from '../../../../components/input/link/StyledLink';
import { useServiceStep } from '../../../services/hooks/service-step.hook';

interface SupplierServiceStepDisplayProps {
  supplierServiceStep: SupplierServiceStep;
}

function SupplierServiceStepDisplay({
  supplierServiceStep
}: SupplierServiceStepDisplayProps) {
  const { serviceStep } = useServiceStep(supplierServiceStep.serviceStepId);

  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1}>
        <StyledLink
          variant="body1"
          to={`/services/service-step/${serviceStep?.id}`}
        >
          {serviceStep ? serviceStep.name : <Skeleton />}
        </StyledLink>
      </Box>
      <Typography variant="subtitle1" color="textSecondary">
        {serviceStep ? supplierServiceStep.comment : <Skeleton />}
      </Typography>
    </Box>
  );
}

interface SupplierServiceStepsDisplayProps {
  value: Supplier;
}

export function SupplierServiceStepsDisplay({
  value
}: SupplierServiceStepsDisplayProps) {
  return (
    <>
      <Typography variant="caption" color="textSecondary">
        Steps
      </Typography>
      {value.serviceSteps?.map((serviceStep) => (
        <SupplierServiceStepDisplay supplierServiceStep={serviceStep} />
      ))}
    </>
  );
}
