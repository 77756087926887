"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitialServicePositionDTO = void 0;
const omit_type_helper_1 = require("../../extern-libs/mapped-types/omit-type.helper");
const ServiceRequestPositionDTO_1 = require("./ServiceRequestPositionDTO");
const intersection_type_helper_1 = require("../../extern-libs/mapped-types/intersection-type.helper");
const InitialRequestPositionDTO_1 = require("./InitialRequestPositionDTO");
class InitialServicePositionDTO extends (0, intersection_type_helper_1.IntersectionType)(InitialRequestPositionDTO_1.InitialRequestPositionDTO, (0, omit_type_helper_1.OmitType)(ServiceRequestPositionDTO_1.ServiceRequestPositionDTO, ['resourceIds', 'requiredPositionIds'])) {
}
exports.InitialServicePositionDTO = InitialServicePositionDTO;
