"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepSequenceTemplate = void 0;
const typeorm_1 = require("typeorm");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const ServiceClass_1 = require("./ServiceClass");
const ProjectVariation_1 = require("./ProjectVariation");
// eslint-disable-next-line import/no-cycle
const SequenceTemplateStep_1 = require("./SequenceTemplateStep");
let StepSequenceTemplate = class StepSequenceTemplate extends JournaledEntity_1.JournaledEntity {
};
__decorate([
    (0, typeorm_1.Column)({ unique: true }),
    __metadata("design:type", String)
], StepSequenceTemplate.prototype, "name", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectVariation_1.ProjectVariation, undefined, { nullable: true }),
    __metadata("design:type", ProjectVariation_1.ProjectVariation)
], StepSequenceTemplate.prototype, "projectVariation", void 0);
__decorate([
    (0, typeorm_1.RelationId)((t) => t.projectVariation),
    __metadata("design:type", String)
], StepSequenceTemplate.prototype, "projectVariationId", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'varchar', array: true, nullable: true }),
    __metadata("design:type", Array)
], StepSequenceTemplate.prototype, "serviceClassTypes", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ServiceClass_1.ServiceClass, undefined, { nullable: true }),
    __metadata("design:type", ServiceClass_1.ServiceClass)
], StepSequenceTemplate.prototype, "serviceClass", void 0);
__decorate([
    (0, typeorm_1.RelationId)((t) => t.serviceClass),
    __metadata("design:type", String)
], StepSequenceTemplate.prototype, "serviceClassId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], StepSequenceTemplate.prototype, "scope", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", Number)
], StepSequenceTemplate.prototype, "childPosition", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => SequenceTemplateStep_1.SequenceTemplateStep, (sts) => sts.stepSequenceTemplate),
    __metadata("design:type", Array)
], StepSequenceTemplate.prototype, "sequenceSteps", void 0);
StepSequenceTemplate = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.Unique)(['name'])
], StepSequenceTemplate);
exports.StepSequenceTemplate = StepSequenceTemplate;
