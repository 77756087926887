import React from 'react';
import {
  OrderStepTask,
  ServiceClass,
  ServiceTaskDTO,
  StepTaskDTO
} from 'model';
import { useTranslation } from 'react-i18next';
import { Button, Grid, IconButton, Popover } from '@material-ui/core';
import { ArrowForward, Check } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import PlusIcon from '@material-ui/icons/Add';
import { unwrapResult } from '@reduxjs/toolkit';
import { EntityReferenceSelect } from '../../../../components/select/EntityReferenceSelect';
import { serviceClassAPI } from '../../../services/redux/service-classes.slice';
import { LanguageSelect } from '../../../languages/components/LanguageSelect';
import { MultipleLanguageSelect } from '../../../languages/components/MultipleLanguageSelect';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import {
  createOrderSubTaskThunk,
  projectOrderTasksThunk
} from '../../redux/project-order-tasks.thunk';
import { NoOp } from '../../../../utils';
import { useProject } from '../../hooks/project.hook';
import { useSequenceTemplateSelector } from '../../../services/hooks/sequence-template-selector.hook';

export function ServiceGroupCreator({ projectId }: { projectId: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [serviceClass, setServiceClass] = React.useState<ServiceClass | null>(
    null
  );

  const [sourceLanguage, setSourceLanguage] = React.useState<string | null>(
    null
  );

  const { project } = useProject(projectId);

  const [targetLanguages, setTargetLanguages] = React.useState<string[]>([]);

  const [anchor, setAnchor] = React.useState<HTMLButtonElement>();

  const template = useSequenceTemplateSelector(
    project?.projectVariationId ?? null,
    'service-lane'
  );

  const submit = () => {
    setAnchor(undefined);
    dispatch(
      projectOrderTasksThunk.create({
        projectId,
        input: {
          task: {
            taskType: 'service-group-task',
            state: 'pending',
            description: '',
            deadline: null,
            attachedFiles: [],
            dependencies: [],
            serviceClassId: serviceClass?.id as string,
            sourceLanguageId: sourceLanguage
          }
        }
      })
    )
      .then(unwrapResult)
      .then((groupTask) => {
        if (serviceClass?.type === 'no-language') {
          const dto: ServiceTaskDTO = {
            attachedFiles: [],
            deadline: null,
            dependencies: [],
            description: '',
            state: 'pending',
            targetLanguageId: null,
            taskType: 'service-task'
          };
          return Promise.all([
            dispatch(
              createOrderSubTaskThunk({
                projectId,
                parentId: groupTask.id,
                input: { task: dto },
                reload: false
              })
            )
          ]);
        }
        return Promise.all(
          targetLanguages.map((tl) => {
            const dto: ServiceTaskDTO = {
              attachedFiles: [],
              deadline: null,
              dependencies: [],
              description: '',
              state: 'pending',
              targetLanguageId: tl,
              taskType: 'service-task'
            };
            return dispatch(
              createOrderSubTaskThunk({
                projectId,
                parentId: groupTask.id,
                input: { task: dto },
                reload: false
              })
            );
          })
        );
      })
      .then((res) => {
        return Promise.all(
          template
            ? res.map(({ payload: lane }: any) => {
                return template.sequenceSteps
                  .map((step) => {
                    const taskInstance: StepTaskDTO = {
                      deadline: null,
                      clientContactAssigneeId: null,
                      projectManagerAssigneeId: null,
                      supplierAssigneeId: null,
                      attachedFiles: [],
                      dependencies: [],
                      taskType: 'step-task',
                      state: 'pending',
                      description: step.serviceStep.name,
                      serviceStepId: step.serviceStep.id,
                      phase: 'provision'
                      /*  step.position > template.childPosition
                          ? 'post-delivery'
                          : 'preparation' */
                    };
                    return taskInstance;
                  })
                  .reduce<Promise<null | OrderStepTask>>(async (acc, c) => {
                    const resolved = await acc;
                    const dependencies = resolved === null ? [] : [resolved.id];
                    // eslint-disable-next-line promise/no-nesting
                    return dispatch(
                      createOrderSubTaskThunk({
                        projectId,
                        parentId: lane.id,
                        input: { task: Object.assign(c, { dependencies }) },
                        reload: false
                      })
                    )
                      .then((res1) => res1.payload as OrderStepTask)
                      .catch(NoOp) as Promise<OrderStepTask | null>;
                  }, Promise.resolve(null) as Promise<OrderStepTask | null>);
              })
            : []
        );
      })
      .then((res) => {
        dispatch(projectOrderTasksThunk.list({ projectId }));
        return res;
      })
      .catch(NoOp);
  };
  return (
    <>
      <Button
        size="small"
        style={{
          color: 'grey'
        }}
        startIcon={<PlusIcon edgeMode="start" />}
        onClick={(event) => setAnchor(event.currentTarget)}
      >
        {t('Service')}
      </Button>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(undefined)}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        <Grid
          container
          spacing={2}
          style={{ padding: 20, minWidth: 300, overflow: 'hidden' }}
        >
          <Grid item xs={12}>
            <EntityReferenceSelect
              currentValue={serviceClass}
              textFieldProps={{ label: t('Service') }}
              entityApi={serviceClassAPI}
              optionLabel={(value) => value.name}
              selectValue={setServiceClass}
              entity
            />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            {serviceClass?.type === 'source-target' && (
              <>
                <LanguageSelect
                  label={t('Source Language')}
                  value={sourceLanguage}
                  onChange={setSourceLanguage}
                />
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <ArrowForward />
                </div>
              </>
            )}
            {serviceClass && serviceClass?.type !== 'no-language' && (
              <Grid item xs={12}>
                <MultipleLanguageSelect
                  label={t('Target Languages')}
                  value={targetLanguages}
                  onChange={setTargetLanguages}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {serviceClass &&
              (serviceClass?.type !== 'source-target' || sourceLanguage) &&
              (serviceClass?.type === 'no-language' ||
                targetLanguages.length > 0) && (
                <IconButton onClick={submit}>
                  <Check />
                </IconButton>
              )}
            <IconButton onClick={() => setAnchor(undefined)}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
