import React from 'react';
import { PlainRequestPosition } from 'model';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { usePriceUnit } from '../../../../../prices/hooks/price-unit.hook';
import { formatDate } from '../../../../../../localized-formats';

interface PlainRequestPositionDisplayProps {
  requestPosition: PlainRequestPosition;
}

export function PlainRequestPositionDisplay({
  requestPosition
}: PlainRequestPositionDisplayProps) {
  const { t } = useTranslation();
  const { priceUnit } = usePriceUnit(requestPosition.priceUnitId);

  return (
    <div>
      <Typography>{requestPosition.name}</Typography>
      <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {requestPosition.estimatedAmount && (
            <div style={{ marginRight: 10 }}>
              <Typography variant="caption" color="textSecondary">
                Est. Amount
              </Typography>
              <Typography variant="body2">
                {requestPosition.estimatedAmount}
              </Typography>
            </div>
          )}
          {priceUnit && (
            <div style={{ marginRight: 10 }}>
              <Typography variant="caption" color="textSecondary">
                Price Unit
              </Typography>
              <Typography variant="body2">{priceUnit.name}</Typography>
            </div>
          )}
          {requestPosition.deadline && (
            <div>
              <Typography variant="caption" color="textSecondary">
                {t('Deadline')}
              </Typography>
              <Typography variant="body2">
                {formatDate(requestPosition.deadline, 'date')}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
