import React from 'react';
import { Typography } from '@material-ui/core';
import { CountrySelect } from './CountrySelect';
import {
  PropertyPath,
  useTypedField
} from '../../../transformer/DTOViewSchema';

export interface FormikCountrySelectProps {
  name: string | PropertyPath<any, string | null>;
  required?: boolean;
}

export function FormikCountrySelect({
  name,
  required
}: FormikCountrySelectProps) {
  const [, meta, helpers] = useTypedField<string | null>(name);

  return (
    <>
      <CountrySelect
        hasError={Boolean(meta.error)}
        required={required}
        currentCountry={meta.value || null}
        onChange={(val) => helpers.setValue(val)}
      />
      <Typography variant="caption" color="error">
        {meta.error}
      </Typography>
    </>
  );
}
