import React from 'react';
import { BaseEntity } from 'model';
import { useAppDispatch, useAppSelector } from './redux.hooks';
import { AdapterSelectors } from './enitySlice';
import { FeatureThunks } from './featureThunks';

export interface EntityAPI<T extends BaseEntity> {
  selectors: AdapterSelectors<T>;
  thunks: FeatureThunks<T, any, any>;
}

export function useEntityAPI<T extends BaseEntity>({
  selectors,
  thunks
}: EntityAPI<T>) {
  const dispatch = useAppDispatch();

  const entities = useAppSelector(selectors.selectAll);
  const apiState = useAppSelector(selectors.selectState);
  const apiError = useAppSelector(selectors.selectError);
  const listFetched = useAppSelector(selectors.selectListFetched);

  const listEntities = () => dispatch(thunks.list());

  React.useEffect(() => {
    if (
      apiState !== 'unauthorized' &&
      (apiState === 'fresh' || listFetched === false) &&
      !apiError
    ) {
      listEntities();
    }
  }, [apiState, listEntities]);

  return { entities, apiState, listEntities, listFetched };
}
