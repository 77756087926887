import { Typography } from '@material-ui/core';
import React from 'react';
import { BaseTableRow } from '../TableDataCell';
import { TextColumnFilter } from '../filter/TextColumnFilter';
import { ColumnFilter } from '../filter/column-filter.types';
import { DataCellRenderer } from './TableDataRenderer';

function isComparable(value: any) {
  return value !== null && value !== undefined;
}

const defaultElementRenderer = () => (value: any) =>
  value !== null ? (
    <Typography variant="body2">{String(value)}</Typography>
  ) : (
    <span>-</span>
  );
const defaultComparator =
  <T extends BaseTableRow>() =>
  (a: [any, T], b: [any, T]) => {
    const itemA = a[0]; // dataAccessor(a, cell) as any;
    const itemB = b[0]; // dataAccessor(b, cell) as any;
    if (isComparable(itemA) || isComparable(itemB)) {
      if (!isComparable(itemA)) {
        return 1;
      }
      if (!isComparable(itemB)) {
        return -1;
      }
      if (itemB < itemA) {
        return -1;
      }
      if (itemB > itemA) {
        return 1;
      }
    }
    return 0;
  };
export function DefaultCellRenderer<T extends BaseTableRow, D = any>(
  config?: Partial<
    Omit<DataCellRenderer<T, D>, 'filter'> & {
      filter?: DataCellRenderer<T, D>['filter'] | null;
    }
  >
): DataCellRenderer<T, D> {
  /*  const dataAccessor: CellDataAccessor<T, D> = (data, cell) =>
    cell.accessor(data); */
  return {
    createElement: config?.createElement ?? defaultElementRenderer(),
    createText: config?.createText ?? ((value) => [String(value)]),
    comparator: config?.comparator ?? defaultComparator(),
    filter:
      config?.filter !== null
        ? config?.filter ??
          (TextColumnFilter<T>({
            showReset: true
          }) as ColumnFilter<T, any, any>)
        : undefined
  };
}
