import { ProjectDTO } from 'model';
import { EntityId, unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import projectsSlice, { projectSelectors } from '../redux/projects.slice';
import { projectThunk } from '../redux/projects.thunk';
import { NoOp } from '../../../utils';
import { DialogForm } from '../../../components/input/form/DialogForm';
import { ProjectBasicForm, ProjectBasicView } from './basic/ProjectBasicForm';
import { CardWithHeader } from '../../../components/card/cardWithHeader/CardWithHeader';
import { useSuppliers } from '../../suppliers/hooks/suppliers.hook';
import { selectUserAsNullable } from '../../auth/redux/auth.slice';

export function ProjectFormDialog() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUserAsNullable);
  const { suppliers } = useSuppliers();
  const currentSupplier = suppliers.find((s) => s.userId === user?.id);

  const initialValues: ProjectDTO = {
    projectName: '',
    projectTagIds: [],
    projectVariationId: null,
    clientId: null as unknown as string,
    projectManagerId: (currentSupplier?.id || null) as unknown as string,
    supportingProjectManagerIds: [],
    createdInWordbee: false,
    tmConsolidated: false,
    projectStatus: 'not-yet-commisioned',
    deadline: null
  };

  const createProject = (dto: ProjectDTO) => {
    return dispatch(
      projectThunk.create({
        input: dto
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  const history = useHistory();

  const navigateToDetails = (id?: EntityId) =>
    history.replace(`/projects/projects/${id}`);

  return (
    <DialogForm
      identifier="project"
      maxWidth="md"
      label="New Project"
      form={{
        initialValues,
        validationSchema: ProjectBasicView.validationSchema
      }}
      api={{
        clearError: projectsSlice.actions.clearError,
        errorSelector: projectSelectors.selectError,
        onSubmit: createProject,
        stateSelector: projectSelectors.selectState
      }}
      actions={[
        { label: t('Save and Exit'), doSubmit: true },
        {
          label: t('Save and Details'),
          doSubmit: true,
          onClick: navigateToDetails
        }
      ]}
    >
      <CardWithHeader title="Basic">
        <ProjectBasicForm />
      </CardWithHeader>
    </DialogForm>
  );
}
