"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupOfferPosition = void 0;
const typeorm_1 = require("typeorm");
// eslint-disable-next-line import/no-cycle
const OfferPosition_1 = require("./OfferPosition");
// eslint-disable-next-line import/no-cycle
const ScalarOfferPosition_1 = require("./ScalarOfferPosition");
let GroupOfferPosition = class GroupOfferPosition extends OfferPosition_1.OfferPosition {
};
__decorate([
    (0, typeorm_1.OneToMany)(() => ScalarOfferPosition_1.ScalarOfferPosition, (p) => p.parentPosition),
    __metadata("design:type", Array)
], GroupOfferPosition.prototype, "positions", void 0);
__decorate([
    (0, typeorm_1.RelationId)((gop) => gop.positions),
    __metadata("design:type", Array)
], GroupOfferPosition.prototype, "positionIds", void 0);
GroupOfferPosition = __decorate([
    (0, typeorm_1.ChildEntity)('group')
], GroupOfferPosition);
exports.GroupOfferPosition = GroupOfferPosition;
