import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import { Client, ClientPrice } from 'model';
import { clientPricesThunk } from './actions/client-prices.thunk';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { RootState } from '../../../redux/store';
import { loadClientThunk } from './actions/load-client.thunk';

const { list, delete: deleteThunk, ...thunks } = clientPricesThunk;

const clientPricesSlice = createEntitySlice<ClientPrice>('client-prices', [
  CustomThunk.override<ClientPrice>(list, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.upsertMany(state, payload as ClientPrice[]);
    }
  }),
  CustomThunk.override<ClientPrice>(
    deleteThunk as AsyncThunk<
      EntityId,
      { clientId: string; id: EntityId },
      any
    >,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  CustomThunk.override<Client, ClientPrice>(loadClientThunk, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.upsertMany(state, (payload as Client).prices ?? []);
    }
  }),
  ...Object.values(thunks)
]);

export const clientPricesSelectors = {
  ...createAdapterSelectors('client-prices', clientPricesSlice.adapter),
  selectByClient(state: RootState, clientId: string) {
    return this.selectAll(state).filter((a) => a.clientId === clientId);
  }
};

export default clientPricesSlice;
