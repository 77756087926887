import { RequestSource, RequestSourceDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const requestSourceThunk = createFeatureThunks<
  RequestSource,
  RequestSourceDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/projects/request-source${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'request-source'
});
