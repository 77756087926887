import React from 'react';
import { useHistory } from 'react-router-dom';
import { Client, permissions } from 'model';
import { ContactMail as CopyEmailsIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  GenericTable,
  useCreateCells
} from '../../../../components/table/GenericTable';
import { useAppDispatch, useAppSelector } from '../../../../redux/redux.hooks';
import { listClientsThunk } from '../../redux/actions/list-clients.thunk';
import { clientSelectors } from '../../redux/clients.slice';
import { AddressColumnFilter } from '../AddressColumnFilter';
import { entityColumnBuilder } from '../../../../components/table/columns.builder';
import {
  AddressColumnRenderer,
  shortAddressPreview
} from './AdressCollumnRenderer';
import { ContactsColumnRenderer } from './ContactsColumnRenderer';
import { CommentColumnRenderer } from './CommentSummaryRenderer';
import { ClientLink } from '../basic/ClientLink';

function buildTableColumns() {
  return entityColumnBuilder<Client>()
    .addDefaultColumn('client_name', 'name', 'Name', {
      createElement: (_, client) => <ClientLink value={client} />
    })
    .addHiddenColumn('client_tax_id', 'taxId', 'Tax Id', {})
    .addDefaultColumn(
      'client_number',
      (c) => c.clientNumber?.number ?? null,
      'client number'
    )
    .addDefaultColumn('client_email', 'email', 'Email')
    .addDefaultColumn('client_phone', 'phone', 'Phone')
    .addHiddenDefaultColumn('client_ceo', 'ceo', 'CEO')
    .addHiddenDefaultColumn('client_description', 'description', 'Description')
    .addColumn('client_addresses', 'addresses', 'Addresses', {
      hiddenByDefault: true,
      renderer: {
        createElement: (_, data) => {
          return <AddressColumnRenderer client={data} />;
        },
        createText: (_, data) =>
          data.addresses.map((a) => shortAddressPreview(a)),
        filter: AddressColumnFilter()
      }
    })
    .addColumn('client_comments', 'comments', 'Comments', {
      hiddenByDefault: true,
      renderer: {
        createElement: (_, data) => <CommentColumnRenderer client={data} />,
        createText: (_, data) => [`${data.comments.length}`]
      }
    })
    .addColumn('client_contacts', 'contacts', 'main contacts', {
      renderer: {
        createElement: (_, data) => <ContactsColumnRenderer client={data} />,
        createText: (_, data) =>
          data.contacts
            .filter((c) => c.mainContact)
            .map((c) => `${c.name.first} ${c.name.last}`)
      }
    }).columns;
}

export function ClientsTable({ clients }: { clients: Client[] }) {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const refreshClients = () => {
    dispatch(listClientsThunk());
  };

  const history = useHistory();
  const apiState = useAppSelector(clientSelectors.selectState);

  const tableCells = useCreateCells(buildTableColumns(), []);

  return (
    <GenericTable
      toolbarActions={[
        {
          label: 'Copy Email adresses to clipboard',
          showWhen: 'selected',
          icon: <CopyEmailsIcon />,
          onClick: (selectedClients, allClients) => {
            const users =
              selectedClients.length === 0 ? allClients : selectedClients;
            navigator.clipboard.writeText(
              users.map((acc) => acc.email).join('; ')
            );
          }
        }
      ]}
      label={t('Clients')}
      refresh={refreshClients}
      rowActions={[]}
      primaryButton={{
        label: 'New Client',
        onClick: () => history.push('/clients?type=create#client'),
        permission: permissions.clients.client.create
      }}
      data={clients}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
    />
  );
}
