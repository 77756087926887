import React from 'react';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { useRequestSources } from '../hooks/request-sources.hook';
import { RequestSourceFormDialog } from '../components/request/source/RequestSourceFormDialog';
import { RequestSourceTable } from '../components/request/source/RequestSourceTable';

export function RequestSourceScreen() {
  const { entities, apiState, listEntities } = useRequestSources();
  const { type, reference } = useEditorLocation();

  return (
    <>
      {type === 'edit' && reference !== undefined && (
        <RequestSourceFormDialog sourceId={reference} />
      )}
      <RequestSourceTable
        requestSources={entities}
        onRefresh={listEntities}
        apiState={apiState}
      />
    </>
  );
}
