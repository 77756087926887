import { useTranslation } from 'react-i18next';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useUserProjectTemplates } from '../../../users/hooks/user-project-templates.hook';
import { ProjectTemplateList } from './ProjectTemplateList';

export function ProjectTemplateDashboardCard() {
  const { entities, listFetched, listEntities, apiState } =
    useUserProjectTemplates();
  const { t } = useTranslation();
  return (
    <Card style={{ position: 'relative', padding: '5px' }}>
      <CardContent
        style={{
          position: 'relative',
          paddingLeft: '20px',
          height: '95%',
          padding: '5px'
        }}
      >
        <Box display="flex" width="100%">
          <Typography variant="h5" style={{ flex: 1 }}>
            {t('Projecttemplates')}
          </Typography>
          <IconButton
            disabled={apiState !== 'idle'}
            size="small"
            title={t('Refresh')}
            onClick={() => {
              if (apiState === 'idle') {
                listEntities();
              }
            }}
          >
            <Refresh fontSize="small" />
          </IconButton>
        </Box>
        <Box width="100%" padding="10px">
          {listFetched || <Skeleton />}
          {listFetched && entities.length === 0 && (
            <Typography
              variant="subtitle1"
              style={{ marginLeft: '2em', color: 'grey' }}
            >
              - {t('You have not marked any project as a template yet')} -
            </Typography>
          )}
          {listFetched && entities.length > 0 && (
            <ProjectTemplateList templates={entities} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
