import React from 'react';
import { LargeScaleProjectDTO } from 'model';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../redux/redux.hooks';
import { largeScaleProjectThunk } from '../../redux/large-scale-projects.thunk';
import { NoOp } from '../../../../utils';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import largeScaleProjectsSlice, {
  largeScaleProjectSelectors
} from '../../redux/large-scale-projects.slice';
import { FormikEntitySelect } from '../../../../components/select/FormikEntitySelect';
import { clientAPI } from '../../../clients/redux/clients.slice';
import { FormikTextField } from '../../../../components/input/FormikTextField';

const initialValues: LargeScaleProjectDTO = {
  clientId: '',
  description: '',
  name: ''
};

const validationSchema = yup.object().shape({
  clientId: yup.string().required('required'),
  description: yup
    .string()
    .max(255, 'Max. 255 characters')
    .required('required'),
  name: yup.string().max(255, 'Max. 255 characters').required('required')
});

export function LargeScaleProjectFormDialog({ lspId }: { lspId?: string }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const lsp = useAppSelector((state) =>
    largeScaleProjectSelectors.selectById(state, lspId ?? '')
  );

  const editLspDto = lsp
    ? { name: lsp.name, description: lsp.description, clientId: lsp.client.id }
    : undefined;

  const createLargeScaleProject = (dto: LargeScaleProjectDTO) => {
    if (lsp) {
      return dispatch(
        largeScaleProjectThunk.update({
          id: lsp.id,
          input: dto
        })
      )
        .then(unwrapResult)
        .catch(NoOp);
    }
    return dispatch(largeScaleProjectThunk.create({ input: dto }))
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <DialogForm
      identifier={lsp?.id ?? 'large-scale-project'}
      label={t('New Large Scale Project')}
      form={{ initialValues: editLspDto ?? initialValues, validationSchema }}
      api={{
        clearError: largeScaleProjectsSlice.actions.clearError,
        errorSelector: largeScaleProjectSelectors.selectError,
        stateSelector: largeScaleProjectSelectors.selectState,
        onSubmit: createLargeScaleProject
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormikTextField required name="name" label="Name" />
        </Grid>
        <Grid item xs={12}>
          <FormikEntitySelect
            textFieldProps={{ label: 'Client', required: true }}
            entityApi={clientAPI}
            optionLabel={(c) => c.name}
            name="clientId"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            name="description"
            label="Description"
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </DialogForm>
  );
}
