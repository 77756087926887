import { omit } from 'lodash';
import React, { useEffect } from 'react';

const ABSENCE_DB_STORAGE_KEY = '_local_db_absence_covers';

export const LocalDBNames = {
  absence_covers: ABSENCE_DB_STORAGE_KEY
};

export interface LocalDBEntry<T> {
  id: string;
  value: T;
}
function createLocalDB<T>(name: string) {
  const eventTarget = new EventTarget();
  const readStorage = () => {
    const currentValue = localStorage.getItem(name);
    const parsedValue: Record<string, T> =
      currentValue === null ? {} : JSON.parse(currentValue);
    return parsedValue;
  };
  const writeStorage = (value: Record<string, T>) => {
    localStorage.setItem(name, JSON.stringify(value));
    eventTarget.dispatchEvent(new CustomEvent('db_updated'));
  };

  return {
    list(): Record<string, T> {
      return readStorage();
    },
    read(id: string): LocalDBEntry<T> | undefined {
      const map = readStorage();
      return map[id] ? { id, value: map[id] } : undefined;
    },
    write(id: string, value: T) {
      const map = readStorage();
      writeStorage({ ...map, [id]: value });
    },
    remove(id: string) {
      writeStorage(omit(readStorage(), id));
    },
    insert(value: T) {
      const id = window.crypto.randomUUID();
      writeStorage({ ...readStorage(), [id]: value });
    },
    subscribe(listener: () => void): () => void {
      eventTarget.addEventListener('db_updated', listener);
      return () => {
        eventTarget.removeEventListener('db_updated', listener);
      };
    }
  };
}

export interface AbsenceCover {
  coveredSupplierId: string;
  started: Date;
  estimatedEnd: Date | null;
  active: boolean;
}

export const AbsenceDBManager = createLocalDB<AbsenceCover>(
  LocalDBNames.absence_covers
);

export function useAbsenceDB() {
  const [data, setData] = React.useState(AbsenceDBManager.list());
  useEffect(() => {
    return AbsenceDBManager.subscribe(() => {
      setData(AbsenceDBManager.list());
    });
  }, []);
  return data;
}

/*
export const LocalDBManager = (function () {
  const eventTarget = new EventTarget();
  const fromEntries = Object.fromEntries(
    Object.entries(LocalDBNames).map(([dbName, storageKey]) => [
      dbName,
      {
        read(): AbsenceCover[] {
          const storageValue = localStorage.getItem(storageKey);
          return storageValue !== null
            ? (JSON.parse(storageValue) as AbsenceCover[])
            : [];
        },
        write(value: AbsenceCover[]) {
          localStorage.setItem(storageKey, JSON.stringify(value));
          eventTarget.dispatchEvent(
            new CustomEvent(`db_${dbName}_value_updated`)
          );
        },
        subscribe(onStoreChange: () => void): () => void {
          eventTarget.addEventListener(
            `db_${dbName}_value_updated`,
            onStoreChange
          );
          return () => {
            eventTarget.removeEventListener(
              `db_${dbName}_value_updated`,
              onStoreChange
            );
          };
        }
      }
    ])
  );
  return fromEntries as Record<
    keyof typeof LocalDBNames,
    typeof fromEntries[string]
  >;
})(); */
