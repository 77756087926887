import { User } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { userThunks } from './actions/users.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { patchUserProfileThunk } from './actions/patch-user-profile.thunk';

const { list, delete: deleteThunk, ...thunks } = userThunks;

const usersSlice = createEntitySlice<User>('users', [
  CustomThunk.override<User>(list, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as User[]);
    }
  }),
  CustomThunk.override<User>(
    deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  ...Object.values(thunks),
  patchUserProfileThunk
]);

export const usersSelectors = createAdapterSelectors(
  'users',
  usersSlice.adapter
);

export const userAPI = {
  selectors: usersSelectors,
  thunks: userThunks
} as EntityAPI<User>;

export default usersSlice;
