import { Project, ProjectNumber } from 'model';
import React from 'react';
import { compareDesc } from 'date-fns';
import { Timeline } from '@material-ui/lab';
import { useCommentsOfRequest } from '../../hooks/request-comments.hook';
import { ProjectCommentEditor } from './ProjectCommentEditor';
import { ProjectCommentOrder } from './ProjectCommentOrder';
import { ProjectCommentItem } from './ProjectCommentItem';

export function ProjectCommentSection({ project }: { project: Project }) {
  const { comments: rawComments } = useCommentsOfRequest(
    project.requestId as string
  );
  const comments = React.useMemo(
    () => rawComments.filter((c) => c.candidateId === null),
    [rawComments]
  );

  const { order } = project;

  const sortedComments = React.useMemo(
    () =>
      [
        ...comments.map((c) => ({ type: 'comment', comment: c } as const)),
        ...(order ? [{ type: 'order', order } as const] : [])
      ].sort((a, b) =>
        compareDesc(
          new Date(
            a.type === 'order' ? a.order.createdAt : a.comment.createdAt
          ),
          new Date(b.type === 'order' ? b.order.createdAt : b.comment.createdAt)
        )
      ),
    [comments, order]
  );

  return (
    <Timeline align="left">
      <ProjectCommentEditor requestId={project.requestId as string} />
      {sortedComments.map((comment) =>
        comment.type === 'order' ? (
          <ProjectCommentOrder
            projectNumber={project.projectNumber as ProjectNumber}
            order={comment.order}
            requestId={project.requestId as string}
          />
        ) : (
          <ProjectCommentItem comment={comment.comment} />
        )
      )}
    </Timeline>
  );
}
