import { SupplierCategory, SupplierCategoryDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';

export const supplierCategoriesThunk = createFeatureThunks<
  SupplierCategory,
  SupplierCategoryDTO,
  SupplierCategoryDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/suppliers/supplier-category${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'supplier-categories'
});
