import React, { ReactNode } from 'react';
import { Form, Formik } from 'formik';
import { Close as CancelIcon, Save as SaveIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { ErrorBoundary } from '../../../features/error/ErrorBoundary';
import { CardWithHeader } from '../cardWithHeader/CardWithHeader';
import { EditableCardFormBaseProps } from './types';
import { NoOp } from '../../../utils';
import { Log } from '../../../logger/logger';

interface EditableCardFormProps<T, U> extends EditableCardFormBaseProps<T, U> {
  title: ReactNode;
  value: T;
  onCancel: () => void;
  focused?: boolean;
}

export function EditableCardForm<T, U>(props: EditableCardFormProps<T, U>) {
  const { t } = useTranslation();
  const [editSuccess, setEditSuccess] = React.useState(false);

  const FormComponent = props.component;

  return (
    <ErrorBoundary context="EditableCardForm">
      <Formik
        initialValues={props.viewFactory(props.dtoTransformer(props.value))}
        validationSchema={props.validationSchema}
        onSubmit={(validValues) => props.onSubmit(validValues)}
      >
        {({ submitForm, errors }) => {
          Log.warn('DialogForm validationError', errors);
          return (
            <Form>
              <Prompt
                message="All changes will be lost if you navigate now1"
                when={!editSuccess}
              />
              <CardWithHeader
                title={props.title}
                focused={props.focused}
                actions={[
                  {
                    title: t('Save'),
                    icon: SaveIcon,
                    onClick: () =>
                      submitForm()
                        .then((res) => {
                          if (res !== undefined) {
                            setEditSuccess(true);
                            props.onCancel();
                          }
                          return res;
                        })
                        .catch(NoOp)
                  },
                  {
                    title: t('Discard'),
                    icon: CancelIcon,
                    onClick: props.onCancel
                  }
                ]}
              >
                <FormComponent />
              </CardWithHeader>
            </Form>
          );
        }}
      </Formik>
    </ErrorBoundary>
  );
}
