import React from 'react';
import { RequestResource, requestResourcePermission } from 'model';
import { Chip, Typography } from '@material-ui/core';
import { Close, GetApp, Info } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../../localized-formats';
import { createFileGrant } from '../../../../../../utils';

export function RequestResourceDisplay({ value }: { value: RequestResource }) {
  const { t } = useTranslation();
  return (
    <div>
      <div style={{ display: 'inline-flex' }}>
        <Typography>{value?.name}</Typography>
        <Typography
          style={{ marginLeft: 5 }}
          variant="caption"
          color="textSecondary"
        >
          ({value.type})
        </Typography>
      </div>
      <div style={{ paddingLeft: 10 }}>
        <Typography variant="subtitle1" color="textSecondary">
          <Info color="inherit" fontSize="inherit" style={{ marginRight: 2 }} />
          {value.description}
        </Typography>
        {!value.available && (
          <Typography
            variant="subtitle1"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Close
              color="error"
              fontSize="inherit"
              style={{ marginRight: 2 }}
            />

            {t('Not Available')}
          </Typography>
        )}
        {value.estimatedDelivery && (
          <Typography>{formatDate(value.estimatedDelivery, 'date')}</Typography>
        )}
        {value.attachedFiles &&
          value.attachedFiles.map((af) => (
            <Chip
              size="small"
              component={RouterLink}
              to={`/files/${af.id}/${
                createFileGrant(af.id, requestResourcePermission, value).token
              }`}
              target="blank"
              label={
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center'
                  }}
                >
                  {af.name}
                  <GetApp
                    style={{ marginLeft: 4, fontSize: 14 }}
                    fontSize="small"
                    color="action"
                  />
                </div>
              }
            />
          ))}
      </div>
    </div>
  );
}
