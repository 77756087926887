import React from 'react';
import { Client } from 'model';
import { Grid } from '@material-ui/core';
import { ClientBasicCard } from './basic/ClientBasicCard';
import { ClientNumberAlert } from './basic/ClientNumberAlert';
import { ClientNumberDialog } from './basic/ClientNumberDialog';

export function ClientProfile({ client }: { client: Client }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <ClientNumberAlert client={client} />
        <ClientNumberDialog client={client} />
      </Grid>
      <Grid item xs={6}>
        <ClientBasicCard client={client} />
      </Grid>
    </Grid>
  );
}
