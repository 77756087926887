import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apiClient } from '../../api/apiClient';
import { Log } from '../../logger/logger';

export interface FileDownloadScreenProps {
  fileId: string;
  token: string;
}

export function FileDownloadScreen() {
  const { fileId, token } = useParams<FileDownloadScreenProps>();
  const [link, setLink] = React.useState<string>();
  useEffect(() => {
    apiClient
      .get(`/files/${fileId}/${token}`)
      .then((res) => {
        setLink(res.data);
        return res;
      })
      .catch((err) => {
        Log.error(err, `Error getting File ${fileId}`);
      });
  }, []);
  if (!link) {
    return <span>Loading...</span>;
  }
  return (
    <a
      href={link}
      ref={(l: HTMLAnchorElement | null) => {
        if (l) {
          l.click();
        }
      }}
    >
      Download
    </a>
  );
}
