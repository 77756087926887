import React from 'react';
import {
  AirportShuttle,
  AssignmentTurnedIn,
  Block,
  Edit,
  HourglassEmptyOutlined,
  Info,
  Settings,
  Today
} from '@material-ui/icons';
import { OrderElementState, OrderStepTask, ServiceStep } from 'model';
import { Avatar, IconButton, Typography, useTheme } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatDate } from '../../../../localized-formats';
import { StepCardAssigneePicker } from './TaskAssigneePicker';
import { supplierApi } from '../../../suppliers/redux/suppliers.slice';
import { TransitionActionComponent } from './TransitionActionComponent';
import { IconButtonLink } from '../../../../components/input/button/IconButtonLink';
import { useTaskTransitions } from './order-task-state.hook';
import { projectOrderTasksThunk } from '../../redux/project-order-tasks.thunk';
import { NoOp } from '../../../../utils';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { useTaskMap } from './order-task.context';
import { aggregateProjectExpensesThunk } from '../../redux/project-expenses.thunk';

const StateUIMap = (theme: Theme) => ({
  blocked: { label: 'Blocked', icon: Block, color: undefined },
  pending: {
    label: 'Pending',
    icon: HourglassEmptyOutlined,
    color: theme.palette.warning.light
  },
  'in-progress': { label: 'In Progress', icon: Settings, color: undefined },
  delivered: { label: 'Delivered', icon: AirportShuttle, color: undefined },
  completed: {
    label: 'Final',
    icon: AssignmentTurnedIn,
    color: theme.palette.success.light
  }
});

/* const useStyles = makeStyles((theme) =>
  createStyles({
    yellow: {
      /!* color: theme.palette.getContrastText(theme.palette.warning.main), *!/
      backgroundColor: theme.palette.warning.main
    },
    green: {
      /!* color: theme.palette.getContrastText(theme.palette.success.main) *!/
      backgroundColor: theme.palette.success.main
    }
  })

); */

export function StepTaskDisplay({
  value,
  projectId,
  serviceStep,
  state
}: {
  value: OrderStepTask;
  projectId: string;
  serviceStep?: ServiceStep;
  state: OrderElementState | 'blocked';
}) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const stateUI = StateUIMap(theme)[state];
  const StateIcon = stateUI.icon;
  const transitionActions = useTaskTransitions(value);
  const { url } = useRouteMatch();
  const taskMap = useTaskMap();
  const de = Object.values(taskMap).some((t) =>
    t.value.dependencies.some((d) => d.id === value.id)
  );
  const deleteStepTask = () => {
    dispatch(projectOrderTasksThunk.delete({ projectId, id: value.id }))
      .then((res) => {
        dispatch(aggregateProjectExpensesThunk({ projectId }));
        dispatch(projectOrderTasksThunk.list({ projectId }));
        return res;
      })
      .catch(NoOp);
  };
  return (
    <div style={{ display: 'flex', padding: 10 }}>
      <Avatar
        style={{ width: 30, height: 30, backgroundColor: stateUI?.color }}
      >
        <StateIcon />
      </Avatar>
      <div style={{ paddingLeft: 10, minWidth: 250 }}>
        <div style={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
          <Typography style={{ flexGrow: 1, paddingRight: 20 }} variant="h6">
            {serviceStep?.name}
          </Typography>
          <IconButtonLink size="small" to={`${url}?type=edit#${value.id}`}>
            <Edit fontSize="small" />
          </IconButtonLink>
          {!de && (
            <IconButton size="small" onClick={deleteStepTask}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
          {transitionActions.map((tA) => (
            <TransitionActionComponent
              action={tA}
              task={value}
              projectId={projectId}
            />
          ))}
        </div>
        {value.description && (
          <div style={{ display: 'flex' }}>
            <Info fontSize="small" color="action" style={{ marginRight: 3 }} />
            <Typography variant="subtitle1" color="textSecondary">
              {value.description}
            </Typography>
          </div>
        )}
        {value.deadline && (
          <div>
            <Typography
              color="textSecondary"
              variant="subtitle1"
              style={{ display: 'inline-flex', alignItems: 'center' }}
            >
              <Today fontSize="small" style={{ marginRight: 2 }} /> until:{' '}
              {formatDate(value.deadline, 'date')}
            </Typography>
          </div>
        )}
        {serviceStep?.assigneeTypes.supplier && (
          <StepCardAssigneePicker
            key="supplierassigner"
            projectId={projectId}
            task={value}
            serviceStep={serviceStep}
            type="supplier"
            api={supplierApi}
          />
        )}
      </div>
      {/*      {serviceStep?.assigneeTypes['project-manager'] && (
          <StepCardAssigneePicker
            projectId={projectId}
            task={value}
            serviceStep={serviceStep}
            type="project-manager"
            api={supplierApi}
          />
        )}
        {serviceStep?.assigneeTypes.client && (
          <StepCardAssigneePicker
            // TODO FIX API
            projectId={projectId}
            task={value}
            serviceStep={serviceStep}
            type="client"
            api={(undefined as unknown) as any}
          />
        )} */}
    </div>
  );
}
