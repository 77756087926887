import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Checkbox,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { CreateUserDTO } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FormikTextField } from '../../../components/input/FormikTextField';
import { FormikSwitch } from '../../../components/input/switch/FormikSwitch';
import {
  buildDTOView,
  useTypedField
} from '../../../transformer/DTOViewSchema';
import { useRoles } from '../hooks/roles.hook';

const useStyles = makeStyles((theme) =>
  createStyles({
    emailContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      paddingBottom: theme.spacing(3)
    },
    emailTextField: {
      marginLeft: theme.spacing(2)
    }
  })
);

const userView = buildDTOView<CreateUserDTO>()
  .exclude('useLinkedName')
  .withSchema(
    yup
      .object()
      .defined()
      .shape({
        email: yup.string().required().email('must be valid email'),
        roleIds: yup
          .array()
          .of(yup.string().required())
          .min(1, 'must assign at least one role'),
        enabled: yup.boolean().required(),
        displayName: yup.string().min(3).default(null).nullable().notRequired(),
        useLinkedName: yup.boolean().notRequired()
      })
  );

export function AccountForm() {
  const classes = useStyles();

  const [, enabledMeta] = useTypedField(userView.path.enabled);
  const { roles } = useRoles();
  const [, roleIdsMeta, roleIdsHelpers] = useTypedField(userView.path.roleIds);

  const { t } = useTranslation();
  return (
    <div>
      <div className={classes.emailContainer}>
        <Avatar>
          <PersonIcon />
        </Avatar>
        <FormikTextField
          required
          className={classes.emailTextField}
          variant="outlined"
          autoComplete="off"
          name={userView.path.email}
          label={t('Email')}
        />
        <FormikSwitch
          name={userView.path.enabled}
          label={enabledMeta.value ? t('active') : t('inactive')}
        />
      </div>
      <FormikTextField
        variant="outlined"
        autoComplete="off"
        name="displayName"
        label={t('Display Name')}
      />
      <Typography variant="body2" color="textSecondary">
        {t('assigned roles')}
      </Typography>
      <Typography variant="body2" color="error">
        {roleIdsMeta.touched && roleIdsMeta.error}
      </Typography>
      <List>
        {roles.map((role) => (
          <ListItem key={role.id} button role={undefined}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                tabIndex={-1}
                disableRipple
                onChange={(_, checked) =>
                  checked
                    ? roleIdsHelpers.setValue([...roleIdsMeta.value, role.id])
                    : roleIdsHelpers.setValue(
                        roleIdsMeta.value.filter((roleId) => roleId !== role.id)
                      )
                }
                checked={roleIdsMeta.value.includes(role.id)}
                inputProps={{ 'aria-labelledby': role.name }}
              />
            </ListItemIcon>
            <ListItemText>{role.name}</ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
