import React, { PropsWithChildren, useState } from 'react';

export const VirtualScrollContext = React.createContext({
  scrollPort: null as HTMLElement | null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateScrollPort: (element: HTMLDivElement | null | undefined) => {}
});

export function useVirtualScroller() {
  const ctx = React.useContext(VirtualScrollContext);
  return {
    scrollPortRef: ctx.updateScrollPort,
    contentChanged: () => {
      ctx.scrollPort?.dispatchEvent(new Event('custom:content-changed'));
    }
  };
}

export function ScrollOverlay({ element }: { element: HTMLElement }) {
  const scrollPosRef = React.useRef<HTMLDivElement>(null);
  const calculatePosition = () => {
    const bounds = element.getBoundingClientRect();
    const actualScrollBounds = new DOMRect(
      bounds.x,
      bounds.bottom,
      bounds.width,
      5
    );
    if (
      actualScrollBounds.top <= window.innerHeight ||
      actualScrollBounds.left > window.innerWidth ||
      actualScrollBounds.right <= 0 ||
      bounds.top >= window.innerHeight ||
      element.scrollWidth <= bounds.width
    ) {
      return undefined;
    }
    if (scrollPosRef.current)
      scrollPosRef.current.scrollLeft = element.scrollLeft;
    return {
      top: window.innerHeight - 10,
      left: actualScrollBounds.left,
      width: actualScrollBounds.width,
      scrollWidth: element.scrollWidth,
      scrollLeft: element.scrollLeft
    };
  };
  const [overlayPos, setOverlayPos] = React.useState(calculatePosition());

  React.useEffect(() => {
    const cleanUp: (() => void)[] = [];

    const scrollListener = () => {
      setOverlayPos(calculatePosition());
    };
    element.addEventListener('scroll', scrollListener);
    element.addEventListener('resize', scrollListener);
    element.addEventListener('custom:content-changed', scrollListener);
    document.addEventListener('scroll', scrollListener);
    window.addEventListener('resize', scrollListener);
    const currentPos = scrollPosRef.current;
    if (currentPos) {
      currentPos.scrollLeft = element.scrollLeft;
      const vScrollListener = () => {
        element.scrollLeft = currentPos.scrollLeft;
      };
      currentPos.addEventListener('scroll', vScrollListener);
      cleanUp.push(() => {
        currentPos.removeEventListener('scroll', vScrollListener);
      });
    }
    cleanUp.push(() => {
      element.removeEventListener('scroll', scrollListener);
      element.removeEventListener('resize', scrollListener);
      element.removeEventListener('custom:content-changed', scrollListener);
      document.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', scrollListener);
    });

    return () =>
      cleanUp.forEach((c) => {
        c();
      });
  }, [element, scrollPosRef.current]);
  return overlayPos ? (
    <div
      ref={scrollPosRef}
      style={{
        // top: `${overlayPos.top}px`,
        bottom: `0.5em`,
        backgroundColor: 'rgba(250,250,250,0.95)',
        margin: '2px 2px 2px 2px',
        marginBottom: '0.5em',
        boxShadow: '0px 1em 2px 1.5em rgba(250,250,250,0.95)',
        left: `${overlayPos.left}px`,
        width: `${overlayPos.width}px`,
        zIndex: 9999,
        position: 'fixed',
        borderRadius: '3px',
        overflowX: 'auto'
      }}
    >
      <div
        style={{
          display: 'flex',
          width: `${overlayPos.scrollWidth}px`,
          height: '1px'
        }}
      />
    </div>
  ) : (
    <></>
  );
}

export function VirtualScrollPanel(props: PropsWithChildren<unknown>) {
  const [scrollPort, setScrollPort] = useState<HTMLElement | null>(null);

  return (
    <VirtualScrollContext.Provider
      value={{
        scrollPort,
        updateScrollPort: (p) => {
          /*    if (p) {
            p.dispatchEvent(new Event('custom:content-changed'));
          } */
          setScrollPort(p ?? null);
        }
      }}
    >
      {scrollPort && <ScrollOverlay element={scrollPort} />}
      {props.children}
    </VirtualScrollContext.Provider>
  );
}
