"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPermissionsMap = void 0;
const types_1 = require("./types");
const Permission_1 = require("./Permission");
function createPermissionsMap(map, name, p) {
    var _a;
    const permission = new Permission_1.Permission(name, typeof map === 'object' ? (_a = map[types_1.Enabled]) !== null && _a !== void 0 ? _a : true : map, p);
    const childs = Object.fromEntries((typeof map === 'object'
        ? Object.entries(map).map(([subName, subMap]) => [
            subName,
            createPermissionsMap(subMap, subName, permission)
        ])
        : []));
    permission.children = Object.values(childs);
    return Object.assign(permission, childs);
}
exports.createPermissionsMap = createPermissionsMap;
