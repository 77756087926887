import { TablePagination } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { useTableDataContext } from '../context/DataTableContext';
import { useTablePaginationControl } from './pagination-control.hook';

export function DataTablePagination<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage } =
    useTablePaginationControl();
  const tableData = useTableDataContext<T, C>();

  const { t } = useTranslation();
  return (
    <TablePagination
      rowsPerPageOptions={[15, 25, 50, 100]}
      component="div"
      count={tableData.tableData.length}
      rowsPerPage={rowsPerPage}
      page={page}
      labelRowsPerPage={t('Rows per page')}
      onPageChange={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}
