import { BaseEntity } from 'model';
import React from 'react';
import {
  EntityReferenceSelect,
  SelectProps,
  SelectValue
} from './EntityReferenceSelect';
import { PropertyPath, useTypedField } from '../../transformer/DTOViewSchema';

export type FormikEntitySelectProps<
  T extends BaseEntity,
  V extends SelectValue<T>
> = Omit<
  SelectProps<T, V> & {
    name: string | PropertyPath<any, V>;
    onChange?: (val: V) => void;
  },
  'currentValue' | 'selectValue'
>;

export function FormikEntitySelect<
  T extends BaseEntity,
  V extends SelectValue<T>
>({ name, onChange, ...props }: FormikEntitySelectProps<T, V>) {
  const [, meta, helpers] = useTypedField(name);

  const subProps = {
    currentValue: meta.value,
    selectValue: (v: V) => {
      if (onChange) onChange(v);
      helpers.setValue(v);
    },
    ...props,
    textFieldProps: {
      ...props.textFieldProps,
      error: meta.touched && Boolean(meta.error),
      helperText: meta.touched && meta.error
    }
  } as unknown as any;
  return <EntityReferenceSelect<T, V> {...subProps} />;
}
