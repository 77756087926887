import { useField } from 'formik';
import { Checkbox, FormControlLabel, List, ListItem } from '@material-ui/core';
import React from 'react';
import { useCatTools } from '../../../hooks/cat-tools.hook';
import { ErrorBoundary } from '../../../../error/ErrorBoundary';

export function SupplierCatToolsForm() {
  const catTools = useCatTools();
  const [, meta, helpers] = useField<string[]>('catTools');
  return (
    <ErrorBoundary context="SupplierCatToolsForm">
      <List dense>
        <ListItem dense>
          {catTools.catTools.map((tool) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={meta.value.includes(tool.id)}
                  onChange={(_, checked) =>
                    helpers.setValue(
                      checked
                        ? [
                            ...new Set([
                              ...catTools.catTools.map((t) => t.id),
                              tool.id
                            ])
                          ]
                        : meta.value.filter((t) => t !== tool.id)
                    )
                  }
                />
              }
              label={tool.name}
              labelPlacement="start"
            />
          ))}
        </ListItem>
      </List>
    </ErrorBoundary>
  );
}
