import React from 'react';
import { SortDirection, TableSortContextData } from './sort.context';
import { BaseTableRow, DataCell, DataCellMap } from '../TableDataCell';
import { useTableConfigContext } from '../context/DataTableContext';
import { stableSort } from '../utils';

const DirectionFactor: Record<SortDirection, number> = {
  asc: -1,
  desc: 1
};

function isDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function useRawTableDataSort<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(tableCells: Partial<C>, sortState: TableSortContextData<T, C>['sortState']) {
  const aggregatedComparators = React.useMemo(
    () =>
      Object.entries(sortState)
        .map(([, state]) =>
          state !== undefined
            ? ([
                tableCells[state.cellId]?.renderer.comparator as (
                  a: [any, T],
                  b: [any, T],
                  cell: DataCell<T, any>
                ) => number,
                tableCells[state.cellId],
                DirectionFactor[state.direction]
              ] as const)
            : undefined
        )
        .filter(isDefined)
        .map(
          ([comparator, cell, factor]) =>
            (a: T, b: T) =>
              comparator(
                [cell?.accessor(a), a],
                [cell?.accessor(b), b],
                cell as any
              ) * factor
        ),
    [sortState, tableCells]
  );

  const comparatorWrapper = React.useCallback(
    (a: T, b: T) =>
      aggregatedComparators.map((comp) => comp(a, b)).find((r) => r !== 0) ?? 0,
    [aggregatedComparators]
  );

  return React.useCallback(
    (data: T[]) => {
      return Object.keys(sortState).length > 0
        ? stableSort(data, comparatorWrapper)
        : data;
    },
    [sortState, comparatorWrapper]
  );
}
export function useTableDataSorter<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>() {
  const {
    tableCells,
    tableSort: { sortState }
  } = useTableConfigContext<T, C>();

  return useRawTableDataSort(tableCells, sortState);
}
