import { Client } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const deleteClientContactThunk = createErrorHandlingThunk<
  Client,
  { clientId: string; contactId: string }
>('client/contact/delete', async (args) => {
  await apiClient.delete(`/client/${args.clientId}/contacts/${args.contactId}`);

  const clientResult = await apiClient.get<Client>(`/client/${args.clientId}`);
  return clientResult.data;
});
