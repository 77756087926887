import React from 'react';
import { ErrorBoundary } from '../../error/ErrorBoundary';
import { LanguagesTable } from '../components/LanguagesTable';

export function LanguageScreen() {
  return (
    <ErrorBoundary context="languagesScreen">
      <LanguagesTable />
    </ErrorBoundary>
  );
}
