"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStepTask = void 0;
const typeorm_1 = require("typeorm");
// eslint-disable-next-line import/no-cycle
const OrderTask_1 = require("./OrderTask");
const ServiceStep_1 = require("../../service/entity/ServiceStep");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("../../supplier/entity/Supplier");
// eslint-disable-next-line import/no-cycle
const ClientContact_1 = require("../../client/entity/ClientContact");
let OrderStepTask = class OrderStepTask extends OrderTask_1.OrderTask {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => ServiceStep_1.ServiceStep),
    __metadata("design:type", ServiceStep_1.ServiceStep)
], OrderStepTask.prototype, "serviceStep", void 0);
__decorate([
    (0, typeorm_1.RelationId)((ost) => ost.serviceStep),
    __metadata("design:type", String)
], OrderStepTask.prototype, "serviceStepId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier, { nullable: true }),
    __metadata("design:type", Supplier_1.Supplier)
], OrderStepTask.prototype, "supplierAssignee", void 0);
__decorate([
    (0, typeorm_1.RelationId)((ost) => ost.supplierAssignee),
    __metadata("design:type", String)
], OrderStepTask.prototype, "supplierAssigneeId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier, { nullable: true }),
    __metadata("design:type", Supplier_1.Supplier)
], OrderStepTask.prototype, "projectManagerAssignee", void 0);
__decorate([
    (0, typeorm_1.RelationId)((ost) => ost.projectManagerAssignee),
    __metadata("design:type", String)
], OrderStepTask.prototype, "projectManagerAssigneeId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ClientContact_1.ClientContact, { nullable: true }),
    __metadata("design:type", ClientContact_1.ClientContact)
], OrderStepTask.prototype, "clientContactAssignee", void 0);
__decorate([
    (0, typeorm_1.RelationId)((ost) => ost.clientContactAssignee),
    __metadata("design:type", String)
], OrderStepTask.prototype, "clientContactAssigneeId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], OrderStepTask.prototype, "phase", void 0);
OrderStepTask = __decorate([
    (0, typeorm_1.ChildEntity)('step-task'),
    __metadata("design:paramtypes", [Object])
], OrderStepTask);
exports.OrderStepTask = OrderStepTask;
