import React, { Component, ReactNode } from 'react';
import { Typography } from '@material-ui/core';
import { Log } from '../../logger/logger';
import {
  PageAreaContext,
  PageAreaPathElement,
  toIdentifiedPath
} from '../../hooks/page-area.context';

interface ErrorBoundaryProps {
  context: string;
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  pageArea?: PageAreaPathElement;

  constructor(
    props: ErrorBoundaryProps,
    public state: ErrorBoundaryState = { hasError: false }
  ) {
    super(props);
  }

  public static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error) {
    Log.error(
      `${error.name} - ${error.message} - ${error.stack} - ${
        this.pageArea ? toIdentifiedPath(this.pageArea) : 'unknown'
      }`,
      this.props.context
    );
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Typography variant="h2">Something went wrong ¯\_(ツ)_/¯</Typography>
      );
    }
    return (
      <PageAreaContext.Consumer>
        {(area) => {
          this.pageArea = area;
          return this.props.children;
        }}
      </PageAreaContext.Consumer>
    );
  }
}
