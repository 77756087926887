import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { userProjectTemplateSelectors } from '../redux/user-project-template.slice';
import {
  listUserProjectTemplatesThunk,
  markUserProjectTemplateThunk,
  removeUserProjectTemplateThunk
} from '../redux/actions/list-user-project-templates.thunk';
import { selectUser } from '../../auth/redux/auth.slice';

export function useUserProjectTemplates() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);
  const entities = useAppSelector(userProjectTemplateSelectors.selectAll);
  const apiState = useAppSelector(userProjectTemplateSelectors.selectState);
  // const apiError = useAppSelector(selectors.selectError);
  const listFetched = useAppSelector(
    userProjectTemplateSelectors.selectListFetched
  );

  const listEntities = () =>
    dispatch(listUserProjectTemplatesThunk({ userId: currentUser.id }));

  React.useEffect(() => {
    if (
      apiState !== 'unauthorized' &&
      (apiState === 'fresh' || listFetched === false)
    ) {
      listEntities();
    }
  }, [apiState, listEntities]);

  return { entities, apiState, listEntities, listFetched };
}

export function useUserProjectTemplateState(projectId: string) {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);
  const template = useAppSelector((state) =>
    userProjectTemplateSelectors.selectByUserAndProject(state, {
      userId: currentUser?.id ?? '',
      projectId
    })
  );
  const apiState = useAppSelector(userProjectTemplateSelectors.selectState);
  // const apiError = useAppSelector(selectors.selectError);
  const listFetched = useAppSelector(
    userProjectTemplateSelectors.selectListFetched
  );
  const listEntities = React.useCallback(
    () => dispatch(listUserProjectTemplatesThunk({ userId: currentUser.id })),
    [currentUser?.id]
  );

  React.useEffect(() => {
    if (
      currentUser &&
      apiState !== 'unauthorized' &&
      (apiState === 'fresh' || listFetched === false)
    ) {
      listEntities();
    }
  }, [apiState, listEntities, currentUser]);

  if (!currentUser || listFetched === false || apiState === 'pending') {
    return undefined;
  }

  return {
    template: !!template,
    toggle: () => {
      if (!template) {
        dispatch(
          markUserProjectTemplateThunk({ userId: currentUser.id, projectId })
        );
      } else {
        dispatch(
          removeUserProjectTemplateThunk({ userId: currentUser.id, projectId })
        );
      }
    }
  };
}
