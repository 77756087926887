import React from 'react';
import { Grid } from '@material-ui/core';
import { RequestSourceDTO } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { buildDTOView } from '../../../../../transformer/DTOViewSchema';
import { FormikTextField } from '../../../../../components/input/FormikTextField';

export const RequestSourceBasicView = buildDTOView<RequestSourceDTO>()
  .all()
  .withSchema(
    yup
      .object({
        name: yup.string().required().min(2, 'Min. 2 characters'),
        description: yup.string().max(255, 'Max. 255 characters').defined()
      })
      .defined()
  );

export function RequestSourceBasicForm() {
  const { t } = useTranslation();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12}>
          <FormikTextField
            required
            name={RequestSourceBasicView.path.name}
            label={t('Name')}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            label={t('Description')}
            name={RequestSourceBasicView.path.description}
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
