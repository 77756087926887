import { CreateUserDTO, User, UserPatchDTO } from 'model';
import { createFeatureThunks } from '../../../../redux/featureThunks';

export const userThunks = createFeatureThunks<
  User,
  CreateUserDTO,
  UserPatchDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/user${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'users'
});
