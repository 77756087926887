import { Language } from 'model';
import { useAppSelector } from '../../../redux/redux.hooks';
import { selectLanguageById } from '../redux/languages.slice';

export function useSelectLanguage(
  id: string | null | undefined
): Language | undefined {
  return useAppSelector((state) =>
    id ? selectLanguageById(state, id) : undefined
  );
}
