import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
  Typography
} from '@material-ui/core';
import {
  PropertyPath,
  useTypedField
} from '../../../transformer/DTOViewSchema';

interface FormikCheckboxProps {
  name: string | PropertyPath<any, boolean>;
  label: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
}

export function EditableCheckboxDisplay(props: FormikCheckboxProps) {
  const [, meta, helpers] = useTypedField<boolean>(props.name);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={meta.value}
          onChange={(_, checked) => helpers.setValue(checked)}
        />
      }
      label={<Typography variant="body2">{props.label}</Typography>}
      labelPlacement={props.labelPlacement || 'start'}
    />
  );
}
