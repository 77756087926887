"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userPatchTransformer = exports.UserPatchDTO = void 0;
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const CreateUserDTO_1 = require("./CreateUserDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const User_1 = require("../entity/User");
class UserPatchDTO extends (0, partial_type_helper_1.PartialType)(CreateUserDTO_1.CreateUserDTO) {
}
exports.UserPatchDTO = UserPatchDTO;
exports.userPatchTransformer = (0, DTOTransformer_1.createDTOTransformer)(User_1.User, UserPatchDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'supplier',
    'clientContact',
    'password',
    'avatar'
], { roles: (0, DTOTransformer_1.transformIds)('roleIds') });
