import React from 'react';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import { IconButton, MenuItem, Select } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { BaseTableRow } from '../TableDataCell';
import { ColumnFilter } from './column-filter.types';

function BooleanFilterComponent(props: {
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
}) {
  return (
    <Select
      style={{ width: '100%' }}
      value={props.value === undefined ? '' : String(props.value)}
      onChange={(e) =>
        e.target.value === ''
          ? props.onChange(undefined)
          : props.onChange(e.target.value === 'true')
      }
      displayEmpty
      endAdornment={
        props.value !== undefined && (
          <IconButton
            size="small"
            onClick={() => {
              props.onChange(undefined);
            }}
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        )
      }
    >
      <MenuItem value="" />
      <MenuItem value="true">
        <CheckBoxOutlinedIcon /> True
      </MenuItem>
      <MenuItem value="false">
        <CheckBoxOutlineBlankOutlinedIcon /> False
      </MenuItem>
    </Select>
  );
}

export function BooleanColumnFilter<T extends BaseTableRow>(): ColumnFilter<
  T,
  boolean | null | undefined,
  boolean | undefined
> {
  return {
    initialState: undefined,
    overlayOnFocus: false,
    createFilter: (cell, state) => {
      return state === undefined
        ? () => true
        : (value: boolean | null | undefined) => value === state;
    },
    // eslint-disable-next-line react/prop-types
    component: ({ filterState, onFilterChange }) => (
      <BooleanFilterComponent value={filterState} onChange={onFilterChange} />
    )
  };
}
