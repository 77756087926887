import React, { PropsWithRef } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from '../../../redux/redux.hooks';
import { selectAuthState } from '../redux/auth.slice';

export function PrivateRoute({
  children,
  ...rest
}: RouteProps & PropsWithRef<any>) {
  const authState = useAppSelector(selectAuthState);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState !== 'logged-out' ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
