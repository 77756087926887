import { BaseEntity } from 'model';
import React, { FC } from 'react';
import { Skeleton } from '@material-ui/lab';
import { EntityAPI } from '../../redux/entity-api.hook';
import { StyledLink } from '../input/link/StyledLink';

type Type<C> = { new (...args: any[]): C };

export interface EntityDocument<T extends BaseEntity, R extends string> {
  entityClass: Type<T>;
  name: string;
  entityApi: EntityAPI<T>;
  documentLinkBuilder: (value: T) => string;
  nameFormatter: (value: T, type?: R) => string;
  previewComponent?: FC<{ id: string }>;
}

export function DocumentLink<E extends EntityDocument<any, any>>({
  value,
  document,
  format
}: {
  value: E extends EntityDocument<infer T, any> ? T : never;
  document: E;
  format?: E extends EntityDocument<any, infer R> ? R : never;
}) {
  return (
    <StyledLink target="_blank" to={document.documentLinkBuilder(value)}>
      {document.nameFormatter(value, format)}
    </StyledLink>
  );
}

export function createEntityDocument<T extends BaseEntity, R extends string>(
  entityClass: Type<T>,
  name: string,
  entityApi: EntityAPI<T>,
  documentLinkBuilder: (value: T) => string,
  nameFormatter: (value: T, type?: R) => string
) {
  const doc: EntityDocument<T, R> = {
    entityClass,
    name,
    entityApi,
    documentLinkBuilder,
    nameFormatter
  };
  const DocLink = ({
    value,
    format
  }: {
    value: T | null | undefined;
    format?: R;
  }) => {
    return value ? (
      <DocumentLink value={value} document={doc} format={format} />
    ) : (
      <Skeleton />
    );
  };
  return { entityDocument: doc, EntityLink: DocLink };
}
