import { SupplierLanguageDTO } from 'model';
import { Typography } from '@material-ui/core';
import React from 'react';
import { useLanguageIndex } from '../../../languages/hooks/language-index.hook';

export function LanguageColumnRenderer({
  languages
}: {
  languages: SupplierLanguageDTO[];
}) {
  const languageIndex = useLanguageIndex();
  if (Object.keys(languageIndex.descriptors).length === 0) {
    return <></>;
  }
  return (
    <>
      {languages.map((l) => (
        <Typography variant="subtitle1">
          {languageIndex.descriptors[l.languageId].language.name}
        </Typography>
      ))}
    </>
  );
}
