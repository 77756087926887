import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import {
  selectAllLanguages,
  selectLanguagesListFetched,
  selectLanguagesState
} from '../redux/languages.slice';
import { listLanguagesThunk } from '../redux/actions/list-languages.thunk';

export function useLanguages() {
  const dispatch = useAppDispatch();

  const languages = useAppSelector(selectAllLanguages);
  const apiState = useAppSelector(selectLanguagesState);
  const listFetched = useAppSelector(selectLanguagesListFetched);

  React.useEffect(() => {
    if (apiState === 'fresh' || !listFetched) {
      dispatch(listLanguagesThunk());
    }
  }, [apiState, listFetched]);

  return { languages, apiState };
}
