import React from 'react';
import { Supplier, SupplierPatchDTO, supplierPatchTransformer } from 'model';
import * as yup from 'yup';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import PlusIcon from '@material-ui/icons/Add';
import { buildDTOView } from '../../../../transformer/DTOViewSchema';
import { EditableTextDisplay } from '../../../../components/input/card/EditableTextDisplay';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { suppliersThunk } from '../../redux/suppliers.thunk';
import { EditableCard } from '../../../../components/input/card/EditableCard';
import { PhoneNumberDisplay } from '../../../../components/phone/PhoneNumberDisplay';
import { SupplierPhoneActions } from './SupplierPhoneActions';

export const SupplierPhonesView = buildDTOView<SupplierPatchDTO>()
  .include('phoneNumbers')
  .withSchema(
    yup.object({
      phoneNumbers: yup
        .array()
        .required()
        .of(
          yup.object({
            id: yup.string().uuid(),
            type: yup.string().nullable().default(null),
            number: yup.string().required('required'),
            comment: yup.string().defined().nullable().default(null)
          })
        )
    })
  );

export function SupplierPhoneForm(props: { index: number }) {
  const arrayAccessor = SupplierPhonesView.path.phoneNumbers.at(props.index);
  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <EditableTextDisplay name={arrayAccessor.type} label="Type" />
      </Grid>
      <Grid item xs={8}>
        <EditableTextDisplay
          required
          name={arrayAccessor.number}
          label="Number"
        />
      </Grid>
      <Grid item xs={8}>
        <EditableTextDisplay name={arrayAccessor.comment} label="Comment" />
      </Grid>
    </Grid>
  );
}

export function SupplierPhones({ supplier }: { supplier: Supplier }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const { type, reference } = useEditorLocation();

  const supplierDto = React.useMemo(
    () => supplierPatchTransformer(supplier),
    [supplier]
  );

  const dispatch = useAppDispatch();

  const phonesView = SupplierPhonesView.viewFactory(supplierDto);

  const updateSupplier = (input: SupplierPatchDTO) =>
    dispatch(suppliersThunk.update({ id: supplier.id, input }))
      .then(unwrapResult)
      .then((res) => res);

  return (
    <>
      {type === 'view' && (
        <Button
          size="small"
          disabled={type !== 'view'}
          variant="outlined"
          color="primary"
          style={{ marginBottom: 20 }}
          onClick={() => history.push(`${url}?type=create#phoneNumber`)}
          startIcon={<PlusIcon />}
        >
          {t('New New Phone')}
        </Button>
      )}
      <Grid container spacing={1}>
        {type === 'create' && reference === 'phoneNumber' && (
          <Grid item xs={3}>
            <EditableCard
              identifier="phoneNumber"
              title="Phone"
              variant="outlined"
              onSubmit={updateSupplier}
              value={{
                phoneNumbers: [
                  ...phonesView.phoneNumbers,
                  {
                    type: '',
                    number: '',
                    comment: null
                  }
                ]
              }}
              validationSchema={SupplierPhonesView.validationSchema}
            >
              <SupplierPhoneForm index={phonesView.phoneNumbers.length} />
            </EditableCard>
          </Grid>
        )}
        {supplier.phoneNumbers.map((phoneNumber, phoneNumberIndex) => {
          const identifier = `${phoneNumber.type}-${phoneNumber.number}`;
          return (
            <Grid item xs={3}>
              {type === 'edit' && identifier === reference ? (
                <EditableCard
                  identifier={identifier}
                  variant="outlined"
                  title="Phone"
                  onSubmit={updateSupplier}
                  value={phonesView}
                  validationSchema={SupplierPhonesView.validationSchema}
                >
                  <SupplierPhoneForm index={phoneNumberIndex} />
                </EditableCard>
              ) : (
                <Card variant="outlined">
                  <CardContent style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: 0, right: 0 }}>
                      <SupplierPhoneActions
                        supplier={supplier}
                        phone={phoneNumber}
                      />
                    </div>
                    <PhoneNumberDisplay phoneNumber={phoneNumber} />
                  </CardContent>
                </Card>
              )}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
