import * as yup from 'yup';
import { ActiveStatusTypeDTO, InactiveStatusTypeDTO } from 'model';
import { useTranslation } from 'react-i18next';
import { Checkbox, Grid } from '@material-ui/core';
import React from 'react';
import {
  buildDTOView,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { EditableCheckboxDisplay } from '../../../../components/input/checkbox/EditableCheckboxDisplay';
import { FormikSelect } from '../../../../components/select/FormikSelect';

export const ActiveStatusTypeView = buildDTOView<ActiveStatusTypeDTO>()
  .all()
  .withSchema(
    yup
      .object({
        statusType: yup.mixed().oneOf(['active']).required(),
        name: yup.string().min(2).required(),
        description: yup.string().defined(),
        expireType: yup.mixed().oneOf(['never', 'maybe', 'always']).required(),
        explanationType: yup
          .mixed()
          .oneOf(['never', 'maybe', 'always'])
          .required(),
        selectable: yup.boolean().required(),
        enforceActivationConstraints: yup.boolean().required()
      })
      .defined()
  );
export const InactiveStatusTypeView = buildDTOView<InactiveStatusTypeDTO>()
  .all()
  .withSchema(
    yup
      .object({
        statusType: yup.mixed().oneOf(['inactive']).required(),
        name: yup.string().min(2).required(),
        description: yup.string().defined(),
        expireType: yup.mixed().oneOf(['never', 'maybe', 'always']).required(),
        explanationType: yup
          .mixed()
          .oneOf(['never', 'maybe', 'always'])
          .required(),
        selectable: yup.boolean().required()
      })
      .defined()
  );
const dataTypeOptions = (t: (v: string) => string) => [
  {
    label: t('never'),
    value: 'never'
  },
  {
    label: t('maybe'),
    value: 'maybe'
  },
  {
    label: t('always'),
    value: 'always'
  }
];
export function SupplierStatusTypeBasicForm({
  basePath,
  alterType
}: {
  basePath:
    | typeof ActiveStatusTypeView.path
    | typeof InactiveStatusTypeView.path;
  alterType?: (type: 'active' | 'inactive') => void;
}) {
  const { t } = useTranslation();
  const [, typeMeta] = useTypedField(basePath.statusType);

  return (
    <Grid item xs={12} container spacing={1}>
      <Grid item container xs={6}>
        <Grid item xs={12}>
          <FormikTextField required name={basePath.name} label={t('Name')} />
        </Grid>
        <Grid item xs={12}>
          <FormikTextField
            required
            label={t('Description')}
            name={basePath.description}
            multiline
          />
        </Grid>
      </Grid>
      <Grid item container xs={6}>
        <Grid item xs={12}>
          <Checkbox
            disabled={!alterType}
            checked={typeMeta.value === 'active'}
            onChange={(e) => {
              const v = e.target.checked;
              if (v) {
                if (alterType) alterType('active');
                /*  console.log('active', gMeta.value);
              gHelper.setValue({
                ...gMeta.value,
                statusType: 'active',
                enforceActivationConstraints: true
              } as any); */
              } else {
                // eslint-disable-next-line no-lonely-if
                if (alterType) alterType('inactive');
                // console.log('inactive', gMeta.value);
                // if (alterType) alterType('inactive');
                /*    gHelper.setValue(
                omit(
                  { ...gMeta.value, statusType: 'inactive' },
                  'enforceActivationConstraints'
                ) as any
              ); */
              }
            }}
          />
          {t('Aktiv')}
          {typeMeta.value === 'active' &&
            (basePath as typeof ActiveStatusTypeView.path)
              .enforceActivationConstraints && (
              <EditableCheckboxDisplay
                name={
                  (basePath as typeof ActiveStatusTypeView.path)
                    .enforceActivationConstraints
                }
                label={t('Enforce activation constraints')}
              />
            )}
        </Grid>
        <Grid item xs={6}>
          <FormikSelect
            name={basePath.expireType}
            options={dataTypeOptions(t)}
            label={t('Expire Type')}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikSelect
            name={basePath.explanationType}
            options={dataTypeOptions(t)}
            label={t('Explanation Type')}
          />
        </Grid>
        <Grid item xs={12}>
          <EditableCheckboxDisplay
            name={basePath.selectable}
            label={t('Selectable')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
