import {
  CandidateDecisionDTO,
  Project,
  ProjectCandidate,
  ProjectCandidateDTO,
  ProjectDTO,
  ProjectNumberDTO,
  ProjectPatchDTO,
  ProjectTemplateStateDTO,
  RequestComment,
  RequestCommentDTO
} from 'model';
import { OutgoingInvoiceDTO } from 'model/dist/project/dto/OutgoingInvoiceDTO';
import { createFeatureThunks } from '../../../redux/featureThunks';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const projectThunk = createFeatureThunks<
  Project,
  ProjectDTO,
  ProjectPatchDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/projects/project${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'projects'
});

export const confirmProjectThunk = createErrorHandlingThunk<
  any,
  { id: string; input: ProjectNumberDTO }
>('projects/confirm', async ({ id, input }, thunkAPI) => {
  await apiClient.put(`/projects/project/${id}/project-number`, input);
  thunkAPI.dispatch(projectThunk.get({ id }));
});

export const outgoingInvoiceThunk = createErrorHandlingThunk<
  any,
  { id: string; input: OutgoingInvoiceDTO }
>('projects/outgoing-invoice', async ({ id, input }) => {
  const res = await apiClient.put(
    `/projects/project/${id}/outgoing-invoice`,
    input
  );
  return res.data;
});

export const createOutgoingInvoiceThunk = createErrorHandlingThunk<
  Project,
  { projectId: string; input: OutgoingInvoiceDTO }
>('projects/create-outgoing-invoice', async ({ projectId, input }) => {
  const res = await apiClient.post(
    `/projects/project/${projectId}/outgoing-invoice`,
    input
  );
  return res.data;
});
export const updateOutgoingInvoiceThunk = createErrorHandlingThunk<
  Project,
  { projectId: string; invoiceId: string; input: OutgoingInvoiceDTO }
>(
  'projects/update-outgoing-invoice',
  async ({ projectId, invoiceId, input }) => {
    const res = await apiClient.put(
      `/projects/project/${projectId}/outgoing-invoice/${invoiceId}`,
      input
    );
    return res.data;
  }
);
export const deleteOutgoingInvoiceThunk = createErrorHandlingThunk<
  Project,
  { projectId: string; invoiceId: string }
>('projects/delete-outgoing-invoice', async ({ projectId, invoiceId }) => {
  const res = await apiClient.delete(
    `/projects/project/${projectId}/outgoing-invoice/${invoiceId}`
  );
  return res.data;
});

export const listClientProjectsThunk = createErrorHandlingThunk<
  Project[],
  { clientId: string }
>('projects/list/client', async ({ clientId }) => {
  const result = await apiClient.get<Project[]>(
    `/projects/project/client/${clientId}`
  );
  return result.data;
});

export const listProjectCandidatesThunk = createErrorHandlingThunk<
  ProjectCandidate[],
  { projectId: string }
>('projects/list/candidates', async ({ projectId }) => {
  const result = await apiClient.get<ProjectCandidate[]>(
    `/projects/project/${projectId}/candidates`
  );
  return result.data;
});

/* export const createCandidateCommentThunk = createErrorHandlingThunk<
    RequestComment,
    { requestId: string; candidateId: string; input: RequestCommentDTO }
    >('projects/comment/candidates', async ({ requestId, offerId, input }) => {
  const comment = await apiClient.post<RequestComment>(
      `/projects/project/${requestId}/offers/${offerId}/comments`,
      input
  );
  return (
      await apiClient.get(
          `/projects/project-request/${requestId}/comments/${comment.data.id}`
      )
  ).data;
}); */

export const addProjectCandidateThunk = createErrorHandlingThunk<
  ProjectCandidate,
  { projectId: string; dto: ProjectCandidateDTO }
>('projects/add/candidates', async ({ projectId, dto }) => {
  const result = await apiClient.post<ProjectCandidate>(
    `/projects/project/${projectId}/candidates`,
    dto
  );
  return result.data;
});
export const commentProjectCandidateThunk = createErrorHandlingThunk<
  RequestComment,
  { projectId: string; candidateId: string; dto: RequestCommentDTO }
>('projects/comment/candidates', async ({ projectId, candidateId, dto }) => {
  const result = await apiClient.post<RequestComment>(
    `/projects/project/${projectId}/candidates/${candidateId}/comments`,
    dto
  );
  return result.data;
});
export const updateProjectCandidateThunk = createErrorHandlingThunk<
  ProjectCandidate,
  { projectId: string; candidateId: string; dto: ProjectCandidateDTO }
>('projects/update/candidates', async ({ projectId, candidateId, dto }) => {
  const result = await apiClient.put<ProjectCandidate>(
    `/projects/project/${projectId}/candidates/${candidateId}`,
    dto
  );
  return result.data;
});

export const makeProjectCandidateDecisionThunk = createErrorHandlingThunk<
  ProjectCandidate,
  { projectId: string; candidateId: string; dto: CandidateDecisionDTO }
>('projects/decide/candidates', async ({ projectId, candidateId, dto }) => {
  const result = await apiClient.put<ProjectCandidate>(
    `/projects/project/${projectId}/candidates/${candidateId}/decision`,
    dto
  );
  return result.data;
});

export const removeProjectCandidateDecisionThunk = createErrorHandlingThunk<
  ProjectCandidate,
  { projectId: string; candidateId: string }
>('projects/undecide/candidates', async ({ projectId, candidateId }) => {
  const result = await apiClient.delete<ProjectCandidate>(
    `/projects/project/${projectId}/candidates/${candidateId}/decision`
  );
  return result.data;
});

export const removeProjectCandidateThunk = createErrorHandlingThunk<
  ProjectCandidate,
  { projectId: string; candidateId: string }
>('projects/remove/candidates', async ({ projectId, candidateId }) => {
  const update = (
    await apiClient.delete<ProjectCandidate>(
      `/projects/project/${projectId}/candidates/${candidateId}`
    )
  ).data;
  return update;
});

export const listOpenProjectsThunk = createErrorHandlingThunk<
  Project[],
  { pmIds: string[] }
>('projects/list-open', async ({ pmIds }) => {
  const result = await apiClient.get<Project[]>(
    // eslint-disable-next-line max-len
    `/projects/project?status=in-progress&status=not-yet-commisioned&status=ready-to-plan&${pmIds
      .map((id) => `pm=${id}`)
      .join('&')}`
  );
  return result.data;
});

export const switchProjectTemplateStateThunk = createErrorHandlingThunk<
  Project,
  { projectId: string; dto: ProjectTemplateStateDTO }
>('projects/decide/candidates', async ({ projectId, dto }) => {
  const result = await apiClient.put<Project>(
    `/projects/project/${projectId}/template`,
    dto
  );
  return result.data;
});
