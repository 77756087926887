import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const StyledButton = styled(Button)({
  // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  // border: 1
  // borderRadius: 3,
  // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  // color: 'green'
  // height: 48,
  // padding: '0 30px'
  // padding: (props: ButtonProps) => (props.endIcon ? 10 : 0)
});
