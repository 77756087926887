"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierLargeScaleProjectAssignment = void 0;
const typeorm_1 = require("typeorm");
// eslint-disable-next-line import/no-cycle
const class_transformer_1 = require("class-transformer");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("./Supplier");
// eslint-disable-next-line import/no-cycle
const LargeScaleProject_1 = require("../../project/entity/LargeScaleProject");
const CircularReference_1 = require("../../serialization/CircularReference");
let SupplierLargeScaleProjectAssignment = class SupplierLargeScaleProjectAssignment {
    constructor(init) {
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => LargeScaleProject_1.LargeScaleProject),
    __metadata("design:type", LargeScaleProject_1.LargeScaleProject)
], SupplierLargeScaleProjectAssignment.prototype, "largeScaleProject", void 0);
__decorate([
    (0, typeorm_1.RelationId)((a) => a.largeScaleProject),
    (0, typeorm_1.PrimaryColumn)(),
    __metadata("design:type", String)
], SupplierLargeScaleProjectAssignment.prototype, "largeScaleProjectId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier),
    (0, CircularReference_1.CircularRef)(),
    __metadata("design:type", Supplier_1.Supplier)
], SupplierLargeScaleProjectAssignment.prototype, "supplier", void 0);
__decorate([
    (0, typeorm_1.RelationId)((l) => l.supplier),
    (0, typeorm_1.PrimaryColumn)(),
    __metadata("design:type", String)
], SupplierLargeScaleProjectAssignment.prototype, "supplierId", void 0);
__decorate([
    (0, typeorm_1.Column)({ type: 'numeric' }),
    (0, class_transformer_1.Type)(() => Number),
    __metadata("design:type", Number)
], SupplierLargeScaleProjectAssignment.prototype, "weeklyHours", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", Boolean)
], SupplierLargeScaleProjectAssignment.prototype, "active", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], SupplierLargeScaleProjectAssignment.prototype, "comment", void 0);
SupplierLargeScaleProjectAssignment = __decorate([
    (0, typeorm_1.Entity)(),
    __metadata("design:paramtypes", [Object])
], SupplierLargeScaleProjectAssignment);
exports.SupplierLargeScaleProjectAssignment = SupplierLargeScaleProjectAssignment;
