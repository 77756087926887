"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.csv = exports.ColumnDescriptor = void 0;
class ColumnDescriptor {
    constructor(prepareContent, parseContent, validateData, splitter) {
        this.prepareContent = prepareContent;
        this.parseContent = parseContent;
        this.validateData = validateData;
        this.splitter = splitter;
        this.positionOffset = 0;
        this.prepareContent = prepareContent;
        this.parseContent = parseContent;
        this.validateData = validateData;
    }
    withPrepare(prep) {
        return new ColumnDescriptor((v) => prep(this.prepareContent(v)), this.parseContent, this.validateData, this.splitter);
    }
    toCase(type) {
        return this.withPrepare((s) => type === 'upper' ? s.toUpperCase() : s.toLowerCase());
    }
    withParse(parser, validator) {
        return new ColumnDescriptor(this.prepareContent, (d) => parser(this.parseContent(d)), validator !== null && validator !== void 0 ? validator : (() => true), this.splitter);
    }
    withValidation(validator) {
        return new ColumnDescriptor(this.prepareContent, this.parseContent, (d) => this.validateData(d) && validator(d), this.splitter);
    }
    valueMap(map) {
        return this.withParse((v) => {
            if (Object.keys(map).includes(v)) {
                return map[v];
            }
            throw new Error(`unknown option [${Object.keys(map).join(',')}]`);
        });
    }
    orEmpty(def) {
        return new ColumnDescriptor(this.prepareContent, (d) => (d === '' ? def : this.parseContent(d)), (v) => v === def || this.validateData(v), this.splitter);
    }
    skipBefore(offset) {
        return Object.assign(this, { positionOffset: offset });
    }
    static createList(splitter, empty, prepare = (v) => v.trim()) {
        return new ColumnDescriptor(prepare, (c) => c, () => true, typeof splitter === 'string'
            ? (v) => (empty !== undefined && v === '' ? empty : v.split(splitter))
            : splitter);
    }
    static create(prepare = (v) => v.trim()) {
        return new ColumnDescriptor(prepare, (c) => c, () => true);
    }
}
exports.ColumnDescriptor = ColumnDescriptor;
exports.csv = {};
