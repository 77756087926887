import {
  CreateUserRoleDTO,
  UpdateRolePermissionDTO,
  UserRoleTree
} from 'model';

import { EntityId } from '@reduxjs/toolkit';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

interface UpdateRoleThunkInput {
  id: string;
  roleDTO: CreateUserRoleDTO;
}

export const updateRoleThunk = createErrorHandlingThunk<
  UserRoleTree,
  UpdateRoleThunkInput
>('role/update', async (arg) => {
  const result = await apiClient.put<UserRoleTree>(
    `/roles/${arg.id}`,
    arg.roleDTO
  );
  return result.data;
});

interface UpdateRolePermissionInput {
  roleId: EntityId;
  permissionDTO: UpdateRolePermissionDTO;
}

export const updateRolePermissionThunk = createErrorHandlingThunk<
  UserRoleTree,
  UpdateRolePermissionInput
>('role/update', async (arg) => {
  const result = await apiClient.patch<UserRoleTree>(
    `/roles/${arg.roleId}`,
    arg.permissionDTO
  );
  return result.data;
});
