import React, { ReactNode } from 'react';
import Popover from '@material-ui/core/Popover';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none'
    },

    paper: {
      padding: theme.spacing(1),
      pointerEvents: 'auto'
    }
  })
);

interface PopoverPreviewProps {
  ready: boolean;
  content: JSX.Element;
  children: ReactNode;
}

export default function PopoverPreview(props: PopoverPreviewProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [mouseOverPopover, setMouseOverPopover] =
    React.useState<boolean>(false);
  const [mouseOverPreview, setMouseOverPreview] =
    React.useState<boolean>(false);
  let intermediateTest = false;
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setMouseOverPreview(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setMouseOverPreview(false);
    if (!mouseOverPopover && !intermediateTest) {
      setAnchorEl(null);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {props.children}
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 20,
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: () => {
            intermediateTest = true;
            setMouseOverPopover(true);
          },
          onMouseLeave: () => {
            setMouseOverPopover(false);
            if (!mouseOverPreview) {
              setAnchorEl(null);
            }
          }
        }}
      >
        {props.ready && props.content}
      </Popover>
    </div>
  );
}
