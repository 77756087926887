"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.taskDataParser = exports.TaskImportData = void 0;
const csv_columns_descriptor_1 = require("../../csv/csv-columns.descriptor");
const column_descriptor_1 = require("../../csv/column-descriptor");
class TaskImportData {
}
exports.TaskImportData = TaskImportData;
const taskDataParser = (supplierSource, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
clientSource, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
projectSource) => {
    return csv_columns_descriptor_1.CSVColumnBuilder.create()
        .skip(3)
        .append({
        projectManagerIds: column_descriptor_1.ColumnDescriptor.createList(',').withValidation((d) => d.every((di) => di !== '' && supplierSource(di) !== null))
    });
};
exports.taskDataParser = taskDataParser;
