import { ChromePicker, ColorResult } from 'react-color';
import React, { FC, useRef, useState } from 'react';
import { Grid, IconButton, Popover } from '@material-ui/core';
import { Colorize, ColorizeOutlined } from '@material-ui/icons';

export type RGBAValue = [r: number, g: number, b: number, a: number];

export const rgba = (
  r: number,
  g: number,
  b: number,
  a?: number
): RGBAValue => [r, g, b, a ?? 1];

export const cssRGBA = ([r, g, b, a]: RGBAValue) => `rgba(${r},${g},${b},${a})`;
export const fromCSSRGBA = (val: string) =>
  val
    .substring(5, val.length - 1)
    .split(',')
    .map((v) => parseInt(v, 10)) as RGBAValue;
interface ColorPickerProps {
  value: RGBAValue;
  onValueChange: (color: RGBAValue) => void;
}
const ColorPicker: FC<ColorPickerProps> = ({
  value,
  onValueChange
}: ColorPickerProps) => {
  const [showDialog, setShowDialog] = useState<HTMLDivElement>();
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [r, g, b, a] = value;
  const onChange = ({ rgb: { r: r1, g: g1, b: b1, a: a1 } }: ColorResult) =>
    onValueChange(rgba(r1, g1, b1, a1));
  return (
    <Grid container justify="flex-start" alignItems="center">
      <div
        ref={buttonRef}
        style={{
          backgroundImage:
            // eslint-disable-next-line max-len
            'linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%)',
          backgroundSize: '10px 10px',
          backgroundPosition: '0 0, 0 5px, 5px -5px, -5px 0px',
          margin: '2px',
          padding: '2px'
        }}
      >
        <div
          style={{
            borderRadius: '50%',
            backgroundColor: cssRGBA(value),
            border: '1px solid'
          }}
        >
          <IconButton
            size="small"
            onClick={() => setShowDialog(buttonRef.current as HTMLDivElement)}
          >
            <div style={{ position: 'relative', textShadow: '2px 2px white' }}>
              <ColorizeOutlined
                htmlColor={cssRGBA([...value.slice(0, 3), 1] as RGBAValue)}
              />
              <Colorize
                style={{
                  position: 'absolute',
                  left: 0,
                  zIndex: 60000,
                  textShadow: `10px 10px ${cssRGBA([
                    ...value.slice(0, 3),
                    1
                  ] as RGBAValue)}`
                }}
              />
            </div>
          </IconButton>
        </div>
      </div>

      <Popover
        open={!!showDialog}
        anchorEl={showDialog}
        onClose={() => setShowDialog(undefined)}
      >
        <ChromePicker
          color={{ r, g, b, a }}
          onChange={onChange}
          onChangeComplete={onChange}
        />
      </Popover>
    </Grid>
  );
};
export default ColorPicker;
