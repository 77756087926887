"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ISO5LangList = void 0;
exports.ISO5LangList = [
    { parent: null, code: 'aav', name: 'Austro-Asiatic languages' },
    { parent: null, code: 'afa', name: 'Afro-Asiatic languages' },
    { parent: 'aql', code: 'alg', name: 'Algonquian languages' },
    { parent: 'nic', code: 'alv', name: 'Atlantic-Congo languages' },
    { parent: 'ath', code: 'apa', name: 'Apache languages' },
    { parent: 'sai', code: 'aqa', name: 'Alacalufan languages' },
    { parent: 'nai', code: 'aql', name: 'Algic languages' },
    { parent: null, code: 'art', name: 'Artificial languages' },
    { parent: 'xnd', code: 'ath', name: 'Athapascan languages' },
    { parent: 'awd', code: 'auf', name: 'Arauan languages' },
    { parent: null, code: 'aus', name: 'Australian languages' },
    { parent: 'sai', code: 'awd', name: 'Arawakan languages' },
    { parent: 'nai', code: 'azc', name: 'Uto-Aztecan languages' },
    { parent: 'alv', code: 'bad', name: 'Banda languages' },
    { parent: 'alv', code: 'bai', name: 'Bamileke languages' },
    { parent: 'ine', code: 'bat', name: 'Baltic languages' },
    { parent: 'afa', code: 'ber', name: 'Berber languages' },
    { parent: 'iir', code: 'bih', name: 'Bihari languages' },
    { parent: 'alv', code: 'bnt', name: 'Bantu languages' },
    { parent: 'pqw', code: 'btk', name: 'Batak languages' },
    { parent: null, code: 'cai', name: 'Central American Indian languages' },
    { parent: null, code: 'cau', name: 'Caucasian languages' },
    { parent: 'sai', code: 'cba', name: 'Chibchan languages' },
    { parent: 'cau', code: 'ccn', name: 'North Caucasian languages' },
    { parent: 'cau', code: 'ccs', name: 'South Caucasian languages' },
    { parent: 'afa', code: 'cdc', name: 'Chadic languages' },
    { parent: 'nai', code: 'cdd', name: 'Caddoan languages' },
    { parent: 'ine', code: 'cel', name: 'Celtic languages' },
    { parent: 'pqw', code: 'cmc', name: 'Chamic languages' },
    { parent: 'crp', code: 'cpe', name: 'creoles and pidgins, English-based' },
    { parent: 'crp', code: 'cpf', name: 'creoles and pidgins, French-based' },
    { parent: 'crp', code: 'cpp', name: 'creoles and pidgins, Portuguese-based' },
    { parent: null, code: 'crp', name: 'creoles and pidgins' },
    { parent: 'ssa', code: 'csu', name: 'Central Sudanic languages' },
    { parent: 'afa', code: 'cus', name: 'Cushitic languages' },
    { parent: null, code: 'day', name: 'Land Dayak languages' },
    { parent: 'nic', code: 'dmn', name: 'Mande languages' },
    { parent: null, code: 'dra', name: 'Dravidian languages' },
    { parent: 'afa', code: 'egx', name: 'Egyptian languages' },
    { parent: null, code: 'esx', name: 'Eskimo-Aleut languages' },
    { parent: null, code: 'euq', name: 'Basque (family)' },
    { parent: 'urj', code: 'fiu', name: 'Finno-Ugrian languages' },
    { parent: 'map', code: 'fox', name: 'Formosan languages' },
    { parent: 'ine', code: 'gem', name: 'Germanic languages' },
    { parent: 'gem', code: 'gme', name: 'East Germanic languages' },
    { parent: 'gem', code: 'gmq', name: 'North Germanic languages' },
    { parent: 'gem', code: 'gmw', name: 'West Germanic languages' },
    { parent: 'ine', code: 'grk', name: 'Greek languages' },
    { parent: null, code: 'hmx', name: 'Hmong-Mien languages' },
    { parent: 'nai', code: 'hok', name: 'Hokan languages' },
    { parent: 'ine', code: 'hyx', name: 'Armenian (family)' },
    { parent: 'ine', code: 'iir', name: 'Indo-Iranian languages' },
    { parent: 'alv', code: 'ijo', name: 'Ijo languages' },
    { parent: 'iir', code: 'inc', name: 'Indic languages' },
    { parent: null, code: 'ine', name: 'Indo-European languages' },
    { parent: 'iir', code: 'ira', name: 'Iranian languages' },
    { parent: 'nai', code: 'iro', name: 'Iroquoian languages' },
    { parent: 'ine', code: 'itc', name: 'Italic languages' },
    { parent: null, code: 'jpx', name: 'Japanese (family)' },
    { parent: 'tbq', code: 'kar', name: 'Karen languages' },
    { parent: 'nic', code: 'kdo', name: 'Kordofanian languages' },
    { parent: null, code: 'khi', name: 'Khoisan languages' },
    { parent: 'alv', code: 'kro', name: 'Kru languages' },
    { parent: null, code: 'map', name: 'Austronesian languages' },
    { parent: 'aav', code: 'mkh', name: 'Mon-Khmer languages' },
    { parent: 'phi', code: 'mno', name: 'Manobo languages' },
    { parent: 'aav', code: 'mun', name: 'Munda languages' },
    { parent: 'cai', code: 'myn', name: 'Mayan languages' },
    { parent: 'azc', code: 'nah', name: 'Nahuatl languages' },
    { parent: null, code: 'nai', name: 'North American Indian languages' },
    { parent: 'paa', code: 'ngf', name: 'Trans-New Guinea languages' },
    { parent: null, code: 'nic', name: 'Niger-Kordofanian languages' },
    { parent: 'sdv', code: 'nub', name: 'Nubian languages' },
    { parent: 'cai', code: 'omq', name: 'Oto-Manguean languages' },
    { parent: 'afa', code: 'omv', name: 'Omotic languages' },
    { parent: 'omq', code: 'oto', name: 'Otomian languages' },
    { parent: null, code: 'paa', name: 'Papuan languages' },
    { parent: 'pqw', code: 'phi', name: 'Philippine languages' },
    { parent: 'poz', code: 'plf', name: 'Central Malayo-Polynesian languages' },
    { parent: 'map', code: 'poz', name: 'Malayo-Polynesian languages' },
    { parent: 'poz', code: 'pqe', name: 'Eastern Malayo-Polynesian languages' },
    { parent: 'poz', code: 'pqw', name: 'Western Malayo-Polynesian languages' },
    { parent: 'inc', code: 'pra', name: 'Prakrit languages' },
    { parent: 'sai', code: 'qwe', name: 'Quechuan (family)' },
    { parent: 'itc', code: 'roa', name: 'Romance languages' },
    { parent: null, code: 'sai', name: 'South American Indian languages' },
    { parent: 'nai', code: 'sal', name: 'Salishan languages' },
    { parent: 'ssa', code: 'sdv', name: 'Eastern Sudanic languages' },
    { parent: 'afa', code: 'sem', name: 'Semitic languages' },
    { parent: null, code: 'sgn', name: 'sign languages' },
    { parent: 'nai', code: 'sio', name: 'Siouan languages' },
    { parent: null, code: 'sit', name: 'Sino-Tibetan languages' },
    { parent: 'ine', code: 'sla', name: 'Slavic languages' },
    { parent: 'fiu', code: 'smi', name: 'Sami languages' },
    { parent: 'ssa', code: 'son', name: 'Songhai languages' },
    { parent: 'ine', code: 'sqj', name: 'Albanian languages' },
    { parent: null, code: 'ssa', name: 'Nilo-Saharan languages' },
    { parent: 'urj', code: 'syd', name: 'Samoyedic languages' },
    { parent: null, code: 'tai', name: 'Tai languages' },
    { parent: 'sit', code: 'tbq', name: 'Tibeto-Burman languages' },
    { parent: 'tut', code: 'trk', name: 'Turkic languages' },
    { parent: 'sai', code: 'tup', name: 'Tupi languages' },
    { parent: null, code: 'tut', name: 'Altaic languages' },
    { parent: 'tut', code: 'tuw', name: 'Tungus languages' },
    { parent: null, code: 'urj', name: 'Uralic languages' },
    { parent: 'nai', code: 'wak', name: 'Wakashan languages' },
    { parent: 'zlw', code: 'wen', name: 'Sorbian languages' },
    { parent: 'tut', code: 'xgn', name: 'Mongolian languages' },
    { parent: 'nai', code: 'xnd', name: 'Na-Dene languages' },
    { parent: 'esx', code: 'ypk', name: 'Yupik languages' },
    { parent: 'sit', code: 'zhx', name: 'Chinese (family)' },
    { parent: 'sla', code: 'zle', name: 'East Slavic languages' },
    { parent: 'sla', code: 'zls', name: 'South Slavic languages' },
    { parent: 'sla', code: 'zlw', name: 'West Slavic languages' },
    { parent: 'alv', code: 'znd', name: 'Zande languages' }
];
