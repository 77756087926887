import React from 'react';
import { SvgIconProps, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Hearing as NonNativeIcon,
  RecordVoiceOver as NativeSpeakerIcon
} from '@material-ui/icons';

interface LanguageNativeIconProps extends SvgIconProps {
  isNative: boolean;
}

export function LanguageNativeIcon({
  isNative,
  ...svgIconProps
}: LanguageNativeIconProps) {
  const { t } = useTranslation();

  return (
    <Tooltip title={`${t(isNative ? 'Native speaker' : 'Non native')}`}>
      {isNative ? (
        <NativeSpeakerIcon fontSize="small" color="action" {...svgIconProps} />
      ) : (
        <NonNativeIcon fontSize="small" color="action" {...svgIconProps} />
      )}
    </Tooltip>
  );
}
