import {
  BaseEntity,
  FileAccessDTO,
  UploadAccessPermission,
  UploadAccessPermissionGrant
} from 'model';

export const NoOp = () => {};

type PropertyValueTransformer<T, K extends keyof T> =
  | keyof T[K]
  | ((value: T[K], e: T) => any);
type PropertyTransformation<T, K extends keyof T> = [
  pvt?: PropertyValueTransformer<T, K>,
  rename?: string
];
type TransformMap<T> = {
  [k in keyof T]?: PropertyTransformation<T, k>;
};
export function transformEntity<T, M extends TransformMap<T>>(
  entity: T,
  transformMap: M
) {
  return Object.fromEntries(
    Object.entries(entity).map(([k, v]) => {
      const [transformer, rename] = transformMap[
        k as keyof T
      ] as PropertyTransformation<T, keyof T>;

      const key = rename ?? k;
      if (typeof transformer === 'string') {
        return [key, v[transformer as keyof T[keyof T]]];
      }
      if (typeof transformer === 'function') {
        return [key, transformer(v, entity)];
      }
      return [key, v];
    })
  );
}

export function createFileGrant<
  N extends string,
  M extends UploadAccessPermissionGrant<N>,
  A extends BaseEntity
>(fileId: string, permission: UploadAccessPermission<N, A, M>, ref: A) {
  return FileAccessDTO.createUnsigned(
    (json) => btoa(json),
    fileId,
    permission,
    ref
  );
}

export function createFileGrantToken<
  N extends string,
  M extends UploadAccessPermissionGrant<N>,
  A extends BaseEntity
>(fileId: string, permission: UploadAccessPermission<N, A, M>, ref: A) {
  return btoa(JSON.stringify({ ...createFileGrant(fileId, permission, ref) }));
}
