"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var OrderTask_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderTask = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
// eslint-disable-next-line import/no-cycle
const ProjectOrder_1 = require("../../project/entity/ProjectOrder");
// eslint-disable-next-line import/no-cycle
const UploadFile_1 = require("../../files/entity/UploadFile");
const JournaledEntity_1 = require("../../JournaledEntity");
let OrderTask = OrderTask_1 = class OrderTask extends JournaledEntity_1.JournaledEntity {
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => OrderTask_1, { nullable: true }),
    __metadata("design:type", OrderTask)
], OrderTask.prototype, "parentTask", void 0);
__decorate([
    (0, typeorm_1.RelationId)((ot) => ot.parentTask),
    __metadata("design:type", String)
], OrderTask.prototype, "parentTaskId", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => OrderTask_1),
    (0, typeorm_1.JoinTable)({
        joinColumn: { name: 'targetId', referencedColumnName: 'id' },
        inverseJoinColumn: { name: 'sourceId', referencedColumnName: 'id' }
    }),
    __metadata("design:type", Array)
], OrderTask.prototype, "dependencies", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectOrder_1.ProjectOrder),
    __metadata("design:type", ProjectOrder_1.ProjectOrder)
], OrderTask.prototype, "order", void 0);
__decorate([
    (0, typeorm_1.RelationId)((ot) => ot.order),
    __metadata("design:type", String)
], OrderTask.prototype, "orderId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], OrderTask.prototype, "description", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], OrderTask.prototype, "taskType", void 0);
__decorate([
    (0, typeorm_1.Column)('timestamp', { nullable: true }),
    (0, class_transformer_1.Type)(() => Date),
    __metadata("design:type", Date)
], OrderTask.prototype, "deadline", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], OrderTask.prototype, "state", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => UploadFile_1.UploadFile),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], OrderTask.prototype, "attachedFiles", void 0);
__decorate([
    (0, typeorm_1.Column)('jsonb'),
    __metadata("design:type", Object)
], OrderTask.prototype, "importDataRef", void 0);
OrderTask = OrderTask_1 = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.TableInheritance)({ column: { name: 'taskType', type: 'varchar' } })
], OrderTask);
exports.OrderTask = OrderTask;
