import DashboardIcon from '@material-ui/icons/Dashboard';
import UsersIcon from '@material-ui/icons/People';
import UserIcon from '@material-ui/icons/Person';
import RolesIcon from '@material-ui/icons/Security';
import SettingsIcon from '@material-ui/icons/Settings';
import ClientsIcon from '@material-ui/icons/SupervisedUserCircle';
import LanguagesIcon from '@material-ui/icons/Translate';
import { permissions } from 'model';

import {
  Person as NewAccountIcon,
  EmojiPeople as SuppliersIcon,
  RoomService as ServicesIcon,
  Assignment as ProjectsIcon,
  GTranslate
} from '@material-ui/icons';
import React from 'react';
import { executeMatcher } from '../../features/auth/permissions.matchers';
import { NavLink } from './types';
import { TranslationsNavLabel } from '../file/TranslationsNavLabel';

export const NewEntityActions = [
  {
    label: 'Account',
    icon: <NewAccountIcon />,
    link: `/users/accounts?type=create#account`,
    permission: permissions.users.user.create
  },
  {
    label: 'Client',
    icon: <ClientsIcon />,
    link: `/clients?type=create#client`,
    permission: permissions.clients.client.create
  },
  {
    label: 'Supplier',
    icon: <SuppliersIcon />,
    link: '/suppliers/suppliers?type=create#supplier',
    permission: permissions.suppliers.supplier.create
  },
  {
    label: 'Request',
    icon: <ProjectsIcon />,
    link: `/projects/project-requests?type=create#project-request`,
    permission: permissions.project.projectRequest.create
  }
];

export const primaryNavLinks: NavLink[] = [
  { link: '/dashboard', label: 'Dashboard', icon: DashboardIcon },
  {
    link: '/clients',
    label: 'Clients',
    icon: ClientsIcon,
    permission: permissions.clients.client.screen
  },
  {
    link: '/suppliers',
    label: 'Suppliers',
    icon: SuppliersIcon,
    children: [
      {
        link: '/suppliers',
        label: 'Suppliers',
        icon: UserIcon,
        permission: permissions.suppliers.supplier.screen
      },
      {
        link: '/domains',
        label: 'Domains',
        icon: RolesIcon,
        permission: permissions.suppliers.domain.screen
      },
      {
        link: '/qualifications',
        label: 'Qualifications',
        icon: RolesIcon,
        permission: permissions.suppliers.qualification.screen
      },
      {
        link: '/document-types',
        label: 'Document Types',
        icon: RolesIcon,
        permission: permissions.suppliers.documentType.screen
      },
      {
        link: '/status-types',
        label: 'Status Types',
        icon: RolesIcon,
        permission: permissions.suppliers.statusType.screen
      },
      {
        link: '/test-types',
        label: 'Tests',
        icon: RolesIcon,
        permission: permissions.suppliers.testType.screen
      }
    ]
  },
  {
    link: '/projects',
    label: 'Projects',
    icon: ProjectsIcon,
    children: [
      {
        link: '/projects',
        label: 'Projects',
        icon: ProjectsIcon,
        permission: permissions.project.project.screen
      },
      {
        link: '/project-tag',
        label: 'Project Tags',
        icon: ProjectsIcon,
        permission: permissions.project.projectTag.screen
      },
      {
        link: '/project-variation',
        label: 'Project Variations',
        icon: ServicesIcon,
        permission: permissions.project.projectVariation.screen
      },
      {
        link: '/large-scale-projects',
        label: 'Large-Scale Projects',
        icon: ProjectsIcon,
        permission: permissions.project.large.screen
      },
      {
        link: '/project-requests',
        label: 'Requests',
        icon: ProjectsIcon,
        permission: permissions.project.projectRequest.screen
      },
      {
        link: '/request-status',
        label: 'Request Status',
        icon: ProjectsIcon,
        permission: permissions.project.requestStatus.screen
      },
      {
        link: '/request-source',
        label: 'Request Sources',
        icon: ProjectsIcon,
        permission: permissions.project.requestSource.screen
      }
    ]
  },
  {
    link: '/services',
    label: 'Services',
    icon: ServicesIcon,
    children: [
      {
        link: '/service-class',
        label: 'Services',
        icon: ServicesIcon,
        permission: permissions.services.serviceClass.screen
      },
      {
        link: '/service-step',
        label: 'Service Steps',
        icon: ServicesIcon,
        permission: permissions.services.serviceClass.screen
      },
      {
        link: '/price-unit',
        label: 'Price Units',
        icon: ServicesIcon,
        permission: permissions.prices.priceUnit.screen
      },
      {
        link: '/price-bound',
        label: 'Price Bounds',
        icon: ServicesIcon,
        permission: permissions.prices.priceLimit.screen
      }
    ]
  },
  {
    link: '/languages',
    label: 'Languages',
    icon: LanguagesIcon,
    permission: permissions.language.screen
  },
  {
    label: 'Users',
    link: '/users',
    icon: UsersIcon,
    children: [
      {
        link: '/accounts',
        label: 'Accounts',
        icon: UserIcon,
        permission: permissions.users.user.screen
      },
      {
        link: '/roles',
        label: 'Roles',
        icon: RolesIcon,
        permission: permissions.users.role.screen
      }
    ]
  }
];

export const secondaryNavLinks: NavLink[] = [
  { link: '/settings', label: 'Settings', icon: SettingsIcon },
  {
    link: '/translations',
    label: TranslationsNavLabel,
    icon: GTranslate,
    permission: permissions.translations.manage
  }
];

function buildURLNavLinkMap(
  links: NavLink[],
  parentPath = ''
): Record<string, NavLink> {
  return Object.assign(
    Object.fromEntries(links.map((l) => [parentPath + l.link, l])),
    ...links
      .filter((l) => l.children !== undefined)
      .map((l) => buildURLNavLinkMap(l.children as NavLink[], l.link))
  );
}

export const urlNavLinkMap = buildURLNavLinkMap([
  ...primaryNavLinks,
  ...secondaryNavLinks
]);

export function filterNavLinks(
  links: NavLink[],
  grantedPermissions: string[]
): NavLink[] {
  return links
    .map((link) => {
      const permitted = executeMatcher(
        link.permission,
        grantedPermissions,
        true
      );
      if (permitted) {
        if (link.children !== undefined) {
          const filteredChildren = filterNavLinks(
            link.children,
            grantedPermissions
          );
          return filteredChildren.length > 0
            ? { ...link, children: filteredChildren }
            : undefined;
        }
        return { ...link };
      }
      return undefined;
    })
    .filter((v) => v !== undefined) as NavLink[];
}
