import {
  Supplier,
  SupplierDTO,
  SupplierPatchDTO,
  SupplierStatusDTO
} from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

const { list, ...supplierFeatureThunk } = createFeatureThunks<
  Supplier,
  SupplierDTO,
  SupplierPatchDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/suppliers/supplier${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'suppliers',
  excludeList: true
});
export const suppliersThunk = {
  ...supplierFeatureThunk,
  list: createErrorHandlingThunk<Supplier[], void>(
    `suppliers/list`,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (_, api) => {
      const result = await apiClient.get<Supplier[]>('/suppliers/supplier');
      api.dispatch(suppliersThunk.listDetails());
      return result.data;
    }
  ),
  listDetails: createErrorHandlingThunk<Supplier[], void>(
    'suppliers/list-details',
    async () => {
      const result = await apiClient.get<Supplier[]>(
        '/suppliers/supplier?details=true&baseData=false'
      );
      return result.data;
    }
  )
};

export const changeSupplierStatusThunk = createErrorHandlingThunk<
  Supplier,
  { id: string; value: SupplierStatusDTO }
>('supplier-status/change', async ({ id, value }) => {
  await apiClient.put(`/suppliers/supplier/${id}/status`, value);
  const supplier = await apiClient.get(`/suppliers/supplier/${id}`);
  return supplier.data;
});
