import { permissions, ProjectVariation } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { ApiState } from '../../auth/redux/types';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { projectVariationsThunk } from '../redux/project-variantions.thunk';

function buildTableColumns() {
  return entityColumnBuilder<ProjectVariation>()
    .addDefaultColumn('var_name', 'name', 'Name')
    .addDefaultColumn('var_description', 'description', 'Description').columns;
}

interface ProjectVariationTableProps {
  projectVariations: ProjectVariation[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function ProjectVariationTable({
  projectVariations,
  onRefresh,
  apiState
}: ProjectVariationTableProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  const deleteProjectVariation = (id: string) => {
    dispatch(projectVariationsThunk.delete({ id }));
  };
  return (
    <GenericTable
      label={t('Project Variations')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Project Variation',
        onClick: () => history.push(`${url}?type=create#project-variation`),
        permission: permissions.project.projectVariation.create
      }}
      data={projectVariations}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.project.projectVariation.edit,
          entry: (step) => ({
            icon: EditIcon,
            label: 'Edit',
            link: `${url}?type=edit#${step.id}`
          })
        },
        {
          permissions: permissions.project.projectVariation.delete,
          entry: (sc) => ({
            icon: DeleteIcon,
            label: 'Delete',
            alert: {
              title: 'Delete Project Variation',
              content: `${t(
                'You may only delete a project variation ' +
                  'if it is not referenced anymore. ' +
                  'Otherwise an error will occur. ' +
                  'Are you sure you want to delete project variation '
              )} "${sc.name}"?`,
              onConfirm: () => {
                deleteProjectVariation(sc.id);
              }
            }
          })
        }
      ]}
      toolbarActions={[]}
    />
  );
}
