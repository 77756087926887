import React from 'react';
import { Button, CircularProgress, Divider, Grid } from '@material-ui/core';
import { SupplierLanguage, SupplierPatchDTO } from 'model';
import * as yup from 'yup';
import PlusIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import {
  buildDTOView,
  useTypedField
} from '../../../transformer/DTOViewSchema';
import { SupplierServiceFormElement } from './services/SupplierServiceFormElement';
import { SupplierServiceStepForm } from './steps/SupplierServiceStepForm';

export const SupplierServiceView = buildDTOView<SupplierPatchDTO>()
  .include('services', 'serviceSteps')
  .withSchema(
    yup
      .object({
        services: yup.array().of(
          yup.object({
            serviceClassId: yup.string().uuid().required('required'),
            comment: yup.string().default(''),
            languages: yup
              .array()
              .of(
                yup
                  .object({
                    sourceLanguage: yup
                      .string()
                      .uuid()
                      .defined()
                      .nullable()
                      .default(null),
                    targetLanguage: yup
                      .string()
                      .uuid()
                      .required()
                      .defined() as any
                  })
                  .required()
              )
              .required()
          })
        ),
        serviceSteps: yup.array().of(
          yup.object({
            serviceStepId: yup.string().uuid().required('required'),
            comment: yup.string().default('')
          })
        )
      })
      .defined()
  );

export function SupplierServiceForm({
  languages
}: {
  languages: SupplierLanguage[];
}) {
  const { t } = useTranslation();
  const [, meta, helpers] = useTypedField(SupplierServiceView.path.services);

  const removeService = (serviceIndex: number) =>
    helpers.setValue(meta.value.filter((_, i) => i !== serviceIndex));

  const addEmptyService = () => {
    helpers.setValue([
      ...meta.value,
      {
        serviceClassId: '',
        comment: '',
        languages: []
      }
    ]);
  };

  return meta.value !== undefined ? (
    <>
      <Grid container spacing={1} xs={12}>
        {meta.value.map((service, serviceIndex) => {
          return (
            <SupplierServiceFormElement
              languages={languages}
              path={SupplierServiceView.path.services.at(serviceIndex)}
              onRemove={() => removeService(serviceIndex)}
            />
          );
        })}
        <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            style={{ marginBottom: 20 }}
            onClick={addEmptyService}
            startIcon={<PlusIcon />}
          >
            {t('Add Service')}
          </Button>
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <SupplierServiceStepForm />
    </>
  ) : (
    <CircularProgress />
  );
}
