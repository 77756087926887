import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../redux/redux.hooks';
import { requestResourceSelectors } from '../redux/request-resources.slice';

export function useResourcesOfRequest(requestId: string) {
  const resources = useAppSelector(
    (state) => requestResourceSelectors.selectByRequest(state, requestId),
    shallowEqual
  );
  return { resources };
}
