import { Divider, List } from '@material-ui/core';
import React from 'react';
import { Project, ProjectCandidate } from 'model';
import { CandidateDisplay } from './CandidateDisplay';
import { SimpleCandidateSelector } from './SimpleCandidateSelector';

export interface CandidateCategoryProps {
  withAdd?: boolean;
  candidates: ProjectCandidate[];
  project: Project;
  title?: JSX.Element | string;
  removed?: boolean;
}
export function CandidateCategory({
  withAdd,
  candidates,
  project,
  title,
  removed = false
}: CandidateCategoryProps) {
  return (
    <>
      {title && (
        <>
          {title}
          <Divider />
        </>
      )}
      <List dense>
        {candidates.map((candidate) => (
          <CandidateDisplay
            project={project}
            candidate={candidate}
            removed={removed}
          />
        ))}
      </List>
      {withAdd && <SimpleCandidateSelector project={project} />}
    </>
  );
}
