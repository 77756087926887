import React from 'react';
import { permissions, SupplierTestType } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Delete as DeleteIcon, Edit } from '@material-ui/icons';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { ApiState } from '../../auth/redux/types';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { supplierTestTypesThunk } from '../redux/test-types.thunk';

function buildTableColumns() {
  return entityColumnBuilder<SupplierTestType>()
    .addDefaultColumn('test_type_type', 'testVariant', 'Variant', {
      createElement: (value) =>
        value === 'simple' ? <span>Simple</span> : <span>Normal</span>
    })
    .addDefaultColumn('test_type_name', 'name', 'Name')
    .addDefaultColumn('test_type_description', 'description', 'Description')
    .columns;
}

interface SupplierTestTypesTableProps {
  supplierTestTypes: SupplierTestType[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function SupplierTestTypesTable({
  supplierTestTypes,
  onRefresh,
  apiState
}: SupplierTestTypesTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  const dispatch = useAppDispatch();
  const deleteTestType = (id: string) => {
    dispatch(supplierTestTypesThunk.delete({ id }));
  };

  return (
    <GenericTable
      label={t('Test Types')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Test Type',
        onClick: () => history.push(`${url}?type=create#test-type`)
      }}
      data={supplierTestTypes}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.suppliers.statusType.edit,
          entry: (testType) => ({
            label: 'Edit',
            icon: Edit,
            link: `${url}?type=edit#${testType.id}`
          })
        },
        {
          permissions: permissions.suppliers.statusType.delete,
          entry: (d) => ({
            icon: DeleteIcon,
            label: 'Delete',
            alert: {
              title: 'Delete Test Type',
              content: `${t(
                'Are you  sure you want to delete the Test Type '
              )} "${d.name}"? It may only be deleted if no references exist!`,
              onConfirm: () => {
                deleteTestType(d.id);
              }
            }
          })
        }
      ]}
      toolbarActions={
        [
          /*        {
                              icon: <PlusIcon />,
                              label: 'Merge',
                              showWhen: 'selected',
                              onClick: () => {}
                            } */
        ]
      }
    />
  );
}
