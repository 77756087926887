import React from 'react';
import { ServiceRequestPosition } from 'model';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';
import { usePriceUnit } from '../../../../../prices/hooks/price-unit.hook';
import { formatDate } from '../../../../../../localized-formats';
import { useServiceClass } from '../../../../../services/hooks/service-class.hook';
import { RequestPositionLanguagesDisplay } from './RequestPositionLanguagesDisplay';

interface ServiceRequestPositionDisplayProps {
  requestPosition: ServiceRequestPosition;
}

export function ServiceRequestPositionDisplay({
  requestPosition
}: ServiceRequestPositionDisplayProps) {
  const { t } = useTranslation();
  const { priceUnit } = usePriceUnit(requestPosition.priceUnitId);
  const { serviceClass } = useServiceClass(requestPosition.serviceClassId);
  return (
    <div>
      <Typography>{serviceClass ? serviceClass.name : <Skeleton />}</Typography>
      <div style={{ paddingLeft: 10 }}>
        <Typography variant="subtitle1" color="textSecondary">
          {requestPosition.description}
        </Typography>
        {requestPosition.languages && (
          <RequestPositionLanguagesDisplay
            languages={requestPosition.languages}
          />
        )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {requestPosition.estimatedAmount && (
            <div style={{ marginRight: 10 }}>
              <Typography variant="caption" color="textSecondary">
                Est. Amount
              </Typography>
              <Typography variant="body2">
                {requestPosition.estimatedAmount}
              </Typography>
            </div>
          )}
          {priceUnit && (
            <div style={{ marginRight: 10 }}>
              <Typography variant="caption" color="textSecondary">
                Price Unit
              </Typography>
              <Typography variant="body2">{priceUnit.name}</Typography>
            </div>
          )}
          {requestPosition.deadline && (
            <div>
              <Typography variant="caption" color="textSecondary">
                {t('Deadline')}
              </Typography>
              <Typography variant="body2">
                {formatDate(requestPosition.deadline, 'date')}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
