import { Client } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const listClientsThunk = createErrorHandlingThunk(
  'clients/list',
  async () => {
    const result = await apiClient.get<Client[]>('/client');
    return result.data;
  }
);
