import React from 'react';
import { SupplierQualification } from 'model';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useSupplierQualification } from '../../hooks/supplier-qualifikation.hook';

interface QualificationsComponentProps {
  supplierQualification: SupplierQualification;
}

function QualificationComponent({
  supplierQualification
}: QualificationsComponentProps) {
  const { qualification } = useSupplierQualification(
    supplierQualification.qualificationId
  );

  return (
    <Typography variant="inherit">
      {qualification?.name ? qualification?.name : <Skeleton />}
    </Typography>
  );
}

export function QualificationsColumnRenderer({
  qualifications
}: {
  qualifications: SupplierQualification[];
}) {
  return (
    <>
      {qualifications?.map((q) => (
        <QualificationComponent supplierQualification={q} />
      ))}
    </>
  );
}
