import React from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../redux/redux.hooks';
import { translationsSelectors } from './translations.slice';
import { listTranslationsThunk } from './translations.thunk';

export function useResourceTranslations() {
  const apiState = useAppSelector(translationsSelectors.selectState);
  const listFetched = useAppSelector(translationsSelectors.selectListFetched);
  const translations = useAppSelector(
    translationsSelectors.selectAll,
    shallowEqual
  );
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (
      apiState === 'fresh' ||
      (apiState !== 'pending' && listFetched === false)
    ) {
      dispatch(listTranslationsThunk());
    }
  }, [apiState, listFetched]);
  const untranslated = React.useMemo(
    () => translations.filter((t) => t.translation === null).length,
    [translations]
  );
  const sortedTranslations = React.useMemo(
    () => [...translations].sort((a, b) => a.key.localeCompare(b.key)),
    [translations]
  );
  return { translations: sortedTranslations, untranslated };
}
