import React from 'react';
import { useServiceSteps } from '../hooks/service-steps.hook';
import { ServiceStepTable } from '../components/ServiceStepTable';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { ServiceStepFormDialog } from '../components/SupplierStepFormDialog';

export function ServiceStepScreen() {
  const { entities, apiState, listEntities } = useServiceSteps();
  const { type, reference } = useEditorLocation();

  return (
    <>
      {type === 'edit' && reference !== undefined && (
        <ServiceStepFormDialog stepId={reference} />
      )}
      <ServiceStepTable
        serviceSteps={entities}
        onRefresh={listEntities}
        apiState={apiState}
      />
    </>
  );
}
