import React from 'react';
import { LargeScaleProject, permissions } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Delete as DeleteIcon, Edit } from '@material-ui/icons';
import { entityColumnBuilder } from '../../../../components/table/columns.builder';
import {
  GenericTable,
  useCreateCells
} from '../../../../components/table/GenericTable';
import { ApiState } from '../../../auth/redux/types';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { largeScaleProjectThunk } from '../../redux/large-scale-projects.thunk';
import { ClientLink } from '../../../clients/components/basic/ClientLink';

function buildTableColumns() {
  return entityColumnBuilder<LargeScaleProject>()
    .addDefaultColumn('ls_project_name', 'name', 'Name')
    .addDefaultColumn('ls_project_description', 'description', 'Description')
    .addColumn('ls_project_client', 'client', 'Kunde', {
      renderer: {
        createElement: (_, s) => {
          return <ClientLink value={s.client} />;
        },
        createText: (_, data) => [data.client.name]
      }
    }).columns;
}

interface LargeScaleProjectsTableProps {
  projects: LargeScaleProject[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function LargeScaleProjectsTable({
  projects,
  onRefresh,
  apiState
}: LargeScaleProjectsTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  const dispatch = useAppDispatch();
  const deleteDomain = (id: string) => {
    dispatch(largeScaleProjectThunk.delete({ id }));
  };

  return (
    <GenericTable
      label={t('Large-Scale Projects')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New LSP',
        onClick: () => history.push(`${url}?type=create#large-scale-project`),
        permission: permissions.project.large.create
      }}
      data={projects}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.project.large.edit,
          entry: (project) => ({
            label: 'Edit',
            icon: Edit,
            link: `${url}?type=edit#${project.id}`
          })
        },
        {
          permissions: permissions.project.large.delete,
          entry: (d) => ({
            icon: DeleteIcon,
            label: 'Delete',
            alert: {
              title: 'Delete Domain',
              content: `${t('Are you  sure you want to delete the domain ')} "${
                d.name
              }"?`,
              onConfirm: () => {
                deleteDomain(d.id);
              }
            }
          })
        }
      ]}
      toolbarActions={[]}
    />
  );
}
