import { format } from 'date-fns';

export const DateTimeFormats = {
  date: {
    default: 'dd MMM yyyy',
    exact: 'dd MMM yyyy'
  },
  'date-time': {
    default: 'dd MMM yyyy HH:mm',
    exact: 'dd MMM yyyy HH:mm:ss'
  },
  'month-year': {
    default: 'MMM yyyy',
    exact: 'MMM yyyy'
  },
  'month-year-short': {
    default: 'MMM yy',
    exact: 'MMM yy'
  },
  time: {
    default: 'HH:mm',
    exact: 'HH:mm:ss'
  }
};

export const DateFormatters = {
  'month-year': (date: Date) => format(date, 'MMM yyyy'),
  'month-year-short': (date: Date) => format(date, 'MMM yy'),
  date: (date: Date) => format(date, 'dd MMM yyyy'),
  'date-time': (date: Date) => format(date, 'dd MMM yyyy HH:mm'),
  time: (date: Date) => format(date, 'HH:mm'),
  'exact-time': (date: Date) => format(date, 'HH:mm:ss'),
  'exact-date-time': (date: Date) => format(date, 'dd MMM yyyy HH:mm:ss')
};

export type DateFormatType = keyof typeof DateTimeFormats;
export type DateFormatVariant = keyof (typeof DateTimeFormats)[DateFormatType];
export function formatDate(
  date: Date | string,
  type: DateFormatType = 'date-time',
  variant: DateFormatVariant = 'default'
) {
  const realDate = typeof date === 'string' ? new Date(date) : date;
  return format(realDate, DateTimeFormats[type][variant]);
  // return DateFormatters[type](realDate);
}
