import React from 'react';
import {
  PlainRequestPosition,
  RequestPosition,
  ServiceRequestPosition
} from 'model';
import { ServiceRequestPositionDisplay } from './ServiceRequestPositionDisplay';
import { PlainRequestPositionDisplay } from './PlainRequestPositionDisplay';

interface RequestPositionDisplayProps {
  value: RequestPosition;
}

export function RequestPositionDisplay({ value }: RequestPositionDisplayProps) {
  return (
    <div>
      <>
        {value.positionType === 'service-position' ? (
          <ServiceRequestPositionDisplay
            requestPosition={value as ServiceRequestPosition}
          />
        ) : (
          <PlainRequestPositionDisplay
            requestPosition={value as PlainRequestPosition}
          />
        )}
      </>
    </div>
  );
}
