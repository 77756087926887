import React from 'react';
import {
  ButtonBase,
  Chip,
  CircularProgress,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import * as Showdown from 'showdown';
import { Link as RouterLink } from 'react-router-dom';
import { UploadFile, userAvatarPermission } from 'model';
import { ReferenceLink } from '../navigation/links/ReferenceLink';
import { formatDate } from '../../localized-formats';
import { CommentInterface } from './types';
import { useUser } from '../../features/users/hooks/user.hook';
import { UserAvatar } from '../image/UserAvatar';

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

interface CommentDisplayProps {
  comment: CommentInterface;
  createFileGrant: (file: UploadFile) => string;
}

export function CommentDisplay(props: CommentDisplayProps) {
  const { authorId, comment, createdAt, attachedFiles } = props.comment;
  const { user: creator } = useUser(authorId);
  return (
    <Grid
      container
      // wrap="nowrap"
      spacing={2}
    >
      <Grid item>
        {creator && (
          <UserAvatar
            avatar={creator.avatar}
            grant={userAvatarPermission.createGrant(creator)}
            permission={userAvatarPermission}
          />
        )}
      </Grid>
      <Grid item xs zeroMinWidth>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ margin: 0, textAlign: 'left' }}>
            {creator ? (
              <ReferenceLink
                refUrl="accounts"
                value={creator}
                display={
                  creator.displayName ? (
                    <Tooltip title={creator.email}>
                      <Typography display="inline">
                        {creator.displayName}
                      </Typography>
                    </Tooltip>
                  ) : (
                    'email'
                  )
                }
              />
            ) : (
              <CircularProgress />
            )}
          </Typography>
        </div>
        <div
          style={{ textAlign: 'left' }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(comment) }}
        />
      </Grid>
      <Grid item>
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            {formatDate(createdAt)}
          </Typography>
          {attachedFiles &&
            attachedFiles.map((af) => (
              <ButtonBase style={{ padding: 5, borderRadius: 10 }}>
                <Chip
                  size="small"
                  component={RouterLink}
                  to={`/files/${af.id}/${props.createFileGrant(af)}`}
                  target="blank"
                  label={
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}
                    >
                      {af.name}
                      <GetApp
                        style={{ marginLeft: 4, fontSize: 14 }}
                        fontSize="small"
                        color="action"
                      />
                    </div>
                  }
                />
              </ButtonBase>
            ))}
        </div>
      </Grid>
    </Grid>
  );
}
