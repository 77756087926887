import React from 'react';
import { useParams } from 'react-router-dom';
import { User } from 'model';
import { Chip } from '@material-ui/core';
import { useUser } from '../hooks/user.hook';
import {
  EntityDetails,
  TabDefinition
} from '../../../components/details/EntityDetails';
import { AccountProfile } from '../components/AccountProfile';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { userThunks } from '../redux/actions/users.thunk';
import { AccountFormDialog } from '../components/AccountFormDialog';

const tabs: TabDefinition<User>[] = [
  {
    name: 'profile',
    subPaths: [''],
    component: (user: User) => <AccountProfile account={user} />
  }
];

export function AccountDetailsScreen() {
  const dispatch = useAppDispatch();
  const { userId } = useParams<{ userId: string }>();
  const { user } = useUser(userId);
  const userStatus = <Chip label={user?.enabled ? 'active' : 'inactive'} />;

  React.useEffect(() => {
    dispatch(userThunks.get({ id: userId }));
  }, []);

  return (
    <>
      <AccountFormDialog />
      <EntityDetails
        entityName="Account"
        title="email"
        tabs={tabs}
        entity={user}
        titleAdornment={userStatus}
      />
    </>
  );
}
