import React from 'react';
import { permissions, Qualification } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Delete as DeleteIcon, Edit } from '@material-ui/icons';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { ApiState } from '../../auth/redux/types';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { supplierQualificationsThunk } from '../redux/supplier-qualifications.thunk';

function buildTableColumns() {
  return entityColumnBuilder<Qualification>()
    .addDefaultColumn('supplier_domain_name', 'name', 'Name')
    .addDefaultColumn(
      'supplier_domain_description',
      'description',
      'Description'
    ).columns;
}

interface SupplierQualificationsTableProps {
  supplierQualifications: Qualification[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function SupplierQualificationsTable({
  supplierQualifications,
  onRefresh,
  apiState
}: SupplierQualificationsTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  const dispatch = useAppDispatch();
  const deleteDomain = (id: string) => {
    dispatch(supplierQualificationsThunk.delete({ id }));
  };

  return (
    <GenericTable
      label={t('Qualifications')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Qualification',
        onClick: () => history.push(`${url}?type=create#supplier-qualification`)
      }}
      data={supplierQualifications}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.suppliers.qualification.edit,
          entry: (domain) => ({
            label: 'Edit',
            icon: Edit,
            link: `${url}?type=edit#${domain.id}`
          })
        },
        {
          permissions: permissions.suppliers.qualification.delete,
          entry: (d) => ({
            icon: DeleteIcon,
            label: 'Delete',
            alert: {
              title: 'Delete Domain',
              content: `${t(
                'Are you  sure you want to delete the qualification '
              )} "${d.name}"?`,
              onConfirm: () => {
                deleteDomain(d.id);
              }
            }
          })
        }
      ]}
      toolbarActions={[]}
    />
  );
}
