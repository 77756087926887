import React from 'react';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { listClientProjectsThunk } from '../redux/projects.thunk';
import {
  projectSelectors,
  selectClientProjects
} from '../redux/projects.slice';

export function useClientProjects(clientId: string) {
  const dispatch = useAppDispatch();

  const clientProjects = useAppSelector(
    selectClientProjects(clientId),
    shallowEqual
  );
  const listClientProjects = (id: string) =>
    dispatch(listClientProjectsThunk({ clientId: id }));

  const apiState = useAppSelector(projectSelectors.selectState);

  React.useEffect(() => {
    listClientProjects(clientId);
  }, [clientId]);

  return { clientProjects, apiState, listClientProjects };
}
