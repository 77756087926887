import React from 'react';
import {
  Client,
  currencyMap,
  DefaultCurrency,
  ProjectOffer,
  ScalarOfferPositionDTO
} from 'model';
import {
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Edit as EditIcon, FileCopy } from '@material-ui/icons';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useUser } from '../../../../../users/hooks/user.hook';
import { formatDate } from '../../../../../../localized-formats';
import { StyledLink } from '../../../../../../components/input/link/StyledLink';
import { AddressDisplay } from '../../../../../../components/address/AddressDisplay';
import { IconButtonLink } from '../../../../../../components/input/button/IconButtonLink';
import { OffersPositionTable } from '../OffersPositionTable';
import { groupOfferPositions } from './EditCurrentOfferDocumentForm';
import { calculatePositionTotal } from '../OfferPositionsForm';
import { MoneyNumberFormat } from '../../../../../clients/components/prices/FormikPriceInput';
import { PercentageFormat } from '../OfferTotalSum';
import { usePriceUnits } from '../../../../../prices/hooks/price-units.hook';

export function OfferDocumentDisplay({
  offerNr,
  offer,
  client,
  isCurrent
}: {
  offer?: ProjectOffer;
  client: Client;
  offerNr: number;
  isCurrent?: boolean;
}) {
  const { t } = useTranslation();
  const { user } = useUser(offer?.authorId);
  const { url } = useRouteMatch();
  const { entities: priceUnits } = usePriceUnits();
  const dtoPositions = React.useMemo(
    () => groupOfferPositions(offer),
    [offer, offer?.positions]
  );
  if (!offer) {
    return <></>;
  }

  return (
    <Card style={{ position: 'relative' }}>
      {!offer.rejectionId && !offer.orderId && isCurrent && (
        <Box display="flex" position="absolute" top="10px" right="20px">
          <IconButtonLink size="small" to={`${url}?type=edit#offers`}>
            <EditIcon fontSize="small" />
          </IconButtonLink>
          <IconButton
            title={t('Copy layouted')}
            size="small"
            onClick={() => {
              const calculatedSum =
                dtoPositions
                  ?.map((pos) => calculatePositionTotal(pos))
                  ?.reduce((acc, c) => acc + c, 0) ?? 0;
              const currency =
                currencyMap[
                  (offer.currency ??
                    DefaultCurrency.code) as keyof typeof currencyMap
                ].symbol;
              const container = document.createElement('div');
              container.innerHTML =
                document.getElementById('current-offer-positions-table')
                  ?.outerHTML ?? '';
              const sumFooter = document.createElement('tfoot');
              sumFooter.innerHTML = `<tr>
                <td colspan="4"/>
                <td>${t('Total Netto')}</td>
                <td>${MoneyNumberFormat.format(
                  offer.totalPrice?.amount ?? calculatedSum
                )} ${currency}</td>
</tr>
<tr>
                <td colspan="4"/>
                <td>${t('Tax')} ${PercentageFormat.format(
                offer.taxPercentage ?? 0
              )}%</td>
                <td>${MoneyNumberFormat.format(
                  (offer.totalPrice?.amount ?? calculatedSum) *
                    (offer.taxPercentage ?? 0) *
                    0.01
                )} ${currency}</td>
</tr><tr>
                <td colspan="4"/>
                <td>${t('Total Brutto')}</td>
                <td>${MoneyNumberFormat.format(
                  (offer.totalPrice?.amount ?? calculatedSum) *
                    (1 + (offer.taxPercentage ?? 0) / 100)
                )} ${currency}</td>
</tr>`;
              container.querySelector('table')?.append(sumFooter);

              // Hide element
              // [2]
              container.style.position = 'fixed';
              container.style.pointerEvents = 'none';
              container.style.opacity = '0';
              // [3]
              document.body.appendChild(container);

              // Copy to clipboard
              // [4]
              window.getSelection()?.removeAllRanges();

              const range = document.createRange();
              range.selectNode(container);
              window.getSelection()?.addRange(range);

              // [5.1]
              document.execCommand('copy');
              // Remove the container
              // [6]
              document.body.removeChild(container);
              toast.success('Copied to clipboard');
            }}
          >
            <FileCopy fontSize="small" />
          </IconButton>

          <IconButton
            title={t('Copy to wordbee format')}
            size="small"
            onClick={() => {
              // Copy to wordbee logic
              const calculatedSum =
                dtoPositions
                  ?.map((pos) => calculatePositionTotal(pos))
                  ?.reduce((acc, c) => acc + c, 0) ?? 0;
              const currency =
                currencyMap[
                  (offer.currency ??
                    DefaultCurrency.code) as keyof typeof currencyMap
                ].symbol;
              const currencyCode =
                currencyMap[
                  (offer.currency ??
                    DefaultCurrency.code) as keyof typeof currencyMap
                ].code;
              // Copy/Paste Format
              const simpleHtml = `<table>
<thead>
<tr style="background-color: #f2f2f2">
<th style="text-align:left">${t('Pos')}</th>
<th style="text-align:left; border-left:none;">${t('Beschreibung')}</th>
<th style="text-align:left; border-left:none; border-right:none;"/>
<th style="text-align:left;border-left:none;">${t('Menge')}</th>
<th style="text-align:left; border-left:none; border-right:none;"/>
<th style="text-align:left; border-left:none; border-right:none;"/>
<th style="text-align:left">${t('Einzelpreis')}</th>
<th/>
<th/>
<th style="text-align:right">${t('Betrag')}</th>
<th/>
</tr>
</thead>
<tbody>
    ${dtoPositions
      .filter(ScalarOfferPositionDTO.isScalarPos)
      .map(
        (pos, posI) => `<tr>
        <td style="text-align:left; vertical-align: top;">${posI + 1}</td>
        <td style="text-align:left; vertical-align: top;">${pos.name}${
          pos.description ? `<br/>${pos.description}` : ''
        }</td>
        <td/>
        <td style="text-align:left; vertical-align: top;" colspan="3">${
          pos.amount
        } ${t(
          `${
            priceUnits.find((pu) => pu.id === pos.priceUnitId)?.name ?? 'unit'
          }(s)`
        )}${pos.amountDescription ? `<br/>${pos.amountDescription}` : ''}</td>
       <td style="text-align:left; vertical-align: top;" colspan="3">
        ${currencyCode} ${MoneyNumberFormat.format(pos.unitPrice)} ${t(
          `per ${
            pos.priceUnitId
              ? priceUnits.find((pu) => pu.id === pos.priceUnitId)?.name
              : 'unit'
          }`
        )} ${
          pos.unitPriceDescription ? `<br/>${pos.unitPriceDescription}` : ''
        }</td>
        <td style="text-align:right; vertical-align: top;">
${MoneyNumberFormat.format(pos.totalPrice ?? calculatePositionTotal(pos))}</td>
        <td style="text-align:left; vertical-align: top;">${currency}</td>
</tr>`
      )
      .join('')}
    <tr>
        <td colspan="7"></td>
        <td style="text-align:left" colspan="2">${t('Total Netto')}</td>
        <td style="text-align:right">${MoneyNumberFormat.format(
          offer.totalPrice?.amount ?? calculatedSum
        )}</td>
        <td>${currency}</td>
    </tr>
    <tr>
        <td colspan="7"></td>
        <td style="text-align:left" colspan="1">${t('Tax')}</td>
        <td style="text-align:right" colspan="1">${PercentageFormat.format(
          offer.taxPercentage ?? 0
        )}%</td>
        <td  style="text-align:right" >${MoneyNumberFormat.format(
          (offer.totalPrice?.amount ?? calculatedSum) *
            (offer.taxPercentage ?? 0) *
            0.01
        )}</td>
        <td style="text-align:left">${currency}</td>
    </tr>
    <tr>
        <td colspan="7"></td>
        <td style="text-align:left" colspan="2">${t('Total Brutto')}</td>
        <td style="text-align:right">${MoneyNumberFormat.format(
          (offer.totalPrice?.amount ?? calculatedSum) *
            (1 + (offer.taxPercentage ?? 0) / 100)
        )}</td>
        <td style="text-align:left">${currency}</td>
    </tr>
</tbody>
</table>`;
              // Copy / Paste Format End

              const container = document.createElement('div');
              container.innerHTML = simpleHtml;
              // Hide element
              // [2]
              container.style.position = 'fixed';
              container.style.pointerEvents = 'none';
              container.style.opacity = '0';
              // [3]
              document.body.appendChild(container);

              // Copy to clipboard
              // [4]
              window.getSelection()?.removeAllRanges();

              const range = document.createRange();
              range.selectNode(container);
              window.getSelection()?.addRange(range);

              // [5.1]
              document.execCommand('copy');
              // Remove the container
              // [6]
              document.body.removeChild(container);
              toast.success('Copied to clipboard');
            }}
          >
            <FileCopy fontSize="small" />
          </IconButton>
        </Box>
      )}
      <CardContent>
        <Typography variant="subtitle2">
          <StyledLink to={`/clients/${client.id}`}>{client.name}</StyledLink>
        </Typography>
        <Typography variant="caption">{client.ceo}</Typography>
        {client.invoiceAddress && (
          <Typography variant="caption">
            <AddressDisplay
              withoutComment
              withoutCountry
              address={client.invoiceAddress}
            />
          </Typography>
        )}
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          paddingTop="30px"
          paddingBottom="5px"
        >
          <Typography variant="h5">{`${t('Offer Nr.')} ${offerNr}`}</Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <div>
              <Typography variant="subtitle2">{t('Currency')}</Typography>
              <Typography variant="body2">
                {
                  (offer.currency
                    ? currencyMap[offer.currency as keyof typeof currencyMap]
                    : DefaultCurrency
                  ).symbol
                }
              </Typography>
            </div>
            <div style={{ paddingLeft: 60 }}>
              <Typography variant="subtitle2">{t('Offer Date')}</Typography>
              <Typography variant="body2">
                {formatDate(offer.offerDate, 'date')}
              </Typography>
            </div>
            {offer.validUntil && (
              <div style={{ paddingLeft: 60 }}>
                <Typography variant="subtitle2">{t('valid until')}</Typography>
                <Typography variant="body2">
                  {formatDate(offer.validUntil, 'date')}
                </Typography>
              </div>
            )}
          </div>
        </Box>
        <Divider style={{ marginTop: 20 }} />
        <OffersPositionTable
          total={offer.totalPrice?.amount ?? null}
          offerDto={dtoPositions}
          taxPercentage={offer.taxPercentage}
          currency={
            (offer.currency
              ? currencyMap[offer.currency as keyof typeof currencyMap]
              : DefaultCurrency
            ).symbol
          }
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 30,
            alignItems: 'flex-end'
          }}
        >
          <Typography variant="caption" color="textSecondary">
            {t('Author')}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            <StyledLink to={`/users/accounts/${user?.id}`} variant="body1">
              {user && (user.displayName ?? user.email)}
            </StyledLink>
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
