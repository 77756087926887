import React from 'react';
import * as yup from 'yup';
import { SupplierDomain, SupplierDomainDTO } from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { DialogForm } from '../../../components/input/form/DialogForm';
import suppliersSlice, { suppliersSelectors } from '../redux/suppliers.slice';
import { NoOp } from '../../../utils';
import { supplierDomainsThunk } from '../redux/supplier-domains.thunk';
import { SupplierDomainBasicForm } from './SupplierDomainBasicForm';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { supplierDomainsSelectors } from '../redux/supplier-domains.slice';

const domainSchema: yup.SchemaOf<Partial<SupplierDomainDTO>> = yup
  .object()
  .shape({
    name: yup.string().min(2).required(),
    description: yup.string()
  });

const initialValues: SupplierDomainDTO = {
  name: '',
  description: ''
};

export function SupplierDomainEditFormDialog() {
  const dispatch = useAppDispatch();
  const { type, reference } = useEditorLocation();
  const domain = useAppSelector((rs) =>
    supplierDomainsSelectors.selectById(rs, reference ?? '')
  );
  if (!reference || type !== 'edit' || !domain) {
    return <></>;
  }
  return (
    <SupplierDomainFormDialog
      save={(dto: SupplierDomainDTO) =>
        dispatch(supplierDomainsThunk.update({ id: domain.id, input: dto }))
          .then(unwrapResult)
          .catch(NoOp)
      }
      domain={domain}
    />
  );
}
export function SupplierDomainFormDialog({
  save,
  domain
}: {
  save?: (d: SupplierDomainDTO) => Promise<void | SupplierDomain>;
  domain?: SupplierDomain;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const createSupplierDomain =
    save ??
    ((dto: SupplierDomainDTO) =>
      dispatch(supplierDomainsThunk.create({ input: dto }))
        .then(unwrapResult)
        .catch(NoOp));

  return (
    <DialogForm
      identifier={domain?.id ?? 'supplier-domain'}
      maxWidth="md"
      label="New Domain"
      form={{
        initialValues: domain ?? initialValues,
        validationSchema: domainSchema
      }}
      api={{
        clearError: suppliersSlice.actions.clearError,
        errorSelector: suppliersSelectors.selectError,
        onSubmit: createSupplierDomain,
        stateSelector: suppliersSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <SupplierDomainBasicForm />
    </DialogForm>
  );
}
