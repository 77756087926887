import { ProjectRequest, RequestResource } from 'model';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { RootState } from '../../../redux/store';
import { projectRequestThunk } from './project-requests.thunk';
import { requestResourceThunk } from './request-resource.thunk';

const requestResourcesSlice = createEntitySlice<RequestResource>(
  'request-resources',
  [
    CustomThunk.override<ProjectRequest, RequestResource>(
      projectRequestThunk.get,
      {
        fulfilled: (adapter, state, { payload }) => {
          return adapter.upsertMany(
            state,
            (payload as ProjectRequest).resources
          );
        }
      }
    ) as any,
    ...Object.values(requestResourceThunk)
  ]
);

export const requestResourceSelectors = {
  ...createAdapterSelectors('request-resources', requestResourcesSlice.adapter),
  selectByRequest(state: RootState, requestId: string) {
    return this.selectAll(state).filter((a) => a.requestId === requestId);
  }
};

export default requestResourcesSlice;
