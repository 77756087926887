import { RequestSource } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { requestSourceThunk } from './request-sources.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';

const { list, delete: deleteThunk, ...thunks } = requestSourceThunk;

const requestSourceSlice = createEntitySlice<RequestSource>('request-source', [
  CustomThunk.override<RequestSource>(list, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as RequestSource[]);
    }
  }),
  CustomThunk.override<RequestSource>(
    deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  ...Object.values(thunks)
]);
export const requestSourceSelectors = createAdapterSelectors(
  'request-source',
  requestSourceSlice.adapter
);

export const requestSourceAPI: EntityAPI<RequestSource> = {
  selectors: requestSourceSelectors,
  thunks: requestSourceThunk
};
export default requestSourceSlice;
