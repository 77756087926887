import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function LspAssignmentTableHeader() {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 'bold' }}
          >
            {t('Project Name')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 'bold' }}
          >
            {t('Comment')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 'bold' }}
          >
            {t('Status')}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontWeight: 'bold' }}
          >
            {t('Weekly Hours')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
