import React from 'react';
import { ParseResultDescriptor } from 'model';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';

export function ParseResultTable({
  result,
  collapse,
  showEmpty,
  headerRows = 1
}: {
  collapse: boolean;
  result: ParseResultDescriptor<any, any>;
  showEmpty: boolean;
  headerRows?: number;
}) {
  return (
    <TableContainer>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(
            result.rows
              .map((r, i) => [i, r] as const)
              .filter(
                ([, r]) =>
                  !collapse ||
                  r.errors.length ||
                  r.data.some((c) => c.errors.length)
              )
              .map(([rowIndex]) => rowIndex + 1 + headerRows)
              .join(', ')
          );
        }}
      >
        Copy Indices
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell variant="head">No.</TableCell>
            {result.columns
              .filter((col) => showEmpty || !!col)
              .map((col) => (
                <TableCell
                  variant="head"
                  style={{
                    backgroundColor: col ? 'white' : 'rgba(200,200,200,0.5)'
                  }}
                >
                  {col ? col.name : '-'}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {result.rows
            .map((r, i) => [i, r] as const)
            .filter(
              ([, r]) =>
                !collapse ||
                r.errors.length ||
                r.data.some((c) => c.errors.length)
            )
            .map(([rowIndex, row]) => {
              return (
                <TableRow
                  style={{
                    backgroundColor: !row.excluded
                      ? 'white'
                      : 'rgba(180,180,180,0.65)',
                    border:
                      row.errors.length ||
                      row.data.some((cd) => cd.errors.length)
                        ? '2px dashed red'
                        : 'default'
                  }}
                >
                  <TableCell>{`${rowIndex + 1 + headerRows}.`}</TableCell>
                  {!row.data.length && (
                    <TableCell
                      colSpan={result.columns.length}
                      style={{ backgroundColor: 'rgba(250,0,0,0.4)' }}
                    >
                      Wrong column amount
                    </TableCell>
                  )}
                  {row.data
                    .map((dat, datId) => ({ ...dat, oId: datId }))
                    .filter((_, cI) => showEmpty || !!result.columns[cI])
                    .map((cd, cdi) => {
                      return (
                        <TableCell
                          size="small"
                          style={{
                            backgroundColor:
                              !showEmpty || result.columns[cdi]
                                ? 'transparent'
                                : 'rgba(200,200,200,0.5)',
                            border: cd.errors.length
                              ? '1px solid red'
                              : 'default',
                            ...(cd.errors.length ||
                            row.errors.some((re) => re.cells.includes(cd.oId))
                              ? { backgroundColor: 'rgba(230,0,0,0.3)' }
                              : {})
                          }}
                          variant="body"
                        >
                          {!showEmpty || result.columns[cdi] ? (
                            cd.text
                          ) : (
                            <Tooltip title={cd.text}>
                              <span>-</span>
                            </Tooltip>
                          )}
                          {/* {cd.text
                          .substr(
                            0,
                            cd.text.indexOf('\n') > 0
                              ? cd.text.indexOf('\n')
                              : 50
                          )
                          .replace(/ /g, '\u00a0')} */}
                        </TableCell>
                      );
                    })}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
