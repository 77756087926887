import React from 'react';
import { Project } from 'model';
import { Grid } from '@material-ui/core';
import { ErrorBoundary } from '../../error/ErrorBoundary';
import { ProjectBasicCard } from './basic/ProjectBasicCard';
import { RequestBasicCard } from './request/basic/RequestBasicCard';
import { ProjectNumberFormDialog } from './ProjectNumberFormDialog';
import { useProjectRequest } from '../hooks/project-request.hook';
import { RequestPositionsCard } from './request/positions/RequestPositionsCard';
import { RequestResourcesCard } from './request/resource/RequestResourceCard';
import { RequestStatusAlert } from './request/status/RequestStatusAlert';
import { RequestStatusDialog } from './request/status/RequestStatusDialog';

export function ProjectOverview({ project }: { project: Project }) {
  const { projectRequest } = useProjectRequest(project.requestId);
  return (
    <ErrorBoundary context="ProjectOverview">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* <ProjectNumberAlert project={project} /> */}
          <ProjectNumberFormDialog project={project} />
          {projectRequest && (
            <>
              <RequestStatusAlert request={projectRequest} />
              <RequestStatusDialog request={projectRequest} />
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          <ProjectBasicCard project={project} />
        </Grid>
        {projectRequest && (
          <>
            <Grid item xs={6}>
              <RequestBasicCard title="Request" request={projectRequest} />
            </Grid>{' '}
            <Grid item xs={6}>
              <RequestPositionsCard projectRequest={projectRequest} />
            </Grid>
            <Grid item xs={6}>
              <RequestResourcesCard request={projectRequest} />
            </Grid>
          </>
        )}
        {/*        <Grid item xs={6}>
          <OutgoingInvoicesCard
            invoices={project.outgoingInvoices}
            projectId={project.id}
          />
        </Grid> */}
      </Grid>
    </ErrorBoundary>
  );
}
