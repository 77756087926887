import { Client, ClientComment, ClientCommentDTO } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const createClientCommentThunk = createErrorHandlingThunk<
  Client,
  { clientId: string; dto: ClientCommentDTO }
>('client/comments/create', async (args) => {
  await apiClient.post<ClientComment>(
    `/client/${args.clientId}/comments`,
    args.dto
  );

  const clientResult = await apiClient.get<Client>(`/client/${args.clientId}`);
  return clientResult.data;
});
