"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatProjectNumber = exports.ProjectNumber = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const Project_1 = require("./Project");
// eslint-disable-next-line import/no-cycle
const Client_1 = require("../../client/entity/Client");
let ProjectNumber = class ProjectNumber extends JournaledEntity_1.JournaledEntity {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.OneToOne)(() => Project_1.Project, (p) => p.projectNumber),
    __metadata("design:type", Project_1.Project)
], ProjectNumber.prototype, "project", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Client_1.Client),
    __metadata("design:type", Client_1.Client)
], ProjectNumber.prototype, "client", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pn) => pn.client),
    __metadata("design:type", String)
], ProjectNumber.prototype, "clientId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], ProjectNumber.prototype, "clientNumber", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    (0, class_transformer_1.Type)(() => Number),
    __metadata("design:type", Number)
], ProjectNumber.prototype, "year", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    (0, class_transformer_1.Type)(() => Number),
    __metadata("design:type", Number)
], ProjectNumber.prototype, "sequentialNumber", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], ProjectNumber.prototype, "firstAcquisition", void 0);
ProjectNumber = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.Unique)(['client', 'year', 'sequentialNumber']),
    __metadata("design:paramtypes", [Object])
], ProjectNumber);
exports.ProjectNumber = ProjectNumber;
const projectNumberNumberFormat = new Intl.NumberFormat('en-GB', {
    maximumFractionDigits: 0,
    minimumIntegerDigits: 2
});
function formatProjectNumber(projectNumber) {
    return `${projectNumber.clientNumber}-${projectNumberNumberFormat.format(projectNumber.year)}-${projectNumberNumberFormat.format(projectNumber.sequentialNumber)}`;
}
exports.formatProjectNumber = formatProjectNumber;
