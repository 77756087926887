import { Permission } from 'model';
import React, { PropsWithChildren } from 'react';
import { useGrantedPermissions } from '../../features/auth/hooks/granted-permissions.hook';

export interface PermittedComponentProps {
  permission: Permission;
}

export function PermittedComponent({
  permission,
  children
}: PropsWithChildren<PermittedComponentProps>) {
  const userPermissions = useGrantedPermissions();
  return userPermissions.includes(permission.getFullQualifiedName()) ? (
    <>{children}</>
  ) : (
    <></>
  );
}
