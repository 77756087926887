import { EntityId } from '@reduxjs/toolkit';
import React from 'react';
import { BaseTableRow } from '../TableDataCell';
import { useTableConfigContext } from '../context/DataTableContext';

export function useTableSelectionControl<T extends BaseTableRow>() {
  const {
    tableSelection: { selectedIds, setSelectedIds }
  } = useTableConfigContext<T, any>();
  const handleSelectRow = (id: T['id']) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelected: EntityId[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelected = newSelected.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }
    setSelectedIds(newSelected);
  };

  const handleSelectAllRows = (
    event: React.ChangeEvent<HTMLInputElement>,
    filteredRows: T['id'][]
  ) => {
    if (event.target.checked) {
      setSelectedIds(filteredRows);
      return;
    }
    setSelectedIds([]);
  };

  return {
    selectedIds,
    handleSelectRow,
    handleSelectAllRows
  };
}
