import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { useHistory } from 'react-router-dom';
import { Backdrop } from '@material-ui/core';
import {
  executeMatcher,
  PermissionMatcher
} from '../features/auth/permissions.matchers';
import { useGrantedPermissions } from '../features/auth/hooks/granted-permissions.hook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    speedDialContainer: {
      transform: 'translateZ(0px)',
      flexGrow: 1
    },
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(4)
    }
  })
);

interface FeatureAction {
  label: string;
  icon: JSX.Element;
  link: string;
  permission?: PermissionMatcher;
}

export default function ActionsSpeedDial({
  actions
}: {
  actions: FeatureAction[];
}) {
  const grantedPermissions = useGrantedPermissions();
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleClose = (link?: string) => {
    setOpen(false);
    setTimeout(() => {
      if (link) {
        history.push(link);
      }
    }, 100);
  };

  return (
    <>
      <Backdrop
        style={{ zIndex: 3 }}
        open={open}
        onClick={() => handleClose()}
      />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClick={() => (open ? handleClose() : setOpen(true))}
        onMouseLeave={() => {}}
        open={open}
      >
        {actions.map((action) => {
          const hasPermission = executeMatcher(
            action.permission,
            grantedPermissions
          );

          return hasPermission ? (
            <SpeedDialAction
              style={{ textTransform: 'none' }}
              title={action.label}
              key={action.label}
              icon={action.icon}
              tooltipTitle={action.label}
              tooltipOpen={open}
              onClick={() => handleClose(action.link)}
            />
          ) : (
            <></>
          );
        })}
      </SpeedDial>
    </>
  );
}
