import { Project, ProjectStatusType, Supplier } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import {
  confirmProjectThunk,
  createOutgoingInvoiceThunk,
  deleteOutgoingInvoiceThunk,
  listClientProjectsThunk,
  listOpenProjectsThunk,
  outgoingInvoiceThunk,
  projectThunk,
  switchProjectTemplateStateThunk,
  updateOutgoingInvoiceThunk
} from './projects.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { RootState } from '../../../redux/store';
import { suppliersThunk } from '../../suppliers/redux/suppliers.thunk';

const { list, delete: deleteThunk, ...thunks } = projectThunk;

const projectSlice = createEntitySlice<Project>('projects', [
  CustomThunk.override<Project>(list, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as Project[]);
    }
  }),
  CustomThunk.override<Project>(
    deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  CustomThunk.override<Project>(confirmProjectThunk, {
    fulfilled: (adapter, state) => {
      return state;
    }
  }),
  CustomThunk.override<Project>(outgoingInvoiceThunk, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.upsertOne(state, payload as Project);
    }
  }),
  CustomThunk.override<Project>(switchProjectTemplateStateThunk, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.upsertOne(state, payload as Project);
    }
  }),
  CustomThunk.override<Project>(createOutgoingInvoiceThunk, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.upsertOne(state, payload as Project);
    }
  }),
  CustomThunk.override<Project>(updateOutgoingInvoiceThunk, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.upsertOne(state, payload as Project);
    }
  }),
  CustomThunk.override<Project>(deleteOutgoingInvoiceThunk, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.upsertOne(state, payload as Project);
    }
  }),
  CustomThunk.override<Project>(listClientProjectsThunk, {
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      return adapter.upsertMany(state, payload as Project[]);
    }
  }),
  CustomThunk.override<Project>(listOpenProjectsThunk, {
    pending: (_, state) => {
      state.state = 'pending';
      state.subQueries = { ...state.subQueries, open: 'pending' };
      return state;
    },
    rejected: (action, state) => {
      state.error = (action as any).error;
      state.subQueries = { ...state.subQueries, open: 'fresh' };
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.subQueries = { ...state.subQueries, open: 'fetched' };
      return adapter.upsertMany(state, payload as Project[]);
    }
  }),
  CustomThunk.override<Supplier, Project>(suppliersThunk.get, {
    fulfilled: (adapter, state, action) => {
      state.state = 'idle';
      const { projects } = action.payload as Supplier;
      return adapter.upsertMany(state, projects ?? []);
    }
  }),
  ...Object.values(thunks)
]);
export const projectSelectors = createAdapterSelectors(
  'projects',
  projectSlice.adapter
);

export const selectClientProjects = (clientId: string) => (state: RootState) =>
  projectSelectors.selectAll(state).filter((p) => p.clientId === clientId);

export const projectAPI: EntityAPI<Project> = {
  selectors: projectSelectors,
  thunks: projectThunk
};

export const listOpenSelector = (state: RootState) =>
  projectSelectors
    .selectAll(state)
    .filter((p) =>
      (
        [
          'not-yet-commisioned',
          'ready-to-plan',
          'in-progress'
        ] as ProjectStatusType[]
      ).includes(p.projectStatus)
    );
export default projectSlice;
