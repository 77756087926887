import { RequestComment, requestCommentPermission } from 'model';
import { TimelineDot } from '@material-ui/lab';
import {
  Badge,
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core';
import {
  Attachment,
  Comment,
  GetApp,
  LocalOffer,
  ThumbDown
} from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import * as Showdown from 'showdown';
import { useUser } from '../../../users/hooks/user.hook';
import { useOffersOfRequest } from '../../hooks/request-offers.hook';
import { createFileGrant } from '../../../../utils';
import { CommentTimelineItem } from './ProjectCommentTimeline';
import { CommentAuthorDisplay } from './CommentAuthorDisplay';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  }
}));

export interface ProjectCommentItemProps {
  comment: RequestComment;
}
const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
});

export function ProjectCommentItem({ comment }: ProjectCommentItemProps) {
  const classes = useStyles();
  const { user } = useUser(comment.authorId);
  const { attachedFiles } = comment;
  const { offers } = useOffersOfRequest(comment.requestId);
  const offerPos =
    comment.offerId && offers
      ? offers.length - offers.findIndex((o) => o.id === comment.offerId)
      : undefined;
  const isRejection = !!offers?.find((o) => o.rejectionId === comment.id);

  return (
    <CommentTimelineItem
      timelineSymbol={
        offerPos !== undefined ? (
          <Badge
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={
              <Chip
                icon={<LocalOffer fontSize="small" />}
                size="small"
                label={`Quote #${offerPos}`}
              />
            }
          >
            <TimelineDot>
              {isRejection ? <ThumbDown htmlColor="red" /> : <Comment />}
            </TimelineDot>
          </Badge>
        ) : (
          <TimelineDot>
            <Comment />
          </TimelineDot>
        )
      }
    >
      <Paper elevation={3} className={classes.paper}>
        <CommentAuthorDisplay
          author={{ type: 'user', user }}
          date={comment.createdAt}
          actions={
            isRejection
              ? [
                  {
                    icon: <ThumbDown style={{ fontSize: '1em' }} />,
                    text: `rejected Quote #${offerPos ?? ''}`
                  }
                ]
              : []
          }
        />
        <Box display="flex" paddingLeft="2.5em" justifyContent="flex-end">
          {comment.comment.trim() !== '' && (
            <Box display="flex" flexGrow={1}>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <div
                  style={{ textAlign: 'left' }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(comment.comment)
                  }}
                />
              </Box>
            </Box>
          )}
          <Box display="flex">
            {attachedFiles?.length > 0 && (
              <>
                <Box display="flex" height="100%" paddingX="1em">
                  <Divider orientation="vertical" flexItem />
                </Box>
                <Box>
                  <Box display="flex" alignItems="center">
                    <Attachment fontSize="small" />
                    <Typography variant="subtitle1">Attachments</Typography>
                  </Box>
                  <List dense style={{ minWidth: '25em' }}>
                    {attachedFiles.map((af) => (
                      <ListItem dense>
                        <ListItemText>
                          <Typography
                            title={af.name}
                            variant="caption"
                            style={{
                              maxWidth: '25em',
                              maxLines: 1,
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: 'block'
                            }}
                          >
                            {af.name}
                          </Typography>
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <IconButton
                            size="small"
                            component={RouterLink}
                            to={`/files/${af.id}/${
                              createFileGrant(
                                af.id,
                                requestCommentPermission,
                                comment as any
                              ).token
                            }`}
                            target="blank"
                          >
                            <GetApp />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Box display="flex" height="100%" paddingX="1em">
                  <Divider orientation="vertical" flexItem />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Paper>
    </CommentTimelineItem>
  );
}
