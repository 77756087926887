import React from 'react';
import { currencyMap, DefaultCurrency } from 'model';
import { ButtonBase, IconButton, InputBase } from '@material-ui/core';
import { Cancel, Edit } from '@material-ui/icons';
import { ProjectOfferView } from './ProjectOfferForm';
import { useTypedField } from '../../../../../transformer/DTOViewSchema';
import {
  MoneyFormat,
  MoneyNumberFormat
} from '../../../../clients/components/prices/FormikPriceInput';

export function PriceAmountInput({
  dtoField,
  calculatedPrice
}: {
  dtoField: string;
  calculatedPrice?: number;
}) {
  const [, meta, helpers] = useTypedField<number>(dtoField);
  const [showCalculated, setShowCalculated] = React.useState(
    calculatedPrice !== undefined && meta.value === null
  );
  const currentCurrency = useTypedField(ProjectOfferView.path.currency)[1]
    .value;
  if (showCalculated && meta.value === null) {
    return (
      <span style={{ textAlign: 'right', color: 'grey' }}>
        {MoneyNumberFormat.format(calculatedPrice as number)}
        {
          currencyMap[
            (currentCurrency ??
              DefaultCurrency.code) as keyof typeof currencyMap
          ].symbol
        }
        <IconButton size="small" onClick={() => setShowCalculated(false)}>
          <Edit style={{ fontSize: '0.8em' }} />
        </IconButton>
      </span>
    );
  }
  return (
    <InputBase
      value={`${meta.value ?? 0.0}`}
      onChange={(evt) => {
        const value = Number(evt.target.value);
        if (Number.isFinite(value)) {
          helpers.setValue(value);
        }
      }}
      inputComponent={MoneyFormat as any}
      margin="none"
      endAdornment={
        <>
          {
            currencyMap[
              (currentCurrency ??
                DefaultCurrency.code) as keyof typeof currencyMap
            ].symbol
          }
          {calculatedPrice !== undefined && (
            <ButtonBase
              onClick={() => {
                setShowCalculated(true);
                if (meta.value !== null) {
                  helpers.setValue(null as any);
                }
              }}
            >
              <Cancel style={{ fontSize: '1em' }} />
            </ButtonBase>
          )}
        </>
      }
      style={{
        fontSize: '1em',
        width: '5em'
      }}
      inputProps={{
        style: {
          padding: 0,
          textAlign: 'right',
          borderBottom: '1px dotted'
        }
      }}
    />
  );
}
