import React from 'react';
import {
  BaseTableRow,
  CellAccessor,
  ConvertInputMap,
  DataCellMap,
  TableRowDataKeys
} from '../TableDataCell';
import {
  TableColumnPositionMap,
  TableColumnVisibility,
  TableColumnVisibilityMap,
  useColumnPositionContext,
  useColumnVisibilityContext
} from '../layout/table-layout.context';
import { useTableSearchContext } from '../search/search.context';
import { useTableSortContext } from '../sort/sort.context';
import { useTablePaginationContext } from '../pagination/pagination.context';
import { useTableFilterContext } from '../filter/column-filter.context';
import { DataTableContextType } from './DataTableContext';
import { useTableSelectionContext } from '../selection/selection.context';
import {
  useTableDataContext,
  useTransformTableData
} from '../data/table-data.context';
import { useRawPaginateData } from '../pagination/pagination-data.hook';
import { useRawDisplayedTableColumns } from '../layout/displayed-columns.hook';
import { DefaultCellRenderer } from '../renderer/DefaultCellRenderer';
import { useAreaStorage } from '../../../hooks/page-area.context';

export interface TableConfigStorage {
  columnVisibilityMap?: TableColumnVisibilityMap<any, any>;
  columnPositionMap?: TableColumnPositionMap<any, any>;
}
export function useDataTable<T extends BaseTableRow, C extends DataCellMap<T>>(
  inputTableCells: ConvertInputMap<T, C>,
  data: T[] | Record<T['id'], T>,
  defaultSortKey: any,
  /* | { column: ColumnKey<T, C>; direction: SortDirection }
    | undefined */
  initialFilter: any,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tableName: string,
  initialLayout?: (keyof C)[]
  /* Partial<FilterStateMap<T, InputDataCellMap<T, C>>> | undefined */
) {
  const configStorage = useAreaStorage<TableConfigStorage>('table-config');

  const tableCells = React.useMemo(() => {
    return Object.fromEntries(
      Object.entries(inputTableCells).map(([cellId, inputConfig]) => {
        const cellAccessor = inputConfig.accessor;
        const accessor: CellAccessor<T, any> =
          typeof cellAccessor === 'string'
            ? (t: T) => t[cellAccessor as TableRowDataKeys<T>]
            : (cellAccessor as CellAccessor<T, any>);
        return [
          cellId,
          {
            ...inputConfig,
            cellId,
            renderer: inputConfig.renderer ?? DefaultCellRenderer<T, any>(),
            accessor
          }
        ];
      })
    ) as C;
  }, [inputTableCells]);
  const initialColumnVisibility = React.useMemo(() => {
    return initialLayout
      ? Object.fromEntries(
          Object.entries(tableCells).map(([cKey, cVal]) => [
            cKey,
            {
              cellId: cKey,
              visible: initialLayout
                ? initialLayout.includes(cKey)
                : !cVal.hiddenByDefault
            } as TableColumnVisibility<T, C>
          ])
        )
      : undefined;
  }, [initialLayout, tableCells]) as TableColumnVisibilityMap<T, C> | undefined;
  const initialColumnPositions = React.useMemo(() => {
    if (!initialLayout) {
      return undefined;
    }
    return Object.fromEntries(
      [
        ...initialLayout,
        ...Object.keys(tableCells).filter((k) => !initialLayout.includes(k))
      ].map((key, index) => [key, { cellId: key, position: index }])
    ) as TableColumnPositionMap<T, C>;
  }, [initialLayout, tableCells]) as TableColumnPositionMap<T, C> | undefined;
  const tableColumnVisibility = useColumnVisibilityContext<T, C>(
    tableCells,
    configStorage.readValue({}).columnVisibilityMap ?? initialColumnVisibility
  );
  const tableColumnPosition = useColumnPositionContext<T, C>(
    tableCells,
    configStorage.readValue({}).columnPositionMap ?? initialColumnPositions
  );
  const tableSearch = useTableSearchContext();
  const tableSort = useTableSortContext<T, C>(defaultSortKey);
  const tablePagination = useTablePaginationContext();
  const tableFilterState = useTableFilterContext<T, C>(
    tableCells,
    initialFilter
  );

  const tableData = useTableDataContext<T>(data);

  const displayedColumns = useRawDisplayedTableColumns<T, C>(
    tableCells,
    tableColumnVisibility.columnConfig
  );

  const activeData = useTransformTableData<T, C>(
    tableData,
    tableSearch.keys,
    tableFilterState.filterState,
    tableSort.sortState,
    displayedColumns,
    tableCells
  );

  const [displayedData, emptyRows] = useRawPaginateData(
    activeData,
    tablePagination.paginationState
  );
  const tableSelection = useTableSelectionContext<T>(activeData);
  const contextValue: DataTableContextType<T, C> = {
    tableConfig: {
      tableCells,
      displayedColumns,
      tableColumnVisibility,
      tableColumnPosition,
      tableSearch,
      tableSort,
      tablePagination,
      tableFilterState,
      tableSelection
    },
    tableData: {
      data: tableData,
      tableData: activeData,
      displayedData,
      emptyRows
    }
  };

  return { contextValue };
}
