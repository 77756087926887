import React from 'react';
import { Supplier } from 'model';
import { Grid } from '@material-ui/core';
import { SupplierInactivationAlert } from './inactivation/SupplierInactivationAlert';
import { SupplierInactivationFormDialog } from './inactivation/SupplierInactivationFormDialog';
import { SupplierBasicCard } from './basic/SupplierBasicCard';
import { SupplierSkillSetCard } from './skillSet/SupplierSkillSetCard';
import { SupplierServicesCard } from './services/SupplierServicesCard';
import { ErrorBoundary } from '../../error/ErrorBoundary';
import { SupplierCommentCard } from './comments/SupplierCommentCard';

export function SupplierProfile({ supplier }: { supplier: Supplier }) {
  return (
    <ErrorBoundary context="SupplierProfile">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <SupplierInactivationAlert supplier={supplier} />
          <SupplierInactivationFormDialog supplier={supplier} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <SupplierBasicCard supplier={supplier} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <SupplierCommentCard supplier={supplier} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <SupplierSkillSetCard supplier={supplier} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <SupplierServicesCard value={supplier} />
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
}
