import { useTaskMap } from '../components/tasks/order-task.context';
import { useProject } from './project.hook';

export function useProjectCompleted(projectId: string) {
  const taskMap = useTaskMap();
  const { project } = useProject(projectId);
  return (
    ['ready-to-plan', 'in-progress', 'delivered'].includes(
      project?.projectStatus as any
    ) &&
    Object.values(taskMap).length > 0 &&
    Object.values(taskMap).every(
      (t) => t.value.taskType === 'resource' || t.value.state === 'completed'
    )
  );
}
