import {
  Checkbox,
  makeStyles,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseTableRow, DataCell, DataCellMap } from './TableDataCell';
import {
  ActionMenuEntry,
  ActionsMenu,
  useActionMenuItemFactories
} from '../actions/ActionsMenu';
import { useTableDataContext } from './context/DataTableContext';
import { useTableRow } from './data/row-data.hook';
import { useArrangedTableColumns } from './layout/arranged-columns.hook';
import { GenericTableProps } from './types';
import { useVirtualScroller } from './VirtualScrollPanel';

export interface GenericTableCellProps<T extends BaseTableRow> {
  column: DataCell<T, any>;
  row: T;
  index: number;
  classMap: TableCellProps['classes'];
}

const useTableStyles = makeStyles({
  tableCellRoot: {
    padding: '2px',
    height: '30px',
    lineClamp: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '18em',
    fontSize: '1em',
    verticalAlign: 'top'
  }
});

function GenericTableCell<T extends BaseTableRow>({
  column,
  row,
  index,
  classMap
}: GenericTableCellProps<T>) {
  const rendered = React.useMemo(() => {
    return column.renderer.createElement(column.accessor(row), row, column);
  }, [column, row]);
  return (
    <TableCell
      style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)' }}
      classes={classMap}
      padding={index === 0 ? 'none' : undefined}
    >
      {rendered}
    </TableCell>
  );
}

export interface GenericTableRowProps<T extends BaseTableRow> {
  rowActions: ((value: T) => ActionMenuEntry | null)[];
  id: T['id'];
  columns: [T['id'], DataCell<T, any>][];
  uiMode: 'widget' | 'standalone';
}
function GenericTableRow<T extends BaseTableRow, C extends DataCellMap<T>>({
  rowActions,
  columns,
  id,
  uiMode
}: GenericTableRowProps<T>) {
  const { setSelected, selected, row } = useTableRow<T, C>(id);
  const styles = useTableStyles();
  const { t } = useTranslation();
  return React.useMemo(() => {
    const classMap = {
      root: uiMode === 'widget' ? styles.tableCellRoot : undefined
    };
    return (
      <TableRow hover tabIndex={-1} style={{ cursor: 'pointer' }}>
        {uiMode !== 'widget' && (
          <TableCell
            key="column-actions"
            align="center"
            padding="none"
            size="small"
            classes={classMap}
          >
            <ActionsMenu
              iconButtonProps={{ size: 'small' }}
              items={rowActions
                .map((action) => action(row))
                .map((action) =>
                  action?.label ? { ...action, label: t(action.label) } : action
                )}
            />
          </TableCell>
        )}
        {uiMode !== 'widget' && (
          <TableCell
            key="column-cell-select"
            size="small"
            padding="none"
            classes={classMap}
          >
            <Checkbox
              size="small"
              checked={selected}
              onClick={(e) => {
                e.stopPropagation();
                setSelected(!selected);
              }}
            />
          </TableCell>
        )}
        {Object.values(columns).map(([cid, cell], index) => (
          <GenericTableCell<T>
            index={index}
            column={cell}
            row={row}
            key={`${id}-${cid}-cell`}
            classMap={classMap}
          />
        ))}
      </TableRow>
    );
  }, [setSelected, selected, row, columns]);
}

export interface GenericTableBodyProps<T extends BaseTableRow> {
  rowActions: GenericTableProps<T, any>['rowActions'];
  uiMode: 'widget' | 'standalone';
}
export function GenericTableBody<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>({ rowActions, uiMode }: GenericTableBodyProps<T>) {
  const { displayedData } = useTableDataContext<T, C>();
  const arrangedTableColumns = useArrangedTableColumns<T, C>();
  const actionItemFactories = useActionMenuItemFactories<T>(rowActions);
  // const displayedTableColumns = useDisplayedTableColumns<T, C>();
  const columnRenderers = React.useMemo(
    () =>
      arrangedTableColumns.map(
        (c) => [c.cellId, c] as [T['id'], DataCell<T, any>]
      ),
    [arrangedTableColumns]
  );
  const { contentChanged } = useVirtualScroller();
  React.useEffect(() => {
    contentChanged();
  }, [columnRenderers, displayedData]);
  return (
    <TableBody>
      {displayedData.map((row) => (
        <GenericTableRow
          uiMode={uiMode}
          key={`data-rows-${row}`}
          columns={columnRenderers}
          rowActions={actionItemFactories}
          id={row}
        />
      ))}
    </TableBody>
  );
}
