import React from 'react';
import { includes } from 'model';
import { BaseTableRow } from '../TableDataCell';
import { ColumnFilter } from './column-filter.types';
import { LanguageIndexType } from '../../../features/languages/hooks/language-index-context.hook';
import { MultipleLanguageSelect } from '../../../features/languages/components/MultipleLanguageSelect';

export function MultipleLangColumnFilter<T extends BaseTableRow, V>(
  languageIndex: LanguageIndexType,
  langMapper: (value: V) => string[],
  overlayOnFocus?: boolean
): ColumnFilter<T, V, string[]> {
  return {
    overlayOnFocus,
    initialState: [],
    createFilter: (cell, state) => {
      return state.length < 1
        ? () => true
        : (value: V) => {
            const dataLangs = langMapper(value).map(
              (v) => languageIndex.descriptors[v].specification
            );
            return dataLangs
              ? state.some((elemState) =>
                  dataLangs.some((dL) =>
                    includes(
                      languageIndex.descriptors[elemState].specification,
                      dL,
                      { regionHierarchy: true }
                    )
                  )
                )
              : false;
          };
    },
    // eslint-disable-next-line react/prop-types
    component: ({ filterState, onFilterChange }) => (
      <MultipleLanguageSelect
        value={filterState ?? null}
        onChange={(val) => onFilterChange(val)}
      />
    ),
    isEffective: (cell, state) => state.length > 0
  };
}
