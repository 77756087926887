import React from 'react';
import { Button, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { projectThunk } from '../redux/projects.thunk';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { useProjectCompleted } from '../hooks/project-completed.hook';

export function TasksDoneAlert({ projectId }: { projectId: string }) {
  const dispatch = useAppDispatch();
  const completed = useProjectCompleted(projectId);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={completed}
    >
      <Alert
        variant="standard"
        severity="warning"
        action={
          <Button
            color="primary"
            size="small"
            onClick={() => {
              dispatch(
                projectThunk.update({
                  id: projectId,
                  input: { projectStatus: 'final' }
                })
              );
            }}
          >
            Finish
          </Button>
        }
      >
        <AlertTitle>All tasks done</AlertTitle>
        to finish project click finish
      </Alert>
    </Snackbar>
  );
}
