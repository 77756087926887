import React, { FC, FunctionComponent, ReactNode } from 'react';
import { BaseEntity } from 'model';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import {
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { BaseSchema } from 'yup';
import PlusIcon from '@material-ui/icons/Add';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { CardWithHeader } from '../cardWithHeader/CardWithHeader';
import { EditableListCardItemFormBaseProps } from './types';
import { EditableListCardForm } from './EditableListCardForm';
import { IconButtonLink } from '../../input/button/IconButtonLink';
import { DTOViewSchema } from '../../../transformer/DTOViewSchema';

const useStyles = makeStyles((theme) =>
  createStyles({
    divider: { marginTop: theme.spacing(1) },
    displayContainer: { position: 'relative' },
    actionsContainer: { position: 'absolute', right: 0, top: 0 }
  })
);

interface EditableListCardItem<E, U> {
  value: E;
  display: {
    component: FunctionComponent<{ value: E }>;
  };
  form: EditableListCardItemFormBaseProps<E, U>;
}

interface EditableListCardCreator<E extends BaseEntity, U> {
  icon?: ReactNode;
  title: string;
  type: 'creator';
  component: FC;
  dtoTransformer: (value: E) => U;
  initialValueFactory: () => E;
  validationSchema: BaseSchema<any>;
  viewFactory: (p: U) => DTOViewSchema<U, any>;
  onSubmit: (validValues: U) => Promise<E | void> | void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface EditableListCardCreatorCollection<E extends BaseEntity, U> {
  type: 'collection';
  icon?: ReactNode;
  creators: EditableListCardCreator<any, any>[];
}

interface EditableListCardProps<E extends BaseEntity, U> {
  title: ReactNode;
  identifierFactory: (entity: E) => string;
  items: EditableListCardItem<E, U>[];
  gridSize?: number;
  withoutCard?: boolean;
  creator?:
    | EditableListCardCreator<E, U>
    | EditableListCardCreatorCollection<E, U>;
}

function EditableListCardAddMenu<E extends BaseEntity, U>({
  collection,
  onAddClicked
}: {
  collection: EditableListCardCreatorCollection<E, U>;
  onAddClicked: (creator: EditableListCardCreator<E, U>) => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuState = (event?: any) => {
    setAnchorEl(event?.currentTarget ?? null);
  };
  return (
    <>
      <IconButton aria-haspopup="true" onClick={handleMenuState}>
        {collection.icon || <PlusIcon />}{' '}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuState()}
      >
        {collection.creators.map((item) => (
          <MenuItem
            onClick={() => {
              onAddClicked(item);
              handleMenuState();
            }}
          >
            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function EditableListCardAddButton<E extends BaseEntity, U>({
  creator,
  onAdd
}: {
  creator:
    | EditableListCardCreator<E, U>
    | EditableListCardCreatorCollection<E, U>;
  onAdd: (value: E, form: EditableListCardItemFormBaseProps<E, U>) => void;
}) {
  const onAddClicked = (c: EditableListCardCreator<E, U>) => {
    onAdd(c.initialValueFactory(), {
      component: c.component,
      dtoTransformer: c.dtoTransformer,
      validationSchema: c.validationSchema,
      viewFactory: c.viewFactory,
      onSubmit: c.onSubmit
    });
  };
  if (creator.type === 'creator') {
    return (
      <IconButton
        size="small"
        style={{ marginTop: 20 }}
        onClick={() => onAddClicked(creator)}
        title={creator.title}
      >
        {creator.icon ?? <PlusIcon />}
      </IconButton>
    );
  }
  return (
    <EditableListCardAddMenu collection={creator} onAddClicked={onAddClicked} />
  );
}

export function EditableListCard<E extends BaseEntity, U>(
  props: EditableListCardProps<E, U>
) {
  const classes = useStyles();

  const [addedEntry, setAddedEntry] = React.useState<{
    value: E;
    form: EditableListCardItemFormBaseProps<E, U>;
  }>();
  const history = useHistory();

  const { url } = useRouteMatch();

  const { type, reference } = useEditorLocation();

  const listItemIsEditable = (entity: E) =>
    props.identifierFactory(entity) === reference && type !== 'view';

  const content = (
    <Grid container>
      {props.items.map((listCardItem) => {
        const ListItemComponent = listCardItem.display.component;
        const isEditable = listItemIsEditable(listCardItem.value);

        return (
          <Grid item xs={(props.gridSize as any) ?? 12}>
            <div className={classes.displayContainer}>
              {isEditable ? (
                <EditableListCardForm
                  value={listCardItem.value}
                  onCancel={history.goBack}
                  {...listCardItem.form}
                />
              ) : (
                <div className={classes.displayContainer}>
                  <div className={classes.actionsContainer}>
                    <IconButtonLink
                      to={`${url}?type=edit#${props.identifierFactory(
                        listCardItem.value
                      )}`}
                    >
                      <Edit />
                    </IconButtonLink>
                  </div>

                  <ListItemComponent value={listCardItem.value} />
                </div>
              )}
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </div>
          </Grid>
        );
      })}
      {addedEntry && (
        <Grid item xs={(props.gridSize as any) ?? 12}>
          <EditableListCardForm
            value={addedEntry.value}
            onCancel={() => setAddedEntry(undefined)}
            {...addedEntry.form}
          />
        </Grid>
      )}
      {props.creator && !addedEntry && (
        <EditableListCardAddButton
          creator={props.creator}
          onAdd={(value, form) => setAddedEntry({ value, form })}
        />
      )}
    </Grid>
  );

  return props.withoutCard ? (
    content
  ) : (
    <CardWithHeader title={props.title} actions={[]}>
      {content}
    </CardWithHeader>
  );
}
