"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestStatusTransformer = exports.RequestStatusDTO = void 0;
const class_validator_1 = require("class-validator");
const RequestStatus_1 = require("../entity/RequestStatus");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
class RequestStatusDTO {
}
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsNotEmpty)(),
    (0, class_validator_1.Length)(2),
    __metadata("design:type", String)
], RequestStatusDTO.prototype, "name", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    (0, class_validator_1.IsOptional)(),
    __metadata("design:type", String)
], RequestStatusDTO.prototype, "description", void 0);
__decorate([
    (0, class_validator_1.IsString)(),
    __metadata("design:type", String)
], RequestStatusDTO.prototype, "color", void 0);
__decorate([
    (0, class_validator_1.IsIn)(['open', 'accepted', 'temporary-closed', 'permanently-closed']),
    __metadata("design:type", String)
], RequestStatusDTO.prototype, "type", void 0);
exports.RequestStatusDTO = RequestStatusDTO;
exports.requestStatusTransformer = (0, DTOTransformer_1.createDTOTransformer)(RequestStatus_1.RequestStatus, RequestStatusDTO, ['id', 'createdAt', 'updatedAt', 'defaultStatus'], {});
