import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, TextFieldProps } from '@material-ui/core';
import { BaseTableRow } from '../TableDataCell';
import { ColumnFilter } from './column-filter.types';

export function EnumColumnFilter<
  T extends BaseTableRow,
  E extends string,
  V extends E
>(
  options: { value: E; label?: string }[],
  textFieldProps?: TextFieldProps,
  overlayOnFocus?: boolean
): ColumnFilter<T, V, V[]> {
  return {
    overlayOnFocus,
    initialState: [],
    createFilter: (cell, state) => {
      return state.length < 1
        ? () => true
        : (value: V) => {
            return state.some((v) => v === value);
          };
    },
    // eslint-disable-next-line react/prop-types
    component: ({ filterState, onFilterChange }) => (
      <Autocomplete
        style={{ width: '100%' }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...textFieldProps}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password'
            }}
            /* eslint-disable-next-line react/jsx-no-duplicate-props */
            InputProps={params.InputProps}
          />
        )}
        options={options.map((o) => o.value)}
        value={filterState}
        multiple
        onChange={(_, value) => onFilterChange(value as V[])}
        getOptionLabel={(v) => options.find((o) => o.value === v)?.label ?? v}
      />
    ),
    isEffective: (cell, state) => state !== undefined
  };
}
