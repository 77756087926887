"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierImportMappings = void 0;
const provisionStep = 'Service Provision';
const reviewStep = 'Review';
exports.SupplierImportMappings = {
    testStates: {
        'test declined': { state: 'declined', resultState: null },
        'test failed': { state: 'finished', resultState: 'failed' },
        passed: { state: 'finished', resultState: 'passed' }
    },
    tests: {
        'Quick Resto': 'Quick Resto',
        'Ausschreibung Bundespresseamt': 'Ausschreibung Bundespresseamt',
        'Kontextor Copywriting': 'Kontextor Copywriting',
        'Kontextor Translation': 'Kontextor Translation',
        'not needed': 'Not needed',
        PAN: 'PAN',
        PayPal: 'PayPal',
        eBay: 'eBay'
    },
    categories: {
        agency: 'Agency',
        employee: 'Employee',
        freelancer: 'Freelancer'
    },
    documents: {
        cv: 'CV',
        nda: 'NDA',
        tax: 'Tax ID'
    },
    interpreting: 'Interpreting',
    lsps: {
        'site-content': 'eBay Site Content',
        cs: 'eBay CS'
    },
    qualifications: {
        certified: 'Geprüfter Übersetzer',
        degree: 'Hochschulabschluss (allgemein)',
        graduated: 'Hochschulabschluss (Diplom-Übersetzer)',
        sworn: 'Beeidigter Übersetzer'
    },
    roles: {
        translator: [
            { type: 'service', name: 'Translation' },
            { type: 'step', name: provisionStep }
        ],
        copywriter: [
            { type: 'service', name: 'Copywriting' },
            { type: 'step', name: provisionStep }
        ],
        reviewer: [{ type: 'step', name: reviewStep }],
        transcribers: [
            { type: 'service', name: 'Transcription' },
            { type: 'step', name: provisionStep }
        ],
        projektmanager: [
            { type: 'service', name: 'Project Management' },
            { type: 'step', name: provisionStep }
        ],
        dolmetscher: [
            { type: 'service', name: 'Interpreting' },
            { type: 'step', name: provisionStep }
        ],
        'localization engineer': [
            { type: 'service', name: 'Localization' },
            { type: 'step', name: provisionStep }
        ],
        'layout/design/dtp': [
            { type: 'service', name: 'dtp' },
            { type: 'step', name: provisionStep }
        ]
    }
};
