import React, { FC } from 'react';
import { OrderResource, OrderStepTask, OrderTask, TemplateScope } from 'model';
import { Divider, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { usePhaseChilds } from './order-task.context';
import { OrderResourceList } from './OrderResourceList';
import { PhaseStepTaskContainer } from './PhaseStepTaskContainer';

export function OrderTaskScopePhases({
  parentTask,
  projectId,
  scope,
  showResources,
  showPreparation,
  showPostDelivery,
  showVerticalDivider,
  provisionComponent: ProvisionComponent
}: {
  projectId: string;
  parentTask?: OrderTask;
  scope: TemplateScope;
  showResources?: boolean;
  showPreparation?: boolean;
  showVerticalDivider?: boolean;
  showPostDelivery?: boolean;
  provisionComponent: FC<{ projectId: string; tasks: OrderTask[] }>;
}) {
  const { preparation, provision, postDelivery, resources } = usePhaseChilds(
    parentTask ?? null
  );

  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <Grid container style={{ width: showResources ? '70%' : '100%' }}>
        {showPreparation && (
          <Grid item xs={12}>
            <PhaseStepTaskContainer
              scope={scope}
              tasks={preparation.map((v) => v.value as OrderStepTask)}
              phase="preparation"
              projectId={projectId}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ProvisionComponent
            projectId={projectId}
            tasks={provision.map((l) => l.value)}
          />
        </Grid>
        {showPostDelivery && (
          <Grid item xs={12}>
            <PhaseStepTaskContainer
              scope={scope}
              tasks={postDelivery.map((v) => v.value as OrderStepTask)}
              phase="post-delivery"
              projectId={projectId}
            />
          </Grid>
        )}
      </Grid>
      {showVerticalDivider && <Divider orientation="vertical" flexItem />}
      {showResources && (
        <div style={{ width: '30%', paddingLeft: 10 }}>
          <Typography
            variant="h5"
            color="textSecondary"
            style={{ marginBottom: 20 }}
          >
            {t('Resources')}
          </Typography>
          <OrderResourceList
            resources={resources.map((v) => v.value as OrderResource)}
            projectId={projectId}
          />
        </div>
      )}
    </div>
  );
}
