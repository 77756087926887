import { EntityId } from '@reduxjs/toolkit';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const deleteRoleThunk = createErrorHandlingThunk<EntityId, EntityId>(
  'role/delete',
  async (roleId) => {
    await apiClient.delete(`/roles/${roleId}`);
    return roleId;
  }
);
