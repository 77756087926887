import React, { ChangeEvent } from 'react';
import { CSVParser, ParseResultDescriptor } from 'model';
import {
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField
} from '@material-ui/core';
import {
  CheckBox,
  CloudUpload,
  UnfoldLess,
  UnfoldMore
} from '@material-ui/icons';
import { parse } from 'papaparse';
import { Alert } from '@material-ui/lab';
import { groupBy } from 'lodash';
import { ParseResultTable } from './ParseResultTable';

export interface CSVParserViewProps {
  parser: CSVParser<any, any>;
  pretransform?: (result: string[][]) => string[][];
  headerRows?: number;
  importAction?: (parserResult: ParseResultDescriptor<any, any>) => void;
}

export function CSVParserView(props: CSVParserViewProps) {
  const [parseResult, setParseResult] =
    React.useState<ParseResultDescriptor<any, any>>();

  const [collapsed, setCollapsed] = React.useState(true);
  const [showEmpty, setShowEmpty] = React.useState(false);
  const [withHeaders, setWithHeaders] = React.useState(props.headerRows ?? 1);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const transformer = props.pretransform ?? ((a: string[][]) => a);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      parse<string[]>(file, {
        complete: (results) => {
          const parseResultDescriptor = props.parser.parseInput(
            transformer(
              results.data.slice(
                withHeaders,
                results.data.length &&
                  results.data[results.data.length - 1].length === 1
                  ? -1
                  : 0
              )
            )
          );
          setParseResult(parseResultDescriptor);
        }
      });
    }
  };

  const handleClick = () => hiddenFileInput.current?.click();
  return (
    <Paper>
      <Grid container xs={12}>
        <Grid item xs={12}>
          {props.importAction !== undefined && parseResult !== undefined && (
            <IconButton
              onClick={() =>
                props.importAction !== undefined &&
                props.importAction(parseResult)
              }
            >
              <CheckBox />
            </IconButton>
          )}
          <IconButton onClick={handleClick}>
            <CloudUpload />
          </IconButton>
          <Checkbox
            checked={withHeaders > 0}
            onChange={() =>
              setWithHeaders(withHeaders === 0 ? props.headerRows ?? 1 : 0)
            }
          />
          Remove Headers
          {withHeaders > 0 && (
            <TextField
              type="number"
              value={withHeaders}
              onChange={(e) => {
                const amount = parseInt(e.target.value, 10);
                if (!Number.isNaN(amount)) {
                  setWithHeaders(amount);
                }
              }}
              size="small"
              inputProps={{ step: 1, min: 1 }}
            />
          )}
          <Checkbox
            checked={showEmpty}
            onChange={() => setShowEmpty(!showEmpty)}
          />
          Show ignored columns
        </Grid>
        <Grid item xs={12}>
          {parseResult && (
            <>
              {parseResult.rows.some(
                (r) => r.errors.length || r.data.some((c) => c.errors.length)
              ) && (
                <Alert severity="error">
                  {
                    parseResult.rows.filter(
                      (r) =>
                        r.errors.length || r.data.some((c) => c.errors.length)
                    ).length
                  }{' '}
                  Fehler
                  <IconButton onClick={() => setCollapsed(!collapsed)}>
                    {collapsed ? <UnfoldMore /> : <UnfoldLess />}
                  </IconButton>
                  <List>
                    {Object.entries(
                      groupBy(
                        parseResult.rows
                          .flatMap((r) => [
                            ...r.errors,
                            ...r.data.flatMap((d) => d.errors)
                          ])
                          .map((e) => ({
                            location: e.cells
                              .sort()
                              .map(
                                (ci) => parseResult.columns[ci]?.name ?? `${ci}`
                              )
                              .join(','),
                            error: e.error
                          })),
                        (ed) => ed.location
                      )
                    ).flatMap(([loc, ed]) => {
                      return Object.entries(groupBy(ed, (s) => s.error)).map(
                        ([ec, a]) => (
                          <ListItem>
                            <ListItemText>
                              {loc}: {ec}
                            </ListItemText>
                            <ListItemSecondaryAction>
                              {`${a.length}`}
                            </ListItemSecondaryAction>
                          </ListItem>
                        )
                      );
                    })}
                  </List>
                </Alert>
              )}

              <ParseResultTable
                headerRows={props.headerRows}
                result={parseResult}
                collapse={collapsed}
                showEmpty={showEmpty}
              />
            </>
          )}
        </Grid>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </Grid>
    </Paper>
  );
}
