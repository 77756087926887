import React from 'react';
import {
  Supplier,
  supplierAttachmentPermission,
  SupplierCommentAttachment
} from 'model';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { SupplierPricesTable } from './SupplierPricesTable';
import { createFileGrant, NoOp } from '../../../../utils';
import { usePricesOfSupplier } from '../../hooks/supplier-prices.hook';
import { SupplierMinPriceFormDialog } from './SupplierMinPriceFormDialog';
import { useAttachmentsOfSupplier } from '../../hooks/supplier-attachments.hook';
import { CommentDisplay } from '../../../../components/comment/CommentDisplay';
import { IconButtonLink } from '../../../../components/input/button/IconButtonLink';
import { PriceDisplay } from '../../../clients/components/prices/PriceDisplay';

export function SupplierPrices({ supplier }: { supplier: Supplier }) {
  const { t } = useTranslation();
  const { prices } = usePricesOfSupplier(supplier.id);
  const { attachments } = useAttachmentsOfSupplier(supplier.id);

  const priceComment = supplier.priceCommentId
    ? (attachments.find((a) => a.id === supplier.priceCommentId) as
        | SupplierCommentAttachment
        | undefined)
    : null;
  const { url } = useRouteMatch();

  if (priceComment === undefined) {
    return <CircularProgress />;
  }
  return (
    <div>
      <SupplierMinPriceFormDialog
        supplierId={supplier.id}
        defaultPrice={supplier.minimumPrice?.price}
        description={supplier.minimumPrice?.description}
      />
      {priceComment && (
        <Paper style={{ border: '1px solid red', padding: 10 }}>
          <CommentDisplay
            comment={priceComment}
            createFileGrant={(af) =>
              createFileGrant(af.id, supplierAttachmentPermission, priceComment)
                .token
            }
          />
        </Paper>
      )}
      <Typography variant="h5">
        Minimum Price:{' '}
        <PriceDisplay
          amount={supplier.minimumPrice?.price.default}
          currencyCode={supplier.minimumPrice?.price.currency}
          defaultCurrencyCode={supplier.defaultCurrency}
          typographyProps={{ variant: 'inherit' }}
        />
        <IconButtonLink title={t('edit')} to={`${url}?type=edit#min-price`}>
          <Edit />
        </IconButtonLink>
      </Typography>

      <SupplierPricesTable
        supplier={supplier}
        prices={prices}
        onRefresh={NoOp}
        apiState="idle"
      />
    </div>
  );
}
