import { Project, ProjectRequest, Supplier } from 'model';
import React from 'react';
import { Box } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { Person } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { supplierApi } from '../../../suppliers/redux/suppliers.slice';
import { ColumnFilter } from '../../../../components/table/filter/column-filter.types';
import { EntityReferenceSelect } from '../../../../components/select/EntityReferenceSelect';
import { useAppSelector } from '../../../../redux/redux.hooks';
import { selectUserAsNullable } from '../../../auth/redux/auth.slice';
import { useSuppliers } from '../../../suppliers/hooks/suppliers.hook';
import { AbsenceDBManager } from '../../../suppliers/hooks/absence-cover.hook';
import { useAreaStorage } from '../../../../hooks/page-area.context';
import { ProjectManagersFilterDefaultState } from './ProjectsTable';

function FilterComponent({
  filterState,
  onFilterChange: onRawFilterChange
}: {
  filterState: { assigned: boolean; ids: string[] };
  onFilterChange: (v: { assigned: boolean; ids: string[] }) => void;
}) {
  const { t } = useTranslation();
  const user = useAppSelector(selectUserAsNullable);
  const configStorage = useAreaStorage<ProjectManagersFilterDefaultState>(
    'project-managers-filter'
  );
  const onFilterChange = (v: { assigned: boolean; ids: string[] }) => {
    configStorage.writeVal({ onlyAssigned: v.assigned });
    onRawFilterChange(v);
  };
  const { suppliers } = useSuppliers();
  const currentSupplier = suppliers.find((s) => s.userId === user?.id);
  React.useEffect(() => {
    if (filterState.assigned) {
      return AbsenceDBManager.subscribe(() =>
        onFilterChange({
          assigned: true,
          ids: [
            currentSupplier?.id as string,
            ...Object.values(AbsenceDBManager.list()).map(
              (av) => av.coveredSupplierId
            )
          ]
        })
      );
    }
    return undefined;
  }, [currentSupplier, filterState.assigned]);

  return (
    <Box display="flex">
      <ToggleButton
        selected={filterState.assigned}
        size="small"
        style={{ padding: 0, margin: 0 }}
        title={
          filterState.assigned
            ? t('click to show projects from all projectmanagers')
            : t('click to show only my projects')
        }
        onClick={() =>
          filterState.assigned
            ? onFilterChange({ assigned: false, ids: [] })
            : onFilterChange({
                assigned: true,
                ids: [
                  currentSupplier?.id as string,
                  ...Object.values(AbsenceDBManager.list()).map(
                    (av) => av.coveredSupplierId
                  )
                ]
              })
        }
      >
        <Person fontSize="small" />
      </ToggleButton>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <EntityReferenceSelect<Supplier, Supplier['id'][]>
        multiple
        disabled={filterState.assigned}
        filter={(s) => s.userId !== null}
        currentValue={
          filterState.ids.filter((i) => i !== undefined) as Supplier['id'][]
        }
        entityApi={supplierApi}
        optionLabel={(v) => v.email}
        selectValue={(v) =>
          onFilterChange({ assigned: filterState.assigned, ids: v })
        }
      />
    </Box>
  );
}
export function ProjectManagersFilter<
  P extends Project | ProjectRequest
>(): ColumnFilter<P, Supplier[], { assigned: boolean; ids: string[] }> {
  return {
    initialState: { assigned: false, ids: [] },
    createFilter: (cell, state) => {
      return state.ids.length < 1
        ? () => true
        : (value: Supplier[]) => {
            const dataVals = value.map((i) => i.id);
            return dataVals.length > 0
              ? state.ids.some((sI) => dataVals.some((i) => i === sI))
              : false;
          };
    },
    // eslint-disable-next-line react/prop-types
    component: FilterComponent,
    isEffective: (cell, state) => state !== undefined
  };
}
