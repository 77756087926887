import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Client,
  PatchClientDTO,
  patchClientTransformer,
  permissions
} from 'model';
import * as yup from 'yup';
import { unwrapResult } from '@reduxjs/toolkit';
import { NewEditableCard } from '../../../../components/card/editableCard/NewEditableCard';
import { ClientBasicDisplay } from './ClientBasicDisplay';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { patchClientThunk } from '../../redux/actions/patch-client.thunk';
import { NoOp } from '../../../../utils';
import { buildDTOView } from '../../../../transformer/DTOViewSchema';
import { ClientBasicForm } from './ClientBasicForm';

const clientBasicView = buildDTOView<Partial<PatchClientDTO>>()
  .include(
    'name',
    'taxId',
    'email',
    'phone',
    'description',
    'invoiceAddressId',
    'headQuarterAddressId'
  )
  .withSchema(
    yup
      .object()
      .shape({
        name: yup
          .string()
          .required()
          .min(3, 'At least 3 characters')
          .max(255, 'Max. 255 characters'),
        taxId: yup
          .string()
          .nullable()
          .max(255, 'Max. 255 characters')
          .defined(),
        ceo: yup
          .string()
          .nullable()
          .max(255, 'Max. 255 characters')
          .default(undefined),
        email: yup
          .string()
          .email()
          .nullable()
          .max(255, 'Max. 255 characters')
          .defined(),
        phone: yup
          .string()
          .nullable()
          .max(255, 'Max. 255 characters')
          .default(undefined),
        description: yup
          .string()
          .max(255, 'Max. 255 characters')
          .nullable()
          .defined(),
        invoiceAddressId: yup.string().nullable().default(undefined),
        headQuarterAddressId: yup.string().nullable().default(undefined)
      })
      .defined()
  );

export function ClientBasicCard({ client }: { client: Client }) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const editClientBasic = (dto: Partial<PatchClientDTO>) =>
    dispatch(patchClientThunk({ id: client.id, dto }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <NewEditableCard
      identifier="basic"
      title={t('Basic')}
      value={client}
      display={{
        component: ClientBasicDisplay
      }}
      form={{
        validationSchema: clientBasicView.validationSchema,
        component: ClientBasicForm,
        onSubmit: editClientBasic,
        viewFactory: clientBasicView.viewFactory,
        dtoTransformer: patchClientTransformer,
        permissions: permissions.clients.client.edit
      }}
    />
  );
}
