import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { supplierDomainsSelectors } from '../redux/supplier-domains.slice';
import { supplierDomainsThunk } from '../redux/supplier-domains.thunk';

export function useSupplierDomains() {
  const dispatch = useAppDispatch();

  const supplierDomains = useAppSelector(supplierDomainsSelectors.selectAll);

  const apiState = useAppSelector(supplierDomainsSelectors.selectState);

  const listFetched = useAppSelector(
    supplierDomainsSelectors.selectListFetched
  );

  const listSupplierDomains = () => dispatch(supplierDomainsThunk.list());

  const createDomain = (name: string, description?: string) =>
    dispatch(
      supplierDomainsThunk.create({
        input: { name, description: description ?? '' }
      })
    );

  React.useEffect(() => {
    if (apiState === 'fresh' || listFetched === false) {
      listSupplierDomains();
    }
  }, [apiState, listFetched]);

  return { supplierDomains, apiState, listSupplierDomains, createDomain };
}
