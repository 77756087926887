import React from 'react';
import {
  currencyMap,
  DefaultCurrency,
  permissions,
  Supplier,
  SupplierPrice
} from 'model';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Edit as EditIcon } from '@material-ui/icons';
import { entityColumnBuilder } from '../../../../components/table/columns.builder';
import { ApiState } from '../../../auth/redux/types';
import {
  GenericTable,
  useCreateCells
} from '../../../../components/table/GenericTable';
import { usePriceUnit } from '../../../prices/hooks/price-unit.hook';
import { useServiceStep } from '../../../services/hooks/service-step.hook';
import { useServiceClass } from '../../../services/hooks/service-class.hook';
import { useLanguageIndex } from '../../../languages/hooks/language-index.hook';
import { SupplierPriceFormDialog } from './SupplierPriceFormDialog';

function ServiceStepCellRenderer(props: { id: string | null }) {
  const { serviceStep } = useServiceStep(props.id);
  return <Typography variant="inherit">{serviceStep?.name}</Typography>;
}

function ServiceClassCellRenderer(props: { id: string | null }) {
  const { serviceClass } = useServiceClass(props.id);
  return <Typography variant="inherit">{serviceClass?.name}</Typography>;
}

function PriceUnitCellRenderer(props: { id: string }) {
  const { priceUnit } = usePriceUnit(props.id);
  return <Typography variant="inherit">{priceUnit?.name}</Typography>;
}

function LanguageCellRenderer(props: { id: string | null }) {
  const index = useLanguageIndex();
  const language = props.id ? index.descriptors[props.id] : null;
  return (
    <Typography variant="inherit">{language?.language.name ?? '-'}</Typography>
  );
}

function buildTableColumns(supplier: Supplier) {
  return entityColumnBuilder<SupplierPrice>()
    .addColumn('supplier_price_service_step', 'serviceStepId', 'Service Step', {
      renderer: {
        createElement: (serviceStepId) => (
          <ServiceStepCellRenderer id={serviceStepId} />
        ),
        createText: (serviceStepId: string | null) => [serviceStepId ?? '']
      }
    })
    .addColumn(
      'supplier_price_service_class',
      'serviceClassId',
      'Service Class',
      {
        renderer: {
          createElement: (serviceClassId) => (
            <ServiceClassCellRenderer id={serviceClassId} />
          ),
          createText: (serviceClassId: string | null) => [serviceClassId ?? '']
        }
      }
    )
    .addColumn(
      'supplier_price_default',
      (p) => `${p.price.default}`,
      'Defaultprice',
      {
        renderer: {
          createElement: (_, sp) => (
            <Typography variant="inherit">
              {sp.price.default
                ? `${sp.price.default} ${
                    currencyMap[
                      (sp.price.currency ??
                        supplier.defaultCurrency ??
                        DefaultCurrency.code) as keyof typeof currencyMap
                    ].symbol
                  }`
                : '-'}
            </Typography>
          ),
          createText: (_, sp) => [`${sp.price.default}`]
        }
      }
    )
    .addColumn(
      'supplier_price_express',
      (p) => `${p.price.express}`,
      'Express price',
      {
        renderer: {
          createElement: (_, sp) => (
            <Typography variant="inherit">
              {sp.price.express
                ? `${sp.price.express} ${
                    currencyMap[
                      (sp.price.currency ??
                        supplier.defaultCurrency ??
                        DefaultCurrency.code) as keyof typeof currencyMap
                    ].symbol
                  }`
                : '-'}
            </Typography>
          ),
          createText: (_, sp) => [`${sp.price.express}`]
        }
      }
    )
    .addColumn('supplier_price_unit', 'priceUnitId', 'Unit', {
      renderer: {
        createElement: (priceUnitId) => (
          <PriceUnitCellRenderer id={priceUnitId} />
        ),
        createText: (priceUnitId: string) => [priceUnitId]
      }
    })

    .addColumn(
      'supplier_price_source_language',
      'sourceLanguageId',
      'Source Language',
      {
        renderer: {
          createElement: (sourceLanguageId) => (
            <LanguageCellRenderer id={sourceLanguageId} />
          ),
          createText: (sourceLanguageId: string | null) => [
            sourceLanguageId ?? ''
          ]
        }
      }
    )
    .addColumn(
      'supplier_price_target_language',
      'targetLanguageId',
      'Target Language',
      {
        renderer: {
          createElement: (targetLanguageId) => (
            <LanguageCellRenderer id={targetLanguageId} />
          ),
          createText: (targetLanguageId: string | null) => [
            targetLanguageId ?? ''
          ]
        }
      }
    )
    .addDefaultColumn(
      'supplier_price_custom_costraint',
      'customConstraint',
      'Custom Constraint'
    )
    .addDefaultColumn(
      'supplier_price_description',
      'description',
      'Description'
    ).columns;
}

interface SupplierPricesTableProps {
  prices: SupplierPrice[];
  onRefresh: () => void;
  apiState: ApiState;
  supplier: Supplier;
}

export function SupplierPricesTable({
  prices,
  onRefresh,
  apiState,
  supplier
}: SupplierPricesTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(supplier), []);

  return (
    <>
      <SupplierPriceFormDialog supplierId={supplier.id} />
      <GenericTable
        label={t('Prices')}
        refresh={onRefresh}
        primaryButton={{
          label: 'New Price',
          onClick: () => history.push(`${url}?type=create#price`)
        }}
        data={prices}
        isLoading={apiState === 'pending'}
        tableCells={tableCells}
        rowActions={[
          {
            permissions: permissions.suppliers.supplier.prices.define,
            entry: (v) => ({
              label: 'Edit',
              icon: EditIcon,
              link: `${url}?type=edit#${v.id}`
            })
          }
        ]}
        toolbarActions={[]}
      />
    </>
  );
}
