import {
  Client,
  permissions,
  Project,
  ProjectRequest,
  RequestSource,
  RequestStatus,
  Supplier
} from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { Box, Chip, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import {
  GenericTable,
  useCreateCells
} from '../../../../../components/table/GenericTable';
import { ApiState } from '../../../../auth/redux/types';
import { entityColumnBuilder } from '../../../../../components/table/columns.builder';
// import { projectRequestThunk } from '../../../redux/project-requests.thunk';
import { OfferStatusDisplay } from '../offers/OfferStatusDisplay';
import {
  defineEntityMap,
  EntityMapType,
  useEntityMap
} from '../../../../../redux/entity-map';
import { clientAPI } from '../../../../clients/redux/clients.slice';
import { supplierApi } from '../../../../suppliers/redux/suppliers.slice';
import { projectVariationAPI } from '../../../../services/redux/project-variations.slice';
import { projectTagAPI } from '../../../redux/project-tag.slice';
import { requestStatusAPI } from '../../../redux/request-status.slice';
import { requestSourceAPI } from '../../../redux/request-sources.slice';
import {
  DataAccessorError,
  entityAccessor,
  lazyConf
} from '../../../../../components/table/renderer/TableDataRenderer';
import { projectAPI } from '../../../redux/projects.slice';
import { MultipleEntityColumnFilter } from '../../../../../components/table/filter/MultipleEntityColumnFilter';
import { EntityColumnFilter } from '../../../../../components/table/filter/EntityColumnFilter';
import { DateCellRenderer } from '../../../../../components/table/renderer/DateCellRenderer';
import { formatDate } from '../../../../../localized-formats';
import {
  determineDeadlineStatus,
  ProjectDeadlineColors,
  ProjectManagersFilterDefaultState
} from '../../table/ProjectsTable';
import { ProjectManagersFilter } from '../../table/ProjectManagersFilter';
import { AbsenceDBManager } from '../../../../suppliers/hooks/absence-cover.hook';
import { useAppSelector } from '../../../../../redux/redux.hooks';
import { selectUserAsNullable } from '../../../../auth/redux/auth.slice';
import { useSuppliers } from '../../../../suppliers/hooks/suppliers.hook';
import { ProjectLink } from '../../basic/ProjectLink';
import { ClientLink } from '../../../../clients/components/basic/ClientLink';
import { SupplierLink } from '../../../../suppliers/components/SupplierLink';
import {
  useAreaStorage,
  usePageArea
} from '../../../../../hooks/page-area.context';
import { DuplicateRequestTableAction } from './DuplicateRequestTableAction';

function ProjectManagerCellRenderer({ supplier }: { supplier: Supplier }) {
  return <SupplierLink value={supplier} />;
}
const requestEntityMap = defineEntityMap({
  client: clientAPI,
  supplier: supplierApi,
  projectVariation: projectVariationAPI,
  projectTag: projectTagAPI,
  requestSource: requestSourceAPI,
  requestStatus: requestStatusAPI,
  project: projectAPI
});

function buildTableColumns(entityMap: EntityMapType<typeof requestEntityMap>) {
  return (
    entityColumnBuilder<ProjectRequest>()
      /* .addDefaultColumn(
      'project_request_details',
      (r) => r.project.projectName,
      'Request',
      {
        createElement: (projectName, s) => {
          return (
            <StyledLink to={`/projects/project-requests/${s.id}`}>
              Request - {projectName}
            </StyledLink>
          );
        },
        createText: (projectName) => [projectName]
      }
    ) */
      .addDefaultColumn(
        'project_request_project',
        (r) => r.project.projectName,
        'Project',
        {
          createElement: (pN, s) => {
            return <ProjectLink value={s.project as Project} />;
          },
          createText: (pN) => [pN]
        }
      )
      .addBooleanColumn(
        'project_request_first_aquisition',
        (r) => r.project.projectNumber?.firstAcquisition ?? null,
        'Acquisition'
      )
      .addColumn(
        'project_request_status',
        entityAccessor(entityMap, ['requestStatus'], (data, entityMap1) => {
          return entityMap1.requestStatus[data.requestStatusId];
        }),
        'Status',
        {
          renderer: lazyConf({
            createElement: (st) => (
              <Chip
                size="small"
                variant="outlined"
                style={{ backgroundColor: st.color }}
                label={st.name}
                title={st.description}
              />
            ),
            createText: (st) => [st.name],
            filter: EntityColumnFilter<
              ProjectRequest,
              RequestStatus,
              RequestStatus
            >(
              requestStatusAPI,
              (s) => s.name,
              (v) => [v.id]
            )
          })
        }
      )
      .addColumn(
        'project_client',
        entityAccessor(
          entityMap,
          ['client'],
          (data, entityMap1) => entityMap1.client[data.project.clientId]
        ),
        'Client',
        {
          renderer: lazyConf({
            createElement: (client) => {
              return <ClientLink value={client} />;
            },
            createText: (client) => [client.name],
            filter: MultipleEntityColumnFilter<ProjectRequest, Client, Client>(
              clientAPI,
              (v) => v.name,
              (p) => [p.id]
            ),
            comparator: ([a], [b]) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              // names must be equal
              return 0;
            }
          })
        }
      )
      .addColumn(
        'project_request_source',
        entityAccessor(
          entityMap,
          ['requestSource'],
          (data, entityMap1) => entityMap1.requestSource[data.requestSourceId]
        ),
        'Source',
        {
          renderer: lazyConf({
            createElement: (rS) => {
              return <span>{rS.name}</span>;
            },
            createText: (rS) => [rS.name],
            filter: MultipleEntityColumnFilter<
              ProjectRequest,
              RequestSource,
              RequestSource
            >(
              requestSourceAPI,
              (data) => data.name,
              (v) => [v.id]
            )
          })
        }
      )
      .addColumn('project_request_offer_status', 'currentOffer', 'Offer', {
        renderer: {
          createElement: (_, s) => {
            return (
              <>
                {s.currentOffer && (
                  <OfferStatusDisplay offer={s.currentOffer} />
                )}
              </>
            );
          },
          createText: (_, data) => [data.currentOffer?.orderId || '']
        }
      })
      .addBooleanColumn('project_request_express', 'expressProject', 'Express')
      .addDateColumn(
        'project_request_date_of_request',
        'date',
        'dateOfRequest',
        'Date of Request'
      )
      .addColumn(
        'project_deadline',
        entityAccessor(
          entityMap,
          ['project'],
          (data, entityMap1) => entityMap1.project[data.project.id]?.deadline
        ),
        'Deadline',
        {
          renderer: lazyConf({
            ...DateCellRenderer('date-time', true),
            createElement: (value, data) => {
              const color =
                value !== null
                  ? ProjectDeadlineColors[
                      determineDeadlineStatus(new Date(value), data.project)
                    ]
                  : undefined;
              return (
                <Typography variant="subtitle1" style={{ color }}>
                  {value === null ? '' : formatDate(value, 'date-time')}
                </Typography>
              );
            }
          })
        }
      )
      .addColumn(
        'project_request_manager',
        entityAccessor(
          entityMap,
          ['supplier', 'project'],
          (data, entityMap1) =>
            entityMap1.supplier[
              entityMap1.project[data.project.id]?.projectManagerId ?? ''
            ]
        ),
        'Projectmanager',
        {
          renderer: lazyConf({
            createElement: (data) => {
              return <ProjectManagerCellRenderer supplier={data} />;
            },
            createText: (m) => [m.email],
            filter: MultipleEntityColumnFilter<
              ProjectRequest,
              Supplier,
              Supplier
            >(
              supplierApi,
              (s) => `${s.name.first} ${s.name.last}`,
              (v) => [v.id],
              (s) => s.userId !== null
            )
          })
        }
      )
      .addColumn(
        'project_all_managers',
        entityAccessor(
          entityMap,
          ['project', 'supplier'],
          (data, entityMap1) => {
            const pms = [
              entityMap1.supplier[
                entityMap1.project[data.project.id]?.projectManagerId ?? ''
              ],
              ...(entityMap1.project[
                data.project.id
              ]?.supportingProjectManagerIds?.map(
                (id) => entityMap1.supplier[id]
              ) ?? [])
            ];
            if (pms.some((v) => !v)) {
              throw new DataAccessorError();
            }
            return pms;
          }
        ),
        'Projectmanagers',
        {
          renderer: lazyConf({
            createElement: (data) => {
              const mainPm = data[0];
              if (!mainPm) {
                return <Skeleton />;
              }
              return (
                <Box position="relative">
                  <SupplierLink value={mainPm} />
                  {data.length > 1 && (
                    <Tooltip
                      title={data
                        .slice(1)
                        .map((spm) => `${spm.name.first} ${spm.name.last}`)
                        .join(', ')}
                    >
                      <span
                        style={{
                          marginLeft: '5px',
                          fontSize: '0.8em',
                          backgroundColor: 'rgba(230,230,230,0.7)',
                          borderRadius: '50%',
                          paddingLeft: '0.5em',
                          paddingRight: '0.5em',
                          paddingTop: '0.2em',
                          paddingBottom: '0.2em'
                        }}
                      >{`+${data.length - 1}`}</span>
                    </Tooltip>
                  )}
                </Box>
              );
            },
            createText: (data) => data.map((p) => p.email),
            filter: ProjectManagersFilter()
          })
        }
      )
      .addColumn(
        'project_offer_order_date',
        entityAccessor(
          entityMap,
          ['project'],
          (data, entityMap1) =>
            entityMap1.project[data.project.id]?.order?.orderDate ?? null
        ),
        'Order Date',
        {
          renderer: lazyConf(DateCellRenderer('date'))
        }
      ).columns
  );
}

interface ProjectRequestTableProps {
  initialColumnLayout?: string[];
  projectRequests: ProjectRequest[];
  onRefresh: () => void;
  apiState: ApiState;
  uiMode?: 'standalone' | 'widget';
  defaultPmFilter?: string[] | null;
}

export function ProjectRequestTable({
  projectRequests,
  onRefresh,
  apiState,
  uiMode = 'standalone',
  initialColumnLayout,
  defaultPmFilter
}: ProjectRequestTableProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const entityMap = useEntityMap(requestEntityMap);
  const area = usePageArea();
  const configStorage = useAreaStorage<ProjectManagersFilterDefaultState>(
    'project-managers-filter',
    area.specifier.deriveSpecifier('table', t('Requests'))
  );
  const { onlyAssigned } = configStorage.readValue({ onlyAssigned: true });
  const tableCells = useCreateCells(buildTableColumns(entityMap), [entityMap]);
  const user = useAppSelector(selectUserAsNullable);
  const { suppliers, listFetched } = useSuppliers();
  const currentSupplier = suppliers.find((s) => s.userId === user?.id);
  if (user?.id && !listFetched && !currentSupplier) {
    return <Skeleton />;
  }
  return (
    <GenericTable
      initialColumnLayout={initialColumnLayout as any}
      uiMode={uiMode}
      label={t('Requests')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Request',
        onClick: () => history.push(`${url}?type=create#project-request`),
        permission: permissions.project.projectRequest.create
      }}
      data={projectRequests}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      initialSort={{
        column: 'project_request_date_of_request',
        direction: 'asc'
      }}
      initialFilter={
        defaultPmFilter !== undefined &&
        (defaultPmFilter !== null || onlyAssigned)
          ? {
              project_all_managers: {
                active: true,
                filterState:
                  defaultPmFilter === null
                    ? {
                        assigned: true,
                        ids: [
                          currentSupplier?.id as string,
                          ...Object.values(AbsenceDBManager.list()).map(
                            (av) => av.coveredSupplierId
                          )
                        ]
                      }
                    : { assigned: false, ids: defaultPmFilter }
              }
            }
          : undefined
      }
      rowActions={[
        {
          permissions: permissions.project.projectRequest.edit,
          entry: (step) => ({
            icon: <HiOutlineDocumentDuplicate />,
            label: 'Duplicate',
            link: `${url}?type=duplicate#${step.projectId}`
          })
        }
      ]}
      toolbarActions={
        uiMode === 'standalone'
          ? [
              {
                showWhen: 'always',
                icon: <DuplicateRequestTableAction />,
                label: 'Duplicate'
              }
            ]
          : []
      }
    />
  );
}
