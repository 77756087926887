"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.patchClientPriceTransformer = exports.ClientPriceDTO = void 0;
const PriceConfigurationDTO_1 = require("../../prices/dto/PriceConfigurationDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const ClientPrice_1 = require("../entity/ClientPrice");
class ClientPriceDTO extends PriceConfigurationDTO_1.PriceConfigurationDTO {
}
exports.ClientPriceDTO = ClientPriceDTO;
exports.patchClientPriceTransformer = (0, DTOTransformer_1.createDTOTransformer)(ClientPrice_1.ClientPrice, ClientPriceDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'clientId',
    'client',
    'priceUnit',
    'serviceClass',
    'sourceLanguage',
    'targetLanguage'
], {
    price: [
        'price',
        (p) => ({
            price: p.default,
            currency: p.currency,
            expressPrice: p.express
        })
    ]
});
