import React from 'react';
import { BaseTableRow, DataCellMap } from '../TableDataCell';
import { useTableConfigContext } from '../context/DataTableContext';
import { ColumnKey } from '../types';

export function useColumnPosition<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(columnKey: ColumnKey<T, C>) {
  const { tableColumnPosition } = useTableConfigContext<T, C>();
  const currentColumnPosition =
    tableColumnPosition.columnConfig[columnKey].position;
  const moveColumn = React.useCallback(
    (newPosition: number) => {
      tableColumnPosition.setColumnConfig(
        Object.fromEntries(
          Object.entries(tableColumnPosition.columnConfig).map(
            ([curColumnKey, { position }]) => {
              let updatedPosition = position;
              if (columnKey === curColumnKey) {
                updatedPosition = newPosition;
              } else {
                if (currentColumnPosition > newPosition) {
                  if (
                    position >= newPosition &&
                    position < currentColumnPosition
                  ) {
                    updatedPosition += 1;
                  }
                }
                if (currentColumnPosition < newPosition) {
                  if (
                    position > currentColumnPosition &&
                    position <= newPosition
                  ) {
                    updatedPosition -= 1;
                  }
                }
              }
              return [
                curColumnKey,
                { cellId: curColumnKey, position: updatedPosition }
              ];
            }
          )
        ) as typeof tableColumnPosition.columnConfig
      );
    },
    [tableColumnPosition, currentColumnPosition]
  );

  return React.useMemo(
    () => ({
      moveColumn,
      position: currentColumnPosition
    }),
    [moveColumn, currentColumnPosition]
  );
}

export function useColumnPositionControl<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(columnKey: ColumnKey<T, C>) {
  const { tableColumnPosition } = useTableConfigContext<T, C>();
  const currentColumnPosition =
    tableColumnPosition.columnConfig[columnKey].position;
  const moveOtherColumn = React.useCallback(
    (otherColumn: string, place: 'before' | 'after') => {
      const otherColumnConf = tableColumnPosition.columnConfig[otherColumn];
      const sign = Math.sign(currentColumnPosition - otherColumnConf.position);
      const matcher = (pos: number) =>
        (place === 'before'
          ? pos * sign < currentColumnPosition * sign
          : pos * sign > currentColumnPosition * sign) &&
        pos * sign > otherColumnConf.position * sign;
      tableColumnPosition.setColumnConfig(
        Object.fromEntries(
          Object.entries(tableColumnPosition.columnConfig).map(
            ([curColumnKey, { position }]) => {
              let updatedPosition = position;
              if (otherColumn === curColumnKey) {
                updatedPosition =
                  (place === 'before'
                    ? currentColumnPosition
                    : currentColumnPosition - 1) + (sign === -1 ? 1 : 0);
              } else if (matcher(position)) {
                updatedPosition -= sign;
              }
              return [
                curColumnKey,
                { cellId: curColumnKey, position: updatedPosition }
              ];
            }
          )
        ) as typeof tableColumnPosition.columnConfig
      );
    },
    [tableColumnPosition, currentColumnPosition]
  );

  return React.useMemo(
    () => ({
      moveOtherColumn,
      position: currentColumnPosition
    }),
    [moveOtherColumn, currentColumnPosition]
  );
}
