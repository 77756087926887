import React from 'react';
import {
  OrderResource,
  OrderResourceDTO,
  orderResourceTransformer
} from 'model';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { EditableListCard } from '../../../../components/card/editableListCard/EditableListCard';
import { OrderResourceDisplay } from './OrderResourceDisplay';
import { NoOp } from '../../../../utils';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { projectOrderTasksThunk } from '../../redux/project-order-tasks.thunk';
import { OrderResourceForm, OrderResourceView } from './OrderResourceForm';
import { useProject } from '../../hooks/project.hook';

export function OrderResourceList({
  resources,
  projectId
}: {
  resources: OrderResource[];
  projectId: string;
}) {
  const { t } = useTranslation();
  const { project } = useProject(projectId);
  const dispatch = useAppDispatch();
  const updateOrderResource = (input: OrderResourceDTO, id: string) =>
    dispatch(
      projectOrderTasksThunk.update({
        projectId: project?.id ?? '',
        id,
        input: { task: input }
      })
    )
      .then(unwrapResult)
      .catch(NoOp);

  const createOrderResource = (input: OrderResourceDTO) =>
    dispatch(
      projectOrderTasksThunk.create({
        projectId: project?.id ?? '',
        input: { task: input }
      })
    )
      .then((a) => unwrapResult(a) as OrderResource)
      .catch(NoOp);

  return (
    <EditableListCard<OrderResource, OrderResourceDTO>
      withoutCard
      title={t('Resources')}
      identifierFactory={(e) => `resources-${e.id}`}
      items={
        resources.map((rp) => ({
          value: rp,
          display: {
            // eslint-disable-next-line react/no-unused-prop-types
            component: ({ value }: { value: OrderResource }) => (
              <OrderResourceDisplay value={value} />
            )
          },
          form: {
            onSubmit: (v: OrderResourceDTO) => updateOrderResource(v, rp.id),
            component: () => <OrderResourceForm />,
            validationSchema: OrderResourceView.validationSchema,
            viewFactory: OrderResourceView.viewFactory,
            dtoTransformer: orderResourceTransformer((s) => btoa(s))
          }
        })) as any
      }
      creator={{
        type: 'creator',
        title: t('Add Resource'),
        initialValueFactory: () =>
          Object.assign(new OrderResource(), {
            dependencies: [],
            taskType: 'resource',
            state: 'pending'
          }),
        component: () => <OrderResourceForm />,
        validationSchema: OrderResourceView.validationSchema,
        onSubmit: createOrderResource,
        viewFactory: OrderResourceView.viewFactory,
        dtoTransformer: orderResourceTransformer((s) => btoa(s))
      }}
    />
  );
}
