import React from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import { ServiceClassPatchDTO } from 'model';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import PlusIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  buildDTOView,
  useTypedField
} from '../../../transformer/DTOViewSchema';
import { FormikEntitySelect } from '../../../components/select/FormikEntitySelect';
import { qualificationApi } from '../../suppliers/redux/supplier-qualifications.slice';
import { serviceStepAPI } from '../redux/service-steps.slice';
import { useCardMode } from '../../../components/input/card/context';

export const ClassQualificationsView = buildDTOView<ServiceClassPatchDTO>()
  .include('requiredQualifications')
  .withSchema(
    yup
      .object({
        requiredQualifications: yup
          .array()
          .required()
          .of(
            yup
              .object({
                qualificationId: yup.string().uuid().required(),
                serviceStepId: yup
                  .string()
                  .uuid()
                  .nullable()
                  .default(null)
                  .defined()
              })
              .required()
          )
      })
      .defined()
  );

export function ServiceClassQualificationsForm() {
  const { t } = useTranslation();

  const [, meta, helpers] = useTypedField(
    ClassQualificationsView.path.requiredQualifications
  );
  const cardMode = useCardMode();
  const editable = cardMode && cardMode !== 'view';

  return meta.value !== undefined ? (
    <Grid container spacing={1}>
      {editable && (
        <Grid item xs={12} container spacing={1} justify="flex-end">
          {' '}
          <Button
            size="small"
            disabled={!editable}
            variant="outlined"
            color="primary"
            style={{ marginBottom: 20 }}
            onClick={() => {
              helpers.setValue([
                ...meta.value,
                {
                  qualificationId: null as unknown as any,
                  serviceStepId: null
                }
              ]);
            }}
            startIcon={<PlusIcon />}
          >
            {t('New Qualification Constraint')}
          </Button>
        </Grid>
      )}
      {meta.value.map((q, i) => (
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={5}>
            <FormikEntitySelect
              textFieldProps={{ label: t('Qualification') }}
              entityApi={qualificationApi}
              optionLabel={(p) => p.name}
              name={
                ClassQualificationsView.path.requiredQualifications.at(i)
                  .qualificationId
              }
            />
          </Grid>
          <Grid item xs={5}>
            <FormikEntitySelect
              textFieldProps={{ label: t('Related service step') }}
              entityApi={serviceStepAPI}
              optionLabel={(p) => p.name}
              name={
                ClassQualificationsView.path.requiredQualifications.at(i)
                  .serviceStepId
              }
            />
          </Grid>
          <Grid item xs={2} justify="flex-end">
            {editable && (
              <IconButton
                size="small"
                onClick={() => {
                  helpers.setValue(
                    meta.value.filter((_, index) => i !== index)
                  );
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  ) : (
    <></>
  );
}
