import {
  Client,
  ClientMinimumPriceDTO,
  ClientPrice,
  ClientPriceDTO
} from 'model';
import { createFeatureThunks } from '../../../../redux/featureThunks';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const clientPricesThunk = createFeatureThunks<
  ClientPrice,
  ClientPriceDTO,
  ClientPriceDTO,
  { clientId: string }
>({
  urlFactory: ({ clientId, id } = { clientId: '', id: undefined }) =>
    `/client/${clientId}/prices${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'client-prices'
});

export const defineMinimumClientPrice = createErrorHandlingThunk<
  Client,
  { id: string; input: ClientMinimumPriceDTO }
>('client/minimum-price', async ({ id, input }) => {
  const result = await apiClient.put<Client>(
    `/client/${id}/minimum-price`,
    input
  );
  return result.data;
});
