import React from 'react';
import { Redirect, Route, Switch as RouterSwitch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { DashboardScreen } from '../dashboard/Dashboard.screen';
import { SettingsScreen } from '../settings/Settings.screen';
import { UsersFeature } from '../users/users.feature';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { ClientsFeature } from '../clients/clients.feature';
import { MainFrame } from '../../components/navigation/mainFrame/MainFrame';
import {
  LanguageIndexContext,
  useLanguageIndexProvider
} from '../languages/hooks/language-index-context.hook';
import { LanguagesFeature } from '../languages/languages.feature';
import { SuppliersFeature } from '../suppliers/suppliers.feature';
import { SupplierDomainsScreen } from '../suppliers/screens/SupplierDomainScreen';
import { ProjectsFeature } from '../projects/projects.feature';
import { ServicesFeature } from '../services/services.feature';
import { FileDownloadScreen } from '../../components/file/FileDownloadScreen';
import { SeafileImageCacheProvider } from '../../components/file/SeafileImageCacheContext';
import { ResourceTranslationScreen } from '../../components/file/ResourceTranslation.screen';
import { VirtualScrollPanel } from '../../components/table/VirtualScrollPanel';
import { RootPageAreaSpecifier } from '../../hooks/page-area.context';
import {
  FeaturePageArea,
  PageAreaBoundary
} from '../../hooks/page-area.components';
import { StyledAppBar } from '../../components/navigation/mainFrame/StyledAppBar';
import { SimpleBreadcrumbs } from '../../components/navigation/mainFrame/BreadCrumbs';
import { ActiveUserDisplay } from '../users/components/ActiveUserDisplay';
import { AbsenceCoverContainer } from '../suppliers/components/AbsenceCoverDisplay';

/* export function useInitialData() {
  const dispatch = useAppDispatch();
  const [requested, setRequested] = React.useState(false);
  const [fetched, setFetched] = React.useState(false);
  React.useEffect(() => {
    if (!requested) {
      dispatch(fetchInitialDataThunk())
        .then((res) => {
          setFetched(true);
          return res;
        })
        .catch((err) => {
          console.log('failed to fetch initial data', err);
        });
      setRequested(true);
    }
  }, []);
  return fetched;
} */

export const AuthenticatedAppSpecifier = RootPageAreaSpecifier.deriveSpecifier(
  'app',
  'authenticated'
);
const RouteMap = [
  {
    path: '/files/:fileId/:token',
    component: <FileDownloadScreen />,
    exact: true
  },
  { path: '/', component: <Redirect to="/dashboard" />, exact: true },
  { path: '/dashboard', component: <DashboardScreen /> },
  { path: '/users', component: <UsersFeature /> },
  { path: '/clients', component: <ClientsFeature /> },
  { path: '/suppliers', component: <SuppliersFeature /> },
  { path: '/services', component: <ServicesFeature /> },
  { path: '/supplier-domains', component: <SupplierDomainsScreen /> },
  { path: '/projects', component: <ProjectsFeature /> },
  { path: '/settings', component: <SettingsScreen /> },
  { path: '/languages', component: <LanguagesFeature /> },
  { path: '/translations', component: <ResourceTranslationScreen /> }
];
function InitializedAuthenticatedApp() {
  const languageIndexProvider = useLanguageIndexProvider();
  return (
    <PageAreaBoundary specifier={AuthenticatedAppSpecifier}>
      <ErrorBoundary context="authenticatedApp">
        <VirtualScrollPanel>
          <LanguageIndexContext.Provider value={languageIndexProvider}>
            <SeafileImageCacheProvider>
              <MainFrame>
                <StyledAppBar>
                  <SimpleBreadcrumbs />
                  <Box display="flex">
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                        alignSelf: 'center',
                        marginRight: '1em'
                      }}
                    >
                      Version: {process.env.REACT_APP_VERSION}
                    </span>
                    <AbsenceCoverContainer />
                    <ActiveUserDisplay link="/settings" />
                  </Box>
                </StyledAppBar>
                <RouterSwitch>
                  {RouteMap.map((route) => (
                    <Route exact={!!route.exact} path={route.path}>
                      <FeaturePageArea specifier={route.path.substr(1)}>
                        {route.component}
                      </FeaturePageArea>
                    </Route>
                  ))}
                  {/* <Route exact path="/files/:fileId/:token">
                    <FileDownloadScreen />
                  </Route>
                  <Route exact path="/">
                    <Redirect to="/dashboard" />
                  </Route>
                  <Route path="/dashboard">
                    <DashboardScreen />
                  </Route>
                  <Route path="/users">
                    <UsersFeature />
                  </Route>
                  <Route path="/clients">
                    <ClientsFeature />
                  </Route>
                  <Route path="/suppliers">
                    <SuppliersFeature />
                  </Route>
                  <Route path="/services">
                    <ServicesFeature />
                  </Route>
                  <Route path="/supplier-domains">
                    <SupplierDomainsScreen />
                  </Route>
                  <Route path="/projects">
                    <ProjectsFeature />
                  </Route>
                  <Route path="/settings">
                    <SettingsScreen />
                  </Route>
                  <Route path="/languages">
                    <LanguagesFeature />
                  </Route>
                  <Route path="/translations">
                    <ResourceTranslationScreen />
                  </Route> */}
                  <Route path="*">Not Found</Route>
                </RouterSwitch>
              </MainFrame>
            </SeafileImageCacheProvider>
          </LanguageIndexContext.Provider>
        </VirtualScrollPanel>
      </ErrorBoundary>
    </PageAreaBoundary>
  );
}

export function AuthenticatedApp() {
  /* const initialDataFetched = useInitialData();
  return initialDataFetched ? <InitializedAuthenticatedApp /> : <Skeleton />; */
  return <InitializedAuthenticatedApp />;
}
