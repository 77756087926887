"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDocumentTypeStateDTO = void 0;
const DocumentTypeStateDTO_1 = require("./DocumentTypeStateDTO");
const omit_type_helper_1 = require("../../extern-libs/mapped-types/omit-type.helper");
class CreateDocumentTypeStateDTO extends (0, omit_type_helper_1.OmitType)(DocumentTypeStateDTO_1.DocumentTypeStateDTO, [
    'id'
]) {
}
exports.CreateDocumentTypeStateDTO = CreateDocumentTypeStateDTO;
