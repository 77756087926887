import React from 'react';
import { Avatar, AvatarProps } from '@material-ui/core';
import { UploadAccessPermission, UploadFile } from 'model';
import { Person as UserIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useSeafileFileUrl } from '../file/seafile-url.hook';

interface UserAvatarProps extends AvatarProps {
  avatar?: UploadFile | null;
  grant: any;
  permission: UploadAccessPermission<any, any, any>;
}

export function UserAvatar(props: UserAvatarProps) {
  const imageUrl = useSeafileFileUrl({ ...props, file: props.avatar });

  if (!props.avatar) {
    return (
      <Avatar {...props}>
        <UserIcon />
      </Avatar>
    );
  }

  return imageUrl ? (
    <Avatar {...props} src={imageUrl} />
  ) : (
    <Skeleton variant="circle">
      <Avatar />
    </Skeleton>
  );
}
