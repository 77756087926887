import { useTranslation } from 'react-i18next';
import { ServiceClassDTO } from 'model';
import { EntityId, unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { NoOp } from '../../../utils';
import { DialogForm } from '../../../components/input/form/DialogForm';
import serviceClassesSlice, {
  serviceClassesSelectors
} from '../redux/service-classes.slice';
import { serviceClassesThunk } from '../redux/service-classes.thunk';
import {
  ServiceClassBasicForm,
  serviceClassBasicView
} from './basic/ServiceClassBasicForm';

const initialValues: Omit<ServiceClassDTO, 'requiredQualifications'> = {
  name: '',
  description: '',
  type: 'only-target',
  defaultPriceUnitId: '',
  usablePriceUnitIds: []
};
export function ServiceClassFormDialog() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const createServiceClass = (
    dto: Omit<ServiceClassDTO, 'requiredQualifications'>
  ) =>
    dispatch(
      serviceClassesThunk.create({
        input: { ...dto, requiredQualifications: [] }
      })
    )
      .then(unwrapResult)
      .catch(NoOp);

  const history = useHistory();

  const navigateToDetails = (id?: EntityId) =>
    history.replace(`/services/service-class/${id}`);

  return (
    <DialogForm
      identifier="service-class"
      maxWidth="md"
      label={t('New Service')}
      form={{
        initialValues,
        validationSchema: serviceClassBasicView.validationSchema
      }}
      api={{
        clearError: serviceClassesSlice.actions.clearError,
        errorSelector: serviceClassesSelectors.selectError,
        onSubmit: createServiceClass,
        stateSelector: serviceClassesSelectors.selectState
      }}
      actions={[
        { label: t('Save and Exit'), doSubmit: true },
        {
          label: t('Save and Details'),
          doSubmit: true,
          onClick: navigateToDetails
        }
      ]}
    >
      <ServiceClassBasicForm />
    </DialogForm>
  );
}
