import React from 'react';
import { IETFTag, includes } from 'model';
import { BaseTableRow } from '../TableDataCell';
import { ColumnFilter } from './column-filter.types';
import { LanguageSelect } from '../../../features/languages/components/LanguageSelect';
import { LanguageIndexType } from '../../../features/languages/hooks/language-index-context.hook';

export function LanguageColumnFilter<T extends BaseTableRow>(
  languageIndex: LanguageIndexType,
  overlayOnFocus?: boolean
): ColumnFilter<T, IETFTag, string | undefined> {
  return {
    overlayOnFocus,
    initialState: undefined,
    createFilter: (cell, state) => {
      return state === undefined
        ? () => true
        : (value: IETFTag) => {
            const dataLang = value;
            return dataLang
              ? includes(
                  languageIndex.descriptors[state].specification,
                  dataLang,
                  { regionHierarchy: true }
                )
              : false;
          };
    },
    // eslint-disable-next-line react/prop-types
    component: ({ filterState, onFilterChange }) => (
      <LanguageSelect
        value={filterState ?? null}
        onChange={(val) => onFilterChange(val ?? undefined)}
      />
    ),
    isEffective: (cell, state) => !!state && state.trim() !== ''
  };
}
