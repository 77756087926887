import React from 'react';
import { User, UserRoleTree } from 'model';
import { Chip } from '@material-ui/core';
import { useRoles } from '../../hooks/roles.hook';
import PopoverPreview from '../../../../components/preview/PopoverPreview';
import { PermissionTree } from '../PermissionTree';

export function RolesChips({ user }: { user: User }) {
  const { roles } = useRoles();
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {user.roles.map((role, index) => {
        const roleTree = roles.find((r) => r.id === role.id);
        return (
          <PopoverPreview
            key={role.id}
            ready={roleTree !== undefined}
            content={
              <PermissionTree
                defaultExpanded
                roleTree={roleTree as UserRoleTree}
              />
            }
          >
            <Chip
              style={{ marginRight: index < user.roles.length ? 2.5 : 0 }}
              key={role.name}
              label={role.name}
            />
          </PopoverPreview>
        );
      })}
    </div>
  );
}
