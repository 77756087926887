import React from 'react';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import { ProjectOffer } from 'model';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../localized-formats';
import { OfferStatusDisplay } from './OfferStatusDisplay';

const useStyles = makeStyles((theme) =>
  createStyles({
    offerDisplayHeader: {
      display: 'flex',
      alignItems: 'center'
    },
    toggleExpandButton: {
      marginLeft: theme.spacing(1)
    }
  })
);

export function OfferDisplayHeader({
  onToggleExpanded,
  expanded,
  isCurrent,
  offer
}: {
  isCurrent?: boolean;
  expanded: boolean;
  onToggleExpanded: () => void;
  offer?: ProjectOffer;
}) {
  const classes = useStyles();

  const { t } = useTranslation();
  if (!offer) {
    return <></>;
  }
  return (
    <div className={classes.offerDisplayHeader}>
      <Typography style={{ marginRight: 10 }}>
        {isCurrent
          ? t('Current Offer')
          : `${t('Offer')} ${formatDate(offer.offerDate, 'date')}`}
      </Typography>
      <OfferStatusDisplay offer={offer} />
      <IconButton
        style={{ marginLeft: 10 }}
        // className={classes.toggleExpandButton}
        size="small"
        onClick={onToggleExpanded}
      >
        {expanded ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        )}
      </IconButton>
    </div>
  );
}
