import React from 'react';
import { ProjectRequest } from 'model';
import { Button, Grid } from '@material-ui/core';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOffersOfRequest } from '../../../hooks/request-offers.hook';
import { OfferDisplay } from './OfferDisplay';
import { useProject } from '../../../hooks/project.hook';
import { AcceptOfferFormDialog } from './AcceptOfferFormDialog';
import { RejectOfferFormDialog } from './RejectOfferFormDialog';
import { useEditorLocation } from '../../../../../hooks/editor-location.hook';
import { CreateOfferDocumentForm } from './offerDocument/CreateOfferDocumentForm';
import { useClient } from '../../../../clients/hooks/client.hook';

interface ProjectRequestOffersProps {
  request: ProjectRequest;
}

export function NewProjectRequestOffers({
  request
}: ProjectRequestOffersProps) {
  const { project } = useProject(request.project.id);
  const { offers } = useOffersOfRequest(request.id);
  const { url } = useRouteMatch();

  const { t } = useTranslation();
  const { client } = useClient(request.project.clientId);

  const offersDescWithoutCurrent = offers
    .filter((o) => o.id !== request.currentOffer?.id)
    .reverse();

  const currentOffer = offers.find((o) => o.id === request.currentOffer?.id);

  const { type } = useEditorLocation();
  if (!client) {
    return <Skeleton />;
  }
  return (
    <>
      <AcceptOfferFormDialog />
      <RejectOfferFormDialog />
      <Grid container spacing={2}>
        {project &&
          type !== 'create' &&
          (currentOffer?.rejectionId || !currentOffer) && (
            <Grid item xs={12}>
              <Alert
                severity="warning"
                action={
                  <Button
                    component={RouterLink}
                    color="primary"
                    to={`${url}?type=create#new-offer`}
                  >
                    {t('New Offer')}
                  </Button>
                }
              >
                <AlertTitle>{t('No Current Offer')}</AlertTitle>
                {t('Click "New Offer" to create a new Offer')}
              </Alert>
            </Grid>
          )}
        {client && (
          <CreateOfferDocumentForm client={client} requestId={request.id} />
        )}
        <Grid item xs={12}>
          {project && request.currentOffer && (
            <OfferDisplay
              key={
                !currentOffer?.rejectionId
                  ? 'currentOffer'
                  : 'rejectedCurrentOffer'
              }
              client={client}
              offerNr={offers.length}
              projectOffer={currentOffer}
              isCurrent={!request.currentOffer?.rejectionId}
            />
          )}
        </Grid>
        {offersDescWithoutCurrent.map((o, i) => (
          <Grid item xs={12} key={`old-offer-${o.id}`}>
            {project && (
              <OfferDisplay
                offerNr={offersDescWithoutCurrent.length - i}
                client={client}
                projectOffer={o}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
}
