import React from 'react';
import { EntityId } from '@reduxjs/toolkit';
import { Divider, Paper, Table, TableContainer } from '@material-ui/core';
import { GenericTableHeader } from './GenericTableHeader';
import { GenericTableProps } from './types';
import { TableColumnConfigButton } from './TableColumnsConfig';
import { TableCSVExportButton } from './TableCSVExportButton';
import { TableToolBar } from './TableToolBar';
import { CircularProgressBackdrop } from './CircularProgressBackdrop';
import { CellInputMap, InputDataCellMap } from './TableDataCell';
import { DataTablePagination } from './pagination/DataTablePagination';
import { GenericTableBody } from './GenericTableBody';
import { DataTableContext } from './context/DataTableContext';
import { useDataTable } from './context/table.hook';
import { useVirtualScroller } from './VirtualScrollPanel';
import { SubPageArea } from '../../hooks/page-area.components';

export function useCreateCells<
  T extends { id: EntityId },
  C extends CellInputMap<T>
>(cells: GenericTableProps<T, C>['tableCells'], deps: any[]) {
  return React.useMemo(() => {
    return cells;
  }, deps);
}

export function GenericTable<
  T extends { id: EntityId },
  C extends CellInputMap<T>
>(props: GenericTableProps<T, C>) {
  const { contextValue: tableContext } = useDataTable<
    T,
    InputDataCellMap<T, C>
  >(
    props.tableCells as unknown as any,
    props.data,
    props.initialSort,
    props.initialFilter,
    props.label,
    props.initialColumnLayout
  );
  const { scrollPortRef: virtualScroller } = useVirtualScroller();
  const uiMode = props.uiMode ?? 'standalone';
  return (
    <SubPageArea type="table" specifier={props.label}>
      <DataTableContext.Provider value={tableContext}>
        <Paper style={{ padding: uiMode === 'widget' ? '10px' : undefined }}>
          <TableToolBar
            refresh={props.refresh}
            showLoading={uiMode === 'widget' && props.isLoading}
            primaryButton={props.primaryButton}
            label={props.label}
            entities={props.data}
            extraSearchKeys={props.extraSearchKeys}
            uiMode={uiMode}
            actions={[
              {
                label: 'Export CSV',
                showWhen: 'always',
                icon: <TableCSVExportButton filename="table_export" />
              },
              {
                label: 'Rowsettings',
                showWhen: 'unselected',
                icon: <TableColumnConfigButton<T, InputDataCellMap<T, C>> />
              },
              ...props.toolbarActions
            ]}
          />
          <DataTablePagination />
          <Divider />
          <TableContainer
            ref={virtualScroller}
            style={{
              zIndex: 0
            }}
          >
            {props.isLoading && uiMode === 'standalone' && (
              <CircularProgressBackdrop
                open={props.isLoading}
                style={{ position: 'absolute', zIndex: 5 }}
              />
            )}

            <Table size="small">
              <GenericTableHeader refresh={props.refresh} uiMode={uiMode} />
              <GenericTableBody rowActions={props.rowActions} uiMode={uiMode} />
            </Table>
          </TableContainer>
          <DataTablePagination />
        </Paper>
        {props.children}
      </DataTableContext.Provider>
    </SubPageArea>
  );
}
