"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var RequestPosition_1;
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestPosition = void 0;
const typeorm_1 = require("typeorm");
const class_transformer_1 = require("class-transformer");
// eslint-disable-next-line import/no-cycle
const ProjectRequest_1 = require("./ProjectRequest");
// eslint-disable-next-line import/no-cycle
const PriceUnit_1 = require("../../prices/entity/PriceUnit");
const JournaledEntity_1 = require("../../JournaledEntity");
const CircularReference_1 = require("../../serialization/CircularReference");
// eslint-disable-next-line import/no-cycle
const RequestResource_1 = require("./RequestResource");
// eslint-disable-next-line import/no-cycle
const RequestComment_1 = require("./RequestComment");
let RequestPosition = RequestPosition_1 = class RequestPosition extends JournaledEntity_1.JournaledEntity {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => ProjectRequest_1.ProjectRequest, (pr) => pr.positions),
    (0, CircularReference_1.CircularRef)(ProjectRequest_1.ProjectRequest),
    __metadata("design:type", ProjectRequest_1.ProjectRequest)
], RequestPosition.prototype, "request", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], RequestPosition.prototype, "description", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rp) => rp.request),
    __metadata("design:type", String)
], RequestPosition.prototype, "requestId", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", String)
], RequestPosition.prototype, "positionType", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => RequestResource_1.RequestResource),
    (0, typeorm_1.JoinTable)(),
    __metadata("design:type", Array)
], RequestPosition.prototype, "resources", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rp) => rp.resources),
    __metadata("design:type", Array)
], RequestPosition.prototype, "resourceIds", void 0);
__decorate([
    (0, typeorm_1.ManyToMany)(() => RequestPosition_1),
    (0, typeorm_1.JoinTable)({
        joinColumn: { name: 'dependentPositionId', referencedColumnName: 'id' },
        inverseJoinColumn: {
            name: 'requiredPositionId',
            referencedColumnName: 'id'
        }
    }),
    __metadata("design:type", Array)
], RequestPosition.prototype, "requiredPositions", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rp) => rp.requiredPositions),
    __metadata("design:type", Array)
], RequestPosition.prototype, "requiredPositionIds", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => PriceUnit_1.PriceUnit, undefined, { nullable: true }),
    __metadata("design:type", PriceUnit_1.PriceUnit)
], RequestPosition.prototype, "priceUnit", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rp) => rp.priceUnit),
    __metadata("design:type", String)
], RequestPosition.prototype, "priceUnitId", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true, type: 'numeric' }),
    (0, class_transformer_1.Type)(() => Number),
    __metadata("design:type", Number)
], RequestPosition.prototype, "estimatedAmount", void 0);
__decorate([
    (0, typeorm_1.Column)('timestamp', { nullable: true }),
    __metadata("design:type", Date)
], RequestPosition.prototype, "deadline", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    (0, class_transformer_1.Type)(() => Boolean),
    __metadata("design:type", Boolean)
], RequestPosition.prototype, "expressPosition", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => RequestComment_1.RequestComment, (rc) => rc.position),
    __metadata("design:type", Array)
], RequestPosition.prototype, "comments", void 0);
__decorate([
    (0, typeorm_1.RelationId)((rp) => rp.comments),
    __metadata("design:type", Array)
], RequestPosition.prototype, "commentIds", void 0);
RequestPosition = RequestPosition_1 = __decorate([
    (0, typeorm_1.Entity)(),
    (0, typeorm_1.TableInheritance)({ column: { type: 'varchar', name: 'positionType' } }),
    __metadata("design:paramtypes", [Object])
], RequestPosition);
exports.RequestPosition = RequestPosition;
