"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SequenceTemplateStep = void 0;
const typeorm_1 = require("typeorm");
// eslint-disable-next-line import/no-cycle
const ServiceStep_1 = require("./ServiceStep");
// eslint-disable-next-line import/no-cycle
const StepSequenceTemplate_1 = require("./StepSequenceTemplate");
let SequenceTemplateStep = class SequenceTemplateStep {
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => StepSequenceTemplate_1.StepSequenceTemplate, (s) => s.sequenceSteps),
    __metadata("design:type", SequenceTemplateStep)
], SequenceTemplateStep.prototype, "stepSequenceTemplate", void 0);
__decorate([
    (0, typeorm_1.RelationId)((e) => e.stepSequenceTemplate),
    (0, typeorm_1.PrimaryColumn)(),
    __metadata("design:type", String)
], SequenceTemplateStep.prototype, "stepSequenceTemplateId", void 0);
__decorate([
    (0, typeorm_1.Column)({ primary: true }),
    __metadata("design:type", Number)
], SequenceTemplateStep.prototype, "position", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => ServiceStep_1.ServiceStep),
    __metadata("design:type", ServiceStep_1.ServiceStep)
], SequenceTemplateStep.prototype, "serviceStep", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", Boolean)
], SequenceTemplateStep.prototype, "onlySuggest", void 0);
SequenceTemplateStep = __decorate([
    (0, typeorm_1.Entity)()
], SequenceTemplateStep);
exports.SequenceTemplateStep = SequenceTemplateStep;
