import { AuthTokensWithUser } from 'model';
import { apiClient } from '../../../../api/apiClient';
import { tokenService } from '../../token.service';
import { createErrorHandlingThunk } from '../../../../redux/thunk';

export interface LoginFormInterface {
  email: string;
  password: string;
}

export const loginThunk = createErrorHandlingThunk<
  AuthTokensWithUser,
  LoginFormInterface
>('login', async ({ email, password }) => {
  const result = await apiClient.post<AuthTokensWithUser>('/auth/login', {
    email,
    password
  });

  tokenService.setAuthTokens(result.data);
  return result.data;
});
