import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import _ from 'lodash';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import LanguageIcon from '@material-ui/icons/Language';
import TranslateIcon from '@material-ui/icons/Translate';
import { useLanguageIndex } from '../hooks/language-index.hook';
import { LanguageDescriptor } from '../hooks/language-index-context.hook';
import { useSelectLanguages } from '../hooks/select-languages.hook';
import {
  determineContextLanguages,
  formatLanguageLabel
} from './LanguageSelect';

export interface MultipleLanguageSelectProps {
  value: string[];
  onChange: (val: string[]) => void;
  filter?: (tag: LanguageDescriptor) => boolean;
  label?: string;
}

export function MultipleLanguageSelect({
  value,
  onChange,
  filter = () => true,
  label
}: MultipleLanguageSelectProps) {
  const languageIndex = useLanguageIndex();
  const selectedLanguages = useSelectLanguages(value);
  const selectedDescriptors = selectedLanguages.map(
    (l) => languageIndex.descriptors[l.id]
  );

  // const [inputValue, setInputValue] = React.useState<string>('');

  const filterOptions = (
    options: LanguageDescriptor[],
    state: FilterOptionsState<LanguageDescriptor>
  ): LanguageDescriptor[] => {
    const normalizedInput = state.inputValue.trim().toLowerCase();
    if (normalizedInput === '') {
      return options;
    }
    const prefixCodeMatches = Object.keys(languageIndex.codeMap)
      .filter((c) => c.startsWith(normalizedInput))
      .sort((a, b) => a.length - b.length);
    const semanticMatches = determineContextLanguages(
      languageIndex,
      normalizedInput
    );
    const nameMatches = Object.values(languageIndex.descriptors).filter((d) =>
      d.language.name.toLowerCase().includes(normalizedInput)
    );

    return _.uniqBy(
      [
        ...prefixCodeMatches.map(
          (p) => languageIndex.descriptors[languageIndex.codeMap[p].language.id]
        ),
        ...semanticMatches,
        ...nameMatches
      ],
      (d) => d.language.id
    );
  };
  return (
    <Autocomplete
      multiple
      value={selectedDescriptors}
      fullWidth
      onChange={(c, newValue) => onChange(newValue.map((v) => v.language.id))}
      options={Object.values(languageIndex.descriptors).filter((desc) =>
        filter(desc)
      )}
      autoHighlight
      clearOnEscape
      blurOnSelect
      getOptionLabel={(option) => formatLanguageLabel(option)}
      filterOptions={filterOptions}
      renderOption={(option) => {
        // eslint-disable-next-line no-nested-ternary
        const TypeIcon = option.specification.language ? (
          option.specification.region ? (
            <span>{option.specification.region.flag}</span>
          ) : (
            <LanguageIcon />
          )
        ) : (
          <CheckBoxOutlineBlankIcon />
        );

        return (
          <>
            {TypeIcon}
            <TranslateIcon
              style={{
                visibility: option.specification.script ? 'visible' : 'hidden'
              }}
            />
            {formatLanguageLabel(option)}
          </>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password'
          }}
        />
      )}
    />
  );
}
