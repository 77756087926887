import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../../../redux/redux.hooks';
import { projectOfferSelectors } from '../redux/project-offer.slice';

export function useOffersOfRequest(requestId: string) {
  const offers = useAppSelector(
    (state) => projectOfferSelectors.selectByRequest(state, requestId),
    shallowEqual
  );
  return { offers };
}
