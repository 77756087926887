import React from 'react';
import {
  Collapse,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { NavLink } from '../types';

interface NavLinkListItemProps {
  navLink: NavLink;
  parentPath?: string;
  currentPath: string;
  isTopLevel?: boolean;
  detailed?: boolean;
  onClick?: (link: NavLink) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    childLink: {
      textTransform: 'uppercase'
    }
  })
);

export function NavLinkListItem({
  navLink,
  parentPath = '',
  currentPath,
  isTopLevel,
  detailed,
  onClick = () => {}
}: NavLinkListItemProps) {
  const classes = useStyles();

  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = React.useState(
    navLink.children &&
      navLink.children.some((c) => c.link && currentPath.includes(c.link))
  );

  const IconComponent = navLink.icon;

  const isSelected = currentPath.startsWith(`${parentPath}${navLink.link}`);

  const childListItemProps = {
    component: RouterLink,
    to: parentPath + navLink.link,
    onClick: () => onClick(navLink)
  };

  const parentListItemProps = {
    onClick: () => {
      if (detailed) {
        setIsExpanded((prevState) => !prevState);
      }
      onClick(navLink);
    }
  };
  const LabelComponent =
    typeof navLink.label === 'function' ? navLink.label : undefined;
  return (
    <>
      <ListItem
        button
        {...(navLink.children ? parentListItemProps : childListItemProps)}
        selected={isSelected}
      >
        {isTopLevel && (
          <ListItemIcon>
            <IconComponent color={isSelected ? 'primary' : 'inherit'} />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Typography
              className={isTopLevel ? undefined : classes.childLink}
              variant={isTopLevel ? 'subtitle2' : 'caption'}
              color={isSelected ? 'primary' : 'textSecondary'}
            >
              {LabelComponent ? <LabelComponent /> : t(navLink.label as string)}
            </Typography>
          }
        />
        {navLink.children && (isExpanded ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {detailed && navLink.children && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {navLink.children.map((nl) => (
              <NavLinkListItem
                onClick={onClick}
                key={`navlink-${nl.link}`}
                navLink={nl}
                currentPath={currentPath}
                parentPath={parentPath + navLink.link}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}
