import React from 'react';
import { permissions, Supplier } from 'model';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import {
  Description as DescriptionIcon,
  Edit as EditIcon,
  Update as UntilIcon
} from '@material-ui/icons';
import { formatDate } from '../../../../localized-formats';
import { useStatusType } from '../../hooks/status-type.hook';
import { useGrantedPermissions } from '../../../auth/hooks/granted-permissions.hook';
import { executeMatcher } from '../../../auth/permissions.matchers';

interface SupplierInactivationDisplayProps {
  supplier: Supplier;
}

export function SupplierInactivationAlert({
  supplier
}: SupplierInactivationDisplayProps) {
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  const grantedPermissions = useGrantedPermissions();

  const editPermitted = executeMatcher(
    permissions.suppliers.supplier.edit,
    grantedPermissions
  );

  const { statusType } = useStatusType(supplier.status.statusTypeId);

  if (!statusType) {
    return <CircularProgress size="1em" />;
  }

  if (statusType.statusType === 'active' && statusType.defaultType) {
    return (
      <Button
        variant="outlined"
        color="primary"
        component={RouterLink}
        to={`${url}?type=create#inactivation`}
      >
        {t('Inactivate')}
      </Button>
    );
  }

  return (
    <Alert
      severity={statusType.statusType === 'active' ? 'warning' : 'error'}
      action={
        editPermitted ? (
          <>
            {statusType.statusType === 'active' ? (
              <Button
                color="primary"
                component={RouterLink}
                to={`${url}?type=create#inactivation`}
              >
                {t('Inactivate')}
              </Button>
            ) : (
              <Button
                color="primary"
                component={RouterLink}
                to={`${url}?type=create#activation`}
              >
                {t('Activate')}
              </Button>
            )}
            <IconButton
              component={RouterLink}
              to={`${url}?type=create#${
                statusType.statusType === 'active'
                  ? 'activation'
                  : 'inactivation'
              }`}
            >
              <EditIcon />
            </IconButton>
          </>
        ) : undefined
      }
    >
      <AlertTitle>
        <Tooltip title={statusType.description}>
          <span>{statusType.name}</span>
        </Tooltip>
      </AlertTitle>
      {statusType.statusType}
      {supplier.status.until && (
        <>
          <UntilIcon /> {formatDate(supplier.status.until, 'date')}
        </>
      )}
      {supplier.status.explanation && (
        <>
          <DescriptionIcon /> {supplier.status.explanation}
        </>
      )}
    </Alert>
  );
}
