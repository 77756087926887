import React from 'react';
import { UserDisplay } from './UserDisplay';
import { useAppSelector } from '../../../redux/redux.hooks';
import { selectUserAsNullable } from '../../auth/redux/auth.slice';

interface ActiveUserDisplayProps {
  link?: string;
}

export function ActiveUserDisplay(props: ActiveUserDisplayProps) {
  const user = useAppSelector(selectUserAsNullable);
  return <UserDisplay value={user} link={props.link} />;
}
