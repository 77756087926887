import React from 'react';
import {
  ActionCreatorWithoutPayload,
  EntityId,
  SerializedError
} from '@reduxjs/toolkit';
import { Form, Formik } from 'formik';
import { ObjectSchema } from 'yup';
import { Prompt, useHistory } from 'react-router-dom';
import {
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormikConfig } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../redux/store';
import { KontextorError } from '../../../features/error/KontextorError';
import { ErrorBoundary } from '../../../features/error/ErrorBoundary';
import { NoOp } from '../../../utils';
import { ApiState } from '../../../features/auth/redux/types';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { CardSelectionContext } from '../card/context';
import { Log } from '../../../logger/logger';
import { DialogFormActions } from '../button/DialogFormActions';
import { useAppSelector } from '../../../redux/redux.hooks';
import { DialogFormAction } from './types';
import { SubPageArea } from '../../../hooks/page-area.components';

const useStyles = makeStyles(() =>
  createStyles({
    dialogFormContainer: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
);

interface DialogFormProps<C, R> {
  maxWidth?: DialogProps['maxWidth'];
  label: string;
  identifier?: string;
  form: {
    initialValues: C;
    validationSchema: ObjectSchema<any>;
    formContainerClassName?: string;
    customValidator?: (value: C) => boolean;
  };
  api: {
    onSubmit: (values: C) => Promise<R | void>;
    stateSelector: (state: RootState) => ApiState;
    errorSelector: (state: RootState) => SerializedError | null;
    clearError: ActionCreatorWithoutPayload;
  };
  actions: DialogFormAction[];
  children: FormikConfig<C>['children'];
}

export function DialogForm<C, R extends { id: EntityId }>(
  props: DialogFormProps<C, R>
) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isCreationSuccess, setIsCreationSuccess] = React.useState(false);
  const { type, reference } = useEditorLocation();
  const history = useHistory();
  const apiState = useAppSelector(props.api.stateSelector);

  const { customValidator } = props.form;

  const onSubmit = (values: C) =>
    props.api
      .onSubmit(values)
      .then((value) => {
        setIsCreationSuccess(true);
        return value;
      })
      .catch(NoOp);

  const closeDialog = () => history.replace({ search: undefined });

  const isSubmitable =
    customValidator !== undefined ? (c: C) => customValidator(c) : () => true;

  return (
    <SubPageArea
      type="dialog"
      specifier={props.identifier ?? 'unknown'}
      identifier={reference}
    >
      <ErrorBoundary context={`roleDialog-${props.label}`}>
        <Prompt
          when={
            type !== 'view' &&
            reference === props.identifier &&
            !isCreationSuccess
          }
          message={t('all data will be lost if you navigate now!')}
        />
        <CardSelectionContext.Provider value={{ reference }}>
          <KontextorError
            clear={props.api.clearError}
            selector={props.api.errorSelector}
          >
            <Dialog
              fullWidth
              maxWidth={props.maxWidth || 'sm'}
              open={reference === props.identifier}
              onClose={closeDialog}
            >
              {apiState === 'pending' && (
                <CircularProgress
                  style={{ position: 'absolute', top: 20, right: 30 }}
                />
              )}
              <DialogTitle id="form-dialog-title">
                <Typography variant="h4">{t(props.label)}</Typography>
              </DialogTitle>
              <Formik<C>
                enableReinitialize
                initialValues={props.form.initialValues}
                validationSchema={props.form.validationSchema}
                onSubmit={onSubmit}
              >
                {(formikProps) => {
                  Log.warn('DialogForm validationError', formikProps.errors);
                  const submittable = isSubmitable(formikProps.values);
                  return (
                    <>
                      <DialogContent>
                        <Form
                          className={
                            props.form.formContainerClassName
                              ? props.form.formContainerClassName
                              : classes.dialogFormContainer
                          }
                        >
                          {typeof props.children === 'function'
                            ? props.children(formikProps)
                            : props.children}
                        </Form>
                      </DialogContent>
                      <DialogFormActions
                        onCancel={closeDialog}
                        actions={props.actions?.map((a) => ({
                          ...a,
                          onClick: async () => {
                            if (a.doSubmit && submittable) {
                              await formikProps
                                .submitForm()
                                .then(async (res) => {
                                  const result = res as unknown as R;
                                  if (result) {
                                    closeDialog();
                                    if (a.onClick) {
                                      await a.onClick(result.id, result);
                                    }
                                  }
                                  return result;
                                })
                                .catch(NoOp);
                            } else if (a.onClick) {
                              await a.onClick();
                            }
                          }
                        }))}
                      />
                    </>
                  );
                }}
              </Formik>
            </Dialog>
          </KontextorError>
        </CardSelectionContext.Provider>
      </ErrorBoundary>
    </SubPageArea>
  );
}
