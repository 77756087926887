import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { supplierApi, suppliersSelectors } from '../redux/suppliers.slice';
import { suppliersThunk } from '../redux/suppliers.thunk';

export function useSupplier(supplierId: string | null | undefined) {
  const dispatch = useAppDispatch();

  const supplier = useAppSelector((state) =>
    suppliersSelectors.selectById(state, supplierId ?? '')
  );
  const apiState = useAppSelector(supplierApi.selectors.selectState);

  React.useEffect(() => {
    if (supplierId && !supplier?.history && apiState !== 'pending') {
      dispatch(suppliersThunk.get({ id: supplierId }));
    }
  }, [supplierId, supplier]);

  return { supplier };
}
