import {
  createEntityAdapter,
  createSlice,
  isFulfilled,
  isPending,
  isRejected
} from '@reduxjs/toolkit';
import { ProjectCandidate } from 'model';
import { createAdapterSelectors } from '../../../redux/enitySlice';
import { getInitialApiAdapterState } from '../../auth/redux/types';
import { listSupplierRecruimentsThunk } from './supplier-recruitments.thunk';

export type SupplierRecruitments = {
  id: string;
  apiState: 'fresh' | 'loading' | 'error' | 'done';
} & (
  | { dataState: 'fetched'; recruitments: ProjectCandidate[] }
  | { dataState: 'none'; recruitments?: undefined }
);

export const recruitmentsAdapter = createEntityAdapter<SupplierRecruitments>();
export const supplierRecruitmentsSlice = createSlice({
  name: 'project-recruitments',
  initialState: recruitmentsAdapter.getInitialState(
    getInitialApiAdapterState()
  ),
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      isFulfilled(listSupplierRecruimentsThunk),
      (state, { payload, meta }) => {
        return recruitmentsAdapter.upsertOne(state, {
          id: meta.arg.supplierId,
          apiState: 'done',
          dataState: 'fetched',
          recruitments: payload
        });
      }
    );
    builder.addMatcher(
      isPending(listSupplierRecruimentsThunk),
      (state, action) => {
        return recruitmentsAdapter.upsertOne(state, {
          ...(state.entities[action.meta.arg.supplierId] ?? {
            id: action.meta.arg.supplierId,
            dataState: 'none'
          }),
          apiState: 'loading'
        });
      }
    );
    builder.addMatcher(
      isRejected(listSupplierRecruimentsThunk),
      (state, action) => {
        return recruitmentsAdapter.upsertOne(state, {
          ...(state.entities[action.meta.arg.supplierId] ?? {
            id: action.meta.arg.supplierId,
            dataState: 'none'
          }),
          apiState: 'error'
        });
      }
    );
  }
});

export const recruitmentsSelectors = createAdapterSelectors(
  'supplier-recruitments',
  recruitmentsAdapter
);
