import React from 'react';
import { EntityId, unwrapResult } from '@reduxjs/toolkit';
import { CreateUserDTO } from 'model';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DialogForm } from '../../../components/input/form/DialogForm';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { NoOp } from '../../../utils';
import { selectRolesState } from '../redux/roles.slice';
import usersSlice, { usersSelectors } from '../redux/users.slice';
import { userThunks } from '../redux/actions/users.thunk';
import { AccountForm } from './AccountForm';

const userSchema: yup.SchemaOf<CreateUserDTO> = yup.object().shape({
  email: yup
    .string()
    .required()
    .email('must be valid email')
    .max(255, 'Max. 255 characters'),
  roleIds: yup
    .array()
    .of(yup.string().required())
    .min(1, 'must assign at least one role'),
  enabled: yup.boolean().required(),
  displayName: yup
    .string()
    .min(3, 'At least 3 characters')
    .max(255, 'Max. 255 characters')
    .nullable()
    .default(null)
    .notRequired(),
  useLinkedName: yup.boolean().notRequired()
});

const initialValues: CreateUserDTO = {
  email: '',
  roleIds: [],
  enabled: true,
  displayName: null,
  useLinkedName: false
};

export function AccountFormDialog() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const navigateToDetails = (id?: EntityId) => {
    history.replace(`/users/accounts/${id}`);
  };

  const createUser = (userDTO: CreateUserDTO) =>
    dispatch(userThunks.create({ input: userDTO }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <div style={{ zIndex: 9001 }}>
      <DialogForm
        label="New Account"
        identifier="account"
        form={{
          validationSchema: userSchema,
          initialValues
        }}
        api={{
          onSubmit: createUser,
          errorSelector: usersSelectors.selectError,
          clearError: usersSlice.actions.clearError,
          stateSelector: selectRolesState
        }}
        actions={[
          { label: t('Save and Exit'), doSubmit: true },
          {
            label: t('Save and Details'),
            doSubmit: true,
            onClick: navigateToDetails
          }
        ]}
      >
        <AccountForm />
      </DialogForm>
    </div>
  );
}
