import { ProjectExpenseDTO, ProjectExpensesSummary } from 'model';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const aggregateProjectExpensesThunk = createErrorHandlingThunk<
  ProjectExpensesSummary,
  { projectId: string }
>('projects/list/expenses', async ({ projectId }) => {
  const result = await apiClient.get<ProjectExpensesSummary>(
    `/projects/project/${projectId}/expenses`
  );
  return result.data;
});
export const bookProjectExpensesThunk = createErrorHandlingThunk<
  ProjectExpensesSummary,
  { projectId: string; supplierId: string; dto: ProjectExpenseDTO }
>('projects/book/expenses', async ({ projectId, supplierId, dto }) => {
  const result = await apiClient.put<ProjectExpensesSummary>(
    `/projects/project/${projectId}/expenses/${supplierId}`,
    dto
  );
  return result.data;
});
export const removeProjectExpensesThunk = createErrorHandlingThunk<
  ProjectExpensesSummary,
  { projectId: string; supplierId: string }
>('projects/remove/expenses', async ({ projectId, supplierId }) => {
  const result = await apiClient.delete<ProjectExpensesSummary>(
    `/projects/project/${projectId}/expenses/${supplierId}`
  );
  return result.data;
});
