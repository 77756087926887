import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { EntityId } from '@reduxjs/toolkit';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  createStyles,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { Add, Refresh } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import SearchBar from './SearchBar';
import { TableAction } from './types';
import { useTableSearchControl } from './search/search-control.hook';
import { useTableSelectionControl } from './selection/selection-control.hook';
import {
  executeMatcher,
  PermissionMatcher
} from '../../features/auth/permissions.matchers';
import { useGrantedPermissions } from '../../features/auth/hooks/granted-permissions.hook';
import { ColumnFilterPopoverButton } from './filter/ColumnFilterPopover';

const useStyles = makeStyles((theme) =>
  createStyles({
    toolBar: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      height: theme.spacing(13)
    },
    toolBarWidget: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.5)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark
          },
    leftSide: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    rightSide: {
      flex: 1
    },
    primaryActionButton: {
      backgroundColor:
        theme.palette.type === 'light'
          ? lighten(theme.palette.secondary.light, 0.5)
          : undefined,
      color:
        theme.palette.type === 'light' ? theme.palette.primary.main : undefined,
      marginLeft: theme.spacing(5),
      border: 0
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: theme.spacing(3)
    }
  })
);

interface TableActionsBarProps<T> {
  label: string;
  entities: T[];
  refresh?: () => void;
  actions: TableAction<T>[];
  primaryButton?: {
    label: string;
    onClick: () => void;
    permission?: PermissionMatcher;
  };
  extraSearchKeys?: { onDelete: () => void; label: React.ReactNode }[];
  showLoading?: boolean;
  uiMode: 'widget' | 'standalone';
}

export function TableToolBar<T extends { id: EntityId }>({
  label,
  entities,
  actions,
  primaryButton,
  extraSearchKeys,
  showLoading = false,
  uiMode,
  refresh
}: TableActionsBarProps<T>) {
  const classes = useStyles();

  const { t } = useTranslation();
  const userPermissions = useGrantedPermissions();

  const primaryButtonPermitted =
    primaryButton && executeMatcher(primaryButton.permission, userPermissions);

  const { selectedIds } = useTableSelectionControl<T>();
  const search = useTableSearchControl();
  const hasSelectedIds = selectedIds.length > 0;
  return (
    <Toolbar
      className={clsx(
        uiMode === 'widget' ? classes.toolBarWidget : classes.toolBar,
        selectedIds.length > 0 && classes.highlight
      )}
    >
      <div className={classes.leftSide}>
        {hasSelectedIds ? (
          <Typography color="textPrimary" variant="h5" component="div">
            {selectedIds.length} selected
          </Typography>
        ) : (
          <>
            <Typography
              variant={uiMode === 'widget' ? 'h5' : 'h3'}
              component="div"
            >
              {label}
            </Typography>
            {primaryButton && primaryButtonPermitted && uiMode !== 'widget' && (
              <Button
                startIcon={
                  !showLoading ? <Add /> : <CircularProgress size="1em" />
                }
                onClick={primaryButton.onClick}
                className={classes.primaryActionButton}
                variant="outlined"
                size="medium"
              >
                {t(primaryButton.label)}
              </Button>
            )}
          </>
        )}
        <div className={classes.actionsContainer}>
          {actions.map((action) => {
            if (
              action.showWhen === 'always' ||
              (action.showWhen === 'selected' && hasSelectedIds) ||
              (action.showWhen === 'unselected' && !hasSelectedIds)
            ) {
              return (
                <Tooltip title={t(action.label) as string} key={action.label}>
                  <IconButton
                    onClick={() => {
                      if (action.onClick) {
                        const selectedEntities = entities.filter((e) =>
                          selectedIds.includes(e.id)
                        );
                        action.onClick(selectedEntities, entities);
                      }
                    }}
                  >
                    {action.icon}
                  </IconButton>
                </Tooltip>
              );
            }
            return <div key="empty-action" />;
          })}
          {uiMode === 'widget' && <ColumnFilterPopoverButton />}
          {showLoading && <CircularProgress size="1em" />}
          {!showLoading && uiMode === 'widget' && !!refresh && (
            <IconButton size="small" onClick={refresh}>
              <Refresh fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
      <div className={classes.rightSide}>
        {!hasSelectedIds && (
          <SearchBar
            uiMode={uiMode}
            search={search}
            extraSearchKeys={extraSearchKeys}
          />
        )}
      </div>
    </Toolbar>
  );
}
