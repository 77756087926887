import React from 'react';
import { Client } from 'model';
import { ColumnFilter } from '../../../components/table/filter/column-filter.types';
import { CountrySelect } from '../../../components/locale/country/CountrySelect';

export function AddressColumnFilter(): ColumnFilter<
  Client,
  Client['addresses'],
  string | undefined
> {
  return {
    initialState: undefined,
    createFilter: (cell, state) => {
      return state === undefined
        ? () => true
        : (value) => value.some((address) => address.country === state);
    },
    // eslint-disable-next-line react/prop-types
    component: ({ filterState, onFilterChange }) => (
      <CountrySelect
        currentCountry={filterState ?? null}
        onChange={(val) => onFilterChange(val ?? undefined)}
      />
    )
  };
}
