import React from 'react';
import { Chip } from '@material-ui/core';
import { NormalTestType, SupplierTestAttachment } from 'model';
import { useAttachmentsOfSupplier } from '../../hooks/supplier-attachments.hook';
import { useTestType } from '../../hooks/test-type.hook';
import { useTestTypes } from '../../hooks/test-types.hook';

export function TestNameDisplay({ testTypeId }: { testTypeId: string }) {
  const { testType } = useTestType(testTypeId);
  return <Chip size="small" label={testType?.name} />;
}

export function SupplierTestColumnRenderer({
  supplierId
}: {
  supplierId: string;
}) {
  const { entities: testTypes } = useTestTypes();

  const supplierTests = useAttachmentsOfSupplier(supplierId).attachments.filter(
    (a) => {
      if (a.attachmentType === 'test') {
        const testType = testTypes.find(
          (tt) => tt.id === (a as SupplierTestAttachment).testTypeId
        );
        if (!testType) {
          return false;
        }
        if (testType.testVariant === 'simple') {
          return true;
        }
        const resultState = (testType as NormalTestType).resultStates.find(
          (rs) => rs.id === (a as SupplierTestAttachment).result?.resultStateId
        );
        return !!resultState?.passed;
      }
      return false;
    }
  ) as SupplierTestAttachment[];
  return (
    <>
      {supplierTests.map((sT) => (
        <TestNameDisplay testTypeId={sT.testTypeId} />
      ))}
    </>
  );
}
