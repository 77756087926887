import { permissions, PriceUnit } from 'model';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import React from 'react';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { ApiState } from '../../auth/redux/types';
import { entityColumnBuilder } from '../../../components/table/columns.builder';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { priceUnitsThunk } from '../../prices/redux/price-units.thunk';

function buildTableColumns() {
  return entityColumnBuilder<PriceUnit>()
    .addDefaultColumn('unit_name', 'name', 'Name')
    .addDefaultColumn('unit_type', 'type', 'Type')
    .addDefaultColumn('unit_description', 'description', 'Description')
    .addBooleanColumn(
      'unit_differ_steps',
      'differServiceSteps',
      'Differ Service Steps'
    ).columns;
}

interface PriceUnitTableProps {
  priceUnits: PriceUnit[];
  onRefresh: () => void;
  apiState: ApiState;
}

export function PriceUnitTable({
  priceUnits,
  onRefresh,
  apiState
}: PriceUnitTableProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const tableCells = useCreateCells(buildTableColumns(), []);
  const deletePriceUnit = (id: string) => {
    dispatch(priceUnitsThunk.delete({ id }));
  };
  return (
    <GenericTable
      label={t('Price Units')}
      refresh={onRefresh}
      primaryButton={{
        label: 'New Price Unit',
        onClick: () => history.push(`${url}?type=create#price-unit`),
        permission: permissions.prices.priceUnit.create
      }}
      data={priceUnits}
      isLoading={apiState === 'pending'}
      tableCells={tableCells}
      rowActions={[
        {
          permissions: permissions.prices.priceUnit.edit,
          entry: (step) => ({
            icon: EditIcon,
            label: 'Edit',
            link: `${url}?type=edit#${step.id}`
          })
        },
        {
          permissions: permissions.prices.priceUnit.delete,
          entry: (sc) => ({
            icon: DeleteIcon,
            label: 'Delete',
            alert: {
              title: 'Delete Price Unit',
              content: `${t(
                'You may only delete a price unit ' +
                  'if it is not referenced anymore. ' +
                  'Otherwise an error will occur. ' +
                  'Are you sure you want to delete price unit'
              )} "${sc.name}"?`,
              onConfirm: () => {
                deletePriceUnit(sc.id);
              }
            }
          })
        }
      ]}
      toolbarActions={[]}
    />
  );
}
