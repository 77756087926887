import { CreateRequestDTO, ProjectRequest, RequestPatchDTO } from 'model';
import { createFeatureThunks } from '../../../redux/featureThunks';
import { createErrorHandlingThunk } from '../../../redux/thunk';
import { apiClient } from '../../../api/apiClient';

export const projectRequestThunk = createFeatureThunks<
  ProjectRequest,
  CreateRequestDTO,
  RequestPatchDTO
>({
  urlFactory: ({ id } = { id: undefined }) =>
    `/projects/project-request${id !== undefined ? `/${id}` : ''}`,
  thunkName: 'project-requests'
});
export const listOpenProjectRequestsThunk = createErrorHandlingThunk<
  ProjectRequest[],
  { pmIds: string[] }
>('projects/list-open', async ({ pmIds }) => {
  const result = await apiClient.get<ProjectRequest[]>(
    // eslint-disable-next-line max-len
    `/projects/project-request?status=open&${pmIds
      .map((id) => `pm=${id}`)
      .join('&')}`
  );

  return result.data;
});
