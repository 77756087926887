import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Supplier } from 'model';
import { SupplierLanguagesDisplay } from './SupplierLanguagesDisplay';
import { SupplierQualificationsDisplay } from './SupplierQualificationsDisplay';
import { SupplierDomainsDisplay } from './SupplierDomainsDisplay';
import { SupplierCatToolsDisplay } from './SupplierCatToolsDisplay';
import { ErrorBoundary } from '../../../../error/ErrorBoundary';

export function SupplierSkillSetDisplay({ value }: { value: Supplier }) {
  const { t } = useTranslation();
  return (
    <ErrorBoundary context="SupplierSkillSetDisplay">
      <Typography variant="caption" color="textSecondary">
        {t('Languages')}
      </Typography>
      <SupplierLanguagesDisplay supplierLanguages={value.languages} />
      <Typography variant="caption" color="textSecondary">
        {t('Qualifications')}
      </Typography>
      <SupplierQualificationsDisplay
        supplierQualifications={value.qualifications}
      />
      <Typography variant="caption" color="textSecondary">
        {t('Domains')}
      </Typography>
      <SupplierDomainsDisplay domainIds={value.domains.map((d) => d.id)} />
      <Typography variant="caption" color="textSecondary">
        {t('CAt Tools')}
      </Typography>
      <SupplierCatToolsDisplay supplierCatTools={value.catTools} />
    </ErrorBoundary>
  );
}
