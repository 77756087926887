import React from 'react';
import { supplierAttachmentPermission, SupplierTestAttachment } from 'model';
import { ButtonBase, Chip, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { GetApp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistoryOfSupplier } from '../../hooks/supplier-history.hook';
import { StyledLink } from '../../../../components/input/link/StyledLink';
import { createFileGrant } from '../../../../utils';
import { CommentDisplay } from '../../../../components/comment/CommentDisplay';
import { CommentInterface } from '../../../../components/comment/types';
import { useSupplier } from '../../hooks/supplier.hook';

interface SupplierTestDisplayProps {
  value: SupplierTestAttachment;
}

export function SupplierTestDisplay(props: SupplierTestDisplayProps) {
  const { t } = useTranslation();
  const testHistory = useHistoryOfSupplier(
    props.value.supplierId
  ).history.filter(
    (h) => h.referenceId === props.value.id && h.comment !== null
  );
  const { supplier: tester } = useSupplier(props.value.testerId);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <StyledLink variant="h6" to="/suppliers/test-types">
          {props.value.testType.name}
        </StyledLink>
        {props.value.testState && (
          <Chip
            style={{
              backgroundColor: props.value.testState.color
            }}
            label={props.value.testState.name}
          />
        )}
      </div>
      {props.value.testType.testVariant === 'normal' && (
        <>
          <Typography variant="caption" color="textSecondary">
            {t('Tester')}
          </Typography>
          <Typography>
            {`${tester?.name?.first} ${tester?.name?.last}`}
          </Typography>
          {!!props.value.result && !!props.value.result.resultState && (
            <>
              <Typography variant="caption" color="textSecondary">
                {t('Result')}
              </Typography>
              <Typography>{props.value.result.resultState.name}</Typography>
              <Typography variant="caption" color="textSecondary">
                {t('Description')}
              </Typography>
              <Typography>{props.value.result.resultContent}</Typography>
            </>
          )}
        </>
      )}
      {props.value.attachedFiles &&
        props.value.attachedFiles.map((af) => (
          <ButtonBase style={{ padding: 5, borderRadius: 10 }}>
            <Chip
              size="small"
              component={RouterLink}
              to={`/files/${af.id}/${
                createFileGrant(
                  af.id,
                  supplierAttachmentPermission,
                  props.value
                ).token
              }`}
              target="blank"
              label={
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  {af.name}
                  <GetApp
                    style={{ marginLeft: 4, fontSize: 14 }}
                    fontSize="small"
                    color="action"
                  />
                </div>
              }
            />
          </ButtonBase>
        ))}
      {testHistory.map((h) => (
        <CommentDisplay
          comment={h as CommentInterface}
          createFileGrant={() => ''}
        />
      ))}
    </div>
  );
}
