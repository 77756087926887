import React from 'react';
import { compareAsc } from 'date-fns';
import { Typography } from '@material-ui/core';
import { BaseTableRow } from '../TableDataCell';
import { DataCellRenderer } from './TableDataRenderer';
import { DateFormatType, formatDate } from '../../../localized-formats';
import { DateColumnFilter } from '../filter/DateColumnFilter';

export function DateCellRenderer<T extends BaseTableRow>(
  formatType: DateFormatType,
  nullable = false
): DataCellRenderer<T, Date | null | string> {
  const dataAccessor = (value: Date | null | string) => {
    const dateAsStringOrDate = value;
    if (value === null) {
      return null;
    }
    if (typeof dateAsStringOrDate === 'string') {
      return new Date(dateAsStringOrDate);
    }
    return dateAsStringOrDate;
  };
  const dataFormatter = (value: Date | null | string) =>
    value === null ? '' : formatDate(value, formatType);
  return {
    createElement: (value: Date | null | string) => (
      <Typography variant="subtitle1">
        {value === null ? '' : formatDate(value, formatType)}
      </Typography>
    ),
    createText: (v) => [dataFormatter(v)],
    comparator: (a, b) => {
      const dateA = dataAccessor(a[0]);
      const dateB = dataAccessor(b[0]);
      if (dateA !== null && dateB !== null) {
        return compareAsc(dateA, dateB);
      }
      if (dateA === dateB) return 0;
      if (dateA === null) return -1;
      return 1;
    },
    filter: DateColumnFilter({ type: formatType, nullable })
  };
}
