import React from 'react';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { usePriceUnits } from '../../prices/hooks/price-units.hook';
import { PriceUnitTable } from '../components/PriceUnitTable';
import { PriceUnitFormDialog } from '../components/PriceUnitFormDialog';

export function PriceUnitScreen() {
  const { entities, apiState, listEntities } = usePriceUnits();
  const { type, reference } = useEditorLocation();

  return (
    <>
      {type === 'edit' && reference !== undefined && (
        <PriceUnitFormDialog stepId={reference} />
      )}
      <PriceUnitTable
        priceUnits={entities}
        onRefresh={listEntities}
        apiState={apiState}
      />
    </>
  );
}
