import { PriceBound } from 'model';
import { AsyncThunk, EntityId } from '@reduxjs/toolkit';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { priceBoundsThunk, definePriceBoundThunk } from './price-bounds.thunk';
import { EntityAPI } from '../../../redux/entity-api.hook';
import { RootState } from '../../../redux/store';

const { list, delete: deleteThunk, ...thunks } = priceBoundsThunk;

const priceBoundsSlice = createEntitySlice<PriceBound>('price-bounds', [
  CustomThunk.override<PriceBound>(list, {
    pending: (_, state) => {
      state.state = 'pending';
      state.listFetched = undefined;
      state.error = null;
      return state;
    },
    rejected: (_, state, action) => {
      state.state = 'idle';
      state.listFetched = false;
      state.error = (action as any).error;
      return state;
    },
    fulfilled: (adapter, state, { payload }) => {
      state.state = 'idle';
      state.listFetched = true;
      return adapter.upsertMany(state, payload as PriceBound[]);
    }
  }),
  CustomThunk.override<PriceBound>(
    deleteThunk as AsyncThunk<EntityId, { id: EntityId }, any>,
    {
      fulfilled: (adapter, state, { payload }) => {
        state.state = 'idle';
        return adapter.removeOne(state, payload as string);
      }
    }
  ),
  definePriceBoundThunk,
  ...Object.values(thunks)
]);
export const priceBoundsSelectors = {
  ...createAdapterSelectors('price-bounds', priceBoundsSlice.adapter),
  selectByTarget(state: RootState, rangeTarget: PriceBound['rangeTarget']) {
    return priceBoundsSelectors
      .selectAll(state)
      .filter((r) => r.rangeTarget === rangeTarget);
  }
};

export const priceBoundAPI: EntityAPI<PriceBound> = {
  selectors: priceBoundsSelectors,
  thunks: priceBoundsThunk
};
export default priceBoundsSlice;
