import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { selectAllRoles } from '../redux/roles.slice';
import { listRolesThunk } from '../redux/actions/list-roles.thunk';

export function useRoles() {
  const dispatch = useAppDispatch();

  const roles = useAppSelector(selectAllRoles);

  React.useEffect(() => {
    dispatch(listRolesThunk());
  }, []);

  return { roles };
}
