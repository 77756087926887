import React from 'react';
import { IETFTag, Language, permissions } from 'model';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@material-ui/icons/Language';
import PlusIcon from '@material-ui/icons/Add';
import TranslateIcon from '@material-ui/icons/Translate';
import RegionIcon from '@material-ui/icons/Room';
import {
  GenericTable,
  useCreateCells
} from '../../../components/table/GenericTable';
import { useLanguages } from '../hooks/languages.hook';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { selectLanguagesState } from '../redux/languages.slice';
import { listLanguagesThunk } from '../redux/actions/list-languages.thunk';
import { useLanguageIndex } from '../hooks/language-index.hook';
import { ErrorBoundary } from '../../error/ErrorBoundary';
import { LanguageColumnFilter } from '../../../components/table/filter/LanguageColumnFilter';
import PopoverPreview from '../../../components/preview/PopoverPreview';
import { formatCountryLabel } from '../../../components/locale/country/CountryLabel';
import { entityColumnBuilder } from '../../../components/table/columns.builder';

function TagColumnView({ ietfTag, code }: { code: string; ietfTag: IETFTag }) {
  return (
    <PopoverPreview
      ready
      content={
        <div>
          <LanguageIcon fontSize="small" />
          {`${ietfTag.language.name}(${ietfTag.language.possibleCodes.join(
            ', '
          )})`}
          {ietfTag.extendedLanguage && (
            <>
              <br />
              <PlusIcon fontSize="small" /> {ietfTag.extendedLanguage}
            </>
          )}
          {ietfTag.script && (
            <>
              <br /> <TranslateIcon fontSize="small" />
              {ietfTag.script.name}
            </>
          )}
          {ietfTag.region && (
            <>
              <br /> <RegionIcon fontSize="small" />
              {ietfTag.region.type === 'country'
                ? formatCountryLabel(ietfTag.region.code)
                : `${ietfTag.region.id} ${ietfTag.region.name}`}
            </>
          )}
        </div>
      }
    >
      <span>{code}</span>
    </PopoverPreview>
  );
}
export function LanguagesTable() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { languages } = useLanguages();
  const apiState = useAppSelector(selectLanguagesState);
  const history = useHistory();

  const refreshLanguages = () => {
    dispatch(listLanguagesThunk());
  };

  const languageIndex = useLanguageIndex();
  const tagValues: ((t: IETFTag) => string | undefined)[] = [
    (tag) => tag.language.code,
    (tag) => tag.region?.id,
    (tag) => tag.script?.code,
    (tag) => tag.extendedLanguage
  ];

  const languageTableCells = useCreateCells(
    entityColumnBuilder<Language>()
      .addColumn(
        'language_code',
        (l) =>
          languageIndex.codeMap[l.languageCode.toLowerCase()].specification,
        'Code',
        {
          renderer: {
            createElement: (value, data) => (
              <TagColumnView code={data.languageCode} ietfTag={value} />
            ),
            createText: (_, l) => [l.languageCode],
            filter: LanguageColumnFilter<Language>({ ...languageIndex }),
            comparator: (a, b) => {
              const tagA = a[0];
              const tagB = b[0];
              return (
                tagValues
                  .map((v) => {
                    const valA = v(tagA) ?? '';
                    const valB = v(tagB) ?? '';
                    if (valA === valB) {
                      return 0;
                    }
                    return valA < valB ? -1 : 1;
                  })
                  .find((v) => v !== 0) ?? 0
              );
            }
          }
        }
      )
      .addDefaultColumn('lang_display_code', 'displayCode', 'Displayed Code')
      .addDefaultColumn('lang_name', 'name', 'Name')
      .addDefaultColumn('lang_wordbee_code', 'wordbeeCode', 'Wordbee code')
      .addDefaultColumn(
        'lang_alternative_codes',
        'alternativeCodes',
        'Alternative Codes',
        {
          createElement: (_, l) => l.alternativeCodes.join(', '),
          createText: (_, l) => l.alternativeCodes
        }
      ).columns,
    [languageIndex]
  );
  return (
    <>
      <ErrorBoundary context="languageTable">
        <GenericTable
          toolbarActions={[]}
          primaryButton={{
            label: 'New Language',
            onClick: () => history.push('/languages/create'),
            permission: permissions.language.create
          }}
          label={t('Languages')}
          refresh={refreshLanguages}
          data={languages}
          isLoading={apiState === 'pending'}
          tableCells={languageTableCells}
          rowActions={[]}
        />
      </ErrorBoundary>
    </>
  );
}
