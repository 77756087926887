import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { Overrides as MuiOverrides } from '@material-ui/core/styles/overrides';

export const typographyOptions: TypographyOptions = {
  h1: {
    fontSize: '6em',
    lineHeight: '7em',
    letterSpacing: '0.09375em',
    fontFamily: 'AvenirMedium'
  },
  h2: {
    fontSize: '3.75em',
    lineHeight: '1.2em',
    letterSpacing: '0.03125em',
    fontFamily: 'AvenirMedium'
  },
  h3: {
    fontSize: '3em',
    lineHeight: '1.167em',
    fontFamily: 'AvenirBlack'
  },
  h4: {
    fontSize: '2.125em',
    lineHeight: '1.2em',
    letterSpacing: '0.015625em',
    fontFamily: 'AvenirMedium'
  },
  h5: {
    fontSize: '1.5em',
    lineHeight: '1.334em',
    fontFamily: 'AvenirMedium'
  },
  h6: {
    fontSize: '1.25em',
    lineHeight: '1.6em',
    letterSpacing: '0.009375em',
    fontFamily: 'AvenirBlack'
  },
  body1: {
    fontSize: '1rem',
    lineHeight: '1.6em',
    letterSpacing: '0.009375em',
    fontFamily: 'AvenirMedium'
  },
  body2: {
    fontSize: '0.875rem',
    lineHeight: '1.43em',
    letterSpacing: '0.009375em',
    fontFamily: 'AvenirMedium'
  },
  subtitle1: {
    fontSize: '1em',
    lineHeight: '1.75em',
    letterSpacing: '0.009375em',
    fontFamily: 'AvenirMedium'
  },
  subtitle2: {
    fontSize: '0.875em',
    lineHeight: '1.57em',
    letterSpacing: '0.00625em',
    fontFamily: 'AvenirBlack'
  },
  caption: {
    fontSize: '0.75em',
    letterSpacing: '0.025em',
    lineHeight: '1.66em',
    fontFamily: 'AvenirMedium'
  },
  overline: {
    fontSize: '0.75em',
    letterSpacing: '0.0625em',
    lineHeight: '2.66em',
    textTransform: 'uppercase',
    fontFamily: 'AvenirMedium'
  }
};

export const textComponentStyleOverrides: MuiOverrides = {
  MuiButton: {
    root: {
      fontFamily: 'AvenirBlack'
    }
  },
  MuiInputLabel: {
    root: {
      fontFamily: 'AvenirMedium',
      fontSize: '1em',
      lineHeight: '1.1875em',
      letterSpacing: '0.009375em'
    }
  },
  // helperText
  MuiFormHelperText: {
    root: {
      fontFamily: 'AvenirMedium',
      fontSize: '0.75em',
      lineHeight: '1.25em',
      letterSpacing: '0.025em'
    }
  },
  // inputText
  // MuiInput: {
  //   input: {
  //     fontSize: '16px',
  //     lineHeight: '19px',
  //     letterSpacing: '0.15px'
  //   }
  // },
  // avatarLetter
  MuiAvatar: {
    root: {
      fontSize: '1.25em',
      lineHeight: '1.25em',
      letterSpacing: '0.00875em'
    }
  },
  MuiChip: {
    root: {
      fontSize: '0.8125em',
      lineHeight: '1.125em',
      letterSpacing: '0.01em'
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '0.625em',
      lineHeight: '0.875em'
    }
  },
  MuiTableHead: {
    root: {
      fontSize: '0.875em',
      lineHeight: '1.5em',
      letterSpacing: '0.009375em'
    }
  },
  // Todo Dark Theme
  MuiListItem: {
    root: {
      '&$selected': {
        backgroundColor: 'rgba(239,64,53, 0.1)',
        '&:hover': {
          backgroundColor: 'rgba(239,64,53, 0.2)'
        }
      }
    },
    button: {
      '&:hover': {
        backgroundColor: 'rgba(239,64,53, 0.2)'
      }
    }
  },
  MuiTableCell: {
    root: {
      height: 50
    }
  }
};
