import React from 'react';
import { Cached as RefreshIcon } from '@material-ui/icons';
import { IconButton, TableHead } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { FutureVoid } from './types';
import { BaseTableRow, DataCellMap } from './TableDataCell';
import { useArrangedTableColumns } from './layout/arranged-columns.hook';
import { TableHeaderCell, TableHeaderFilterCell } from './TableHeaderCell';
import { useTableSelectionControl } from './selection/selection-control.hook';
import {
  useTableConfigContext,
  useTableDataContext
} from './context/DataTableContext';

interface TestTableHeaderProps {
  refresh: FutureVoid;
  uiMode: 'widget' | 'standalone';
}

export function GenericTableHeader<
  T extends BaseTableRow,
  C extends DataCellMap<T>
>(props: TestTableHeaderProps) {
  const { refresh } = props;

  const arrangeColumns = useArrangedTableColumns<T, C>();
  const { displayedData, tableData } = useTableDataContext<T, C>();
  const { selectedIds, handleSelectAllRows } = useTableSelectionControl();
  const {
    tableFilterState: { filterState }
  } = useTableConfigContext<T, C>();
  const isAnyFilterActive = React.useMemo(
    () => Object.values(filterState).some((fs) => fs?.active),
    [filterState]
  );
  const onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void = (e) => {
    handleSelectAllRows(e, tableData);
  };
  return (
    <TableHead>
      <TableRow
        style={{
          verticalAlign: props.uiMode === 'widget' ? 'middle' : undefined
        }}
      >
        {props.uiMode !== 'widget' && (
          <TableCell
            padding="none"
            align="center"
            size="small"
            key="header-refresh"
            style={{ width: '45px' }}
          >
            <IconButton size="small" onClick={() => refresh()}>
              <RefreshIcon />
            </IconButton>
          </TableCell>
        )}
        {props.uiMode !== 'widget' && (
          <TableCell
            size="small"
            padding="none"
            key="header-select"
            style={{ width: '45px' }}
          >
            <Checkbox
              size="small"
              indeterminate={
                selectedIds.length > 0 &&
                selectedIds.length < displayedData.length
              }
              checked={displayedData.length > 0 && selectedIds.length > 0}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        )}
        {arrangeColumns.map((column, index) => (
          <TableHeaderCell
            uiMode={props.uiMode}
            index={index}
            column={column.cellId}
            key={`column-header-${column.cellId}`}
          />
        ))}
      </TableRow>
      {props.uiMode !== 'widget' && isAnyFilterActive && (
        <TableRow>
          <TableCell
            padding="none"
            align="center"
            size="small"
            key="header-refresh"
            style={{ width: '45px' }}
          />
          <TableCell
            style={{ width: '45px' }}
            size="small"
            padding="none"
            key="header-select"
          />
          {arrangeColumns.map((column, index) => (
            <TableHeaderFilterCell
              uiMode={props.uiMode}
              index={index}
              column={column.cellId}
              key={`column-header-${column.cellId}`}
            />
          ))}
        </TableRow>
      )}
    </TableHead>
  );
}
