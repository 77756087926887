import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import React from 'react';
import { BaseTableRow } from '../TableDataCell';
import { DataCellRenderer } from './TableDataRenderer';
import { BooleanColumnFilter } from '../filter/BooleanColumnFilter';

const boolValOrder = (val: boolean | null | undefined) => {
  if (val === true) {
    return 0;
  }
  if (val === false) {
    return 1;
  }
  return 2;
};

export function BooleanCellRenderer<T extends BaseTableRow>({
  filter = true,
  sortable = true
}: {
  filter?: boolean;
  sortable?: boolean;
}): DataCellRenderer<T, boolean | null | undefined> {
  const dataAccessor = (value: boolean | null | undefined) => {
    return value !== undefined && value !== null ? Boolean(value) : undefined;
  };

  return {
    createElement: (v) => {
      const boolVal = dataAccessor(v);
      if (boolVal === undefined) {
        return <span>-</span>;
      }
      return boolVal ? (
        <CheckBoxOutlinedIcon fontSize="small" />
      ) : (
        <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
      );
    },
    createText: (v) => {
      const val = dataAccessor(v);
      return [`${val ?? ''}`];
    },
    comparator: sortable
      ? (a, b) => {
          return (
            boolValOrder(dataAccessor(a[0])) - boolValOrder(dataAccessor(b[0]))
          );
        }
      : undefined,
    filter: filter ? BooleanColumnFilter() : undefined
  };
}
