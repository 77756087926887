"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.documentAttachmentTransformer = exports.DocumentAttachmentPatchDTO = void 0;
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const DocumentSupplierAttachmentDTO_1 = require("./DocumentSupplierAttachmentDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const SupplierDocumentAttachment_1 = require("../entity/SupplierDocumentAttachment");
const SupplierAttachmentGrant_1 = require("../entity/SupplierAttachmentGrant");
const FileAccessDTO_1 = require("../../files/dto/FileAccessDTO");
class DocumentAttachmentPatchDTO extends (0, partial_type_helper_1.PartialType)(DocumentSupplierAttachmentDTO_1.DocumentSupplierAttachmentDTO) {
}
exports.DocumentAttachmentPatchDTO = DocumentAttachmentPatchDTO;
const documentAttachmentTransformer = (encoder) => (0, DTOTransformer_1.createDTOTransformer)(SupplierDocumentAttachment_1.SupplierDocumentAttachment, DocumentSupplierAttachmentDTO_1.DocumentSupplierAttachmentDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'supplier',
    'supplierId',
    'documentState',
    'documentType'
], {
    attachedFiles: [
        'attachedFiles',
        (v, k, o) => {
            return v.map((f) => FileAccessDTO_1.FileAccessDTO.createUnsigned(encoder, f.id, SupplierAttachmentGrant_1.supplierAttachmentPermission, o));
        }
    ]
});
exports.documentAttachmentTransformer = documentAttachmentTransformer;
