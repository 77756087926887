import { Permission } from 'model';

export type PermissionMatcherFn = (
  withChildren: boolean,
  permissions: string[]
) => boolean;

export type PermissionMatcher = PermissionMatcherFn | Permission | undefined;

type DefinedPermissionMatcher = Exclude<PermissionMatcher, undefined>;

export function anyPermission(
  ...matchers: DefinedPermissionMatcher[]
): PermissionMatcherFn {
  return (withChildren, permissions) =>
    matchers
      .map((p) => executeMatcher(p, permissions, withChildren))
      .some((b) => b);
}

export function allPermissions(
  ...matchers: DefinedPermissionMatcher[]
): PermissionMatcherFn {
  return (withChildren, permissions) =>
    matchers
      .map((p) => executeMatcher(p, permissions, withChildren))
      .every((b) => b);
}
export function executeMatcher(
  matcher: PermissionMatcher,
  permissions: string[],
  withChildren = false
) {
  if (matcher !== undefined) {
    return matcher instanceof Permission
      ? (withChildren
          ? matcher.getIncludedNames()
          : [matcher.getFullQualifiedName()]
        )
          .map((n) => permissions.includes(n))
          .some((b) => b)
      : matcher(withChildren, permissions);
  }
  return true;
}
