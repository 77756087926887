import { useSelector } from 'react-redux';
import {
  DocumentType,
  DocumentTypeDTO,
  updateDocumentTypeTransformer
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useAppDispatch } from '../../../redux/redux.hooks';
import { useEditorLocation } from '../../../hooks/editor-location.hook';
import { RootState } from '../../../redux/store';
import { NoOp } from '../../../utils';
import { DialogForm } from '../../../components/input/form/DialogForm';
import suppliersSlice, { suppliersSelectors } from '../redux/suppliers.slice';
import { documentTypesThunk } from '../redux/document-type.thunk';
import { documentTypesSelectors } from '../redux/document-type.slice';
import {
  DocumentTypeBasicForm,
  DocumentTypeBasicView
} from './DocumentTypeBasicForm';

const initialValues = {
  name: '',
  description: '',
  states: [],
  activationConstraint: false
};
export function DocumentTypeEditFormDialog() {
  const dispatch = useAppDispatch();
  const { type, reference } = useEditorLocation();
  const documentType = useSelector((rs: RootState) =>
    documentTypesSelectors.selectById(rs, reference ?? '')
  );
  if (!reference || type !== 'edit' || !documentType) {
    return <></>;
  }
  return (
    <DocumentTypeFormDialog
      save={(dto: DocumentTypeDTO) =>
        dispatch(documentTypesThunk.update({ id: documentType.id, input: dto }))
          .then(unwrapResult)
          .catch(NoOp)
      }
      documentType={documentType}
    />
  );
}
export function DocumentTypeFormDialog({
  save,
  documentType
}: {
  save?: (d: DocumentTypeDTO) => Promise<void | DocumentType>;
  documentType?: DocumentType;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const createDocumentType =
    save ??
    ((dto: DocumentTypeDTO) =>
      dispatch(documentTypesThunk.create({ input: dto }))
        .then(unwrapResult)
        .catch(NoOp));

  return (
    <DialogForm
      identifier={documentType?.id ?? 'document-type'}
      maxWidth="md"
      label={t('New Document Type')}
      form={{
        initialValues: DocumentTypeBasicView.viewFactory(
          documentType
            ? updateDocumentTypeTransformer(documentType)
            : initialValues
        ),
        validationSchema: DocumentTypeBasicView.validationSchema
      }}
      api={{
        clearError: suppliersSlice.actions.clearError,
        errorSelector: suppliersSelectors.selectError,
        onSubmit: createDocumentType,
        stateSelector: suppliersSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <DocumentTypeBasicForm />
    </DialogForm>
  );
}
