import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { supplierQualificationsSelectors } from '../redux/supplier-qualifications.slice';
import { supplierQualificationsThunk } from '../redux/supplier-qualifications.thunk';

export function useSupplierQualifications() {
  const dispatch = useAppDispatch();

  const supplierQualifications = useAppSelector(
    supplierQualificationsSelectors.selectAll
  );

  const apiState = useAppSelector(supplierQualificationsSelectors.selectState);

  const listSupplierQualification = () =>
    dispatch(supplierQualificationsThunk.list());

  React.useEffect(() => {
    listSupplierQualification();
  }, []);

  return {
    supplierQualifications,
    apiState,
    listSupplierQualification
  };
}
