import { User, userAvatarPermission } from 'model';
import { Avatar, Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { FaServer } from 'react-icons/fa';
import { formatDate } from '../../../../localized-formats';
import { UserAvatar } from '../../../../components/image/UserAvatar';

export type CommentAuthor =
  | { type: 'system' | 'none' }
  | { type: 'user'; user: User | undefined };
export interface CommentAuthorDisplayProps {
  author: CommentAuthor;
  date: Date | string;
  actions?: { icon: JSX.Element; text: string }[];
}

export function CommentAuthorDisplay({
  author,
  date,
  actions = []
}: CommentAuthorDisplayProps) {
  if (author.type === 'none') {
    return <></>;
  }
  return (
    <>
      <Box
        marginY="0.25em"
        marginX="0.25em"
        display="flex"
        justifyContent="flex-start"
      >
        <Box marginRight="0.75em">
          {author.type === 'system' && (
            <Avatar style={{ width: '2em', height: '2em' }}>
              <FaServer />
            </Avatar>
          )}
          {author.type === 'user' && !!author.user && (
            <UserAvatar
              style={{ width: '2em', height: '2em' }}
              avatar={author.user.avatar}
              grant={userAvatarPermission.createGrant(author.user)}
              permission={userAvatarPermission}
            />
          )}
        </Box>
        <Box>
          {author.type === 'system' && (
            <Typography variant="body2">TEO (System)</Typography>
          )}
          {author.type === 'user' &&
            (author.user ? (
              <Typography variant="body2">
                {author.user.displayName ?? author.user.email}
              </Typography>
            ) : (
              <Skeleton />
            ))}
          <Typography variant="body2" color="textSecondary">
            {formatDate(date, 'date-time')}
          </Typography>
        </Box>
      </Box>
      {actions?.map((action) => (
        <Box display="flex" paddingLeft="3.5em">
          <Typography variant="subtitle2">
            {action.icon} {action.text}
          </Typography>
        </Box>
      ))}
    </>
  );
}
