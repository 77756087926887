import React from 'react';
import { Address } from 'model';
import { Typography } from '@material-ui/core';
import { CountryLabel } from '../locale/country/CountryLabel';

interface AddressDisplayProps {
  withoutComment?: boolean;
  withoutCountry?: boolean;
  address: Address;
}

export function AddressDisplay({
  address,
  withoutCountry,
  withoutComment
}: AddressDisplayProps) {
  return (
    <div>
      {address.address.map((a) => (
        <span>
          {a}
          <br />
        </span>
      ))}
      <span>
        {address.zipCode ?? ''} {address.city}
        <br />
      </span>
      {!withoutCountry && <CountryLabel country={address.country} />}
      {!withoutComment && address.comment && (
        <Typography style={{ marginTop: 10 }} color="textSecondary">
          {address.comment}
        </Typography>
      )}
    </div>
  );
}
