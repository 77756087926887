import { Project } from 'model';
import React from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { Add, Check, Close } from '@material-ui/icons';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { EntityReferenceSelect } from '../../../../components/select/EntityReferenceSelect';
import { supplierApi } from '../../../suppliers/redux/suppliers.slice';
import { addProjectCandidateThunk } from '../../redux/projects.thunk';

export function SimpleCandidateSelector({ project }: { project: Project }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const [selectedSupplier, setSelectedSupplier] = React.useState(
    null as string | null
  );
  if (!open) {
    return (
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(true);
          }}
          color="primary"
          startIcon={<Add />}
        >
          Add Candidate
        </Button>
      </Box>
    );
  }
  return (
    <Box display="flex">
      <EntityReferenceSelect
        currentValue={selectedSupplier}
        entityApi={supplierApi}
        optionLabel={(s) => `${s.name.first} ${s.name.last}`}
        selectValue={(s) => setSelectedSupplier(s)}
      />
      {selectedSupplier && (
        <IconButton
          size="small"
          onClick={() => {
            dispatch(
              addProjectCandidateThunk({
                projectId: project.id,
                dto: {
                  supplierId: selectedSupplier,
                  positionId: null,
                  contactedOn: null
                }
              })
            );
            setSelectedSupplier(null);
            setOpen(false);
          }}
        >
          <Check fontSize="small" />
        </IconButton>
      )}
      <IconButton
        size="small"
        onClick={() => {
          setSelectedSupplier(null);
          setOpen(false);
        }}
      >
        <Close fontSize="small" />
      </IconButton>
    </Box>
  );
}
