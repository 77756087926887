import React from 'react';
import { MonthlyClients } from 'model';
import { apiClient } from '../../api/apiClient';
import { TimeBarChartWidget } from '../../components/widgets/TimeBarChartWidget';

export function NewClientsWidget() {
  return (
    <TimeBarChartWidget
      loadData={() =>
        apiClient
          .get<MonthlyClients[]>('/client/stats/new-clients-per-month')
          .then((res) => res.data)
      }
      calculator={(t) => ({
        clients: t.clientIds.length
      })}
      label="New Clients"
      keys={['clients']}
    />
  );
}
/*
export function NewClientsWidget() {
  const [monthlyClients, setMonthlyClients] = React.useState<MonthlyClients[]>(
    []
  );

  useEffect(() => {
    apiClient
      .get<MonthlyClients[]>('/client/stats/new-clients-per-month')
      .then((res) => setMonthlyClients(res.data))
      .catch(NoOp);
  }, []);

  return <MyResponsiveBar data={monthlyClients} />;
}
*/
