import { useAppSelector } from '../../../redux/redux.hooks';
import { supplierHistorysSelectors } from '../redux/supplier-history.slice';

export function useHistoryOfSupplier(supplierId: string) {
  const history = useAppSelector((state) =>
    supplierHistorysSelectors.selectBySupplier(state, supplierId)
  );

  return { history };
}
