"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultCurrency = exports.currencyMap = void 0;
exports.currencyMap = {
    USD: { name: 'Dollar', code: 'USD', symbol: '$' },
    AUD: { name: 'Australischer Dollar', code: 'AUD', symbol: 'AU$' },
    CAD: { name: 'Kanadischer Dollar', code: 'CAD', symbol: 'C$' },
    CHF: { name: 'Franken', code: 'CHF', symbol: 'FS' },
    JPY: { name: 'Yen', code: 'JPY', symbol: '¥' },
    NZD: { name: 'Neuseeland-Dollar', code: 'NZD', symbol: 'NZ$' },
    EUR: { name: 'Euro', code: 'EUR', symbol: '€' },
    GBP: { name: 'Pfund', code: 'GBP', symbol: '£' },
    SEK: { name: 'Schwedische Krone', code: 'SEK', symbol: 'kr' },
    DKK: { name: 'Dänische Krone', code: 'DKK', symbol: 'kr' },
    NOK: { name: 'Norwegische Krone', code: 'NOK', symbol: 'kr' },
    SGD: { name: 'Singapur-Dollar', code: 'SGD', symbol: 'S$' },
    CZK: { name: 'Tschechische Krone', code: 'CZK', symbol: 'Kč' },
    HKD: { name: 'Hongkong-Dollar', code: 'HKD', symbol: 'HK$' },
    MXN: { name: 'Peso', code: 'MXN', symbol: 'Mex$' },
    PLN: { name: 'Złoty', code: 'PLN', symbol: 'zł' },
    RUB: { name: 'Rubel', code: 'RUB', symbol: '' },
    TRY: { name: 'Lira', code: 'TRY', symbol: '' },
    ZAR: { name: 'Rand', code: 'ZAR', symbol: 'R' },
    CNH: { name: 'Yuan', code: 'CNH', symbol: '' },
    AED: { name: 'VAE-Dirham', code: 'AED', symbol: 'د.إ' },
    AMD: { name: 'Armenischer Dram', code: 'AMD', symbol: 'Դ' },
    ANG: { name: 'Antillen-Gulden', code: 'ANG', symbol: 'NAƒ' },
    ARS: { name: 'Argentinischer Peso', code: 'ARS', symbol: '$' },
    AWG: { name: 'Aruba-Florin', code: 'AWG', symbol: 'ƒ' },
    AZN: { name: 'Aserbaidschan-Manat', code: 'AZN', symbol: '' },
    BAM: { name: 'Konvertible Mark', code: 'BAM', symbol: 'KM' },
    BBD: { name: 'Barbados-Dollar', code: 'BBD', symbol: 'BBD' },
    BDT: { name: 'Taka', code: 'BDT', symbol: 'Tk' },
    BGN: { name: 'Lew', code: 'BGN', symbol: 'лв' },
    BHD: { name: 'Bahrain-Dinar', code: 'BHD', symbol: '.د.ب' },
    BIF: { name: 'Burundi-Franc', code: 'BIF', symbol: 'FBu' },
    BMD: { name: 'Bermuda-Dollar', code: 'BMD', symbol: 'BD$' },
    BND: { name: 'Brunei-Dollar', code: 'BND', symbol: 'B$' },
    BOB: { name: 'Boliviano', code: 'BOB', symbol: 'Bs' },
    BOV: { name: 'Bolivian Mvdol', code: 'BOV', symbol: '' },
    BRL: { name: 'Brasilianischer Real', code: 'BRL', symbol: 'R$' },
    BSD: { name: 'Bahama-Dollar', code: 'BSD', symbol: 'B$' },
    BTN: { name: 'Ngultrum', code: 'BTN', symbol: 'Nu.' },
    BWP: { name: 'Botswanischer Pula', code: 'BWP', symbol: 'P' },
    BYN: { name: 'Weißrussischer Rubel', code: 'BYN', symbol: 'р' },
    BYR: { name: 'Weißrussischer Rubel', code: 'BYR', symbol: 'Br' },
    BZD: { name: 'Belize-Dollar', code: 'BZD', symbol: 'BZ$' },
    CDF: { name: 'Kongo-Franc', code: 'CDF', symbol: '₣' },
    CHE: { name: 'WIR Euro', code: 'CHE', symbol: '' },
    CLF: { name: 'Unidad de Fomento', code: 'CLF', symbol: 'UF' },
    CLP: { name: 'Chilenischer Peso', code: 'CLP', symbol: '$' },
    CNY: { name: 'Renminbi', code: 'CNY', symbol: '¥' },
    COP: { name: 'Kolumbianischer Peso', code: 'COP', symbol: '$' },
    COU: { name: 'Unidad de Valor Real', code: 'COU', symbol: '' },
    CRC: { name: 'Costa-Rica-Colón', code: 'CRC', symbol: '₡' },
    CUC: {
        name: 'Konvertierbarer Kubanischer Peso',
        code: 'CUC',
        symbol: 'CUC$'
    },
    CUP: { name: 'Kubanischer Peso', code: 'CUP', symbol: '$MN' },
    CVE: { name: 'Kap-Verde-Escudo', code: 'CVE', symbol: 'Esc' },
    DJF: { name: 'Dschibuti-Franc', code: 'DJF', symbol: 'Fdj' },
    DOP: { name: 'Dominikanischer Peso', code: 'DOP', symbol: 'RD$' },
    DZD: { name: 'Algerischer Dinar', code: 'DZD', symbol: 'دج' },
    EGP: { name: 'Ägyptisches Pfund', code: 'EGP', symbol: 'ج.م' },
    ERN: { name: 'Eritreischer Nakfa', code: 'ERN', symbol: 'ናቕፋ' },
    ETB: { name: 'Äthiopischer Birr', code: 'ETB', symbol: 'Br' },
    FJD: { name: 'Fidschi-Dollar', code: 'FJD', symbol: 'FJ$' },
    FKP: { name: 'Falkland-Pfund', code: 'FKP', symbol: '£' },
    GEL: { name: 'Georgischer Lari', code: 'GEL', symbol: '' },
    GHS: { name: 'Cedi', code: 'GHS', symbol: 'GHS' },
    GIP: { name: 'Gibraltar-Pfund', code: 'GIP', symbol: '£' },
    GMD: { name: 'Dalasi', code: 'GMD', symbol: 'D' },
    GNF: { name: 'Franc Guinéen', code: 'GNF', symbol: 'FG' },
    GTQ: { name: 'Guatemaltekischer Quetzal', code: 'GTQ', symbol: 'Q' },
    GYD: { name: 'Guyana-Dollar', code: 'GYD', symbol: 'GY$' },
    HNL: { name: 'Lempira', code: 'HNL', symbol: 'L' },
    HRK: { name: 'Kroatische Kuna', code: 'HRK', symbol: 'kn' },
    HTG: { name: 'Gourde', code: 'HTG', symbol: '' },
    HUF: { name: 'Forint', code: 'HUF', symbol: 'Ft' },
    IDR: { name: 'Indonesische Rupiah', code: 'IDR', symbol: 'Rp' },
    ILS: { name: 'Schekel', code: 'ILS', symbol: '₪' },
    INR: { name: 'Indische Rupie', code: 'INR', symbol: 'Rs.' },
    IQD: { name: 'Irakischer Dinar', code: 'IQD', symbol: 'ع.د' },
    IRR: { name: 'Iranischer Rial', code: 'IRR', symbol: '' },
    ISK: { name: 'Isländische Krone', code: 'ISK', symbol: 'kr' },
    JMD: { name: 'Jamaika-Dollar', code: 'JMD', symbol: '$' },
    JOD: { name: 'Jordanischer Dinar', code: 'JOD', symbol: 'JOD' },
    KES: { name: 'Kenia-Schilling', code: 'KES', symbol: 'KSh' },
    KGS: { name: 'Som', code: 'KGS', symbol: 'KGS' },
    KHR: { name: 'Kambodschanischer Riel', code: 'KHR', symbol: 'KHR' },
    KMF: { name: 'Komoren-Franc', code: 'KMF', symbol: 'KMF' },
    KPW: { name: 'Nordkoreanischer Won', code: 'KPW', symbol: '₩' },
    KRW: { name: 'Südkoreanischer Won', code: 'KRW', symbol: '₩' },
    KWD: { name: 'Kuwait-Dinar', code: 'KWD', symbol: 'د.ك' },
    KYD: { name: 'Kaiman-Dollar', code: 'KYD', symbol: '$' },
    KZT: { name: 'Tenge', code: 'KZT', symbol: 'KZT' },
    LAK: { name: 'Laotischer Kip', code: 'LAK', symbol: '₭' },
    LBP: { name: 'Libanesisches Pfund', code: 'LBP', symbol: 'ل.ل' },
    LKR: { name: 'Sri-Lanka-Rupie', code: 'LKR', symbol: 'ரூ' },
    LRD: { name: 'Liberianischer Dollar', code: 'LRD', symbol: 'L$' },
    LSL: { name: 'Lesothischer Loti', code: 'LSL', symbol: 'L' },
    LYD: { name: 'Libyscher Dinar', code: 'LYD', symbol: 'LD' },
    MAD: { name: 'Marokkanischer Dirham', code: 'MAD', symbol: 'د.م.' },
    MDL: { name: 'Moldauischer Leu', code: 'MDL', symbol: 'MDL' },
    MGA: { name: 'Ariary', code: 'MGA', symbol: 'Ar' },
    MKD: { name: 'Mazedonischer Denar', code: 'MKD', symbol: 'MKD' },
    MMK: { name: 'Kyat', code: 'MMK', symbol: 'K' },
    MNT: { name: 'Tögrög', code: 'MNT', symbol: '₮' },
    MOP: { name: 'Macau-Pataca', code: 'MOP', symbol: '$' },
    MRO: { name: 'Ouguiya', code: 'MRO', symbol: 'UM' },
    MUR: { name: 'Mauritius-Rupie', code: 'MUR', symbol: '₨' },
    MVR: { name: 'Rufiyaa', code: 'MVR', symbol: 'Rf' },
    MWK: { name: 'Malawi-Kwacha', code: 'MWK', symbol: 'MK' },
    MXV: { name: 'Mexican Unidad de Inversion (UDI)', code: 'MXV', symbol: '' },
    MYR: { name: 'Ringgit', code: 'MYR', symbol: 'RM' },
    MZN: { name: 'Metical', code: 'MZN', symbol: 'MT' },
    NAD: { name: 'Namibia-Dollar', code: 'NAD', symbol: 'N$' },
    NGN: { name: 'Naira', code: 'NGN', symbol: '₦' },
    NIO: { name: 'Córdoba Oro', code: 'NIO', symbol: 'C$' },
    NPR: { name: 'Nepalesische Rupie', code: 'NPR', symbol: '₨' },
    OMR: { name: 'Omanischer Rial', code: 'OMR', symbol: 'ر.ع.' },
    PAB: { name: 'Panamaischer Balboa', code: 'PAB', symbol: 'B' },
    PEN: { name: 'Peruanischer Sol', code: 'PEN', symbol: 'S/.' },
    PGK: { name: 'Kina', code: 'PGK', symbol: 'K' },
    PHP: { name: 'Philippinischer Peso', code: 'PHP', symbol: '₱' },
    PKR: { name: 'Pakistanische Rupie', code: 'PKR', symbol: 'Rs.' },
    PYG: { name: 'Paraguayischer Guaraní', code: 'PYG', symbol: '' },
    QAR: { name: 'Katar-Riyal', code: 'QAR', symbol: 'ر.ق' },
    RON: { name: 'Rumänischer Leu', code: 'RON', symbol: 'L' },
    RSD: { name: 'Serbischer Dinar', code: 'RSD', symbol: 'din' },
    RWF: { name: 'Ruanda-Franc', code: 'RWF', symbol: 'RF' },
    SAR: { name: 'Saudi-Riyal', code: 'SAR', symbol: 'ر.س' },
    SBD: { name: 'Salomonen-Dollar', code: 'SBD', symbol: 'SI$' },
    SCR: { name: 'Seychellen-Rupie', code: 'SCR', symbol: 'SR' },
    SDG: { name: 'Sudanesisches Pfund', code: 'SDG', symbol: 'SDG' },
    SHP: { name: 'St.-Helena-Pfund', code: 'SHP', symbol: '£' },
    SLL: { name: 'Sierra-leonischer Leone', code: 'SLL', symbol: 'Le' },
    SOS: { name: 'Somalia-Schilling', code: 'SOS', symbol: 'So.' },
    SRD: { name: 'Suriname-Dollar', code: 'SRD', symbol: '$' },
    SSP: { name: 'Südsudanesisches Pfund', code: 'SSP', symbol: 'SSP' },
    STD: { name: 'São-toméischer Dobra', code: 'STD', symbol: 'Db' },
    SVC: { name: 'El-Salvador-Colón', code: 'SVC', symbol: '₡' },
    SYP: { name: 'Syrisches Pfund', code: 'SYP', symbol: 'SYP' },
    SZL: { name: 'Lilangeni', code: 'SZL', symbol: 'SZL' },
    THB: { name: 'Baht', code: 'THB', symbol: '฿' },
    TJS: { name: 'Somoni', code: 'TJS', symbol: 'с.' },
    TMT: { name: 'Turkmenistan-Manat', code: 'TMT', symbol: 'T' },
    TND: { name: 'Tunesischer Dinar', code: 'TND', symbol: 'د.ت' },
    TOP: { name: 'Paʻanga', code: 'TOP', symbol: 'T$' },
    TTD: { name: 'Trinidad-und-Tobago-Dollar', code: 'TTD', symbol: 'TTD' },
    TWD: { name: 'Neuer Taiwan-Dollar', code: 'TWD', symbol: 'NT$' },
    TZS: { name: 'Tansania-Schilling', code: 'TZS', symbol: 'x' },
    UAH: { name: 'Ukrainische Hrywnja', code: 'UAH', symbol: '' },
    UGX: { name: 'Uganda-Schilling', code: 'UGX', symbol: 'USh' },
    USN: { name: 'US Dollar', code: 'USN', symbol: '' },
    UYI: { name: 'UYI', code: 'UYI', symbol: '' },
    UYU: { name: 'Uruguayischer Peso', code: 'UYU', symbol: 'UYU' },
    UZS: { name: 'Soʻm', code: 'UZS', symbol: 'UZS' },
    VEF: { name: 'Venezolanischer Bolívar', code: 'VEF', symbol: 'VEF' },
    VND: { name: 'Vietnamesischer Đồng', code: 'VND', symbol: '₫' },
    VUV: { name: 'Vatu', code: 'VUV', symbol: 'Vt' },
    WST: { name: 'Samoanischer Tala', code: 'WST', symbol: 'WS$' },
    XAF: { name: 'CFA-Franc', code: 'XAF', symbol: 'BEAC' },
    XCD: { name: 'Ostkaribischer Dollar', code: 'XCD', symbol: 'EC$' },
    XDR: { name: 'Sonderziehungsrecht', code: 'XDR', symbol: 'SDR' },
    XOF: { name: 'Westafrikanischer CFA-Franc', code: 'XOF', symbol: 'BCEAO' },
    XPF: { name: 'CFP-Franc', code: 'XPF', symbol: 'F' },
    XSU: { name: 'Sucre', code: 'XSU', symbol: '' },
    XUA: { name: 'ADB Einheit des Kontos', code: 'XUA', symbol: '' },
    YER: { name: 'Jemen-Rial', code: 'YER', symbol: 'YER' },
    ZMW: { name: 'Sambischer Kwacha', code: 'ZMW', symbol: 'ZK' },
    ZWL: { name: 'Simbabwe-Dollar', code: 'ZWL', symbol: '$' }
};
exports.DefaultCurrency = exports.currencyMap.EUR;
