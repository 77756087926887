import React from 'react';
import { useStatusTypes } from '../hooks/status-types.hook';
import { SupplierStatusTypesTable } from '../components/status/SupplierStatusTypeTable';

export function SupplierStatusTypesScreen() {
  const { entities, apiState, listEntities } = useStatusTypes();
  return (
    <SupplierStatusTypesTable
      supplierStatusTypes={entities}
      onRefresh={listEntities}
      apiState={apiState}
    />
  );
}
