import React from 'react';
import { permissions, Supplier, SupplierPhone } from 'model';
import { Edit as EditIcon } from '@material-ui/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ActionsMenu,
  useActionMenuItems
} from '../../../../components/actions/ActionsMenu';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { suppliersThunk } from '../../redux/suppliers.thunk';

interface SupplierPhoneActionsProps {
  supplier: Supplier;
  phone: SupplierPhone;
}

export function SupplierPhoneActions(props: SupplierPhoneActionsProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { reference } = useEditorLocation();

  const menuItems = useActionMenuItems([
    {
      entry: {
        icon: EditIcon,
        label: 'Edit',
        link: `${url}?type=edit#${props.phone.type}-${props.phone.number}`
      },
      permissions: permissions.suppliers.supplier.edit
    },
    {
      permissions: permissions.suppliers.supplier.delete,
      entry: {
        icon: DeleteIcon,
        label: 'Remove',

        onClick: () => {
          dispatch(
            suppliersThunk.update({
              id: props.supplier.id,
              input: {
                phoneNumbers: props.supplier.phoneNumbers.filter(
                  (a) => a.number !== props.phone.number
                )
              }
            })
          );
          if (`${props.phone.type}-${props.phone.number}` === reference) {
            history.replace(url);
          }
        }
      }
    }
  ]);

  return <ActionsMenu items={menuItems} />;
}
