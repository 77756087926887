import { useSelector } from 'react-redux';
import {
  ActiveStatusTypeDTO,
  activeStatusTypeTransformer,
  InactiveStatusTypeDTO,
  inactiveStatusTypeTransformer,
  SupplierStatusType
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { omit } from 'lodash';
import { supplierStatusTypesThunk } from '../../redux/status-type.thunk';
import supplierStatusTypesSlice, {
  supplierStatusTypesSelectors
} from '../../redux/status-type.slice';
import {
  ActiveStatusTypeView,
  InactiveStatusTypeView,
  SupplierStatusTypeBasicForm
} from './SupplierStatusTypeBasicForm';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { RootState } from '../../../../redux/store';
import { NoOp } from '../../../../utils';
import { DialogForm } from '../../../../components/input/form/DialogForm';

const initialValues = {
  statusType: 'active' as const,
  name: '',
  description: '',
  expireType: 'never' as const,
  explanationType: 'never' as const,
  selectable: true,
  enforceActivationConstraints: true
};
export function SupplierStatusTypeEditDialog() {
  const dispatch = useAppDispatch();
  const { type, reference } = useEditorLocation();
  const statusType = useSelector((rs: RootState) =>
    supplierStatusTypesSelectors.selectById(rs, reference ?? '')
  );
  if (!reference || type !== 'edit' || !statusType) {
    return <></>;
  }
  return (
    <SupplierStatusTypeFormDialog
      save={(dto: ActiveStatusTypeDTO | InactiveStatusTypeDTO) =>
        dispatch(
          supplierStatusTypesThunk.update({
            id: statusType.id,
            input: { type: dto }
          })
        )
          .then(unwrapResult)
          .catch(NoOp)
      }
      statusType={statusType}
    />
  );
}
export function SupplierStatusTypeFormDialog({
  save,
  statusType
}: {
  save?: (
    d: ActiveStatusTypeDTO | InactiveStatusTypeDTO
  ) => Promise<void | SupplierStatusType>;
  statusType?: SupplierStatusType;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [currentType, setCurrentType] = React.useState<'active' | 'inactive'>(
    statusType?.statusType ?? 'active'
  );
  const createStatusType =
    save ??
    ((dto: ActiveStatusTypeDTO | InactiveStatusTypeDTO) =>
      dispatch(supplierStatusTypesThunk.create({ input: { type: dto } }))
        .then(unwrapResult)
        .catch(NoOp));

  return (
    <DialogForm
      identifier={statusType?.id ?? 'supplier-status-type'}
      maxWidth="md"
      label={t('New Status Type')}
      form={{
        initialValues:
          currentType === 'active'
            ? ActiveStatusTypeView.viewFactory(
                statusType
                  ? activeStatusTypeTransformer(statusType as any)
                  : initialValues
              )
            : InactiveStatusTypeView.viewFactory(
                statusType
                  ? inactiveStatusTypeTransformer(statusType)
                  : omit(
                      { ...initialValues, statusType: 'inactive' as const },
                      'enforceValidationConstraints'
                    )
              ),
        validationSchema:
          currentType === 'active'
            ? ActiveStatusTypeView.validationSchema
            : InactiveStatusTypeView.validationSchema
      }}
      api={{
        clearError: supplierStatusTypesSlice.actions.clearError,
        errorSelector: supplierStatusTypesSelectors.selectError,
        onSubmit: createStatusType,
        stateSelector: supplierStatusTypesSelectors.selectState
      }}
      actions={[{ label: t('Save'), doSubmit: true }]}
    >
      <SupplierStatusTypeBasicForm
        alterType={statusType ? undefined : setCurrentType}
        basePath={
          currentType === 'active'
            ? ActiveStatusTypeView.path
            : InactiveStatusTypeView.path
        }
      />
    </DialogForm>
  );
}
