import React from 'react';
import { projectOfferSelectors } from '../redux/project-offer.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/redux.hooks';
import { loadRequestOffersThunk } from '../redux/project-offers.thunk';

export function useProjectOffer(
  requestId: string | null | undefined,
  id: string | null | undefined
) {
  const dispatch = useAppDispatch();

  const entity = useAppSelector((state) =>
    projectOfferSelectors.selectByRequest(state, requestId ?? '')
  ).find((o) => o.id === id);

  React.useEffect(() => {
    if (!entity && requestId) {
      dispatch(loadRequestOffersThunk({ requestId }));
    }
  }, [entity, requestId, id]);

  return { projectOffer: entity };
}
