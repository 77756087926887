import React from 'react';
import * as yup from 'yup';
import { Supplier, SupplierPriceDTO } from 'model';
import { Divider, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ArrowForward } from '@material-ui/icons';
import {
  buildDTOView,
  useTypedField
} from '../../../../transformer/DTOViewSchema';
import { FormikEntitySelect } from '../../../../components/select/FormikEntitySelect';
import { priceUnitsAPI } from '../../../prices/redux/price-units.slice';
import { serviceClassAPI } from '../../../services/redux/service-classes.slice';
import { serviceStepAPI } from '../../../services/redux/service-steps.slice';
import { FormikLanguageSelect } from '../../../../components/select/FormikLanguageSelect';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { FormikCurrencySelect } from '../../../../components/select/FormikCurrencySelect';
import { FormikPriceInput } from '../../../clients/components/prices/FormikPriceInput';
import { usePriceUnit } from '../../../prices/hooks/price-unit.hook';
import { useServiceClass } from '../../../services/hooks/service-class.hook';

const validationSchema: yup.SchemaOf<SupplierPriceDTO> = yup.object({
  priceUnitId: yup.string().required(),
  serviceStepId: yup.string().default(null).nullable(),
  serviceClassId: yup.string().default(null).nullable(),
  sourceLanguageId: yup.string().default(null).nullable(),
  targetLanguageId: yup.string().default(null).nullable(),
  customConstraint: yup.string().default(null).nullable(),
  description: yup.string().default(null).nullable(),
  price: yup
    .object()
    .required()
    .shape({
      expressPrice: yup.number().default(null).nullable(),
      price: yup.number().required(),
      currency: yup.string().default(null).nullable()
    })
});

export const supplierPriceView = buildDTOView<SupplierPriceDTO>()
  .all()
  .withSchema(validationSchema);

export function SupplierPriceForm({ supplier }: { supplier: Supplier }) {
  const { t } = useTranslation();
  const [, priceUnitIdMeta] = useTypedField(supplierPriceView.path.priceUnitId);
  const [, serviceClassMeta] = useTypedField(
    supplierPriceView.path.serviceClassId
  );
  const { priceUnit } = usePriceUnit(priceUnitIdMeta.value);

  const { serviceClass } = useServiceClass(serviceClassMeta.value);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormikEntitySelect
          textFieldProps={{ label: t('Unit'), required: true }}
          entityApi={priceUnitsAPI}
          optionLabel={(value) => value.name}
          name={supplierPriceView.path.priceUnitId}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormikEntitySelect
          textFieldProps={{
            label: t('Service Step'),
            required: priceUnit?.type === 'amount'
          }}
          filter={(value) =>
            !!(
              supplier.serviceSteps &&
              supplier.serviceSteps.find((s) => s.serviceStepId === value.id)
            )
          }
          entityApi={serviceStepAPI}
          optionLabel={(value) => value.name}
          name={supplierPriceView.path.serviceStepId}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <FormikPriceInput
          required
          amountPath={supplierPriceView.path.price.price}
          label={t('Price')}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <FormikPriceInput
          amountPath={supplierPriceView.path.price.expressPrice}
          label={t('ExpressPrice')}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <FormikCurrencySelect
          textFieldProps={{ label: t('Currency') }}
          name={supplierPriceView.path.price.currency}
          nullable
          withoutToggle
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormikTextField
          label={t('Description')}
          name={supplierPriceView.path.description}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="body2" color="textSecondary">
          {t('Conditions (optional)')}:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <FormikEntitySelect
          textFieldProps={{ label: t('Service Class') }}
          entityApi={serviceClassAPI}
          optionLabel={(value) => value.name}
          name={supplierPriceView.path.serviceClassId}
        />
      </Grid>

      <Grid item xs={12} container spacing={1}>
        {(!serviceClass || serviceClass.type === 'source-target') && (
          <Grid item xs={12} sm={5} md={5} lg={5}>
            <FormikLanguageSelect
              name={supplierPriceView.path.sourceLanguageId}
              label={t('Source Language')}
            />
          </Grid>
        )}
        {(!serviceClass || serviceClass.type !== 'no-language') && (
          <>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center'
              }}
            >
              <ArrowForward />
            </Grid>

            <Grid item xs={12} sm={5} md={5} lg={5}>
              <FormikLanguageSelect
                name={supplierPriceView.path.targetLanguageId}
                label={t('Target Language')}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormikTextField
            label={t('Custom Constraint')}
            name={supplierPriceView.path.customConstraint}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
