import { Address, AddressDTO, permissions, Supplier } from 'model';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Edit as EditIcon } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { omit } from 'lodash';
import React from 'react';
import { suppliersThunk } from '../../redux/suppliers.thunk';
import {
  ActionsMenu,
  useActionMenuItems
} from '../../../../components/actions/ActionsMenu';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { useAppDispatch } from '../../../../redux/redux.hooks';

export function SupplierAddressActions({
  supplier,
  address
}: {
  supplier: Supplier;
  address: Address;
}) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { reference } = useEditorLocation();

  const menuItems = useActionMenuItems([
    {
      entry: {
        icon: EditIcon,
        label: 'Edit',
        link: `${url}?type=edit#${address.id}`
      },
      permissions: permissions.suppliers.supplier.edit
    },
    {
      permissions: permissions.suppliers.supplier.delete,
      entry: {
        icon: DeleteIcon,
        label: 'Remove',

        onClick: () => {
          dispatch(
            suppliersThunk.update({
              id: supplier.id,
              input: {
                addresses: supplier.addresses
                  .filter((a) => a.id !== address.id)
                  .map(
                    (val) =>
                      ({
                        ...omit(val, 'supplier'),
                        countryCode: val.country
                      } as AddressDTO)
                  )
              }
            })
          );
          if (address.id === reference) {
            history.replace(url);
          }
        }
      }
    }
  ]);

  return <ActionsMenu items={menuItems} />;
}
