"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestPatchTransform = exports.RequestPatchDTO = void 0;
const ProjectRequestDTO_1 = require("./ProjectRequestDTO");
const partial_type_helper_1 = require("../../extern-libs/mapped-types/partial-type.helper");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const ProjectRequest_1 = require("../entity/ProjectRequest");
class RequestPatchDTO extends (0, partial_type_helper_1.PartialType)(ProjectRequestDTO_1.ProjectRequestDTO) {
}
exports.RequestPatchDTO = RequestPatchDTO;
exports.requestPatchTransform = (0, DTOTransformer_1.createDTOTransformer)(ProjectRequest_1.ProjectRequest, RequestPatchDTO, [
    'id',
    'createdAt',
    'updatedAt',
    'currentOffer',
    'offers',
    'positions',
    'resources',
    'requestStatus',
    'comments',
    'requestSource',
    'currentOfferId',
    'project'
], {});
