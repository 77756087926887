"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Permission = void 0;
class Permission {
    constructor(name, enabled, parent) {
        this.name = name;
        this.enabled = enabled;
        this.children = [];
        this.name = name;
        this.parent = parent;
    }
    getFullQualifiedName() {
        return this.parent
            ? `${this.parent.getFullQualifiedName()}.${this.name}`
            : this.name;
    }
    getPath() {
        var _a, _b;
        return [this].concat(((_b = (_a = this.parent) === null || _a === void 0 ? void 0 : _a.getPath()) !== null && _b !== void 0 ? _b : []));
    }
    getHierarchicalNames() {
        return this.getPath().map((p) => p.getFullQualifiedName());
    }
    getIncludedNames() {
        return [this.getFullQualifiedName()].concat(this.children.flatMap((c) => c.getIncludedNames()));
    }
}
exports.Permission = Permission;
