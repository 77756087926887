"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestDataParser = exports.RequestImportData = exports.initialAcquisitionState = exports.requestStatusMapping = exports.requestImportStates = void 0;
const csv_columns_descriptor_1 = require("../../csv/csv-columns.descriptor");
const column_descriptor_1 = require("../../csv/column-descriptor");
exports.requestImportStates = [
    'Angebot bestätigt',
    'Angebot abgelehnt',
    'Angebot unsererseits abgelehnt',
    'warten auf Antwort',
    'Keine Antwort',
    'erfolgreich akquiriert',
    'verschoben auf später'
];
exports.requestStatusMapping = {
    'Angebot abgelehnt': 'Rejected',
    'Angebot bestätigt': 'Accepted',
    'Angebot unsererseits abgelehnt': 'Withdrawn',
    'Keine Antwort': 'No answer',
    'erfolgreich akquiriert': 'Accepted',
    'verschoben auf später': 'Postponed',
    'warten auf Antwort': 'Waiting'
};
exports.initialAcquisitionState = 'erfolgreich akquiriert';
class RequestImportData {
}
exports.RequestImportData = RequestImportData;
const requestDataParser = (supplierSource, clientSource) => {
    return (csv_columns_descriptor_1.CSVColumnBuilder.create()
        .skip(1)
        .append({
        clientName: column_descriptor_1.ColumnDescriptor.create().withValidation((d) => d !== ''),
        clientNumber: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((d) => /^[0-9]{5}$/.test(d) && clientSource(d) !== null)
            .orEmpty(null),
        requestSource: column_descriptor_1.ColumnDescriptor.create().withValidation((d) => d !== '')
    })
        .skip(1)
        .append({
        projectManagers: column_descriptor_1.ColumnDescriptor.createList(',').withValidation((d) => d.every((v) => /^[0-9]{3}-[0-9]{3}$/.test(v) && supplierSource(v) !== null)),
        dateOfRequest: column_descriptor_1.ColumnDescriptor.create().withValidation((v) => /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/.test(v)),
        dateOfOffer: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((v) => /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/.test(v))
            .orEmpty(null),
        dateOfOrder: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((v) => /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/.test(v))
            .orEmpty(null),
        isClient: column_descriptor_1.ColumnDescriptor.create().valueMap({ ja: true, nein: false }),
        status: column_descriptor_1.ColumnDescriptor.create().withValidation((d) => exports.requestImportStates.includes(d)),
        projectNumber: column_descriptor_1.ColumnDescriptor.create()
            .withValidation((d) => /^[0-9]{5}-[0-9]{2}-[0-9]{2,4}$/.test(d))
            .orEmpty(null),
        comments: column_descriptor_1.ColumnDescriptor.create().orEmpty(null)
    })
        // .skip(28)
        .build({
        validateData: (data) => {
            /*        const projectNumbers = data
            .map((d) => d.projectNumber)
            .filter((d) => !!d); */
            const numberMap = {};
            data.forEach((d, i) => {
                if (d.projectNumber) {
                    if (!numberMap[d.projectNumber]) {
                        numberMap[d.projectNumber] = [];
                    }
                    numberMap[d.projectNumber].push(i);
                }
            });
            return Object.entries(numberMap)
                .filter(([, n]) => n.length > 1)
                .map(([, r]) => ({
                rows: r,
                cells: ['projectNumber'],
                message: `Duplicate project number`
            }));
        },
        validateRow: (data) => {
            const result = [];
            if (data.isClient === !data.clientNumber) {
                result.push({
                    cells: ['clientNumber', 'isClient'],
                    message: 'inconsistent client status information'
                });
            }
            const confirmed = [
                'Angebot bestätigt',
                'erfolgreich akquiriert'
            ].includes(data.status);
            if (confirmed === !data.projectNumber) {
                result.push({
                    cells: ['projectNumber', 'status'],
                    message: 'status inconsistency'
                });
            }
            const offerStatus = [
                'Angebot abgelehnt',
                'Angebot unsererseits abgelehnt',
                'Angebot bestätigt',
                'erfolgreich akquiriert',
                'Keine Antwort'
            ].includes(data.status);
            if (offerStatus === !data.dateOfOffer &&
                data.status !== 'warten auf Antwort') {
                result.push({
                    cells: ['status', 'dateOfOffer'],
                    message: 'status inconsistency (no offer date)'
                });
            }
            /*        if (confirmed === !data.dateOfOffer) {
            result.push({
              cells: ['status', 'dateOfOffer'],
              message: 'status inconsistency'
            });
          } */
            if (confirmed === !data.dateOfOrder) {
                result.push({
                    cells: ['status', 'dateOfOrder'],
                    message: 'status inconsistency (no order date)'
                });
            }
            if (!data.dateOfOffer && data.dateOfOrder) {
                result.push({
                    cells: ['dateOfOffer', 'dateOfOrder'],
                    message: 'order date without offer date'
                });
            }
            return result;
        }
    }));
};
exports.requestDataParser = requestDataParser;
