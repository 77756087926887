import React, { ReactNode } from 'react';
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { PropertyPath, useTypedField } from '../../transformer/DTOViewSchema';

type SelectValueType = number | string | string[] | undefined | null;
interface FormikSelectProps {
  name: string | PropertyPath<any, SelectValueType>;
  label?: string;
  multiple?: boolean;
  disabled?: boolean;
  required?: boolean;
  options: { value: any; label: ReactNode }[];
  onChange?: (value: any) => void;
}

export function FormikSelect(props: FormikSelectProps) {
  const [, meta, helpers] = useTypedField<SelectValueType>(props.name);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
    helpers.setValue(event.target.value as SelectValueType);
  };

  const additionalProps = props.multiple
    ? {
        renderValue: (selected: any) => (
          <div>
            {(selected as string[]).map((value) => (
              <Chip key={value} label={value} />
            ))}
          </div>
        )
      }
    : {};
  return (
    <FormControl
      required={props.required}
      fullWidth
      error={Boolean(meta.error)}
    >
      {props.label && (
        <InputLabel id="select-input-label">{props.label}</InputLabel>
      )}
      <Select
        required={props.required}
        labelId="select-input-label"
        fullWidth
        multiple={props.multiple}
        disabled={props.disabled}
        value={meta.value ?? (props.multiple ? [] : null)}
        onChange={handleChange}
        label={props.label}
        {...additionalProps}
      >
        {props.options.map((o) => (
          <MenuItem value={o.value}>{o.label}</MenuItem>
        ))}
      </Select>
      {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
