import React from 'react';
import { useEditorLocation } from '../../../hooks/editor-location.hook';

interface CardSelectionContextType {
  reference?: string | true;
}

// eslint-disable-next-line max-len
export const CardSelectionContext =
  React.createContext<CardSelectionContextType>({});

export function useCardReference() {
  return React.useContext(CardSelectionContext).reference;
}

export function useCardSelection() {
  const { reference: editorReference } = useEditorLocation();
  const currentReference = useCardReference();
  return (
    currentReference === true ||
    (editorReference !== undefined && currentReference === editorReference)
  );
}

export function useCardMode() {
  const { type } = useEditorLocation();
  const selected = useCardSelection();
  return selected ? type : 'view';
}
