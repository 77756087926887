import React from 'react';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import { Switch as RouterSwitch } from 'react-router';
import { ErrorBoundary } from '../error/ErrorBoundary';
import { getUuidUrlParamMatcher } from '../../components/navigation/utils';
import { SuppliersScreen } from './screens/Suppliers.screen';
import { SupplierFormDialog } from './components/SupplierFormDialog';
import { SupplierDetailsScreen } from './screens/SupplierDetails.screen';
import { SupplierDomainsScreen } from './screens/SupplierDomainScreen';
import {
  SupplierDomainEditFormDialog,
  SupplierDomainFormDialog
} from './components/SupplierDomainFormDialog';
import {
  SupplierQualificationEditFormDialog,
  SupplierQualificationFormDialog
} from './components/SupplierQualificationFormDialog';
import { SupplierQualificationsScreen } from './screens/SupplierQualificationsScreen';
import {
  DocumentTypeEditFormDialog,
  DocumentTypeFormDialog
} from './components/DocumentTypeFormDialog';
import { DocumentTypeScreen } from './screens/DocumentTypeScreen';
import { SupplierStatusTypesScreen } from './screens/SupplierStatusTypes.screen';
import {
  SupplierTestTypeEditFormDialog,
  SupplierTestTypeFormDialog
} from './components/TestTypesFormDialog';
import { SupplierTestTypesScreen } from './screens/SupplierTestTypes.screen';
import { SupplierImportScreen } from './screens/SupplierImport.screen';
import { KontextorError } from '../error/KontextorError';
import suppliersSlice, { suppliersSelectors } from './redux/suppliers.slice';
import {
  SupplierStatusTypeEditDialog,
  SupplierStatusTypeFormDialog
} from './components/status/SupplierStatusTypeFormDialog';

export function SuppliersFeature() {
  const { path } = useRouteMatch();

  return (
    <ErrorBoundary context="suppliersFeature">
      <KontextorError
        clear={suppliersSlice.actions.clearError}
        selector={suppliersSelectors.selectError}
      >
        <SupplierFormDialog />
        <SupplierDomainFormDialog />
        <SupplierDomainEditFormDialog />
        <SupplierQualificationFormDialog />
        <SupplierQualificationEditFormDialog />
        <DocumentTypeFormDialog />
        <DocumentTypeEditFormDialog />
        <SupplierStatusTypeEditDialog />
        <SupplierStatusTypeFormDialog />
        <SupplierTestTypeFormDialog />
        <SupplierTestTypeEditFormDialog />
        <RouterSwitch>
          <Route path={path} exact>
            <Redirect to={`${path}/suppliers`} />
          </Route>
          <Route
            path={`${path}/supplier/${getUuidUrlParamMatcher('supplierId')}`}
          >
            <SupplierDetailsScreen />
          </Route>
          <Route path={`${path}/suppliers/import`}>
            <SupplierImportScreen />
          </Route>
          <Route path={`${path}/suppliers`}>
            <SuppliersScreen />
          </Route>
          <Route path={`${path}/domains`}>
            <SupplierDomainsScreen />
          </Route>
          <Route path={`${path}/qualifications`}>
            <SupplierQualificationsScreen />
          </Route>
          <Route path={`${path}/document-types`}>
            <DocumentTypeScreen />
          </Route>
          <Route path={`${path}/status-types`}>
            <SupplierStatusTypesScreen />
          </Route>
          <Route path={`${path}/test-types`}>
            <SupplierTestTypesScreen />
          </Route>
        </RouterSwitch>
      </KontextorError>
    </ErrorBoundary>
  );
}
