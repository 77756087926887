import React from 'react';
import { ActiveStatusType, Supplier, SupplierStatusDTO } from 'model';
import * as yup from 'yup';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import suppliersSlice, {
  suppliersSelectors
} from '../../redux/suppliers.slice';
import { useAppDispatch } from '../../../../redux/redux.hooks';
import { FormikSelect } from '../../../../components/select/FormikSelect';
import { changeSupplierStatusThunk } from '../../redux/suppliers.thunk';
import { NoOp } from '../../../../utils';
import { useStatusTypes } from '../../hooks/status-types.hook';
import { useEditorLocation } from '../../../../hooks/editor-location.hook';
import { FormikDatePicker } from '../../../../components/input/date/FormikDatePicker';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { useSupplierActivation } from './supplierInactivation.hook';

const initialValues: SupplierStatusDTO = {
  statusTypeId: '',
  explanation: null,
  until: null
};

// Todo typing
const validationSchema = yup.object({
  statusTypeId: yup.string().uuid().required(),
  until: yup.date().nullable().default(null),
  explanation: yup.string().nullable().default(null)
});

interface SupplierInactivationFormDialogProps {
  supplier: Supplier;
}

export function SupplierInactivationFormDialog({
  supplier
}: SupplierInactivationFormDialogProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const statusTypes = useStatusTypes();

  const { reference } = useEditorLocation();

  const supplierActivationErrors = useSupplierActivation(supplier);

  const createSupplierInactivation = (dto: SupplierStatusDTO) =>
    dispatch(changeSupplierStatusThunk({ id: supplier.id, value: dto }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <DialogForm
      label={reference === 'activation' ? 'Activation' : 'Inactivation'}
      identifier={
        reference === 'activation' || reference === 'inactivation'
          ? reference
          : '_'
      }
      form={{
        initialValues,
        validationSchema,
        customValidator: (activation) => {
          if (
            reference !== 'activation' ||
            supplierActivationErrors.length < 1
          ) {
            return true;
          }
          const statusType = activation.statusTypeId
            ? statusTypes.entities?.find(
                (sT) => sT.id === activation.statusTypeId
              )
            : undefined;

          return !(
            !statusType ||
            ((statusType as ActiveStatusType).enforceActivationConstraints &&
              supplierActivationErrors.length > 0)
          );
        }
      }}
      api={{
        stateSelector: suppliersSelectors.selectState,
        errorSelector: suppliersSelectors.selectError,
        onSubmit: createSupplierInactivation,
        clearError: suppliersSlice.actions.clearError
      }}
      actions={[
        {
          label: reference === 'activation' ? t('Activate') : t('Inactivate'),
          doSubmit: true
        }
      ]}
    >
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      {({ values, setFieldValue }) => {
        const statusType = values.statusTypeId
          ? statusTypes.entities?.find((sT) => sT.id === values.statusTypeId)
          : undefined;
        const expireType = statusType?.expireType;
        const explanationType = statusType?.explanationType;

        return (
          <Grid container spacing={1} xs={12}>
            <Grid container item xs={6}>
              <Grid item xs={12}>
                {statusTypes.entities ? (
                  <FormikSelect
                    required
                    onChange={() => {
                      setFieldValue('until', null, false);
                      setFieldValue('explanation', null, false);
                    }}
                    name="statusTypeId"
                    label="Reason"
                    options={statusTypes.entities
                      .filter(
                        (sT) =>
                          (sT.statusType === 'active' &&
                            reference === 'activation') ||
                          (sT.statusType === 'inactive' &&
                            reference === 'inactivation')
                      )
                      .map((sT) => ({
                        value: sT.id,
                        label: sT.name
                      }))}
                  />
                ) : (
                  <CircularProgress />
                )}
              </Grid>
              {expireType && expireType !== 'never' && (
                <Grid item xs={12}>
                  <FormikDatePicker name="until" label="Until" />
                </Grid>
              )}
              {explanationType && explanationType !== 'never' && (
                <Grid item xs={12}>
                  <FormikTextField label="Explanation" name="explanation" />
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              {reference === 'activation' &&
                supplierActivationErrors.length > 0 &&
                (statusType as ActiveStatusType | undefined)
                  ?.enforceActivationConstraints === true &&
                supplierActivationErrors.map((ae) => (
                  <Typography>
                    <li>{ae}</li>
                  </Typography>
                ))}
            </Grid>
          </Grid>
        );
      }}
    </DialogForm>
  );
}
