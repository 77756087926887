"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectExpense = void 0;
const typeorm_1 = require("typeorm");
const JournaledEntity_1 = require("../../JournaledEntity");
// eslint-disable-next-line import/no-cycle
const Project_1 = require("./Project");
// eslint-disable-next-line import/no-cycle
const OrderStepTask_1 = require("../../project-management/entity/OrderStepTask");
// eslint-disable-next-line import/no-cycle
const RequestComment_1 = require("./RequestComment");
// eslint-disable-next-line import/no-cycle
const Supplier_1 = require("../../supplier/entity/Supplier");
let ProjectExpense = class ProjectExpense extends JournaledEntity_1.JournaledEntity {
};
__decorate([
    (0, typeorm_1.ManyToOne)(() => Project_1.Project, (p) => p.projectExpenses),
    __metadata("design:type", Project_1.Project)
], ProjectExpense.prototype, "project", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pc) => pc.project),
    __metadata("design:type", String)
], ProjectExpense.prototype, "projectId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => Supplier_1.Supplier),
    __metadata("design:type", Supplier_1.Supplier)
], ProjectExpense.prototype, "supplier", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pc) => pc.supplier),
    __metadata("design:type", String)
], ProjectExpense.prototype, "supplierId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => OrderStepTask_1.OrderStepTask),
    __metadata("design:type", OrderStepTask_1.OrderStepTask)
], ProjectExpense.prototype, "orderTask", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pc) => pc.orderTask),
    __metadata("design:type", String)
], ProjectExpense.prototype, "orderTaskId", void 0);
__decorate([
    (0, typeorm_1.ManyToOne)(() => RequestComment_1.RequestComment, undefined, { nullable: true }),
    __metadata("design:type", RequestComment_1.RequestComment)
], ProjectExpense.prototype, "comment", void 0);
__decorate([
    (0, typeorm_1.RelationId)((pc) => pc.comment),
    __metadata("design:type", String)
], ProjectExpense.prototype, "commentId", void 0);
__decorate([
    (0, typeorm_1.Column)({ nullable: true }),
    __metadata("design:type", Number)
], ProjectExpense.prototype, "plannedMinutes", void 0);
__decorate([
    (0, typeorm_1.Column)(),
    __metadata("design:type", Number)
], ProjectExpense.prototype, "expensedMinutes", void 0);
ProjectExpense = __decorate([
    (0, typeorm_1.Entity)()
], ProjectExpense);
exports.ProjectExpense = ProjectExpense;
