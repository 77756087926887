"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalTestTypeTransformer = exports.NormalTestTypeDTO = void 0;
const class_validator_1 = require("class-validator");
const class_transformer_1 = require("class-transformer");
const SupplierTestTypeDTO_1 = require("./SupplierTestTypeDTO");
const TestTypeStateDTO_1 = require("./TestTypeStateDTO");
const TestResultStateDTO_1 = require("./TestResultStateDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const NormalTestType_1 = require("../entity/NormalTestType");
const TestTypeState_1 = require("../entity/TestTypeState");
const TestResultState_1 = require("../entity/TestResultState");
class NormalTestTypeDTO extends SupplierTestTypeDTO_1.SupplierTestTypeDTO {
}
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => TestTypeStateDTO_1.TestTypeStateDTO),
    __metadata("design:type", Array)
], NormalTestTypeDTO.prototype, "testStates", void 0);
__decorate([
    (0, class_validator_1.IsArray)(),
    (0, class_validator_1.ValidateNested)({ each: true }),
    (0, class_transformer_1.Type)(() => TestResultStateDTO_1.TestResultStateDTO),
    __metadata("design:type", Array)
], NormalTestTypeDTO.prototype, "resultStates", void 0);
exports.NormalTestTypeDTO = NormalTestTypeDTO;
exports.normalTestTypeTransformer = (0, DTOTransformer_1.createDTOTransformer)(NormalTestType_1.NormalTestType, NormalTestTypeDTO, ['id', 'createdAt', 'updatedAt'], {
    testStates: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createDTOTransformer)(TestTypeState_1.TestTypeState, TestTypeStateDTO_1.TestTypeStateDTO, ['testType', 'createdAt', 'updatedAt', 'testTypeId'], {}), 'testStates'),
    resultStates: (0, DTOTransformer_1.nestedArrayTransform)((0, DTOTransformer_1.createDTOTransformer)(TestResultState_1.TestResultState, TestResultStateDTO_1.TestResultStateDTO, ['testType', 'createdAt', 'updatedAt', 'testTypeId'], {}), 'resultStates')
});
