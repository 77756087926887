import { ProjectRequest, RequestPosition } from 'model';
import {
  createAdapterSelectors,
  createEntitySlice,
  CustomThunk
} from '../../../redux/enitySlice';
import { RootState } from '../../../redux/store';
import { projectRequestThunk } from './project-requests.thunk';
import { requestPositionThunk } from './request-positions.thunk';

const { list, delete: deleteThunk, ...thunks } = requestPositionThunk;

const requestPositionsSlice = createEntitySlice<RequestPosition>(
  'request-positions',
  [
    CustomThunk.override<ProjectRequest, RequestPosition>(
      projectRequestThunk.get,
      {
        fulfilled: (adapter, state, { payload }) => {
          return adapter.upsertMany(
            state,
            (payload as ProjectRequest).positions
          );
        }
      }
    ) as any,
    ...Object.values(thunks)
  ]
);

export const requestPositionSelectors = {
  ...createAdapterSelectors('request-positions', requestPositionsSlice.adapter),
  selectByRequest(state: RootState, requestId: string) {
    return this.selectAll(state).filter((a) => a.requestId === requestId);
  }
};

export default requestPositionsSlice;
