import { InitialDataAggregation } from 'model';
import { createErrorHandlingThunk } from './thunk';

// eslint-disable-next-line max-len
export const fetchInitialDataThunk = createErrorHandlingThunk<
  InitialDataAggregation,
  InitialDataAggregation
>('initial-data/fetch', async (data: InitialDataAggregation) => {
  return Promise.resolve(data);
});

/* // eslint-disable-next-line max-len
export const fetchInitialDataThunk =
  createErrorHandlingThunk<InitialDataAggregation>(
  'initial-data/fetch',
  async () => {
    return (await apiClient.get(`/app/initial-data`)).data;
  }
); */
