import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';
import {
  Badge,
  Box,
  ButtonBase,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { MdHistory } from 'react-icons/md';
import { CgComment } from 'react-icons/cg';
import { BsChevronExpand } from 'react-icons/bs';
import { AiOutlineStar } from 'react-icons/ai';
import {
  AddCircleOutline,
  Check,
  Close as CancelIcon,
  CommentOutlined,
  MailOutline,
  ThumbDown,
  ThumbUp
} from '@material-ui/icons';
import React from 'react';
import { Project, ProjectCandidate, RequestComment } from 'model';
import { compareDesc } from 'date-fns';
import { IoMdTrash } from 'react-icons/io';
import { useCommentsOfRequest } from '../../hooks/request-comments.hook';
import { useSupplier } from '../../../suppliers/hooks/supplier.hook';
import { CandidateActions } from './CandidateDisplay';
import { formatDate } from '../../../../localized-formats';
import { useUser } from '../../../users/hooks/user.hook';
import { commentProjectCandidateThunk } from '../../redux/projects.thunk';
import { useAppDispatch } from '../../../../redux/redux.hooks';

export type TimelineState = 'hidden' | 'partial' | 'full';
export type TimelineTransitions = Record<
  TimelineState,
  { expand?: TimelineState; collapse?: TimelineState }
>;
const TimelineTransitionMap: TimelineTransitions = {
  full: {
    collapse: 'hidden'
  },
  partial: {
    collapse: 'hidden',
    expand: 'full'
  },
  hidden: {
    expand: 'full'
  }
};
export type CandidateTimelineEntry =
  | {
      type: 'action';
      action: 'created' | 'contacted' | 'accepted' | 'declined' | 'removed';
      date: Date | string;
    }
  | {
      type: 'comment';
      comment: RequestComment;
      date: Date | string;
    };

const EntryIcon = {
  contacted: <MailOutline style={{ fontSize: '1em' }} />,
  accepted: <ThumbUp htmlColor="green" style={{ fontSize: '1em' }} />,
  declined: <ThumbDown htmlColor="red" style={{ fontSize: '1em' }} />,
  removed: <IoMdTrash style={{ fontSize: '1em' }} />,
  created: <AiOutlineStar fontSize="1em" />
};
export function CandidateCommentItem({
  entry
}: {
  entry: CandidateTimelineEntry;
}) {
  const { user } = useUser(
    entry.type === 'comment' ? entry.comment.authorId : null
  );
  return (
    <TimelineItem style={{ minHeight: '45px' }}>
      <TimelineOppositeContent
        style={{ flex: 'unset', padding: 0, display: 'block' }}
      />
      <TimelineSeparator>
        <TimelineDot style={{ margin: 0 }}>
          {entry.type === 'comment' ? (
            <CommentOutlined style={{ fontSize: '1em' }} />
          ) : (
            EntryIcon[entry.action]
          )}
        </TimelineDot>
        <TimelineConnector style={{ flexGrow: 1 }} />
      </TimelineSeparator>
      <TimelineContent style={{ padding: '0 1em' }}>
        <Typography variant="caption">
          {formatDate(entry.date, 'date-time')}{' '}
          {user ? user.displayName ?? user.email : ''}
          {entry.type === 'action' && entry.action}
        </Typography>
        {entry.type === 'comment' && (
          <Typography variant="subtitle1">{entry.comment.comment}</Typography>
        )}
      </TimelineContent>
    </TimelineItem>
  );
}
export function CandidateCommentEditor({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  candidate,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  project,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hideEditor
}: {
  candidate: ProjectCandidate;
  project: Project;
  hideEditor: (saved: boolean) => void;
}) {
  const dispatch = useAppDispatch();
  const [commentValue, setCommentValue] = React.useState('');

  return (
    <Badge
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <Box
          display="flex"
          border="1px solid rgba(100,100,100,0.5)"
          borderRadius="2em"
          style={{ backgroundColor: 'rgba(230,230,230,0.9)' }}
        >
          <IconButton
            onClick={() => hideEditor(false)}
            size="small"
            style={{ padding: '1px' }}
          >
            <CancelIcon fontSize="small" />
          </IconButton>
          <IconButton
            style={{ padding: '1px' }}
            onClick={() => {
              dispatch(
                commentProjectCandidateThunk({
                  projectId: project.id,
                  candidateId: candidate.id,
                  dto: {
                    comment: commentValue
                  }
                })
              );
              setCommentValue('');
              hideEditor(true);
            }}
            size="small"
          >
            <Check fontSize="small" />
          </IconButton>
        </Box>
      }
      style={{ flexGrow: 1 }}
    >
      <TextField
        inputProps={{
          style: {
            padding: '2px',
            fontSize: '0.75em'
          }
        }}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          style: {
            padding: '0.2em'
          }
        }}
        autoFocus
        variant="outlined"
        placeholder=""
        fullWidth
        multiline
        rows={3}
        style={{ padding: 0 }}
        onChange={(e) => setCommentValue(e.target.value)}
        value={commentValue}
      />
    </Badge>
  );
}
export function CandidateTimeline({
  candidate,
  project,
  initialTimelineState = 'hidden'
}: {
  candidate: ProjectCandidate;
  project: Project;
  initialTimelineState?: TimelineState;
}) {
  const { comments } = useCommentsOfRequest(project.requestId as string);
  const candidateComments = React.useMemo(
    () => comments.filter((c) => c.candidateId === candidate.id),
    [comments, candidate.id]
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { supplier } = useSupplier(candidate.supplierId);

  const candidateEntries = React.useMemo(
    () =>
      (
        [
          { type: 'action', action: 'created', date: candidate.createdAt },
          candidate.contactedOn
            ? {
                type: 'action',
                action: 'contacted',
                date: candidate.contactedOn
              }
            : undefined,
          candidate.decision
            ? {
                type: 'action',
                action:
                  candidate.decision.status === 'confirmed'
                    ? 'accepted'
                    : 'declined',
                date: candidate.decision.createdAt
              }
            : undefined,
          candidate.removedOn
            ? { type: 'action', action: 'removed', date: candidate.removedOn }
            : undefined,
          ...candidateComments.map((comment) => ({
            type: 'comment',
            comment,
            date: comment.createdAt
          }))
        ] as CandidateTimelineEntry[]
      )
        .filter((val) => val !== undefined)
        .sort((a, b) => compareDesc(new Date(a.date), new Date(b.date))),
    [candidateComments, candidate]
  );
  const [timelineState, setTimelineState] =
    React.useState<TimelineState>(initialTimelineState);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showCommentEditor, setShowCommentEditor] = React.useState(false);

  const currentTransitions = React.useMemo(
    () => TimelineTransitionMap[timelineState],
    [timelineState]
  );
  return (
    <Timeline align="left" style={{ paddingTop: 0, paddingBottom: 0 }}>
      <TimelineItem
        style={{ minHeight: timelineState === 'hidden' ? 0 : '45px' }}
      >
        <TimelineOppositeContent
          style={{ flex: 'unset', padding: 0, display: 'block' }}
        />
        <TimelineSeparator>
          <ButtonBase
            title={
              timelineState !== 'hidden' ? 'Hide history' : 'Expand history'
            }
            disabled={
              !currentTransitions.expand && !currentTransitions.collapse
            }
            onClick={() => {
              if (currentTransitions.collapse) {
                setTimelineState(currentTransitions.collapse);
              } else if (currentTransitions.expand) {
                setTimelineState(currentTransitions.expand);
              }
            }}
          >
            <TimelineDot
              variant={timelineState !== 'hidden' ? 'default' : 'outlined'}
              style={{ margin: 0 }}
            >
              <MdHistory />
            </TimelineDot>
          </ButtonBase>
          {timelineState !== 'hidden' && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent style={{ padding: '3px 16px' }}>
          <Box display="flex">
            {showCommentEditor || (
              <>
                {CandidateActions.filter(
                  (ac) => ac.value(candidate) !== undefined
                ).map((ac) => {
                  return (
                    <div
                      title={ac.stateTooltip}
                      style={{
                        fontSize: '0.8em',
                        marginRight: '1.5em',
                        display: 'inline-flex',
                        alignItems: 'center',
                        alignContent: 'center'
                      }}
                    >
                      {ac.icon}{' '}
                      <span style={{ marginLeft: '0.6em' }}>
                        {ac.value(candidate)}
                      </span>
                    </div>
                  );
                })}
                <Box display="flex">
                  <ButtonBase
                    title="Add Comment"
                    disabled={!!candidate.removedOn}
                    onClick={() => {
                      setShowCommentEditor(true);
                      setTimelineState(
                        timelineState === 'hidden' ? 'partial' : timelineState
                      );
                    }}
                  >
                    <Box display="flex">
                      <Badge
                        badgeContent={
                          candidateComments.length > 0 ? (
                            <Typography color="textSecondary">
                              {candidateComments.length}
                            </Typography>
                          ) : undefined
                        }
                      >
                        <CgComment />
                        <AddCircleOutline style={{ fontSize: '1em' }} />
                      </Badge>
                    </Box>
                  </ButtonBase>
                </Box>
              </>
            )}
            {showCommentEditor && (
              <CandidateCommentEditor
                hideEditor={() => setShowCommentEditor(false)}
                candidate={candidate}
                project={project}
              />
            )}
          </Box>
        </TimelineContent>
      </TimelineItem>
      {timelineState !== 'hidden' && (
        <>
          {candidateEntries
            .slice(0, timelineState === 'partial' ? 1 : undefined)
            .map((entry) => (
              <CandidateCommentItem entry={entry} />
            ))}
          {currentTransitions.expand && (
            <TimelineItem style={{ minHeight: 'unset' }}>
              <TimelineOppositeContent
                style={{ flex: 'unset', padding: 0, display: 'block' }}
              />
              <TimelineSeparator>
                <ButtonBase
                  title="Expand history"
                  onClick={() => {
                    setTimelineState(
                      currentTransitions.expand as TimelineState
                    );
                  }}
                >
                  <TimelineDot style={{ margin: 0 }}>
                    <BsChevronExpand fontSize="1em" />
                  </TimelineDot>
                </ButtonBase>
                <TimelineConnector style={{ flexGrow: 1 }} />
              </TimelineSeparator>
              <TimelineContent />
            </TimelineItem>
          )}
        </>
      )}
    </Timeline>
  );
}
