"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NormalTestType = void 0;
const typeorm_1 = require("typeorm");
const SupplierTestType_1 = require("./SupplierTestType");
// eslint-disable-next-line import/no-cycle
const TestTypeState_1 = require("./TestTypeState");
// eslint-disable-next-line import/no-cycle
const TestResultState_1 = require("./TestResultState");
let NormalTestType = class NormalTestType extends SupplierTestType_1.SupplierTestType {
    constructor(init) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
};
__decorate([
    (0, typeorm_1.OneToMany)(() => TestTypeState_1.TestTypeState, (s) => s.testType, {
        cascade: ['insert', 'update']
    }),
    __metadata("design:type", Array)
], NormalTestType.prototype, "testStates", void 0);
__decorate([
    (0, typeorm_1.OneToMany)(() => TestResultState_1.TestResultState, (s) => s.testType, {
        cascade: ['insert', 'update']
    }),
    __metadata("design:type", Array)
], NormalTestType.prototype, "resultStates", void 0);
NormalTestType = __decorate([
    (0, typeorm_1.ChildEntity)('normal'),
    __metadata("design:paramtypes", [Object])
], NormalTestType);
exports.NormalTestType = NormalTestType;
