import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Badge, Box, Chip, IconButton, Popover } from '@material-ui/core';
import { MdOutlineSick } from 'react-icons/md';
import { BsStopCircle } from 'react-icons/bs';
import { Add, Cancel, Check } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {
  AbsenceCover,
  AbsenceDBManager,
  useAbsenceDB
} from '../hooks/absence-cover.hook';
import { useSupplier } from '../hooks/supplier.hook';
import { EntityReferenceSelect } from '../../../components/select/EntityReferenceSelect';
import { supplierApi } from '../redux/suppliers.slice';

export interface AbsenceCoverDisplayProps {
  id: string;
  absenceCover: AbsenceCover;
}

export function AbsenceCoverDisplay({
  absenceCover,
  id
}: AbsenceCoverDisplayProps) {
  const { supplier } = useSupplier(absenceCover.coveredSupplierId || null);
  if (!supplier) {
    return <Skeleton />;
  }
  return (
    <Chip
      icon={<MdOutlineSick />}
      label={`${supplier.name.first} ${supplier.name.last}`}
      deleteIcon={<BsStopCircle />}
      onDelete={() => {
        AbsenceDBManager.remove(id);
      }}
    />
  );
}

function AbsenceSupplierSelector() {
  const [showSelect, setShowSelect] = React.useState<Element | null>(null);
  const [selectedId, setSelectedId] = React.useState<string>();
  const { t } = useTranslation();

  return (
    <>
      <IconButton
        size="small"
        onClick={(evt) => {
          setShowSelect(evt.target as Element);
        }}
      >
        <Badge badgeContent={<Add fontSize="small" />}>
          <MdOutlineSick fontSize="1em" />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={showSelect}
        open={!!showSelect}
        onClose={() => {
          setSelectedId(undefined);
          setShowSelect(null);
        }}
      >
        <Box display="flex" minWidth="25vw">
          <EntityReferenceSelect
            textFieldProps={{ label: t('Select person to cover for sickness') }}
            currentValue={selectedId ?? null}
            entityApi={supplierApi}
            optionLabel={(s) => `${s.name.first} ${s.name.last}`}
            selectValue={(id) => {
              setSelectedId(id ?? undefined);
            }}
            filter={(v) => v.userId !== null}
          />
          <IconButton
            onClick={() => {
              AbsenceDBManager.insert({
                coveredSupplierId: selectedId as string,
                started: new Date(),
                active: true,
                estimatedEnd: null
              });
              setSelectedId(undefined);
              setShowSelect(null);
            }}
            disabled={selectedId === undefined}
            size="small"
          >
            <Check fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setSelectedId(undefined);
              setShowSelect(null);
            }}
            size="small"
          >
            <Cancel fontSize="small" />
          </IconButton>
        </Box>
      </Popover>
    </>
  );
}

export function AbsenceCoverContainer() {
  const currentDB = useAbsenceDB();

  return (
    <Box display="flex" justifyContent="right" marginRight="2rem">
      {Object.entries(currentDB).map(([id, value]) => (
        <AbsenceCoverDisplay id={id} absenceCover={value} />
      ))}
      <AbsenceSupplierSelector />;
    </Box>
  );
}
