import React from 'react';
import {
  Button,
  CircularProgress,
  createStyles,
  DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { DialogFormAction } from '../form/types';

const useStyles = makeStyles((theme) =>
  createStyles({
    dialogActionsContainer: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    cancelButton: {
      marginRight: 'auto'
    }
  })
);

interface DialogFormActionsProps {
  onCancel: () => void;
  actions?: (Omit<DialogFormAction, 'onClick'> & {
    onClick?: () => void | Promise<void>;
  })[];
}

function InternalActionButton({
  onClick,
  label,
  loading,
  setLoading
}: {
  onClick: (() => Promise<void> | void) | undefined;
  label: React.ReactNode;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}) {
  return (
    <Button
      disabled={loading}
      onClick={async () => {
        setLoading(true);
        try {
          if (onClick) {
            await onClick();
          }
        } finally {
          setLoading(false);
        }
      }}
    >
      {label}
    </Button>
  );
}

export function DialogFormActions(props: DialogFormActionsProps) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  return (
    <DialogActions className={classes.dialogActionsContainer}>
      <Button
        className={classes.cancelButton}
        variant="contained"
        color="primary"
        onClick={props.onCancel}
      >
        {t('Cancel')}
      </Button>
      {loading ? (
        <CircularProgress style={{ fontSize: '0.25em' }} />
      ) : undefined}
      {props.actions &&
        props.actions.map((a) => (
          <InternalActionButton
            loading={loading}
            setLoading={setLoading}
            onClick={a.onClick}
            label={a.label}
          />
        ))}
    </DialogActions>
  );
}
