import { Divider, Grid, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { SupplierServiceStepDTO } from 'model';
import { useTranslation } from 'react-i18next';

import { PropertyPath } from '../../../../transformer/DTOViewSchema';
import { FormikTextField } from '../../../../components/input/FormikTextField';
import { FormikSelect } from '../../../../components/select/FormikSelect';
import { useServiceSteps } from '../../../services/hooks/service-steps.hook';

interface SupplierServiceFormElementProps {
  path: PropertyPath<any, SupplierServiceStepDTO>;
  onRemove: () => void;
}

export function SupplierServiceStepFormElement({
  path,
  onRemove
}: SupplierServiceFormElementProps) {
  const { t } = useTranslation();

  const serviceSteps = useServiceSteps();

  return (
    <>
      <Typography variant="caption" color="textSecondary">
        {t('Service Step')}
      </Typography>
      <Grid item xs={12} style={{ display: 'flex' }}>
        <Grid container spacing={1} style={{ paddingRight: 20 }}>
          <Grid item xs={12}>
            <FormikSelect
              name={path.serviceStepId}
              options={serviceSteps.entities
                .filter((s) =>
                  Object.keys(s.assigneeTypes).includes('supplier')
                )
                .map((s) => ({
                  label: s.name,
                  value: s.id
                }))}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'inline-flex', alignItems: 'center' }}
          >
            <FormikTextField
              variant="outlined"
              multiline
              rows={3}
              name={path.comment}
              label={t('Comment')}
            />
          </Grid>
        </Grid>
        <div>
          <IconButton size="small" onClick={onRemove}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider style={{ marginTop: 10, marginBottom: 20 }} />
      </Grid>
    </>
  );
}
