import React from 'react';
import {
  ProjectRequest,
  RequestResource,
  requestResourceTransformer,
  ResourceRequestDTO
} from 'model';
import { unwrapResult } from '@reduxjs/toolkit';
import { NoOp } from '../../../../../utils';
import { EditableListCard } from '../../../../../components/card/editableListCard/EditableListCard';
import {
  RequestResourceForm,
  RequestResourceView
} from './form/RequestResourceForm';
import { RequestResourceDisplay } from './display/RequestResourceDisplay';
import { useAppDispatch } from '../../../../../redux/redux.hooks';
import { requestResourceThunk } from '../../../redux/request-resource.thunk';
import { useResourcesOfRequest } from '../../../hooks/request-resources.hook';

export function RequestResourcesCard({ request }: { request: ProjectRequest }) {
  const dispatch = useAppDispatch();
  const { resources } = useResourcesOfRequest(request.id);
  const updateRequestResource = (input: ResourceRequestDTO, id: string) =>
    dispatch(requestResourceThunk.update({ requestId: request.id, id, input }))
      .then(unwrapResult)
      .catch(NoOp);

  const createRequestResource = (input: ResourceRequestDTO) =>
    dispatch(requestResourceThunk.create({ requestId: request.id, input }))
      .then(unwrapResult)
      .catch(NoOp);

  return (
    <EditableListCard<RequestResource, ResourceRequestDTO>
      title="Resources"
      identifierFactory={(entity) => `resource-${entity.id}`}
      items={
        resources
          ? resources.map((requestResource) => ({
              value: requestResource,
              display: { component: RequestResourceDisplay },
              form: {
                component: () => <RequestResourceForm />,
                validationSchema: RequestResourceView.validationSchema,
                onSubmit: (values) =>
                  updateRequestResource(values, requestResource.id),
                viewFactory: RequestResourceView.viewFactory,
                dtoTransformer: requestResourceTransformer((s) => btoa(s))
              }
            }))
          : []
      }
      creator={{
        type: 'creator',
        title: 'Add Request',
        initialValueFactory: () =>
          Object.assign(new RequestResource(), {
            available: false,
            description: ''
          }),
        component: () => <RequestResourceForm />,
        validationSchema: RequestResourceView.validationSchema,
        onSubmit: createRequestResource,
        viewFactory: RequestResourceView.viewFactory,
        dtoTransformer: requestResourceTransformer((s) => btoa(s))
      }}
    />
  );
}
