"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IsNullable = void 0;
const class_validator_1 = require("class-validator");
function IsNullable(validationOptions) {
    return function nullableDecorator(
    // eslint-disable-next-line @typescript-eslint/ban-types
    object, propertyName) {
        (0, class_validator_1.registerDecorator)({
            name: class_validator_1.ValidationTypes.CONDITIONAL_VALIDATION,
            target: object.constructor,
            propertyName,
            constraints: [
                (innerObject) => {
                    return innerObject[propertyName] !== null;
                }
            ],
            options: validationOptions,
            validator: () => true
        });
    };
}
exports.IsNullable = IsNullable;
