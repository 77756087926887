import { PatchClientDTO, Client } from 'model';
import { createErrorHandlingThunk } from '../../../../redux/thunk';
import { apiClient } from '../../../../api/apiClient';

export const patchClientThunk = createErrorHandlingThunk<
  Client,
  { id: string; dto: Partial<PatchClientDTO> }
>('client/patch', async (args) => {
  const result = await apiClient.patch<Client>(`/client/${args.id}`, args.dto);
  const clientResult = await apiClient.get<Client>(`/client/${result.data.id}`);
  return clientResult.data;
});
