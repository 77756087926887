"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.simpleTestTypeTransformer = exports.SimpleTestTypeDTO = void 0;
const SupplierTestTypeDTO_1 = require("./SupplierTestTypeDTO");
const DTOTransformer_1 = require("../../transformation/DTOTransformer");
const SimpleTestType_1 = require("../entity/SimpleTestType");
class SimpleTestTypeDTO extends SupplierTestTypeDTO_1.SupplierTestTypeDTO {
}
exports.SimpleTestTypeDTO = SimpleTestTypeDTO;
exports.simpleTestTypeTransformer = (0, DTOTransformer_1.createDTOTransformer)(SimpleTestType_1.SimpleTestType, SimpleTestTypeDTO, ['id', 'createdAt', 'updatedAt'], {});
