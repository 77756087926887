import { ProjectTagDTO, projectTagTransformer } from 'model';
import { useTranslation } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/redux.hooks';
import { NoOp } from '../../../../utils';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import projectTagsSlice, {
  projectTagSelectors
} from '../../redux/project-tag.slice';
import { projectTagThunk } from '../../redux/project-tag.thunk';
import {
  ProjectTagBasicForm,
  ProjectTagBasicView
} from './ProjectTagBasicForm';
import { CardWithHeader } from '../../../../components/card/cardWithHeader/CardWithHeader';

const initialValues: ProjectTagDTO = {
  name: '',
  description: ''
};

export function ProjectTagFormDialog({ tagId }: { tagId?: string }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const projectTag = useAppSelector((state) =>
    projectTagSelectors.selectById(state, tagId ?? '')
  );

  const projectTagDTO = projectTag
    ? projectTagTransformer(projectTag)
    : undefined;

  const createProjectTag = (dto: ProjectTagDTO) => {
    if (projectTag) {
      return dispatch(
        projectTagThunk.update({
          id: projectTag.id,
          input: dto
        })
      )
        .then(unwrapResult)
        .catch(NoOp);
    }
    return dispatch(
      projectTagThunk.create({
        input: dto
      })
    )
      .then(unwrapResult)
      .catch(NoOp);
  };

  return (
    <DialogForm
      identifier={projectTag?.id ?? 'project-tag'}
      maxWidth="md"
      label={projectTag?.id ? t('Edit Project Tag') : t('New Project Tag')}
      form={{
        initialValues: projectTagDTO ?? initialValues,
        validationSchema: ProjectTagBasicView.validationSchema
      }}
      api={{
        clearError: projectTagsSlice.actions.clearError,
        errorSelector: projectTagSelectors.selectError,
        onSubmit: createProjectTag,
        stateSelector: projectTagSelectors.selectState
      }}
      actions={[{ label: t('Save and Exit'), doSubmit: true }]}
    >
      <CardWithHeader title="Basic">
        <ProjectTagBasicForm />
      </CardWithHeader>
    </DialogForm>
  );
}
