import React from 'react';
import { Box, ButtonBase, InputBase, InputBaseProps } from '@material-ui/core';
import { TiMinus, TiPlus } from 'react-icons/ti';
import { PropertyPath, useTypedField } from '../../transformer/DTOViewSchema';

export function FormikAmountInput({
  name,
  textFieldProps,
  min = 1,
  max
}: {
  name: PropertyPath<any, number> | string;
  textFieldProps?: Partial<InputBaseProps>;
  min?: number;
  max?: number;
}) {
  const [, meta, helpers] = useTypedField<number>(name);
  return (
    <InputBase
      value={`${meta.value}`}
      onChange={(evt) => {
        const value = Number(evt.target.value);
        if (
          Number.isInteger(value) &&
          value >= min &&
          (max === undefined || value <= max)
        ) {
          helpers.setValue(value);
        } else if (evt.target.value.trim() === '') {
          helpers.setValue(0);
        }
      }}
      margin="none"
      endAdornment={
        <>
          <Box display="flex" position="relative" width="15px">
            <Box position="absolute" top="-0.9em">
              <ButtonBase
                onClick={() => {
                  helpers.setValue(meta.value + 1);
                }}
              >
                <TiPlus fontSize="0.9em" />
              </ButtonBase>
            </Box>
            <Box position="absolute" top="-0.1em">
              <ButtonBase
                onClick={() => {
                  helpers.setValue(meta.value - 1);
                }}
              >
                <TiMinus fontSize="0.9em" />
              </ButtonBase>
            </Box>
          </Box>
        </>
      }
      inputProps={{
        style: {
          padding: 0,
          textAlign: 'right',
          borderBottom: '1px dotted'
        }
      }}
      {...textFieldProps}
    />
  );
}
