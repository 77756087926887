import React from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { FormikTextField } from '../../../components/input/FormikTextField';
import { FormikCountrySelect } from '../../../components/locale/country/FormikCountrySelect';
import { EditableTextArray } from '../../../components/input/EditableTextArray';

interface AddressFormElementProps {
  name: string;
  postfix?: string;
}
interface AddressCardProps {
  label: string;
  onChange: () => void;
  icon?: JSX.Element;
  name: string | null;
}
export function AddressCard(props: AddressCardProps) {
  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography style={{ display: 'inline-flex', alignItems: 'center' }}>
          {props.label} {props.icon}
        </Typography>
        <Checkbox
          size="small"
          checked={!!props.name}
          onChange={props.onChange}
        />
      </Box>
      <Collapse in={Boolean(props.name)}>
        {props.name && <AddressFormElement name={props.name} />}
      </Collapse>

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
    </div>
  );
}
export function AddressFormElement({
  name,
  postfix = '.'
}: AddressFormElementProps) {
  return (
    <Grid container spacing={1} style={{ marginBottom: 20, width: '90%' }}>
      <Grid item xs={12}>
        <EditableTextArray
          allowEmpty
          required
          name={`${name}${postfix}address`}
          primaryLabel="Address"
          secondaryLabel="address addition"
        />
      </Grid>
      <Grid item xs={3}>
        <FormikTextField name={`${name}${postfix}zipCode`} label="Zip Code" />
      </Grid>
      <Grid item xs={9}>
        <FormikTextField required name={`${name}${postfix}city`} label="City" />
      </Grid>
      <Grid item xs={12}>
        <FormikCountrySelect required name={`${name}${postfix}countryCode`} />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          rows={3}
          multiline
          name={`${name}${postfix}comment`}
          label="Comment "
        />
      </Grid>
    </Grid>
  );
}
