import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

export function IconButtonLink(props: IconButtonProps & RouterLinkProps) {
  return (
    <IconButton component={RouterLink} {...props}>
      {props.children}
    </IconButton>
  );
}
