"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAllTags = exports.formatIETFTag = exports.includes = exports.parseIETFTag = void 0;
const Regions_1 = require("../shared/constants/Regions");
const LanguageCodes_1 = require("./mappings/LanguageCodes");
const Alphabets_1 = require("./mappings/Alphabets");
// eslint-disable-next-line max-len
const IETFRegex = /^(?<lang>[a-zA-Z]{2,3})(?:-(?<extLang>[a-zA-Z]{3}))?(?:-(?<script>[a-zA-Z]{4}))?(?:-(?<region>([a-zA-Z]{2})|([0-9]{3})))?$/;
function parseIETFTag(tag) {
    const match = tag.match(IETFRegex);
    if (!match || !match.groups || !match.groups.lang) {
        return undefined;
    }
    const parts = {
        language: match.groups.lang,
        extendedLanguage: match.groups.extLang,
        script: match.groups.script,
        region: match.groups.region
    };
    const ietfTag = {
        language: LanguageCodes_1.LanguageMap[parts.language],
        extendedLanguage: parts.extendedLanguage,
        script: parts.script ? Alphabets_1.Alphabets[parts.script.toLowerCase()] : undefined,
        region: parts.region ? Regions_1.RegionCodeMap[parts.region.toUpperCase()] : undefined
    };
    if (!ietfTag.language) {
        return undefined;
    }
    if (!ietfTag.script && parts.script) {
        return undefined;
    }
    if (!ietfTag.region && parts.region) {
        return undefined;
    }
    return ietfTag;
}
exports.parseIETFTag = parseIETFTag;
function includes(parentTag, childTag, includeOptions = { regionHierarchy: false }) {
    const componentIncludes = (p, c, accessor) => {
        var _a;
        const pValue = accessor[0](p);
        if (pValue !== undefined) {
            if (!((_a = accessor[1]) !== null && _a !== void 0 ? _a : ((p1, c1) => p1 === c1))(pValue, accessor[0](c), includeOptions)) {
                return false;
            }
        }
        return true;
    };
    const includesAll = (p, c, ...accessor) => accessor.every((a) => componentIncludes(p, c, a));
    return (parentTag.language.code === childTag.language.code &&
        includesAll(parentTag, childTag, [(t) => t.extendedLanguage], [
            (t) => { var _a; return (_a = t.region) === null || _a === void 0 ? void 0 : _a.id; },
            (p, c, { regionHierarchy }) => regionHierarchy
                ? c !== undefined &&
                    (0, Regions_1.regionIncludes)(Regions_1.RegionCodeMap[p], Regions_1.RegionCodeMap[c])
                : p === c
        ], [(t) => { var _a; return (_a = t.script) === null || _a === void 0 ? void 0 : _a.code; }]));
}
exports.includes = includes;
function formatIETFTag(ietfTag, format = 'standard') {
    var _a, _b;
    const parts = [
        ietfTag.language[format === 'standard' ? 'code' : 'displayCode'],
        ietfTag.extendedLanguage,
        (_a = ietfTag.script) === null || _a === void 0 ? void 0 : _a.code,
        (_b = ietfTag.region) === null || _b === void 0 ? void 0 : _b.code
    ];
    return parts.filter((p) => !!p).join('-');
}
exports.formatIETFTag = formatIETFTag;
function formatAllTags(ietfTag) {
    return [formatIETFTag(ietfTag), formatIETFTag(ietfTag, 'short')];
}
exports.formatAllTags = formatAllTags;
/* Countries.forEach((c) => {
  if (!RegionCodeMap[c.code]) {
    console.log('not found', c);
  }
});
WordbeeStaticList.forEach((w) => {
  const ietfTag = parseIETFTag(w.ietf ?? w.code);
  if (ietfTag === undefined) {
    console.log(w.code, w.name);
  }
}); */
