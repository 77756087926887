"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CSVParser = void 0;
class CSVParser {
    constructor(map, columnAmount, config) {
        this.map = map;
        this.config = config;
        const posMap = Object.fromEntries(Object.entries(map).map(([, v]) => [v.position, v]));
        this.columns = Array.from({ length: columnAmount }, (_, i) => posMap[`${i}`]);
    }
    parseInput(data) {
        const rowData = data.map((row, rowIndex) => {
            var _a;
            if (row.length !== this.columns.length) {
                return {
                    errors: [
                        { cells: [], rows: [rowIndex], error: 'Column amount mismatch' }
                    ],
                    excluded: false,
                    data: []
                };
            }
            const prepData = Object.fromEntries(Object.entries(this.map).map(([k, v]) => [
                k,
                v.column.prepareContent(row[v.position])
            ]));
            const excluded = ((_a = this.config.exclude) !== null && _a !== void 0 ? _a : (() => false))(prepData);
            const rowDescriptor = {
                errors: [],
                excluded,
                data: row.map((text) => ({
                    text,
                    errors: [],
                    value: undefined
                }))
            };
            if (!excluded) {
                rowDescriptor.data.forEach((cD, i) => {
                    var _a;
                    const desc = (_a = this.columns) === null || _a === void 0 ? void 0 : _a[i];
                    if (desc) {
                        try {
                            const parsedVal = desc.column.splitter
                                ? desc.column
                                    .splitter(prepData[desc.name])
                                    .map((v) => desc.column.parseContent(desc.column.prepareContent(v)))
                                : desc.column.parseContent(prepData[desc.name]);
                            const validation = desc.column.validateData(parsedVal);
                            if (validation !== true) {
                                cD.errors.push({
                                    rows: [rowIndex],
                                    cells: [i],
                                    error: validation === false ? 'failed' : validation
                                });
                            }
                            else {
                                // eslint-disable-next-line no-param-reassign
                                cD.value = parsedVal;
                            }
                        }
                        catch (e) {
                            cD.errors.push({
                                rows: [rowIndex],
                                cells: [i],
                                error: e.message
                            });
                        }
                    }
                });
                if (rowDescriptor.data.every((rd) => rd.errors.length === 0)) {
                    if (this.config.validateRow) {
                        const rowValidation = this.config.validateRow(Object.fromEntries(Object.entries(this.map).map(([k, v]) => [
                            k,
                            rowDescriptor.data[v.position].value
                        ])));
                        rowDescriptor.errors.push(...rowValidation.map((rv) => ({
                            rows: [rowIndex],
                            cells: rv.cells.map((sc) => this.map[sc].position),
                            error: rv.message
                        })));
                    }
                }
            }
            return rowDescriptor;
        });
        const validRows = rowData
            .map((rd, ri) => [
            ri,
            rd.excluded ||
                rd.errors.length ||
                rd.data.some((cd) => cd.errors.length)
                ? undefined
                : Object.fromEntries(Object.entries(this.map).map(([k, v]) => [
                    k,
                    rd.data[v.position].value
                ]))
        ])
            .filter(([, a]) => a !== undefined);
        if (this.config.validateData) {
            const dataValidation = this.config.validateData(validRows.map((vr) => vr[1]));
            dataValidation.forEach((dV) => {
                const rowIndices = dV.rows.map((oi) => validRows[oi][0]);
                const cellIndices = dV.cells.map((cn) => this.map[cn].position);
                const errorObj = {
                    rows: rowIndices,
                    cells: cellIndices,
                    error: dV.message
                };
                rowIndices.forEach((ri) => rowData[ri].errors.push(errorObj));
            });
        }
        const validImport = rowData.every((rd) => rd.excluded ||
            (rd.errors.length === 0 &&
                rd.data.every((cd) => cd.errors.length === 0)));
        return {
            columns: this.columns,
            valid: validImport,
            result: validImport ? validRows.map((vr) => vr[1]) : undefined,
            result2: rowData
                .map((rd, ri) => [
                ri,
                rd.excluded ||
                    rd.errors.length ||
                    rd.data.some((cd) => cd.errors.length)
                    ? undefined
                    : Object.fromEntries(Object.entries(this.map).map(([k, v]) => [
                        k,
                        rd.data[v.position].value
                    ]))
            ])
                .filter(([, a]) => a !== undefined)
                .map((vr) => vr[1]),
            rows: rowData
        };
    }
}
exports.CSVParser = CSVParser;
