import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Chip, Grid, Typography } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { FormikEntitySelect } from '../../../../components/select/FormikEntitySelect';
import { serviceClassAPI } from '../../../services/redux/service-classes.slice';
import { DialogForm } from '../../../../components/input/form/DialogForm';
import projectsSlice, {
  projectSelectors
} from '../../../projects/redux/projects.slice';
import {
  FormikLanguageSelect,
  FormikMultipleLanguageSelect
} from '../../../../components/select/FormikLanguageSelect';
import { useServiceClass } from '../../../services/hooks/service-class.hook';
import { LanguageDisplay } from '../../../languages/components/LanguageDisplay';
import { useTableColumn } from '../../../../components/table/context/table-column.hook';
import { useTableFilterControl } from '../../../../components/table/filter/column-filter-control.hook';
import { useStatusTypes } from '../../hooks/status-types.hook';
import { useTypedField } from '../../../../transformer/DTOViewSchema';

function ServiceClassNameDisplay({ id }: { id: string }) {
  const { serviceClass } = useServiceClass(id);
  return <Typography>{serviceClass?.name}</Typography>;
}

export function SearchConfigChip(props: { searchConfig: ServiceSearchState }) {
  return (
    <Chip
      label={
        <div style={{ display: 'flex' }}>
          <ServiceClassNameDisplay id={props.searchConfig.serviceClassId} />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {(props.searchConfig.languages[0].sourceLanguageId ||
              props.searchConfig.languages[0].targetLanguageIds.length) && (
              <Typography style={{ paddingLeft: 10 }}>(</Typography>
            )}
            {props.searchConfig.languages[0].sourceLanguageId && (
              <div>
                <LanguageDisplay
                  languageId={props.searchConfig.languages[0].sourceLanguageId}
                />
              </div>
            )}
            {props.searchConfig.languages[0].targetLanguageIds.length && (
              <>
                <ArrowForward />
                {props.searchConfig.languages[0].targetLanguageIds.map((ti) => (
                  <LanguageDisplay languageId={ti} />
                ))}
              </>
            )}
            {(props.searchConfig.languages[0].sourceLanguageId ||
              props.searchConfig.languages[0].targetLanguageIds.length) && (
              <Typography>)</Typography>
            )}
          </div>
        </div>
      }
    />
  );
}

export interface ServiceSearchState {
  serviceClassId: string;
  ignoreServiceLanguages: boolean;
  languages: { sourceLanguageId?: string; targetLanguageIds: string[] }[];
}

const initialValues: ServiceSearchState = {
  serviceClassId: '',
  ignoreServiceLanguages: false,
  languages: [{ targetLanguageIds: [] }]
};

interface SupplierServiceSearchDialogFormProps {
  onChange: (searchConfig: ServiceSearchState) => void;
  state?: ServiceSearchState;
}

function LanguageCombinationSelect() {
  const [, meta] = useTypedField<string>('serviceClassId');
  const { serviceClass } = useServiceClass(meta.value);
  if (!serviceClass || serviceClass.type === 'no-language') {
    return <></>;
  }
  if (serviceClass.type === 'only-target') {
    return (
      <Grid item xs={12}>
        <FormikMultipleLanguageSelect
          name="languages[0].targetLanguageIds"
          label="Target Language"
        />
      </Grid>
    );
  }
  return (
    <Grid item xs={12} container>
      <Grid item xs={5}>
        <FormikLanguageSelect
          name="languages[0].sourceLanguageId"
          label="Source Language"
        />
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ArrowForward />
      </Grid>
      <Grid item xs={5}>
        <FormikMultipleLanguageSelect
          name="languages[0].targetLanguageIds"
          label="Target Languages"
        />
      </Grid>
    </Grid>
  );
}

export function SupplierServiceSearchDialogForm(
  props: SupplierServiceSearchDialogFormProps
) {
  const { t } = useTranslation();
  const { filter: qFilter, ...qCell } = useTableColumn(
    'supplier_qualifications'
  );
  const {
    state: qState,
    active: qActive,
    setActive: qSetActive,
    resetState: qResetState,
    changeState: qChangeState
  } = useTableFilterControl('supplier_qualifications');
  const { filter: sFilter, ...sCell } = useTableColumn('supplier_status');
  const { entities: statusTypes } = useStatusTypes();
  const activeStatusTypes = statusTypes.filter(
    (st) => st.statusType === 'active'
  );
  const {
    state: sState,
    active: sActive,
    setActive: sSetActive,
    resetState: sResetState,
    changeState: sChangeState
  } = useTableFilterControl('supplier_status');
  useEffect(() => {
    if (sFilter !== undefined) {
      sChangeState(activeStatusTypes.map((a) => a.id) as never);
    }
  }, [sFilter]);
  if (sFilter === undefined || qFilter === undefined) {
    return <></>;
  }
  const Component = qFilter.component;
  const SComponent = sFilter.component;
  return (
    <DialogForm
      identifier="search"
      label="Detailed Search"
      form={{
        initialValues: props.state ?? initialValues,
        validationSchema: yup
          .object({
            serviceClassId: yup.string().uuid().required()
          })
          .defined()
      }}
      api={{
        clearError: projectsSlice.actions.clearError,
        errorSelector: projectSelectors.selectError,
        onSubmit: (values) => {
          if (!qActive && (qState as any)?.length) {
            qSetActive(true);
          }
          if (!sActive) {
            sSetActive(true);
          }
          props.onChange(values);
          return Promise.resolve({ id: 'empty' });
        },
        stateSelector: projectSelectors.selectState
      }}
      actions={[{ label: t('Find'), doSubmit: true }]}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormikEntitySelect
            entityApi={serviceClassAPI}
            optionLabel={(s) => s.name}
            name="serviceClassId"
            textFieldProps={{ label: 'Service Class' }}
          />
        </Grid>
        <LanguageCombinationSelect />
        <Grid item xs={12}>
          <Component
            cell={qCell}
            filterState={qState}
            onFilterChange={qChangeState as any}
            onFilterReset={qResetState}
          />
        </Grid>
        <Grid item xs={12}>
          <SComponent
            cell={sCell}
            filterState={sState}
            onFilterChange={sChangeState as any}
            onFilterReset={sResetState}
          />
        </Grid>
      </Grid>
    </DialogForm>
  );
}
