import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikTextField } from '../../../../components/input/FormikTextField';

export function ClientBasicForm() {
  const { t } = useTranslation();

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <FormikTextField label={t('Client Name')} name="name" />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField label={t('Tax ID')} name="taxId" />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField label={t('CEO')} name="ceo" />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField label={t('E-Mail')} name="email" />
      </Grid>
      <Grid item xs={6}>
        <FormikTextField label={t('Phone')} name="phone" />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          label={t('Description')}
          name="description"
          multiline
        />
      </Grid>
    </Grid>
  );
}
