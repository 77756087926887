import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CommentEditor } from '../../comment/CommentEditor';
import { useTypedField } from '../../../transformer/DTOViewSchema';

// Todo Manu was für ein type müsste ich hier stat any nehmen?

interface CommentWithFiles {
  comment: string;
  attachedFiles?: any;
}

interface FormikCommentEditorProps {
  name: any;
  label?: string;
}

export function FormikCommentEditor(props: FormikCommentEditorProps) {
  const [, meta, helpers] = useTypedField<CommentWithFiles | undefined>(
    props.name
  );

  const { t } = useTranslation();

  return (
    <div>
      {props.label && (
        <Typography variant="caption" color="textSecondary">
          {t(props.label)}
        </Typography>
      )}
      <CommentEditor
        onChange={(v) =>
          helpers.setValue({
            comment: v,
            attachedFiles: meta.value?.attachedFiles
          })
        }
        value={meta.value?.comment || ''}
        onFileChange={(attachedFiles, done) => {
          if (!done) {
            helpers.setValue({
              comment: meta.value?.comment || '',
              attachedFiles
            });
          }
        }}
      />
    </div>
  );
}
